/*
 * MUI DataTable component for Abnormal Values
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Paper } from "@mui/material";

const AbnormalValuesTable = (props) => {
  const { t } = useTranslation("translations");
  const abnormalValueArray = props.abnormalValues;
  const [pageSize, setPageSize] = useState(25)
  const abnormalValuesTableArray = props.containsBurninTest
    ? abnormalValueArray.filter((val) => val.burnin_test !== 0) // get all burnin tests aka burnin test != 0
    : abnormalValueArray.filter((val) => val.burnin_test === 0); // get all non burnin tests aka burnin test == 0
  
    const columns = [
    {
      field: "display_name",
			headerName: t("Value name"),
			headerAlign: "center",
			align: "center",
			flex: 1,
    },
    {
      field: "minimum_value",
			headerName: t("Minimum"),
			type: "number",
			headerAlign: "center",
			align: "center",
			flex: 0.5,
    },
    {
      field: "maximum_value",
			headerName: t("Maximum"),
			type: "number",
			headerAlign: "center",
			align: "center",
			flex: 0.5,
    },
    ...(props.containsBurninTest
      ? [
          {
            field: "burnin_test",
            headerName: t("Test #"),
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
          },
        ]
      : []),
    ...(props.containsBurninTest
      ? [
          {
            field: "score_coefficient",
            headerName: t("Score Coefficient"),
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
          },
        ]
      : []),
  ];

  return (
    <Paper sx={{ height: "70vh", width: "100%" }}>
			<Box
				sx={{
					height: "70vh",
					width: "100%",
				}}
			>
    <DataGrid
      rows={abnormalValuesTableArray}
      columns={columns}
      pagination
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: { background: "primary" },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: props.containsBurninTest ? "burnin_test" : "display_name", sort: "asc" }],
        },
      }}
    />
    </Box>
  </Paper>
  );
};

AbnormalValuesTable.defaultProps = {
  abnormalValues: [],
  containsBurninTest: false,
};

AbnormalValuesTable.propTypes = {
  abnormalValues: PropTypes.array,
  containsBurninTest: PropTypes.bool,
};

export default AbnormalValuesTable;
