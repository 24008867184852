import React from "react";
import PropTypes from "prop-types";
import { Box, Paper } from "@mui/material";

const MetricResults = (props) => {
	const formatCycleType = (type) => {
		switch (type) {
			case "SteamCycle":
				return "Steam Cycle";
			case "GrindCycle":
				return "Grind Cycle";
			case "BurninTest":
				return "Burn in Test";
			default:
				return "";
		}
	};
	return (
		<Paper>
			<Box>
				{props.steamCycles.map((cycle) => {
					return cycle.id;
				})}
			</Box>
			<Box>
				{props.grindCycles.map((cycle) => {
					return cycle.id;
				})}
			</Box>
		</Paper>
	);
};

MetricResults.propTypes = {
	steamCycles: PropTypes.array,
	grindCycles: PropTypes.array,
};

export default MetricResults;
