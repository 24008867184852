/*
*  Component Responsible for Creating a New Device Operator
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Trans} from "react-i18next";
import '../css/SplitPaneCard.css';
import '../css/CreateADeviceOperator.css';
import {Button, Header, Modal, Checkbox, Dropdown, TransitionablePortal} from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import SimpleLoader from './SimpleLoader';
import {
  isGroupSterilisOrDist,
  AuthReq,
  isGroupInGroups,
} from './library/helpers'
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class CustomerCreateASiteAdmin extends React.Component {
  state = {
    isAdmin: false,
    createAnother: false,
    notificationLevel: 0,
    pinValidationError: false,
    fullName: '',
    userPIN: '',
    emailAddr: '',
    phoneNumber: '',
    username: '',
    checkingUsername: false,
    allowModalToBeClosed: true,
    facilityDropdown: []
  };


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const {
      isFacilityTab,
      group
    } = this.props;

    // If they're a Customer, their customer ID will within their simpleCustomerFacilityObj
    // this will be used later to figure out which set of PINs must be enforced
    // For internal people, this will be figured out when they click on a customer
    if (group === 'CustomerPortalUsers') {
      const [customerID] = Object.keys(this.props.simpleCustomerFacilityObj);
      this.setState({
        customerID
      });
    }
    if (isFacilityTab && isGroupInGroups(group, ['FacilityAdmin'])) {
      const [customerID] = Object.keys(this.props.simpleCustomerFacilityObj);
      const FacilityID =  this.props.simpleCustomerFacilityObj[customerID].facilities.map(facility => facility.id)
      this.setState({
        customerID,
        FacilityID
      });
    }
  }

  handleDeviceAdminChange = () => {
    this.setState((prevState) => {
      return {isAdmin: !prevState.isAdmin};
    });
  };

  handleCreateAnother = () => {
    this.setState((prevState) => {
      return {createAnother: !prevState.createAnother};
    });
  };

  handleNotificationChange = (event, data) => {
    this.setState({
      notificationLevel: data.value
    });
  };

  shakeUsernameSpan = () => {
    this.setState({
      setUsernameShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setUsernameShake: false,
        })
      }, 1000)
    })
  };

  shakePINSpan = () => {
    this.setState({
      setPINShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setPINShake: false,
        })
      }, 1000)
    })
  };

  checkIfUsernameTaken = (username) => {
    // If the user is submitting a duplicate username really quickly and they try to submit the
    // form before the check username call finishes, it'll crash because the username will not be unique
    // We'll just disable the form submit through checkingUsername to prevent this bad UX
    this.setState({
      checkingUsername: true
    });
    Auth.fetch(`/api/username-available/`, {
      method: 'POST',
      body: JSON.stringify({
        'username': username
      })
    }).then(data => {
      if (data['available'] === 1) {
        this.setState({
          usernameValidationError: false,
          checkingUsername: false,
        })
      } else {
        this.shakeUsernameSpan();
        this.setState({
          usernameValidationError: true,
          checkingUsername: false,
        })
      }
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        checkingUsername: false,
      })
    });
  };

  debouncedCheckIfUsernameTaken = debounce((username) => this.checkIfUsernameTaken(username), 350);

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (name === 'username') {
      if (/^[a-zA-Z0-9_-]*$/i.test(value)) {
        this.debouncedCheckIfUsernameTaken(value);
      } else {
        this.shakeUsernameSpan();
        this.setState({
          usernameValidationError: true
        })
      }
    }

    if (name === 'userPIN') {
      const customerID = this.state.customerID;
      if (customerID) {
        const pinArr = this.props.simpleCustomerFacilityObj[customerID].pins;

        // pinArr is a JavaScript Set, which does not have the Array find function
        //we need to [...spread] pinArr into an array, so we can use the find function
        const usedPin = [...pinArr].find((pins) => {
          return pins === value;
        });
        if (usedPin) {
          this.shakePINSpan();
          this.setState({
            usedPin: usedPin,
            usedPinValidationError: true
          })
        } else {
          this.setState({
            usedPin: null,
            usedPinValidationError: false
          })
        }

      }

      if (value.length > 7 || value.length < 4) {
        this.setState({
          pinValidationError: true
        })
      } else {
        //We only want to un-validate the pinvalidation state if we know there is no usedPin validation error going on
        if (!this.state.usedPin) {
          this.setState({
            pinValidationError: false
          })
        }
      }

      // if the value is not not a number, it is a number
      // goofy, but it's the only 100% way to check if something is a number in js
      // pins must only be numbers
      // need to use this over type="number" because of floating labels & controlled inputs
      if (!isNaN(value)) {
        this.setState({[name]: value});
      }

    } else {
      this.setState({[name]: value});
    }

  };


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };


  createSiteAdmin = (e) => {
    e.preventDefault();
    const {
      isFacilityTab
    } = this.props;
    let usergroup = 'CustomerPortalUsers'
    let employee
    const {
      createAnother,
      isAdmin,
      notificationLevel,
      fullName,
      userPIN,
      emailAddr,
      phoneNumber,
      customerID,
      FacilityID,
      username
    } = this.state;

    employee = {
      full_name: fullName,
      email_address: emailAddr,
      phone: phoneNumber,
      customer_id: customerID,
    };

    if (isFacilityTab){
      employee = {
        full_name: fullName,
        email_address: emailAddr,
        phone: phoneNumber,
        customer_id: customerID,
        facility_ids: FacilityID
      }
      usergroup = "FacilityAdmin"
    }

    this.setState({
      appLoading: true,
      allowModalToBeClosed: false
    });

    Auth.fetch(`/api/employee/`, {
      method: 'POST',
      body: JSON.stringify(employee)
    }).then(data => {
      const employeeID = data.id;
      let notifyComplete = false;
      let notifyError = false;
      switch (notificationLevel) {
        case 0:
          notifyComplete = false;
          notifyError = false;
          break;
        case 1:
          notifyComplete = true;
          notifyError = false;
          break;
        case 2:
          notifyComplete = false;
          notifyError = true;
          break;
        case 3:
          notifyComplete = true;
          notifyError = true;
          break;
        default:
          notifyComplete = false;
          notifyError = false;
          break;
      }
      const deviceOperator = {
        employee_id: employeeID,
        pin: userPIN,
        is_admin: isAdmin,
        notify_complete: notifyComplete,
        notify_error: notifyError,

      };
      Auth.fetch(`/api/device-operator/`, {
        method: 'POST',
        body: JSON.stringify(deviceOperator)
      }).then(data => {
        const portalUser = {
          employee_id: employeeID,
          username: username,
          is_active: false,
          group: usergroup,
        };
        Auth.fetch(`/api/portal-user/`, {
          method: 'POST',
          body: JSON.stringify(portalUser)
        }).then(data => {

          this.props.notifySiteAdminSuccess(fullName);
          this.props.fetchCustomerMachines().then(() => {

            this.props.fetchSiteAdmins().then(() => {
              this.props.fetchDeviceOperators().then(() => {
                // reset the form
                this.setState({
                  isAdmin: false,
                  notificationLevel: 0,
                  fullName: '',
                  userPIN: '',
                  emailAddr: '',
                  phoneNumber: '',
                  username: '',
                  appLoading: false,
                  allowModalToBeClosed: true
                });

                if (!createAnother) {  // if user doesn't want to create another device operator, close the modal
                  this.props.handleSiteAdminModal();
                }

              });
            });


          });
        }).catch((err) => {
          Sentry.captureException(err);
          this.setState({
            isAdmin: false,
            notificationLevel: 0,
            fullName: '',
            userPIN: '',
            emailAddr: '',
            phoneNumber: '',
            username: '',
            appLoading: false,
            allowModalToBeClosed: true
          });
          this.props.notifySiteAdminFailure(fullName);
        });
      }).catch((err) => {
        Sentry.captureException(err);
        this.setState({
          isAdmin: false,
          notificationLevel: 0,
          fullName: '',
          userPIN: '',
          emailAddr: '',
          phoneNumber: '',
          username: '',
          appLoading: false,
          allowModalToBeClosed: true
        });
        this.props.notifySiteAdminFailure(fullName);
      });
    }).catch((err) => {
      Sentry.captureException(err);
      this.setState({
        isAdmin: false,
        notificationLevel: 0,
        fullName: '',
        userPIN: '',
        emailAddr: '',
        phoneNumber: '',
        username: '',
        appLoading: false,
        allowModalToBeClosed: true
      });
      this.props.notifySiteAdminFailure(fullName);
    });

  };

  handleCustomerChange = (none, data) => {
    const customerID = data.value;
    const facilityDropdown = data.options.find(o => o.value === customerID).facilities
    const pinArr = this.props.simpleCustomerFacilityObj[customerID].pins;
    const userPIN = this.state.userPIN;

    // pinArr is a JavaScript Set, which does not have the Array find function
    //we need to [...spread] pinArr into an array, so we can use the find function
    const usedPin = [...pinArr].find((pins) => {
      return pins === userPIN;
    });
    if (usedPin) {
      this.setState({
        usedPin: usedPin,
        usedPinValidationError: true,
        customerID: customerID,
        FacilityID:[],
        facilityDropdown: facilityDropdown
      })
    } else {
      this.setState({
        usedPin: null,
        usedPinValidationError: false,
        customerID: customerID,
        FacilityID:[],
        facilityDropdown: facilityDropdown
      })
    }
  };

  handleFacilityChange = (none, data) => {
    const FacilityID = data.value;

    this.setState({
      FacilityID
    })

  };


  render() {
    // const topMargin = this.state.height < 1000 ? '3vh' : '10vh';
    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '4%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const {
      isAdmin,
      createAnother,
      notificationLevel,
      pinValidationError,
      appLoading,
      usedPin,
      usedPinValidationError,
      customerID,
      FacilityID,
      facilityDropdown,
      usernameValidationError,
      setUsernameShake,
      setPINShake,
      checkingUsername
    } = this.state;

    const {
      group,
      t,
      customerDropdown,
      isFacilityTab

    } = this.props;


    const notificationDropdown = [
      {
        key: 0,
        value: 0,
        id: '0',
        text: t('Do not notify')
      },
      {
        key: 1,
        value: 1,
        id: '1',
        text: t('Notify on Completion')
      },
      {
        key: 2,
        value: 2,
        id: '2',
        text: t('Notify on Error')
      },
      {
        key: 3,
        value: 3,
        id: '3',
        text: t('Notify on Completion and Error')
      },

    ];

    return (
      <TransitionablePortal
        open={this.props.addSiteAdminModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.handleSiteAdminModal()
          }}
        >
          {appLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
          {isFacilityTab ?
            <h2>{t('Create a New Facility Administrator')}</h2>
            :
            <h2>{t('Create a New Site Administrator')}</h2>
          }
          </Header>
          <Modal.Content className={isGroupSterilisOrDist(group) ? 'create-site-admin-ster' : 'create-device-operator-cust'}>
            <form id='createASiteAdminForm'
                  onSubmit={this.createSiteAdmin}
                  className="create-a-site-admin-form">
              <div className='split-container'>
                <div className='desc customer'>
                  <div className='desc-text'>
                    { isFacilityTab ?
                      t('create-facility-admin.1', 'Facility Administrators are the employees who will be using the Sterilis Solutions portal. A Device Operator account will also be automatically created for them.')
                      :
                      t('create-site-admin.1', 'Site Administrators are the employees who will be using the Sterilis Solutions portal. A Device Operator account will also be automatically created for them.')
                    }
                  </div>
                  <div className='desc-text'>
                    {isFacilityTab ?
                      t('create-facility-admin.2', 'Facility Administrators are responsible for creating Device Operators, as well as other Facility Administrators.')
                      :
                      t('create-site-admin.2', 'Site Administrators are responsible for creating Device Operators, as well as other Site Administrators.')
                    }
                  </div>
                  <div className='desc-text'>
                    {isFacilityTab ?
                      t('create-facility-admin.3', 'Facility Administrators can view the Cycle Dashboard, which shows cycles that have been run on a device. They can also generate OSHA reports.')
                      :
                      t('create-site-admin.3', 'Site Administrators can view the Cycle Dashboard, which shows cycles that have been run on a device. They can also generate OSHA reports.')
                    }
                    <br/>
                  </div>
                  <div className='desc-text'>
                    {isFacilityTab ?
                      t('create-facility-admin.4', 'Various documents provided by Sterilis are also available to Facility Administrators. These documents include materials such as Training Manuals or Troubleshooting guides.')
                      :
                      t('create-site-admin.4', 'Various documents provided by Sterilis are also available to Site Administrators. These documents include materials such as Training Manuals or Troubleshooting guides.')
                    }
                  </div>
                  <div
                    className={(usernameValidationError ? 'label-validation-error desc-text' : 'desc-text') + (setUsernameShake ? ' shake' : '')}>
                    {t('create-site-admin.7', 'Usernames must be unique. They also must be alphanumeric. - and _ are allowed')}
                  </div>
                  <div
                    className={pinValidationError || usedPinValidationError ? 'label-validation-error desc-text' : 'desc-text'}>
                    {t('create-site-admin.5', 'The User PIN value must be a unique set of 4 to 7 numeric characters.')}
                    <br/>
                  </div>
                  {
                    usedPin &&
                    <div className={(setPINShake ? 'shake' : '') + ' label-validation-error desc-text desc-text'}>
                      <Trans ns='translations' i18nKey='used_pin'>
                        The pin {{usedPin}} has already been used.
                      </Trans>
                    </div>
                  }
                </div>
                <div className='items'>
                  <AuthReq
                    userGroup={group}
                    requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs',
                      'FactoryWorkers', 'DistributorAdmins','SterilisWasteTypeAdmin']}
                  >
                      <div className="form-group">
                        <label className="fake-float">{t('Customer')}</label>
                        <Dropdown
                          className='full-width'
                          search
                          selection
                          id='siteAdminSelectCustomer'
                          options={customerDropdown}
                          value={customerID}
                          onChange={this.handleCustomerChange}

                        />
                      </div>
                      {isFacilityTab ?
                        <div className="form-group">
                          <label className="fake-float">{t('Facility')}</label>
                          <Dropdown
                            className='full-width'
                            search
                            fluid 
                            multiple selection
                            id='siteAdminSelectFacility'
                            options={facilityDropdown}
                            value={FacilityID}
                            onChange={this.handleFacilityChange}

                          />
                        </div> : null
                      }

                  </AuthReq>
                  <AuthReq
                    userGroup={group}
                    requiredRoles={['CustomerPortalUsers', 'FacilityAdmin']}
                  >
                    {isFacilityTab ?
                      <div className="form-group">
                        <label className="fake-float">{t('Facility')}</label>
                        <Dropdown
                          className='full-width'
                          search
                          fluid 
                          multiple selection
                          id='siteAdminSelectFacility'
                          options={customerDropdown[0]['facilities']}
                          value={FacilityID}
                          onChange={this.handleFacilityChange}

                        />
                      </div>
                      : null
                    }
                  </AuthReq>
                  <div className="form-group">
                    <input value={this.state.fullName}
                           name='fullName'
                           onChange={this.handleChange}
                           type="text"
                           id="fullNameInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="fullNameInput">{t('Full Name')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.username}
                           name='username'
                           onChange={this.handleChange}
                           type="text"
                      // disabled={checkingUsername}
                           id="usernameInput"
                           className="form-control"
                           pattern="^[a-zA-Z0-9_-]*$"
                           title="Usernames must be alphanumeric. - and _ are allowed"
                           required/>
                    <label
                      className={`${usernameValidationError ? 'float-label-invalid' : ''} form-control-placeholder`}
                      htmlFor="usernameInput">{t('Username')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.userPIN}
                           name='userPIN'
                           onChange={this.handleChange}
                           type="text"
                           id="userPINInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="userPINInput">{t('User PIN')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.emailAddr}
                           name='emailAddr'
                           onChange={this.handleChange}
                           type="text"
                           id="emailAddressInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="emailAddressInput">{t('Email Address')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.phoneNumber}
                           name='phoneNumber'
                           onChange={this.handleChange}
                           type="text"
                           id="phoneNumberInput"
                           className="form-control" required/>
                    <label className="form-control-placeholder"
                           htmlFor="phoneNumberInput">{t('Phone Number')}</label>
                  </div>

                  <div className="form-group">
                    <label className="fake-float">{t('Notification Settings')}</label>
                    <Dropdown
                      className='full-width'
                      search
                      selection
                      id='notificationSettingsDropdown'
                      options={notificationDropdown}
                      value={notificationLevel}
                      onChange={this.handleNotificationChange}
                    />
                  </div>
                  <div className="form-group">
                    <Checkbox label={t('Device Admin')}
                              name='isAdmin'
                              id='isAdmin'
                              checked={isAdmin}
                              onChange={this.handleDeviceAdminChange}/>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Checkbox label={<label htmlFor="createAnotherSiteAdminCheckbox"
                                    id='labelCreateAnotherSiteAdminCheckbox'>{isFacilityTab? t('Create another Facility Admin?') : t('Create another Site Admin?')}</label>}
                      checked={createAnother}
                      name='createAnother'
                      id='createAnotherSiteAdminCheckbox'
                      onChange={this.handleCreateAnother}/>
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='createASiteAdminForm'
                    id='createASiteAdminBtn'
                    disabled={pinValidationError || usedPinValidationError || !customerID || usernameValidationError || checkingUsername || (isFacilityTab && (!FacilityID || FacilityID.length===0))}
            >{isFacilityTab? t('Create Facility Admin') : t('Create Site Admin')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(CustomerCreateASiteAdmin);
