import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import DistributorTable from "./DistributorTable";

const ViewDistributors = (props) => {
    return (
        <Card>
            <CardHeader
                title={
                    <Typography variant="h4" color="secondary" fontWeight={"bold"}>
                        View Distributors
                    </Typography>
                }
            />
            <CardContent>
                <DistributorTable />
            </CardContent>
        </Card>
    );
};

export default ViewDistributors;
