import viewDistributors from "./images/sterilis/view_distributors.png";
import serviceSummaryFilters from "./images/sterilis/service_summary_filters.png";
import chartOptions from "./images/sterilis/chart_options.png";
import wasteTypes from "./images/sterilis/waste_types.png";

export const changelog = [
    {
        type: "new",
        audience: "sterilis",
        desc: "Added view distributors page",
        details: "Added new View option under Distributos option in side menu.",
        images: [{ id: "view-distributors", src: viewDistributors, title: "View Distributors" }],
    },
    {
        type: "new",
        audience: "sterilis",
        desc: "Added additional filters to Service Summary report.",
        details: "Added Active, Archived, Suspended, and Unassigned device filters.",
        images: [{ id: "summary-filters", src: serviceSummaryFilters, title: "Service Summary Filters" }],
    },
    {
        type: "chg",
        audience: "sterilis",
        desc: "Added solonoid number labels to cycle metrics chart options.",
        details: "Added secondary labels to the chart options to display the solonoid numbers for overlays.",
        images: [{ id: "chart-options", src: chartOptions, title: "Solonoid Labels" }],
    },
    {
        type: "chg",
        audience: "sterilis",
        desc: "Updated Device Config to accept 6 active waste types.",
        details:
            "Device configurations are now allowed to accept up to 6 waste type for device running latest firmware.",
        images: [{ id: "wastetypes", src: wasteTypes, title: "Increased Waste Types" }],
    },
];
