/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getRoundedNumber, calculateDurations } from "src/components/library/helpers";
import CycleStat from "./CycleStat";
import { cycleStatHeader } from "./ChartHelpers";
import CycleParameters from "./CycleParameters";
import moment from "moment";
import PortalCycleInformation from "./PortalCycleInformation";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    Card,
    CardHeader,
    Typography,
    Box,
    Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Duration } from "luxon";
import { get } from "lodash";

const CycleStats = (props) => {
    const { t } = useTranslation("translations");
    const { bitNumber, selectedCycle, showSteamCycleTrends, showGrindCycleTrends, showSharedCycleTrends } = props;
    const cycleType = selectedCycle.cycle_type;
    const md = props.fromBurninTest ? 12 : 6;
    const lg = props.fromBurninTest ? 12 : 4;

    const grindSuccess = ([key, value]) => {
        if (typeof key === "string" && key.includes("_duration")) {
            let duration = Duration.fromISOTime(selectedCycle[key]).as("minutes");
            return duration >= value.minimum_value && duration <= value.maximum_value;
        } else {
            return selectedCycle[key] >= value.minimum_value && selectedCycle[key] <= value.maximum_value;
        }
    };

    const successfulCycle = (cycle, parserValues) => {
        let success = false;
        if (cycleType === "SteamCycle") {
            success = cycle.score > 8;
        } else if (cycleType === "GrindCycle") {
            const thresholds = Object.fromEntries(
                Object.entries(parserValues).filter(
                    ([key, val]) =>
                        Object.keys(cycle).includes(key) && (val.burnin_test === bitNumber || val.burnin_test === 0)
                )
            );
            success = Object.entries(thresholds).every(grindSuccess);
        }
        return <span className={`${success ? "green-text" : "red-text"}`}>{success ? "Success" : "Fail"}</span>;
    };

    const burninTest7Header = () => {
        return (
            <p>
                Selected Steam Cycle #{props.burninTest7Number} started at{" "}
                {moment.utc(selectedCycle["start_time"]).format("YYYY-MM-DD HH:mm:ss z")}
            </p>
        );
    };

    return (
        <Box>
            {cycleType && selectedCycle && (
                <Card variant="outlined" sx={{ padding: 1 }}>
                    <CardHeader
                        title={
                            <Typography variant="h5" color="primary">
                                Cycle Summary
                            </Typography>
                        }
                        sx={{ p: 1 }}
                    />
                    {props.cycleTrendOptions}
                    {props.burninTest7Number && burninTest7Header()}
                    {cycleType === "SteamCycle" && (
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={md} lg={lg}>
                                    <Accordion variant="outlined" sx={{ my: 1 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="cycle-accordion"
                                            id="cycle-accordion-header">
                                            <Box>
                                                <Typography variant="h6" color="secondary">
                                                    {t("Cycle")}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {successfulCycle(selectedCycle, props.abnormalValuesObj)}
                                                </Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List>
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends ? props.trendCycleScoreCheckbox : "Score"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "score", 2)}
                                                    abnormalValues={props.abnormalValuesObj["score"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendCycleDurationCheckbox
                                                            : "Full duration"
                                                    }
                                                    value={calculateDurations(
                                                        selectedCycle,
                                                        get(selectedCycle, "duration")
                                                            ? "duration"
                                                            : "duration_minutes",
                                                        true
                                                    ).toString()}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendFlowSecondsCheckbox
                                                            : "Flow Seconds"
                                                    }
                                                    value={calculateDurations(
                                                        selectedCycle,
                                                        "flow_duration"
                                                    ).toString()}
                                                    abnormalValues={props.abnormalValuesObj["flow_seconds"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendPumpdownDurationCheckbox
                                                            : "Steam Pumpdown Time (mins)"
                                                    }
                                                    value={calculateDurations(
                                                        selectedCycle,
                                                        "pumpdown_duration",
                                                        true
                                                    ).toString()}
                                                    abnormalValues={
                                                        props.abnormalValuesObj["pumpdown_duration_minutes"]
                                                    }
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendDrainingTimeCheckbox
                                                            : "Draining Time (mins)"
                                                    }
                                                    value={calculateDurations(
                                                        selectedCycle,
                                                        "draining_duration",
                                                        true
                                                    ).toString()}
                                                    abnormalValues={
                                                        props.abnormalValuesObj["draining_duration_minutes"]
                                                    }
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendHeatUpTimeCheckbox
                                                            : "Heat Up Time (mins)"
                                                    }
                                                    value={calculateDurations(
                                                        selectedCycle,
                                                        "ramp_duration",
                                                        true
                                                    ).toString()}
                                                    abnormalValues={props.abnormalValuesObj["ramp_duration_minutes"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendPumpdownTimeoutsCheckbox
                                                            : "Pumpdown Timeouts"
                                                    }
                                                    value={getRoundedNumber(
                                                        selectedCycle,
                                                        "pumpdown_timeout_count",
                                                        0,
                                                        true
                                                    )}
                                                    abnormalValues={props.abnormalValuesObj["pumpdown_timeout_count"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendHeatUpRateDifferenceCheckbox
                                                            : props.abnormalValuesObj?.heat_up_rate_delta?.display_name
                                                    }
                                                    value={getRoundedNumber(
                                                        selectedCycle,
                                                        "heat_up_rate_delta",
                                                        2,
                                                        true
                                                    )}
                                                    abnormalValues={props.abnormalValuesObj["heat_up_rate_delta"]}
                                                />
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={md} lg={lg}>
                                    <Accordion variant="outlined" sx={{ my: 1 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="temperature-accordion"
                                            id="temperature-accordion-header">
                                            <Typography variant="h6" color="secondary">
                                                {t("Temperature")}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List disablePadding>
                                                <CycleStat
                                                    label={showSteamCycleTrends ? props.trendTPDiffCheckbox : "TP Diff"}
                                                    value={getRoundedNumber(selectedCycle, "tp_diff", 2)}
                                                    abnormalValues={props.abnormalValuesObj["tp_diff"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends ? props.trendTVStartCheckbox : "TV Start"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "tv_start", 2)}
                                                    abnormalValues={props.abnormalValuesObj["tv_start"]}
                                                />
                                                <CycleStat
                                                    label={showSteamCycleTrends ? props.trendTVMaxCheckbox : "TV Max"}
                                                    value={getRoundedNumber(selectedCycle, "tv_max", 2)}
                                                    abnormalValues={props.abnormalValuesObj["tv_max"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends ? props.trendTRAverageCheckbox : "TR Avg"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "tr_average", 2)}
                                                    abnormalValues={props.abnormalValuesObj["tr_average"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendTvAvgHoldCheckbox
                                                            : "TV Avg during hold"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "tv_average_during_hold", 2)}
                                                    abnormalValues={props.abnormalValuesObj["tv_average_during_hold"]}
                                                />
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} md={md} lg={lg}>
                                    <Accordion variant="outlined" sx={{ my: 1 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="pressure-accordion"
                                            id="pressure-accordion-header">
                                            <Typography variant="h6" color="secondary">
                                                {t("Pressure")}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List disablePadding>
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends ? props.trendPVStartCheckbox : "PV Start"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "pv_start")}
                                                    abnormalValues={props.abnormalValuesObj["pv_start"]}
                                                />
                                                <CycleStat
                                                    label={showSteamCycleTrends ? props.trendPVMinCheckbox : "PV Min"}
                                                    value={getRoundedNumber(selectedCycle, "pv_min")}
                                                    abnormalValues={props.abnormalValuesObj["pv_min"]}
                                                />
                                                <CycleStat
                                                    label={showSteamCycleTrends ? props.trendPVMaxCheckbox : "PV Max"}
                                                    value={getRoundedNumber(selectedCycle, "pv_max", 0, true)}
                                                    abnormalValues={props.abnormalValuesObj["pv_max"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showSteamCycleTrends
                                                            ? props.trendPVAverageCheckbox
                                                            : "PV Avg during hold"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "pv_average_during_hold")}
                                                    abnormalValues={props.abnormalValuesObj["pv_average_during_hold"]}
                                                />
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {cycleType === "GrindCycle" && (
                                <Grid item xs={12} md={md} lg={lg}>
                                    <Accordion variant="outlined" sx={{ my: 1 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="grinder-accordion"
                                            id="grinder-accordion-header">
                                            <Box>
                                                <Typography variant="h6" color="secondary">
                                                    {t("Grinder")}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {successfulCycle(selectedCycle, props.abnormalValuesObj)}
                                                </Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List disablePadding>
                                                <CycleStat
                                                    label={
                                                        showGrindCycleTrends
                                                            ? props.trendGrindDurationCheckbox
                                                            : "Grind Duration"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "duration_minutes")}
                                                    abnormalValues={props.abnormalValuesObj["grind_duration_minutes"]}
                                                />
                                                <CycleStat
                                                    label={showGrindCycleTrends ? props.trendStallsCheckbox : "Stalls"}
                                                    value={getRoundedNumber(selectedCycle, "stalls", 0, true)}
                                                    abnormalValues={props.abnormalValuesObj["stalls"]}
                                                />

                                                <CycleStat
                                                    label={showGrindCycleTrends ? props.trendJackCheckbox : "Jack"}
                                                    value={getRoundedNumber(selectedCycle, "jack", 0, true)}
                                                    abnormalValues={props.abnormalValuesObj["jack"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showGrindCycleTrends
                                                            ? props.trendNumberStallsCheckbox
                                                            : "Number of Stalls"
                                                    }
                                                    value={getRoundedNumber(selectedCycle, "stalls", 0, true)}
                                                    abnormalValues={props.abnormalValuesObj["stalls"]}
                                                />
                                                <CycleStat
                                                    label={showGrindCycleTrends ? props.trendTriesCheckbox : "Tries"}
                                                    value={getRoundedNumber(selectedCycle, "attempts", 0, true)}
                                                    abnormalValues={props.abnormalValuesObj["tries"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showGrindCycleTrends
                                                            ? props.trendForwardDurationCheckbox
                                                            : "Forward Duration"
                                                    }
                                                    value={calculateDurations(
                                                        selectedCycle,
                                                        "forward_duration",
                                                        true,
                                                        true
                                                    ).toString()}
                                                    abnormalValues={props.abnormalValuesObj["forward_duration"]}
                                                />
                                                <CycleStat
                                                    label={
                                                        showGrindCycleTrends
                                                            ? props.trendReverseDurationCheckbox
                                                            : "Reverse Duration"
                                                    }
                                                    value={calculateDurations(
                                                        selectedCycle,
                                                        "reverse_duration",
                                                        true,
                                                        true
                                                    ).toString()}
                                                    abnormalValues={props.abnormalValuesObj["reverse_duration"]}
                                                />
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}
                            {props.selectedCycle["cycle_id"] && (
                                <Grid item xs={12} md={md} lg={lg}>
                                    <PortalCycleInformation portalCycleid={selectedCycle["cycle_id"]} />
                                </Grid>
                            )}
                            <Grid item xs={12} md={md} lg={lg}>
                                <Accordion variant="outlined" sx={{ my: 1 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="duty-cycle-accordion"
                                        id="duty-cycle-accordion-header">
                                        <Typography variant="h6" color="secondary">
                                            {t("Duty Cycle")}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List disablePadding>
                                            <CycleStat
                                                label={
                                                    showSharedCycleTrends
                                                        ? props.trendDutyCycleAvgCheckbox
                                                        : "Duty Cycle Avg"
                                                }
                                                value={getRoundedNumber(selectedCycle, "duty_cycle_average")}
                                            />
                                            <CycleStat
                                                label={
                                                    showSharedCycleTrends
                                                        ? props.trendDutyCycleStartCheckbox
                                                        : "Duty Cycle Start"
                                                }
                                                value={getRoundedNumber(selectedCycle, "duty_cycle_start")}
                                            />
                                            <CycleStat
                                                label={
                                                    showSharedCycleTrends
                                                        ? props.trendDutyCycleEndCheckbox
                                                        : "Duty Cycle End"
                                                }
                                                value={getRoundedNumber(selectedCycle, "duty_cycle_end")}
                                            />
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            {props.fromBurninTest === false && selectedCycle["steamcycleparams"] && (
                                <Grid item xs={12} md={md} lg={lg}>
                                    <CycleParameters cycleParameters={selectedCycle["steamcycleparams"]} />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Card>
            )}
        </Box>
    );
};

CycleStats.defaultProps = {
    selectedCycle: {},
    trendCycleOutcomeCheckbox: <React.Fragment />,
    trendCycleDurationCheckbox: <React.Fragment />,
    trendCycleScoreCheckbox: <React.Fragment />,
    trendPumpdownDurationCheckbox: <React.Fragment />,
    trendDrainingTimeCheckbox: <React.Fragment />,
    trendHeatUpTimeCheckbox: <React.Fragment />,
    trendPumpdownTimeoutsCheckbox: <React.Fragment />,
    trendTPDiffCheckbox: <React.Fragment />,
    trendTVStartCheckbox: <React.Fragment />,
    trendTVMaxCheckbox: <React.Fragment />,
    trendTRAverageCheckbox: <React.Fragment />,
    trendTvAvgHoldCheckbox: <React.Fragment />,
    trendPVMinCheckbox: <React.Fragment />,
    trendPVMaxCheckbox: <React.Fragment />,
    trendPVAverageCheckbox: <React.Fragment />,
    trendFlowSecondsCheckbox: <React.Fragment />,
    trendPVStartCheckbox: <React.Fragment />,
    cycleTrendOptions: <React.Fragment />,
    trendStallsCheckbox: <React.Fragment />,
    trendJackCheckbox: <React.Fragment />,
    trendNumberStallsCheckbox: <React.Fragment />,
    trendTriesCheckbox: <React.Fragment />,
    trendForwardDurationCheckbox: <React.Fragment />,
    trendReverseDurationCheckbox: <React.Fragment />,
    trendGrindDurationCheckbox: <React.Fragment />,
    trendDutyCycleAvgCheckbox: <React.Fragment />,
    trendDutyCycleStartCheckbox: <React.Fragment />,
    trendDutyCycleEndCheckbox: <React.Fragment />,
    trendHeatUpRateDifferenceCheckbox: <React.Fragment />,
    showSteamCycleTrends: false,
    showGrindCycleTrends: false,
    showSharedCycleTrends: false,
    fromBurninTest: false,
    burninTest7Number: null,
};

CycleStats.propTypes = {
    selectedCycle: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    trendCycleOutcomeCheckbox: PropTypes.element,
    trendCycleDurationCheckbox: PropTypes.element,
    trendCycleScoreCheckbox: PropTypes.element,
    trendPumpdownDurationCheckbox: PropTypes.element,
    trendDrainingTimeCheckbox: PropTypes.element,
    trendHeatUpTimeCheckbox: PropTypes.element,
    trendPumpdownTimeoutsCheckbox: PropTypes.element,
    trendTPDiffCheckbox: PropTypes.element,
    trendTVStartCheckbox: PropTypes.element,
    trendTVMaxCheckbox: PropTypes.element,
    trendTRAverageCheckbox: PropTypes.element,
    trendTvAvgHoldCheckbox: PropTypes.element,
    trendPVMinCheckbox: PropTypes.element,
    trendPVMaxCheckbox: PropTypes.element,
    trendPVAverageCheckbox: PropTypes.element,
    trendFlowSecondsCheckbox: PropTypes.element,
    trendPVStartCheckbox: PropTypes.element,
    cycleTrendOptions: PropTypes.element,
    trendStallsCheckbox: PropTypes.element,
    trendJackCheckbox: PropTypes.element,
    trendNumberStallsCheckbox: PropTypes.element,
    trendTriesCheckbox: PropTypes.element,
    trendForwardDurationCheckbox: PropTypes.element,
    trendReverseDurationCheckbox: PropTypes.element,
    trendGrindDurationCheckbox: PropTypes.element,
    trendDutyCycleAvgCheckbox: PropTypes.element,
    trendDutyCycleStartCheckbox: PropTypes.element,
    trendDutyCycleEndCheckbox: PropTypes.element,
    trendHeatUpRateDifferenceCheckbox: PropTypes.element,
    showSteamCycleTrends: PropTypes.bool,
    showGrindCycleTrends: PropTypes.bool,
    showSharedCycleTrends: PropTypes.bool,
    fromBurninTest: PropTypes.bool,
    burninTest7Number: PropTypes.number,
};

export default CycleStats;
