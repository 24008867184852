import { configureStore } from "@reduxjs/toolkit";
import bitReducer from "./features/CycleMetric/BurninContainer/bitSlice";
import metricReducer from "./features/CycleMetric/metricSlice";
import distributorReducer from "./features/Distributor/distributorSlice";
import deviceDashboardReducer from "./features/Device/DeviceDashboard/deviceDashboardSlice";
import customerReducer from "./features/Customer/customerSlice";
import userReducer from "./features/User/userSlice";
import createUserReducer from "./features/User/Create/createUserSlice";
import editUserReducer from "./features/User/Edit/editUserSlice";
import editOperatorReducer from "./features/User/Edit/editOperatorSlice";
import siteAdminReducer from "./features/Customer/SiteAdmins/siteAdminSlice";
import facilityAdminReducer from "./features/Customer/FacitlityAdmins/facilityAdminSlice";
import operatorReducer from "./features/Customer/Operators/operatorSlice";
import deviceInfoReducer from "./features/Device/DeviceInformation/deviceInfoSlice";
import { config } from "./portal-environment";

export const store = configureStore({
    reducer: {
        bit: bitReducer,
        customer: customerReducer,
        user: userReducer,
        metric: metricReducer,
        distributor: distributorReducer,
        devicedashboard: deviceDashboardReducer,
        createUser: createUserReducer,
        editUser: editUserReducer,
        editOperator: editOperatorReducer,
        siteAdmin: siteAdminReducer,
        facilityAdmin: facilityAdminReducer,
        operator: operatorReducer,
        deviceInfo: deviceInfoReducer,
    },
    devTools: config.env !== "production",
});
