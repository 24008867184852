/*
* A library for Float label inputs to be used with React Hook Form
*
* Copyright (C) 2018, 2019, 2020 Sterilis Medical Inc., all rights reserved.
*/

import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {Dropdown} from "semantic-ui-react";
import {Controller, useFormContext} from "react-hook-form";
import {ConditionalWrap} from './helpers'

export const FloatLabelInput = props => {
  const {register, errors, watch} = useFormContext();
  // if we want to fix the float labels with some js, the following works
  const valueWatch = watch(props['name']);
  return <div className={`form-group ${props['containerClass']}`}>
    <label
      className={`${(valueWatch && valueWatch.length > 0) ? 'float-label-float' : ''}  float-label-anchor
      ${errors[props['name']] ? 'label-validation-error' : ''}`}
      htmlFor={props['name']}>
      <React.Fragment>
        {props['labelText']}
        {/*Only show message if showErrorMessage prop is true and if errors[props['name']] exists*/}
        {props['showErrorMessage'] && errors[props['name']] && errors[props['name']]['message']
        // and if there is a message, concat the message onto the label prefixed w/ a -
        && ` - ${errors[props['name']]['message']}`}
      </React.Fragment>
    </label>
    <input
      ref={register(props['registerParams'])}
      name={props['name']}
      type={props['type']}
      id={props['name']}
      className={`form-control ${props['inputClass']} ${errors[props['name']] ? 'validation-error-background' : ''}`}
    />
  </div>
};

FloatLabelInput.defaultProps = {
  registerParams: {},
  type: 'text',
  required: true,
  showErrorMessage: true,
  name: '',
  inputClass: '',
  labelText: '',
  containerClass: '',
};

FloatLabelInput.propTypes = {
  registerParams: PropTypes.object,
  type: PropTypes.string,
  inputClass: PropTypes.string,
  required: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  name: PropTypes.string,
  labelText: PropTypes.string,
  containerClass: PropTypes.string,

};


export const FloatLabelDropdown = props => {
  const {errors, setValue} = useFormContext();

  useEffect(() => {
    if(props['useFirstItemAsDefault']){
      if(props.options.length > 0){
        setValue(props['name'], props.options[0].value)
      }
    }
  }, [JSON.stringify(props.options)]);

  return <div className={`form-group ${props.containerClass}`}>
    <label
      className={`${props.boldHeader ? '' : 'fake-float'} ${errors[props['name']] ? 'label-validation-error' : ''}`}>
      <ConditionalWrap
        condition={props.boldHeader}
        wrap={children => (
          <h4 className={`device-filter-header ster-blue ${errors[props['name']] ? 'label-validation-error' : ''}`}>
            {children}
          </h4>)
        }
      >
        {props['labelText']}
        {/*Only show message if showErrorMessage prop is true and if errors[props['name']] exists*/}
        {props['showErrorMessage'] && errors[props['name']] && errors[props['name']]['message']
        // and if there is a message, concat the message onto the label prefixed w/ a -
        && ` - ${errors[props['name']]['message']}`}
      </ConditionalWrap>
    </label>
    <Controller
      render={(dropdownProps) => {
        return <Dropdown
          {...dropdownProps}
          onChange={(event, data) => {
            props.onChangeCallback(data);
            setValue(props['name'], data['value']);
          }}
          className={`full-width ${props['inputClass']} ${errors[props['name']]
            ? 'validation-error-background' : ''}`}
          multiple={props['multiple']}
          selection
          options={props['options']}
          loading={props['isLoading']}
          disabled={props['isDisabled']}
          search={props.enableCustomSearchFunction ? props.customSearchFunction : true}
          selectOnBlur={props['selectOnBlur']}
        />
      }}
      name={props['name']}
      rules={props['registerParams']}
      defaultValue={null}
    />
  </div>
};

FloatLabelDropdown.defaultProps = {
  registerParams: {},
  name: '',
  labelText: '',
  inputClass: '',
  containerClass: '',
  options: [],
  errors: {},
  showErrorMessage: true,
  useFirstItemAsDefault: false,
  // We use a boolean here to dictate if a custom search function should be applied over just using the presence of
  // the function (e.g. onChangeCallback) because we don't want to override the default search functionality, and
  // we don't want to mimic the default search functionality in the defaultProp for customSearchFunction
  enableCustomSearchFunction: false,
  isLoading: false,
  isDisabled: false,
  boldHeader: false,
  defaultValue: null,
  onChangeCallback: () => {
  },
  customSearchFunction: () => {
  },
};

FloatLabelDropdown.propTypes = {
  registerParams: PropTypes.object,
  name: PropTypes.string,
  inputClass: PropTypes.string,
  containerClass: PropTypes.string,
  labelText: PropTypes.string,
  defaultValue: PropTypes.number,
  options: PropTypes.array,
  errors: PropTypes.object,
  onChangeCallback: PropTypes.func,
  customSearchFunction: PropTypes.func,
  showErrorMessage: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  boldHeader: PropTypes.bool,
  enableCustomSearchFunction: PropTypes.bool,
  useFirstItemAsDefault: PropTypes.bool,

};


export const FloatLabelHookDropdown = props => {
  return <div className="form-group">
    <label
      className={`fake-float`}>
      <React.Fragment>
        {props['labelText']}
      </React.Fragment>
    </label>
    <Dropdown
      onChange={(event, data) => props.onChangeCallback(event, data)}
      className={`full-width`}
      search={props.enableCustomSearchFunction ? props.customSearchFunction : true}
      selection
      value={props['value']}
      options={props['options']}
    />
  </div>
};

FloatLabelHookDropdown.defaultProps = {
  name: '',
  labelText: '',
  inputClass: '',
  options: [],
  useFirstItemAsDefault: false,
  // We use a boolean here to dictate if a custom search function should be applied over just using the presence of
  // the function (e.g. onChangeCallback) because we don't want to override the default search functionality, and
  // we don't want to mimic the default search functionality in the defaultProp for customSearchFunction
  enableCustomSearchFunction: false,
  defaultValue: null,
  onChangeCallback: () => {
  },
  customSearchFunction: () => {
  },
};

FloatLabelHookDropdown.propTypes = {
  name: PropTypes.string,
  inputClass: PropTypes.string,
  labelText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.array,
  onChangeCallback: PropTypes.func,
  customSearchFunction: PropTypes.func,
  enableCustomSearchFunction: PropTypes.bool,
  useFirstItemAsDefault: PropTypes.bool,

};
