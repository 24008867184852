import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDevice } from "src/features/Device/DeviceInformation/deviceInfoSlice";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

const DeviceAutoComplete = (props) => {
    // Sample data for options
    const { deviceOptions } = props;
    const { t } = useTranslation("translations");
    const dispatch = useDispatch();

    const { selectedDevice } = useSelector((store) => store.deviceInfo);
    const loading = useSelector((store) => store.deviceInfo.loading.dropdown);

    return (
        <Autocomplete
            value={selectedDevice}
            onChange={(event, newValue) => dispatch(setSelectedDevice(newValue))}
            options={deviceOptions}
            getOptionLabel={(option) => option.text}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("Device Selection")}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default DeviceAutoComplete;
