import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = forwardRef(
    (
        {
            children,
            content,
            contentClass,
            darkTitle,
            secondary,
            avatar,
            sx = {},
            contentSX = {},
            title,
            titleProps,
            titleVariant,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        const borderColor = theme.palette.divider;

        return (
            <Card
                ref={ref}
                sx={{
                    border: "1px solid",
                    borderColor: borderColor,
                    borderRadius: 4,
                    ":hover": {
                        boxShadow: "0 2px 14px 0 rgb(32 40 45 / 8%)",
                    },
                    ...sx,
                }}
                {...others}>
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader
                        sx={{ p: 2.5 }}
                        title={
                            <Typography {...titleProps} variant={titleVariant || "h6"}>
                                {title}
                            </Typography>
                        }
                        avatar={avatar}
                        action={secondary}
                    />
                )}
                {darkTitle && title && (
                    <CardHeader
                        sx={{ p: 2.5 }}
                        title={
                            <Typography {...titleProps} variant="h6">
                                {title}
                            </Typography>
                        }
                        avatar={avatar}
                        action={secondary}
                    />
                )}

                {/* content & header divider */}
                {title && (
                    <Divider
                        sx={{
                            opacity: 1,
                            borderColor: borderColor,
                        }}
                    />
                )}
                {/* card content */}
                {content && (
                    <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ""}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

SubCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    sx: PropTypes.object,
    contentSX: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
};

SubCard.defaultProps = {
    content: true,
};

export default SubCard;
