/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
// import {composeHoc} from "../../helpers/helpers";
import AuthService from "../AuthService";
import {Button} from "semantic-ui-react";
import debounce from "lodash/debounce";
import {toast} from "react-toastify";
import SimpleLoader from "../SimpleLoader";

const Auth = new AuthService();

class ChangeUserPassword extends React.Component {
  state = {
    isLoading: false,
    oldPassword: '',
    newPassword: '',
    newPasswordAgain: '',
  };

  notifyPasswordSuccess = () => toast(`Successfully changed your password. Please log back in with your new password.`,
    {
      type: toast.TYPE.DEFAULT,
      autoClose: 5000,
      onClose: () => {
        window.location.href = '/login';
      }
    });
  notifyFailure = (err) => toast(`Failed to change password. Reason: ${err}`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  notifyGenericFailure = () => toast(`Failed to complete request. Please refresh this page or try again later.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });



  componentDidMount() {

  }

  changePassword = e => {
    e.preventDefault();
    const {
      oldPassword,
      newPassword,
    } = this.state;

    this.setState({
      isLoading: true,
    });

    Auth.fetch(`/api/change-password/`, {
      method: 'POST',
      body: JSON.stringify({
        'old_password': oldPassword,
        'new_password': newPassword,
      })
    }).then(data => {
      this.setState({
        isLoading: false,
        passwordFeedback: false,
        passwordValidationError: false,
      });

      Auth.logout();
      this.notifyPasswordSuccess();
    }).catch(err => {
      err.response.json().then(resp => {
        this.setState({
          isLoading: false,
        });
        if(resp['validation_errors']){
          this.detailPasswordFail(resp['validation_errors']);
        } else if (resp['message']) {
          this.setState({
            passwordFeedback:<p className='light-validation-error'>{resp['message']}</p>,
            passwordValidationError: true,
          });
          this.notifyFailure(resp['message']);
        } else {
          this.notifyGenericFailure();
        }
      });
    });
  };

  checkPassword = (password) => {
    // Regex for number, character, and symbol
    // const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    if (password.length >= 10) {
      this.setState({
        passwordValidationError: false,
        passwordsBeingDebounced: false
      })
    } else {
      this.shakeRequirements();
      this.setState({
        passwordValidationError: true,
        passwordsBeingDebounced: false
      })
    }
  };

  debounceCheckPassword = debounce((password) => this.checkPassword(password), 1000);

  checkEqualPasswords = () => {
    const {
      newPassword,
      newPasswordAgain
    } = this.state;
    if (newPassword === newPasswordAgain) {
      this.setState({
        passwordsNotEqualError: false,
        passwordsBeingDebounced: false
      })
    } else {
      this.shakeEqual();
      this.setState({
        passwordsNotEqualError: true,
        passwordsBeingDebounced: false
      })
    }
  };

  debounceCheckEqualPasswords = debounce((password) => this.checkEqualPasswords(password), 1000);

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    this.setState({
      passwordsBeingDebounced: true
    });
    if (value) {
      this.debounceCheckEqualPasswords();
      this.debounceCheckPassword(value);
    } else { //when there is no password present, remove invalidation
      this.setState({
        passwordValidationError: false
      })
    }
    this.setState({[name]: value});
  };

  detailPasswordFail = (passwordFails) => {
    const passwordFeedback = passwordFails.map((fail) => {
      return <p className='light-validation-error'>{fail}</p>
    });
    this.shakeRequirements();
    this.setState({passwordFeedback})
  };

  shakeEqual = () => {
    this.setState({
      setEqualShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setEqualShake: false,
        })
      }, 1000)
    })
  };

  shakeRequirements = () => {
    this.setState({
      setRequirementsShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setRequirementsShake: false,
        })
      }, 1000)
    })
  };

  render() {
    const {
      isLoading,
      passwordValidationError,
      checkingPassword,
      passwordFeedback,
      setRequirementsShake,
      passwordsNotEqualError,
      setEqualShake,

    } = this.state;
    const {
      t
    } = this.props;
    return (
      <form id="editPasswordForm" onSubmit={this.changePassword}>
        {isLoading ? (
          <SimpleLoader/>
        ) : null}
        <div className='medium-font flex-dir-column'>
          <p className={'' + (passwordValidationError ? ' light-validation-error ' : '') + (setRequirementsShake ? ' shake ' : '')}>
            {(this.state.newPassword.length < 10 || this.state.newPasswordAgain.length < 10) &&
                        t('Passwords must be at least ten characters long')}</p>

          {
            passwordsNotEqualError ? (
              <p className={"light-validation-error " + (setEqualShake ? ' shake ' : '')}>
                {t("The two new passwords do not match")}.
              </p>
            ) : null
          }
          {
            passwordFeedback && passwordFeedback
          }
        </div>

        <div className='medium-margin-top'>
          <div className="form-group">
            <input value={this.state.oldPassword}
                   name='oldPassword'
                   onChange={this.handleChange}
                   type="password"
                   id="oldPasswordInput"
                   className="form-control"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="oldPasswordInput">{t('Old Password')}</label>
          </div>
          <div className="form-group">
            <input value={this.state.newPassword}
                   name='newPassword'
                   onChange={this.handleChange}
                   type="password"
                   id="newPasswordInput"
                   className="form-control"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="newPasswordInput">{t('New Password')}</label>
          </div>
          <div className="form-group">
            <input value={this.state.newPasswordAgain}
                   name='newPasswordAgain'
                   onChange={this.handleChange}
                   type="password"
                   id="newPasswordAgainInput"
                   className="form-control"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="newPasswordAgainInput">{t('New Password Again')}</label>
          </div>
        </div>

        <Button
          id='changePasswordBtn'
          className='submit-machine'
          disabled={passwordValidationError || checkingPassword || passwordsNotEqualError}
          form='editPasswordForm'
          value="Submit"
          type="submit"
          primary>
          {t('Submit changes')}
        </Button>
      </form>
    )
  }
}

export default translate('translations')(ChangeUserPassword);
