import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "src/components/AuthService";
import { alphabeticalSort, isGroupSterilisOrDist } from "src/components/library/helpers";

const auth = new AuthService();

const initialState = {
    id: null,
    loading: false,
    customers: [],
    customerNames: {},
    activeTab: null,
    devices: [],
    siteAdmins: [],
    facilityAdmins: [],
    facilities: [],
    facilityNames: {},
    customerDropdown: [],
    facilityDropdown: [],
    originCustomerDropdown: [],
    originFacilityDropdown: [],
    selectedCustomer: null,
    customerPins: {},
    machinesByCustomer: {},
    customerFacilities: {},
    customerFacilitiesAndPins: {},
};

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setSelectedCustomer(state, action) {
            state.selectedCustomer = action.payload;
        },
        setMachinesByCustomer(state, action) {
            state.machinesByCustomer = action.payload;
        },
        setCustomerFacilities(state, action) {
            state.customerFacilities = action.payload;
        },
        setCustomerFacilitiesAndPins(state, action) {
            state.customerFacilitiesAndPins = action.payload;
        },
        setOriginCustomerDropdown(state, action) {
            state.originCustomerDropdown = action.payload;
        },
        setOriginFacilityDropdown(state, action) {
            state.originFacilityDropdown = action.payload;
        },
        setActiveTab(state, action) {
            switch (action.payload) {
                case 0:
                    state.activeTab = "Devices";
                    break;
                case 1:
                    state.activeTab = "SiteAdmins";
                    break;
                case 2:
                    state.activeTab = "FacilityAdmins";
                    break;
                case 3:
                    state.activeTab = "Operators";
                    break;
                case 4:
                    state.activeTab = "Documents";
                    break;
                default:
                    break;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomers.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.loading = false;
                state.customers = initialState.customers;
                state.customerDropdown = initialState.customerDropdown;

                action.payload.forEach((customer) => {
                    state.customers.push(customer);
                    state.customerDropdown.push({
                        id: customer.id,
                        name: customer.customer_name,
                        dist_name: customer?.distributor.distributor_name,
                        dist_id: customer?.distributor.id,
                    });

                    if ("facilities" in customer) {
                        customer?.facilities.forEach((facility) => {
                            state.facilities.push({ ...facility, customer: { id: customer.id } });
                        });
                    }
                });
                state.customerDropdown = state.customerDropdown.sort((a, b) => alphabeticalSort(a, b, "name"));
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchCustomerMachines.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchCustomerMachines.fulfilled, (state, action) => {
                state.customers = initialState.customers;
                state.facilities = initialState.facilities;
                state.customerDropdown = initialState.customerDropdown;
                state.loading = false;

                action.payload.sort((a, b) => {
                    const tempA = a.customer_name.toUpperCase();
                    const tempB = b.customer_name.toUpperCase();
                    if (tempA < tempB) {
                        return -1;
                    }
                    if (tempA > tempB) {
                        return 1;
                    }
                    return 0;
                });

                action.payload.forEach((customer) => {
                    state.customerNames[customer.id] = customer.customer_name;
                    state.customers.push(customer);
                    state.customerDropdown.push({
                        id: customer.id,
                        value: customer.id,
                        name: customer.customer_name,
                        dist_name: customer?.distributor.distributor_name,
                        dist_id: customer?.distributor.id,
                    });

                    let hasDevices = false;
                    let custPins = new Set();

                    customer?.facilities.forEach((facility) => {
                        state.facilities.push({ ...facility, customer: { id: customer.id } });
                        state.facilityNames[facility.id] = facility.facility_name;
                        state.facilityDropdown.push({
                            id: facility.id,
                            value: facility.id,
                            name: facility.facility_name,
                            customer_id: customer.id,
                        });

                        facility?.device_configs.filter((config) => {
                            if (!config.deleted) {
                                hasDevices = true;
                                state.devices.push(config);
                                config?.operators.forEach((operator) => {
                                    custPins.add(operator.pin);
                                });
                                config["facility_schedules"] = facility?.facility_schedules;
                                return config;
                            } else {
                                return null;
                            }
                        });
                    });
                    if (custPins.size > 0) {
                        state.customerPins[customer.id] = Array.from(custPins);
                    }
                });
            })
            .addCase(fetchCustomerMachines.rejected, (state, action) => {
                state.loading = false;
            })

            .addCase(fetchFacilities.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFacilities.fulfilled, (state, action) => {
                state.facilities = action.payload;
                state.loading = false;
            })
            .addCase(fetchFacilities.rejected, (state, action) => {
                state.loading = false;
                state.facilities = [];
            });
    },
});

export const fetchCustomers = createAsyncThunk("customer/fetchCustomers", async (userGroup) => {
    const response = await auth.fetch(`/api/customer/`);
    return response;
});

export const fetchCustomerMachines = createAsyncThunk("customer/fetchCustomerMachines", async (userGroup) => {
    const response = await auth.fetch(`/api/customer-machines/`);
    return response;
});

export const fetchFacilities = createAsyncThunk("facility/fetchFacilities", async () => {
    const response = await auth.fetch(`/api/facility/`);
    return response;
});

export const selectFacilitiesByCustomer = (state, customerId) => {
    return customerId ? state.facilities.filter((facility) => facility?.customer?.id === customerId) : [];
};

export const selectFacilitiesDropdownByCustomer = (state, customerId) => {
    return customerId
        ? state.facilities
              .filter((facility) => facility?.customer?.id === customerId)
              .map((facility) => {
                  return { id: facility.id, value: facility.id, label: facility.facility_name };
              })
        : [];
};

export const {
    setSelectedCustomer,
    setMachinesByCustomer,
    setCustomerFacilities,
    setCustomerFacilitiesAndPins,
    setOriginCustomerDropdown,
    setOriginFacilityDropdown,
    setActiveTab,
} = customerSlice.actions;

export default customerSlice.reducer;
