/*
*  Wrapper component for the Sterilis Dashboard
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {composeHoc} from "../library/helpers";
import DashboardMain from "./DashboardMain";
import { withTranslation as translate} from "react-i18next";
import withAuth from "../withAuth";

class DashboardWrapper extends React.Component{
  render() {
    return <DashboardMain {...this.props}/>
  }
}

export default composeHoc(
  translate('translations'),
  withAuth(['SterilisSuperUsers', 'CustomerPortalUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs',
    'FactoryWorkers', 'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs', 'SterilisWasteTypeAdmin', 'FacilityAdmin'
  ]))(DashboardWrapper);
