import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const Stats = (props) => {
    const theme = useTheme();
    const { t } = useTranslation("translations");
    const { loading, value, label } = props;
    return (
        <Paper variant="outlined" sx={{ padding: theme.spacing(2, 3) }}>
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", pt: 1 }}>
                    <Skeleton variant="circular" height={45} width={45} />
                </Box>
            ) : (
                <Typography variant="h3" fontWeight={"bold"} color="primary" align="center">
                    {value}
                </Typography>
            )}
            <Typography variant="subtitle1" fontWeight={600} color={theme.palette.text.disabled} align="center">
                {t(label)}
            </Typography>
        </Paper>
    );
};

Stats.propTypes = {
    value: PropTypes.number,
    label: PropTypes.string,
    loading: PropTypes.bool,
};

export default Stats;
