import { Box, CardContent, CircularProgress, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CycleSummaryChart from "./CycleSummaryChart";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import SubCard from "src/components/material/Cards/SubCard";

const CycleSummaryPanel = (props) => {
    const { t } = useTranslation("translations");
    // @ts-ignore
    const { deviceCycles, deviceTimezone } = useSelector((store) => store.devicedashboard);
    // @ts-ignore
    const loading = useSelector((store) => store.devicedashboard.loading.cycleSummary);
    const theme = useTheme();
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);

    const checkForNonZeroArray = (array) => {
        const maxVal = Math.max.apply(null, array);
        return maxVal !== 0;
    };

    useEffect(() => {
        calculateSummary(deviceCycles);
    }, [deviceCycles]);

    const calculateSummary = (deviceCycles) => {
        const { timezone } = props;
        const totalArray = [];
        const unknownArray = []; // blank
        const dialysisArray = []; // Dialysis
        const mixedArray = []; // Mixed
        const redBagArray = []; // Red Bags
        const sharpsArray = []; // Sharps
        const yellowBagArray = []; // Yellow bags
        const cannabisArray = []; // Cannabis Microbial Waste
        const cannabisProductArray = []; // Cannabis Product Waste
        const liquidsProductArray = []; // Liquids
        const softwasteProductArray = []; // Soft Lab Waste
        const xAxisDateArray = []; // Date array for x axis
        const yellowBagNoGrindArray = []; // Yellow bags no grind
        const glassSlidesArray = []; // Glass Slides
        const ungrindableArray = []; // Ungrindable Waste

        for (const cycle in deviceCycles) {
            const date = DateTime.fromISO(deviceCycles[cycle].time_started, { zone: deviceTimezone }).toISODate();
            if (!xAxisDateArray.includes(date)) {
                xAxisDateArray.push(date);
            }
            const indexOfRecentPush = xAxisDateArray.indexOf(date);

            let totalCycleCount = totalArray[indexOfRecentPush];
            totalCycleCount = totalCycleCount ? ++totalCycleCount : 1;
            totalArray[indexOfRecentPush] = totalCycleCount;

            if (deviceCycles[cycle]["waste_type"] === "dialysis") {
                let currentCycleCount = dialysisArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                dialysisArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "mixed") {
                let currentCycleCount = mixedArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                mixedArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "red_bags") {
                let currentCycleCount = redBagArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                redBagArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "sharps") {
                let currentCycleCount = sharpsArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                sharpsArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "unknown") {
                let currentCycleCount = unknownArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                unknownArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "yellow_bags") {
                let currentCycleCount = yellowBagArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                yellowBagArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "yellow_bags_nogrind") {
                let currentCycleCount = yellowBagNoGrindArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                yellowBagNoGrindArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "cannabis") {
                let currentCycleCount = cannabisArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                cannabisArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "cannabis_product") {
                let currentCycleCount = cannabisProductArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                cannabisProductArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "liquids") {
                let currentCycleCount = liquidsProductArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                liquidsProductArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "soft_lab_waste") {
                let currentCycleCount = softwasteProductArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                softwasteProductArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "glass_slides") {
                let currentCycleCount = glassSlidesArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                glassSlidesArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "ungrindable_waste") {
                let currentCycleCount = ungrindableArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
                ungrindableArray[indexOfRecentPush] = currentCycleCount;
            }
        }

        //Since Highcharts requires array primitives and we need our X axis array and our Y axis arrays to line up index wise
        //We need to fill every Y axis array index with 0s where they do not currently have a value
        //The or syntax is just shorthand for if(!sharpsArray[day])
        for (let day in xAxisDateArray) {
            totalArray[day] = totalArray[day] || 0;
            sharpsArray[day] = sharpsArray[day] || 0;
            redBagArray[day] = redBagArray[day] || 0;
            mixedArray[day] = mixedArray[day] || 0;
            dialysisArray[day] = dialysisArray[day] || 0;
            unknownArray[day] = unknownArray[day] || 0;
            yellowBagArray[day] = yellowBagArray[day] || 0;
            yellowBagNoGrindArray[day] = yellowBagNoGrindArray[day] || 0;
            cannabisArray[day] = cannabisArray[day] || 0;
            cannabisProductArray[day] = cannabisProductArray[day] || 0;
            liquidsProductArray[day] = liquidsProductArray[day] || 0;
            softwasteProductArray[day] = softwasteProductArray[day] || 0;
            glassSlidesArray[day] = glassSlidesArray[day] || 0;
            ungrindableArray[day] = ungrindableArray[day] || 0;
        }

        const cycleSummarySeries = [];

        if (checkForNonZeroArray(totalArray)) {
            cycleSummarySeries.push({
                name: t("Total Cycles"),
                type: "column",
                color: theme.palette.waste.unknown,
                total: deviceCycles.length,
                data: totalArray.slice(0),
                visible: false,
            });
        }

        if (checkForNonZeroArray(sharpsArray)) {
            cycleSummarySeries.push({
                name: t("Sharps"),
                type: "column",
                color: theme.palette.waste.sharps,
                total: sharpsArray.reduce((acc, val) => acc + val, 0),
                data: sharpsArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("sharps cycles"),
                },
            });
        }
        if (checkForNonZeroArray(redBagArray)) {
            cycleSummarySeries.push({
                name: t("Red Bags"),
                type: "column",
                color: theme.palette.waste.redBags,
                total: redBagArray.reduce((acc, val) => acc + val, 0),
                data: redBagArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("red bag cycles"),
                },
            });
        }
        if (checkForNonZeroArray(dialysisArray)) {
            cycleSummarySeries.push({
                name: t("Dialysis"),
                type: "column",
                color: theme.palette.waste.dialysis,
                total: dialysisArray.reduce((acc, val) => acc + val, 0),
                data: dialysisArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("dialysis cycles"),
                },
            });
        }
        if (checkForNonZeroArray(mixedArray)) {
            cycleSummarySeries.push({
                name: t("Mixed"),
                type: "column",
                color: theme.palette.waste.mixed,
                total: mixedArray.reduce((acc, val) => acc + val, 0),
                data: mixedArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("mixed cycles"),
                },
            });
        }
        if (checkForNonZeroArray(unknownArray)) {
            cycleSummarySeries.push({
                name: t("Unknown"),
                type: "column",
                color: theme.palette.waste.unknown,
                total: unknownArray.reduce((acc, val) => acc + val, 0),
                data: unknownArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("unknown cycles"),
                },
            });
        }
        if (checkForNonZeroArray(yellowBagArray)) {
            cycleSummarySeries.push({
                name: t("Yellow Bags"),
                type: "column",
                color: theme.palette.waste.yellowBags,
                total: yellowBagArray.reduce((acc, val) => acc + val, 0),
                data: yellowBagArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("yellow bag cycles"),
                },
            });
        }
        if (checkForNonZeroArray(yellowBagNoGrindArray)) {
            cycleSummarySeries.push({
                name: t("Yellow Bags No Grind"),
                type: "column",
                color: theme.palette.waste.yellowBagsNoGrind,
                total: yellowBagNoGrindArray.reduce((acc, val) => acc + val, 0),
                data: yellowBagNoGrindArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("yellow bag no grind cycles"),
                },
            });
        }
        if (checkForNonZeroArray(cannabisArray)) {
            cycleSummarySeries.push({
                name: t("Cannabis Microbial Waste"),
                type: "column",
                color: theme.palette.waste.cannabisMicrobial,
                total: cannabisArray.reduce((acc, val) => acc + val, 0),
                data: cannabisArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("cannabis microbial cycles"),
                },
            });
        }

        if (checkForNonZeroArray(cannabisProductArray)) {
            cycleSummarySeries.push({
                name: t("Cannabis Product Waste"),
                type: "column",
                color: theme.palette.waste.cannabisProduct,
                total: cannabisProductArray.reduce((acc, val) => acc + val, 0),
                data: cannabisProductArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("cannabis product cycles"),
                },
            });
        }

        if (checkForNonZeroArray(liquidsProductArray)) {
            cycleSummarySeries.push({
                name: t("Liquids"),
                type: "column",
                color: theme.palette.waste.liquids,
                total: liquidsProductArray.reduce((acc, val) => acc + val, 0),
                data: liquidsProductArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("liquid cycles"),
                },
            });
        }

        if (checkForNonZeroArray(softwasteProductArray)) {
            cycleSummarySeries.push({
                name: t("Soft Lab Waste"),
                type: "column",
                color: theme.palette.waste.softlab,
                total: softwasteProductArray.reduce((acc, val) => acc + val, 0),
                data: softwasteProductArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("soft lab cycles"),
                },
            });
        }

        if (checkForNonZeroArray(glassSlidesArray)) {
            cycleSummarySeries.push({
                name: t("Glass Slides"),
                type: "column",
                color: theme.palette.waste.glassSlides,
                total: glassSlidesArray.reduce((acc, val) => acc + val, 0),
                data: glassSlidesArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("glass slides cycles"),
                },
            });
        }

        if (checkForNonZeroArray(ungrindableArray)) {
            cycleSummarySeries.push({
                name: t("Ungrindable Waste"),
                type: "column",
                color: theme.palette.waste.ungrindable,
                total: ungrindableArray.reduce((acc, val) => acc + val, 0),
                data: ungrindableArray.slice(0),
                tooltip: {
                    valueSuffix: " " + t("ungrindable cycles"),
                },
            });
        }

        const options = {
            chart: {
                type: "column",
                zoomType: "x",
            },
            credits: {
                enabled: false,
            },
            title: {
                text: t("Cycle Summary"),
            },
            // subtitle: {
            //   text: deviceFacility
            // },
            xAxis: [
                {
                    categories: xAxisDateArray,
                    reversed: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    title: {
                        text: t("Total Cycles"),
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
            },
            plotOptions: {
                column: {
                    stacking: "normal",
                    borderRadius: theme.shape.borderRadius,
                },
                series: {
                    events: {
                        legendItemClick: function (event) {
                            if (event.target.name === t("Total Cycles")) {
                                return false;
                            }
                        },
                    },
                },
            },
            legend: {
                labelFormatter: function () {
                    if (this.name === t("Total Cycles")) {
                        return `<b style="color:black;"> ${this.name} : <b/> ${Math.round(this.options.total)} <br/>`;
                    } else {
                        return this.name + ": " + this.options.total;
                    }
                },
            },
            series: cycleSummarySeries,
        };
        setSeries(cycleSummarySeries);
        setOptions(options);
    };

    return (
        <SubCard sx={{ width: "100%" }}>
            <CardContent>
                {loading ? (
                    <Box
                        sx={{
                            p: 20,
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                        }}>
                        <CircularProgress color="secondary" size={60} />
                    </Box>
                ) : (
                    <CycleSummaryChart options={options} />
                )}
            </CardContent>
        </SubCard>
    );
};

export default CycleSummaryPanel;
