/*
 *  Main component for the Sterilis Summary Dashboard
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { withTranslation as translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";
import AuthService from "../AuthService";
import { alphabeticalSort, isGroupSterilisOrDist, composeHoc } from "../library/helpers";
import * as Sentry from "@sentry/browser";
import SummaryDashboard from "./SummaryDashboard";
import LoadingSummaryDashboard from "./LoadingSummaryDashboard";
import SummaryDashboardNoCycles from "./SummaryDashboardNoCycles";
import '../../css/summary-dashboard/SummaryDashboardMain.css';
const Auth = new AuthService();
const AbortController = window.AbortController;

class SummaryDashboardMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      width: 0,
      height: 0,
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceTimezone: null,
      fromDate: "",
      toDate: "",
      deviceConfigID: [],
      facilityName: "",
      summaryCycles: [],
      complianceReport: false,
      comprehensiveReport: false,
      cycleTableIsExpanded: false,
      secondsUntilRefresh: 40,
      isError: false,
      abortController: new AbortController(),
      customerId: null,
      customerName: "",
      isFacility: false,
      currentPressureUnit: "PSI(a)",
      pageInactive: false,
      isAirport: false,
      biochallengeInfo: [],
      facilities: [],
      facilityID: Number(this.props.match.params["facility_id"]),
      selectedCycle: null,
      weight_unit: "",
    };
  }

  componentDidMount() {
    document.title = "Summary Dashboard";
    const { group } = this.props;

    // Sterilis people want to see Comprehensive view by default.
    // Customers want to see compliant.
    let complianceReport = false;
    let comprehensiveReport = true;
    if (isGroupSterilisOrDist(group)) {
      complianceReport = false;
      comprehensiveReport = true;
    } else {
      complianceReport = true;
      comprehensiveReport = false;
    }

    this.setState(
      {
        fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        deviceConfigID: [],
        complianceReport,
        comprehensiveReport,
      },
      () => {
        this.updateWindowDimensions();
        document.addEventListener(
          "visibilitychange",
          this.handleVisibilityChange,
          false
        );
        window.addEventListener("resize", this.updateWindowDimensions);
        this.prepareDashboard(true);
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
    this.state.abortController.abort();
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      console.log("doc was hidden");
      this.setState({
        pageInactive: true,
      });
    } else {
      console.log("doc was shown");
      this.setState({
        pageInactive: false,
      });
    }
  };

  prepareDashboard = (initialLoad) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.fetchData(initialLoad).then(() => {
          this.setState({
            isLoading: false,
          });
        });
      }
    );
  };

  /*
   *  Used to fetch fresh data, on dashboard load or on a device selection
   */
  fetchData = async (initialLoad) => {
    // if it's the initialLoad, we need to retrieve the Device dropdown from /api/customer-machines
    if (initialLoad) {
      await this.fetchCustomerInfo();
    }

    await this.fetchCustomerCycles();
    await this.fetchBiochallengeInfo();
  };

  /*
   * Used to refresh data on an existing instance of the dashboard
   */
  refreshData = async (selectedCycle) => {
    await this.fetchCustomerCycles();
    await this.fetchBiochallengeInfo();
  };

  fetchCustomerInfo = () => {
    const facilities = [];
    const customerID =
      this.state.customerId || this.props.match.params["customer_id"];
    let selectFacility = this.props.match.params["facility_id"];
    return Auth.fetch(`/api/customer-facilities/${customerID}`, {
      method: "GET",
      signal: this.state.abortController.signal,
    })
      .then((data) => {
        this.setState({
          customerId: data["id"],
          customerName: data["customer_name"],
        });
        if (this.props.match.params["facility_id"]) {
          data["facilities"].forEach((facility) => {
            if (
              facility["device_configs"].length > 0 &&
              facility["deleted"] === null
            ) {
              facilities.push({
                key: facility["id"],
                text: facility["facility_name"],
                value: facility["id"],
                customer_id: data["id"],
              });
            }

            facilities.sort((a, b) => alphabeticalSort(a, b, "text"));
            this.setState({
              facilities,
            });
            if (facility["id"] == selectFacility) {
              this.setState(
                {
                  deviceTimezone:
                    facility["region_setting"]["timezone"]["timezone"],
                  facilityName: facility["facility_name"],
                },
                () => {
                  document.title = `${this.state.facilityName} Dashboard`;
                }
              );
              facility["device_configs"].forEach((deviceConfig) => {
                if (deviceConfig["id"]) {
                  this.setState((previousState) => ({
                    deviceConfigID: [
                      ...previousState.deviceConfigID,
                      deviceConfig["id"],
                    ],
                  }));
                }
              });
            }
          });
        } else {
          data["facilities"].forEach((facility) => {
            document.title = `${this.state.customerName} Dashboard`;
            if (
              facility["device_configs"].length > 0 &&
              facility["deleted"] === null
            ) {
              facilities.push({
                key: facility["id"],
                text: facility["facility_name"],
                value: facility["id"],
                customer_id: data["id"],
              });
            }

            facilities.sort((a, b) => alphabeticalSort(a, b, "text"));
            this.setState({
              facilities,
            });

            facility["device_configs"].forEach((deviceConfig) => {
              if (deviceConfig["id"]) {
                this.setState((previousState) => ({
                  deviceConfigID: [
                    ...previousState.deviceConfigID,
                    deviceConfig["id"],
                  ],
                }));
              }
            });
          });
        }
      })
      .catch((err) => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.setState({ isLoading: false, isError: true });
        }
      });
  };

  /*
   * This function evokes the get-customer-cycles endpoint, which retrieves information about
   * the cycles the device has ran, as well as some meta information about the device & it's facility.
   */
  fetchCustomerCycles = () => {
    const customerID =
      this.state.customerId || this.props.match.params["customer_id"];
    const { fromDate, toDate, complianceReport } = this.state;

    if (this.props.match.params["facility_id"]) {
      this.setState({
        isFacility: true,
        facilityId: this.props.match.params["facility_id"],
      });

      const facilityID =
        this.state.facilityId || this.props.match.params["facility_id"];

      Auth.fetch(
        `/api/get-facility-cycles?` +
          new URLSearchParams({
            facility_id: facilityID,
            customer_id: customerID,
            // Add/Subtract 1 day so that the date range is inclusive
            from_date: moment.utc(fromDate).subtract("1", "days").format(),
            to_date: moment.utc(toDate).add("1", "days").format(),
            compliance_report: complianceReport,
            enable_date_adjustment: true,
          }),
        {
          method: "GET",
          signal: this.state.abortController.signal,
        }
      )
        .then((data) => {
          this.setState(
            {
              summaryCycles: data["facility_cycles"],
              facilityName: data["facility_metadata"]["facility_name"],
              deviceFacilityFullAddress:
                data["facility_metadata"]["device_facility_full_address"],
              customerName: data["facility_metadata"]["customer_name"],
              isAirport:
                data["facility_metadata"]["facility_type"] === "airport",
              isFacility: true,
              weight_unit: data["facility_metadata"]["weight_unit"],
            },
            () => {
              document.title = `${this.state.facilityName} Dashboard`;
            }
          );
          if (data["cycle_metadata"]["changed_from_date"]) {
            this.setState({
              fromDate: moment
                .utc(data["cycle_metadata"]["new_from_date"])
                .format("YYYY-MM-DD"),
            });
          } else {
            if (
              data["facility_cycles"].length === 0 &&
              complianceReport === true
            ) {
              this.setComprehensiveReport();
            }
          }
        })
        .catch((err) => {
          if (err.code !== 20) {
            Sentry.captureException(err);
            this.setState({ isLoading: false });
          }
        });
    } else {
      return Auth.fetch(
        `/api/get-customer-cycles?` +
          new URLSearchParams({
            customer_id: customerID,
            // Add/Subtract 1 day so that the date range is inclusive
            from_date: moment.utc(fromDate).subtract("1", "days").format(),
            to_date: moment.utc(toDate).add("1", "days").format(),
            compliance_report: complianceReport,
            enable_date_adjustment: true,
          }),
        {
          method: "GET",
          signal: this.state.abortController.signal,
        }
      )
        .then((data) => {
          this.setState(
            {
              summaryCycles: data["device_cycles"],
              deviceFacilityFullAddress:
                data["device_metadata"]["customer_name"],
              customerName: data["device_metadata"]["customer_name"],
              isAirport: data["device_metadata"]["facility_type"] === "airport",
              weight_unit: data["device_metadata"]["weight_unit"],
            },
            () => {
              document.title = `${this.state.customerName} Dashboard`;
            }
          );
          if (data["cycle_metadata"]["changed_from_date"]) {
            this.setState({
              fromDate: moment
                .utc(data["cycle_metadata"]["new_from_date"])
                .format("YYYY-MM-DD"),
            });
          } else {
            if (
              data["device_cycles"].length === 0 &&
              complianceReport === true
            ) {
              this.setComprehensiveReport();
            }
          }
        })
        .catch((err) => {
          if (err.code !== 20) {
            Sentry.captureException(err);
            this.setState({ isLoading: false });
          }
        });
    }
  };

  fetchBiochallengeInfo = () => {
    const deviceConfigID = this.state.deviceConfigID;
    let paramString = "";
    deviceConfigID.forEach((id) => (paramString += `device_config_id=${id}&`));

    return Auth.fetch(
      `/api/get-summary-biochallenge-info/?${paramString.slice(0, -1)}`,
      {
        method: "GET",
        signal: this.state.abortController.signal,
      }
    )
      .then((data) => {
        this.setState({
          biochallengeInfo: data,
        });
      })
      .catch((err) => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.setState({ isLoading: false, isError: true });
        }
      });
  };

  fromDateClick = (timestamp) => {
    this.setState({
      fromDate: moment.utc(timestamp).format("YYYY-MM-DD"),
    });
  };

  toDateClick = (timestamp) => {
    this.setState({
      toDate: moment.utc(timestamp).format("YYYY-MM-DD"),
    });
  };

  setComprehensiveReport = () => {
    this.setState(
      {
        complianceReport: false,
        comprehensiveReport: true,
      },
      () => this.prepareDashboard(false)
    );
  };

  setComplianceReport = () => {
    this.setState(
      {
        complianceReport: true,
        comprehensiveReport: false,
      },
      () => this.prepareDashboard(false)
    );
  };

  selectedFacility = (e, data) => {
    let selecteFacility = data.options.find(
      (selected) => selected.value === data.value
    ).text;
    this.setState({
      facilityID: data.value,
      facilityName: selecteFacility,
      deviceConfigID: [],
    });

    document.title = `${selecteFacility} Dashboard`;
    this.props.history.push(
      `/sa/summary-dashboard/${data.options[0].customer_id}/${data.value}`
    );
    this.prepareDashboard(true);
  };

  dashboardLayout = (width, height, customerCycles) => {
    if (customerCycles.length <= 0) {
      return (
        <SummaryDashboardNoCycles
          toDateClick={this.toDateClick}
          fromDateClick={this.fromDateClick}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          prepareDashboard={this.prepareDashboard}
          selectedCycle={this.state.selectedCycle}
          pageInactive={this.state.pageInactive}
          refreshData={this.refreshData}
          customerName={this.state.customerName}
          isFacility={this.state.isFacility}
          facilityName={this.state.facilityName}
          customerId={this.state.customerId}
          facilityID={this.state.facilityID}
          facilities={this.state.facilities}
          selectedFacility={this.selectedFacility}
        />
      );
    } else {
      return (
        <SummaryDashboard
          width={width}
          height={height}
          summaryCycles={this.state.summaryCycles}
          userTimezone={this.state.userTimezone}
          deviceTimezone={this.state.deviceTimezone}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          toDateClick={this.toDateClick}
          fromDateClick={this.fromDateClick}
          selectedCycle={this.state.selectedCycle}
          refreshData={this.refreshData}
          pageInactive={this.state.pageInactive}
          prepareDashboard={this.prepareDashboard}
          customerName={this.state.customerName}
          isFacility={this.state.isFacility}
          isAirport={this.state.isAirport}
          facilityName={this.state.facilityName}
          complianceReport={this.state.complianceReport}
          comprehensiveReport={this.state.comprehensiveReport}
          setComprehensiveReport={this.setComprehensiveReport}
          setComplianceReport={this.setComplianceReport}
          currentPressureUnit={this.state.currentPressureUnit}
          deviceFacilityFullAddress={this.state.deviceFacilityFullAddress}
          biochallengeInfo={this.state.biochallengeInfo}
          customerId={this.state.customerId}
          facilityID={this.state.facilityID}
          facilities={this.state.facilities}
          selectedFacility={this.selectedFacility}
          weight_unit={this.state.weight_unit}
        />
      );
    }
  };

  render() {
    const { isLoading, height, width, summaryCycles } = this.state;
    const dashboardComponent = this.dashboardLayout(
      width,
      height,
      summaryCycles
    );

    return (
      <div>
        {isLoading ? (
          <LoadingSummaryDashboard
            toDateClick={this.toDateClick}
            fromDateClick={this.fromDateClick}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            prepareDashboard={this.prepareDashboard}
            selectedCycle={this.state.selectedCycle}
            pageInactive={this.state.pageInactive}
            refreshData={this.refreshData}
            customerName={this.state.customerName}
            isFacility={this.state.isFacility}
            facilityName={this.state.facilityName}
            customerId={this.state.customerId}
            facilityID={this.state.facilityID}
            facilities={this.state.facilities}
            selectedFacility={this.selectedFacility}
          />
        ) : (
          dashboardComponent
        )}
      </div>
    );
  }
}

export default composeHoc(
  withRouter,
  translate("translations")
)(SummaryDashboardMain);
