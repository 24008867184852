/*
*  Decommission a device modal
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Checkbox, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../../SimpleLoader";
import AuthService from "../../AuthService";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import {toast} from "react-toastify";

const Auth = new AuthService();

class RetireDeviceModal extends React.Component {
  state = {
    modalLoading: false,
    allowModalToBeClosed: true,
    reassignDevice: false,
    selectedFacilityID: 1,
  };

  componentDidMount() {
    this.setState({
      retireDeviceID: this.props.modalDeviceID,
      retireDeviceSerial: this.props.modalSerial,
    })
  }

  toggleReassignDevice = () => {
    this.setState((prevState) => {
      return {reassignDevice: !prevState.reassignDevice};
    });
  };

  notifyActiveCycle = (cycleStartTime) => toast(`The device cannot be re-assigned because there is an active cycle. The cycle began on ${cycleStartTime}`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  submit = () => {
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    }, () => {
      this.postChanges().then(() => this.setState({
        modalLoading: false,
        allowModalToBeClosed: true
      }))
    });
  };

  postChanges = async () => {
    const {
      reassignDevice,
      retireDeviceSerial,
    } = this.state;
    if (reassignDevice) {
      await this.assignDevice();
    }
    await this.retireDevice();

    await this.props.preparePage(this.props.prepareParameter).then(() => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: false
      }, () => {
        this.props.triggerRetireDeviceModal(null);
        this.props.notifySuccess(`${reassignDevice ? 'archived and moved': 'archived'}`, "device", retireDeviceSerial)
      });
    });

  };

  assignDevice = () => {
    const {
      selectedFacilityID,
    } = this.state;
    const {
      modalDeviceConfigID
    } = this.props;
    return Auth.fetch(`/api/assign-device-to-facility/`, {
      method: 'POST',
      body: JSON.stringify({
        facility_id: selectedFacilityID,
        device_config_id: modalDeviceConfigID
      })
    }).then(data => {

    }).catch(err => {
      // Good example of how to get information from the server during an error
      err.response.json().then(resp => {
        if (resp['msg'] === 'cycle_active') {
          const activeCycleTimeStarted = moment.utc(resp['cycle_time_started']).format("YYYY-MM-DD HH:mm:ss z");
          this.notifyActiveCycle(activeCycleTimeStarted);
        } else {
          // Don't alert sentry on cycle_active error - not really a real server error
          Sentry.captureException(err);
          this.notifyFailure();
        }
      });
    });

  };

  retireDevice = () => {
    const {
      retireDeviceID,
    } = this.state;

    return Auth.fetch(`/api/device/${retireDeviceID}/`, {
      method: 'DELETE',
    }).then(data => {
    }).catch(err => {
      Sentry.captureException(err);
      this.props.notifyFailure();
      this.props.triggerRetireDeviceModal(null);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: false
      });
    });
  };

  handleFacilityChange = (event, data) => {
    this.setState({
      selectedFacilityID: data.value,
    });
  };

  render() {
    const {
      t
    } = this.props;
    const {
      retireDeviceSerial,
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const facilityOptions = [
      {
        key: 1006,
        value: 1006,
        text: `Sterilis Factory - Cogmedix`
      },
      {
        key: 1,
        value: 1,
        text: `Sterilis Factory - NeoTech`
      },
      {
        key: 1007,
        value: 1007,
        text: `Sterilis Factory - PPI/Time Zero`
      },
      {
        key: 3,
        value: 3,
        text: `Sterilis HQ - Lab`
      },
      {
        key: 100,
        value: 100,
        text: `Sterilis HQ - Lab (as airport)`
      },

    ];
    return (
      <TransitionablePortal
        open={this.props.retireDeviceModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='small'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerRetireDeviceModal(null);
          }}
        >
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2 className='grey-text'>{t('Archive')} {retireDeviceSerial}</h2>
          </Header>
          <Modal.Content>
            <div className='short-modal-text center-text'>
              <p className='slight-padding-bottom'>
                {t('Are you sure you want to archive this device?')}
              </p>
              <p className='slight-padding-bottom'>
                {t('The device will no longer function and will need to be reactivated to run cycles')}
              </p>


            </div>
            <div className='retire-device-modal-select-facility__container'>
              <div className="form-group medium-right-margin">
                <Checkbox
                  // label={<label><p className='short-modal-text'>Assign device to a different facility</p></label>}
                  label={t('Assign device to a different facility')}
                  name='byDate'
                  id='byDate'
                  checked={this.state.reassignDevice}
                  onChange={this.toggleReassignDevice}/>
              </div>
              {this.state.reassignDevice &&
              <div className="form-group wide-300">
                <label><h4 className='orange-text device-filter-header'>{t('Select facility')}</h4></label>
                <Dropdown
                  search
                  selection
                  fluid
                  onChange={this.handleFacilityChange}
                  value={this.state.selectedFacilityID}
                  id='facilityDropdown'
                  options={facilityOptions}
                />
              </div>
              }
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='grey-btn'
                    value="Submit"
                    type="submit"
                    id='retireDeviceBtn'
                    onClick={this.submit}
            >{t('Archive Device')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(RetireDeviceModal);
