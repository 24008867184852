import {
    Box,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Typography,
} from "@mui/material";
import React, { memo, useContext, useEffect } from "react";
import CreateUserForm from "./CreateUserForm";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "src/components/library/UserContext";
import { isGroupCustomer, isGroupSterilis } from "src/components/library/helpers";
import AuthService from "src/components/AuthService";
import {
    formSubmitted,
    formSuccessful,
    resetState,
    setCreateModalOpen,
    setCustomer,
    setFailed,
} from "./createUserSlice";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import CreateUserInfo from "./CreateUserInfo";
import { fetchFacilityAdmins } from "src/features/Customer/FacitlityAdmins/facilityAdminSlice";
import { fetchSiteAdmins } from "src/features/Customer/SiteAdmins/siteAdminSlice";
import { fetchDeviceOperators } from "src/features/Customer/Operators/operatorSlice";

const Auth = new AuthService();

const userTypeGroups = {
    customer: "CustomerPortalUsers",
    facilityadmin: "FacilityAdmin",
    distributor: "DistributorSiteAdmin",
};

const CreateUserModal = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translations");
    const userContext = useContext(UserContext);
    // @ts-ignore
    const { user, group } = userContext;

    const { userType, userTypeDisplay, createAnotherUser, customer, createModalOpen, loading, failed } =
        // @ts-ignore
        useSelector((store) => store.createUser);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            user: {
                first_name: "",
                last_name: "",
                username: "",
                permission: null,
            },
            employee: {
                email_address: "",
                phone: "",
                facility_ids: [],
                customer: null,
                distributor: null,
                is_admin: false,
            },
            operator: {
                pin: "",
                notifications: 0,
                is_admin: false,
            },
        },
    });

    const createOperator = async (employeeId, { operator: { notifications, pin, is_admin } }) => {
        const notifyComplete = [1, 3].includes(notifications);
        const notifyError = [2, 3].includes(notifications);

        const deviceOperator = {
            employee_id: employeeId,
            pin: pin,
            is_admin: is_admin,
            notify_complete: notifyComplete,
            notify_error: notifyError,
        };

        return await Auth.fetch(`/api/device-operator/`, {
            method: "POST",
            body: JSON.stringify(deviceOperator),
        })
            .then((operator) => {
                return operator?.id;
            })
            .catch((err) => {
                console.error(err);
                dispatch(setFailed(true));
            });
    };

    const createPortalUser = async (
        employeeId,
        { user: { username, first_name, last_name, permission }, employee: { email_address } }
    ) => {
        const portalUser = userType !== "operator" && {
            employee_id: employeeId,
            username: username,
            first_name: first_name,
            last_name: last_name,
            email: email_address,
            is_active: false,
            group: permission ? permission?.value : userTypeGroups[userType],
        };

        return await Auth.fetch(`/api/portal-user/`, {
            method: "POST",
            body: JSON.stringify(portalUser),
        })
            .then((newUser) => {
                return newUser?.id;
            })
            .catch((err) => {
                console.error(err);
                dispatch(setFailed(true));
            });
    };

    const createEmployee = async ({ employee }) => {
        let customerID = customer?.id ? customer.id : user.customer_id;

        if (group === "DistributorAdmins" && userType === "distributor") {
            customerID = user.customer_id;
        }

        if (isGroupSterilis(group) && userType === "sterilis") {
            if (employee.permission?.value === "FactoryWorkers") {
                customerID = 1;
            } else {
                customerID = user.customer_id;
            }
        }

        return await Auth.fetch(`/api/employee/`, {
            method: "POST",
            body: JSON.stringify(employee),
        })
            .then((employee) => {
                return employee;
            })
            .catch((err) => {
                console.error(err);
                dispatch(setFailed(true));
            });
    };

    const handleFormSubmit = async (data) => {
        const {
            user: { username, first_name },
        } = data;

        if (!data.employee.hasOwnProperty("customer")) {
            data.employee.customer_id = user.customer_id;
        } else {
            data.employee.customer_id = data.employee.customer.id;
            delete data.employee.customer;
        }
        data.employee.full_name = `${data.user.first_name} ${data.user.last_name}`;
        dispatch(formSubmitted());

        const employeeId = await createEmployee(data).then((employee) => {
            return employee.id;
        });

        await createOperator(employeeId, data);

        if (userType !== "operator") {
            await createPortalUser(employeeId, data);
        }

        // Refresh the siteAdmins table
        if (userType === "facilityadmin") {
            // @ts-ignore
            dispatch(fetchFacilityAdmins());
        } else {
            // @ts-ignore
            dispatch(fetchSiteAdmins());
        }

        // @ts-ignore
        dispatch(fetchDeviceOperators());

        if (!failed) {
            dispatch(formSuccessful(`${userTypeDisplay} ${username || first_name} Created Successfully!`));
        }

        if (createAnotherUser) {
            reset();
        } else {
            dispatch(resetState());
        }
    };

    useEffect(() => {
        if (!customer && isGroupCustomer(group)) {
            dispatch(setCustomer({ id: user?.customer_id, name: user?.customer }));
        }
    }, []);

    return (
        <Dialog open={createModalOpen} onClose={(e, reason) => dispatch(resetState())} maxWidth="lg">
            {loading ? (
                <DialogContent>
                    <CircularProgress size={80} color="primary" />
                </DialogContent>
            ) : (
                <>
                    <DialogTitle component="div">
                        <Typography variant="h4" color="secondary">
                            {t("Create")} {userTypeDisplay}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}>
                            <Grid container spacing={2}>
                                <Grid xs={3.8}>
                                    <Box m={2}>
                                        <CreateUserInfo />
                                    </Box>
                                </Grid>
                                <Grid xs={0.2}>
                                    <Divider orientation="vertical" variant="middle" />
                                </Grid>
                                <Grid xs={8}>
                                    <Box mt={1} component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
                                        <CreateUserForm
                                            control={control}
                                            errors={errors}
                                            setValue={setValue}
                                            isModal={true}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default memo(CreateUserModal);
