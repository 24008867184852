/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useEffect, useState } from "react";
import AuthService from "src/components/AuthService";
import PropTypes from "prop-types";
import CycleStat from "./CycleStat";
import { getRoundedNumber } from "src/components/library/helpers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleBackdrop from "src/components/material/SimpleBackdrop/SimpleBackdrop";

const Auth = new AuthService();

const PortalCycleInformation = (props) => {
  const { t } = useTranslation("translations");
  const [portalCycle, setPortalCycle] = useState(null)
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if(props.portalCycleid){
        setLoading(true)
        Auth.fetch(`/metrics/cycle_info/${props.portalCycleid}`).then((cycledata) => {
          setPortalCycle(cycledata)
          setLoading(false)
        });
    }
}, [props.portalCycleid]);
  const getErrorJsx = (deviceErrors) => {
      const errorTitle = `${deviceErrors.length} Error${deviceErrors.length > 0 ? "s" : ""}`;
      return (
          <Accordion variant="outlined" sx={{ my: 1 }}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="cycle-accordion"
                  id="cycle-accordion-header">
                  <Box>
                      <Typography variant="h6" color="secondary">
                          {errorTitle}
                      </Typography>
                  </Box>
              </AccordionSummary>
              <AccordionDetails>
                  {deviceErrors.map((error, idx) => {
                      return (
                          <Paper sx={{ p: 2, m: 1 }} key={`${idx}-${error["name"]}`}>
                              <CycleStat
                                  label={"Error time"}
                                  key="error-time-stat"
                                  itemKey="error-time"
                                  value={moment.utc(error["error_time"]).format("YYYY-MM-DD HH:mm:ss")}
                              />
                              {error["sw_error_msg"] && (
                                  <CycleStat
                                      label={"Software error message"}
                                      key="error-msg-stat"
                                      itemKey="error-msg"
                                      value={error["sw_error_msg"]}
                                  />
                              )}
                              {error["hw_error_code"] && (
                                  <React.Fragment>
                                      <CycleStat
                                          label={"Error code"}
                                          key="error-code-stat"
                                          itemKey="error-code"
                                          value={error["hw_error_code"]["error_code"]}
                                      />
                                      <CycleStat
                                          label={"Description"}
                                          key="error-desc-stat"
                                          itemKey="error-desc"
                                          value={error["hw_error_code"]["description"]}
                                      />
                                  </React.Fragment>
                              )}
                              <CycleStat
                                  label={"Device State"}
                                  key="device-state-stat"
                                  itemKey="device-state"
                                  value={error["machine_state"]}
                              />
                          </Paper>
                      );
                  })}
              </AccordionDetails>
          </Accordion>
      );
  };
  return (
      <Box>
          <Accordion variant="outlined" sx={{ my: 1 }}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="cycle-accordion"
                  id="cycle-accordion-header">
                  <Box>
                      <Typography variant="h6" color="secondary">
                          {t("Portal Cycle Information")}
                      </Typography>
                  </Box>
              </AccordionSummary>
              <AccordionDetails>
                  {isLoading && <SimpleBackdrop loaderText="Fetching cycle information" />}
                  {portalCycle && (
                      <List disablePadding>
                          <CycleStat
                              label={"Cycle Type"}
                              key="cycle-type-stat"
                              itemKey="cycle-type"
                              value={portalCycle["cycle_type_display"]}
                          />
                          <CycleStat
                              label={"Waste Type"}
                              key="waste-type-stat"
                              itemKey="waste-type"
                              value={portalCycle["waste_type_display"]}
                          />

                          <CycleStat
                              label={"Weight"}
                              key="cycle-weight-stat"
                              itemKey="cycle-weight"
                              value={getRoundedNumber(portalCycle, "weight")}
                          />
                          <CycleStat
                              label={"Ambient pressure"}
                              key="ambient-pressure-stat"
                              itemKey="ambient-pressure"
                              value={getRoundedNumber(portalCycle, "ambient_pressure")}
                          />
                          <CycleStat
                              label={"Tv cal"}
                              key="tv-cal-stat"
                              itemKey="tv-cal"
                              value={getRoundedNumber(portalCycle, "tv_cal")}
                          />
                          <CycleStat
                              label={"Voltage"}
                              key="voltage-stat"
                              itemKey="voltage"
                              value={portalCycle["voltage"]}
                          />
                          <CycleStat
                              label={"Odor Spray Count"}
                              key="odor-count-stat"
                              itemKey="odor-count"
                              value={getRoundedNumber(portalCycle, "odor_spray_count")}
                          />
                          {portalCycle["firmware"] && (
                              <CycleStat
                                  label={"Firmware version"}
                                  key="fw-version-stat"
                                  itemKey="fw-version"
                                  value={portalCycle["firmware"]["firmware_version"]}
                              />
                          )}
                          {portalCycle["device_errors"] &&
                              portalCycle["device_errors"].length > 0 &&
                              getErrorJsx(portalCycle["device_errors"])}
                      </List>
                  )}
              </AccordionDetails>
          </Accordion>
      </Box>
  );
};

PortalCycleInformation.defaultProps = {
  portalCycleid: null,
};

PortalCycleInformation.propTypes = {
  portalCycleid: PropTypes.string,
};

export default PortalCycleInformation;
