import { createTheme } from "@mui/material/styles";

// assets
import colors from "src/scss/_themes-vars.module.scss";

// project imports
import themePalette from "./palette";
import themeTypography from "./typography";
import componentStyleOverrides from "./compStyleOverride";

export const theme = () => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.grey50,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryUltraLight,
        divider: color.grey200,
    };

    const themeOptions = {
        palette: themePalette(themeOption),
        status: {
            failed: {
                main: "rgba(244, 67, 54, 1)",
                light: "rgba(244, 67, 54, 0.75)",
            },
        },

        typography: themeTypography(themeOption),
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
