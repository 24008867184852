/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { roundNumber, calculateDurations } from "src/components/library/helpers";
import {
    Card,
    LinearProgress,
    Grid,
    Typography,
    Box,
    Container,
    Tooltip,
    CardActionArea,
    CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectTestResults, setTestSuccess, setTotalScore, setTestScore } from "./bitSlice";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" color={props.color} value={(props.value / props.total) * 100} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                {props.loading ? (
                    <CircularProgress size={15} />
                ) : (
                    <Typography variant="body2" color={props.color}>{`${Math.round(props.value)}/${Math.round(
                        props.total
                    )}`}</Typography>
                )}
            </Box>
        </Box>
    );
}

const BurninScore = (props) => {
    const dispatch = useDispatch();
    const { burninScore, setScoreCalculated } = props;
    const [pointsPossibleByTest, setPointsPossibleByTest] = useState({
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        total: 0,
    });
    const scorePassThreshHold = 90;

    const burninTestResults = useSelector(selectTestResults);

    const duration_list = {
        scj_extension_duration_avg: "scj_extension_avg_time",
        scj_retraction_duration_avg: "scj_retraction_avg_time",
        shroud_open_duration_avg: "shroud_open_avg_time",
        shroud_close_duration_avg: "shroud_close_avg_time",
        heat_up_time_delta: "heat_up_time_delta",
        grind_duration: "grinder_time_seconds",
        open_toplid_duration: "open_toplid_seconds",
        open_bottomlid_duration: "open_bottomlid_seconds",
    };

    const calcInMinutes = {
        scj_extension_duration_avg: false,
        scj_retraction_duration_avg: false,
        shroud_open_duration_avg: false,
        shroud_close_duration_avg: false,
        heat_up_time_delta: true,
        grind_duration: false,
        open_toplid_duration: false,
        open_bottomlid_duration: false,
    };

    useEffect(() => {
        const { burninTest1, abnormalBurninValues } = props;
        let score = 0;
        Object.keys(burninTest1).forEach((burninStat) => {
            const oldBurninStat = burninStat;
            if (burninStat in duration_list) {
                burninStat = duration_list[burninStat];
            }
            const abnormalDefinition = abnormalBurninValues["1"][burninStat];
            let burninValue = burninTest1[burninStat];
            if (oldBurninStat in duration_list) {
                burninValue = calculateDurations(burninTest1, oldBurninStat, calcInMinutes[oldBurninStat], true);
            }
            if (abnormalDefinition) {
                if (
                    burninValue <= abnormalDefinition["maximum_value"] &&
                    burninValue >= abnormalDefinition["minimum_value"]
                ) {
                    score += abnormalDefinition["score_coefficient"];
                }
            }
        });
        dispatch(setTestScore({ test: 1, score: score }));
        dispatch(setTestSuccess({ test: 1, value: score > 0 && score === pointsPossibleByTest[1] }));
    }, [props.burninTest1, props.loadingBurnIn1]);

    useEffect(() => {
        const { burninTest2, abnormalBurninValues } = props;
        let score = 0;
        Object.keys(burninTest2).forEach((burninStat) => {
            const oldBurninStat = burninStat;
            if (burninStat in duration_list) {
                burninStat = duration_list[burninStat];
            }
            const abnormalDefinition = abnormalBurninValues["2"][burninStat];
            let burninValue = burninTest2[burninStat];
            if (oldBurninStat in duration_list) {
                burninValue = calculateDurations(burninTest2, oldBurninStat, calcInMinutes[oldBurninStat], true);
            }
            if (abnormalDefinition) {
                if (
                    burninValue <= abnormalDefinition["maximum_value"] &&
                    burninValue >= abnormalDefinition["minimum_value"]
                ) {
                    score += abnormalDefinition["score_coefficient"];
                }
            }
        });
        dispatch(setTestScore({ test: 2, score: score }));
        dispatch(setTestSuccess({ test: 2, value: score > 0 && score === pointsPossibleByTest[2] }));
    }, [props.burninTest2, props.loadingBurnIn2]);

    useEffect(() => {
        const { burninTest3, abnormalBurninValues } = props;
        let score = 0;
        Object.keys(burninTest3).forEach((burninStat) => {
            const oldBurninStat = burninStat;
            if (burninStat in duration_list) {
                burninStat = duration_list[burninStat];
            }
            const abnormalDefinition = abnormalBurninValues["3"][burninStat];
            let burninValue = burninTest3[burninStat];
            if (oldBurninStat in duration_list) {
                burninValue = calculateDurations(burninTest3, oldBurninStat, calcInMinutes[oldBurninStat], true);
            }
            if (abnormalDefinition) {
                if (
                    burninValue <= abnormalDefinition["maximum_value"] &&
                    burninValue >= abnormalDefinition["minimum_value"]
                ) {
                    score += abnormalDefinition["score_coefficient"];
                }
            }
        });
        dispatch(setTestScore({ test: 3, score: score }));
        dispatch(setTestSuccess({ test: 3, value: score > 0 && score === pointsPossibleByTest[3] }));
    }, [props.burninTest3, props.loadingBurnIn3]);

    useEffect(() => {
        const { burninTest4, abnormalBurninValues } = props;
        let score = 0;
        Object.keys(burninTest4).forEach((burninStat) => {
            const oldBurninStat = burninStat;
            if (burninStat in duration_list) {
                burninStat = duration_list[burninStat];
            }
            const abnormalDefinition = abnormalBurninValues["4"][burninStat];
            let burninValue = burninTest4[burninStat];
            if (oldBurninStat in duration_list) {
                burninValue = calculateDurations(burninTest4, oldBurninStat, calcInMinutes[oldBurninStat], true);
            }
            if (abnormalDefinition) {
                if (
                    burninValue <= abnormalDefinition["maximum_value"] &&
                    burninValue >= abnormalDefinition["minimum_value"]
                ) {
                    score += abnormalDefinition["score_coefficient"];
                }
            }
        });
        dispatch(setTestScore({ test: 4, score: score }));
        dispatch(setTestSuccess({ test: 4, value: score > 0 && score === pointsPossibleByTest[4] }));
    }, [props.burninTest4, props.loadingBurnIn4]);

    useEffect(() => {
        const { burninTest5, abnormalBurninValues } = props;
        let score = 0;
        Object.keys(burninTest5).forEach((burninStat) => {
            const oldBurninStat = burninStat;
            if (burninStat in duration_list) {
                burninStat = duration_list[burninStat];
            }
            const abnormalDefinition = abnormalBurninValues["5"][burninStat];
            let burninValue = burninTest5[burninStat];
            if (oldBurninStat in duration_list) {
                burninValue = calculateDurations(burninTest5, oldBurninStat, calcInMinutes[oldBurninStat], true);
            }
            if (abnormalDefinition) {
                if (
                    burninValue <= abnormalDefinition["maximum_value"] &&
                    burninValue >= abnormalDefinition["minimum_value"]
                ) {
                    score += abnormalDefinition["score_coefficient"];
                }
            }
        });
        dispatch(setTestScore({ test: 5, score: score }));
        dispatch(setTestSuccess({ test: 5, value: score > 0 && score === pointsPossibleByTest[5] }));
    }, [props.burninTest5, props.loadingBurnIn5]);

    useEffect(() => {
        const { burninTest6, abnormalBurninValues } = props;
        let score = 0;
        Object.keys(burninTest6).forEach((burninStat) => {
            const oldBurninStat = burninStat;
            if (burninStat in duration_list) {
                burninStat = duration_list[burninStat];
            }
            const abnormalDefinition = abnormalBurninValues["6"][burninStat];
            let burninValue = burninTest6[burninStat];
            if (oldBurninStat in duration_list) {
                burninValue = calculateDurations(burninTest6, oldBurninStat, calcInMinutes[oldBurninStat], true);
            }
            if (abnormalDefinition) {
                if (
                    burninValue <= abnormalDefinition["maximum_value"] &&
                    burninValue >= abnormalDefinition["minimum_value"]
                ) {
                    score += abnormalDefinition["score_coefficient"];
                }
            }
        });
        dispatch(setTestScore({ test: 6, score: score }));
        dispatch(setTestSuccess({ test: 6, value: score > 0 && score === pointsPossibleByTest[6] }));
    }, [props.burninTest6, props.loadingBurnIn6]);

    useEffect(() => {
        const { burninTest7, abnormalBurninValues } = props;
        let score = 0;
        Object.keys(burninTest7).forEach((burninStat) => {
            const oldBurninStat = burninStat;
            if (burninStat in duration_list) {
                burninStat = duration_list[burninStat];
            }
            const abnormalDefinition = abnormalBurninValues["7"][burninStat];
            let burninValue = burninTest7[burninStat];
            if (oldBurninStat in duration_list) {
                burninValue = calculateDurations(burninTest7, oldBurninStat, calcInMinutes[oldBurninStat], true);
            }
            if (abnormalDefinition) {
                if (
                    burninValue <= abnormalDefinition["maximum_value"] &&
                    burninValue >= abnormalDefinition["minimum_value"]
                ) {
                    score += abnormalDefinition["score_coefficient"];
                }
            }
        });
        dispatch(setTestScore({ test: 7, score: score }));
        dispatch(setTestSuccess({ test: 7, value: score > 0 && score === pointsPossibleByTest[7] }));
    }, [props.burninTest7, props.loadingBurnIn7]);

    useEffect(() => {
        const score = burninTestResults.reduce((acc, curr) => {
            acc += curr["score"];
            return acc;
        }, 0);
        dispatch(setTotalScore(score));
        setScoreCalculated(true);
    }, [burninTestResults]);

    useEffect(() => {
        const { abnormalBurninValues } = props;

        const pointsByTest = { ...pointsPossibleByTest };
        pointsByTest["total"] = 0;

        Object.entries(abnormalBurninValues).forEach(([burninTestNumber, burninTestAbnormalDefinitions]) => {
            pointsByTest[burninTestNumber] = Object.entries(burninTestAbnormalDefinitions).reduce(
                (score, [name, definition]) => {
                    if (definition["score_coefficient"]) {
                        score += definition["score_coefficient"];
                        pointsByTest["total"] += definition["score_coefficient"];
                    }
                    return score;
                },
                0
            );
        });

        setPointsPossibleByTest(pointsByTest);
    }, [props.abnormalBurninValues]);

    const scoreLabel = () => {
        const scoreFailed = burninScore < scorePassThreshHold;

        const scoreIncomplete = Object.keys(burninTestResults).some((test) => {
            return burninTestResults[test]["missing"];
        });

        const completedTests = Object.keys(burninTestResults).reduce((acc, test) => {
            if (burninTestResults[test]["missing"] === false) {
                acc += 1;
            }
            return acc;
        }, 0);

        const numberOfCompletedTests = `(${completedTests} out of ${
            Object.keys(burninTestResults).length
        } tests completed)`;

        return (
            <Container maxWidth="xs" sx={{ mb: 1 }}>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h5" color="primary" sx={{ fontWeight: "bold" }}>
                        Burn-In Score:
                    </Typography>
                    <Typography variant="h5" color={scoreFailed ? "error" : "success.light"} sx={{ ml: 1 }}>
                        {burninScore} - {scoreIncomplete ? "Incomplete" : scoreFailed ? "Fail" : "Pass"}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography variant="subtitle1" color="secondary">
                        {numberOfCompletedTests}{" "}
                    </Typography>
                </Box>
            </Container>
        );
    };

    const testSummaryLabel = ({ missing }) => {
        return missing ? "(Incomplete)" : "";
    };

    return (
        <Box>
            {scoreLabel()}
            <Grid container sx={{ justifyContent: "center" }}>
                <Grid item>
                    <Card sx={{ mb: 1, px: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            Total Possible Points
                        </Typography>
                        <LinearProgressWithLabel
                            value={burninScore}
                            total={pointsPossibleByTest["total"]}
                            color={burninScore < scorePassThreshHold ? "error" : "success"}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ py: 2 }}>
                {burninTestResults.map((result, idx) => {
                    return (
                        <Grid item key={idx}>
                            <Tooltip
                                title={`Burnin score for Test ${result["test"]} ${testSummaryLabel(result)}`}
                                placement="top-end">
                                <Card
                                    sx={{ my: 1, minWidth: 175 }}
                                    onClick={() => props.handleTabChange(parseInt(result["test"]) - 1)}>
                                    <CardActionArea>
                                        <Typography px={1}>{`${result["testname"]} ${testSummaryLabel(
                                            result
                                        )}`}</Typography>
                                        <LinearProgressWithLabel
                                            loading={result["loading"]}
                                            value={roundNumber(result["score"])}
                                            total={roundNumber(pointsPossibleByTest[result["test"]])}
                                            color={
                                                result["score"] < pointsPossibleByTest[result["test"]]
                                                    ? "error"
                                                    : "success"
                                            }
                                        />
                                    </CardActionArea>
                                </Card>
                            </Tooltip>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

BurninScore.defaultProps = {
    burninTest1: {},
    burninTest2: {},
    burninTest3: {},
    burninTest4: {},
    burninTest5: {},
    burninTest6: {},
    burninTest7: {},
    abnormalBurninValues: {},
};

BurninScore.propTypes = {
    burninTest1: PropTypes.object,
    burninTest2: PropTypes.object,
    burninTest3: PropTypes.object,
    burninTest4: PropTypes.object,
    burninTest5: PropTypes.object,
    burninTest6: PropTypes.object,
    burninTest7: PropTypes.object,
    abnormalBurninValues: PropTypes.object,
    setScoreCalculated: PropTypes.func.isRequired,
};

export default BurninScore;
