/*
 *  Main compnent for the Customer Machine tab on the Customer Facing portal
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { withTranslation as translate } from "react-i18next";
import { composeHoc, calculateCycleTime } from "./library/helpers";
import SimpleLoader from "./SimpleLoader";
import CustomerDevice from "./CustomerDevice";
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";
import CycleReportModal from "./CycleReportModal";
import CustomerCycleReport from "./customer_portal/CustomerCycleReport";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const Auth = new AuthService();

class CustomerMachines extends React.Component {
  state = {
    appLoading: false,
    numOfCustomerMachines: 0,
    customerDropdown: [],
    customerFacilityObject: {},
    facilityCustomerDropdown: [],
    complianceReport: false,
    showCustomerCycleReportModal: false,
    showSummaryDashboard: true,
  };

  componentDidMount() {
    //this.fetchDropdownData();
    this.setState({ appLoading: true });
    this.preparePage().then(() => {
      this.props.hoistFunction(
        "fetchCustomerMachines",
        this.fetchCustomerMachines
      );
      this.setState({
        appLoading: false,
      });
    });
  }

  preparePage = async () => {
    await this.fetchCustomerMachines();
  };

  componentDidUpdate(prevProps) {
    if (this.props.action !== prevProps.action) {
      const { action } = this.props;
      // Need to do .includes because there will be a dynamic customer_id inside of it, cannot do hard string comparision
      if (action && action.includes("customer-cycle-report?customer_id=")) {
        //split at =, convert url id which is a string to number
        const customerID = Number(action.split("=")[1]);
        this.setState({
          customerCycleReportCustomerID: customerID,
          showCustomerCycleReportModal: true,
        });
      }
    }
  }

  fetchDeviceStatus = (device) => {
    const { t } = this.props;
    let devicestatus = 'device-unknown'
    let msg = t('Status unknown')
    let weekly_util_time = 0
    let daily_util_time = 0
    let showWeeklyUtilization = false
    let showDailyUtilization = false
    let weekly_time_utilized = 0
    let daily_time_utilized = 0
    let operations_time_weekly = 0
    let operations_time_today = 0
    let schedule_avail = false
    device.facility_schedules.map((facility_schedule, idx) => {
      schedule_avail = true;

      const present_day = new Date();

      const start_time = facility_schedule.start_time.split(':');
      const end_time = facility_schedule.end_time.split(':');

      const start_time_minute = (parseInt(start_time[0]) * 60) + parseInt(start_time[1])
      const end_time_minute = (parseInt(end_time[0]) * 60) + parseInt(end_time[1])

      if(facility_schedule.day === present_day.getDay()){
        operations_time_today = (end_time_minute - start_time_minute)
      }

      operations_time_weekly = operations_time_weekly + (end_time_minute - start_time_minute)

    })

    device.latest_cycle.map((cycles, idx) => {
      cycles.cycle_status.map((status) => {
        if(status.cycle_state.cycle_failed){
          devicestatus = 'device-error'
          msg = t('Error State')
        }
        else if(['CS_GRN','CS_STE','CS_STR'].includes(status.cycle_state.status_code)){
          devicestatus = 'device-processing'
          msg = t('Processing waste')
        }
        else{
          devicestatus = 'device-ready'
          msg = t('Machine ready')
        }
        
      })
    });
    device.current_week_cycle.map((cycles, idx) => {
      const weekTime = calculateCycleTime(cycles.time_started, cycles.time_ended, cycles.lastEventTimestamp, cycles.lastStatusTimestamp);
      let time_in_minutes = weekTime.replace(/[^0-9\.]+/g, "");
      weekly_util_time = weekly_util_time + parseInt(time_in_minutes);
      if( (new Date(cycles.time_started)).setHours(0,0,0,0) === (new Date()).setHours(0,0,0,0) ){
        daily_util_time = daily_util_time + parseInt(time_in_minutes);
      }

    });

    if (schedule_avail){
      if(operations_time_weekly !== 0){
        weekly_time_utilized = Math.round(weekly_util_time / operations_time_weekly * 100);
        showWeeklyUtilization = true
      }
      if(operations_time_today !== 0){
        daily_time_utilized = Math.round(daily_util_time / operations_time_today * 100);
        showDailyUtilization = true
      }
    }
    return [devicestatus, msg, weekly_time_utilized, daily_time_utilized,showWeeklyUtilization, showDailyUtilization]
  }

  fetchCustomerMachines = () => {
    const controller = this.props.customerAppAbortController;
    return Auth.fetch(`/api/customer-machines/`, {
      method: "GET",
      signal: controller.signal,
    })
      .then((data) => {
        const customerDropdown = [];
        const customerDropdownToBeHoisted = [];
        const customerFacilityDropdownToBeHoisted = [];
        const simpleFacilityObj = {};
        // simpleCustomerObj is used to construct the Customer Machines in an efficient manner
        const simpleCustomerObj = {};
        const simpleCustomerNameObj = {};
        // simpleCustomerFacilityObj is used in the Create Site Admin/Device operator modal
        const simpleCustomerFacilityObj = {};
        const { t, isCurrentUserSterilisOrDist } = this.props;
        // If the user is a Sterilis Super user we need to construct the array used to populate the 'Filter by Facility' dropdown differently
        if (isCurrentUserSterilisOrDist) {
          customerDropdown.push({
            key: -1,
            value: -1,
            text: t("All Customers"),
          });
        }

        const customerFacilityObject = {};
        let numOfCustomerMachines = 0;

        // Before we begin building the data structures used to construct the Device tab, we need to
        // alphabetically order the source data. This will alphabetically order everything built
        // off of data
        data.sort((a, b) => {
          const tempAText = a.customer_name.toUpperCase();
          const tempBText = b.customer_name.toUpperCase();
          if (tempAText < tempBText) {
            return -1;
          }
          if (tempAText > tempBText) {
            return 1;
          }
          // names must be equal
          return 0;
        });

        data.forEach((customer) => {
          const facilityObject = {};
          const facilities = [];

          simpleCustomerObj[customer.id] = customer.facilities;
          simpleCustomerNameObj[customer.id] = customer.customer_name;
          const customerPins = new Set();

          customerDropdown.push({
            key: customer.id,
            value: customer.id,
            text: customer.customer_name,
          });
          customerDropdownToBeHoisted.push({
            key: customer.id,
            value: customer.id,
            text: customer.customer_name,
          });

          let hasDevices = false;

          customer.facilities.forEach((facility) => {
            simpleFacilityObj[facility.id] = facility.facility_name;

            const facilityID = facility.id;

            facilities.push(
              {
                key: facility['id'],
                value: facility['id'],
                text: facility['facility_name'],

              }    
            );       

            facilityObject[facilityID] = facility.device_configs.filter(
              (devConfig) => {
                if (!devConfig.deleted) {
                  hasDevices = true;
                  // this line here is in lieu of the ON sorting through the backend
                  numOfCustomerMachines++;
                  devConfig.operators.forEach((operator) => {
                    customerPins.add(operator.pin);
                  });
                  devConfig['facility_schedules'] = facility.facility_schedules
                  return devConfig;
                } else {
                  return null;
                }
              }
            );     
          });

          customerFacilityDropdownToBeHoisted.push(
            {
              key: customer['id'],
              value: customer['id'],
              text: customer['customer_name'],
              facilities:  facilities

            }
          );

          simpleCustomerFacilityObj[customer.id] = {
            customer_name: customer.customer_name,
            facilities: customer.facilities,
            pins: customerPins,
          };

          customerFacilityObject[customer.customer_name] = facilityObject;
          customerFacilityObject[customer.customer_name][
            "hasDevices"
          ] = hasDevices;
          customerFacilityObject[customer.customer_name]["id"] = customer.id;
          simpleCustomerObj[customer.id]["hasDevices"] = hasDevices;
        });

        // We need to hoist these two up separately because we use them to build forms on the /sa page
        this.props.hoistData(
          simpleCustomerFacilityObj,
          customerDropdownToBeHoisted,
          customerFacilityDropdownToBeHoisted
        );
        this.props.hoistFunction("filterByCustomerDropdown", customerDropdown);

        if (isCurrentUserSterilisOrDist) {
          this.setState({
            customerFacilityObject: customerFacilityObject,
            numOfCustomerMachines: numOfCustomerMachines,
            simpleFacilityObj: simpleFacilityObj,
            customerDropdown: customerDropdown,
            simpleCustomerObj: simpleCustomerObj,
            simpleCustomerNameObj: simpleCustomerNameObj,
            simpleCustomerFacilityObj: simpleCustomerFacilityObj,
          });
        } else {
          this.setState({
            customerFacilityObject: customerFacilityObject,
            numOfCustomerMachines: numOfCustomerMachines,
            simpleFacilityObj: simpleFacilityObj,
            simpleCustomerFacilityObj: simpleCustomerFacilityObj,
          });
        }
      })
      .catch((err) => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.props.notifyFailure();
        }
      });
  };

  /*
  * Displays the customer label.
  * Adds the dashboard button if the customer has devices
  */
  displaySummaryDashboardBtn(label, hasDevices, customerId) {
    const { t } = this.props;
    return hasDevices && this.state.showSummaryDashboard ? (
      <Link to={`/sa/summary-dashboard/${customerId}`}>
        <Button
          icon="chart bar"
          content={t("Dashboard")}
          label={{
            basic: true,
            content: <h3>{label}</h3>,
            pointing: false,
          }}
          labelPosition="left"
        ></Button>
      </Link>
    ) : (
      <h3>{label}</h3>
    );
  }

  /*
  * Displays the facility label.
  * Adds the dashboard button if the facility has devices
  */
  displayFacilityLabel(lable, hasDevices, customer_id, facility_id) {
    const { t } = this.props;
    return hasDevices && this.state.showSummaryDashboard ? (
      <Link to={`/sa/summary-dashboard/${customer_id}/${facility_id}`}>
        <Button
          icon="chart bar"
          content={t("Dashboard")}
          label={{
            basic: true,
            content: <h4>{lable}</h4>,
            pointing: false,
          }}
          labelPosition="left"
        ></Button>
      </Link>
    ) : (
      <h4>{lable}</h4>
    );
  }

  /*
    complianceReport is a boolean representing if the uer wants a compliance report or not
    deviceConfigID will either be an ID if the report is being invoked on a single device, or it will be false
    if it's being invoked on several machines
  */
  triggerCycleReportModal = (complianceReport, deviceConfig) => {
    this.setState((prevState) => {
      return {
        generateCycleReportModal: !prevState.generateCycleReportModal,
        deviceConfigForReport: deviceConfig,
        complianceReport: complianceReport,
      };
    });
  };

  render() {
    const { t, selectedCustomer, isCurrentUserSterilisOrDist } = this.props;

    const {
      appLoading,
      numOfCustomerMachines,
      customerFacilityObject,
      simpleFacilityObj,
      simpleCustomerObj,
      simpleCustomerNameObj,
      generateCycleReportModal,
      deviceConfigForReport,
      complianceReport,
    } = this.state;

    return (
      <div>
        {appLoading ? <SimpleLoader /> : null}
        {generateCycleReportModal ? (
          <CycleReportModal
            deviceConfigForReport={deviceConfigForReport}
            generateCycleReportModal={generateCycleReportModal}
            triggerCycleReportModal={this.triggerCycleReportModal}
            complianceReport={complianceReport}
          />
        ) : null}

        <div className="customer-header">
          <div className="stat-info">
            <p className="large-num">{numOfCustomerMachines}</p>
            <div className="stat-name">
              {t("TOTAL")}
              <br />
              {t("DEVICES")}
            </div>
          </div>
          {isCurrentUserSterilisOrDist && (
            <CustomerCycleReport
              customerCycleReportCustomerID={
                this.state.customerCycleReportCustomerID
              }
              isCurrentUserSterilisOrDist={isCurrentUserSterilisOrDist}
              showModal={this.state.showCustomerCycleReportModal}
            />
          )}
          {isCurrentUserSterilisOrDist && this.props.filterByDiv}
        </div>

        <div className="height-restricted-content">
          {
            // if it's a Sterilis user, and if they're selecting 'All Customers' from the 'Filter by Customer' dropdown
            isCurrentUserSterilisOrDist && selectedCustomer === -1 ? (
              Object.keys(customerFacilityObject).map((customer, idx) => {
                const customerFacilities = customerFacilityObject[customer];
                return (
                  <div
                    key={idx}
                    className={
                      isCurrentUserSterilisOrDist
                        ? "machines-for-a-customer-ster"
                        : "machines-for-a-customer-cust"
                    }
                  >
                    {/*if it's a super user, we want to show the
                    customer names because there will be multiple customers*/}
                    {isCurrentUserSterilisOrDist ? (
                      <div className="customer-name-div">
                        {this.displaySummaryDashboardBtn(
                          customer,
                          customerFacilities.hasDevices,
                          customerFacilityObject[customer].id
                        )}
                      </div>
                    ) : null}
                    {Object.keys(customerFacilities)
                      .filter(
                        (facilities) =>
                          facilities !== "hasDevices" && facilities !== "id"
                      )
                      .map((facility, idx) => {
                        const individualFacilityMachines =
                          customerFacilities[facility];
                        const individualFacilityName =
                          simpleFacilityObj[facility];
                        return (
                          <div
                            key={idx}
                            className={
                              isCurrentUserSterilisOrDist
                                ? "machines-for-a-facility-ster"
                                : "machines-for-a-facility-ster"
                            }
                          >
                            <div className="facility-name-div">
                              {this.displayFacilityLabel(
                                individualFacilityName,
                                individualFacilityMachines.length > 0,
                                customerFacilityObject[customer].id,
                                facility
                              )}
                            </div>
                            {individualFacilityMachines.map((machine, idx) => {
                              let [devicestatus, msg, weekly_time_utilized,daily_time_utilized, showWeeklyUtilization, showDailyUtilization] = this.fetchDeviceStatus(machine)
                              return (
                                <CustomerDevice
                                  individualFacilityName={
                                    individualFacilityName
                                  }
                                  key={machine.id}
                                  deviceConfig={machine}
                                  triggerCycleReportModal={
                                    this.triggerCycleReportModal
                                  }
                                  group={this.props.group}
                                  status={devicestatus}
                                  msg={msg}
                                  weekly_time_utilized={weekly_time_utilized} 
                                  daily_time_utilized={daily_time_utilized}
                                  showWeeklyUtilization={showWeeklyUtilization} 
                                  showDailyUtilization={showDailyUtilization} 
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                );
              })
            ) : isCurrentUserSterilisOrDist ? (
              // if they're a Sterilis person and they're selecting a specific customer
              <div
                key={selectedCustomer}
                className="machines-for-a-customer-ster"
              >
                {this.displaySummaryDashboardBtn(
                  simpleCustomerNameObj[selectedCustomer],
                  simpleCustomerObj[selectedCustomer].hasDevices,
                  selectedCustomer
                )}
                {simpleCustomerObj[selectedCustomer].map((facility, idx) => {
                  const individualFacilityName = facility.facility_name;
                  const individualFacilityMachines = facility.device_configs;
                  return (
                    <div key={idx} className="machines-for-a-facility-ster">
                      <div className="facility-name-div">
                        {this.displayFacilityLabel(
                          individualFacilityName,
                          individualFacilityMachines.length > 0,
                          this.props.selectedCustomer,
                          facility.id
                        )}
                      </div>
                      {individualFacilityMachines.map((machine, idx) => {
                        let [devicestatus, msg, weekly_time_utilized, daily_time_utilized, showWeeklyUtilization, showDailyUtilization] = this.fetchDeviceStatus(machine)
                        return (
                          <CustomerDevice
                            individualFacilityName={individualFacilityName}
                            key={machine.id}
                            deviceConfig={machine}
                            triggerCycleReportModal={
                              this.triggerCycleReportModal
                            }
                            group={this.props.group}
                            status={devicestatus}
                            msg={msg}
                            weekly_time_utilized={weekly_time_utilized}
                            daily_time_utilized={daily_time_utilized}
                            showWeeklyUtilization={showWeeklyUtilization}
                            showDailyUtilization={showDailyUtilization}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              Object.keys(customerFacilityObject).map((customer, idx) => {
                const customerFacilities = customerFacilityObject[customer];
                return (
                  <div key={idx} className={"machines-for-a-customer-cust"}>
                    {Object.keys(customerFacilities)
                      .filter(
                        (facilities) =>
                          facilities !== "hasDevices" && facilities !== "id"
                      )
                      .map((facility, idx) => {
                      const individualFacilityMachines =
                        customerFacilities[facility];
                      const individualFacilityName =
                        simpleFacilityObj[facility];
                      return (
                        <div
                          key={idx}
                          className={"machines-for-a-facility-ster"}
                        >
                          <div className="facility-name-div">
                            <h4>{individualFacilityName}</h4>
                          </div>
                          {individualFacilityMachines.map((machine, idx) => {
                            let [devicestatus, msg, weekly_time_utilized, daily_time_utilized, showWeeklyUtilization, showDailyUtilization] = this.fetchDeviceStatus(machine)
                            return (
                              <CustomerDevice
                                individualFacilityName={individualFacilityName}
                                key={machine.id}
                                deviceConfig={machine}
                                triggerCycleReportModal={
                                  this.triggerCycleReportModal
                                }
                                group={this.props.group}
                                status={devicestatus}
                                msg={msg}
                                weekly_time_utilized={weekly_time_utilized}
                                daily_time_utilized={daily_time_utilized}
                                showWeeklyUtilization={showWeeklyUtilization}
                                showDailyUtilization={showDailyUtilization}
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )
          }
        </div>
      </div>
    );
  }
}

export default composeHoc(translate("translations"))(CustomerMachines);
