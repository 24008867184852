/*
* Component which displays device health dashboard
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate } from "react-i18next";
import SimpleLoader from "../SimpleLoader";
import withAuth from "../withAuth";
import AuthService from "../AuthService";
import {composeHoc} from "../library/helpers";
import {
  critical,
  minor,
  major
} from "./ErrorList";
import {Card, Dropdown, Button, Accordion} from "semantic-ui-react";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DeviceHealthTable from "./DeviceHealthTable";
import ErrorOccurrenceTable from "./ErrorOccurrenceTable";
import FailedBurninTest from "./FailedBurninTest";
import * as Sentry from "@sentry/browser";
import {toast} from "react-toastify";
import moment from "moment";

const Auth = new AuthService();

class DeviceHealthDashboard extends React.Component {

  state = {
    isLoading : false,
    criticalFilter: 'all',
    deviceHealthTableArray: [],
    fromDate: "",
    toDate: "",
    origFromDate: "",
    origToDate: "",
    errorOccurrenceTable: [],
    activeIndex: -1,
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    BurninData: [],
    displayBurninTest: false,
    MaxBurninScores: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      'total': 0,
    }
  };

  componentDidMount(){
    document.title = 'Device Health Dashboard';
    this.fetchDeviceHealth()
  }

  notifyGenericFailure = () => toast(`Server error. Please refresh this page and try again. If the problem persists, please contact support.`
    , {type: toast.TYPE.ERROR, autoClose: 5000});

  handleCriticalFilterChange = (event, data) => {
    this.setState({
      criticalFilter: data.value
    });
  };

  fromDateClick = timestamp => {
    const {userTimezone} = this.state;
    this.setState({
      fromDate: moment.utc(timestamp).tz(userTimezone).format("YYYY-MM-DD")
    });
  };

  toDateClick = timestamp => {
    const {userTimezone} = this.state;
    this.setState({
      toDate: moment.utc(timestamp).tz(userTimezone).format("YYYY-MM-DD")
    });
  };

  resetDate = () => {
    const {origFromDate, origToDate} = this.state;
    this.setState({
      fromDate: origFromDate,
      toDate: origToDate
    })
  }


  fetchDeviceHealth = () => {
    let { fromDate, toDate, userTimezone, origFromDate, origToDate, BurninData, MaxBurninScores } = this.state;
    const deviceHealthTableArray = []
    let errorOccurrenceTable = []
    /************* Sample data, will be removed once backend is implemented*********************
    BurninData = [{
      'device_serial': '100-12345',
      'bit_score': '33',
      'bit_date': '01/01/2022',
      'test_score':{
        1: '12',
        2: '15',
        3: '6',
        4: '0',
        5: '0',
        6: '0',
        7: '0',
      },
    },
    {
      'device_serial': '505-52365',
      'bit_score': '64',
      'bit_date': '05/21/2022',
      'test_score':{
        1: '12',
        2: '15',
        3: '16',
        4: '8',
        5: '13',
        6: '0',
        7: '0',
      },
    },{
      'device_serial': '900-56324',
      'bit_score': '72',
      'bit_date': '02/02/2022',
      'test_score':{
        1: '12',
        2: '15',
        3: '16',
        4: '8',
        5: '15',
        6: '6',
        7: '0',
      },
    },
    {
      'device_serial': '702-52345',
      'bit_score': '82',
      'bit_date': '03/01/2022',
      'test_score':{
        1: '12',
        2: '15',
        3: '16',
        4: '8',
        5: '15',
        6: '11',
        7: '5',
      },
    }]
    **************************************************************************************/
    this.setState({isLoading: true});

    if(this.state.displayBurninTest){
      Auth.fetch(`/api/get_abnormal_parser_values`, {
        method: 'GET',
      }).then(data => {

        data.map((parserData) => {
          MaxBurninScores[parserData.burnin_test] = MaxBurninScores[parserData.burnin_test] + parserData.score_coefficient
          MaxBurninScores['total'] = MaxBurninScores['total'] + parserData.score_coefficient
        });
        BurninData.map((BITresults, idx) => {
          BITresults.bit_score = BITresults.bit_score + '/' + MaxBurninScores['total']
          Object.entries(BITresults.test_score).forEach(([key, value]) => {
            BITresults.test_score[key] = BITresults.test_score[key] + '/' + MaxBurninScores[key]
          });
        });
        this.setState({
          MaxBurninScores
        });
      }).catch(err => {
        Sentry.captureException(err)
      });
    }

    Auth.fetch(`/api/device-health-status/`, {
      method : 'GET',
    }).then(data => {
      data.map(device=> {
        device.latest_cycle.map(latest_cycle => {
          latest_cycle.latest_error.map(latest_error => {
            if (latest_error['hw_error_code']) {
              const error_date = moment.utc(latest_error['error_time']).tz(userTimezone).format("YYYY-MM-DD")
              if (fromDate === "" || fromDate >= error_date){
                fromDate = error_date
                origFromDate = error_date
              }
              if (toDate === "" || toDate <= error_date){
                toDate = error_date
                origToDate = error_date
              }

              var errObj = errorOccurrenceTable.find(obj => obj.error_code === latest_error['hw_error_code']['error_code'])

              if(errObj){
                errObj.occurrence = errObj.occurrence + 1
              }
              else{
                const newObj = {
                  error_code: latest_error['hw_error_code']['error_code'],
                  description: latest_error['hw_error_code']['description'],
                  occurrence: 1
                }
                errorOccurrenceTable.push(newObj)
              }

              deviceHealthTableArray.push({
                'device_serial': device['serial_number'],
                'facility': device['facility'],
                'customer': device['customer'],
                'error_timestamp': moment.utc(latest_error['error_time']).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z"),
                'error_date': moment.utc(latest_error['error_time']).tz(userTimezone).format("YYYY-MM-DD"),
                'error_category': critical.includes(latest_error['hw_error_code']['error_code']) ? 'Critical' : major.includes(latest_error['hw_error_code']['error_code'])
                  ? 'Major' : minor.includes(latest_error['hw_error_code']['error_code']) ? 'Minor' : ' ',
                'error_code': latest_error['hw_error_code']['error_code'],
                'machine_state': latest_error['machine_state'],
                'error_desc': latest_error['hw_error_code']['description'],
              })
            }
          })
        })
      });

      this.setState({
        isLoading: false,
        deviceHealthTableArray,
        fromDate,
        toDate,
        origFromDate,
        origToDate,
        errorOccurrenceTable,
        BurninData
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.notifyGenericFailure();
      this.setState({
        isLoading : false,
      })
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const {t} = this.props;
    const {
      isLoading,
      deviceHealthTableArray,
      criticalFilter,
      fromDate,
      toDate,
      errorOccurrenceTable,
      activeIndex,
      BurninData,
      displayBurninTest
    } = this.state;

    const criticalFilterDropdownOptions = [
      {
        key: 'all',
        value: 'all',
        text: t('All Errors')
      },
      {
        key: 'critical',
        value: 'critical',
        text: t('Critical')
      },
      {
        key: 'major',
        value: 'major',
        text: t('Major')
      },
      {
        key: 'minor',
        value: 'minor',
        text: t('Minor')
      },
    ];

    const criticalFilterDropdown = <div className='view-critical-filter'>
      <label><h4 className='orange-text device-filter-header'>{t('Error Category Filter')}</h4></label>
      <Dropdown
        className='device-filter-dropdown'
        search
        selection
        onChange={this.handleCriticalFilterChange}
        fluid
        value={criticalFilter}
        options={criticalFilterDropdownOptions}
      />
    </div>;

    const dateRangeFilter = <div className='dashboard-nav-right'>
        <div className='date-group'>
          <div className='nav-group from-date-div dashboard-datepicker'>
            <label className='date-label'>{t('From')}</label>
            <DayPickerInput
              onDayChange={day => this.fromDateClick(day)}
              value={fromDate}
            />
          </div>
          <div className='nav-group dashboard-datepicker'>
            <label className='date-label'>{t('To')}</label>
            <DayPickerInput
              onDayChange={day => this.toDateClick(day)}
              value={toDate}
            />
          </div>
      </div>
      <Button
          className='ster-btn show-data-filter-btn'
          onClick={() => this.resetDate()}
      >{t('Reset Date')}</Button>
    </div>

    return (
		<Card fluid>
			{isLoading ? <SimpleLoader /> : null}
			<Card.Header style={{ margin: "10px" }}>
				<h2>{t("Device Health Dashboard")}</h2>
			</Card.Header>
			<Card.Content className="card-body">
				<Card fluid>
					{isLoading ? <SimpleLoader /> : null}
					<Card.Header style={{ margin: "10px" }}>
						<h3>{t("Device Errors")}</h3>
					</Card.Header>
					<Card.Content className="card-body">
						<Accordion className="device-health-accordion">
							<Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
								<h4 className="orange-text device-filter-header">
									{activeIndex === -1
										? "Click to view the Error Occurrence Table"
										: "Click to hide the Error Occurrence Table"}
								</h4>
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 0}>
								<ErrorOccurrenceTable
									className="slight-margin-top"
									errorOccurrenceTable={errorOccurrenceTable}
								/>
							</Accordion.Content>
						</Accordion>
						<div className="flex-dir-row even-split view-critical-filter_container">
							{criticalFilterDropdown}
							{dateRangeFilter}
						</div>
						<DeviceHealthTable
							className="slight-margin-top"
							deviceHealthTableArray={deviceHealthTableArray}
							criticalFilter={criticalFilter}
							fromDate={fromDate}
							toDate={toDate}
						/>
					</Card.Content>
				</Card>
				{displayBurninTest ? (
					<Card fluid>
						{isLoading ? <SimpleLoader /> : null}
						<Card.Header style={{ margin: "10px" }}>
							<h3>{t("Failed Burn in Tests")}</h3>
						</Card.Header>
						<Card.Content className="card-body">
							<FailedBurninTest className="slight-margin-top" BurninData={BurninData} />
						</Card.Content>
					</Card>
				) : null}
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FactoryWorkers','SterilisWasteTypeAdmin'], 'internalPage'))(DeviceHealthDashboard);
