/*
*  Component responsible for Editing a Deice Operator
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {prettyGroupName, userTypeFromGroup, AuthReq} from "./library/helpers";
import '../css/EditADeviceOperator.css';
import SimpleLoader from './SimpleLoader';
import {Button, Header, Modal, TransitionablePortal, Dropdown} from 'semantic-ui-react'
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";
import get from "lodash/get";
import debounce from "lodash/debounce";

const Auth = new AuthService();

class EditASiteAdminModal extends React.Component {
  state = {
    pinValidationError: false,
    usedPinValidationError: false,
    usedPin: '',
    notificationLevel: 0,
    fullName: '',
    userPIN: '',
    emailAddr: '',
    phoneNumber: '',
    username: '',
    employeeID: null,
    deviceOperatorID: null,
    usernameValidationError: false,
    checkingUsername: false,
    checkingPin: false,
    pinLengthError: false,
    allowModalToBeClosed: true
  };

  componentDidMount() {
    const {
      siteAdminBeingEdited,
      isFacilityTab
    } = this.props;
    const customerID = siteAdminBeingEdited['employee']['customer']['id'];
    let facilityDropdown = []
    let FacilityID = []
    if(isFacilityTab){
      facilityDropdown = this.props.customerDropdown.filter( item => {return item.key === customerID})[0].facilities
      FacilityID = this.props.siteAdminBeingEdited.employee.facilities.map(item => {return item.id})

    }
    const pin = get(siteAdminBeingEdited, 'employee.device_user', []).length > 0 ?
      get(siteAdminBeingEdited, 'employee.device_user')[0]['pin'] : false;
    const deviceOperatorID = get(siteAdminBeingEdited, 'employee.device_user', []).length > 0 ?
      get(siteAdminBeingEdited, 'employee.device_user')[0]['id'] : false;
    const userType = userTypeFromGroup(siteAdminBeingEdited.groups[0]['name']);
    this.setState({
      fullName: siteAdminBeingEdited['employee']['full_name'],
      userPIN: siteAdminBeingEdited['pin'],
      emailAddr: siteAdminBeingEdited['employee']['email_address'],
      phoneNumber: siteAdminBeingEdited['employee']['phone'],
      employeeID: siteAdminBeingEdited['employee']['id'],
      portalUserID: siteAdminBeingEdited['id'],
      username: siteAdminBeingEdited['username'],
      originalUsername: siteAdminBeingEdited['username'],
      pin: pin,
      originalPin: pin,
      customerID: customerID,
      modalTitle: this.modalTitle(),
      userType,
      deviceOperatorID,
      facilityDropdown,
      FacilityID
    })
  }

  handleFacilityChange = (none, data) => {
    const FacilityID = data.value;

    this.setState({
      FacilityID
    })

  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (name === 'username') {
      if (/^[a-z0-9]+$/i.test(value)) {
        this.debouncedCheckIfUsernameTaken(value);
      }
    }
    if (name === 'pin') {
      const {
        userType
      } = this.state;
      if (userType === 'sterilis' || userType === 'distributor') {
        if (value.length !== 8) {
          this.shakePinSpan();
          this.setState({
            pinLengthError: true,
          })
        } else {
          this.setState({
            pinLengthError: false,
          })
        }
      } else if (userType === 'customer' || userType === 'operator') {
        if (value.length < 4 || value.length > 7) {
          this.shakePinSpan();
          this.setState({
            pinLengthError: true,
          })
        } else {
          this.setState({
            pinLengthError: false,
          })
        }
      }
      this.debouncedCheckIfPINTaken(value);
    }

    this.setState({[name]: value});
  };

  checkIfUsernameTaken = () => {
    const {
      originalUsername,
      username,
    } = this.state;

    // If the user is submitting a duplicate username really quickly and they try to submit the
    // form before the check username call finishes, it'll crash because the username will not be unique
    // We'll just disable the form submit through checkingUsername to prevent this bad UX
    this.setState({
      checkingUsername: true
    });

    if (username !== originalUsername) {
      Auth.fetch(`/api/username-available/`, {
        method: 'POST',
        body: JSON.stringify({
          'username': username
        })
      }).then(data => {
        if (data['available'] === 1) {
          this.setState({
            usernameValidationError: false,
            checkingUsername: false,
          })
        } else {
          this.shakeUsernameSpan();
          this.setState({
            usernameValidationError: true,
            checkingUsername: false,
          })
        }
      }).catch(err => {
        Sentry.captureException(err)
      });
    } else {
      this.setState({
        checkingUsername: false
      });
    }
  };

  debouncedCheckIfUsernameTaken = debounce((username) => this.checkIfUsernameTaken(username), 350);

  shakeUsernameSpan = () => {
    this.setState({
      setUsernameShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setUsernameShake: false,
        })
      }, 1000)
    })
  };

  shakePinSpan = () => {
    this.setState({
      setPinShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setPinShake: false,
        })
      }, 1000)
    })
  };


  checkIfPINTaken = () => {
    const {
      originalPin,
      pin,
      userType,
      customerID
    } = this.state;
    if (originalPin !== pin) {
      this.setState({
        checkingPin: true,
      });
      return Auth.fetch(`/api/pin-available/`, {
        method: 'POST',
        body: JSON.stringify({
          'pin': pin,
          'user_type': userType,
          'customer_id': customerID,
        })
      }).then(data => {
        if (data['available']) {
          this.setState({
            pinValidationError: false,
            checkingPin: false,
          })
        } else {
          this.shakePinSpan();
          this.setState({
            pinValidationError: true,
            checkingPin: false,
          })
        }
        return data['available']
      }).catch(err => {
        Sentry.captureException(err);
        this.setState({
          checkingPin: false,
        })
      });
    }
  };

  debouncedCheckIfPINTaken = debounce(() => this.checkIfPINTaken(), 350);


  editSiteAdmin = async (e) => {
    e.preventDefault();
    const {
      siteAdminBeingEdited,
      isFacilityTab,
      group
    } = this.props;
    const {
      fullName,
      emailAddr,
      phoneNumber,
      employeeID,
      originalUsername,
      username,
      usernameValidationError,
      checkingUsername,
      pin,
      originalPin,
      pinValidationError,
      checkingPin,
      deviceOperatorID,
      FacilityID
    } = this.state;

    let putObjEmployee

    
    if(isFacilityTab && group!=="FacilityAdmin"){
      putObjEmployee = {
        'facility_ids': FacilityID,
        'full_name': fullName,
        'email_address': emailAddr,
        'phone': phoneNumber
      };
    }
    else{
      putObjEmployee = {
        'full_name': fullName,
        'email_address': emailAddr,
        'phone': phoneNumber
      };
    }

    this.setState({
      appLoading: true,
      allowModalToBeClosed: false
    });
    const groupName = prettyGroupName(get(siteAdminBeingEdited, 'groups[0].name', 'Portal User'));
    const modifiedEmployeeName = await Auth.fetch(`/api/employee/${employeeID}/`, {
      method: 'PATCH',
      body: JSON.stringify(putObjEmployee)
    }).then(data => {
      return data.full_name;
    }).catch(e => {
      Sentry.captureException(e);
      this.props.notifyGenericFailure("edit", groupName, fullName);
    });

    if (pin !== originalPin && pinValidationError === false && checkingPin === false) {
      await Auth.fetch(`/api/device-operator/${deviceOperatorID}/`, {
        method: 'PATCH',
        body: JSON.stringify({
          'pin': pin
        })
      }).then(data => {
      }).catch(e => {
        Sentry.captureException(e);
        this.props.notifyGenericFailure("edit pin for", groupName, fullName);
      });
    }

    if (username !== originalUsername && usernameValidationError === false && checkingUsername === false) {
      const {
        portalUserID
      } = this.state;
      const newUsername = await Auth.fetch(`/api/portal-user/${portalUserID}/`, {
        method: 'PATCH',
        body: JSON.stringify({
          'username': username
        })
      }).then(data => {
        return data.username;
      }).catch(e => {
        Sentry.captureException(e);
        this.props.notifyGenericFailure("edit username for", groupName, fullName);
      });
      this.props.notifyGenericSuccess("edited", groupName, `${modifiedEmployeeName} with new username: ${newUsername}`)
    } else {
      this.props.notifyGenericSuccess("edited", groupName, modifiedEmployeeName)
    }


    this.setState({
      appLoading: false,
      allowModalToBeClosed: false
    });
    this.props.triggerEditSiteAdminModal(null); // close modal, set operator being edited to null
    if (this.props.allUsers === false) {
      this.props.fetchDeviceOperators(); //fetch new data for table
    }
    this.props.fetchSiteAdmins(); // fetch new data for table

  };

  modalTitle = () => {
    const {t, siteAdminBeingEdited} = this.props;
    const groupName = get(siteAdminBeingEdited, 'groups[0].name', 'Portal User');
    return <p>{t(`Edit ${prettyGroupName(groupName)}`)}</p>
  };

  render() {
    const {
      appLoading,
      modalTitle,
      setUsernameShake,
      usernameValidationError,
      checkingUsername,
      userType,
      setPinShake,
      pinValidationError,
      checkingPin,
      pinLengthError, 
      facilityDropdown,
      FacilityID
    } = this.state;

    const {
      t,
      allUsers,
      isFacilityTab,
      group
    } = this.props;

    // const topMargin = this.state.height < 1000 ? '3vh' : '10vh';
    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    const pinTextClassName = ((pinValidationError || pinLengthError) ? 'label-validation-error desc-text' : 'desc-text') + (setPinShake ? ' shake' : '');
    const pinText = (userType === 'customer' || userType === 'operator') ? <div
      className={pinTextClassName}
    >
      {t('create-site-admin.5', 'The User PIN value must be a unique set of 4 to 7 numeric characters.')}
      <br/>
    </div> : <div
      className={pinTextClassName}>
      PINs must be unique and eight (8) digits long.
    </div>;

    return (
      <TransitionablePortal
        open={this.props.editSiteAdminModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerEditSiteAdminModal(null) //pass null to both edit ids when closing modal
          }}
        >
          {appLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2>{modalTitle}</h2>
          </Header>
          <Modal.Content className='edit-a-device-operator'>
            <form id='editASiteAdminForm'
                  onSubmit={this.editSiteAdmin}
                  className="edit-a-site-admin-form">
              <div className='split-container'>
                <div className='desc customer'>
                  {allUsers ?
                    <React.Fragment>
                      <div className='desc-text'>
                        {t('edit-portal-user.1', 'This modal allows you to update a portal user\'s account information.')}
                      </div>
                      {pinText}
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {isFacilityTab ?
                        <div className='desc-text'>
                          {t('edit-facility-admin.1', 'This modal allows you to update a Facility Admin\'s portal account information.')}
                        </div>
                        :
                        <div className='desc-text'>
                          {t('edit-site-admin.1', 'This modal allows you to update a Site Admin\'s portal account information.')}
                        </div>
                      }
                      {pinText}
                    </React.Fragment>
                  }
                  <div
                    className={(usernameValidationError ? 'label-validation-error desc-text' : 'desc-text') + (setUsernameShake ? ' shake' : '')}>
                    {t('create-site-admin.7', 'Usernames must be unique. They also must be alphanumeric. - and _ are allowed')}
                  </div>
                </div>
                <div className='items'>
                <AuthReq
                    userGroup={group}
                    requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs',
                      'FactoryWorkers', 'DistributorAdmins','SterilisWasteTypeAdmin', 'CustomerPortalUsers']}
                  >

                    { isFacilityTab ?
                        <div className="form-group">
                        <label className="fake-float">{t('Facility')}</label>
                        <Dropdown
                          className='full-width'
                          search
                          fluid 
                          multiple selection
                          id='editFASelectFacility'
                          options={facilityDropdown}
                          value={FacilityID}
                          onChange={this.handleFacilityChange}

                        />
                      </div>
                      : null
                    }
                  </AuthReq>
                  <div className="form-group">
                    <input value={this.state.username}
                           name='username'
                           onChange={this.handleChange}
                           type="text"
                      // disabled={checkingUsername}
                           id="usernameInput"
                           className="form-control"
                           pattern="^[a-zA-Z0-9_-]*$"
                           title="Usernames must be alphanumeric. - and _ are allowed"
                           required/>
                    <label
                      className={`${usernameValidationError ? 'float-label-invalid' : ''} form-control-placeholder`}
                      htmlFor="usernameInput">{t('Username')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.fullName}
                           name='fullName'
                           onChange={this.handleChange}
                           type="text"
                           id="fullNameInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="fullNameInput">{t('Full Name')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.emailAddr}
                           name='emailAddr'
                           onChange={this.handleChange}
                           type="text"
                           id="emailAddressInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="emailAddressInput">{t('Email Address')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.phoneNumber}
                           name='phoneNumber'
                           onChange={this.handleChange}
                           type="text"
                           id="phoneNumberInput"
                           className="form-control" required/>
                    <label className="form-control-placeholder"
                           htmlFor="phoneNumberInput">{t('Phone Number')}</label>
                  </div>
                  {this.state.pin &&
                  <div className="form-group">
                    <input value={this.state.pin}
                           name='pin'
                           onChange={this.handleChange}
                           type="numbers"
                           id="pinInput"
                           className="form-control" required/>
                    <label className="form-control-placeholder"
                           htmlFor="phoneNumberInput">{t('PIN')}</label>
                  </div>
                  }
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='editASiteAdminForm'
                    id='editASiteAdminBtn'
                    disabled={usernameValidationError || checkingUsername || pinValidationError || checkingPin || pinLengthError || (isFacilityTab && (!FacilityID || FacilityID.length===0))}
            >{t('Submit changes')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(EditASiteAdminModal);
