import { InfoTwoTone, QuestionMarkTwoTone } from "@mui/icons-material";
import { Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const CreateUserInfo = () => {
    // @ts-ignore
    const { userType, userTypeDisplay } = useSelector((store) => store.createUser);

    const siteAdminInfo = (
        <>
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Have access to the Sterilis Solutions portal." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="A Device Operator account will be automatically created for them. Giving them access to Devices" />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Can create Device Operators, as well as other Site Administrators." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Can view the Cycle Dashboard, which shows cycles that have been run on a device. They can also generate OSHA reports." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Can view documents provided by Sterilis. Including Training Manuals or Troubleshooting guides." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="PINs must be a unique set of 4 to 7 digits." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Usernames must be unique. They also must be alphanumeric - and _ are allowed." />
            </ListItem>
        </>
    );

    const distributorAdminInfo = (
        <>
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Have access to the Sterilis Solutions portal." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="A Device Operator account will be automatically created for them. Giving them access to Devices" />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Must be assigned a Permission Group." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Will be able to log onto any device under their Distributor with their PIN." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="PINs must be eight (8) digits long." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Usernames must be unique. They also must be alphanumeric - and _ are allowed." />
            </ListItem>
        </>
    );

    const operatorInfo = (
        <>
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Are only able to use Sterilis devices. They do not have Portal access." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Are responsible for inserting waste and beginning a cycles." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Are responsible for removing the sterilized medical waste and preparing it for disposal, when the cycle completes." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="If a Device Admin, can conduct mandatory Biological Challenges and PRV tests to satisfy medical regulations." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="PINs must be a unique set of 4 to 7 digits." />
            </ListItem>
        </>
    );

    const facilityAdminInfo = (
        <>
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Are restricted to specific Facility(s) within the Portal. A Device Operator is automatically created for them." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Can create Device Operators, as well as other Facility Administrators." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Can view Cycle Dashboards, which shows cycles that have been run on a device. They can also generate OSHA reports." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Can view documents provided by Sterilis. Including Training Manuals or Troubleshooting guides." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="The User PIN value must be a unique set of 4 to 7 digits." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Usernames must be unique. They also must be alphanumeric - and _ are allowed." />
            </ListItem>
        </>
    );

    const sterilisInfo = (
        <>
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Can view all Customers, Facilities, and Devices. As well as administration tools." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Must be assigned a Permission Group." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                {userTypeDisplay === "Factory Worker" ? (
                    <ListItemText primary="Are able to log onto any device within their assigned Facility(s) with their PIN." />
                ) : (
                    <ListItemText primary="Are able to log onto any device with their PIN." />
                )}
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="PINs must be eight (8) digits long." />
            </ListItem>
            <Divider component="li" variant="inset" />
            <ListItem>
                <ListItemIcon>
                    <InfoTwoTone />
                </ListItemIcon>
                <ListItemText primary="Usernames must be unique. They also must be alphanumeric - and _ are allowed." />
            </ListItem>
        </>
    );

    const getContent = () => {
        switch (userType) {
            case "customer":
                return siteAdminInfo;
            case "distributor":
                return distributorAdminInfo;
            case "operator":
                return operatorInfo;
            case "facilityadmin":
                return facilityAdminInfo;
            case "sterilis":
                return sterilisInfo;
            default:
                return null;
        }
    };

    return (
        <List
            subheader={
                <ListSubheader color="primary">
                    <Typography variant="h6">{userTypeDisplay}s:</Typography>
                </ListSubheader>
            }>
            {getContent()}
        </List>
    );
};

export default CreateUserInfo;
