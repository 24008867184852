import React from "react";
import PropTypes from "prop-types";
import SubCard from "src/components/material/Cards/SubCard";
import {
    Avatar,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Typography,
    useTheme,
} from "@mui/material";
import { QuestionMarkTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LOCAL_DATETIME_FORMAT, convertWeight } from "src/components/library/helpers";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { selectCustomer, selectDistributor, selectFacility } from "../deviceInfoSlice";
import { DateTime } from "luxon";

const GeneralInformation = (props) => {
    const theme = useTheme();
    const { t } = useTranslation("translations");
    const { selectedDevice, selectedDeviceConfig, extraDeviceInfo } = useSelector((store) => store.deviceInfo);
    const loading = useSelector((store) => store.deviceInfo.loading.deviceConfig);
    const distributor = useSelector(selectDistributor);
    const customer = useSelector(selectCustomer);
    const facility = useSelector(selectFacility);

    const getMachineStatus = () => {
        if (selectedDeviceConfig) {
            if (selectedDeviceConfig?.device?.deleted) {
                return t("Archived Device");
            }
            if (selectedDeviceConfig?.device?.activated) {
                if (selectedDeviceConfig.facility.id === 1) {
                    return t("Factory Device");
                } else {
                    return t("Assigned To Customer");
                }
            } else {
                return t("Device Awaiting Activation");
            }
        }
    };

    const getCookParams = () => {
        const isAirport = facility?.facility_type === "airport";
        const overrideEnabled = facility?.enable_facility_specific_cooktime;
        const minCookTime = overrideEnabled
            ? facility?.facility_specific_cooktime
            : isAirport
            ? 55
            : facility?.region_setting.min_cook_time;
        const minCookTemp = isAirport ? "134" : facility?.region_setting.min_cook_temp;

        const divider = overrideEnabled ? (
            <Divider>
                <Typography variant="underline" color={"error"}>
                    {t("Facility Cook Override Enabled")}
                </Typography>
            </Divider>
        ) : isAirport ? (
            <Divider>
                <Typography variant="underline" color={"error"}>
                    {t("Airport Cook Override Enabled")}
                </Typography>
            </Divider>
        ) : null;

        return (
            <>
                {divider}
                <ListItem secondaryAction={`${minCookTime} mins`}>
                    <ListItemText primary={t("Regulated min cook time") + ":"} />
                </ListItem>
                <ListItem secondaryAction={`${minCookTemp} °C`}>
                    <ListItemText primary={t("Regulated min cook temp") + ":"} />
                </ListItem>
            </>
        );
    };

    const getWeightForMonth = () => {
        const weight = extraDeviceInfo?.weight_under_customer_month?.weight__sum || 0;
        return selectedDeviceConfig && extraDeviceInfo
            ? convertWeight(weight, {
                  ...customer?.weight_unit_preference,
                  roundPrecision: 1,
              })
            : "0";
    };

    return (
        <SubCard
            title={t("General Information")}
            avatar={
                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                    <QuestionMarkTwoTone />
                </Avatar>
            }>
            <CardContent>
                {selectedDevice && (
                    <List>
                        {loading ? (
                            <>
                                <Skeleton width={"100%"}>
                                    <ListItem secondaryAction={getMachineStatus()}>
                                        <ListItemText primary={t("Status") + ":"} />
                                    </ListItem>
                                </Skeleton>
                                <Skeleton width={"100%"}>
                                    <ListItem
                                        secondaryAction={
                                            <Link to={`/view/customers?name=${distributor?.distributor_name}`}>
                                                {distributor && distributor?.distributor_name}
                                            </Link>
                                        }>
                                        <ListItemText primary={t("Distributor") + ":"} />
                                    </ListItem>
                                </Skeleton>
                                <Skeleton width={"100%"}>
                                    <ListItem
                                        secondaryAction={
                                            <Link to={`/view/customers?name=${customer?.customer_name}`}>
                                                {customer && customer.customer_name}
                                            </Link>
                                        }>
                                        <ListItemText primary={t("Customer") + ":"} />
                                    </ListItem>
                                </Skeleton>

                                <Skeleton width={"100%"}>
                                    <ListItem
                                        secondaryAction={
                                            <Link to={`/view/customers?name=${facility?.facility_name}`}>
                                                {facility && facility.facility_name}
                                            </Link>
                                        }>
                                        <ListItemText primary={t("Facility") + ":"} />
                                    </ListItem>
                                </Skeleton>

                                <Skeleton width={"100%"}>
                                    <ListItem secondaryAction={selectedDeviceConfig?.facility_type_display}>
                                        <ListItemText primary={t("Regulatory Market") + ":"} />
                                    </ListItem>
                                </Skeleton>

                                <Skeleton width={"100%"}>
                                    <ListItem secondaryAction={`${facility?.elevation} ${facility && "ft"}`}>
                                        <ListItemText primary={t("Facility elevation") + ":"} />
                                    </ListItem>
                                </Skeleton>
                                <Skeleton width={"100%"}>
                                    <ListItem
                                        secondaryAction={DateTime.fromISO(selectedDeviceConfig?.created).toLocaleString(
                                            LOCAL_DATETIME_FORMAT
                                        )}>
                                        <ListItemText primary={t("Customer assignment date") + ":"} />
                                    </ListItem>
                                </Skeleton>
                                <Skeleton width={"100%"}>
                                    <ListItem secondaryAction={extraDeviceInfo?.cycles_under_customer_month}>
                                        <ListItemText
                                            primary={t("Number of customer cycles completed (this month)") + ":"}
                                        />
                                    </ListItem>
                                </Skeleton>
                                <Skeleton width={"100%"}>
                                    <ListItem secondaryAction={getWeightForMonth()}>
                                        <ListItemText primary={t("Weight of waste processed (this month)") + ":"} />
                                    </ListItem>
                                </Skeleton>
                            </>
                        ) : (
                            <>
                                <ListItem secondaryAction={getMachineStatus()}>
                                    <ListItemText primary={t("Status") + ":"} />
                                </ListItem>
                                <ListItem
                                    secondaryAction={
                                        <Link to={`/view/customers?name=${distributor?.distributor_name}`}>
                                            {distributor && distributor?.distributor_name}
                                        </Link>
                                    }>
                                    <ListItemText primary={t("Distributor") + ":"} />
                                </ListItem>
                                <ListItem
                                    secondaryAction={
                                        <Link to={`/view/customers?name=${customer?.customer_name}`}>
                                            {customer && customer.customer_name}
                                        </Link>
                                    }>
                                    <ListItemText primary={t("Customer") + ":"} />
                                </ListItem>
                                <ListItem
                                    secondaryAction={
                                        <Link to={`/view/customers?name=${facility?.facility_name}`}>
                                            {facility && facility.facility_name}
                                        </Link>
                                    }>
                                    <ListItemText primary={t("Facility") + ":"} />
                                </ListItem>
                                <ListItem secondaryAction={selectedDeviceConfig?.facility_type_display}>
                                    <ListItemText primary={t("Regulatory Market") + ":"} />
                                </ListItem>
                                <ListItem secondaryAction={`${facility?.elevation} ${facility && "ft"}`}>
                                    <ListItemText primary={t("Facility elevation") + ":"} />
                                </ListItem>
                                <ListItem
                                    secondaryAction={DateTime.fromISO(selectedDeviceConfig?.created).toLocaleString(
                                        LOCAL_DATETIME_FORMAT
                                    )}>
                                    <ListItemText primary={t("Customer assignment date") + ":"} />
                                </ListItem>
                                <ListItem secondaryAction={extraDeviceInfo?.cycles_under_customer_month}>
                                    <ListItemText
                                        primary={t("Number of customer cycles completed (this month)") + ":"}
                                    />
                                </ListItem>
                                <ListItem secondaryAction={getWeightForMonth()}>
                                    <ListItemText primary={t("Weight of waste processed (this month)") + ":"} />
                                </ListItem>
                                {selectedDeviceConfig && !selectedDeviceConfig.device.activated && (
                                    <ListItem secondaryAction={selectedDeviceConfig.device.reset_password_key}>
                                        <ListItemText primary={t("Last Activation Code") + ":"} />
                                    </ListItem>
                                )}
                                {getCookParams()}
                            </>
                        )}
                    </List>
                )}
            </CardContent>
        </SubCard>
    );
};

GeneralInformation.propTypes = {};

export default GeneralInformation;
