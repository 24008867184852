import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import withAuth from "src/components/withAuth";
import AuthService from "src/components/AuthService";
import { prettyWastetypes } from "src/components/library/helpers";
import SimpleBackdrop from "src/components/material/SimpleBackdrop/SimpleBackdrop";
import "./CycleMetricHomePage.css";
import {
    TextField,
    Box,
    Autocomplete,
    Button,
    Divider,
    Typography,
    Stack,
    Paper,
    FormControlLabel,
    Switch,
} from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CycleSelectionTable from "./CycleSelection/CycleSelectionTable";
import CycleMetricView from "./CycleMetricView";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import {
    setDeviceId,
    fetchTest1Results,
    fetchTest2Results,
    fetchTest3Results,
    fetchTest4Results,
    fetchTest5Results,
    fetchTest6Results,
    resetTests,
} from "./BurninContainer/bitSlice";
import {
    addCycleData,
    setContainsBurninTest,
    setCycleData,
    setCycles,
    setConfigId,
    setMetaIds,
    setShowBit,
    setShowCycleSelection,
    setShowResults,
    setSelectedCycle,
    fetchDigitalOutput,
    fetchDeviceDropdown,
    fetchAbnormalParserValues,
    fetchAvailableCycles,
    fetchCycleEvents,
    resetMetricState,
} from "./metricSlice";

const Auth = new AuthService();

// @ts-ignore
const CycleMetricHomePage = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translations");
    const [endDate, setEndDate] = useState(DateTime.now());
    const [selectedDevice, setSelectedDevice] = useState(null);

    // @ts-ignore
    const { bitTests, deviceId } = useSelector((store) => store.bit);
    const {
        deviceConfigId,
        deviceDropdown,
        loading,
        loadingText,
        cycles,
        metaIds,
        digitalOutput,
        containsBurninTest,
        cycleData,
        fromDate,
        abnormalParserValues,
        abnormalParserValuesObj,
        // @ts-ignore
    } = useSelector((store) => store.metric);

    const [startDate, setStartDate] = useState(fromDate || null);
    // @ts-ignore
    const showResults = useSelector((store) => store.metric.show.results);
    // @ts-ignore
    const showCycleSelection = useSelector((store) => store.metric.show.cycleSelection);
    // @ts-ignore
    const showBurninTest = useSelector((store) => store.metric.show.bit);

    const metaIdParam = Number(props.match.params["metadata_id"]);

    useEffect(() => {
        document.title = t("Cycle Metrics Dashboard");

        // @ts-ignore
        dispatch(fetchDeviceDropdown());
        // @ts-ignore
        dispatch(fetchAbnormalParserValues());
        // @ts-ignore
        dispatch(fetchDigitalOutput());

        dispatch(setConfigId(null));
        dispatch(setCycleData([]));
        dispatch(setSelectedCycle({}));
        dispatch(setShowResults(false));

        return () => {
            dispatch(resetMetricState());
        };
    }, []);

    //function which makes an API call to populate the serial-customer-facility dropdown

    const fetchCycles = () => {
        // @ts-ignore
        dispatch(fetchAvailableCycles({ deviceConfig: deviceConfigId, startDate: startDate, endDate: endDate }));
    };

    //To be changed
    const fetchBurninData = () => {
        dispatch(setContainsBurninTest(true));

        dispatch(resetTests());
        // @ts-ignore
        dispatch(fetchTest1Results(deviceId));
        // @ts-ignore
        dispatch(fetchTest2Results(deviceId));
        // @ts-ignore
        dispatch(fetchTest3Results(deviceId));
        // @ts-ignore
        dispatch(fetchTest4Results(deviceId));
        // @ts-ignore
        dispatch(fetchTest5Results(deviceId));
        // @ts-ignore
        dispatch(fetchTest6Results(deviceId));

        dispatch(setShowResults(true));
        dispatch(setShowCycleSelection(false));
    };

    useEffect(() => {
        if (metaIdParam) {
            dispatch(setMetaIds([metaIdParam]));
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        metaIds.forEach((metadataId) => dispatch(fetchCycleEvents(metadataId)));
    }, [metaIds]);

    useEffect(() => {
        if (deviceDropdown.length > 0 && deviceConfigId !== null) {
            setSelectedDevice(deviceDropdown.find((device) => device.value === deviceConfigId));
        }
    }, [deviceDropdown, deviceConfigId]);

    // @ts-ignore
    const handleViewMetricsClick = (event, selectedMetaIds) => {
        // @ts-ignore
        dispatch(setMetaIds(selectedMetaIds));
    };

    return (
        <Box>
            {loading && <SimpleBackdrop loaderText={loadingText} />}
            <Paper sx={{ width: "100%", minHeight: "100vh" }}>
                <Typography variant="h4" color="primary" fontWeight="bold" padding={2}>
                    Cycle Metric Dashboard
                </Typography>
                <Divider />
                <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction={{ xs: "column", md: "row" }}
                    sx={{ width: "90%", mx: "auto", mt: 2 }}>
                    <Box width={showBurninTest ? "40%" : "100%"}>
                        <Typography variant="subtitle1" color="secondary">
                            {t("Device Filter")}
                        </Typography>
                        <Autocomplete
                            size="small"
                            id="device-select"
                            options={deviceDropdown}
                            autoHighlight
                            value={selectedDevice}
                            getOptionLabel={(option) => option.text}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select device"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                            // @ts-ignore
                            onChange={(event, values) => {
                                dispatch(setConfigId(values ? values.value : null));
                                dispatch(setDeviceId(values ? values.device_id : null));
                            }}
                        />
                    </Box>
                    {!showBurninTest && (
                        <Box width="90%">
                            <Typography variant="subtitle1" color="secondary">
                                {t("Date Filter")}
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                                    <DateTimePicker
                                        label="From"
                                        mask="__/__/____ __:__ __"
                                        value={startDate}
                                        openTo="day"
                                        views={["year", "month", "day", "hours", "minutes"]}
                                        disableFuture={true}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />

                                    <DateTimePicker
                                        label="To"
                                        mask="__/__/____ __:__ __"
                                        value={endDate}
                                        openTo="day"
                                        views={["year", "month", "day", "hours", "minutes"]}
                                        disableFuture={true}
                                        minDate={startDate}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Box>
                    )}
                    <Box width="80%" sx={{ display: "flex", alignItems: "flex-end", justifyItems: "flex-start" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={!(deviceConfigId && (showBurninTest || (startDate && endDate)))}
                            onClick={showBurninTest ? fetchBurninData : fetchCycles}>
                            {showBurninTest ? t("Show Burnin Test") : t("Show Cycles")}
                        </Button>
                    </Box>
                </Stack>
                <Box mt={1}>
                    <Box width="90%" m="auto">
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() => {
                                        dispatch(setContainsBurninTest(!showBurninTest));
                                        dispatch(setCycles([]));
                                        dispatch(setCycleData([]));
                                        dispatch(setShowResults(false));
                                        dispatch(setShowCycleSelection(false));
                                        dispatch(setShowBit(!showBurninTest));
                                    }}
                                    checked={showBurninTest}
                                />
                            }
                            label={
                                <Typography variant="subtitle1" color="secondary">
                                    View Burnin Test data
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
                <Box p={2}>
                    {showCycleSelection && !showResults ? (
                        <CycleSelectionTable viewMetricsOnClick={handleViewMetricsClick} rows={cycles} />
                    ) : null}
                </Box>
                {showResults ? (
                    <Box px={4} py={2}>
                        <Divider variant="middle" sx={{ mb: 2 }}>
                            <Typography variant="h5" color="secondary" fontWeight="bold">
                                {showBurninTest ? "Burn In Tests" : "Cycle Events"}
                            </Typography>
                        </Divider>
                        <CycleMetricView
                            // @ts-ignore
                            cycleData={cycleData}
                            abnormalValues={abnormalParserValues}
                            abnormalValuesObj={abnormalParserValuesObj}
                            containsBurninTest={containsBurninTest}
                            digitalOutput={digitalOutput}
                            burnIn1Data={bitTests[1].testList}
                            loadingBurnIn1={bitTests[1].isLoading}
                            burnIn2Data={bitTests[2].testList}
                            loadingBurnIn2={bitTests[2].isLoading}
                            burnIn3Data={bitTests[3].testList}
                            loadingBurnIn3={bitTests[3].isLoading}
                            burnIn4Data={bitTests[4].testList}
                            loadingBurnIn4={bitTests[4].isLoading}
                            burnIn5Data={bitTests[5].testList}
                            loadingBurnIn5={bitTests[5].isLoading}
                            burnIn6Data={bitTests[6].testList}
                            loadingBurnIn6={bitTests[6].isLoading}
                            burnIn7Data={bitTests[7].testList}
                            loadingBurnIn7={bitTests[7].isLoading}
                        />{" "}
                    </Box>
                ) : null}
            </Paper>
        </Box>
    );
};

export default withAuth(
    [
        "SterilisSuperUsers",
        "SterilisPortalUsers",
        "FSEs",
        "ExternalFSEs",
        "DistributorAdmins",
        "DistributorReadOnly",
        "DistributorFSEs",
        "SterilisWasteTypeAdmin",
    ],
    "internalPage"
)(CycleMetricHomePage);
