/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import PropTypes from 'prop-types';

export const SideDrawer = props => {
  return <div className={`side-drawer ${props.show ? 'open' : ''}`}>
    {props.children}
  </div>
};

SideDrawer.defaultProps = {};

SideDrawer.propTypes = {};

export const Backdrop = props => {
  return <div className="backdrop" onClick={() => props.setDrawerOpen(false)} />
};

Backdrop.defaultProps = {

};

Backdrop.propTypes = {
  setDrawerOpen: PropTypes.func.isRequired,
};


export const DrawerHeader = props => {
  return <h2 className='drawer-header'>{props.children}</h2>
};
