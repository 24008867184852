import React from "react";
// @ts-ignore
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CustomerCycleGraph from "./CustomerCycleGraph";
import AuthService from "src/components/AuthService";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Stats from "./Stats";
// @ts-ignore
import { SyncTwoTone } from "@mui/icons-material";
import ActiveCyclesTable from "./ActiveCyclesTable";
import { useSelector } from "react-redux";
import ActiveUsersTable from "./ActiveUsersTable";
import DueDatesTable from "./DueDatesTable";
import { DateTime } from "luxon";
import { AuthReq } from "src/components/library/helpers";
import MainCard from "src/components/material/Cards/MainCard";
import SubCard from "src/components/material/Cards/SubCard";

const auth = new AuthService();

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <Box role="tabpanel" hidden={value !== index} id={`tab-${index}`} width={"100%"} {...other}>
            {value === index && (
                <Paper variant="outlined" sx={{ p: 3, width: "100%" }}>
                    {children}
                </Paper>
            )}
        </Box>
    );
};

const Dashboard = (props) => {
    // @ts-ignore
    const { user, group } = useSelector((store) => store.user);
    const { t } = useTranslation("translations");
    const theme = useTheme();
    const [cycleGraphStartDate, setCycleGraphStartDate] = React.useState(DateTime.now().minus({ days: 7 }).toISODate());
    const [cycleOutcome, setCycleOutcome] = React.useState("successful_cycles");
    const [activeTab, setActiveTab] = React.useState(0);
    const [timeRange, setTimeRange] = React.useState(7);
    const [customerDevicesOnly, setCustomerDevicesOnly] = React.useState(true);
    const [distributorFilter, setDistributorFilter] = React.useState("");
    const [cyclesByCustomerChartOptions, setCyclesByCustomerChartOptions] = React.useState({});
    const [numberOfActiveCustomers, setNumberOfActiveCustomers] = React.useState(null);
    const [numberOfCycles, setNumberOfCycles] = React.useState(0);
    const [lbsOfWaste, setLbsOfWaste] = React.useState(0);
    const [numberOfActiveDevices, setNumberOfActiveDevices] = React.useState(0);
    const [cyclesLoading, setCyclesLoading] = React.useState(false);
    const [usersLoading, setUsersLoading] = React.useState(false);
    const [distributorDropdownOptions, setDistributorDropdownOptions] = React.useState([]);
    const [activeCycles, setActiveCycles] = React.useState([]);
    const [activeUsers, setActiveUsers] = React.useState([]);
    const [altText, setAltText] = React.useState(t("homepage.within_past_week", "within the past week, beginning"));

    const fetchDistributors = () => {
        return auth
            .fetch(`/api/distributor/`, {
                method: "GET",
            })
            .then((data) => {
                const distDropdownArray = data.map((dist) => {
                    return {
                        key: dist["id"],
                        value: dist["id"],
                        text: dist["distributor_name"],
                    };
                });
                // add 'All Distributors' to the top of the dropdown
                distDropdownArray.unshift({
                    key: 0,
                    value: 0,
                    text: t("All Distributors"),
                });
                setDistributorDropdownOptions(distDropdownArray);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const fetchCycles = () => {
        //set loading state
        setCyclesLoading(true);

        return (
            auth
                .fetch(
                    `/api/cycle/?start_time=${cycleGraphStartDate}&cycle_outcome=${cycleOutcome}&distributor_id=${distributorFilter}&customers_only=${customerDevicesOnly}`,
                    {
                        method: "GET",
                    }
                )
                .then((data) => {
                    let numOfActiveCustomers = 0;
                    let weightOfWaste = 0;
                    // this is used to find the amount of total devices running cycles currently
                    // there might be a better way to find that than using a set's length, couldn't think of one at the time
                    const totalDevicesSet = new Set();
                    const customerCycles = data.reduce((acc, curr) => {
                        // const customerID = curr['device_config']['customer_id'];
                        const customerName = curr["device_config"]["customer"];
                        const currentWasteType = curr["waste_type"];
                        const serial = curr["device_config"]["device"]["serial_number"];

                        weightOfWaste += curr["weight"];
                        totalDevicesSet.add(serial);
                        if (curr["device_config"]["customer"] in acc) {
                            acc[customerName]["cycle_count"] += 1;
                            acc[customerName][currentWasteType] += 1;
                            acc[customerName]["serial_set"].add(serial);
                            return acc;
                        } else {
                            numOfActiveCustomers++;
                            const serial = curr["device_config"]["device"]["serial_number"];
                            const serialSet = new Set();
                            serialSet.add(serial);
                            // Need to add new waste types to this array
                            acc[customerName] = {
                                cycle_count: 0,
                                red_bags: 0,
                                yellow_bags: 0,
                                yellow_bags_nogrind: 0,
                                dialysis: 0,
                                sharps: 0,
                                soft_lab_waste: 0,
                                mixed: 0,
                                bio: 0,
                                prv: 0,
                                cannabis: 0,
                                cannabis_product: 0,
                                liquids: 0,
                                glass_slides: 0,
                                ungrindable_waste: 0,
                                customer: curr["device_config"]["customer"],
                                serial_set: serialSet,
                            };

                            acc[customerName][currentWasteType] += 1;
                            return acc;
                        }
                    }, {});

                    const alphabeticalCustomerCycles = {};
                    Object.keys(customerCycles)
                        .sort()
                        .forEach(function (key) {
                            alphabeticalCustomerCycles[key] = customerCycles[key];
                        });

                    const numOfCycles = data.length;
                    const numOfActiveDevices = totalDevicesSet.size;

                    const customerNameSet = new Set();
                    //Iterate over above data structure to get a flat Set of customer names in order, for the xAxis category
                    Object.keys(alphabeticalCustomerCycles).forEach((customer) => {
                        const deviceSerialString = Array.from(alphabeticalCustomerCycles[customer]["serial_set"]).join(
                            ", "
                        );
                        customerNameSet.add(
                            `${alphabeticalCustomerCycles[customer]["customer"]} - ${deviceSerialString}`
                        );
                    });
                    const cycleGraphSeries = [
                        {
                            name: t("Red Bags"),
                            // @ts-ignore
                            color: theme.palette.waste.redBags,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["red_bags"];
                            }),
                        },
                        {
                            name: t("Yellow Bags No Grind"),
                            // @ts-ignore
                            color: theme.palette.waste.yellowBagsNoGrind,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["yellow_bags_nogrind"];
                            }),
                        },
                        {
                            name: t("Yellow Bags"),
                            // @ts-ignore
                            color: theme.palette.waste.yellowBags,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["yellow_bags"];
                            }),
                        },
                        {
                            name: t("Dialysis"),
                            // @ts-ignore
                            color: theme.palette.waste.dialysis,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["dialysis"];
                            }),
                        },
                        {
                            name: t("Sharps"),
                            // @ts-ignore
                            color: theme.palette.waste.sharps,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["sharps"];
                            }),
                        },
                        {
                            name: t("Mixed"),
                            // @ts-ignore
                            color: theme.palette.waste.mixed,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["mixed"];
                            }),
                        },
                        {
                            name: t("Bio-challenge"),
                            // @ts-ignore
                            color: theme.palette.waste.biochallenge,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["bio"];
                            }),
                        },
                        {
                            name: t("PRV Test"),
                            // @ts-ignore
                            color: theme.palette.waste.prv,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["prv"];
                            }),
                        },
                        {
                            name: t("Cannabis Microbial Waste"),
                            // @ts-ignore
                            color: theme.palette.waste.cannabisMicrobial,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["cannabis"];
                            }),
                        },
                        {
                            name: t("Cannabis Product Waste"),
                            // @ts-ignore
                            color: theme.palette.waste.cannabisProduct,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["cannabis_product"];
                            }),
                        },
                        {
                            name: t("Liquids"),
                            // @ts-ignore
                            color: theme.palette.waste.liquids,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["liquids"];
                            }),
                        },
                        {
                            name: t("Soft Lab Waste"),
                            // @ts-ignore
                            color: theme.palette.waste.softlab,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["soft_lab_waste"];
                            }),
                        },
                        {
                            name: t("Glass Slides"),
                            // @ts-ignore
                            color: theme.palette.waste.glassSlides,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["glass_slides"];
                            }),
                        },
                        {
                            name: t("Ungrindable Waste"),
                            // @ts-ignore
                            color: theme.palette.waste.ungrindable,
                            data: Object.keys(alphabeticalCustomerCycles).map((customerID) => {
                                return alphabeticalCustomerCycles[customerID]["ungrindable_waste"];
                            }),
                        },
                    ];

                    const cycleGraphStartDateFormatted = moment.utc(cycleGraphStartDate).format("MMMM Do YYYY");
                    const options = {
                        chart: {
                            type: "column",
                            zoomType: "x",
                            zooming: {
                                mouseWheel: {
                                    enabled: false,
                                },
                            },
                        },

                        title: {
                            text: t("homepage.title", {
                                cycleGraphStartDateFormatted: cycleGraphStartDateFormatted,
                                altText: altText,
                            }),
                        },

                        xAxis: {
                            // Convert the Customer Name set to a plain old Array
                            categories: Array.from(customerNameSet),
                        },

                        yAxis: {
                            allowDecimals: false,
                            min: 0,
                            title: {
                                text: t("Number of Cycles"),
                            },
                        },
                        credits: false,

                        tooltip: {
                            formatter: function () {
                                return (
                                    "<b>" +
                                    this.x +
                                    "</b><br/>" +
                                    this.series.name +
                                    ": " +
                                    this.y +
                                    "<br/>" +
                                    "Total: " +
                                    this.point.stackTotal
                                );
                            },
                        },

                        plotOptions: {
                            column: {
                                stacking: "normal",
                            },
                        },

                        series: cycleGraphSeries,
                    };

                    setCyclesByCustomerChartOptions(options);
                    setNumberOfActiveCustomers(numOfActiveCustomers);
                    setNumberOfCycles(numOfCycles);
                    setLbsOfWaste(weightOfWaste);
                    setNumberOfActiveDevices(numOfActiveDevices);
                    setCyclesLoading(false);
                })
                // @ts-ignore
                .catch((err) => {
                    setCyclesLoading(false);
                })
        );
    };

    const fetchPortalStatus = () => {
        setUsersLoading(true);
        return auth
            .fetch(`/api/portal-status/`, {
                method: "GET",
            })
            .then((data) => {
                setActiveUsers(data["last_five_active_users"]);
                setActiveCycles(data["currently_active_cycles"]);
                setUsersLoading(false);
            })
            .catch((err) => {
                setUsersLoading(false);
            });
    };

    const timeRangeChange = (event) => {
        setTimeRange(event.target.value);
        setCycleGraphStartDate(DateTime.now().minus({ days: event.target.value }).toISODate());
        switch (event.target.value) {
            case 1:
                setAltText(t("Today"));
                break;
            case 3:
                setAltText(t("within the past three days, beginning"));
                break;
            case 7:
                setAltText(t("within the past week, beginning"));
                break;
            case 14:
                setAltText(t("within the past two weeks, beginning"));
                break;
            default:
                break;
        }
    };

    const refreshData = () => {
        fetchCycles();
        fetchPortalStatus();
    };

    React.useEffect(() => {
        fetchCycles();
        fetchDistributors();
        fetchPortalStatus();
    }, []);

    React.useEffect(() => {
        fetchCycles();
    }, [timeRange, cycleOutcome, distributorFilter, customerDevicesOnly]);

    return (
        <MainCard>
            <Grid container spacing={2} rowSpacing={4} padding={2}>
                <Grid xs={12} sm={6} md={3}>
                    <Stats
                        primary={+true}
                        value={numberOfActiveCustomers}
                        label={"ACTIVE CUSTOMERS"}
                        loading={cyclesLoading}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <Stats value={numberOfActiveDevices} label={"ACTIVE DEVICES"} loading={cyclesLoading} />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <Stats primary={+true} value={numberOfCycles} label={"TOTAL CYCLES"} loading={cyclesLoading} />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <Stats value={Math.round(lbsOfWaste)} label={"LBS OF WASTE"} loading={cyclesLoading} />
                </Grid>
                <Grid xs={12} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id="date-range-select-label">Time Range</InputLabel>
                        <Select
                            id="date-range-select"
                            labelId="date-range-select-label"
                            label="Time Range"
                            value={timeRange}
                            size="small"
                            onChange={(event) => timeRangeChange(event)}>
                            <MenuItem key={"today"} value={1}>
                                Today
                            </MenuItem>
                            <MenuItem key={"3days"} value={3}>
                                Three Days Ago
                            </MenuItem>
                            <MenuItem key={"1week"} value={7}>
                                One Week Ago
                            </MenuItem>
                            <MenuItem key={"2weeks"} value={14}>
                                Two Weeks Ago
                            </MenuItem>
                            <MenuItem key={"1month"} value={30}>
                                One Month Ago
                            </MenuItem>
                            <MenuItem key={"3months"} value={90}>
                                Three Months Ago
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={6} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id="cycle-outcome-select-label">Cycle Outcome</InputLabel>
                        <Select
                            id="cycle-outcome-select"
                            labelId="cycle-outcome-select-label"
                            label="Cycle Outcome"
                            value={cycleOutcome}
                            size="small"
                            onChange={(event) => setCycleOutcome(event.target.value)}>
                            <MenuItem key={"all-cycle"} value={"all_cycles"}>
                                All Cycles
                            </MenuItem>
                            <MenuItem key={"successful-cycles"} value={"successful_cycles"}>
                                Successful Cycles
                            </MenuItem>
                            <MenuItem key={"error-cycles"} value={"error_cycles"}>
                                Error Cycles
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={6} md={2}>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FSEs",
                            "ExternalFSEs",
                            "FactoryWorkers",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        <FormControl fullWidth>
                            <InputLabel id="distributor-select-label">Distributor</InputLabel>
                            <Select
                                id="distributor-select"
                                labelId="distributor-select-label"
                                label="Distributor"
                                value={distributorFilter}
                                size="small"
                                // @ts-ignore
                                onChange={(event) => setDistributorFilter(event.target.value)}>
                                {distributorDropdownOptions.map((dist) => {
                                    return (
                                        <MenuItem key={dist.key} value={dist.value}>
                                            {dist.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </AuthReq>
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FSEs",
                            "ExternalFSEs",
                            "FactoryWorkers",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked
                                            value={customerDevicesOnly}
                                            onChange={(event) => setCustomerDevicesOnly(event.target.checked)}
                                        />
                                    }
                                    label={t("Customer Devices Only")}
                                />
                            </FormGroup>
                        </Box>
                    </AuthReq>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}>
                        <Box sx={{ position: "relative" }}>
                            <Button
                                variant="contained"
                                size="large"
                                disabled={cyclesLoading}
                                endIcon={<SyncTwoTone />}
                                onClick={refreshData}>
                                {t("Refresh Data")}
                            </Button>
                            {cyclesLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: theme.palette.secondary.main,
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <SubCard sx={{ width: "100%", padding: 1 }}>
                        {cyclesLoading ? (
                            <Stack spacing={1}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Skeleton variant="rounded" height={25} animation="wave" width="40%" />
                                </Box>

                                <Skeleton variant="rounded" animation="wave" width="100%" height={300} />
                            </Stack>
                        ) : (
                            <CustomerCycleGraph options={cyclesByCustomerChartOptions} />
                        )}
                    </SubCard>
                </Grid>
                <Grid xs={12}>
                    <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        value={activeTab}
                        variant="fullWidth"
                        onChange={(_, newVal) => setActiveTab(newVal)}>
                        <Tab
                            label={
                                <Typography variant="h6" fontWeight={"bold"}>
                                    {t("Active Cycles")}
                                </Typography>
                            }
                            id="dashboard-tab-0"
                        />
                        <Tab
                            label={
                                <Typography variant="h6" fontWeight={"bold"}>
                                    {t("Bio-Challenge & PRV Due Dates")}
                                </Typography>
                            }
                            id="dashboard-tab-1"
                        />
                        <Tab
                            label={
                                <Typography variant="h6" fontWeight={"bold"}>
                                    {t("Active Users")}
                                </Typography>
                            }
                            id="dashboard-tab-2"
                        />
                    </Tabs>
                    <TabPanel value={activeTab} index={0} dir={theme.direction}>
                        <ActiveCyclesTable activeCycles={activeCycles} cyclesLoading={cyclesLoading} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1} dir={theme.direction}>
                        <DueDatesTable customerDevicesOnly={customerDevicesOnly} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2} dir={theme.direction}>
                        <ActiveUsersTable users={activeUsers} loading={usersLoading} />
                    </TabPanel>
                </Grid>
            </Grid>
        </MainCard>
    );
};

Dashboard.propTypes = {};

export default Dashboard;
