/*
*  Modal responsible for De-activating a site admin
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Trans} from "react-i18next";
import SimpleLoader from "./SimpleLoader";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";
import get from "lodash/get";
import {prettyGroupName} from "./library/helpers";

const Auth = new AuthService();

class DeleteSiteAdminModal extends React.Component {
  state = {
    allowModalToBeClosed: true,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({
      siteAdminBeingDeleted: this.props.siteAdminBeingDeleted,
      groupName: prettyGroupName(get(this.props.siteAdminBeingDeleted, 'groups[0].name', 'Portal User')),
    })
  }

  deleteSiteAdmin = () => {
    const {siteAdminBeingDeleted} = this.state;
    const siteAdminBeingDeletedEmployeeID = siteAdminBeingDeleted.employee.id;
    const siteAdminBeingDeletedUsername = siteAdminBeingDeleted.username;

    this.setState({
      appLoading: true,
      allowModalToBeClosed: false
    });
    Auth.fetch(`/api/employee/${siteAdminBeingDeletedEmployeeID}/`, {
      method: 'DELETE',
    }).then(data => {
      this.setState({
        appLoading: false,
        allowModalToBeClosed: true
      });

      this.props.handleDeleteSiteAdminModal();
      this.props.fetchSiteAdmins();
      if (this.props.allUsers === false) {
        this.props.fetchDeviceOperators();
      }
      // this.props.notifySiteAdminDeactivationSuccess(siteAdminBeingDeletedUsername);
      this.props.notifyGenericSuccess("archived", "site admin", siteAdminBeingDeletedUsername);
    }).catch(e => {
      Sentry.captureException(e);
      // this.props.notifySiteAdminDeactivationFailure(siteAdminBeingDeletedUsername);
      this.props.notifyGenericFailure("archive", "site admin", siteAdminBeingDeletedUsername);
      this.setState({
        appLoading: false,
        allowModalToBeClosed: true
      });
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  render() {
    // const topMargin = this.state.height < 1000 ? '3vh' : '10vh';
    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '600px',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    const {
      appLoading,
    } = this.state;

    const {
      t,
      siteAdminBeingDeleted
    } = this.props;

    const siteAdminUsername = siteAdminBeingDeleted.username;
    const groupName = this.state.groupName; //ignore the i18n warning about this variable. the translation works just fine

    return (
      <TransitionablePortal
        open={this.props.showDeleteSiteAdminModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.handleDeleteSiteAdminModal(this.props.siteAdminBeingDeleted)
          }}
        >
          {appLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2 className='red'>
              <Trans ns="translations" i18nKey="archive_user_with_group_name">
                Archive {{groupName}}
              </Trans>
            </h2>
          </Header>
          <Modal.Content className='create-device-operator-cust'>
            <div className='short-modal-text center-text'>
              <Trans ns="translations" i18nKey="archive_user_confirmation">
                Are you sure you want to archive {{siteAdminUsername}}'s {{groupName}} account?
              </Trans>
            </div>
            <div className='short-modal-text center-text'>
              {t('deactivate-site-admin-dialogue', 'They will no longer be able to log into the Sterilis portal, and their account will need to be re-activated if they wish to use it again.')}
            </div>
            <div className='short-modal-text center-text'>
              {t('deactivate-site-admin-dialogue-2', 'Their device operator logon will also be disabled.')}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='red-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    id='deactivateSiteAdmin'
                    onClick={this.deleteSiteAdmin}
            >
              <Trans ns="translations" i18nKey="archive_user_with_group_name">
                Archive {{groupName}}
              </Trans>
            </Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(DeleteSiteAdminModal);
