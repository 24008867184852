/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useRef, useEffect, useState} from 'react';
import Highcharts from "highcharts";
import PropTypes from 'prop-types';
import HighchartsReact from "highcharts-react-official";
import {useTranslation} from "react-i18next";
import {
  calcTitleMargin,
  calcSingleChartHeight,
  dutyCycleConstants,
  dutyCycleYAxisLabel,
  zoomButtonOptions,
  tempConstants,
  pressureConstants, defaultPlotOptions, getTempSeries, getPresSeries, getChartLegend, chartTooltip
} from './ChartHelpers'
import {useZoomParams} from "../library/hooks";
import moment from "moment";


const SeparateSteamChart = props => {
  const {t} = useTranslation('translations');

  const titleMargin = calcTitleMargin(props.plotBands.length);
  const singleChartHeight = calcSingleChartHeight(props.plotBands.length);
  const dutyCycleYAxis = {
    ...dutyCycleYAxisLabel,
    plotLines: props.dutyCycleValuesOverThreshHold ? dutyCycleConstants : false
  };

  const chartLegend = getChartLegend(props.plotDutyCycle, false);

  const tempSeries = React.useMemo(() => {
    const selectedCycle = getTempSeries(
      props.thList,
      props.tvList,
      props.trList,
      props.dutyCycleList,
      props.plotDutyCycle
    );
    if (props.overlayedCycles.length > 0) {
      const overlayedSeries = props.overlayedCycles.flatMap((overlayedCycle, idx) => {
        const counter = idx + 1;
        const cycleStartTime = moment.utc(overlayedCycle['raw_start_time']).format("MM-DD HH:mm:ss z");
        return getTempSeries(
          overlayedCycle.th_list,
          overlayedCycle.tv_list,
          overlayedCycle.tr_list,
          overlayedCycle.duty_cycle_list,
          props.plotDutyCycle,
          counter,
          true,
          cycleStartTime);
      });
      return [...selectedCycle, ...overlayedSeries];
    }
    return selectedCycle

  }, [props.overlayedCycles, props.timeList, props.showAbnormalSeriesValues, props.plotDutyCycle]);
  const presSeries = React.useMemo(() => {
    const selectedCycle = getPresSeries(
      props.paList,
      props.psList,
      props.pvList,
      props.dutyCycleList,
      props.plotDutyCycle
    );
    if (props.overlayedCycles.length > 0) {
      const overlayedSeries = props.overlayedCycles.flatMap((overlayedCycle, idx) => {
        const counter = idx + 1;
        const cycleStartTime = moment.utc(overlayedCycle['raw_start_time']).format("MM-DD HH:mm:ss z");
        return getPresSeries(
          overlayedCycle.pa_list,
          overlayedCycle.ps_list,
          overlayedCycle.pv_list,
          overlayedCycle.duty_cycle_list,
          props.plotDutyCycle,
          counter,
          true,
          cycleStartTime);
      });
      return [...selectedCycle, ...overlayedSeries];
    }
    return selectedCycle

  }, [props.overlayedCycles, props.timeList, props.showAbnormalSeriesValues, props.plotDutyCycle]);

  const tooltip = function () {
    return chartTooltip(this, props.timeList, props.cycleStartTime, props.plotBands);
  };


  const [tempOptions, setTempOptions] = useState({
    chart: {
      zoomType: 'x',
      height: singleChartHeight,
      ignoreHiddenSeries: false,
      resetZoomButton: zoomButtonOptions
    },
    plotOptions: defaultPlotOptions,
    tooltip: {
      shared: true,
      useHTML: true,
      animation: false,
      formatter: tooltip
    },
    legend: chartLegend,
    title: {
      text: `${props.customTitlePrefix && props.customTitlePrefix} Temperature`,
      margin: titleMargin,
    },
    xAxis: [{
      categories: props.timeList,
      crosshair: true,
      plotBands: props.plotBands,
      plotLines: props.overlayInfo && props.infoConstants,
      events: {
        afterSetExtremes: function (event) {
          if (event.trigger === 'zoom' && props.setZoomParams) {
            props.setZoomParams({min: event.min, max: event.max})
          }
        }
      }
    }],
    yAxis: [{ // Primary yAxis
      labels: {
        format: '{value}°C',
        style: {
          color: Highcharts.getOptions().colors[0]
        }
      },
      title: {
        text: t('Temperature'),
        style: {
          color: Highcharts.getOptions().colors[0]
        }
      },
      plotLines: props.showTempConstants ? tempConstants : false,
    },
      ...props.plotDutyCycle ? [dutyCycleYAxis] : [],
    ],
    credits: false,
    series: tempSeries,
  });

  const [presOptions, setPresOptions] = useState({
    chart: {
      zoomType: 'x',
      height: singleChartHeight,
      ignoreHiddenSeries: false,
      resetZoomButton: zoomButtonOptions
    },
    tooltip: {
      shared: true,
      useHTML: true,
      animation: false,
      formatter: tooltip
    },
    legend: chartLegend,
    plotOptions: defaultPlotOptions,
    title: {
      text: `${props.customTitlePrefix && props.customTitlePrefix} Pressure`,
      margin: titleMargin,
    },
    xAxis: [{
      categories: props.timeList,
      crosshair: true,
      plotBands: props.plotBands,
      plotLines: props.overlayInfo && props.infoConstants,
      events: {
        afterSetExtremes: function (event) {
          if (event.trigger === 'zoom') {
            props.setZoomParams({min: event.min, max: event.max})
          }
        }
      }
    }],
    yAxis: [{
      title: {
        text: t('Pressure'),
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      labels: {
        format: `{value} PSI`,
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      plotLines: props.showPresConstants ? pressureConstants : false,
    },
      ...props.plotDutyCycle ? [dutyCycleYAxis] : [],
    ],
    credits: false,
    series: presSeries,
  });

  useEffect(() => {
    const updatedOptions = {
      chart: {
        height: singleChartHeight,
      },
      title: {
        margin: titleMargin,
      },
      tooltip: {
        // need to re-declare this in the uuseeffect update because it needs new timelist props on cycle change
        formatter: tooltip
      },
      legend: chartLegend,
      xAxis: [{
        categories: props.timeList,
        plotBands: props.plotBands,
        plotLines: props.overlayInfo && props.infoConstants,
      }],
      yAxis: [
        {
          plotLines: props.showTempConstants ? tempConstants : false,
        },
        ...props.plotDutyCycle ? [dutyCycleYAxis] : [],
      ],
      series: tempSeries,
    };
    setTempOptions(updatedOptions);
  }, [props.timeList, props.plotBands, props.overlayInfo, props.infoConstants,
    props.showTempConstants, props.burninChart, tempSeries]);

  useEffect(() => {
    const updatedOptions = {
      chart: {
        height: singleChartHeight,
      },
      title: {
        margin: titleMargin,
      },
      legend: chartLegend,
      tooltip: {
        // need to re-declare this in the uuseeffect update because it needs new timelist props on cycle change
        formatter: tooltip
      },
      xAxis: [{
        categories: props.timeList,
        plotBands: props.plotBands,
        plotLines: props.overlayInfo && props.infoConstants,
      }],
      yAxis: [
        {
          plotLines: props.showPresConstants ? pressureConstants : false,
        },
        ...props.plotDutyCycle ? [dutyCycleYAxis] : [],
      ],
      series: presSeries,
    };
    setPresOptions(updatedOptions);

  }, [props.timeList, props.plotBands, props.overlayInfo, props.infoConstants, props.showPresConstants,
    props.burninChart, presSeries]);

  const presRef = useRef();
  const tempRef = useRef();
  useZoomParams(props.zoomParams, presRef, props.timeList);
  useZoomParams(props.zoomParams, tempRef, props.timeList);

  return <React.Fragment>
    <HighchartsReact
      ref={tempRef}
      highcharts={Highcharts}
      options={tempOptions}
    />
    <HighchartsReact
      ref={presRef}
      highcharts={Highcharts}
      options={presOptions}
    />
  </React.Fragment>
};

SeparateSteamChart.defaultProps = {
  thList: [],
  tvList: [],
  trList: [],
  paList: [],
  psList: [],
  pvList: [],
  iList: [],
  timeList: [],
  plotBands: [],
  dutyCycleList: [],
  showTempConstants: true,
  showPresConstants: true,
  combineTPGraphs: true,
  showCurrentGraph: true,
  overlayInfo: false,
  overlayStates: false,
  plotDutyCycle: false,
  dutyCycleValuesOverThreshHold: false,
  showAbnormalSeriesValues: false,
  burninChart: false,
  abnormalThValues: {},
  abnormalTvValues: {},
  abnormalTrValues: {},
  abnormalPaValues: {},
  abnormalPsValues: {},
  abnormalPvValues: {},
  abnormalIValues: {},
  abnormalDutyCycleValues: {},
  customTitlePrefix: '',
  zoomParams: null,
  overlayedCycles: [],
};

SeparateSteamChart.propTypes = {
  thList: PropTypes.array,
  tvList: PropTypes.array,
  trList: PropTypes.array,
  paList: PropTypes.array,
  psList: PropTypes.array,
  iList: PropTypes.array,
  pvList: PropTypes.array,
  timeList: PropTypes.array,
  plotBands: PropTypes.array,
  dutyCycleList: PropTypes.array,
  showPresConstants: PropTypes.bool,
  combineTPGraphs: PropTypes.bool,
  showCurrentGraph: PropTypes.bool,
  overlayInfo: PropTypes.bool,
  overlayStates: PropTypes.bool,
  plotDutyCycle: PropTypes.bool,
  dutyCycleValuesOverThreshHold: PropTypes.bool,
  showAbnormalSeriesValues: PropTypes.bool,
  burninChart: PropTypes.bool,
  abnormalThValues: PropTypes.object,
  abnormalTvValues: PropTypes.object,
  abnormalTrValues: PropTypes.object,
  abnormalPaValues: PropTypes.object,
  abnormalPsValues: PropTypes.object,
  abnormalPvValues: PropTypes.object,
  abnormalIValues: PropTypes.object,
  abnormalDutyCycleValues: PropTypes.object,
  customTitlePrefix: PropTypes.string,
  zoomParams: PropTypes.object,
  setZoomParams: PropTypes.func,
  overlayedCycles: PropTypes.array,
};

export default SeparateSteamChart;
