/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

const GrindCycleTrendsChart = (props) => {
    const { t } = useTranslation("translations");
    const theme = useTheme();
    const {
        triesTrend,
        durationTrend,
        jackTrend,
        stallsTrend,
        numberStallsTrend,
        forwardDurationTrend,
        reverseDurationTrend,
        // @ts-ignore
    } = useSelector((store) => store.metric.grindCycleTrends);
    const combinedGrindCycleTrendOptions = {
        chart: {
            zoomType: "x",
            resetZoomButton: {
                position: {
                    x: 0,
                    y: 0,
                },
            },
        },
        tooltip: {
            shared: true,
        },
        title: {
            text: "Grind Cycle Trends",
        },
        credits: false,
        time: {
            useUTC: false,
        },
        xAxis: [
            {
                type: "datetime",
                crosshair: true,
            },
        ],
        yAxis: [
            ...(props.trendTries
                ? [
                      {
                          id: "triesYAxisLabel",
                          labels: {
                              format: "Tries: {value}",
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "dodgerblue",
                              },
                          },
                          title: {
                              text: t("Tries"),
                              style: {
                                  color: "dodgerblue",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendGrindDuration
                ? [
                      {
                          id: "grindDurationYAxisLabel",
                          title: {
                              text: "Grind Duration",
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "lightseagreen",
                              },
                          },
                          labels: {
                              format: `{value} mins`,
                              style: {
                                  color: "lightseagreen",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendJack
                ? [
                      {
                          id: "jacksYAxisLabel",
                          title: {
                              text: t("Jacks"),
                              style: {
                                  color: "fuchsia",
                              },
                          },
                          labels: {
                              format: `{value}`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "fuchsia",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendStalls
                ? [
                      {
                          id: "stallsYAxisLabel",
                          title: {
                              text: t("Stalls"),
                              style: {
                                  color: "MediumSlateBlue",
                              },
                          },
                          labels: {
                              format: `{value}`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "MediumSlateBlue",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendNumberStalls
                ? [
                      {
                          id: "numberStallsYAxisLabel",
                          title: {
                              text: t("Number of Stalls"),
                              style: {
                                  color: "MediumTurquoise",
                              },
                          },
                          labels: {
                              format: `{value}`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "MediumTurquoise",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendForwardDuration
                ? [
                      {
                          id: "forwardDurationYAxisLabel",
                          title: {
                              text: t("Forward Duration"),
                              style: {
                                  color: "LightSalmon",
                              },
                          },
                          labels: {
                              format: `{value} secs`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "LightSalmon",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendReverseDuration
                ? [
                      {
                          id: "reverseDurationYAxisLabel",
                          title: {
                              text: t("Reverse Duration"),
                              style: {
                                  color: "BlueViolet",
                              },
                          },
                          labels: {
                              format: `{value} secs`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "BlueViolet",
                              },
                          },
                      },
                  ]
                : []),
        ],
        series: [
            ...(props.trendTries
                ? [
                      {
                          name: "Tries",
                          data: triesTrend,
                          yAxis: "triesYAxisLabel",
                          color: "dodgerblue",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalTriesValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalTriesValues["maximum_value"],
                                            color: "dodgerblue",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendGrindDuration
                ? [
                      {
                          name: "Grind Duration",
                          data: durationTrend,
                          yAxis: "grindDurationYAxisLabel",
                          color: "lightseagreen",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalGrindDurationValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalGrindDurationValues["maximum_value"],
                                            color: "lightseagreen",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendJack
                ? [
                      {
                          name: "Jacks",
                          data: jackTrend,
                          yAxis: "jacksYAxisLabel",
                          color: "fuchsia",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalJackValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalJackValues["maximum_value"],
                                            color: "fuchsia",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendStalls
                ? [
                      {
                          name: "Stalls",
                          data: stallsTrend,
                          yAxis: "stallsYAxisLabel",
                          color: "MediumSlateBlue",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalStallsValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalStallsValues["maximum_value"],
                                            color: "MediumSlateBlue",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendNumberStalls
                ? [
                      {
                          name: "Number of Stalls",
                          data: numberStallsTrend,
                          yAxis: "numberStallsYAxisLabel",
                          color: "MediumTurquoise",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalNumberStallsValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalNumberStallsValues["maximum_value"],
                                            color: "MediumTurquoise",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendForwardDuration
                ? [
                      {
                          name: "Forward Duration",
                          data: forwardDurationTrend,
                          yAxis: "forwardDurationYAxisLabel",
                          color: "DarkSeaGreen",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalForwardDurationValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalForwardDurationValues["maximum_value"],
                                            color: "DarkSeaGreen",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendReverseDuration
                ? [
                      {
                          name: "Reverse Duration",
                          data: reverseDurationTrend,
                          yAxis: "reverseDurationYAxisLabel",
                          color: "BlueViolet",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalReverseDurationValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalReverseDurationValues["maximum_value"],
                                            color: "BlueViolet",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
        ],
    };
    return <HighchartsReact highcharts={Highcharts} options={combinedGrindCycleTrendOptions} />;
};

GrindCycleTrendsChart.defaultProps = {
    hideAxisLabels: true,
    trendStalls: true,
    trendGrindDuration: false,
    trendJack: true,
    trendNumberStalls: true,
    trendTries: true,
    trendForwardDuration: false,
    trendReverseDuration: false,
    showAbnormalTrendValues: false,
    cycleStartTimes: [],
    abnormalGrindDurationValues: {},
    abnormalStallsValues: {},
    abnormalJackValues: {},
    abnormalNumberStallsValues: {},
    abnormalTriesValues: {},
    abnormalForwardDurationValues: {},
    abnormalReverseDurationValues: {},
};

GrindCycleTrendsChart.propTypes = {
    hideAxisLabels: PropTypes.bool,
    cycleStartTimes: PropTypes.array,
    numberStallsTrend: PropTypes.array,
    trendStalls: PropTypes.bool,
    trendJack: PropTypes.bool,
    trendNumberStalls: PropTypes.bool,
    trendTries: PropTypes.bool,
    trendForwardDuration: PropTypes.bool,
    trendGrindDuration: PropTypes.bool,
    showAbnormalTrendValues: PropTypes.bool,
    trendReverseDuration: PropTypes.bool,
    abnormalGrindDurationValues: PropTypes.object,
    abnormalStallsValues: PropTypes.object,
    abnormalJackValues: PropTypes.object,
    abnormalNumberStallsValues: PropTypes.object,
    abnormalTriesValues: PropTypes.object,
    abnormalForwardDurationValues: PropTypes.object,
    abnormalReverseDurationValues: PropTypes.object,
};

export default GrindCycleTrendsChart;
