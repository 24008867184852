/*
*  Main housing component for all the User Setting tabs
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {isGroupNotCustomer} from "../library/helpers";
import {Card, Tab} from 'semantic-ui-react';
import EditUserInfo from './EditUserInfo';
import ChangeUserPassword from './ChangeUserPassword';
import ChangeUserPortalSettings from './ChangeUserPortalSettings';


const UserSettings = props => {
  const settingsPanes = [
    {
      menuItem: props.t('Edit Account Information'),
      pane: {
        key: 'tab0',
        className: 'full-height-vertical-tab',
        content: <EditUserInfo
          user={props.user}
          group={props.group}

        />
      }
    },
    {
      menuItem: props.t('Change Password'),
      pane: {
        key: 'tab1',
        className: 'full-height-vertical-tab',
        content: <ChangeUserPassword
        />
      }
    },
    {
      menuItem: props.t('Portal Settings'),
      pane: {
        key: 'tab2',
        className: 'full-height-vertical-tab',
        content: <ChangeUserPortalSettings
        />
      }
    },

  ];
  return <div className={isGroupNotCustomer(props.group) ? '' : 'customer-container'}>
    <Card fluid>
      <Card.Header style={{'margin': '10px'}}>
        <h2>{props.t('Edit your profile')}</h2>
      </Card.Header>
      <Card.Content className="card-body">
        <Tab
          menu={{fluid: true, vertical: true}}
          panes={settingsPanes}
          renderActiveOnly={false}
        />
      </Card.Content>
    </Card>
  </div>
};

export default translate('translations')(UserSettings);