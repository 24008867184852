import userMenu from "./images/customer/user-menu.gif";
import sideNav from "./images/sterilis/navigation/side-nav.gif";
import logoNav from "./images/customer/logo-nav.gif";
import notificationSettings from "./images/customer/notifications-user-settings.gif";
import facilityAdmin from "./images/customer/facility-admin.gif";
import cycleSelection from "./images/sterilis/cyclemetrics/cycle-select.gif";
import steamCycle from "./images/sterilis/cyclemetrics/steam-cycle.gif";
import grindCycle from "./images/sterilis/cyclemetrics/grind-cycle.gif";


export const changelog = [
    { type: "new", audience: "customer", desc: "Added new user menu with username and version information", details: "Menu options will vary based on user permissions.", images: [{ id: "usermenu", src: userMenu, title: "User Menu" }] },
    { type: "new", audience: "customer", desc: "Added new Sterilis logo to navigation bar.", details: "Clicking the logo navigates to the home page", images: [{ id: "logonav", src: logoNav, title: "Navigation Logo" }] },
    { type: "new", audience: "customer", desc: "Added notification settings dropdown to user settings.", details: "Notification settings will affect all devices the user has access to.", images: [{ id: "notifications", src: notificationSettings, title: "Notification Settings" }] },
    { type: "new", audience: "customer", desc: "Added Facility Admin user role.", details: "Facility Admin users have the same access as Site Admins but are restricted to their assigned Facilities.", images: [{ id: "facadmin", src: facilityAdmin, title: "Create Facility Admin" }] },
    { type: "new", audience: "sterilis", desc: "Added new side navigation menu", details: "Navigation items are split by entity (i.e. Device, Customer, etc.) then each entity has actions such as Create, View, etc.", images: [{ id: "sidenav", src: sideNav, title: "Side Navigation" }] },
    { type: "fix", audience: "sterilis", desc: "Fixed issue with linux kernel version reporting incorrectly.", images: [] },
    { type: "new", audience: "sterilis", desc: "Added new Cycle Metrics dashboard for viewing cycle sensor data.", details: "This functionality is only available for devices running firmware v3.9.4.2 or higher.", images: [{ id: "cycle-select", src: cycleSelection, title: "Cycle Selection" }, { id: "steam-cycle", src: steamCycle, title: "Steam Cycle" }, { id: "grind-cycle", src: grindCycle, title: "Grind Cycle" }] }
];
