/*
* Home component for the Ethel log parser
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useState, useEffect} from 'react';
import withAuth from "../withAuth";
import {Button, Card, Checkbox} from "semantic-ui-react";
import SimpleLoader from "../SimpleLoader";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from 'react-hook-form';
import * as Sentry from "@sentry/browser";
import AuthService from "../AuthService";
import moment from "moment";
import ParsedCycleView from "./ParsedCycleView";
import queryString from "query-string";
import {notifyFailureToast, getRoundedNumber} from "../library/helpers"
import DayPickerInput from "react-day-picker/DayPickerInput";
import AvailableEthelFilesTable from "./AvailableEthelFilesTable";
import DeviceDropdown from "../library/DeviceDropdown";
import ScrollTopArrow from "../library/ScrollTopArrow";
import {useHistory} from 'react-router-dom';
import get from "lodash/get";

const Auth = new AuthService();

const LogParserHomepage = (props) => {
	const [loadingText, setLoadingText] = useState("");
	const [loadingFromExternal, setLoadingFromExternal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [containsBurninTest, setContainsBurninTest] = useState(false);
	const [batchProcessing, setBatchProcessing] = useState(false);
	const [initialLoadFromBatch, setInitialLoadFromBatch] = useState(false);
	const [parsedLog, setParsedLog] = useState([]);
	const [steamCycleTrends, setSteamCycleTrends] = useState({});
	const [grindCycleTrends, setGrindCycleTrends] = useState({});
	const [sharedCycleTrends, setSharedCycleTrends] = useState({});
	const [abnormalValuesObj, setAbnormalValuesObj] = useState({});
	const [abnormalValues, setAbnormalValues] = useState([]);
	const [cycleStartTimes, setCycleStartTimes] = useState([]);
	const [steamCycleStartTimes, setSteamCycleStartTimes] = useState([]);
	const [grindCycleStartTimes, setGrindCycleStartTimes] = useState([]);
	const [deviceDropdownOptions, setDeviceDropdownOptions] = useState([]);
	const [availableEthelFiles, setAvailableEthelFiles] = useState([]);
	const [showEthelFileTable, setShowEthelFileTable] = useState(false);
	const [filterByDate, setFilterByDate] = useState(false);
	const [deviceInfo, setDeviceInfo] = useState({});
	const [fileID, setFileID] = useState("");
	const [fileName, setFileName] = useState("");
	const [deviceConfigID, setDeviceConfigID] = useState();
	const [startDate, setStartDate] = useState(moment().subtract(1, "months").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
	const methods = useForm();
	const { register: singleFileRegister, errors: singleFileErrors, handleSubmit: singleFileHandleSubmit } = useForm();
	const [ethelFilesToParse, setEthelFilesToParse] = useState({});
	const { t } = useTranslation("translations");
	const [distributorDropdownArray, setDistributorDropdownArray] = useState([
		{
			key: 0,
			value: 0,
			text: t("All Distributors"),
		},
	]);
	const [test7CycleIDs, setTest7CycleIDs] = useState([]);
	const watchDevice = methods.watch("deviceConfigID");
	const history = useHistory();

	useEffect(() => {
		// when the batch processor device selection changes, hide & clear table, clear selected files
		if (watchDevice) {
			setShowEthelFileTable(false);
			setAvailableEthelFiles([]);
			setEthelFilesToParse({});
		}
	}, [watchDevice]);

	useEffect(() => {
		document.title = "Log Parser";
		if (queryString.parse(props.location.search).file_id) {
			const ethelLogFileID = queryString.parse(props.location.search).file_id;
			fetchParserFromFileID(ethelLogFileID);
		} else if (
			queryString.parse(props.location.search).file_ids &&
			queryString.parse(props.location.search).device_config_id
		) {
			const deviceConfigIDFromQuerystring = queryString.parse(props.location.search).device_config_id;
			if (props.location.search.includes("|")) {
				const ethelLogFileIDs = queryString
					.parse(props.location.search, { arrayFormat: "separator", arrayFormatSeparator: "|" })
					.file_ids.split("|");
				processBatchFiles(ethelLogFileIDs, deviceConfigIDFromQuerystring);
			} else {
				const ethelLogFileIDs = queryString
					.parse(props.location.search, { arrayFormat: "comma" })
					.file_ids.split(",");
				processBatchFiles(ethelLogFileIDs, deviceConfigIDFromQuerystring);
			}
		} else {
			fetchDevices();
		}
		// Check for test 7 cycle ids in the parser URL
		if (queryString.parse(props.location.search).test_7_cycle_ids) {
			// If this URL is coming from the excel sheet, the delimiter is a |
			if (props.location.search.includes("|")) {
				const test7CycleIDs = queryString
					.parse(props.location.search, { arrayFormat: "separator", arrayFormatSeparator: "|" })
					.test_7_cycle_ids.split("|") // split string of ids on delimiter,
					.map((id) => Number(id)); // convert str to number for each comparison later on
				setTest7CycleIDs(test7CycleIDs);
			} else {
				const test7CycleIDs = queryString
					.parse(props.location.search, { arrayFormat: "comma" })
					.test_7_cycle_ids.split(",") // split string of ids on delimiter,
					.map((id) => Number(id)); // convert str to number for each comparison later on
				setTest7CycleIDs(test7CycleIDs);
			}
		}
	}, []);

	const fetchParserFromFileID = (ethelFileID) => {
		setIsLoading(true);
		setLoadingFromExternal(true);
		setLoadingText(t("Parsing log"));
		Auth.fetch(`/log_parser/parse_cycle`, {
			method: "POST",
			body: JSON.stringify({
				file_id: ethelFileID,
				file_type: "log",
			}),
		})
			.then((data) => {
				setFileID(ethelFileID);
				setFileName(data["file_name"]);
				setLoadingText(t("Building charts"));
				processParsedLog(data);
				setLoadingFromExternal(false);
			})
			.catch((err) => {
				onError(err);
				setLoadingFromExternal(false);
			});
	};

	const fetchDeviceConfigFromLogName = (ethelLogName) => {
		if (ethelLogName.match(/([0-9]{3}-[0-9]{5})/)) {
			const serial = ethelLogName.match(/([0-9]{3}-[0-9]{5})/)[0];
			return Auth.fetch(`/api/get-device-config-from-serial/`, {
				method: "POST",
				body: JSON.stringify({
					serial_number: serial,
				}),
			})
				.then((data) => {
					setDeviceInfo(data);
				})
				.catch((err) => {
					if (err.code !== 20) {
						Sentry.captureException(err);
					}
				});
		}
	};

	const onSubmit = (data) => {
		const ethelLog = data["ethelLogFile"][0];
		const formData = new FormData();
		formData.append("log_file", ethelLog);
		formData.append("file_type", "log");
		setIsLoading(true);
		setLoadingText(t("Parsing log"));
		Auth.file_fetch(`/log_parser/parse_cycle`, {
			method: "POST",
			body: formData,
		})
			.then((data) => {
				fetchDeviceConfigFromLogName(ethelLog.name);
				processParsedLog(data);
			})
			.catch((err) => {
				onError(err);
			});
	};

	const toggleEthelLogParse = (fileRequestS3ID) => {
		setEthelFilesToParse((prevState) => ({
			...prevState,
			[fileRequestS3ID]: !prevState[fileRequestS3ID],
		}));
	};

	const fetchFilesForBatch = (data) => {
		setDeviceConfigID(data["deviceConfigID"]);
		setIsLoading(true);
		const loadingText = filterByDate ? `Finding Ethel logs from ${startDate} to ${endDate}` : `Finding Ethel logs`;
		setLoadingText(loadingText);
		const fetchURL = filterByDate
			? `/api/file-request-file?start_date=${startDate}&end_date=${endDate}&device_config_id=${data["deviceConfigID"]}&log_type=ethel`
			: `/api/file-request-file?device_config_id=${data["deviceConfigID"]}&log_type=ethel`;
		Auth.fetch(fetchURL)
			.then((data) => {
				// Filter out duplicates via a reduce where we only append to the acc if the acc doesn't have the s3 upload
				// url, aka the file name, in the acc already.
				const filesAvailableArrayNoDuplicates = data
					.filter((file) => file["file_request_s3_file"] && file["status"] === "OnPortal")
					.reduce((acc, fileOnPortal) => {
						if (
							!acc.find(
								(file) =>
									file["file_request_s3_file"]["upload"] ===
									fileOnPortal["file_request_s3_file"]["upload"]
							)
						) {
							acc.push(fileOnPortal);
						}
						return acc;
					}, []);

				const tempEthelFilesToParse = data
					.filter((file) => file["file_request_s3_file"] && file["status"] === "OnPortal")
					.reduce((acc, curr) => {
						acc[curr["file_request_s3_file"]["id"]] = false;
						return acc;
					}, {});

				setEthelFilesToParse(tempEthelFilesToParse);
				setAvailableEthelFiles(filesAvailableArrayNoDuplicates);
				setShowEthelFileTable(true);
				setIsLoading(false);
			})
			.catch((err) => {
				onError(err);
			});
	};

	const processBatchFiles = (ethelFiles, deviceConfigIDFromQuerystring = false) => {
		setIsLoading(true);
		setLoadingText(`Parsing the selected Ethel files...`);
		const batchDeviceConfigID = deviceConfigIDFromQuerystring ? deviceConfigIDFromQuerystring : deviceConfigID;
		history.push(`/log-parser?device_config_id=${batchDeviceConfigID}&file_ids=${ethelFiles}`);

		Auth.fetch(`/log_parser/parse_cycle`, {
			method: "POST",
			body: JSON.stringify({
				file_type: "batch",
				ethel_file_ids: ethelFiles,
				device_config_id: batchDeviceConfigID,
			}),
		})
			.then((data) => {
				setBatchProcessing(true);
				setInitialLoadFromBatch(true);
				processParsedLog(data);
			})
			.catch((err) => {
				onError(err);
			});
	};

	const getPrettyCycleType = ({ cycle_type: cycleType, test_type: testType }) => {
		if (cycleType === "SteamCycle") {
			return "Steam Cycle";
		} else if (cycleType === "GrindCycle") {
			return "Grind Cycle";
		} else if (cycleType === "BurninTest") {
			return `Burn in Test ${testType ? testType : ""}`;
		} else {
			return "Unknown Cycle Type";
		}
	};

	const processParsedLog = (data) => {
		if (data["device_info"]) {
			setDeviceInfo(data["device_info"]);
			document.title = `${data["device_info"]["serial_number"]} Log Parser`;
		}

		if (data["cycle_list"].length === 0) {
			setIsLoading(false);
			return notifyFailureToast("No cycles found in processed log(s)");
		}

		if (data["abnormal_values"]) {
			// create an easily referenced dict for abnormal min/max values
			const abnormalValueObj = data["abnormal_values"].reduce((acc, cur) => {
				acc[cur["value_name"]] = {
					...cur,
				};
				return acc;
			}, {});
			setAbnormalValuesObj(abnormalValueObj);
			setAbnormalValues(data["abnormal_values"]);
		}

		const steamCycleTrends = {};

		steamCycleTrends["scoreTrend"] = [];
		steamCycleTrends["tvAvgHoldTrend"] = [];
		steamCycleTrends["tpDiffTrend"] = [];
		steamCycleTrends["durationTrend"] = [];

		steamCycleTrends["flowSecondsTimeTrend"] = [];
		steamCycleTrends["steamPumpdownTimeTrend"] = [];
		steamCycleTrends["drainTimeTrend"] = [];
		steamCycleTrends["heatUpTimeTrend"] = [];
		steamCycleTrends["pumpdownTimeoutTrend"] = [];
		steamCycleTrends["tvStartTrend"] = [];
		steamCycleTrends["tvMaxTrend"] = [];
		steamCycleTrends["trAvgTrend"] = [];
		steamCycleTrends["tvAvgDuringHoldTrend"] = [];
		steamCycleTrends["pvStartTrend"] = [];
		steamCycleTrends["pvMinTrend"] = [];
		steamCycleTrends["pvMaxTrend"] = [];
		steamCycleTrends["pvAvgDuringHoldTrend"] = [];
		steamCycleTrends["heatUpRateDifferenceTrend"] = [];

		const grindCycleTrends = {};
		grindCycleTrends["durationTrend"] = [];
		grindCycleTrends["triesTrend"] = [];
		grindCycleTrends["stallsTrend"] = [];
		grindCycleTrends["jackTrend"] = [];
		grindCycleTrends["numberStallsTrend"] = [];
		grindCycleTrends["forwardDurationTrend"] = [];
		grindCycleTrends["reverseDurationTrend"] = [];

		const sharedCycleTrends = {};
		sharedCycleTrends["dutyCycleAvgTrend"] = [];
		sharedCycleTrends["dutyCycleStartTrend"] = [];
		sharedCycleTrends["dutyCycleEndTrend"] = [];

		const cycleStartTimes = [];
		const steamCycleStartTimes = [];
		const grindCycleStartTimes = [];

		data["cycle_list"].sort((a, b) => {
			const dateA = new Date(a.start_time);
			const dateB = new Date(b.start_time);
			return dateA - dateB;
		});

		const containsBurnInTestData = data["cycle_list"].some((data) => data["cycle_type"] === "BurninTest");
		if (containsBurnInTestData) {
			setContainsBurninTest(true);
		}
		const parsedData = data["cycle_list"].map((cycle) => {
			if (cycle["cycle_type"] === "Notification") {
				return {
					start_time: moment.utc(cycle["start_time"]).format("YYYY-MM-DD HH:mm:ss z"),
					pretty_cycle_type: "Message",
					cycle_type: cycle["cycle_type"],
					score: cycle["notification"],
				};
			} else {
				if (cycle["cycle_type"] === "SteamCycle") {
					addSteamTrendData(steamCycleTrends, cycle);
					steamCycleStartTimes.push(moment.utc(cycle["start_time"]).format("MM-DD HH:mm:ss"));
				}

				if (cycle["cycle_type"] === "GrindCycle") {
					addGrindTrendData(grindCycleTrends, cycle);
					grindCycleStartTimes.push(moment.utc(cycle["start_time"]).format("MM-DD HH:mm:ss"));
				}

				if (cycle["cycle_type"] === "BurninTest") {
					if (cycle["cycles"] && Array.isArray(cycle["cycles"])) {
						// iterate through all cycles in burnin tests, format their timestamps nicely
						cycle["cycles"].forEach((burninCycle) => {
							burninCycle["time_list"] = burninCycle["time_list"].map((time) => {
								return moment.utc(time).format("HH:mm:ss");
							});
						});
					}
				}

				sharedCycleTrends.dutyCycleAvgTrend.push(Number(Math.round(cycle["duty_cycle_average"])));
				sharedCycleTrends.dutyCycleStartTrend.push(Number(Math.round(cycle["duty_cycle_start"])));
				sharedCycleTrends.dutyCycleEndTrend.push(Number(Math.round(cycle["duty_cycle_end"])));

				cycleStartTimes.push(moment.utc(cycle["start_time"]).format("MM-DD HH:mm:ss"));

				const infoCount = cycle["info_constants"] ? cycle["info_constants"].length : 0;
				return {
					...cycle,
					time_list: cycle["time_list"]
						? cycle["time_list"].map((time) => {
								return moment.utc(time).format("HH:mm:ss");
						  })
						: cycle["cycles"]
						? [...[]]
						: [],
					info_count: infoCount > 0 ? infoCount : null,
					pretty_cycle_type: getPrettyCycleType(cycle),
					pretty_waste_type: get(cycle, "portal_cycle.waste_type_display", ""),
					start_time: moment.utc(cycle["start_time"]).format("YYYY-MM-DD HH:mm:ss z"),
					raw_start_time: cycle["start_time"],
					score: getRoundedNumber(cycle, "score", 2),
					duration_minutes: getRoundedNumber(cycle, "duration_minutes"),
				};
			}
		});

		setSteamCycleTrends(steamCycleTrends);
		setGrindCycleTrends(grindCycleTrends);
		setSharedCycleTrends(sharedCycleTrends);
		setCycleStartTimes(cycleStartTimes);
		setSteamCycleStartTimes(steamCycleStartTimes);
		setGrindCycleStartTimes(grindCycleStartTimes);
		setParsedLog(parsedData);
		setIsLoading(false);
	};

	const addSteamTrendData = (steamCycleTrends, cycle) => {
		steamCycleTrends.scoreTrend.push(Number(cycle["score"].toFixed(2)));
		steamCycleTrends.durationTrend.push(Number(Math.round(cycle["duration_minutes"])));
		steamCycleTrends.tvAvgHoldTrend.push(Number(cycle["tv_average_during_hold"].toFixed(2)));
		steamCycleTrends.tpDiffTrend.push(Number(cycle["tp_diff"].toFixed(2)));

		steamCycleTrends.flowSecondsTimeTrend.push(Number(cycle["flow_seconds"].toFixed(2)));
		steamCycleTrends.steamPumpdownTimeTrend.push(Number(cycle["pumpdown_duration_minutes"].toFixed(2)));
		steamCycleTrends.drainTimeTrend.push(Number(cycle["draining_duration_minutes"].toFixed(2)));
		steamCycleTrends.heatUpTimeTrend.push(Number(cycle["ramp_duration_minutes"].toFixed(2)));
		steamCycleTrends.pumpdownTimeoutTrend.push(Number(cycle["pumpdown_timeout_count"].toFixed(2)));
		steamCycleTrends.tvStartTrend.push(Number(cycle["tv_start"].toFixed(2)));
		steamCycleTrends.tvMaxTrend.push(Number(cycle["tv_max"].toFixed(2)));
		steamCycleTrends.trAvgTrend.push(Number(cycle["tr_average"].toFixed(2)));
		steamCycleTrends.tvAvgDuringHoldTrend.push(Number(cycle["tv_average_during_hold"].toFixed(2)));
		steamCycleTrends.pvStartTrend.push(cycle["pv_start"]);
		steamCycleTrends.pvMinTrend.push(Number(cycle["pv_min"].toFixed(2)));
		steamCycleTrends.pvMaxTrend.push(Number(cycle["pv_max"].toFixed(2)));
		steamCycleTrends.pvAvgDuringHoldTrend.push(Number(cycle["pv_average_during_hold"].toFixed(2)));
		// Don't need to do the rounding here, the backend has already rounded this number
		steamCycleTrends.heatUpRateDifferenceTrend.push(Number(cycle["tv_rate_of_rise"]));
		return steamCycleTrends;
	};;

	const addGrindTrendData = (grindCycleTrends, cycle) => {
		grindCycleTrends.durationTrend.push(Number(Math.round(cycle["duration_minutes"])));
		grindCycleTrends.triesTrend.push(cycle["tries"]);
		grindCycleTrends.stallsTrend.push(cycle["stalls"]);
		grindCycleTrends.jackTrend.push(cycle["jack"]);
		grindCycleTrends.numberStallsTrend.push(cycle["number_stalls"]);
		grindCycleTrends.forwardDurationTrend.push(cycle["forward_duration"]);
		grindCycleTrends.reverseDurationTrend.push(cycle["reverse_duration"]);
		return grindCycleTrends;
	};

	const onError = (err) => {
		err.response.json().then((errInfo) => {
			if (errInfo["message"]) {
				notifyFailureToast(errInfo["message"]);
			}
		});
		notifyFailureToast();
		console.log(err);
		Sentry.captureException(err);
		setIsLoading(false);
	};

	const fetchDevices = () => {
		setIsLoading(true);
		return Auth.fetch(`/api/export/device-configs/`, {
			method: "GET",
		})
			.then((data) => {
				const deviceOptions = data.map((deviceConfig) => {
					return {
						key: deviceConfig.id,
						value: deviceConfig.id,
						text: `${deviceConfig.customer} : ${deviceConfig.facility} : ${deviceConfig.device.serial_number}`,
						customer_id: deviceConfig.customer_id,
						distributor_id: deviceConfig.distributor_id,
					};
				});

				// good example of find unique distributors reduce
				const distributorOptions = data.reduce(
					(acc, curr) => {
						if (acc.some((dist) => dist["value"] === curr["distributor_id"]) === false) {
							acc.push({
								key: curr["distributor_id"],
								value: curr["distributor_id"],
								text: curr["distributor"],
							});
						}
						return acc;
					},
					[...distributorDropdownArray]
				);

				setDeviceDropdownOptions(deviceOptions);
				setDistributorDropdownArray(distributorOptions);
				setIsLoading(false);
			})
			.catch((err) => {
				onError(err);
			});
	};

	const parserHeader = () => {
		return (
			<React.Fragment>
				{batchProcessing ? <h2>{t("Batch Ethel Log Analysis")}</h2> : <h2>{t("Ethel Log Parser")}</h2>}
			</React.Fragment>
		);
	};

	return (
		<Card fluid>
			<Card.Header style={{ margin: "10px" }}>{parserHeader()}</Card.Header>
			<FormProvider {...methods}>
				<Card.Content className="card-body">
					{isLoading && <SimpleLoader loaderText={loadingText} />}
					{parsedLog.length > 0 ? (
						<ParsedCycleView
							parsedLog={parsedLog}
							steamCycleTrends={steamCycleTrends}
							grindCycleTrends={grindCycleTrends}
							sharedCycleTrends={sharedCycleTrends}
							cycleStartTimes={cycleStartTimes}
							deviceInfo={deviceInfo}
							fileID={fileID}
							fileName={fileName}
							setLoadingText={setLoadingText}
							setIsLoading={setIsLoading}
							steamCycleStartTimes={steamCycleStartTimes}
							grindCycleStartTimes={grindCycleStartTimes}
							batchProcessing={batchProcessing}
							initialLoadFromBatch={initialLoadFromBatch}
							setParsedLog={setParsedLog}
							setBatchProcessing={setBatchProcessing}
							abnormalValues={abnormalValues}
							abnormalValuesObj={abnormalValuesObj}
							containsBurninTest={containsBurninTest}
							test7CycleIDs={test7CycleIDs}
						/>
					) : (
						<React.Fragment>
							{loadingFromExternal === false && (
								<div className="flex-dir-row even-split">
									<div className="batch-container">
										<fieldset>
											<legend>{t("Batch log processing")}</legend>
											<div>
												<form onSubmit={methods.handleSubmit(fetchFilesForBatch)}>
													<p>
														{t(
															"This feature will automatically retrieve and parse all Ethel logs between the specified date range for the selected device"
														)}
													</p>
													<div className="medium-margin-top">
														<DeviceDropdown
															dropdownRegisterParams={{ required: "Required" }}
															dropdownName={"deviceConfigID"}
															dropdownLabel={t("Device")}
															deviceDropdownOptions={deviceDropdownOptions}
															dropdownInputClass="batch-parser-device-picker"
															dropdownFirstItemAsDefault={true}
															distributorDropdownArray={distributorDropdownArray}
															showCustomerDevicesOnly={false}
															group={props.group}
														/>
													</div>
													<div className="batch-parser-date-picker-container">
														<Checkbox
															className="digital-output-toggle"
															label={t("Filter files by date")}
															onChange={() => setFilterByDate(!filterByDate)}
															checked={filterByDate}
														/>
														{filterByDate && (
															<div className="flex-dir-row even-split">
																<div className="nav-group from-date-div dashboard-datepicker">
																	<label className="date-label">Start Date</label>
																	<DayPickerInput
																		onDayChange={(day) =>
																			setStartDate(
																				moment(day).format("YYYY-MM-DD")
																			)
																		}
																		value={startDate}
																	/>
																</div>
																<div className="nav-group dashboard-datepicker">
																	<label className="date-label">End date</label>
																	<DayPickerInput
																		onDayChange={(day) =>
																			setEndDate(moment(day).format("YYYY-MM-DD"))
																		}
																		value={endDate}
																	/>
																</div>
															</div>
														)}
														<div>
															<Button
																className="ster-btn float-left important-slight-margin-top important-medium-margin-bottom"
																value="Submit"
																type="submit"
															>
																{availableEthelFiles.length < 1
																	? t("Find Logs")
																	: t("Search again")}
															</Button>
														</div>
													</div>
												</form>
											</div>
											{showEthelFileTable && (
												<AvailableEthelFilesTable
													availableEthelFilesTableArray={availableEthelFiles}
													ethelFilesToParse={ethelFilesToParse}
													toggleEthelLogParse={toggleEthelLogParse}
													processBatchFiles={processBatchFiles}
												/>
											)}
										</fieldset>
									</div>
									<div>
										<fieldset>
											<legend>{t("Upload single log")}</legend>
											<form
												className="slight-margin-top"
												onSubmit={singleFileHandleSubmit(onSubmit)}
											>
												<div className="form-group">
													<label
														className={`fake-float ${
															singleFileErrors[props["name"]]
																? "label-validation-error"
																: ""
														}`}
														htmlFor="ethelLogFile"
													>
														{t("Ethel Log")}
													</label>
													<input
														type="file"
														name="ethelLogFile"
														id="ethelLogFile"
														className="form-control"
														accept=".log"
														ref={singleFileRegister({ required: true })}
													/>
												</div>
												<Button className="ster-btn float-right" value="Submit" type="submit">
													{t("Parse Log")}
												</Button>
											</form>
										</fieldset>
										{/*</AuthReq>*/}
									</div>
								</div>
							)}
						</React.Fragment>
					)}
					<ScrollTopArrow />
				</Card.Content>
			</FormProvider>
		</Card>
	);
};

export default withAuth(
	[
		"SterilisSuperUsers",
		"SterilisPortalUsers",
		"FSEs",
		"ExternalFSEs",
		"DistributorAdmins",
		"DistributorReadOnly",
		"DistributorFSEs",
		"SterilisWasteTypeAdmin",
	],
	"internalPage"
)(LogParserHomepage);
