import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

const FormTextField = ({ name, control, label, errors, rules, ...rest }) => {
    return (
        <Controller
            render={({ value, onChange, ref }) => {
                return (
                    <TextField
                        label={label}
                        value={value}
                        fullWidth
                        onChange={onChange}
                        inputRef={ref}
                        error={!!errors}
                        helperText={errors ? errors.message : null}
                        {...rest}
                    />
                );
            }}
            name={name}
            control={control}
            rules={rules}
        />
    );
};

FormTextField.defaultProps = {
    autoComplete: "",
};

FormTextField.propTypes = {
    name: PropTypes.string,
    control: PropTypes.any,
    label: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    autoComplete: PropTypes.string,
};

export default FormTextField;
