/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useState} from 'react';
import {Checkbox, Button, Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {SideDrawer, Backdrop, DrawerHeader} from '../library/Drawer';
import {useScrollPosition} from '@n8tb1t/use-scroll-position'

const ChartOptions = props => {
  const {t} = useTranslation('translations');

  const {
    overlaySol1,
    overlaySol2,
    overlaySol3,
    overlaySol4,
    overlaySol5,
    overlaySol6,
    overlaySol7,
    overlaySol8,
    overlaySol9,
    overlaySol10,
    overlaySol11,
    overlaySol12,
    overlaySol13,
    overlaySol14,
    overlaySol15,
    overlaySol16,
    overlaySol17,
    overlaySol18,
    overlayVacPump,
    overlayAirPump,
    overlayWaterPump,
    overlayHeaters,
    overlayDeodorizerLED,
    overlayGrinderOn,
    overlayGrinderForward,
    overlayOil,
    overlayOilLevels,
  } = props.digitalOutputOverlays;

  const {
    combineTPGraphs,
    showCurrentGraph,
    showTempConstants,
    showPresConstants,
    overlayStates,
    overlayInfo,
    plotDutyCycle,
    showAbnormalSeriesValues,
  } = props.chartOptions;

  const [drawerOpen, setDrawerOpen] = useState(false);

  let backdrop;
  if (drawerOpen) {
    backdrop = <Backdrop setDrawerOpen={setDrawerOpen}/>
  }

  const [floatButton, setFloatButton] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -300 & props.containsBurninTest === true) {
      setFloatButton(true)
    }
    else {
      //only set float button to false again if its set to true
      if (floatButton) {
        setFloatButton(false)
      }
    }
  }, [floatButton]);

  return <div className='chart-options-container slight-margin-top slight-margin-bottom'>
    <Button className={`ster-btn ${floatButton ? 'flip-and-stick-right' : '' }`}
            onClick={() => setDrawerOpen(!drawerOpen)}>
      Show Chart Options
    </Button>
    {backdrop}
    <SideDrawer show={drawerOpen}>
      <Icon
        name='close'
        color='red'
        size='large'
        className='abs-top-right pointer'
        onClick={() => setDrawerOpen(!drawerOpen)}>
      </Icon>
      <div className={'drawer-chart-options-container'}>
        <DrawerHeader>Chart Options</DrawerHeader>
        <div className={`flex-dir-column`}>
          <div className="flex-dir-column">
            {(props.cycleType === 'SteamCycle' || props.containsBurninTest) &&
            <React.Fragment>
              <Checkbox
                className='digital-output-toggle'
                toggle
                label='Combine temperature and pressure graphs'
                onChange={() => props.setChartOptions({combineTPGraphs: !combineTPGraphs})}
                checked={combineTPGraphs}
              />
              <Checkbox
                className='digital-output-toggle'
                toggle
                label='Show Current(A) graph'
                onChange={() => props.setChartOptions({showCurrentGraph: !showCurrentGraph})}
                checked={showCurrentGraph}
              />
              <Checkbox
                className='digital-output-toggle'
                toggle
                label='Show Temperature Constants'
                onChange={() => props.setChartOptions({showTempConstants: !showTempConstants})}
                checked={showTempConstants}
              />
              <Checkbox
                className='digital-output-toggle'
                toggle
                label='Show Pressure Constants'
                onChange={() => props.setChartOptions({showPresConstants: !showPresConstants})}
                checked={showPresConstants}
              />
            </React.Fragment>
            }
            <Checkbox
              className='digital-output-toggle'
              toggle
              label='Overlay States'
              onChange={() => props.setChartOptions({overlayStates: !overlayStates})}
              checked={overlayStates}
            />
            {(props.infoCount > 0 || props.containsBurninTest) &&
            <Checkbox
              className='digital-output-toggle'
              toggle
              label={`Overlay ${props.containsBurninTest ? '' : props.infoCount} Messages`}
              onChange={() => props.setChartOptions({overlayInfo: !overlayInfo})}
              checked={overlayInfo}
            />
            }
            <Checkbox
              className='digital-output-toggle'
              toggle
              label='Plot Duty Cycle'
              onChange={() => props.setChartOptions({plotDutyCycle: !plotDutyCycle})}
              checked={plotDutyCycle}
            />
            <Checkbox
              className='digital-output-toggle'
              toggle
              label='Color Abnormal Values on Graph'
              onChange={() => props.setChartOptions({showAbnormalSeriesValues: !showAbnormalSeriesValues})}
              checked={showAbnormalSeriesValues}
            />

            <Checkbox
              className='digital-output-toggle'
              toggle
              label='Scroll to top on cycle table row click'
              onChange={() => props.setScrollOnCycleClick(!props.scrollOnCycleClick)}
              checked={props.scrollOnCycleClick}
            />


          </div>

          <div className="drawer-chart-options-digital-output-container">
            <fieldset>
              <legend>{t('Digital Outputs')}</legend>
              <div className={'three-across-grid-container'}>
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 1'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol1: !overlaySol1})}
                  checked={overlaySol1}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 2'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol2: !overlaySol2})}
                  checked={overlaySol2}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 3'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol3: !overlaySol3})}
                  checked={overlaySol3}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 4'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol4: !overlaySol4})}
                  checked={overlaySol4}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 5'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol5: !overlaySol5})}
                  checked={overlaySol5}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 6'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol6: !overlaySol6})}
                  checked={overlaySol6}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 7'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol7: !overlaySol7})}
                  checked={overlaySol7}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 8'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol8: !overlaySol8})}
                  checked={overlaySol8}
                />
                <Checkbox
                  className='slight-margin-bottom'
                  toggle={false}
                  label='Solenoid 9'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol9: !overlaySol9})}
                  checked={overlaySol9}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 10'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol10: !overlaySol10})}
                  checked={overlaySol10}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 11'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol11: !overlaySol11})}
                  checked={overlaySol11}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 12'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol12: !overlaySol12})}
                  checked={overlaySol12}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 13'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol13: !overlaySol13})}
                  checked={overlaySol13}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 14'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol14: !overlaySol14})}
                  checked={overlaySol14}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 15'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol15: !overlaySol15})}
                  checked={overlaySol15}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 16'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol16: !overlaySol16})}
                  checked={overlaySol16}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 17'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol17: !overlaySol17})}
                  checked={overlaySol17}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Solenoid 18'
                  onChange={() => props.setDigitalOutputOverlays({overlaySol18: !overlaySol18})}
                  checked={overlaySol18}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Vac Pump'
                  onChange={() => props.setDigitalOutputOverlays({overlayVacPump: !overlayVacPump})}
                  checked={overlayVacPump}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Air Pump'
                  onChange={() => props.setDigitalOutputOverlays({overlayAirPump: !overlayAirPump})}
                  checked={overlayAirPump}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Water Pump'
                  onChange={() => props.setDigitalOutputOverlays({overlayWaterPump: !overlayWaterPump})}
                  checked={overlayWaterPump}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Heaters'
                  onChange={() => props.setDigitalOutputOverlays({overlayHeaters: !overlayHeaters})}
                  checked={overlayHeaters}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Deodorizer LED'
                  onChange={() => props.setDigitalOutputOverlays({overlayDeodorizerLED: !overlayDeodorizerLED})}
                  checked={overlayDeodorizerLED}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Grinder On'
                  onChange={() => props.setDigitalOutputOverlays({overlayGrinderOn: !overlayGrinderOn})}
                  checked={overlayGrinderOn}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Grinder Reverse'
                  onChange={() => props.setDigitalOutputOverlays({overlayGrinderForward: !overlayGrinderForward})}
                  checked={overlayGrinderForward}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Oil'
                  onChange={() => props.setDigitalOutputOverlays({overlayOil: !overlayOil})}
                  checked={overlayOil}
                />
                <Checkbox
                  className='digital-output-checkbox'
                  toggle={false}
                  label='Oil Levels'
                  onChange={() => props.setDigitalOutputOverlays({overlayOilLevels: !overlayOilLevels})}
                  checked={overlayOilLevels}
                />
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </SideDrawer>
  </div>;
};

ChartOptions.defaultProps = {
  chartOptions: {},
  digitalOutputOverlays: {},
  useDrawer: false,
};

ChartOptions.propTypes = {
  setChartOptions: PropTypes.func.isRequired,
  setDigitalOutputOverlays: PropTypes.func.isRequired,
  chartOptions: PropTypes.object,
  digitalOutputOverlays: PropTypes.object,
  useDrawer: PropTypes.bool,
  scrollOnCycleClick: PropTypes.bool.isRequired,
  setScrollOnCycleClick: PropTypes.func.isRequired,
};

export default ChartOptions;
