/*
* Functional component where user can change their portal settings. At time of creation, only portal
* setting is language setting, but more will come
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Dropdown} from "semantic-ui-react";


const languageOptions = [
  {
    key: 'de',
    value: 'de',
    text: 'Deutsch'
  }, {
    key: 'en',
    value: 'en',
    text: 'English'
  }, {
    key: 'es',
    value: 'es',
    text: 'Español'
  }, {
    key: 'fr',
    value: 'fr',
    text: 'Français'
  },
];


const ChangeUserPortalSettings = props => {
  const currentLanguage = languageOptions.find(
    obj => obj.value === localStorage.getItem("i18nextLng") || 'en'
  ).text;

  const selectLang = (event, data) => {
    const {i18n} = props;
    i18n.changeLanguage(data.value);
  };

  return <React.Fragment>
    <div>
      <h4>{props.t('Language Preference')}</h4>
      <Dropdown onChange={selectLang} placeholder={currentLanguage} search selection
                options={languageOptions}/>
    </div>
  </React.Fragment>
};

export default translate('translations')(ChangeUserPortalSettings);
