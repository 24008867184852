/*
 * The device table inside the facility table, which is inside the Customer table on the View Customers page
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
 */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, Paper, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getBackgroundColor, getHoverColor } from "src/services/ColorService";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCycle, setSelectedIndex } from "../metricSlice";
import CustomNoRowsOverlay from "./NoRowsOverlay";
import SubCard from "src/components/material/Cards/SubCard";

const getRowClass = (params) => {
    return !params.row.is_successful && params.row.cycle_type !== "BurninTest" ? "sterilis-app-theme--failed" : "";
};

const CycleEventDataGrid = (props) => {
    const { t } = useTranslation("translations");
    const dispatch = useDispatch();
    // @ts-ignore
    const { cycleData, selectedIndex, selectedCycle } = useSelector((store) => store.metric);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [pageSize, setPageSize] = useState(25);

    const cycleClick = (params) => {
        if (params.row.cycle_type !== "Notification") {
            if (params.row.cycle_type === "BurninTest") {
                // props.handleTabChange(parseInt(params.row.test_type) - 1)
                props.setShowBurninContainer(true);
            } else {
                props.setShowBurninContainer(false);
            }
            props.setZoomParams({});
            dispatch(setSelectedIndex(params.row.id));
            dispatch(setSelectedCycle(params.row));
        }
    };

    const theme = useTheme();

    useEffect(() => {
        setSelectionModel(selectedIndex);
    }, [selectedIndex]);

    const columns = [
        {
            field: "start_time",
            headerName: t("Event Time"),
            type: "dateTime",
            headerAlign: "center",
            align: "center",
            flex: 0.75,
            valueFormatter: (params) => {
                const formatted = DateTime.fromISO(params.value)
                    .toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: "short" })
                    .replace(",", "");
                return `${formatted}`;
            },
        },
        {
            field: "pretty_cycle_type",
            headerName: t("Event Type"),
            headerAlign: "center",
            align: "center",
            flex: 0.5,
        },
        {
            field: "pretty_waste_type",
            headerName: t("Waste Type"),
            headerAlign: "center",
            align: "center",
            flex: 0.5,
        },
        {
            field: "score",
            headerName: t("Score/Message"),
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
        },
        {
            field: "duration_minutes",
            headerName: t("Cycle Duration (mins)"),
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 0.75,
        },
        {
            field: "info_count",
            headerName: t("Message count"),
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
        },
        {
            field: "cycle_id",
            headerName: t("Cycle id"),
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
        },
    ];

    const tableData = React.useMemo(() => {
        let tableData = [];
        cycleData.forEach((cycle) => {
            if (cycle.steam_cycle && cycle.steam_cycle["start_time"] !== null) {
                tableData.push(cycle.steam_cycle);
            }
            if (cycle.grind_cycle && cycle.grind_cycle["start_time"] !== null) {
                tableData.push(cycle.grind_cycle);
            }
        });
        setPageSize(tableData.length > 25 ? 50 : 25);

        return tableData;
    }, [cycleData]);

    return (
        <SubCard title={t("Cycle Events")}>
            <Box
                sx={{
                    width: "100%",
                    "& .sterilis-app-theme--failed": {
                        bgcolor: (theme) => getBackgroundColor(theme.palette.error.light),
                        "&:hover": {
                            bgcolor: (theme) => getHoverColor(theme.palette.error.light),
                        },
                    },
                    "& .sterilis-app-theme--failed.Mui-selected": {
                        bgcolor: (theme) => getBackgroundColor(theme.palette.error.main, true),
                        color: theme.palette.error.contrastText,
                        "&:hover": {
                            bgcolor: (theme) => getHoverColor(theme.palette.error.main, true),
                        },
                    },
                }}>
                <DataGrid
                    columns={columns}
                    rows={tableData}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}
                    autoHeight
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "start_time", sort: "asc" }],
                        },
                    }}
                    components={{ Toolbar: GridToolbar, NoRowsOverlay: CustomNoRowsOverlay }}
                    getRowClassName={getRowClass}
                    componentsProps={{
                        toolbar: { background: "primary" },
                    }}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    onRowClick={cycleClick}
                />
            </Box>
        </SubCard>
    );
};

CycleEventDataGrid.defaultProps = {
    showMessages: false,
    showBurninContainer: false,
    showOverlayCycleSelect: false,
    showBurninTest7CycleSelect: false,
};

CycleEventDataGrid.propTypes = {
    showMessages: PropTypes.bool,
    showBurninContainer: PropTypes.bool,
    showOverlayCycleSelect: PropTypes.bool,
    showBurninTest7CycleSelect: PropTypes.bool,
    setShowBurninContainer: PropTypes.func.isRequired,
    onRowSelectCallback: PropTypes.func,
};

export default CycleEventDataGrid;
