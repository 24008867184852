/*
*  Modal for suspending a Facility
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import SimpleLoader from "../../../SimpleLoader";
import AuthService from "../../../AuthService";
import CycleReportWarning from "./CycleReportWarning";

const Auth = new AuthService();

class BanFacilityModal extends React.Component {
  state = {
    modalLoading: false,
    allowModalToBeClosed: true,
  };
  banFacility = () => {
    const {
      facility
    } = this.props;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    const facilityID = facility['id'];

    Auth.fetch(`/api/facility/${facilityID}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        banned: true
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.preparePage();
        this.props.triggerBanFacilityModal(null);
        this.props.notifySuccess('suspended', 'facility', data['facility_name'])
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerBanFacilityModal(null);
        this.props.notifyFailure()
      });
    });
  };

  render() {
    const {
      t,
      facility,
      customer,
    } = this.props;
    const {
      modalLoading
    } = this.state;
    const facilityName = facility['facility_name'];

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.banFacilityModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerBanFacilityModal(null)
          }}
        >
          <Header>
            <h2 className='red-text'>{t('Suspend Facility')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            <div className='short-modal-text center-text'>
              <p>
                <Trans ns="translations" i18nKey="suspend-facility.1">
                  Are you sure you want to suspend <strong>{{facilityName}}</strong>?
                </Trans>
              </p>
              <p>{t('suspend-facility.2', 'Their devices will no longer be able to communicate with the portal.')}</p>
              <CycleReportWarning
                action={t('suspend')}
                actionPastTense={t('suspended')}
                noun={t('facility')}
                name={customer['customer_name']}
                customerID={customer['id']}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='red-btn'
                    value="Submit"
                    type="submit"
                    id='banFacilityBtn'
                    onClick={this.banFacility}
            >{t('Suspend Facility')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(BanFacilityModal);
