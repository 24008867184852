import { ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerMachines } from "src/features/Customer/customerSlice";
import FormAutocomplete from "src/components/material/Forms/FormAutocomplete";

const CustomerSelection = (props) => {
    const dispatch = useDispatch();

    const { control, rules, errors, setValue } = props;

    // @ts-ignore
    const { customerDropdown, loading, customers } = useSelector((store) => store.customer);
    // @ts-ignore
    const { group } = useSelector((store) => store.user);

    const visibleDistributorGroups = ["SterilisSuperUsers", "SterilisPortalUsers", "FSEs", "SterilisWasteTypeAdmin"];

    React.useEffect(() => {
        if (customers.length === 0)
            // @ts-ignore
            dispatch(fetchCustomerMachines());
    }, []);

    return (
        <FormAutocomplete
            control={control}
            options={customerDropdown}
            errors={errors}
            rules={rules}
            setValue={setValue}
            label={"Customer"}
            name={"employee.customer"}
            renderOption={(props, option) => (
                <ListItem
                    component="li"
                    {...props}
                    secondaryAction={
                        visibleDistributorGroups.includes(group) ? (
                            <Typography variant="subtitle2">{option.dist_name}</Typography>
                        ) : null
                    }>
                    <ListItemText primary={option.name} />
                </ListItem>
            )}
        />
    );
};

export default CustomerSelection;
