import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import React from "react";
import { Box } from "@mui/material";

const CycleSummaryChart = (props) => {
    return (
        <Box>
            <HighchartsReact highcharts={Highcharts} options={props.options} />
        </Box>
    );
};

export default CycleSummaryChart;
