/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useRef, useEffect, useState} from 'react';
import Highcharts from "highcharts";
import PropTypes from 'prop-types';
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import {useTranslation} from "react-i18next";
import {
  calcTitleMargin,
  calcSingleChartHeight,
  calcCombinedChartHeight,
  dutyCycleConstants,
  dutyCycleYAxisLabel,
  zoomButtonOptions,
  tempConstants,
  pressureConstants,
  defaultPlotOptions,
  chartTooltip,
  getCombinedSeries,
  getChartLegend
} from './ChartHelpers'
import {useZoomParams} from "../library/hooks";

import boost from 'highcharts/modules/boost';

boost(Highcharts);

const CombinedSteamChart = props => {
  const {t} = useTranslation('translations');
  const titleMargin = calcTitleMargin(props.plotBands.length);
  const combinedChartHeight = props.burninChart ?
    calcSingleChartHeight(props.plotBands.length) : // use single height if this is a burnin chart
    calcCombinedChartHeight(props.plotBands.length);
  const dutyCycleYAxis = {
    ...dutyCycleYAxisLabel,
    plotLines: props.dutyCycleValuesOverThreshHold ? dutyCycleConstants : false
  };

  const chartLegend = getChartLegend(props.plotDutyCycle);

  const combinedSeries = React.useMemo(() => {
    const selectedCycle = getCombinedSeries(
      props.thList,
      props.tvList,
      props.trList,
      props.paList,
      props.psList,
      props.pvList,
      props.dutyCycleList,
      props.plotDutyCycle
    );
    if (props.overlayedCycles.length > 0) {
      const overlayedSeries = props.overlayedCycles.flatMap((overlayedCycle, idx) => {
        const counter = idx + 1;
        const cycleStartTime = moment.utc(overlayedCycle['raw_start_time']).format("MM-DD HH:mm:ss z");
        return getCombinedSeries(
          overlayedCycle.th_list,
          overlayedCycle.tv_list,
          overlayedCycle.tr_list,
          overlayedCycle.pa_list,
          overlayedCycle.ps_list,
          overlayedCycle.pv_list,
          overlayedCycle.duty_cycle_list,
          props.plotDutyCycle,
          counter,
          true,
          cycleStartTime);
      });
      return [...selectedCycle, ...overlayedSeries];
    }
    return selectedCycle
  }, [props.overlayedCycles, props.timeList, props.showAbnormalSeriesValues, props.plotDutyCycle]);

  const combinedChartTooltip = function () {
    return chartTooltip(this, props.timeList, props.cycleStartTime, props.plotBands);
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      zoomType: 'x',
      height: combinedChartHeight,
      ignoreHiddenSeries: false,
      resetZoomButton: zoomButtonOptions
    },
    legend: chartLegend,
    plotOptions: defaultPlotOptions,
    tooltip: {
      shared: true,
      useHTML: true,
      animation: false,
      formatter: combinedChartTooltip
    },
    title: {
      text: `${props.customTitlePrefix && props.customTitlePrefix} Temperature and Pressure`,
      margin: titleMargin,
    },
    credits: false,
    xAxis: [{
      categories: props.timeList,
      crosshair: true,
      plotBands: props.plotBands,
      plotLines: props.overlayInfo && props.infoConstants,
      events: {
        afterSetExtremes: (event) => {
          if (event.trigger === 'zoom' && props.setZoomParams) {
            props.setZoomParams({min: event.min, max: event.max})
          }
        }
      }
    }],
    yAxis: [
      { // Primary yAxis
        labels: {
          format: '{value}°C',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        title: {
          text: t('Temperature'),
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        // min: 0,
        // max: 150,
        startOnTick: false,
        plotLines: props.showTempConstants ? tempConstants : false,
        tickInterval: 10
      },
      {
        title: {
          text: t('Pressure'),
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: `{value} PSI`,
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true,
        startOnTick: false,
        gridLineColor: 'transparent',
        tickInterval: 5,
        plotLines: props.showPresConstants ? pressureConstants : false,
      },
      ...props.plotDutyCycle ? [dutyCycleYAxis] : [],
    ],
    series: combinedSeries,
  });

  // this use effect should only get triggered when the chart is being updated with new series values because
  // the user clicked a different cycle of the same type
  useEffect(() => {
    const updatedOptions = {
      chart: {
        height: combinedChartHeight,
      },
      title: {
        margin: titleMargin,
      },
      legend: chartLegend,
      xAxis: [{
        categories: props.timeList,
        plotBands: props.plotBands,
        plotLines: props.overlayInfo && props.infoConstants,
      }],
      tooltip: {
        // need to re-declare this in the uuseeffect update because it needs new timelist props on cycle change
        formatter: combinedChartTooltip
      },
      yAxis: [
        {
          plotLines: props.showTempConstants ? tempConstants : false,
        },
        {
          plotLines: props.showPresConstants ? pressureConstants : false,
        },
        ...props.plotDutyCycle ? [dutyCycleYAxis] : [],
      ],
      series: combinedSeries,
    };
    setChartOptions(updatedOptions);
  }, [props.timeList, props.plotBands, props.overlayInfo, props.infoConstants, props.showPresConstants,
    props.showTempConstants, props.burninChart, combinedSeries]);
  const combinedRef = useRef();
  useZoomParams(props.zoomParams, combinedRef, props.timeList);
  return <HighchartsReact
    ref={combinedRef}
    highcharts={Highcharts}
    options={chartOptions}
  />
};

CombinedSteamChart.defaultProps = {
  thList: [],
  tvList: [],
  trList: [],
  paList: [],
  psList: [],
  pvList: [],
  iList: [],
  timeList: [],
  plotBands: [],
  dutyCycleList: [],
  showTempConstants: true,
  showPresConstants: true,
  combineTPGraphs: true,
  showCurrentGraph: true,
  overlayInfo: false,
  overlayStates: false,
  plotDutyCycle: false,
  dutyCycleValuesOverThreshHold: false,
  showAbnormalSeriesValues: false,
  burninChart: false,
  abnormalThValues: {},
  abnormalTvValues: {},
  abnormalTrValues: {},
  abnormalPaValues: {},
  abnormalPsValues: {},
  abnormalPvValues: {},
  abnormalIValues: {},
  abnormalDutyCycleValues: {},
  customTitlePrefix: '',
  zoomParams: null,
  overlayedCycles: [],
};

CombinedSteamChart.propTypes = {
  thList: PropTypes.array,
  tvList: PropTypes.array,
  trList: PropTypes.array,
  paList: PropTypes.array,
  psList: PropTypes.array,
  iList: PropTypes.array,
  pvList: PropTypes.array,
  timeList: PropTypes.array,
  plotBands: PropTypes.array,
  dutyCycleList: PropTypes.array,
  showPresConstants: PropTypes.bool,
  combineTPGraphs: PropTypes.bool,
  showCurrentGraph: PropTypes.bool,
  overlayInfo: PropTypes.bool,
  overlayStates: PropTypes.bool,
  plotDutyCycle: PropTypes.bool,
  dutyCycleValuesOverThreshHold: PropTypes.bool,
  showAbnormalSeriesValues: PropTypes.bool,
  burninChart: PropTypes.bool,
  abnormalThValues: PropTypes.object,
  abnormalTvValues: PropTypes.object,
  abnormalTrValues: PropTypes.object,
  abnormalPaValues: PropTypes.object,
  abnormalPsValues: PropTypes.object,
  abnormalPvValues: PropTypes.object,
  abnormalIValues: PropTypes.object,
  abnormalDutyCycleValues: PropTypes.object,
  customTitlePrefix: PropTypes.string,
  zoomParams: PropTypes.object,
  setZoomParams: PropTypes.func,
  overlayedCycles: PropTypes.array,
};

export default CombinedSteamChart;
