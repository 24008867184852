import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { StyledDialog } from "../Configuration";
import { useDispatch, useSelector } from "react-redux";
import { resetBioInterval, selectSerial, setResetBiochallengeDialogOpen } from "../deviceInfoSlice";
import { DateTime, Duration } from "luxon";
import { enqueueSnackbar } from "notistack";

const ResetBiochallengeIntervalDialog = (props) => {
    const dispatch = useDispatch();
    const { deviceTimezone, resetBiochallengeDialogOpen, selectedDeviceConfig } = useSelector(
        (store) => store.deviceInfo
    );
    const loading = useSelector((store) => store.deviceInfo.loading.bioReset);

    const deviceSerial = useSelector(selectSerial);

    const { t } = useTranslation("translations");

    const [lastBioDate, setLastBioDate] = useState(null);
    const [oldDueDate, setOldDueDate] = useState(null);
    const [newDueDate, setNewDueDate] = useState(null);
    const [oldDueDateString, setOldDueDateString] = useState(null);
    const [newDueDateString, setNewDueDateString] = useState(null);

    const notifySuccess = () =>
        enqueueSnackbar(
            `Successfully Reset Biochallenge Due Date To: ${newDueDate.toLocaleString({
                ...DateTime.DATETIME_SHORT_WITH_SECONDS,
                timeZoneName: "short",
            })}`,
            { variant: "success" }
        );

    const notifyFailure = () =>
        enqueueSnackbar(`Unable To Reset Biochallenge Date For ${deviceSerial}. Please Refresh And Try Again.`, {
            variant: "error",
        });

    const resetBio = () => {
        const updatedBcDate = lastBioDate.plus({ days: 2 });
        dispatch(
            // @ts-ignore
            resetBioInterval({
                deviceId: selectedDeviceConfig.device.id,
                body: {
                    time_last_biochallenge: updatedBcDate,
                    steam_time_since_last_bcv: 3600,
                    last_timefield_update_time: DateTime.now(),
                    serial_number: deviceSerial,
                },
            })
        ).then((resp) => {
            if (resp.meta.requestStatus === "fulfilled") {
                notifySuccess();
            } else if (resp.meta.requestStatus === "rejected") {
                notifyFailure();
            }
        });
    };

    useEffect(() => {
        if (selectedDeviceConfig) {
            const bioType = selectedDeviceConfig.facility.region_setting.bio_interval_type;
            const bioInterval = selectedDeviceConfig.facility.region_setting.bio_interval_duration;
            const lastBcDate = selectedDeviceConfig.device.time_last_biochallenge;

            const duration = Duration.fromObject({
                months: 0,
                weeks: 0,
                days: 0,
                hours: 0,
                seconds: bioInterval,
            }).normalize();

            if (bioType === "usage") {
                setNewDueDate(DateTime.utc());
            } else {
                const lastBio = DateTime.fromISO(lastBcDate, { zone: "utc" });
                setLastBioDate(lastBio);
                const oldDate = lastBio.plus(duration.toObject());
                const newDate = oldDate.plus({ days: 2 });
                setOldDueDate(oldDate);
                setNewDueDate(newDate);
                setNewDueDateString(
                    newDate.setZone(deviceTimezone).toLocaleString({
                        ...DateTime.DATETIME_SHORT_WITH_SECONDS,
                        timeZoneName: "short",
                    })
                );
                setOldDueDateString(
                    oldDate.setZone(deviceTimezone).toLocaleString({
                        ...DateTime.DATETIME_SHORT_WITH_SECONDS,
                        timeZoneName: "short",
                    })
                );
            }
        }
    }, [selectedDeviceConfig]);
    return (
        <StyledDialog open={resetBiochallengeDialogOpen} maxWidth={"lg"} fullWidth>
            <DialogTitle>Reset Biochallenge Interval For {deviceSerial}</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                        <CircularProgress color="primary" />
                        <Typography variant="caption" color={"primary"}>
                            {t("Resetting Biochallenge Date")}
                        </Typography>
                    </Stack>
                ) : (
                    selectedDeviceConfig && (
                        <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
                            <Typography textAlign={"center"}>
                                {`Are you sure you want to reset the biochallenge interval for ${deviceSerial}?`}
                            </Typography>
                            <Alert severity="info">
                                {`This will bump the Biochallenge Due Date 48 hours so cycles can be run temporarily.`}
                            </Alert>
                            <Divider />
                            <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                                <Typography variant="h6" color={"primary"}>
                                    Device Serial:
                                </Typography>
                                <Typography variant="h6" color={"textPrimary"}>
                                    {deviceSerial}
                                </Typography>
                            </Stack>

                            <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                                <Typography variant="h6" color={"primary"}>
                                    Current Due Date:
                                </Typography>
                                <Typography variant="h6" color={"textPrimary"}>
                                    {oldDueDate && oldDueDateString}
                                </Typography>
                            </Stack>

                            <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                                <Typography variant="h6" color={"primary"}>
                                    Due Date After Reset:
                                </Typography>
                                <Typography variant="h6" color={"textPrimary"}>
                                    {newDueDate && newDueDateString}
                                </Typography>
                            </Stack>

                            <Divider />
                        </Stack>
                    )
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button onClick={() => dispatch(setResetBiochallengeDialogOpen(false))}>{t("Close")}</Button>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                        resetBio();
                    }}>
                    {t("Reset")}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

ResetBiochallengeIntervalDialog.propTypes = {
    modalDeviceID: PropTypes.number,
};

export default ResetBiochallengeIntervalDialog;
