import React from "react";
import PropTypes from "prop-types";
import { Box, Button, CircularProgress, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import DeviceDropdown from "../../DeviceDropdown/DeviceDropdown";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import {
    setFromDate,
    setToDate,
    fetchDeviceCycles,
    fetchBiochallengeInfo,
    setRefreshInterval,
} from "../deviceDashboardSlice";
import RefreshTimer from "./RefreshTimer";

const Toolbar = (props) => {
    const dispatch = useDispatch();
    // @ts-ignore
    const { toDate, fromDate } = useSelector((store) => store.devicedashboard);

    const submit = () => {
        // @ts-ignore
        dispatch(fetchDeviceCycles());
        // @ts-ignore
        dispatch(fetchBiochallengeInfo());
    };

    const toDateObj = DateTime.fromISO(toDate);
    const fromDateObj = DateTime.fromISO(fromDate);

    return (
        <Box>
            <RefreshTimer />
            <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, lg: 42 }}
                sx={{ mt: 2, mb: 4 }}
                alignContent={"space-between"}>
                <DeviceDropdown />

                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        sx={{ justifyContent: "center", alignItems: "center" }}
                        spacing={1}>
                        <DatePicker
                            label="From"
                            mask="__/__/____"
                            value={fromDateObj}
                            openTo="day"
                            views={["year", "month", "day"]}
                            disableFuture={true}
                            onChange={(newValue) => {
                                dispatch(setFromDate(newValue.toISODate()));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />

                        <DatePicker
                            label="To"
                            mask="__/__/____"
                            value={toDateObj}
                            openTo="day"
                            views={["year", "month", "day"]}
                            disableFuture={true}
                            minDate={DateTime.fromISO(fromDate)}
                            onChange={(newValue) => {
                                dispatch(setToDate(newValue.toISODate()));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />

                        <Button variant="contained" onClick={submit} size="large">
                            Submit
                        </Button>
                    </Stack>
                </LocalizationProvider>
            </Stack>
        </Box>
    );
};

Toolbar.propTypes = {};

export default Toolbar;
