import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AuthService from "src/components/AuthService";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { PRV_INTERVAL, sterilisCustomers } from "src/components/library/helpers";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import clsx from "clsx";
import { ExpandMoreTwoTone } from "@mui/icons-material";

const Auth = new AuthService();

const DueDatesTable = (props) => {
    const { t } = useTranslation("translations");
    const theme = useTheme();

    const [devicesRequiringBioPrv, setDevicesRequiringBioPrv] = React.useState([]);
    const [devicesLoading, setDevicesLoading] = React.useState(false);

    const customerFacility = (deviceConfig) => {
        // if the customer & facility name are the same, eg hilo, just return the customer name
        if (deviceConfig["facility"]["customer"]["customer_name"] === deviceConfig["facility"]["facility_name"]) {
            return deviceConfig["facility"]["customer"]["customer_name"];
        } else {
            return `${deviceConfig["facility"]["customer"]["customer_name"]} - ${deviceConfig["facility"]["facility_name"]}`;
        }
    };

    const figureOutPRVDueDate = (lastPrvDate) => {
        const deviceLastPRV = DateTime.fromISO(lastPrvDate);
        const newPrvTestDueDate = deviceLastPRV.plus({ seconds: PRV_INTERVAL });
        const currentDate = DateTime.now();
        const prettyDueDate = newPrvTestDueDate.toRelative();
        const daysUntilDueDate = newPrvTestDueDate.diff(currentDate, "days").days;
        const newPRVTestDueDateTimestamp = newPrvTestDueDate.toISO();

        return (
            <Accordion
                elevation={0}
                sx={{
                    // @ts-ignore
                    ...(daysUntilDueDate >= 30 && { bgcolor: theme.palette.dueDate.ok }),
                    // @ts-ignore
                    ...(daysUntilDueDate < 30 && daysUntilDueDate > 1 && { bgcolor: theme.palette.dueDate.warning }),
                    // @ts-ignore
                    ...(daysUntilDueDate <= 1 && { bgcolor: theme.palette.dueDate.due }),
                }}
                data-date={prettyDueDate}
                data-search={`${prettyDueDate} - ${newPRVTestDueDateTimestamp}`}>
                <AccordionSummary sx={{ flexDirection: "row-reverse" }} expandIcon={<ExpandMoreTwoTone />}>
                    {prettyDueDate.charAt(0).toUpperCase() + prettyDueDate.slice(1)}
                </AccordionSummary>
                <AccordionDetails>{newPRVTestDueDateTimestamp}</AccordionDetails>
            </Accordion>
        );
    };

    const fetchDevices = () => {
        setDevicesLoading(true);
        return Auth.fetch(`/api/device-config/`, {
            method: "GET",
        })
            .then((data) => {
                const devicesRequiringBioPRV = data
                    //Filter out airport devices immediately
                    .filter((deviceConfig) => deviceConfig["facility"]["facility_type"] !== "airport")
                    .map((deviceConfig) => {
                        if (deviceConfig["facility"]["region_setting"]["bio_interval_type"] === "calendar") {
                            //const deviceLastBiochallenge = moment.utc(deviceConfig["device"]["time_last_biochallenge"]);
                            const deviceLastBiochallenge = DateTime.fromISO(
                                deviceConfig["device"]["time_last_biochallenge"]
                            ).toUTC();

                            let biochallengeValidDuration =
                                deviceConfig["facility"]["region_setting"]["bio_interval_duration"];

                            // For Texas devices, waste_per_month will be populated
                            // We need to use the facility's bio_interval_duration for Texas facilities to comply
                            // w/ their weight laws
                            if (deviceConfig["facility"]["waste_per_month"]) {
                                biochallengeValidDuration = deviceConfig["facility"]["bio_interval_duration"];
                            }
                            const newBioChallengeDueDate = deviceLastBiochallenge.plus({
                                seconds: biochallengeValidDuration,
                            });

                            const currentDate = DateTime.now();

                            const daysUntilDueDate = newBioChallengeDueDate.diff(currentDate, "days").days;

                            const prettyDueDate = newBioChallengeDueDate.toRelative();

                            const newBioChallengeDueDateTimestamp = newBioChallengeDueDate.toISO();
                            const dueDateJsx = (
                                <Accordion
                                    elevation={0}
                                    sx={{
                                        ...(daysUntilDueDate >= 7 && { bgcolor: theme.palette.dueDate.ok }),
                                        ...(daysUntilDueDate > 1 &&
                                            daysUntilDueDate < 7 && { bgcolor: theme.palette.dueDate.warning }),
                                        ...(daysUntilDueDate <= 1 && { bgcolor: theme.palette.dueDate.due }),
                                    }}
                                    data-date={prettyDueDate}
                                    data-search={`${prettyDueDate} - ${newBioChallengeDueDateTimestamp}`}>
                                    <AccordionSummary
                                        sx={{ flexDirection: "row-reverse" }}
                                        expandIcon={<ExpandMoreTwoTone />}>
                                        {prettyDueDate.charAt(0).toUpperCase() + prettyDueDate.slice(1)}
                                    </AccordionSummary>
                                    <AccordionDetails>{newBioChallengeDueDateTimestamp}</AccordionDetails>
                                </Accordion>
                            );

                            return {
                                deviceSerial: deviceConfig["device"]["serial_number"],
                                customer: customerFacility(deviceConfig),
                                customerId: deviceConfig["facility"]["customer"]["id"],
                                biochallengeDue: dueDateJsx,
                                bioDueDate: newBioChallengeDueDateTimestamp,
                                prvDue: deviceConfig["device"]["time_last_prv_test"],
                                bioType: deviceConfig["facility"]["region_setting"]["bio_interval_type"],
                            };
                        } else if (deviceConfig["facility"]["region_setting"]["bio_interval_type"] === "usage") {
                            // The device reports up a negative number, so we'll need to find the absolute value of it
                            const cyclesUntilBiochallenge = Math.abs(
                                deviceConfig["device"]["cycles_till_biochallenge"]
                            );

                            const cyclesTillString = `${cyclesUntilBiochallenge} cycle${
                                cyclesUntilBiochallenge > 1 ? "s" : ""
                            }`;

                            return {
                                deviceSerial: deviceConfig["device"]["serial_number"],
                                customer: customerFacility(deviceConfig),
                                customerId: deviceConfig["facility"]["customer"]["id"],
                                biochallengeDue: <div data-search={cyclesTillString}>{cyclesTillString}</div>,
                                bioCyclesLeft: cyclesUntilBiochallenge,
                                prvDue: deviceConfig["device"]["time_last_prv_test"],
                                bioType: deviceConfig["facility"]["region_setting"]["bio_interval_type"],
                            };
                        } else {
                            return {};
                        }
                    });
                setDevicesRequiringBioPrv(devicesRequiringBioPRV);
                setDevicesLoading(false);
            })
            .catch((err) => {
                // Sentry.captureException(err);
            });
    };

    const getPrvCellFormatting = (value) => {
        const daysTill = DateTime.fromISO(value).plus({ seconds: PRV_INTERVAL }).diff(DateTime.now(), "days").days;
        return clsx("due-date-table", {
            ok: daysTill >= 30,
            warn: daysTill < 30 && daysTill > 1,
            past: daysTill <= 1,
        });
    };

    const getBioCellFormatting = (params) => {
        if (params.row.bioType === "calendar") {
            const daysTill = DateTime.fromISO(params.row.bioDueDate).diff(DateTime.now(), "days").days;
            return clsx("due-date-table", {
                ok: daysTill >= 7,
                warn: daysTill < 7 && daysTill > 1,
                past: daysTill <= 1,
            });
        } else if (params.row.bioType === "usage") {
            const cyclesTill = params.row.bioCyclesLeft;
            return clsx("due-date-table", {
                ok: cyclesTill >= 14,
                warn: cyclesTill < 14 && cyclesTill > 5,
                past: cyclesTill <= 1,
            });
        }
    };

    const columns = [
        {
            headerName: "Device Serial",
            field: "deviceSerial",
            headerAlign: "center",
            headerClassName: "due-date-table--header",
            align: "center",
            flex: 0.5,
            renderCell: ({ value }) => (
                <Link target="_blank" to={`/find-a-machine?serial=${value}`}>
                    {value}
                </Link>
            ),
        },
        {
            headerName: "Customer",
            field: "customer",
            headerAlign: "center",
            headerClassName: "due-date-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: "Bio-Challenge Due In",
            field: "biochallengeDue",
            headerAlign: "center",
            headerClassName: "due-date-table--header",
            align: "center",
            flex: 1,
            renderCell: (params) => params.value,
            cellClassName: (params) => getBioCellFormatting(params),
        },
        {
            headerName: "PRV Test Due In",
            field: "prvDue",
            headerAlign: "center",
            headerClassName: "due-date-table--header",
            align: "center",
            flex: 1,
            renderCell: ({ value }) => figureOutPRVDueDate(value),
            cellClassName: ({ value }) => getPrvCellFormatting(value),
        },
    ];

    const filteredDevices = props.customerDevicesOnly
        ? devicesRequiringBioPrv.filter((device) => !sterilisCustomers.includes(device.customerId))
        : devicesRequiringBioPrv;

    React.useEffect(() => {
        fetchDevices();
    }, []);

    const Toolbar = (props) => {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    return (
        <Box
            sx={{
                width: "100%",
                "& .due-date-table--header": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                },
                "& .due-date-table.ok": {
                    backgroundColor: theme.palette.dueDate.ok,
                },
                "& .due-date-table.warn": {
                    backgroundColor: theme.palette.dueDate.warning,
                },
                "& .due-date-table.past": {
                    backgroundColor: theme.palette.dueDate.due,
                },
            }}>
            {devicesLoading ? (
                <Skeleton variant="rounded" animation="wave" width="100%" height={300} />
            ) : (
                <DataGrid
                    disableSelectionOnClick
                    autoHeight
                    // @ts-ignore
                    columns={columns}
                    rows={filteredDevices}
                    density="comfortable"
                    sx={{
                        fontSize: 14,
                    }}
                    getRowHeight={() => {
                        return "auto";
                    }}
                    getRowId={(params) => params.deviceSerial}
                    components={{ Toolbar: Toolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            )}
        </Box>
    );
};

DueDatesTable.propTypes = {
    customerDevicesOnly: PropTypes.bool,
};

export default DueDatesTable;
