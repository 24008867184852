/*
*  This is the component for the large (width >1200px) dashboard
*
*   It will have three graphs at the top.
*   The cycle table & pressure/temp graph will be parallel to one another at the bottom of the page
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import DashboardNav from "./DashboardNav";
import WaveLoader from "../WaveLoader";
import '../../css/dashboard/DashboardMain.css';

const LoadingDashboard = props => (
  <div className='customer-container'>
    <DashboardNav
      toDateClick={props.toDateClick}
      fromDateClick={props.fromDateClick}
      fromDate={props.fromDate}
      toDate={props.toDate}
      prepareDashboard={props.prepareDashboard}
      customerMachines={props.customerMachines}
      selectDevice={props.selectDevice}
      deviceConfigID={props.deviceConfigID}
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
    />
    <WaveLoader/>
  </div>
);

export default translate('translations')(LoadingDashboard);
