import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import React, { memo, useContext, useEffect } from "react";
import CustomerSelection from "./CustomerSelection";
import DistributorSelection from "./DistributorSelection";
import { AuthReq, isGroupSterilis } from "src/components/library/helpers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FacilitySelection from "./FacilitySelection";
import { useHistory } from "react-router-dom";
import { resetState, setCreateAnotherUser, setCustomer, setPermissionGroup } from "./createUserSlice";
import { ValidateUniquePin, ValidateUniqueUsername } from "src/services/ValidationService";
import { UserContext } from "src/components/library/UserContext";
import { toast } from "react-toastify";
import FormTextField from "src/components/material/Forms/FormTextField";
import FormSelect from "src/components/material/Forms/FormSelect";
import FormAutocomplete from "src/components/material/Forms/FormAutocomplete";
import FormCheckbox from "src/components/material/Forms/FormCheckbox";

const CreateUserForm = ({ control, errors, setValue, isModal }) => {
    const userContext = useContext(UserContext);
    // @ts-ignore
    const { user } = userContext;
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation("translations");
    // @ts-ignore
    const { group } = useSelector((store) => store.user);
    const {
        userType,
        userTypeDisplay,
        createAnotherUser,
        permissionGroup,
        customer,
        pinMin,
        pinMax,
        failed,
        successText,
    } =
        // @ts-ignore
        useSelector((store) => store.createUser);
    const [groupOptions, setGroupOptions] = React.useState([]);

    const notifySuccess = () =>
        toast(<div>{successText}</div>, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });

    const notifyFailure = () =>
        toast(<div>Failed to create user. Please refresh and try again.</div>, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    const showCustomer = ["distributor", "customer", "operator", "facilityadmin"].includes(userType);

    const showNotificationOptions = ["customer", "operator", "facilityadmin"].includes(userType);

    const showGroupOptions = ["distributor", "sterilis"].includes(userType);

    const notificationDropdown = [
        {
            value: 0,
            id: "0",
            label: t("Do not notify"),
        },
        {
            value: 1,
            id: "1",
            label: t("Notify on Completion"),
        },
        {
            value: 2,
            id: "2",
            label: t("Notify on Error"),
        },
        {
            value: 3,
            id: "3",
            label: t("Notify on Completion and Error"),
        },
    ];

    const distributorGroupOptions = [
        {
            id: "DistributorAdmins",
            value: "DistributorAdmins",
            label: isGroupSterilis(group) ? t("Distributor Admin") : t("Admin"),
        },
        {
            id: "DistributorFSEs",
            value: "DistributorFSEs",
            label: isGroupSterilis(group) ? t("Distributor FSE") : t("Field Service Engineer"),
        },
        {
            id: "DistributorReadOnly",
            value: "DistributorReadOnly",
            label: isGroupSterilis(group) ? t("Distributor Read Only") : t("Read only account"),
        },
    ];

    const sterilisGroupOptions = [
        {
            id: "SterilisPortalUsers",
            value: "SterilisPortalUsers",
            label: t("Sterilis Portal User"),
        },
        {
            id: "FSEs",
            value: "FSEs",
            label: t("Sterilis Field Service Engineer"),
        },
        {
            id: "SterilisSuperUsers",
            value: "SterilisSuperUsers",
            label: t("Sterilis Super User"),
        },
        {
            id: "ExternalFSEs",
            value: "ExternalFSEs",
            label: t("External Field Service Engineer"),
        },
        {
            id: "FactoryWorkers",
            value: "FactoryWorkers",
            label: t("Factory Worker"),
        },
        {
            id: "SterilisWasteTypeAdmin",
            value: "SterilisWasteTypeAdmin",
            label: t("Sterilis Waste Type Admin"),
        },
    ];

    useEffect(() => {
        const options = userType === "distributor" ? distributorGroupOptions : sterilisGroupOptions;
        setGroupOptions(options);
        switch (userType) {
            case "customer":
                dispatch(
                    setPermissionGroup({
                        id: "CustomerPortalUsers",
                        value: "CustomerPortalUsers",
                        label: "Customer Portal User",
                    })
                );
                break;
            case "facilityadmin":
                dispatch(
                    setPermissionGroup({
                        id: "FacilityAdmin",
                        value: "FacilityAdmin",
                        label: "Facility Admin",
                    })
                );
                break;

            default:
                break;
        }
    }, [userType]);

    useEffect(() => {
        if (!failed && successText.length > 0) {
            notifySuccess();
            if (isGroupSterilis(group) && !isModal) {
                history.push("/view/users");
            }
        }
    }, [successText]);

    useEffect(() => {
        if (failed) {
            notifyFailure();
        }
    }, [failed]);

    useEffect(() => {
        if (!customer && !isGroupSterilis(group)) {
            dispatch(setCustomer({ id: user.customer_id, name: user.customer }));
        }
    }, []);

    return (
        <Grid container spacing={2}>
            {showGroupOptions ? (
                <Grid xs={12}>
                    <FormAutocomplete
                        control={control}
                        label={"Permission Group"}
                        options={groupOptions}
                        rules={{ required: "Permission group is required." }}
                        name="user.permission"
                        errors={errors.user?.permission}
                        setValue={setValue}
                    />
                </Grid>
            ) : null}
            {showCustomer ? (
                <AuthReq
                    userGroup={group}
                    requiredRoles={[
                        "SterilisSuperUsers",
                        "SterilisPortalUsers",
                        "FSEs",
                        "ExternalFSEs",
                        "FactoryWorkers",
                        "DistributorAdmins",
                        "SterilisWasteTypeAdmin",
                    ]}>
                    <Grid xs={12}>
                        {userType === "distributor" ? (
                            <DistributorSelection
                                control={control}
                                errors={errors?.employee?.distributor}
                                rules={{ required: "Must select a distributor." }}
                            />
                        ) : (
                            <CustomerSelection
                                userType={userType}
                                control={control}
                                errors={errors?.employee?.customer}
                                setValue={setValue}
                                rules={{ required: "Must select a customer." }}
                            />
                        )}
                    </Grid>
                </AuthReq>
            ) : null}

            {userType === "operator" ||
            (permissionGroup && ["FactoryWorkers", "FacilityAdmin", "Operator"].includes(permissionGroup.id)) ? (
                <Grid xs={12}>
                    <FacilitySelection control={control} errors={errors?.employee?.facility_ids} />
                </Grid>
            ) : null}
            <Grid xs={12} sm={6}>
                <FormTextField
                    control={control}
                    rules={{ required: "First Name Required" }}
                    required
                    name="user.first_name"
                    label="First Name"
                    autoComplete="given-name"
                    errors={errors?.user?.first_name}
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <FormTextField
                    control={control}
                    required
                    rules={{ required: "Last Name Required" }}
                    name="user.last_name"
                    label="Last Name"
                    autoComplete="family-name"
                    errors={errors?.user?.last_name}
                />
            </Grid>
            {userType !== "operator" && (
                <Grid xs={12}>
                    <FormTextField
                        control={control}
                        required
                        rules={{
                            required: "Username Required",
                            pattern: {
                                value: /^[a-zA-Z0-9_-]*$/,
                                message:
                                    "Usernames can only contain alphanumeric and _ (underscore) - (hyphen) characters.",
                            },
                            validate: {
                                validUsername: async (value) =>
                                    (await ValidateUniqueUsername(value)) || "Username is already taken.",
                            },
                        }}
                        name="user.username"
                        label="Username"
                        errors={errors?.user?.username}
                        autoComplete=""
                    />
                </Grid>
            )}

            <Grid xs={12}>
                <FormTextField
                    control={control}
                    required
                    name="operator.pin"
                    label="PIN"
                    rules={{
                        required: "PIN Required",
                        minLength: {
                            value: pinMin,
                            message: `PIN must be at least ${pinMin} digits.`,
                        },
                        maxLength: {
                            value: pinMax,
                            message: `PIN cannot be longer than ${pinMax} digits.`,
                        },
                        pattern: {
                            value: /^[0-9]*$/,
                            message: "PINs can only contain numbers.",
                        },
                        validate: {
                            validatePin: async (value) =>
                                (await ValidateUniquePin(value, userType, customer?.id)) || "PIN must be unique.",
                        },
                    }}
                    errors={errors?.operator?.pin}
                />
            </Grid>
            <Grid xs={12}>
                <FormTextField
                    control={control}
                    required
                    rules={{
                        required: "Email Address Required",
                        pattern: {
                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Must be a valid email address.",
                        },
                    }}
                    name="employee.email_address"
                    label="Email Address"
                    errors={errors?.employee?.email_address}
                    autoComplete="email"
                />
            </Grid>
            <Grid xs={12}>
                <FormTextField
                    control={control}
                    rules={{ required: false }}
                    name="employee.phone"
                    label="Phone Number"
                    errors={errors?.employee?.phone}
                    autoComplete="phone"
                />
            </Grid>
            {showNotificationOptions ? (
                <React.Fragment>
                    <Grid xs={12}>
                        <FormSelect
                            control={control}
                            name="operator.notifications"
                            fullWidth
                            rules={{ required: false }}
                            label="Notifications"
                            required
                            errors={errors?.operator?.notifications}
                            options={notificationDropdown}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <FormControlLabel
                            control={
                                <FormCheckbox
                                    control={control}
                                    name="operator.is_admin"
                                    color="secondary"
                                    rules={{ required: false }}
                                />
                            }
                            label={t("Device Admin")}
                        />
                    </Grid>
                </React.Fragment>
            ) : null}
            {isModal && (
                <React.Fragment>
                    <Grid xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <FormControlLabel
                            label={`Create Another ${userTypeDisplay}`}
                            control={
                                <Checkbox
                                    value={createAnotherUser}
                                    onChange={(e) => dispatch(setCreateAnotherUser(e.target.checked))}
                                />
                            }
                        />
                    </Grid>
                </React.Fragment>
            )}
            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {isModal && (
                    <Button variant="outlined" color="error" onClick={() => dispatch(resetState())}>
                        {t("Cancel")}
                    </Button>
                )}
                <Button sx={{ ml: 2 }} variant="contained" type="submit">
                    {t("Submit")}
                </Button>
            </Grid>
        </Grid>
    );
};

export default memo(CreateUserForm);
