import React, { useContext, useEffect } from "react";
import { Box, Card, CardContent, CardHeader, CircularProgress, Container, Divider, Typography } from "@mui/material";
import CreateUserForm from "./CreateUserForm";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import withAuth from "src/components/withAuth";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "src/components/library/UserContext";
import {
    formSubmitted,
    formSuccessful,
    resetState,
    setFailed,
    setPinMax,
    setPinMin,
    setUserType,
    setUserTypeDisplay,
} from "./createUserSlice";
import { isGroupSterilis } from "src/components/library/helpers";
import AuthService from "src/components/AuthService";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import CreateUserInfo from "./CreateUserInfo";
import MainCard from "src/components/material/Cards/MainCard";

const Auth = new AuthService();

const userTypeGroups = {
    customer: "CustomerPortalUsers",
    facilityadmin: "FacilityAdmin",
    distributor: "DistributorSiteAdmin",
};

const CreateUser = (props) => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            user: {
                first_name: "",
                last_name: "",
                username: "",
                permission: null,
                email: "",
            },
            employee: {
                email_address: "",
                phone: "",
                facility_ids: [],
                customer: null,
                distributor: null,
                is_admin: false,
            },
            operator: {
                pin: "",
                notifications: 0,
                is_admin: false,
            },
        },
        mode: "onBlur",
    });

    const userContext = useContext(UserContext);
    // @ts-ignore
    const { user } = userContext;
    // @ts-ignore
    const { group } = useSelector((store) => store.user);

    // @ts-ignore
    const { loading, userType, userTypeDisplay } = useSelector((store) => store.createUser);

    const { t } = useTranslation("translations");

    const getUserTypeFromUrl = () => {
        const shortUserType = queryString.parse(props.location.search).type;
        switch (shortUserType) {
            case "ster":
                dispatch(setUserType("sterilis"));
                dispatch(setUserTypeDisplay(t("Sterilis User")));
                dispatch(setPinMin(8));
                dispatch(setPinMax(8));
                break;
            case "dist":
                dispatch(setUserType("distributor"));
                dispatch(setUserTypeDisplay(t("Distributor User")));
                dispatch(setPinMin(8));
                dispatch(setPinMax(8));
                break;
            case "cust":
                dispatch(setUserType("customer"));
                dispatch(setUserTypeDisplay(t("Site Administrator")));
                break;
            case "operator":
                dispatch(setUserType("operator"));
                dispatch(setUserTypeDisplay(t("Customer Device Operator")));
                break;
            case "fadm":
                dispatch(setUserType("facilityadmin"));
                dispatch(setUserTypeDisplay(t("Facility Administrator")));
                break;
            default:
                break;
        }
    };

    const createEmployee = (data) => {
        const employee = data?.employee && { ...data.employee };
        const operator = data?.operator && { ...data.operator };
        const portalUser = data?.user && {
            ...data.user,
            is_active: false,
            group: data?.user?.permission ? data?.user.permission?.value : userTypeGroups[userType],
        };

        let customerID = employee?.customer ? employee?.customer.id : user.customer_id;
        if (group === "DistributorAdmins" && userType === "distributor") {
            customerID = user.customer_id;
        }

        if (userType === "distributor" && employee.distributor) {
            customerID = employee.distributor.key;
            delete employee.distributor;
        }

        if (isGroupSterilis(group) && userType === "sterilis") {
            if (portalUser?.permission?.value === "FactoryWorkers") {
                customerID = 1;
            } else {
                customerID = user.customer_id;
            }
        }
        delete employee.customer;

        employee.full_name = `${portalUser.first_name} ${portalUser.last_name}`;
        const completionNotifications = [1, 3].includes(operator.notifications);
        const errorNotifications = [2, 3].includes(operator.notifications);
        delete operator.notifications;

        operator["notify_complete"] = completionNotifications;
        operator["notify_error"] = errorNotifications;
        if (userType !== "operator") {
            employee["portal_user"] = portalUser;
        }
        //employee["facility_ids"] = facilityIds;
        employee["customer_id"] = customerID;
        employee["device_operator"] = operator;

        return Auth.fetch(`/api/employee/`, {
            method: "POST",
            body: JSON.stringify(employee),
        })
            .then((data) => {
                return data?.id;
            })
            .catch((err) => {
                dispatch(setFailed(true));
            });
    };

    const handleFormSubmit = (data) => {
        const {
            user: { username, first_name },
        } = data;
        dispatch(formSubmitted());

        if (!data.employee.customer && userType === "sterilis" && data.user.permission.value === "FactoryWorkers") {
            data.employee.customer = { id: 1, name: "Sterilis Factory", dist_name: "Sterilis", dist_id: 1 };
        }

        createEmployee(data).then(() => {
            dispatch(formSuccessful(`${userTypeDisplay} ${username || first_name} Created Successfully!`));
        });
    };

    useEffect(() => {
        dispatch(resetState());
        getUserTypeFromUrl();
    }, [props.location.search]);

    return (
        <MainCard>
            <Container component="main" maxWidth="xl">
                {loading ? (
                    <Box
                        sx={{
                            width: "100%",
                            height: "80vh",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                        }}>
                        <CircularProgress size={100} sx={{ m: "auto" }} />
                    </Box>
                ) : (
                    <MainCard sx={{ mt: 4, mb: 4 }} variant="outlined">
                        <CardHeader
                            title={
                                <Typography variant="h4" color="secondary">
                                    {t("Create")} {userTypeDisplay}
                                </Typography>
                            }
                            subheader={t(
                                "This form is to create a new portal user who will be able to log onto the Sterilis portal."
                            )}
                        />

                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}>
                                <Grid container spacing={2}>
                                    <Grid xs={3.8}>
                                        <Box m={2}>
                                            <CreateUserInfo />
                                        </Box>
                                    </Grid>
                                    <Grid xs={0.2}>
                                        <Divider orientation="vertical" variant="middle" />
                                    </Grid>
                                    <Grid xs={8}>
                                        <Box
                                            component="form"
                                            m={2}
                                            noValidate
                                            onSubmit={handleSubmit(handleFormSubmit)}>
                                            <CreateUserForm
                                                control={control}
                                                errors={errors}
                                                setValue={setValue}
                                                isModal={false}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </MainCard>
                )}
            </Container>
        </MainCard>
    );
};

export default withAuth(
    ["SterilisSuperUsers", "SterilisPortalUsers", "DistributorAdmins", "SterilisWasteTypeAdmin"],
    "internalPage"
)(CreateUser);
