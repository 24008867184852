/*
*  This is the main table for the View Customers page.
*  It has a table within it's table (FacilityTable.js) for every facility
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Popup} from "semantic-ui-react";
import { isGroupSterilis, isGroupAdmin, isGroupAdminOrDistFse } from "../../library/helpers";
import HookTable from "../../library/HookTable";
import expandColumn from "../../library/ExpandColumn";
import SimpleLoader from "../../SimpleLoader";

const CustomerTable = (props) => {
    const customRowClass = (rowInfo) => {
        if (rowInfo.original) {
            if (rowInfo.original.deleted_status) {
                return "light-grey-tr";
            } else if (rowInfo.original.banned_status) {
                return "light-red-tr";
            }
        }
    };

    const numOfFacilities = (data) => {
        return data.reduce((acc, curr) => {
            return (acc += curr["subRows"].length);
        }, 0);
    };

    const numOfDevices = (data) => {
        return data.reduce((acc, curr) => {
            return (acc += curr["subRows"].reduce((acc, curr) => {
                return (acc += curr["subRows"].length);
            }, 0));
        }, 0);
    };

    const { t, customerFilter, distributorFilter, group } = props;
    const columns = [
        expandColumn(t("Number of Facilities")),
        ...(isGroupSterilis(group)
            ? [
                  {
                      Header: t("Distributor"),
                      accessor: "distributor_name",
                  },
              ]
            : []),
        {
            Header: t("Customer"),
            accessor: "customer_name",
        },
        {
            Header: t("Cycle Count (past week)"),
            accessor: "customer_cycle_count",
            manualWidth: 100,
        },
        {
            Header: t("Communication Status"),
            accessor: "customer_device_summary",
            sortType: (a, b, columnID) => {
                // don't invoke the sort method on child rows
                if (a.depth === 0 && b.depth === 0) {
                    if (
                        a.original[columnID]["props"]["data-greensum"] ===
                        b.original[columnID]["props"]["data-greensum"]
                    ) {
                        return a.original[columnID]["props"]["data-greensum"] >
                            b.original[columnID]["props"]["data-greensum"]
                            ? 1
                            : -1;
                    }
                    return a.original[columnID]["props"]["data-greensum"] >
                        b.original[columnID]["props"]["data-greensum"]
                        ? 1
                        : -1;
                }
            },
        },
        ...(isGroupAdminOrDistFse(group)
            ? [
                  {
                      Header: t("Actions"),
                      accessor: "actions",
                      className: "actions-column",
                  },
              ]
            : []),
    ];

    let data = props.customerTableArray;

    if (customerFilter) {
        data = data.filter((row) => {
            if (customerFilter === "active") {
                return row["banned_status"] === false && row["deleted_status"] === null;
            } else if (customerFilter === "banned") {
                return row["banned_status"] === true;
            } else if (customerFilter === "deleted") {
                // Decommissioned is a null/not null field, so returning it's presence is enough
                return row["deleted_status"];
            } else {
                return row;
            }
        });
    }

    if (distributorFilter) {
        data = data.filter((row) => {
            return row["distributor_id"] === distributorFilter;
        });
    }

    return (
        <div>
            <Link className="text-right-align" id="createCustomerLink" to="/create/v2/customer">
                {t("Click here to create a new customer")}
            </Link>
            <div className="flex-dir-row even-split view-customer-filter_container">
                {props.customerFilterDropdown}
                {props.distributorFilterDropdown}
            </div>
            <div className="table-header-flex">
                {props.isLoading ? <SimpleLoader /> : null}
                <div className="customer-stats">
                    {props.isLoading === false && (
                        <React.Fragment>
                            <div className="stat-info">
                                <p className="large-num">{data.length}</p>
                                <div className="stat-name">
                                    <Trans ns="translations" i18nKey="total_customers_br">
                                        TOTAL <br /> CUSTOMERS
                                    </Trans>
                                </div>
                            </div>
                            <div className="stat-info">
                                <p className="large-num">{numOfFacilities(data)}</p>
                                <div className="stat-name">
                                    <Trans ns="translations" i18nKey="total_facilities_br">
                                        TOTAL <br /> FACILITIES
                                    </Trans>
                                </div>
                            </div>
                            <div className="stat-info">
                                <p className="large-num">{numOfDevices(data)}</p>
                                <div className="stat-name">
                                    <Trans ns="translations" i18nKey="total_devices_br">
                                        TOTAL <br /> DEVICES
                                    </Trans>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className="search-div">
                    <Popup
                        content={
                            <div>
                                <div className="small-width minor-padding light-grey-tr">
                                    {t("Customer, facility or device has been archived")}
                                </div>
                                <div className="small-width minor-padding light-red-tr">
                                    {t("Customer, facility, or device has been suspended")}
                                </div>
                                <div className="small-width minor-padding light-yellow-tr">
                                    {t("Device has not been activated with the four digit initialization key")}
                                </div>
                            </div>
                        }
                        on="click"
                        position="bottom right"
                        trigger={
                            <Button
                                className="grey-btn view-customers-table-legend-btn"
                                content={t("Show Table Legend")}
                            />
                        }
                    />
                </div>
            </div>
            <HookTable
                searchParam={props.searchParam}
                tableName="customerTable"
                innerTable={false}
                columns={columns}
                data={data}
                group={group}
                subComponentJSX={"facilityTable"}
                customRowClass={true}
                customRowClassFunc={customRowClass}
                expandableTable={true}
                defaultSortBy={{ id: "customer_device_summary", desc: true }}
            />
        </div>
    );
};

export default translate('translations')(CustomerTable);
