/*
*  Device Health - Failed BIT table
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import HookTable from "../library/HookTable";

const FailedBurninTest = props => {
  const {
    t,
    BurninData
  } = props;

  const columns = [
    {
      Header: t('Device Serial'),
      accessor: 'device_serial',
      id: 'device_serial',
    },
    {
      Header: t('BIT Date'),
      accessor: 'bit_date',
      id: 'bit_date',
    },
    {
      Header: t('BIT Score'),
      accessor: 'bit_score',
      id: 'bit_score',
    },
    {
      Header: t('Test 1'),
      accessor: 'test_score.1',
      id: 'test_1',
    },
    {
      Header: t('Test 2'),
      accessor: 'test_score.2',
      id: 'test_2',
    },
    {
      Header: t('Test 3'),
      accessor: 'test_score.3',
      id: 'test_3',
    },
    {
      Header: t('Test 4'),
      accessor: 'test_score.4',
      id: 'test_4',
    },
    {
      Header: t('Test 5'),
      accessor: 'test_score.5',
      id: 'test_5',
    },
    {
      Header: t('Test 6'),
      accessor: 'test_score.6',
      id: 'test_6',
    },
    {
      Header: t('Test 7'),
      accessor: 'test_score.7',
      id: 'test_7',
    }
  ];

  return (
    <HookTable
      id='BurninData'
      data={BurninData}
      columns={columns}
      tableName='BurninData'
      defaultSortBy={{id: 'bit_date', desc: true}}
    />
  )
};
export default translate('translations')(FailedBurninTest);
