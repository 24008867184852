import React, { useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Paper, useTheme } from "@mui/material";
import { CancelTwoTone, CheckCircleTwoTone, EditTwoTone, SettingsTwoTone } from "@mui/icons-material";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import EditDistributorModal from "../Edit/EditDistributorModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchDistributors, setEditModalOpen, setSelectedDistributor } from "../distributorSlice";

const SettingsButton = (props) => {
    const { value } = props;
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const settingsOpen = Boolean(anchorEl);

    const handleSettingsClick = (event, distId) => {
        setAnchorEl(event.currentTarget);
        dispatch(setSelectedDistributor(distId));
    };
    const handleSettingsClose = () => {
        setAnchorEl(null);
        dispatch(setSelectedDistributor(null));
    };

    const handleEditClick = (distId) => {
        dispatch(setSelectedDistributor(distId));
        dispatch(setEditModalOpen(true));
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={(event) => handleSettingsClick(event, value)}>
                <SettingsTwoTone fontSize="large" />
            </IconButton>
            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                open={settingsOpen}
                onClose={handleSettingsClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}>
                <MenuItem onClick={() => handleEditClick(value)}>
                    <EditTwoTone /> Edit
                </MenuItem>
            </Menu>
        </>
    );
};

const Toolbar = (props) => {
    return (
        <GridToolbarContainer sx={{ display: "flex" }}>
            <Box sx={{ flex: 1 }}>
                <GridToolbarQuickFilter />
            </Box>
            <GridToolbarExport csvOptions={{ fields: ["distributor_name", "main_customer"] }} />
        </GridToolbarContainer>
    );
};

const DistributorTable = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    // @ts-ignore
    const { distributors } = useSelector((store) => store.distributor);

    const columns = [
        {
            headerName: "Active",
            field: "deleted",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
            renderCell: (params) =>
                params.value === null ? (
                    <CheckCircleTwoTone fontSize="large" sx={{ color: theme.palette.success.main }} />
                ) : (
                    // @ts-ignore
                    <CancelTwoTone fontSize="large" sx={{ color: theme.status.failed.light }} />
                ),
        },
        { headerName: "Name", field: "distributor_name", headerAlign: "center", align: "center", flex: 1 },
        {
            headerName: "Main Customer",
            field: "main_customer",
            headerAlign: "center",
            align: "center",
            flex: 1,
            valueGetter: (params) => {
                return params.row.main_customer.customer_name;
            },
        },
        {
            headerName: "Settings",
            field: "id",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: SettingsButton,
        },
    ];

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchDistributors());
    }, []);

    return (
        <>
            <DataGrid
                sx={{ width: "95%", margin: "auto" }}
                autoHeight
                // @ts-ignore
                columns={columns}
                rows={distributors}
                disableSelectionOnClick
                components={{
                    Toolbar: Toolbar,
                }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
            <EditDistributorModal />
        </>
    );
};

export default DistributorTable;
