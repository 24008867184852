/*
*  Component for the Site Admin table
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Checkbox, Dropdown} from "semantic-ui-react";
import {
  isGroupSterilisOrDist,
  isGroupInGroups,
  isGroupDist,
  isGroupSterilis,
} from './library/helpers'
import DistributorDropdownFilter from "./view/distributors/DistributorDropdownFilter";
import HookTable from "./library/HookTable";

class CustomerSiteAdminsTable extends React.Component {
    state = {
        showDeleted: false,
        distributorFilter: 0,
        selected: [0],
        tablePortalUsers: [],
    };

    componentDidMount() {
        this.setState({
            tablePortalUsers: this.props.siteAdmins,
        });
    }

    componentDidUpdate(prevProps) {
        // Update the shown device operators when a user clicks the Filter By Customer dropdown
        if (this.props.selectedCustomer !== prevProps.selectedCustomer) {
            const { selectedCustomer, siteAdmins } = this.props;

            if (selectedCustomer !== -1) {
                const filteredPortalUsers = siteAdmins.filter((user) => {
                    return user["customer_id"] === selectedCustomer;
                });

                this.setState(
                    {
                        tablePortalUsers: filteredPortalUsers,
                    },
                    () => this.props.setSiteAdminCount(this.state.tablePortalUsers.length)
                );
            } else {
                this.setState(
                    {
                        tablePortalUsers: siteAdmins,
                    },
                    () => this.props.setSiteAdminCount(this.state.tablePortalUsers.length)
                );
            }
        }

        if (this.props.siteAdmins !== prevProps.siteAdmins) {
            const { selectedCustomer, siteAdmins } = this.props;

            if (selectedCustomer !== -1) {
                const filteredPortalUsers = siteAdmins.filter((user) => {
                    return user["customer_id"] === selectedCustomer;
                });

                this.setState(
                    {
                        tablePortalUsers: filteredPortalUsers,
                    },
                    () => this.props.setSiteAdminCount(this.state.tablePortalUsers.length)
                );
            } else {
                this.setState(
                    {
                        tablePortalUsers: siteAdmins,
                    },
                    () => this.props.setSiteAdminCount(this.state.tablePortalUsers.length)
                );
            }
        }
    }

    handleHideDeleted = () => {
        this.setState((prevState) => {
            return { showDeleted: !prevState.showDeleted };
        });
    };

    handleMultiSelectDropdownChange = (e, { value }) => {
        let difference = [];
        if (this.state.selected.length > value.length) {
            // an item has been removed
            difference = this.state.selected.filter((x) => !value.includes(x));
        } else if (this.state.selected.length < value.length) {
            difference = this.state.selected.filter((x) => value.includes(x));
        }
        // If user chooses all Devices, remove all other selections and do base query on /customer-machines
        if (value.includes(0) && !difference.includes(0)) {
            this.setState(
                {
                    selected: [0],
                },
                () => {
                    this.props.setQueryString("");
                }
            );
        } else {
            // In this else, since the user has chosen something that is not 'ALl Devices',
            // remove the 'All devices' index from the value array
            const indexOfZero = value.indexOf(0);
            if (indexOfZero > -1) {
                value.splice(indexOfZero, 1);
            }
            this.setState({ selected: value }, () => {
                const { selected } = this.state;
                const queryString = selected.reduce((str, cur) => {
                    switch (cur) {
                        case 1:
                            return (str += "group=CustomerPortalUsers&");
                        case 2:
                            return (str += "group=FSEs&");
                        case 3:
                            return (str += "group=ExternalFSEs&");
                        case 4:
                            return (str += "group=FactoryWorkers&");
                        case 5:
                            return (str += "group=SterilisPortalUsers&");
                        case 6:
                            return (str += "group=SterilisSuperUsers&");
                        case 7:
                            return (str += "group=DistributorAdmins&");
                        case 8:
                            return (str += "group=DistributorFSEs&");
                        case 9:
                            return (str += "group=DistributorReadOnly&");
                        case 10:
                            return (str += "group=SterilisWasteTypeAdmin&");
                        case 11:
                            return (str += "group=FacilityAdmin&");
                        default:
                            return (str += "");
                    }
                }, "?");
                this.props.setQueryString(queryString);
            });
        }
    };

    customRowClass = (rowInfo) => {
        if (rowInfo && rowInfo.original) {
            if (rowInfo.original.deleted_status) {
                return "light-red-tr";
            } else if (rowInfo.original.active_status === false) {
                return "light-yellow-tr";
            }
        }
    };

    handleDistributorFilterChange = (event, data) => {
        this.setState({
            distributorFilter: data.value,
        });
    };

    userFilterOptionsForGroup = (group) => {
        const { t } = this.props;

        if (isGroupDist(group)) {
            return [
                {
                    key: 0,
                    value: 0,
                    text: t("All Users"),
                },
                {
                    key: 1,
                    value: 1,
                    text: t("Customer Site Admins"),
                },
                {
                    key: 7,
                    value: 7,
                    text: t("Admins"),
                },
                {
                    key: 8,
                    value: 8,
                    text: t("Field Service Engineers"),
                },
                {
                    key: 9,
                    value: 9,
                    text: t("Read Only accounts"),
                },
                {
                    key: 11,
                    value: 11,
                    text: t("Facility Admin"),
                },
            ];
        } else if (isGroupSterilis(group)) {
            return [
                {
                    key: 0,
                    value: 0,
                    text: t("All Users"),
                },
                {
                    key: 1,
                    value: 1,
                    text: t("Customer Site Admins"),
                },
                {
                    key: 2,
                    value: 2,
                    text: t("Field Service Engineers"),
                },
                {
                    key: 3,
                    value: 3,
                    text: t("External Field Service Engineers"),
                },
                {
                    key: 4,
                    value: 4,
                    text: t("Factory workers"),
                },
                {
                    key: 5,
                    value: 5,
                    text: t("Sterilis Users"),
                },
                {
                    key: 6,
                    value: 6,
                    text: t("Sterilis Super Users"),
                },
                {
                    key: 7,
                    value: 7,
                    text: t("Distributor Admins"),
                },
                {
                    key: 8,
                    value: 8,
                    text: t("Distributor FSEs"),
                },
                {
                    key: 9,
                    value: 9,
                    text: t("Distributor Read Only"),
                },
                {
                    key: 10,
                    value: 10,
                    text: t("Waste Type Admin"),
                },
                {
                    key: 11,
                    value: 11,
                    text: t("Facility Admin"),
                },
            ];
        }
    };

    render() {
        const { t, group, allUsers, distributorDropdownArray, tabpermissiongroup, isFacilityTab } = this.props;
        const { showDeleted, distributorFilter, tablePortalUsers } = this.state;

        const columns = [
            ...(isGroupSterilisOrDist(group)
                ? [
                      {
                          Header: t("Customer"),
                          accessor: "customer_name",
                      },
                  ]
                : []),
            ...(isGroupInGroups("FacilityAdmin", tabpermissiongroup)
                ? [
                      {
                          Header: t("Facility"),
                          accessor: "facility_name",
                      },
                  ]
                : []),
            {
                Header: allUsers ? t("Role") : t("Status"),
                accessor: "status",
                manualWidth: allUsers ? 165 : 60,
            },
            {
                Header: t("Username"),
                accessor: "username",
            },
            {
                Header: t("Full name"),
                accessor: "full_name",
            },
            {
                Header: t("PIN"),
                accessor: "pin",
            },
            {
                Header: t("Contact Information"),
                accessor: "contact_info",
                manualWidth: 150,
            },
            ...(isGroupInGroups(group, tabpermissiongroup)
                ? [
                      {
                          Header: t("Actions"),
                          accessor: "actions",
                          manualWidth: 60,
                      },
                  ]
                : []),
        ];

        const userDropdown = this.userFilterOptionsForGroup(group);
        const userFilterDropdown = (
            <div className="form-group">
                <label>
                    <h4 className="orange-text device-filter-header">{t("User Filter")}</h4>
                </label>
                <Dropdown
                    className="device-filter-dropdown"
                    search
                    selection
                    onChange={this.handleMultiSelectDropdownChange}
                    fluid
                    multiple
                    value={this.state.selected}
                    id="filterSelectionDropdown"
                    options={userDropdown}
                />
            </div>
        );

        const floatLeftHeaderJSX = (
            <div className="table-sub-header">
                <Checkbox
                    label={
                        allUsers
                            ? t("Show archived portal users")
                            : isFacilityTab
                            ? t(`Show archived facility admins`)
                            : t(`Show archived site admins`)
                    }
                    name={isFacilityTab ? "showDeletedFacilityAdmins" : "showDeletedSiteAdmins"}
                    id={isFacilityTab ? "showDeletedFacilityAdmins" : "showDeletedSiteAdmins"}
                    className={allUsers ? "slight-margin-bottom" : ""}
                    checked={showDeleted}
                    onChange={this.handleHideDeleted}
                />
                {allUsers ? (
                    <div className="flex-dir-row even-split view-customer-filter_container">
                        {userFilterDropdown}
                        <DistributorDropdownFilter
                            group={group}
                            onChange={this.handleDistributorFilterChange}
                            selectedDistributor={distributorFilter}
                            distributorDropdownArray={distributorDropdownArray}
                        />
                    </div>
                ) : null}
            </div>
        );

        let portalUserTableArray = tablePortalUsers;

        if (distributorFilter) {
            portalUserTableArray = portalUserTableArray.filter((row) => {
                return row["distributor_id"] === distributorFilter;
            });
        }

        return (
            <div>
                <HookTable
                    id={isFacilityTab ? "customerFacilityAdminTable" : "customerSiteAdminTable"}
                    data={portalUserTableArray}
                    columns={columns}
                    defaultSortBy={{ id: "full_name" }}
                    tableName={isFacilityTab ? "customerFacilityAdminTable" : "customerSiteAdminTable"}
                    clickableTable={false}
                    showDeleted={showDeleted}
                    floatLeftHeaderJSX={floatLeftHeaderJSX}
                    customRowClass={true}
                    customRowClassFunc={this.customRowClass}
                />
            </div>
        );
    }
}

export default translate('translations')(CustomerSiteAdminsTable);
