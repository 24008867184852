import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { UnarchiveTwoTone } from "@mui/icons-material";
import AuthService from "src/components/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { selectSerial, setArchiveDialogOpen } from "../deviceInfoSlice";
import { UserContext } from "src/components/library/UserContext";
import { useSnackbar } from "notistack";

const Auth = new AuthService();

const RestoreArchivedDataDialog = (props) => {
    const { t } = useTranslation("translations");
    const dispatch = useDispatch();
    const { selectedDeviceConfig, archiveDialogOpen } = useSelector((store) => store.deviceInfo);
    const { user } = useSelector((store) => store.user);
    const deviceSerial = useSelector(selectSerial);
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const notifyNoFilesFound = () =>
        enqueueSnackbar(`No Archived Files Were Found For Device ${deviceSerial}.`, { variant: "warning" });

    const notifyFailure = () =>
        enqueueSnackbar(`An Error Occured While Retrieving Files For Device ${deviceSerial}.`, { variant: "error" });

    const notifySuccess = () =>
        enqueueSnackbar(
            `Your file request has been successfully submitted. The files should be available to view within 5-12 hours.`,
            { variant: "success" }
        );

    const retrieveArchivedData = () => {
        setLoading(true);
        return Auth.fetch(`/api/retrieve-s3-data/`, {
            method: "POST",
            body: JSON.stringify({
                deviceSerial: deviceSerial,
                userEmail: user ? user.email : userContext?.user?.email,
                user_id: user ? user.portal_user_id : userContext?.user?.portal_user_id,
            }),
        })
            .then((data) => {
                if (data) {
                    dispatch(setArchiveDialogOpen(false));
                    notifySuccess();
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    dispatch(setArchiveDialogOpen(false));
                    notifyNoFilesFound();
                } else {
                    dispatch(setArchiveDialogOpen(false));
                    notifyFailure();
                }
                setLoading(false);
            });
    };

    return (
        <Dialog open={archiveDialogOpen}>
            <DialogTitle>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ marginRight: 2 }}>
                        <UnarchiveTwoTone />
                    </Avatar>
                    {t("Retrieve Archived Data")}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
                    {loading ? (
                        <>
                            <CircularProgress />
                            <Typography color={"primary"}>{t("Retrieving Archived Files")}</Typography>
                        </>
                    ) : (
                        <>
                            <Typography>
                                {t("Are you sure you want to retrieve archived data older than 3 years?")}
                            </Typography>
                            <Alert severity="info">
                                {t("Once submitted, the request will be processed within 12 hours.")}
                            </Alert>
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button color="error" onClick={() => dispatch(setArchiveDialogOpen(false))}>
                    {t("Cancel")}
                </Button>
                <Button
                    color="success"
                    variant="outlined"
                    onClick={() => {
                        retrieveArchivedData();
                    }}>
                    {t("Submit")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RestoreArchivedDataDialog.propTypes = {};

export default RestoreArchivedDataDialog;
