/*
*  Modal which houses the Customer Device Report functionality
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../../SimpleLoader";
import {parse as jsonToCSV} from 'json2csv';
import * as Sentry from "@sentry/browser";
import AuthService from "../../AuthService";
import moment from "moment";

const Auth = new AuthService();

class CustomerDeviceReportModal extends React.Component {
  state = {
    modalLoading: false,
    allowModalToBeClosed: true,
    customerDropdownValue: 'active',
  };

  formatBioInterval = (bioInterval) => {
    // TODO: Figure out a more flexible way to calculate the bio interval
    switch (bioInterval) {
      case '2419200':
        return '1 month';
      case '7257600':
        return '3 months';
      case '144000':
        return '40 hours';
      case '360000':
        return '100 hours';
      case '1209600':
        return '14 days';
      case '604800':
        return '7 days';
      default:
        return bioInterval
    }
  };

  calculateTimeLastCommunicated = (timeLastCommunicated) => {
    if (timeLastCommunicated) {
      //Get rid of milliseconds
      const timeDelta = Math.abs((Date.now() / 1000) - (Date.parse(timeLastCommunicated) / 1000));
      const duration = moment.duration(timeDelta, 'seconds');
  
      const s = Math.floor((duration / 1000) % 60);
      const m = Math.floor((duration / 1000 / 60) % 60);
      const h = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const d = Math.floor(duration / (1000 * 60 * 60 * 24));
      const formattedDelta = d + ' days ' + h + ' hours ' + m + ' minutes ' + s + ' seconds ago';
      return formattedDelta
    }
  };

  customerDeviceReport = () => {
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });


    Auth.fetch(`/api/export/customer-device`, {
      method: 'GET',
    }).then(data => {

      let new_array = []
      data.forEach((customer) => {
        let customerStatus = false
        let customerStatusVal
        
        if (this.state.customerDropdownValue === 'active' && customer.banned === false && customer.deleted === null) {
          customerStatus = true
          customerStatusVal = 'Active'
        } else if (this.state.customerDropdownValue === 'banned' && customer.banned === true) {
          customerStatus = true
          customerStatusVal = 'Banned'
        } else if (this.state.customerDropdownValue === 'deleted' && customer.deleted !== null) {
          customerStatus = true
          customerStatusVal = 'Deleted'
        } else if(this.state.customerDropdownValue === 'all'){
          customerStatus = true
          if(customer.banned === false && customer.deleted === null){
            customerStatusVal = 'Active'
          }
          else if(customer.banned === true){
            customerStatusVal = 'Banned'
          }
          else if(customer.deleted !== null){
            customerStatusVal = 'Deleted'
          }
        }
         
        customer['facilities'].forEach((facility) => {
            
            let deviceAvailable = false
            
            let formattedBioIntervalDuration = this.formatBioInterval(facility.region_bio_interval_duration);
            let formattedBioInterval = facility.region_bio_interval_type
            if(facility.waste_per_month){
              formattedBioIntervalDuration = this.formatBioInterval(facility.bio_interval_duration);
              formattedBioInterval = facility.bio_interval_type
            }

            
            facility['device_configs'].forEach((device_config) => {
              let last_communication = ''
              let customerAssignment = moment.utc(device_config.created).tz(facility.device_timezone).format("MM/DD/YY HH:mm:ss z")
              let weight_of_waste = '0 ' + customer.weight_unit_preference + 's'
              let cycles_in_month = '0'
              let last_cycle_time = ''
              let last_cycle_message = ''
              if (device_config.device){
                cycles_in_month = device_config.device.cycles_in_month.toString()
                weight_of_waste = device_config.device.weight_in_month.toString() + " " + customer.weight_unit_preference + 's'
                last_communication = this.calculateTimeLastCommunicated(device_config.device.time_of_last_communication)
              }
              
              device_config.cycles.forEach((cycle) => {
              cycle.cycle_status.forEach((lastcyclestatus) => {
                last_cycle_time = moment.utc(lastcyclestatus.timestamp).tz(facility.device_timezone).format("MM/DD/YY HH:mm:ss z")
                last_cycle_message = lastcyclestatus['cycle_state'].message
              });
            });
              deviceAvailable = true
              if (customerStatus && device_config.device){

                let deviceInfo = {
                  'Customer' : customer.customer_name,
                  'Customer Status': customerStatusVal,
                  'Facility Name' : facility.facility_name,
                  'Distributor': device_config.distributor,
                  'Address 1': facility.thoroughfare,
                  'Address 2': facility.sub_thoroughfare,
                  'City': facility.locality,
                  'State': facility.region_name,
                  'Country': facility.country.toUpperCase(),
                  'Zip Code': facility.postal_code,
                  'Facility Elevation (Ft.)': facility.elevation, 
                  'Time zone': facility.tz_display_label, 
                  'Regulatory Market': facility.facility_type, 
                  'Facility Bio-Challenge Type': facility.bio_validation_waste_type_display, 
                  'Bio-Challenge Interval Type': formattedBioInterval, 
                  'Bio-Challenge Interval': formattedBioIntervalDuration, 
                  'Min. Time (minutes)': facility.min_cook_time, 
                  'Min. Temp (C)': facility.min_cook_temp,
                  'Serial Number': device_config.device.serial_number, 
                  'Software Version': device_config.current_firmware ? device_config.current_firmware : 'No software assigned', 
                  'Communication Status(Last Communicated)': last_communication, 
                  'Last Cycle Date': last_cycle_time, 
                  'Last Cycle Outcome/Status': last_cycle_message,  
                  'Raspberry Pi version': device_config.device.pi_rev, 
                  'Linux Version': device_config.device.get_linux_version_display, 
                  'Network Type': device_config.device.network_type, 
                  'Customer Assignment Date': customerAssignment, 
                  'Customer Cycles completed (this month)': cycles_in_month, 
                  'Weight of waste processed (this month)': weight_of_waste, 
                  'Last PRV test': moment(device_config.device.time_last_prv_test).tz(facility.device_timezone).format("YYYY-MM-DD HH:mm z"), 
                  'Next PRV test due': moment(device_config.device.time_last_prv_test).add(6, 'months').tz(facility.device_timezone).format("YYYY-MM-DD HH:mm z") 
                  // TODO: Add next PRV date to database rather than calculating on front end.

                }
                new_array.push(deviceInfo)
                
              }
            });
            
          if (!deviceAvailable && customerStatus){
            let customerInfo = {
              'Customer' : customer.customer_name,
              'Customer Status': customerStatusVal,
              'Facility Name' : facility.facility_name,
              'Distributor': facility.distributor,
              'Address 1': facility.thoroughfare,
              'Address 2': facility.sub_thoroughfare,
              'City': facility.locality,
              'State': facility.region_name,
              'Country': facility.country.toUpperCase(),
              'Zip Code': facility.postal_code,
              'Facility Elevation (Ft.)': facility.elevation, 
              'Time zone': facility.tz_display_label, 
              'Regulatory Market': facility.facility_type, 
              'Facility Bio-Challenge Type': facility.bio_validation_waste_type_display, 
              'Bio-Challenge Interval Type': formattedBioInterval, 
              'Bio-Challenge Interval': formattedBioIntervalDuration, 
              'Min. Time (minutes)': facility.min_cook_time, 
              'Min. Temp (C)': facility.min_cook_temp,
              'Serial Number': 'NA', 
              'Software Version': '', 
              'Communication Status(Last Communicated)': '', 
              'Last Cycle Date': '', 
              'Last Cycle Outcome/Status': '',  
              'Raspberry Pi version': '', 
              'Linux Version': '', 
              'Network Type': '', 
              'Customer Assignment Date': '', 
              'Customer Cycles completed (this month)': '', 
              'Weight of waste processed (this month)': '', 
              'Last PRV test': '', 
              'Next PRV test due': ''

              }

              new_array.push(customerInfo)
              
            }

        });
        
      });
      
      const csv = jsonToCSV(new_array);
      const blob = new Blob([csv], {type: 'text/csv'});

      const link = document.createElement('a');

      const filePath = window.URL.createObjectURL(blob);

      link.href = filePath;

      link.download = `${this.state.customerDropdownValue}_customer_device_report-${moment().format("YYYY-MM-DD-HHmmss z")}.csv`;
      
      document.getElementById('downloadDiv').appendChild(link);
      link.click();
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => this.props.triggerCustomerDeviceReportModal());
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => this.props.triggerCustomerDeviceReportModal());
    });
  };

  customerDropdownChange = (event, data) => {
    this.setState({
        customerDropdownValue: data.value
    });
  };

  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading,
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const customerDropdownOptions = [
        {
            key: 'all',
            value: 'all',
            text: t('All Customers')
          },
          {
            key: 'active',
            value: 'active',
            text: t('Active Customers')
          },
          {
            key: 'banned',
            value: 'banned',
            text: t('Suspended Customers')
          },
          {
            key: 'deleted',
            value: 'deleted',
            text: t('Archived Customers')
          },

    ];
    return (
      <TransitionablePortal
        open={this.props.customerDeviceReportModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size={'mini'}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerCustomerDeviceReportModal()
          }}
        >
          <Header>
            <h2>{t('Export Customer Device Report')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            <div className="short-modal-text center-text">
              <p>{t('csv-download-on-submit', 'Upon clicking submit, a CSV will be downloaded to your browser.')}</p>

              <div className="form-group medium-margin-top">
                <label className="fake-float" htmlFor="customerTypeDropdown">{t('Customer Filter')}</label>
                <Dropdown
                  name='customerTypeDropdown'
                  className='medium-max-width'
                  selection
                  id='customerTypeDropdown'
                  options={customerDropdownOptions}
                  value={this.state.customerDropdownValue}
                  onChange={this.customerDropdownChange}
                  required
                />
              </div>
            </div>
          </Modal.Content>
          <div id='downloadDiv' className="download-div"/>
          <Modal.Actions>
            <Button className='ster-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    id='cycleReportBtn'
                    onClick={this.customerDeviceReport}
            >{t('Generate Report')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(CustomerDeviceReportModal);
