/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {useTranslation} from "react-i18next";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import PropTypes from 'prop-types';

const GrindCycleTrendsChart = props => {
  const {t} = useTranslation('translations');
  const combinedGrindCycleTrendOptions = {
    chart: {
      zoomType: 'x',
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        }
      }
    },
    tooltip: {
      shared: true,
    },
    title: {
      text: 'Grind Cycle Trends'
    },
    credits: false,
    xAxis: [{
      categories: props.cycleStartTimes,
      crosshair: true,
    }],
    yAxis: [
      ...props.trendTries ? [{
        id: 'triesYAxisLabel',
        labels: {
          format: 'Tries: {value}',
          enabled: props.hideAxisLabels,
          style: {
            color: 'dodgerblue'
          }
        },
        title: {
          text: t('Tries'),
          style: {
            color: 'dodgerblue'
          }
        },
      }] : [],
      ...props.trendGrindDuration ? [{
        id: 'grindDurationYAxisLabel',
        title: {
          text: 'Grind Duration',
          enabled: props.hideAxisLabels,
          style: {
            color: "lightseagreen"
          }
        },
        labels: {
          format: `{value} mins`,
          style: {
            color: "lightseagreen"
          }
        },
      }] : [],
      ...props.trendJack ? [{
        id: 'jacksYAxisLabel',
        title: {
          text: t('Jacks'),
          style: {
            color: 'fuchsia'
          }
        },
        labels: {
          format: `{value}`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'fuchsia'
          }
        },
      }] : [],
      ...props.trendStalls ? [{
        id: 'stallsYAxisLabel',
        title: {
          text: t('Stalls'),
          style: {
            color: 'MediumSlateBlue'
          }
        },
        labels: {
          format: `{value}`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'MediumSlateBlue'
          }
        },
      }] : [],
      ...props.trendNumberStalls ? [{
        id: 'numberStallsYAxisLabel',
        title: {
          text: t('Number of Stalls'),
          style: {
            color: 'MediumTurquoise'
          }
        },
        labels: {
          format: `{value}`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'MediumTurquoise'
          }
        },
      }] : [],
      ...props.trendForwardDuration ? [{
        id: 'forwardDurationYAxisLabel',
        title: {
          text: t('Forward Duration'),
          style: {
            color: 'LightSalmon'
          }
        },
        labels: {
          format: `{value} secs`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'LightSalmon'
          }
        },
      }] : [],
      ...props.trendReverseDuration ? [{
        id: 'reverseDurationYAxisLabel',
        title: {
          text: t('Reverse Duration'),
          style: {
            color: 'BlueViolet'
          }
        },
        labels: {
          format: `{value} secs`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'BlueViolet'
          }
        },
      }] : [],


    ],
    series: [
      ...props.trendTries ? [{
        name: "Tries",
        data: props.grindTriesTrend,
        yAxis: 'triesYAxisLabel',
        color: 'dodgerblue',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalTriesValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalTriesValues['maximum_value'],
            color: 'dodgerblue'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendGrindDuration ? [{
        name: "Grind Duration",
        data: props.grindDurationTrend,
        yAxis: 'grindDurationYAxisLabel',
        color: "lightseagreen",
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalGrindDurationValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalGrindDurationValues['maximum_value'],
            color: 'lightseagreen'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendJack ? [{
        name: "Jacks",
        data: props.grindJackTrend,
        yAxis: 'jacksYAxisLabel',
        color: 'fuchsia',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalJackValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalJackValues['maximum_value'],
            color: 'fuchsia'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendStalls ? [{
        name: "Stalls",
        data: props.grindStallsTrend,
        yAxis: 'stallsYAxisLabel',
        color: 'MediumSlateBlue',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalStallsValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalStallsValues['maximum_value'],
            color: 'MediumSlateBlue'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendNumberStalls ? [{
        name: "Number of Stalls",
        data: props.numberStallsTrend,
        yAxis: 'numberStallsYAxisLabel',
        color: 'MediumTurquoise',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalNumberStallsValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalNumberStallsValues['maximum_value'],
            color: 'MediumTurquoise'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendForwardDuration ? [{
        name: "Forward Duration",
        data: props.forwardDurationTrend,
        yAxis: 'forwardDurationYAxisLabel',
        color: 'DarkSeaGreen',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalForwardDurationValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalForwardDurationValues['maximum_value'],
            color: 'DarkSeaGreen'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendReverseDuration ? [{
        name: "Reverse Duration",
        data: props.reverseDurationTrend,
        yAxis: 'reverseDurationYAxisLabel',
        color: 'BlueViolet',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalReverseDurationValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalReverseDurationValues['maximum_value'],
            color: 'BlueViolet'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
    ],
  };
  return <HighchartsReact
    highcharts={Highcharts}
    options={combinedGrindCycleTrendOptions}
  />;
};


GrindCycleTrendsChart.defaultProps = {
  hideAxisLabels: true,
  trendStalls: true,
  trendGrindDuration: false,
  trendJack: true,
  trendNumberStalls: true,
  trendTries: true,
  trendForwardDuration: false,
  trendReverseDuration: false,
  showAbnormalTrendValues: false,
  grindTriesTrend: [],
  grindJackTrend: [],
  grindStallsTrend: [],
  grindDurationTrend: [],
  forwardDurationTrend: [],
  reverseDurationTrend: [],
  numberStallsTrend: [],
  cycleStartTimes: [],
  abnormalGrindDurationValues: {},
  abnormalStallsValues: {},
  abnormalJackValues: {},
  abnormalNumberStallsValues: {},
  abnormalTriesValues: {},
  abnormalForwardDurationValues: {},
  abnormalReverseDurationValues: {},
};

GrindCycleTrendsChart.propTypes = {
  hideAxisLabels: PropTypes.bool,
  grindTriesTrend: PropTypes.array,
  grindJackTrend: PropTypes.array,
  grindStallsTrend: PropTypes.array,
  grindDurationTrend: PropTypes.array,
  forwardDurationTrend: PropTypes.array,
  reverseDurationTrend: PropTypes.array,
  cycleStartTimes: PropTypes.array,
  numberStallsTrend: PropTypes.array,
  trendStalls: PropTypes.bool,
  trendJack: PropTypes.bool,
  trendNumberStalls: PropTypes.bool,
  trendTries: PropTypes.bool,
  trendForwardDuration: PropTypes.bool,
  trendGrindDuration: PropTypes.bool,
  showAbnormalTrendValues: PropTypes.bool,
  trendReverseDuration: PropTypes.bool,
  abnormalGrindDurationValues: PropTypes.object,
  abnormalStallsValues: PropTypes.object,
  abnormalJackValues: PropTypes.object,
  abnormalNumberStallsValues: PropTypes.object,
  abnormalTriesValues: PropTypes.object,
  abnormalForwardDurationValues: PropTypes.object,
  abnormalReverseDurationValues: PropTypes.object,
};

export default GrindCycleTrendsChart;
