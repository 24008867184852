import AuthService from "src/components/AuthService";

const Auth = new AuthService();

export const ValidateUniqueUsername = async (username) => {
    const valid = await Auth.fetch("/api/username-available/", {
        method: "POST",
        body: JSON.stringify({ username: username }),
    })
        .then((data) => {
            return data["available"] === 1;
        })
        .catch((err) => {
            return false;
        });

    return valid;
};

export const ValidateUniquePin = async (pin, userType, customerId) => {
    const valid = await Auth.fetch("/api/pin-available/", {
        method: "POST",
        body: JSON.stringify({
            pin: pin,
            user_type: userType,
            customer_id: customerId,
        }),
    }).then((data) => {
        return data["available"];
    });

    return valid;
};

export const ValidateEmail = (email) => {
    if (email) {
        return email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    } else {
        return true;
    }
};
