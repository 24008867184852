/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";

const DeviceOperatorDescText = props => {
  const usedPin = props.usedPin;
  return (
  <div className='desc customer'>
    <div className='desc-text'>
      {props.t('create-device-operator.1', 'Device Operators are the employees who will be using a Sterilis device.')}
    </div>
    <div className='desc-text'>
      {props.t('create-device-operator.2', 'Device Operators are responsible for inserting waste and beginning a cycle.')}
    </div>
    <div className='desc-text'>
      {props.t('create-device-operator.3', 'When the cycle is complete, they will remove the sterilized medical waste and prepare it for disposal.')}
    </div>
    <div
      className={props.pinValidationError || props.usedPinValidationError ? 'label-validation-error desc-text' : 'desc-text'}>
      {props.t('create-device-operator.4', 'A User PIN must be a unique set of 4 to 7 numeric characters.')}
    </div>
    {
      usedPin &&
      <span className={(props.setPINShake ? 'shake' : '') + ' label-validation-error desc-text desc-text'}>
          <Trans ns='translations' i18nKey='used_pin'>
            The pin {{usedPin}} has already been used.
          </Trans>
        </span>
    }
    <div className='desc-text'>
      {props.t('create-device-operator.5', 'If an operator is a Device Admin, they are able to conduct the mandatory Biological Challenges and PRV tests that are required to satisfy medical regulations.')}
    </div>
  </div>
  )
};

export default translate('translations')(DeviceOperatorDescText);
