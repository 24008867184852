import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prettyGroupName } from "src/components/library/helpers";
import AuthService from "src/components/AuthService";

import { useTranslation } from "react-i18next";
import {
    resetEditState,
    setOperatorCustomer,
    setPinMax,
    setPinMin,
    setLoading,
    setFailed,
    setSuccessText,
} from "src/features/User/Edit/editOperatorSlice";
import { ValidateUniquePin } from "src/services/ValidationService";
import FacilitySelection from "../Create/FacilitySelection";
import { useForm } from "react-hook-form";
import FormTextField from "src/components/material/Forms/FormTextField";
import { toast } from "react-toastify";
import { fetchFacilityAdmins } from "src/features/Customer/FacitlityAdmins/facilityAdminSlice";
import { fetchSiteAdmins } from "src/features/Customer/SiteAdmins/siteAdminSlice";
import { fetchDeviceOperators } from "src/features/Customer/Operators/operatorSlice";
import FormSelect from "src/components/material/Forms/FormSelect";

const Auth = new AuthService();

const EditOperatorModal = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translations");

    const {
        editOperator,
        editOperatorModalOpen,
        loading,
        pinMin,
        pinMax,
        operatorCustomer,
        successText,
        failed,
        // @ts-ignore
    } = useSelector((store) => store.editOperator);

    const firstName = editOperator?.portal_user?.first_name
        ? editOperator?.portal_user?.first_name
        : editOperator?.employee.full_name.split(" ")[0];

    const lastName = editOperator?.portal_user?.last_name
        ? editOperator?.portal_user?.last_name
        : editOperator?.employee.full_name.split(" ")[1];

    const defaultValues = {
        operator: {
            id: editOperator?.employee?.device_user[0]?.id || null,
            employee_id: editOperator?.employee.id || null,
            pin: editOperator?.pin || "",
            notify_complete: editOperator?.notify_complete || false,
            notify_error: editOperator?.notify_error || false,
            notifications: 0,
        },
        employee: {
            id: editOperator?.employee.id || null,
            full_name: editOperator?.employee.full_name || "",
            first_name: editOperator?.employee.first_name || firstName,
            last_name: editOperator?.employee.last_name || lastName,
            email_address: editOperator?.employee.email_address || "",
            phone: editOperator?.employee.phone || "",
            facility_ids: editOperator?.employee?.facilities.map((fac) => fac.id || fac) || [],
        },
    };

    const notificationDropdown = [
        {
            value: 0,
            id: "0",
            label: t("Do not notify"),
        },
        {
            value: 1,
            id: "1",
            label: t("Notify on Completion"),
        },
        {
            value: 2,
            id: "2",
            label: t("Notify on Error"),
        },
        {
            value: 3,
            id: "3",
            label: t("Notify on Completion and Error"),
        },
    ];

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        reset,
    } = useForm({
        defaultValues,
    });

    const notifySuccess = () =>
        toast(<div>{successText}</div>, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });

    const notifyFailure = () =>
        toast(<div>Failed to update user. Please refresh and try again.</div>, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    const [userType, setUserType] = React.useState("");
    const [showNotifications, setShowNotifications] = React.useState(false);

    const patchEmployee = async (updatedEmployee) => {
        return await Auth.fetch(`/api/employee/${updatedEmployee?.id}/`, {
            method: "PATCH",
            body: JSON.stringify(updatedEmployee),
        })
            .then((data) => {
                return data;
            })
            .catch((err) => {
                dispatch(setFailed(true));
                console.log(err);
            });
    };

    const patchOperator = async (updatedOperator) => {
        return await Auth.fetch(`/api/device-operator/${updatedOperator?.id}/`, {
            method: "PATCH",
            body: JSON.stringify(updatedOperator),
        })
            .then((data) => {
                return data;
            })
            .catch((err) => {
                dispatch(setFailed(true));
                console.log(err);
            });
    };

    const submitUpdates = async (data, dirtyFields) => {
        dispatch(setLoading(true));
        // Here we patch each component of a "User"

        const getDirtyValues = () => {
            let updated = {};
            for (const obj in dirtyFields) {
                updated[obj] = {};
                const thing = dirtyFields[obj];
                for (const field in thing) {
                    updated[obj][field] = data[obj][field];
                }
            }
            return updated;
        };

        const updates = getDirtyValues();

        const updatedEmployee = updates?.employee && {
            ...updates.employee,
            id: defaultValues.employee.id,
        };

        if (updatedEmployee?.first_name || updatedEmployee?.last_name) {
            updatedEmployee["full_name"] = `${updatedEmployee?.first_name || firstName} ${
                updatedEmployee?.last_name || lastName
            }`;
        }

        if (updatedEmployee) {
            await patchEmployee(updatedEmployee).then((employee) => console.log(employee));
        }

        const updatedOperator = updates?.operator && {
            ...updates.operator,
            id: defaultValues.operator.id,
            employee_id: defaultValues.employee.id,
        };

        const completionNotifications = [1, 3].includes(updatedOperator.notifications);
        const errorNotifications = [2, 3].includes(updatedOperator.notifications);
        delete updatedOperator.notifications;

        updatedOperator["notify_complete"] = completionNotifications;
        updatedOperator["notify_error"] = errorNotifications;

        if (updatedOperator) {
            await patchOperator(updatedOperator).then((operator) => console.log(operator));
        }

        if (!failed) {
            dispatch(setSuccessText(`Successfully updated ${updatedEmployee?.full_name}`));
        }

        reset();
        // Reset the form state
        dispatch(resetEditState());
        // Refresh the siteAdmins table

        if (userType === "facilityadmin") {
            // @ts-ignore
            dispatch(fetchFacilityAdmins());
            // @ts-ignore
            dispatch(fetchDeviceOperators());
        } else if (userType === "operator") {
            // @ts-ignore
            dispatch(fetchDeviceOperators());
        } else {
            // @ts-ignore
            dispatch(fetchSiteAdmins());
            // @ts-ignore
            dispatch(fetchDeviceOperators());
        }
    };

    const showFactilitySelection = () => {
        if (editOperator?.portal_user[0]?.groups) {
            return ["FactoryWorkers", "FacilityAdmin", "Operator"].includes(
                editOperator?.portal_user[0]?.groups[0].name
            );
        }
        if (userType === "operator") {
            return true;
        } else {
            return false;
        }
    };

    const getUserType = () => {
        const groupName = editOperator?.groups
            ? editOperator?.groups[0]?.name
            : editOperator.portal_user.length > 0
            ? editOperator.portal_user[0].groups[0]?.name
            : "Operator";

        switch (groupName) {
            case "FactoryWorkers":
            case "SterilisPortalUsers":
            case "SterilisSuperUsers":
            case "SterilisWasteTypeAdmin":
            case "ExternalFSEs":
            case "FSEs":
                return "sterilis";
            case "DistributorAdmins":
            case "DistributorFSEs":
            case "DistributorReadOnly":
                return "distributor";
            case "CustomerPortalUsers":
                return "customer";
            case "Operator":
                return "operator";
            case "FacilityAdmin":
                return "facilityadmin";
            default:
                console.log("hit default");
                break;
        }
    };

    useEffect(() => {
        if (editOperator) {
            dispatch(setOperatorCustomer(editOperator?.employee.customer));
            setUserType(getUserType());

            if (editOperator.employee.device_user[0]) {
                const operator = editOperator.employee.device_user[0];
                const notifyComplete = operator.notify_complete;
                const notifyError = operator.notify_error;
                if (notifyComplete && notifyError) {
                    defaultValues.operator.notifications = 3;
                } else if (notifyComplete) {
                    defaultValues.operator.notifications = 1;
                } else if (notifyError) {
                    defaultValues.operator.notifications = 2;
                }
            }
        }

        reset(defaultValues);
    }, [editOperator]);

    useEffect(() => {
        if (userType && userType !== "") {
            if (["sterilis", "distributor"].includes(userType)) {
                dispatch(setPinMin(8));
                dispatch(setPinMax(8));
            } else {
                dispatch(setPinMin(4));
                dispatch(setPinMax(7));
            }

            setShowNotifications(["customer", "operator", "facilityadmin"].includes(userType));
        }
    }, [userType, editOperator]);

    useEffect(() => {
        if (!failed && successText !== "") {
            notifySuccess();
            dispatch(resetEditState());
        }

        if (failed) {
            notifyFailure();
            dispatch(resetEditState());
        }
    }, [failed, successText]);

    return (
        <Dialog open={editOperatorModalOpen} onClose={() => dispatch(resetEditState())}>
            {loading ? (
                <DialogContent>
                    <CircularProgress size={80} color="primary" />
                </DialogContent>
            ) : (
                <>
                    <DialogTitle>
                        {t(
                            `Edit ${prettyGroupName(
                                editOperator?.groups
                                    ? editOperator?.groups[0]?.name
                                    : editOperator?.portal_user
                                    ? "Device Operator"
                                    : "Portal User"
                            )}`
                        )}
                    </DialogTitle>
                    <DialogContent>
                        <Box mt={1}>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit((d) => submitUpdates(d, dirtyFields))}>
                                <Grid container spacing={2}>
                                    {showFactilitySelection() ? (
                                        <Grid xs={12}>
                                            <FacilitySelection
                                                isEdit={true}
                                                control={control}
                                                errors={errors.employee?.facility_ids}
                                                rules={{ required: "At least one facility must be selected." }}
                                            />
                                        </Grid>
                                    ) : null}

                                    <Grid xs={12} sm={6}>
                                        <FormTextField
                                            control={control}
                                            required
                                            name="employee.first_name"
                                            label="First Name"
                                            rules={{}}
                                            autoComplete="given-name"
                                            errors={errors.employee?.first_name}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <FormTextField
                                            control={control}
                                            required
                                            name="employee.last_name"
                                            label="Last Name"
                                            rules={{}}
                                            autoComplete="family-name"
                                            errors={errors.employee?.last_name}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <FormTextField
                                            control={control}
                                            required
                                            id="pin"
                                            name="operator.pin"
                                            label="PIN"
                                            rules={{
                                                minLength: {
                                                    value: pinMin,
                                                    message: `PIN must be at least ${pinMin} digits.`,
                                                },
                                                maxLength: {
                                                    value: pinMax,
                                                    message: `PIN cannot be longer than ${pinMax} digits.`,
                                                },
                                                validate: {
                                                    validatePin: async (value) => {
                                                        if (value && value !== defaultValues.operator.pin) {
                                                            return (
                                                                (await ValidateUniquePin(
                                                                    value,
                                                                    userType,
                                                                    operatorCustomer?.id
                                                                )) || "PIN must be unique."
                                                            );
                                                        } else {
                                                            return true;
                                                        }
                                                    },
                                                },
                                            }}
                                            errors={errors.operator?.pin}
                                        />
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormTextField
                                            control={control}
                                            required
                                            id="email"
                                            name="employee.email_address"
                                            label="Email Address"
                                            rules={{
                                                pattern: {
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Must be a valid email address.",
                                                },
                                            }}
                                            errors={errors.employee?.email_address}
                                            autoComplete="email"
                                        />
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormTextField
                                            control={control}
                                            required
                                            id="phoneNumber"
                                            name="employee.phone"
                                            label="Phone Number"
                                            errors={errors.employee?.phone}
                                            autoComplete="phone"
                                            rules={{}}
                                        />
                                    </Grid>
                                    {showNotifications && (
                                        <Grid xs={12}>
                                            <FormSelect
                                                control={control}
                                                name="operator.notifications"
                                                fullWidth
                                                rules={{ required: false }}
                                                label="Notifications"
                                                required
                                                errors={errors?.operator?.notifications}
                                                options={notificationDropdown}
                                            />
                                        </Grid>
                                    )}

                                    <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => dispatch(resetEditState())}>
                                            Cancel
                                        </Button>
                                        <Button sx={{ ml: 2 }} variant="contained" type="submit">
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default EditOperatorModal;
