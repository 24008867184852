/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import {alphabeticalSort, AuthReq, composeHoc} from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import {Button, Card, Checkbox, Dropdown} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import * as Sentry from "@sentry/browser";
import {toast} from "react-toastify";
import get from "lodash/get";
import {Link} from "react-router-dom";
import moment from "moment";
import DistributorDropdownFilter from "../../view/distributors/DistributorDropdownFilter";

const Auth = new AuthService();

class AssignDeviceToFacility extends React.Component {
	state = {
		isLoading: false,
		selectedFacilityID: null,
		selectedDeviceConfigID: null,
		selectedDeviceSerial: null,
		deviceDropdown: [],
		facilityDropdown: [],
		selectedFacility: null,
		selectedDeviceConfig: null,
		activeCycleError: false,
		distributorFilter: 0,
		distributorDropdownArray: [
			{
				key: 0,
				value: 0,
				text: this.props.t("All Distributors"),
			},
		],
	};
	notifySuccess = (deviceSerial, newFacility) =>
		toast(`Successfully relocated ${deviceSerial} to new facility ${newFacility}.`, {
			type: toast.TYPE.DEFAULT,
			autoClose: 15000,
		});
	notifyFailure = () =>
		toast(`Failed to complete operation. Please refresh this page and try again.`, {
			type: toast.TYPE.ERROR,
			autoClose: 5000,
		});
	notifyActiveCycle = (cycleStartTime) =>
		toast(
			`The device cannot be re-assigned because there is an active cycle. The cycle began on ${cycleStartTime}`,
			{
				type: toast.TYPE.ERROR,
				autoClose: 5000,
			}
		);

	componentDidMount() {
		document.title = "Assign Device To Facility";
		this.setState({ isLoading: true });
		this.preparePage().then(() => {
			this.setState({ isLoading: false });
		});
	}

	preparePage = async () => {
		await this.fetchFacilities();
		await this.fetchDeviceConfigs();
	};

	fetchFacilities = () => {
		return Auth.fetch(`/api/facility/`)
			.then((data) => {
				// take shallow copy of array in state to begin building distributor dropdown array
				const distributorDropdownArray = [...this.state.distributorDropdownArray];
				const facilityDropdown = data
					.filter((facility) => {
						//filter out banned and deleted customers
						return (
							facility["customer"]["banned"] === false &&
							facility["customer"]["deleted"] === null &&
							facility["banned"] === false &&
							//this shouldn't be necessary since /api/facility/ should use .objects.
							// (aka get all non deleted items), but it can't hurt
							facility["deleted"] === null
						);
					})
					.map((facility) => {
						// Create an array of unique distributors
						if (
							distributorDropdownArray.some((elem) => {
								return facility["customer"]["distributor"]["id"] === elem["value"];
							}) === false
						) {
							distributorDropdownArray.push({
								key: facility["customer"]["distributor"]["id"],
								value: facility["customer"]["distributor"]["id"],
								text: facility["customer"]["distributor"]["distributor_name"],
							});
						}
						return {
							key: facility["id"],
							value: facility["id"],
							facility: facility,
							text: `${facility["customer"]["customer_name"]} - ${facility["facility_name"]}`,
							distributor_id: facility["customer"]["distributor"]["id"],
						};
					});

				if (queryString.parse(this.props.location.search).facility) {
					const facilityID = parseInt(queryString.parse(this.props.location.search).facility, 10);
					// make sure facilityID is a valid ID inside of facilityDropdown, aka it didn't get .filtered() out
					if (facilityDropdown.find((facilityDropdownItem) => facilityDropdownItem["value"] === facilityID)) {
						this.setState({
							selectedFacilityID: facilityID,
						});
					}
				}

				facilityDropdown.sort((a, b) => alphabeticalSort(a, b, "text"));
				this.setState({
					facilityDropdown,
					distributorDropdownArray,
				});
			})
			.catch((err) => {
				this.notifyFailure();
				Sentry.captureException(err);
			});
	};

	deviceDropdownClass = (deviceConfig) => {
		if (deviceConfig["device"]["deleted"]) {
			return "light-grey-tr";
		} else if (deviceConfig["device"]["banned"]) {
			return "light-red-tr";
		} else if (deviceConfig["device"]["activated"] === false) {
			return "light-yellow-tr";
		}
	};

	fetchDeviceConfigs = () => {
		return Auth.fetch(`/api/export/device-configs/`)
			.then((data) => {
				const deviceDropdown = data.map((deviceConfig) => {
					return {
						key: deviceConfig["id"],
						value: deviceConfig["id"],
						device_config: deviceConfig,
						className: this.deviceDropdownClass(deviceConfig),
						distributor_id: deviceConfig["distributor_id"],
						customer_and_facility_names: `${deviceConfig["customer"]} ${deviceConfig["facility"]}`,
						text: `${deviceConfig["serial_number"]} | Current: ${deviceConfig["customer"]} - ${deviceConfig["facility"]}`,
					};
				});

				if (queryString.parse(this.props.location.search).device_config) {
					const deviceConfigID = queryString.parse(this.props.location.search).device_config;
					this.setState({
						selectedDeviceConfigID: parseInt(deviceConfigID, 10),
					});
				}
				deviceDropdown.sort((a, b) => alphabeticalSort(a, b, "customer_and_facility_names"));
				this.setState({
					deviceDropdown,
				});
			})
			.catch((err) => {
				this.notifyFailure();
				Sentry.captureException(err);
			});
	};

	handleFacilityChange = (event, data) => {
		// good example of getting a custom field from semantic ui dropdown options
		const facility = data["options"].filter((option) => {
			return option["value"] === data.value;
		})[0]["facility"];

		this.setState(
			{
				selectedFacilityID: data.value,
				selectedFacility: facility,
			},
			() => this.checkFacilityAndDeviceSettings()
		);
	};

	handleDeviceChange = (event, data) => {
		const deviceConfig = data["options"].filter((option) => {
			return option["value"] === data.value;
		})[0]["device_config"];

		this.setState(
			{
				selectedDeviceConfigID: data.value,
				selectedDeviceConfig: deviceConfig,
				activeCycleError: false,
				selectedDeviceSerial: deviceConfig["device"]["serial_number"],
			},
			() => this.checkFacilityAndDeviceSettings()
		);
	};

	checkFacilityAndDeviceSettings = () => {
		const { selectedDeviceConfig, selectedFacility } = this.state;
		if (selectedFacility && selectedDeviceConfig) {
			// Machines with Gauge sensors cannot be assigned to facilities with a maximum elevation of 1,640.
			// When pv_type = 0, it is a Gauge machine
			this.setState({
				configError: selectedDeviceConfig["pv_type"] === "0" && selectedFacility["elevation"] > 1669,
			});
		}
	};

	submit = () => {
		const { selectedFacilityID, selectedDeviceConfigID } = this.state;

		this.setState({ isLoading: true });
		Auth.fetch(`/api/assign-device-to-facility/`, {
			method: "POST",
			body: JSON.stringify({
				facility_id: selectedFacilityID,
				device_config_id: selectedDeviceConfigID,
			}),
		})
			.then((data) => {
				this.setState(
					{
						isLoading: false,
					},
					() => {
						this.notifySuccess(data["device__serial_number"], data["facility__facility_name"]);
						this.props.history.push("/view/devices");
					}
				);
			})
			.catch((err) => {
				if (err.response) {
					// Good example of how to get information from the server during an error
					err.response.json().then((resp) => {
						if (resp["msg"] === "cycle_active") {
							const activeCycleTimeStarted = moment
								.utc(resp["cycle_time_started"])
								.format("YYYY-MM-DD HH:mm:ss z");
							this.notifyActiveCycle(activeCycleTimeStarted);
							this.setState({
								activeCycleError: true,
								activeCycleTimeStarted,
							});
						} else {
							// Don't alert sentry on cycle_active error - not really a real server error
							Sentry.captureException(err);
							this.notifyFailure();
						}
					});
				} else {
					Sentry.captureException(err);
					this.setState({ isLoading: false });
				}
			});
	};

	handleDistributorForFacilityFilterChange = (event, data) => {
		this.setState({
			distributorFilterForFacility: data.value,
			selectedFacilityID: null,
		});
	};

	handleDistributorForDeviceFilterChange = (event, data) => {
		this.setState({
			distributorFilterForDevice: data.value,
			selectedDeviceConfigID: null,
		});
	};

	toggleShowDistributorDeviceFilter = () => {
		this.setState((prevState) => {
			return {
				showDistributorDeviceFilter: !prevState.showDistributorDeviceFilter,
				distributorFilterForDevice: prevState.showDistributorDeviceFilter ? 0 : prevState.distributorFilterForDevice
			};
		});
	};

	toggleShowDistributorFacilityFilter = () => {
		this.setState((prevState) => {
			return {
				showDistributorFacilityFilter: !prevState.showDistributorFacilityFilter,
				distributorFilterForFacility: prevState.showDistributorFacilityFilter ? 0 : prevState.distributorFilterForFacility
			};
		});
	};

	render() {
		const { t, group } = this.props;
		const {
			isLoading,
			deviceDropdown,
			facilityDropdown,
			configError,
			selectedFacility,
			selectedDeviceConfig,
			activeCycleError,
			activeCycleTimeStarted,
			distributorFilterForDevice,
			distributorFilterForFacility,
			distributorDropdownArray,
			showDistributorDeviceFilter,
			showDistributorFacilityFilter,
		} = this.state;

		const facilityName = get(selectedFacility, "facility_name", "facility");
		const deviceSerial = get(selectedDeviceConfig, "serial_number", "device");
		const deviceConfigID = get(selectedDeviceConfig, "id", "");
		const facilityElevation = get(selectedFacility, "elevation", "");

		let filteredDevices = deviceDropdown;
		let filteredFacilities = facilityDropdown;

		if (distributorFilterForDevice) {
			filteredDevices = filteredDevices.filter((row) => {
				return row["distributor_id"] === distributorFilterForDevice;
			});
		}

		if (distributorFilterForFacility) {
			filteredFacilities = filteredFacilities.filter((row) => {
				return row["distributor_id"] === distributorFilterForFacility;
			});
		}

		return (
			<Card fluid>
				{isLoading ? <SimpleLoader /> : null}
				<Card.Header style={{ margin: "10px" }}>
					<h2>{t("assign-device-to-facility.header", "Assign Device To Facility")}</h2>
				</Card.Header>
				<Card.Content className="card-body">
					<div className="split-container even-split">
						<div className="wide-desc">
							<p>
								{t(
									"assign-device-to-facility.1",
									"Use the first dropdown to select a device you wish to assign to a different facility."
								)}
							</p>
							<p>
								{t(
									"assign-device-to-facility.2",
									"Use the second dropdown to select a facility you wish to assign the device to."
								)}
							</p>
							<br />
							<p>
								{t(
									"assign-device-to-facility.3",
									"Please be aware that some facilities have different biological challenge requirements than others."
								)}
							</p>
							<p>
								{t(
									"assign-device-to-facility.4",
									"A biological challenge may be required after facility assignment."
								)}
							</p>
							{configError ? (
								<div>
									<p className="red-text">
										<Trans ns="translations" i18nKey="assign-device-to-facility.gauge_warning">
											Devices with a <strong>PV Type</strong> of <strong>Gauge</strong> cannot be
											assigned to facilities with elevations greater than 1,670 ft.
										</Trans>
									</p>
									<p>
										<Trans ns="translations" i18nKey="assign-device-to-facility.elevation_notice">
											{{ facilityName }} has an elevation of {{ facilityElevation }} ft.
										</Trans>
									</p>
									<Link to={`/find-a-machine/${deviceConfigID}`}>
										<Trans ns="translations" i18nKey="assign-device-to-facility.device_info_link">
											Find a Machine page for {{ deviceSerial }}
										</Trans>
									</Link>
								</div>
							) : null}
							{activeCycleError ? (
								<div>
									<p className="red-text">
										<Trans ns="translations" i18nKey="assign-device-to-facility.active_cycle">
											The device cannot be re-assigned because there is an active cycle. The cycle
											began on {{ activeCycleTimeStarted }}
										</Trans>
									</p>
								</div>
							) : null}
						</div>
						<div className="wide-no-flex-items">
							<div className="form-group">
								<div className="flex-dir-row even-split">
									<label className={`${showDistributorDeviceFilter ? "flex-dir-col-rev" : ""}`}>
										<h4 className="orange-text device-filter-header">{t("Select device")}</h4>
									</label>
									<AuthReq
										userGroup={group}
										requiredRoles={[
											"SterilisSuperUsers",
											"SterilisPortalUsers",
											"FSEs",
											"ExternalFSEs",
											"FactoryWorkers",
											"SterilisWasteTypeAdmin",
										]}
									>
										<div>
											<Checkbox
												label={{
													children: t("Filter by distributor"),
												}}
												name="filterDevicesByDistributor"
												id="filterDevicesByDistributor"
												className="slight-margin-bottom"
												checked={showDistributorDeviceFilter}
												onChange={this.toggleShowDistributorDeviceFilter}
											/>
											{showDistributorDeviceFilter && (
												<DistributorDropdownFilter
													group={group}
													onChange={this.handleDistributorForDeviceFilterChange}
													selectedDistributor={distributorFilterForDevice}
													distributorDropdownArray={distributorDropdownArray}
													dropdownClass="wide-dropdown"
													containerClass={`form-group ${
														showDistributorDeviceFilter ? "" : "heavy-left-margin"
													}`}
													filterLabel="Filter devices by distributor"
													labelTextColor="ster-blue"
												/>
											)}
										</div>
									</AuthReq>
								</div>
								<Dropdown
									className="wide-dropdown"
									search
									selection
									onChange={this.handleDeviceChange}
									fluid
									value={this.state.selectedDeviceConfigID}
									id="deviceDropdown"
									options={filteredDevices}
								/>
							</div>
							<div className="form-group">
								<div className="flex-dir-row even-split">
									<label className={`${showDistributorFacilityFilter ? "flex-dir-col-rev" : ""}`}>
										<h4 className="orange-text device-filter-header">{t("Select facility")}</h4>
									</label>
									<AuthReq
										userGroup={group}
										requiredRoles={[
											"SterilisSuperUsers",
											"SterilisPortalUsers",
											"FSEs",
											"ExternalFSEs",
											"FactoryWorkers",
											"SterilisWasteTypeAdmin",
										]}
									>
										<div>
											<Checkbox
												label={{
													children: t("Filter by distributor"),
												}}
												name="filterFacilitiesByDistributor"
												id="filterFacilitiesByDistributor"
												className="slight-margin-bottom"
												checked={showDistributorFacilityFilter}
												onChange={this.toggleShowDistributorFacilityFilter}
											/>
											{showDistributorFacilityFilter && (
												<DistributorDropdownFilter
													group={group}
													onChange={this.handleDistributorForFacilityFilterChange}
													selectedDistributor={distributorFilterForFacility}
													distributorDropdownArray={distributorDropdownArray}
													dropdownClass="wide-dropdown"
													containerClass={`form-group ${
														showDistributorFacilityFilter ? "" : "heavy-left-margin"
													}`}
													filterLabel="Filter facilities by distributor"
													labelTextColor="ster-blue"
												/>
											)}
										</div>
									</AuthReq>
								</div>
								<Dropdown
									className="wide-dropdown"
									search
									selection
									onChange={this.handleFacilityChange}
									fluid
									value={this.state.selectedFacilityID}
									id="facilityDropdown"
									options={filteredFacilities}
								/>
							</div>
							<Button
								className="ster-btn float-right"
								value="Submit"
								type="submit"
								disabled={
									!this.state.selectedFacilityID ||
									!this.state.selectedDeviceConfigID ||
									this.state.configError
								}
								onClick={this.submit}
							>
								<Trans ns="translations" i18nKey="assign-device-to-facility.assign_btn">
									Assign {{ deviceSerial }} to {{ facilityName }}
								</Trans>
							</Button>
						</div>
					</div>
				</Card.Content>
			</Card>
		);
	}
}

export default composeHoc(withRouter, translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FactoryWorkers',
    'DistributorAdmins','SterilisWasteTypeAdmin'], 'internalPage'))(AssignDeviceToFacility);
