/*
*  Component that allows users to change their own username, email, name, and (sterilis only) PIN
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import SimpleLoader from "../SimpleLoader";
import AuthService from "../AuthService";
import {Button, Dropdown} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import debounce from "lodash/debounce";
import {toast} from "react-toastify";

const Auth = new AuthService();

class EditUserInfo extends React.Component {
  state = {
    isLoading: false,
    fullName: '',
    emailAddr: '',
    phoneNumber: '',
    username: '',
    userPIN: '',
    notificationLevel: 0,
  };

  notifySuccess = () => toast(`Successfully edited account details.`, {type: toast.TYPE.DEFAULT, autoClose: 5000});
  notifyFetchFailure = () => toast(`Failed to fetch data. Please refresh and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });
  notifyUsernameSuccess = () => toast(`Successfully edited account details along with username. Please log back in with your new username.`,
    {
      type: toast.TYPE.DEFAULT,
      autoClose: 5000,
      onClose: () => {
        window.location.href = '/login';
      }
    });
  notifyFailure = (err) => toast(`Failed to edit account details. Reason: ${err}`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  handleNotificationChange = (event, data) => {
    this.setState({
      notificationLevel: data.value
    });
  };

  componentDidMount() {
    this.preparePage();
    document.title = 'Edit Account Information';
  }

  preparePage = () => {
    this.setState({
      isLoading: true
    }, () =>
      Promise.all([
        this.getEmployee(),
        this.getDeviceOperator(),
      ]).then(() => {
        // we need the user's PIN in this call, so we'll do it after the getDeviceOperator call is finished
        this.getPins().then(() => {
          this.setState({
            isLoading: false
          })
        });
      }));
  };

  getPins = () => {
    const {
      userPIN,
      customerID,
    } = this.state;
    // if it's a factory person, get their PIN from customer 1. otherwise get customer 3
    return Auth.fetch(`/api/device-operator?customer_id=${customerID}`, {
      method: 'GET',
    }).then(data => {
      const pinSet = new Set();
      data.forEach(deviceOperator => {
        // don't add the user's pin, so when they're not changing their pin / they change it and change it right back
        // they'll be able to submit the form
        if (userPIN !== deviceOperator['pin']) {
          pinSet.add(deviceOperator['pin'])
        }
      });
      this.setState({
        pinSet
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.notifyFetchFailure();
    });
  };


  getEmployee = () => {
    const {
      user
    } = this.props;
    return Auth.fetch(`/api/portal-user/${user['user_id']}`)
      .then(data => {
        this.setState({
          fullName: data['employee']['full_name'],
          originalFullName: data['employee']['full_name'],
          emailAddr: data['employee']['email_address'],
          originalEmailAddr: data['employee']['email_address'],
          phoneNumber: data['employee']['phone'],
          originalPhoneNumber: data['employee']['phone'],
          username: data['username'],
          originalUsername: data['username'],
          customerID: data['employee']['customer']['id']
        })
      }).catch(err => {
        Sentry.captureException(err)
      });
  };

  getDeviceOperator = () => {
    const {
      user,
    } = this.props;
    return Auth.fetch(`/api/device-operator/?employee_id=${user['employee_id']}`)
      .then(data => {
        const userOperator = data[0];
        const notify_complete = userOperator['notify_complete'];
        const notify_error = userOperator['notify_error'];
        let notificationLevel = 0;
        if (notify_complete && notify_error) {
          notificationLevel = 3;
        } else if (notify_complete) {
          notificationLevel = 1;
        } else if (notify_error) {
          notificationLevel = 2;
        } else {
          notificationLevel = 0;
        }
        this.setState({
          userPIN: userOperator['pin'],
          originalUserPIN: userOperator['pin'],
          deviceOperatorID: userOperator['id'],
          originalNotificationLevel: notificationLevel,
          notificationLevel
        })


      }).catch(err => {
        Sentry.captureException(err)
      });
  };

  debouncedCheckIfUsernameTaken = debounce((username) => this.checkIfUsernameTaken(username), 350);

  shakeUsernameSpan = () => {
    this.setState({
      setUsernameShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setUsernameShake: false,
        })
      }, 1000)
    })
  };

  checkIfUsernameTaken = (username) => {
    const {
      originalUsername
    } = this.state;
    if (username !== originalUsername) {
      // If the user is submitting a duplicate username really quickly and they try to submit the
      // form before the check username call finishes, it'll crash because the username will not be unique
      // We'll just disable the form submit through checkingUsername to prevent this bad UX
      this.setState({
        checkingUsername: true
      });
      Auth.fetch(`/api/username-available/`, {
        method: 'POST',
        body: JSON.stringify({
          'username': username
        })
      }).then(data => {
        if (data['available'] === 1) {
          this.setState({
            usernameValidationError: false,
            checkingUsername: false,
          })
        } else {
          this.shakeUsernameSpan();
          this.setState({
            usernameValidationError: true,
            checkingUsername: false,
          })
        }
      }).catch(err => {
        Sentry.captureException(err);
        this.setState({
          checkingUsername: false,
        })
      });
    }
  };


  shakePINSpan = () => {
    this.setState({
      setPINShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setPINShake: false,
        })
      }, 1000)
    })
  };

  checkIfPinUsed = (pinSet, value) => {
    // pinArr is a JavaScript Set, which does not have the Array find function
    //we need to [...spread] pinArr into an array, so we can use the find function
    const usedPin = [...pinSet].find((pins) => {
      return pins === value;
    });
    if (usedPin) {
      this.shakePINSpan();
      this.setState({
        usedPin: usedPin,
        usedPinValidationError: true
      })
    } else {
      this.setState({
        usedPin: null,
        usedPinValidationError: false
      })
    }
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (name === 'username') {
      if (/^[a-zA-Z0-9_-]*$/i.test(value)) {
        this.debouncedCheckIfUsernameTaken(value);
      } else {
        this.shakeUsernameSpan();
        this.setState({
          usernameValidationError: true
        })
      }
    }

    if (name === 'userPIN') {
      const pinSet = this.state.pinSet;
      this.checkIfPinUsed(pinSet, value);

      if (value.length !== 8) {
        this.setState({
          pinValidationError: true
        })
      } else {
        //We only want to un-validate the pinvalidation state if we know there is no usedPin validation error going on
        if (!this.state.usedPin) {
          this.setState({
            pinValidationError: false
          })
        }
      }

      // if the value is not not a number, it is a number
      // goofy, but it's the only 100% way to check if something is a number in js
      // pins must only be numbers
      // need to use this over type="number" because of floating labels & controlled inputs
      if (!isNaN(value)) {
        this.setState({[name]: value});
      }

    } else {
      this.setState({[name]: value});
    }
  };

  submitUserInfo = e => {
    e.preventDefault();
    const {
      fullName,
      emailAddr,
      phoneNumber,
      userPIN,
      username,
      originalFullName,
      originalEmailAddr,
      originalPhoneNumber,
      originalUsername,
      originalUserPIN,
      originalNotificationLevel,
      notificationLevel
    } = this.state;


    const updateEmployee = ((fullName !== originalFullName) ||
      (emailAddr !== originalEmailAddr) ||
      (phoneNumber !== originalPhoneNumber)
    );
    const updatePortalUser = username !== originalUsername;
    const updateDeviceOperator = ((userPIN !== originalUserPIN) ||
      (notificationLevel !== originalNotificationLevel));


    this.setState({
      isLoading: true
    });
    Promise.all([
      updateEmployee && this.updateEmployee(),
      updatePortalUser && this.updatePortalUser(),
      updateDeviceOperator && this.updateDeviceOperator(),
    ]).then(() => {
      if (updatePortalUser) {
        this.notifyUsernameSuccess();
        Auth.logout();
      } else {
        this.notifySuccess();
        this.preparePage();
      }
    }).catch(err => {
      this.notifyFailure(err);
      Sentry.captureException(err);
      this.setState({
        isLoading: false
      })
    });

  };

  updateEmployee = () => {
    const {
      fullName,
      emailAddr,
      phoneNumber,
    } = this.state;

    const employeeID = this.props.user['employee_id'];

    const putObjEmployee = {
      'full_name': fullName,
      'email_address': emailAddr,
      'phone': phoneNumber
    };

    return Auth.fetch(`/api/employee/${employeeID}/`, {
      method: 'PATCH',
      body: JSON.stringify(putObjEmployee)
    }).catch(err => {
      Sentry.captureException(err);
    });

  };

  updatePortalUser = () => {
    const {
      username
    } = this.state;

    const putObjPortalUser = {
      'username': username,
    };

    const portalUserID = this.props.user['user_id'];

    return Auth.fetch(`/api/portal-user/${portalUserID}/`, {
      method: 'PATCH',
      body: JSON.stringify(putObjPortalUser)
    }).catch(err => {
      Sentry.captureException(err)
    });
  };

  updateDeviceOperator = () => {
    const {
      userPIN,
      deviceOperatorID,
      notificationLevel
    } = this.state;

    let notifyComplete = false;
    let notifyError = false;
    switch (notificationLevel) {
      case 0:
        notifyComplete = false;
        notifyError = false;
        break;
      case 1:
        notifyComplete = true;
        notifyError = false;
        break;
      case 2:
        notifyComplete = false;
        notifyError = true;
        break;
      case 3:
        notifyComplete = true;
        notifyError = true;
        break;
      default:
        notifyComplete = false;
        notifyError = false;
        break;
    }

    const deviceOperator = {
      pin: userPIN,
      notify_complete: notifyComplete,
      notify_error: notifyError
    };
    return Auth.fetch(`/api/device-operator/${deviceOperatorID}`, {
      method: 'PATCH',
      body: JSON.stringify(deviceOperator)
    }).catch(err => {
      Sentry.captureException(err);
    })
  };

  render() {
    const {
      isLoading,
      pinValidationError,
      usedPinValidationError,
      usernameValidationError,
      checkingUsername,
      usedPin,
      setPINShake,
      setUsernameShake,
      notificationLevel
    } = this.state;
    const {
      t,
    } = this.props;
    const notificationDropdown = [
      {
        key: 0,
        value: 0,
        id: '0',
        text: t('Do not notify')
      },
      {
        key: 1,
        value: 1,
        id: '1',
        text: t('Notify on Completion')
      },
      {
        key: 2,
        value: 2,
        id: '2',
        text: t('Notify on Error')
      },
      {
        key: 3,
        value: 3,
        id: '3',
        text: t('Notify on Completion and Error')
      },

    ];
    return (
      <form id="editUserInfoForm" onSubmit={this.submitUserInfo}>
        {isLoading ? (
          <SimpleLoader/>
        ) : null}

        <div className='medium-font flex-dir-column'>
          <React.Fragment>
            <div className='desc-text'>
              {t('create-user.3', 'All Portal Users will be able to log onto any device with their PIN.')}
            </div>
            <span className={pinValidationError || usedPinValidationError ?
              'label-validation-error desc-text' : 'desc-text'}>
                {t('create-user.4', 'PINs must be eight (8) digits long.')}
              </span>
            {
              usedPin &&
              <div className={(setPINShake ? 'shake' : '') + ' label-validation-error desc-text desc-text'}>
                {t('The pin')} {usedPin} {t('has already been used.')}
              </div>
            }
          </React.Fragment>
          <span className={
            (usernameValidationError ? 'label-validation-error desc-text' : 'desc-text') + (setUsernameShake ? ' shake' : '')}>
            {t('create-site-admin.7', 'Usernames must be unique.')}
          </span>
        </div>

        <div className='slight-margin-top'>
          <div className="form-group">
            <input value={this.state.fullName}
                   name='fullName'
                   onChange={this.handleChange}
                   type="text"
                   id="fullNameInput"
                   className="form-control"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="fullNameInput">{t('Full Name')}</label>
          </div>
          <div className="form-group">
            <input value={this.state.username}
                   name='username'
              // disabled={checkingUsername}
                   onChange={this.handleChange}
                   type="text"
                   id="usernameInput"
                   className="form-control"
                   pattern="^[a-zA-Z0-9_-]*$"
                   title="Usernames must be alphanumeric. - and _ are allowed"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="usernameInput">{t('Username')}</label>
          </div>
          <div className="form-group">
            <input value={this.state.userPIN}
                   name='userPIN'
                   onChange={this.handleChange}
                   type="text"
                   id="userPINInput"
                   className="form-control"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="userPINInput">{t('User PIN')}</label>
          </div>
          <div className="form-group">
            <input value={this.state.emailAddr}
                   name='emailAddr'
                   onChange={this.handleChange}
                   type="text"
                   id="emailAddressInput"
                   className="form-control"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="emailAddressInput">{t('Email Address')}</label>
          </div>
          <div className="form-group">
            <input value={this.state.phoneNumber}
                   name='phoneNumber'
                   onChange={this.handleChange}
                   type="text"
                   id="phoneNumberInput"
                   className="form-control"
                   required/>
            <label className="form-control-placeholder"
                   htmlFor="phoneNumberInput">{t('Phone Number')}</label>
          </div>
          <div className="form-group">
            <label className="fake-float">{t('Notification Settings')}</label>
            <Dropdown
              className='full-width'
              search
              selection
              id='notificationSettingsDropdown'
              options={notificationDropdown}
              value={notificationLevel}
              onChange={this.handleNotificationChange}
            />
          </div>
        </div>
        <Button
          id='editUserInfoBtn'
          className='submit-machine'
          disabled={pinValidationError || usedPinValidationError || usernameValidationError || checkingUsername}
          form='editUserInfoForm'
          value="Submit"
          type="submit"
          primary>
          {t('Submit changes')}
        </Button>
      </form>
    )
  }
}

export default translate('translations')(EditUserInfo);
