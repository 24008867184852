/*
*  Part of the View Customers page, this is the facility table inside the Customer table
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import { isGroupAdminOrDistFse } from "../../library/helpers";
import HookTable from "../../library/HookTable";
import expandColumn from "../../library/ExpandColumn";

const FacilityTable = (props) => {
    const customRowClass = (rowInfo) => {
        if (rowInfo.original) {
            if (rowInfo.original.deleted_status) {
                return "light-grey-tr";
            } else if (rowInfo.original.banned_status) {
                return "light-red-tr";
            }
        }
    };
    const { t, group } = props;
    const columns = [
        expandColumn(t("Number of Devices")),
        {
            Header: t("Facility name"),
            accessor: "facility_name",
        },
        {
            Header: t("Address"),
            accessor: "facility_address",
        },
        {
            Header: t("Regulatory Market"),
            accessor: "facility_type",
        },
        {
            Header: t("Biochallenge type"),
            accessor: "facility_biochallenge",
        },
        {
            Header: t("Cook parameters"),
            accessor: "facility_cook_params",
        },

        {
            Header: t("Timezone"),
            accessor: "facility_timezone_display_label",
            // manualWidth: 150
        },
        {
            Header: t("Elevation"),
            accessor: "facility_elevation",
            // manualWidth: 80
        },
        ...(isGroupAdminOrDistFse(group)
            ? [
                  {
                      Header: t("Actions"),
                      accessor: "actions",
                      className: "actions-column",
                  },
              ]
            : []),
    ];
    return (
        <HookTable
            tableName="facilityTable"
            innerTable={true}
            pageSize={props.data.length}
            columns={columns}
            data={props.data}
            subComponentJSX={"simpleDeviceTable"}
            customRowClass={true}
            customRowClassFunc={customRowClass}
            expandableTable={true}
            renderEmptyRow={false}
            // only show pagination if the data length is greater than the default pagination length
            showPagination={props.data.length > 10}
            showSearch={false}
            group={props.group}
            defaultSortBy={{ id: "facility_name" }}
        />
    );
};

export default translate('translations')(FacilityTable);
