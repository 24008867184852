/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {
    calcSingleChartHeight,
    calcTitleMargin,
    chartTooltip,
    defaultPlotOptions,
    dutyCycleConstants,
    dutyCycleYAxisLabel,
    getDutyCycleSeries,
    zoomButtonOptions,
} from "../CycleStats/ChartHelpers";
import { useZoomParams } from "src/components/library/hooks";

import moment from "moment";

const GrindCycle = (props) => {
    const titleMargin = calcTitleMargin(props.plotBands.length);
    const singleChartHeight = calcSingleChartHeight(props.plotBands.length);

    const dutyCycleYAxis = {
        ...dutyCycleYAxisLabel,
        plotLines: props.dutyCycleValuesOverThreshHold ? dutyCycleConstants : false,
    };

    const getGrindSeries = (
        paList,
        dutyCycleList,
        plotDutyCycle,
        overlayedCycleNumber = 0,
        isOverlayedCycle = false,
        cycleStartTime = ""
    ) => {
        return [
            {
                name: "Pa",
                data: paList,
                unit: "PSI(g)",
                color: "lightseagreen",
                overlayedCycleNumber,
                isOverlayedCycle,
                cycleStartTime,
            },
            ...(plotDutyCycle
                ? getDutyCycleSeries(dutyCycleList, overlayedCycleNumber, isOverlayedCycle, cycleStartTime)
                : []),
        ];
    };

    const grindSeries = React.useMemo(() => {
        const selectedCycle = getGrindSeries(props.paList, props.dutyCycleList, props.plotDutyCycle);
        if (props.overlayedCycles.length > 0) {
            const overlayedSeries = props.overlayedCycles.flatMap((overlayedCycle, idx) => {
                const counter = idx + 1;
                const cycleStartTime = moment.utc(overlayedCycle["raw_start_time"]).format("MM-DD HH:mm:ss z");
                return getGrindSeries(
                    overlayedCycle.pa_list,
                    overlayedCycle.duty_cycle_list,
                    props.plotDutyCycle,
                    counter,
                    true,
                    cycleStartTime
                );
            });
            return [...selectedCycle, ...overlayedSeries];
        }
        return selectedCycle;
    }, [props.paList, props.dutyCycleList, props.overlayedCycles, props.showAbnormalSeriesValues, props.plotDutyCycle]);

    const chartLegend = {
        width: props.plotDutyCycle ? 200 : 100,
        itemWidth: 100,
    };

    const tooltip = function () {
        return chartTooltip(this, props.cycleStartTime, props.plotBands);
    };

    const [chartOptions, setChartOptions] = useState({
        chart: {
            zoomType: "x",
            height: singleChartHeight,
            resetZoomButton: zoomButtonOptions,
        },
        plotOptions: defaultPlotOptions,
        tooltip: {
            shared: true,
            useHTML: true,
            animation: false,
            formatter: tooltip,
        },
        legend: chartLegend,
        title: {
            text: `${props.customTitlePrefix && props.customTitlePrefix} Pa PSI(g)`,
        },
        xAxis: [
            {
                type: "datetime",
                crosshair: true,
                plotBands: props.plotBands,
                plotLines: props.overlayInfo && props.infoConstants,
                events: {
                    afterSetExtremes: function (event) {
                        if (event.trigger === "zoom" && props.setZoomParams) {
                            props.setZoomParams({ min: event.min, max: event.max });
                        }
                    },
                },
            },
        ],
        yAxis: [
            {
                title: {
                    text: "PSI (g)",
                },
                labels: {
                    format: `{value} PSI(g)`,
                },
            },
            ...(props.plotDutyCycle ? [dutyCycleYAxis] : []),
        ],
        credits: false,
        series: grindSeries,
    });

    const ref = useRef();
    useEffect(() => {
        const updatedOptions = {
            chart: {
                height: singleChartHeight,
            },
            xAxis: [
                {
                    type: "datetime",
                    plotBands: props.plotBands,
                    plotLines: props.overlayInfo && props.infoConstants,
                },
            ],
            tooltip: {
                formatter: tooltip,
            },
            yAxis: [
                {
                    title: {
                        text: "PSI (g)",
                    },
                    labels: {
                        format: `{value} PSI(g)`,
                    },
                },
                ...(props.plotDutyCycle ? [dutyCycleYAxis] : []),
            ],
            series: grindSeries,
        };
        setChartOptions(updatedOptions);
    }, [props.plotBands, props.overlayInfo, props.infoConstants, props.burninChart, grindSeries]);

    useZoomParams(props.zoomParams, ref, props.timeList);
    return <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />;
};

GrindCycle.defaultProps = {
  igList: [],
  paList: [],
  timeList: [],
  plotBands: [],
  infoConstants: [],
  overlayInfo: false,
  dutyCycleList: [],
  plotDutyCycle: false,
  dutyCycleValuesOverThreshHold: false,
  showAbnormalSeriesValues: false,
  abnormalPaValues: {},
  abnormalIgValues: {},
  abnormalDutyCycleValues: {},
  customTitlePrefix: "",
  overlayedCycles: [],
};

GrindCycle.propTypes = {
  igList: PropTypes.array,
  paList: PropTypes.array,
  infoConstants: PropTypes.array,
  plotBands: PropTypes.array,
  overlayInfo: PropTypes.bool,
  dutyCycleList: PropTypes.array,
  plotDutyCycle: PropTypes.bool,
  dutyCycleValuesOverThreshHold: PropTypes.bool,
  showAbnormalSeriesValues: PropTypes.bool,
  abnormalPaValues: PropTypes.object,
  abnormalIgValues: PropTypes.object,
  abnormalDutyCycleValues: PropTypes.object,
  customTitlePrefix: PropTypes.string,
  zoomParams: PropTypes.object,
  setZoomParams: PropTypes.func,
  overlayedCycles: PropTypes.array,
};

export default GrindCycle;
