/*
* -placeholder text for new functional component -
* 
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import {Checkbox} from "semantic-ui-react";
import BurninTestChart from "./BurninTestChart";
import CycleStats from "./CycleStats";
import moment from "moment";
import PropTypes from 'prop-types';
import BurninItem from "./BurninItem";

const BurninTest7Results = props => {
  const [displayTest7Chart, setDisplayTest7Chart] = useState({});
  const [displayTest7Metrics, setDisplayTest7Metrics] = useState({});
  const [burninTest7Cycles, setBurninTest7Cycles] = useState([]);
  const [burninTest7, setBurninTest7] = useState({});

  useEffect(() => {
    const {cycles: burninTest7Cycles, ...burninTest7} = props.burninTest7;
    setBurninTest7(burninTest7);
    setBurninTest7Cycles(burninTest7Cycles);

    const displayTest7Chart = burninTest7Cycles.reduce((acc, curr, idx) => {
      acc[idx] = false;
      return acc;
    }, {});
    setDisplayTest7Chart(displayTest7Chart);

    const displayTest7Metrics = burninTest7Cycles.reduce((acc, curr, idx) => {
      acc[idx] = false;
      return acc;
    }, {});
    setDisplayTest7Metrics(displayTest7Metrics);


  }, [props.burninTest7]);

  return <React.Fragment>
    <div className='flex-dir-row'>
      <div className='burnin-stats-container'>
        <fieldset>
          <legend>{props.testTitle} {props.burninTestState(props.burninTest7.is_successful === 1)}</legend>
          {
            burninTest7Cycles.map((cycle, idx) => {
              return <Checkbox
                className='digital-output-toggle'
                toggle
                key={`show-metrics-checkbox-${idx+1}`}
                label={`Show Cycle Metrics for Steam Cycle ${idx + 1}/4`}
                onChange={() => setDisplayTest7Metrics(prevState => ({
                  ...prevState,
                  [idx]: !prevState[idx]
                }))}
                checked={displayTest7Metrics[idx]}
              />
            })
          }
          {
            props.burninStatInternalNames.map((name, idx) => {
              return <details className='detailed-cycle-stat-main-stat' key={`burnin-${name}`}>
                <summary>
                  <BurninItem
                    test={burninTest7}
                    name={name}
                    idx={idx}
                    key={`${name}-${idx}-7`}
                    abnormalValuesForBurninTest={props.abnormalValuesForBurninTest}
                    useInlineBlock={true}
                  />
                </summary>
                <div className='detailed-cycle-stats'>
                  {
                    burninTest7Cycles.map((cycle, cycleIdx) => {
                      return <BurninItem
                        test={cycle}
                        name={name}
                        idx={cycleIdx}
                        key={`${name}-${idx}-${cycleIdx}-7`}
                        abnormalValuesForBurninTest={props.abnormalValuesForBurninTest}
                        labelPrefix={`Cycle #${cycleIdx + 1}: `}
                      />
                    })
                  }
                </div>
              </details>
            })
          }
          {
            burninTest7Cycles.map((cycle, idx) => {
              return <Checkbox
                className='digital-output-toggle'
                toggle
                key={`show-chart-checkbox-${idx+1}`}
                label={`Show Chart for Steam Cycle ${idx + 1}/4 `}
                onChange={() => setDisplayTest7Chart(prevState => ({
                  ...prevState,
                  [idx]: !prevState[idx]
                }))}
                checked={displayTest7Chart[idx]}
              />

            })
          }
        </fieldset>
      </div>
      <div className='flex-fill'>
        {
          burninTest7Cycles.map((cycle, idx) => {
            return <React.Fragment key={`${idx}-${cycle['start_time']}-metrics`}>
              {
                displayTest7Metrics[idx] && <CycleStats
                  selectedCycle={cycle}
                  abnormalValuesObj={props.abnormalValuesObj}
                  fromBurninTest={true}
                  burninTest7Number={idx+1}
                />
              }
            </React.Fragment>
          })
        }
      </div>
    </div>
    {
      burninTest7Cycles.map((cycle, idx) => {
        const startTime = moment.utc(cycle['start_time']).format("YYYY-MM-DD HH:mm:ss z");
        return <React.Fragment key={`${idx}-${cycle['start_time']}-charts`}>
          {
            displayTest7Chart[idx] && <BurninTestChart
              customTitlePrefix={`Test 7 Steam Cycle ${idx + 1}/4 - ${startTime}`}
              burninTestCycle={burninTest7Cycles[idx]}
              abnormalValuesObj={props.abnormalValuesObj}
              digitalOutputOverlays={props.digitalOutputOverlays}
              chartOptions={props.chartOptions}
            />
          }
        </React.Fragment>
      })
    }
  </React.Fragment>
};

BurninTest7Results.defaultProps = {};

BurninTest7Results.propTypes = {
  burninTestState: PropTypes.func.isRequired
};

export default BurninTest7Results;
