/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CycleParameters = (props) => {
  const [parameters, setParameters] = useState([]);
  const cycleParamName = {
    steam_type: "Steam Type",
    control_temp: "Control Temp",
    cook_temp: "Cook Temp",
    hot_drain_temp: "Hot Drain Temp",
    pv_unsafe: "Pv Unsafe",
    pv_too_low: "Pv Too Low",
    drain_open_duration: "Drain Open Duration",
    drain_to_sec1: "Drain to Sec1",
    drain_to_sec2: "Drain to Sec2",
    wait_pressure: "Wait Pressure"
  }

  useEffect(() => {
    const params = Object.entries(props.cycleParameters).map(([key, value]) => {
      return { name: key, value: value };
    });
    setParameters(params);
  }, [props.cycleParameters]);

  return (
    <Box>
      {parameters.length > 0 && (
        <Accordion variant="outlined" sx={{ my: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="cycle-parameter-accordian"
            id="cycle-parameter-accordian-header"
          >
            <Typography variant="h6" color="secondary">
              Cycle Parameters
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List disablePadding>
              {parameters.map((cycleParam, idx) => {
                return (
                    cycleParam["name"] in cycleParamName && (
                        <ListItem disablePadding divider={true} key={`${idx}-${cycleParam["name"]}`}>
                            <ListItemButton sx={{ px: 0 }}>
                                <ListItemText primary={cycleParamName[cycleParam["name"]]} />
                                <ListItemText primary={cycleParam["value"]} sx={{ textAlign: "right" }} />
                            </ListItemButton>
                        </ListItem>
                    )
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

CycleParameters.defaultProps = {
  cycleParameters: {},
};

CycleParameters.propTypes = {
  cycleParameters: PropTypes.object,
};

export default CycleParameters;
