/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import PropTypes from "prop-types";
import CycleStat from "../CycleStats/CycleStat";
import { calculateDurations, getRoundedNumber } from "src/components/library/helpers";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, Grid } from "@mui/material";
import { Box, ListItemButton } from "@mui/material";

const BurninTest7Results = (props) => {
    const { test, name, idx, subBurninStatInternalNames } = props;

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const getCycleStatLabel = (name, labelPrefix) => {
        const { abnormalValuesForBurninTest } = props;
        if (name in abnormalValuesForBurninTest) {
            return `${labelPrefix ? labelPrefix : ""} ${abnormalValuesForBurninTest[name]["display_name"]}`;
        } else {
            return name;
        }
    };

    const getAbnormalValueDefinition = (name) => {
        const { abnormalValuesForBurninTest } = props;
        if (name in abnormalValuesForBurninTest) {
            return abnormalValuesForBurninTest[name];
        }
    };

    return (
        <React.Fragment>
            <Box>
                <ListItemButton onClick={handleClick} sx={{ p: 0 }}>
                    <CycleStat
                        label={getCycleStatLabel(name)}
                        value={getRoundedNumber(
                            test,
                            name,
                            props.usePercent ? 0 : 2 // for percent based metrics, we don't want to have 2 decimal points
                        )}
                        abnormalValues={getAbnormalValueDefinition(name)}
                        useInlineBlock={true}
                        usePercent={props.usePercent}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ justifyContent: "flex-end" }}>
                    {subBurninStatInternalNames.map((subMetric, internalIdx) => {
                        return (
                            <Grid container justifyContent="right" key={`${subMetric}-${internalIdx}-${idx}`}>
                                <Box width="90%" pl="10">
                                    <CycleStat
                                        label={getCycleStatLabel(name, `Cycle #${internalIdx + 1}`)}
                                        value={getRoundedNumber(subMetric, name, 2)}
                                        abnormalValues={getAbnormalValueDefinition(subMetric[name])}
                                    />
                                </Box>
                            </Grid>
                        );
                    })}
                </Collapse>
            </Box>
        </React.Fragment>
    );
};

BurninTest7Results.defaultProps = {
    usePercent: false,
};

BurninTest7Results.propTypes = {
    usePercent: PropTypes.bool,
    test: PropTypes.object,
    abnormalValuesForBurninTest: PropTypes.object,
    name: PropTypes.string,
    idx: PropTypes.number,
    subBurninStatInternalNames: PropTypes.array,
};

export default BurninTest7Results;
