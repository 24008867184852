import React, {useState} from 'react';
import {useScrollPosition} from '@n8tb1t/use-scroll-position'
import {Icon} from 'semantic-ui-react';


const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  useScrollPosition(({prevPos, currPos}) => {
    if (currPos.y < -300) {
      setShowScroll(true)
    } else {
      if (showScroll) {
        setShowScroll(false)
      }
    }
  }, [showScroll]);


  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <Icon
      title='Scroll to top of page'
      // name='long arrow alternate up'
      name='arrow circle up'
      size='huge'
      className="scroll-top-icon"
      onClick={scrollTop}
      style={{height: 40, display: showScroll ? 'flex' : 'none'}}
    />
  );
};

export default ScrollTopArrow;