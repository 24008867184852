/*
* Authorization & Authentication HOC
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

import React, {Component} from 'react';
import {withRouter} from 'react-router';
// import SimpleLoader from './SimpleLoader';
import WaveLoader from './WaveLoader';
import AuthService from './AuthService';
import * as Sentry from "@sentry/browser";
import {isGroupSterilis} from "./library/helpers"
import {toast} from "react-toastify";
import { UserContext } from "./library/UserContext";

export default function withAuth(AuthLevels, pageType) {
	return (AuthComponent) => {
		const Auth = new AuthService();

		class AuthWrapped extends Component {
			constructor() {
				super();
				this.state = {
					group: null,
					authLoading: false,
					successfulAuth: false,
				};
			}

			notifyReleaseNotes = () =>
				toast(
					<div onClick={() => this.props.history.replace("/release-notes")}>
						New Version of the Sterilis Portal has been released! Click here to view the Release Notes
					</div>,
					{ type: toast.TYPE.DEFAULT, autoClose: 20000 }
				);

			componentDidMount() {
				if (!Auth.loggedIn()) {
					this.props.history.push({
						pathname: "/login",
						state: {
							attemptedUrl: this.props.location.pathname,
							attemptedQueryParam: this.props.location.search,
						},
					});
				} else {
					try {
						this.setState({ authLoading: true });
						if (AuthLevels instanceof Array) {
							let counter = 1;
							AuthLevels.forEach((authLevel) => {
								if (authLevel === Auth.getGroup()) {
									Auth.verifyGroup()
										.then((res) => {
											const group = Auth.getGroup();
											const user = {
												full_name: res["user"]["full_name"],
												first_name: res["user"]["first_name"],
												last_name: res["user"]["last_name"],
												username: res["user"]["username"],
												customer: res["user"]["customer"],
												customer_id: res["user"]["customer_id"],
												email: res["user"]["email"],
												user_id: res["user"]["portal_user_id"],
												employee_id: res["user"]["employee_id"],
												customer_unit_preference: res["user"]["customer_unit_preference"],
											};
											// if the customer user hasn't accepted the toc, don't let them go to any other page except the toc
											if (
												(res["user"]["accepted_toc"] === false ||
													res["user"]["toc_accepted_preferred"] === false) &&
												isGroupSterilis(group) === false
											) {
												this.setState({
													group: group,
													authLoading: false,
													user,
													env: res["env"],
												});
												if (pageType !== "termsPage") {
													this.props.history.replace("/terms");
												}
											} else {
												const localStorageReleaseNoteSeen =
													localStorage.getItem("seen_release_note");

												// if the user really doesn't want to see the notes, let's not annoy them every time they
												// navigate to a page. cap the notification amount to two
												const amountOfTimesNotified = Number(
													localStorage.getItem("release_note_notification_count")
												);

												// Change this string to the latest version of Release Notes when there's new release notes
												if (
                                                    amountOfTimesNotified < 2 &&
                                                    localStorageReleaseNoteSeen !== "v2.2.10" &&
                                                    pageType !== "releaseNotesPage"
                                                ) {
                                                    localStorage.setItem(
                                                        "release_note_notification_count",
                                                        amountOfTimesNotified + 1
                                                    );
                                                    this.notifyReleaseNotes();
                                                }

												this.setState({
													group: group,
													authLoading: false,
													successfulAuth: true,
													user,
													env: res["env"],
												});
											}
										})
										.catch((e) => {
											Sentry.captureException(e);
											this.setState({ authLoading: false });
											Auth.logout();
											this.props.history.replace("/login");
										});
								} else {
									//else, if the level passed into the hoc does not match what's in their local storage, redirect them
									if (counter === AuthLevels.length) {
										Auth.logout();
										this.props.history.replace("/login");
									} else {
										counter++;
									}
								}
							});
						}
					} catch (err) {
						Sentry.captureException(err);
						Auth.logout();
						this.props.history.replace("/login");
					}
				}
			}

			render() {
				const authLoading = this.state.authLoading;

				if (authLoading) {
					// If this is an internal page, display the SideBarLeftPush.
					// Makes changing pages smoother on prod where load times are basically nonexistent
					if (pageType === "internalPage") {
						return (
							<UserContext.Provider
								value={{ user: this.state.user, env: this.state.env, group: this.state.group }}
							>
								<WaveLoader />
							</UserContext.Provider>
						);
					} else {
						return (
							<UserContext.Provider
								value={{ user: this.state.user, env: this.state.env, group: this.state.group }}
							>
								<WaveLoader />
							</UserContext.Provider>
						);
					}
				} else {
					if (this.state.successfulAuth || pageType === "termsPage") {
						return (
							<UserContext.Provider
								value={{ user: this.state.user, env: this.state.env, group: this.state.group }}
							>
								<AuthComponent
									{...this.props}
									user={this.state.user}
									env={this.state.env}
									group={this.state.group}
								/>
							</UserContext.Provider>
						);
					} else {
						return null;
					}
				}
			}
		}

		return withRouter(AuthWrapped);
	};
}
