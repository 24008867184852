/*
*  Archive a Customer modal
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import {Link} from "react-router-dom";
import AuthService from "../../../AuthService";
import SimpleLoader from "../../../SimpleLoader";
import CycleReportWarning from './CycleReportWarning'
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();


class RetireCustomerModal extends React.Component {
  state = {
    customerAbleToBeRetired: true,
    allowModalToBeClosed: true,
  };

  componentDidMount() {
    const {
      customer
    } = this.props;
    let customerAbleToBeRetired = true;
    const customerDevices = [];
    customer['facilities'].forEach((facility) => {
      if (facility['device_configs'].length > 0) {
        customerAbleToBeRetired = false;
        facility['device_configs'].forEach((deviceConfig) => {
          customerDevices.push({
            id: deviceConfig['id'],
            serial_number: deviceConfig['device']['serial_number']
          })
        })
      }
    });

    this.setState({
      customerAbleToBeRetired,
      customerDevices
    })
  }

  retireCustomer = () => {
    const {
      customer
    } = this.props;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/customer/${customer['id']}/`, {
      method: 'DELETE',
    }).then(() => {

      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.preparePage();
        this.props.triggerRetireCustomerModal(null);
        this.props.notifySuccess('archived', 'customer', customer['customer_name'])
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerRetireCustomerModal();
        this.props.notifyFailure()
      });
    });
  };

  render() {
    const {
      t,
      customer
    } = this.props;
    const {
      customerAbleToBeRetired,
      customerDevices,
      modalLoading
    } = this.state;

    const customerName = customer['customer_name'];

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.retireCustomerModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerRetireCustomerModal(null)
          }}
        >
          <Header>
            <h2 className='grey-text'>{t('Archive Customer')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            {
              customerAbleToBeRetired ?
                <div className='short-modal-text center-text'>
                  <p>
                    <Trans ns="translations" i18nKey="archive-customer.1">
                      Are you sure you want to archive <strong>{{customerName}}</strong>?
                    </Trans>
                  </p>
                  <p>
                    <Trans ns="translations" i18nKey="archive-customer.2">
                      All of <strong>{{customerName}}</strong>'s facilities will be archived as well.
                    </Trans>
                  </p>
                  <CycleReportWarning
                    action={t('archive')}
                    actionPastTense={t('archived')}
                    noun={t('customer')}
                    name={customer['customer_name']}
                    customerID={customer['id']}
                  />
                </div> :
                <div className='short-modal-text center-text'>
                  <p>{t('archive-customer.cannot_archive', 'This customer cannot be archived yet. It has active devices assigned to it. Please move the following devices to a different facility')}:</p>
                  <ul className="facility-serials">
                    {customerDevices.map((deviceConfig) => {
                      return (
                        <li key={deviceConfig['id']}>
                          <Link target="_blank" to={`/find-a-machine/${deviceConfig['id']}`}>
                            {deviceConfig['serial_number']}
                          </Link>
                        </li>
                      )
                    })
                    }
                  </ul>
                </div>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn grey-btn'
                    value="Submit"
                    type="submit"
                    id='unbanCustomerBtn'
                    onClick={this.retireCustomer}
                    disabled={!customerAbleToBeRetired}
            >{t('Archive Customer')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(RetireCustomerModal);
