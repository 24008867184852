/*
 * Child component from UserControlPanel, contains language editing view
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */

import decode from "jwt-decode";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import { config } from "../portal-environment";

export default class AuthService {
    constructor() {
        this.webDomain = config.webDomain;
        this.apiDomain = config.apiDomain;
        this.fetch = this.fetch.bind(this);
        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    login(username, password) {
        // Get a token
        return this.fetch(`/api/login/`, {
            method: "POST",
            body: JSON.stringify({
                username,
                password,
            }),
        }).then((res) => {
            localStorage.setItem("id_token", res.token);
            localStorage.setItem("group_token", res["user"]["group_name"]);

            return Promise.resolve(res);
        });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    getGroup() {
        return localStorage.getItem("group_token");
    }

    verifyGroup() {
        const group = this.getGroup();
        const userId = this.getProfile().user_id;

        return this.fetch(`/api/verify-group/`, {
            method: "POST",
            body: JSON.stringify({
                group_name: group,
                user_id: userId,
            }),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem("id_token");
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem("id_token");
        localStorage.removeItem("group_token");
    }

    getProfile() {
        return decode(this.getToken());
    }

    async fetch(url, options) {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        if (this.loggedIn()) {
            headers["Authorization"] = "JWT " + this.getToken();
        }

        const response = await fetch(`${this.apiDomain}${url}`, {
            headers,
            ...options,
        });
        const response_1 = await this._checkStatus(response);
        if (response_1.headers.get("content-type") === "application/json") {
            return response_1.json();
        } else if (response_1.headers.get("content-type") === "application/pdf") {
            this.savePDF(response_1);
        } else {
            return response_1;
        }
    }

    file_fetch(url, options, filename) {
        const headers = {
            Accept: "application/json",
        };

        if (this.loggedIn()) {
            headers["Authorization"] = "JWT " + this.getToken();
        }

        headers["Content-Disposition"] = `attachment; filename="${filename}"`;

        return fetch(`${this.apiDomain}${url}`, {
            headers,
            ...options,
        })
            .then(this._checkStatus)
            .then((response) => {
                if (response.headers.get("content-type") === "application/json") {
                    return response.json();
                } else if (response.headers.get("content-type") === "application/pdf") {
                    this.savePDF(response);
                } else {
                    return response;
                }
            });
    }

    savePDF(response) {
        // If content-disposition has been set by the server, it will include the filename.
        if (response.headers.get("content-disposition")) {
            // Header string should look like this from the server
            // 'attachment; filename="pdf_file_name.pdf"'
            const fileName = response.headers.get("content-disposition").match(/"([^']+)"/)[1];
            return response.blob().then((blob) => {
                saveAs(blob, `${fileName}`);
            });
        } else {
            return response.blob().then((blob) => saveAs(blob));
        }
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            // See AssignDeviceToFacility's submit()/catch to see how to get the server error's JSON resp
            let error = new Error(response.statusText);
            error.response = response;
            // When the user's JWT is invalid, either through changing their password, username, or JWT expiring, we need to
            // boot them off the frontend in the most graceful way possible

            if (response.status === 401 || response.status === 403) {
                // If user isn't on the login page, display the toast to tell them that they've been logged out. Clear
                // their auth on close
                if (window.location.pathname + window.location.search !== "/login") {
                    this.logout();
                    toast(`Your session has expired. Please log in again.`, {
                        type: toast.TYPE.DEFAULT,
                        autoClose: 3500,
                        onClose: () => {
                            window.location.href = "/login";
                        },
                    });
                }
            }
            throw error;
        }
    }
}
