/*
 *  A table which has device's biochallenge info displayed
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
 */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {Icon} from "semantic-ui-react";
import {
  capitalizeFirstLetter,
  attrTimestampSortHook,
} from "../library/helpers";
import HookTable from "../library/HookTable";
import {Link} from "react-router-dom";
import "../../css/summary-dashboard/SummaryDashboardBiochallenge.css";

const SummaryDashboardBiochallenge = (props) => {
  const [BioChallengeArray, setBioChallengeArray] = useState([]);
  const { t } = useTranslation("translations");

  const calcBarColor = (xSeriesValue, maxXAxisValue) => {
    if(xSeriesValue / maxXAxisValue > .75){
      return 'bc-green-tr'
    }else if (xSeriesValue / maxXAxisValue > .50){
      return 'bc-yellow-tr'
    }else if (xSeriesValue / maxXAxisValue > .25){
      return 'bc-dark-yellow-tr'
    }else{
      return 'bc-red-tr'
    }

  };

  useEffect(() => {
    const { biochallengeInfo, userTimezone, deviceTimezone } = props;

    if (biochallengeInfo.length > 0) {
      const BioChallengeArray = biochallengeInfo.filter(biochallenge => {
        if(biochallenge["is_airport"]){
          return false
        }
        return biochallenge;
      }).map((biochallenge) => {
        let biochallenge_due = "";
        let cleanStatus = "";
        let prettyDueDate = "";
        const xSeriesValue = Number(biochallenge["x_series_value"]);
        const maxXAxisValue = Number(biochallenge["x_axis"]);
        const comment =
          xSeriesValue < 0 ? (
            <div className="minor-padding red-text">
              <Icon name='attention' />
              {t(
                "A Biological Challenge must be run before this device can run a Sterilization cycle"
              )}
            </div>
          ) : (
            ""
          );

        if (biochallenge["last_biochallenge_info"]["last_biochallenge_result"] === 1) {
          cleanStatus = t("Validated")
        } else{
          cleanStatus = biochallenge['last_biochallenge_info']['last_biochallenge_status_msg']
        }
        const lastbiochallengedate = (biochallenge['last_biochallenge_info']['last_biochallenge_result'] === 1 || !(biochallenge['last_biochallenge_info']['last_biochallenge_start'])) ? biochallenge['last_biochallenge_info']['last_biochallenge_validation_date'] : biochallenge['last_biochallenge_info']['last_biochallenge_start']
        const last_biochallenge_date = `${moment(lastbiochallengedate)
          .tz(deviceTimezone ? deviceTimezone : userTimezone)
          .format("YYYY-MM-DD HH:mm:ss z")} (${cleanStatus})`;

        if (biochallenge["type"] === "calendar") {
          const newBioChallengeDueDate = moment.utc(biochallenge["next_biochallenge_date"]);
          if(xSeriesValue < 0){
            prettyDueDate = newBioChallengeDueDate.fromNow();
          }else{
            prettyDueDate = xSeriesValue + " days ";
          }

          let cellClass = calcBarColor(xSeriesValue, maxXAxisValue);
          const nextBiochallenge = moment(biochallenge["next_biochallenge_date"])
            .tz(deviceTimezone ? deviceTimezone : userTimezone)
            .format("YYYY-MM-DD HH:mm:ss z");
          biochallenge_due = (
            <details
              data-date={nextBiochallenge}
              className={`${cellClass} minor-padding`}
            >
              <summary>{capitalizeFirstLetter(prettyDueDate)}</summary>
              {nextBiochallenge}
            </details>
          );
        } else if (biochallenge["type"] === "usage") {
          // The device reports up a negative number, so we'll need to find the absolute value of it
          const cyclesUntilBiochallenge = Math.abs(biochallenge["x_series_value"]);

          let cellClass = calcBarColor(xSeriesValue, maxXAxisValue);
          const cyclesTillString = `${cyclesUntilBiochallenge} cycle${cyclesUntilBiochallenge > 1 ? "s" : ""}`;

          biochallenge_due = (
            <div
              data-date={cyclesUntilBiochallenge}
              className={`${cellClass} minor-padding`}
            >
              {cyclesTillString}
            </div>
          );
        }
        return {
          device_serial: <Link target="_blank" to={`/sa/dashboard/${biochallenge['device_config_id']}`}>{biochallenge["serial_number"]}</Link>,
          biochallenge_due: biochallenge_due,
          last_biochallenge_date: last_biochallenge_date,
          run_by: biochallenge["last_biochallenge_info"]["last_biochallenge_operator"],
          comment: comment,
        }
      })

      setBioChallengeArray(BioChallengeArray);
    }
  }, [props.biochallengeInfo]);

  const deviceDueDateColumns = [
    {
      Header: t("Device Serial"),
      accessor: "device_serial",
      manualWidth: 110,
    },
    {
      Header: t("Bio-Challenge due in"),
      accessor: "biochallenge_due",
      id: "biochallenge_due",
      sortType: (a, b, columnID) => attrTimestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss z"),
      manualWidth: 220,
    },
    {
      Header: t("Last Biological Challenge date"),
      accessor: "last_biochallenge_date",
    },
    {
      Header: t("Run by"),
      accessor: "run_by",
      manualWidth: 110,
    },
    {
      Header: t("Comment"),
      accessor: "comment",
    },
  ]


  return (
    <React.Fragment>
      <div className="cycle-table-header slight-margin-bottom">
        <div style={{ margin: "10px" }}>
          <h2>{t("Biological Challenge")}</h2>
        </div>
      </div>
      <HookTable
        columns={deviceDueDateColumns}
        defaultSortBy={{ id: "serial_number" }}
        renderEmptyRow={false}
        tableAscend={true}
        data={BioChallengeArray}
      />
    </React.Fragment>
  )
}
export default SummaryDashboardBiochallenge
