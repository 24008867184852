/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import {
    Checkbox,
    FormControlLabel,
    Switch,
    Tooltip,
    Fab,
    Box,
    Drawer,
    Typography,
    Divider,
    useTheme,
    styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BarChartIcon from "@mui/icons-material/BarChart";

const CycleMetricChartOptions = (props) => {
    const { t } = useTranslation("translations");
    const theme = useTheme();

    const {
        overlaySol1,
        overlaySol2,
        overlaySol3,
        overlaySol4,
        overlaySol5,
        overlaySol6,
        overlaySol7,
        overlaySol8,
        overlaySol9,
        overlaySol10,
        overlaySol11,
        overlaySol12,
        overlaySol13,
        overlaySol14,
        overlaySol15,
        overlaySol16,
        overlaySol17,
        overlaySol18,
        overlayVacPump,
        overlayAirPump,
        overlayWaterPump,
        overlayHeaters,
        overlayDeodorizerLED,
        overlayGrinderOn,
        overlayGrinderForward,
        overlayOil,
        overlayOilLevels,
    } = props.digitalOutputOverlays;

    const {
        combineTPGraphs,
        showCurrentGraph,
        showTempConstants,
        showPresConstants,
        overlayStates,
        overlayInfo,
        plotDutyCycle,
        showAbnormalSeriesValues,
    } = props.chartOptions;

    const [drawerOpen, setDrawerOpen] = useState(false);

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    useEffect(() => {
        props.setShowFloatButtons(!drawerOpen);
    }, [drawerOpen]);

    const [floatButton, setFloatButton] = useState(false);

    useScrollPosition(
        ({ prevPos, currPos }) => {
            if (currPos.y < -300 && props.containsBurninTest === true) {
                setFloatButton(true);
            } else {
                //only set float button to false again if its set to true
                if (floatButton) {
                    setFloatButton(false);
                }
            }
        },
        [floatButton]
    );

    return (
        <React.Fragment>
            {props.showFloatButtons && (
                <Box
                    sx={{
                        transform: "translateZ(0px)",
                        flexGrow: 1,
                        position: "fixed",
                        bottom: 100,
                        right: 30,
                        zIndex: 2000,
                    }}>
                    <Tooltip title="Show Chart Options" placement="left">
                        <Fab
                            color="secondary"
                            aria-label="copy"
                            size="small"
                            onClick={() => setDrawerOpen(!drawerOpen)}>
                            <BarChartIcon />
                        </Fab>
                    </Tooltip>
                </Box>
            )}

            <Drawer
                open={drawerOpen}
                anchor="right"
                transitionDuration={400}
                onClose={() => setDrawerOpen(!drawerOpen)}>
                <DrawerHeader />
                <CloseIcon
                    color="error"
                    fontSize="large"
                    className="abs-top-right pointer"
                    onClick={() => setDrawerOpen(!drawerOpen)}
                />
                <Box mx={2}>
                    <Typography variant="h5" color="primary" sx={{ p: 1.5 }}>
                        Chart Options
                    </Typography>
                    <Divider />
                    <div className={`flex-dir-column`}>
                        {(props.cycleType === "SteamCycle" || props.containsBurninTest) && (
                            <React.Fragment>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                props.setChartOptions({
                                                    combineTPGraphs: !combineTPGraphs,
                                                })
                                            }
                                            checked={combineTPGraphs}
                                        />
                                    }
                                    label="Combine temperature and pressure graphs"
                                    sx={{ width: "fit-content" }}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                props.setChartOptions({
                                                    showCurrentGraph: !showCurrentGraph,
                                                })
                                            }
                                            checked={showCurrentGraph}
                                        />
                                    }
                                    label="Show Current(A) graph"
                                    sx={{ width: "fit-content" }}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                props.setChartOptions({
                                                    showTempConstants: !showTempConstants,
                                                })
                                            }
                                            checked={showTempConstants}
                                        />
                                    }
                                    label="Show Temperature Constants"
                                    sx={{ width: "fit-content" }}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                props.setChartOptions({
                                                    showPresConstants: !showPresConstants,
                                                })
                                            }
                                            checked={showPresConstants}
                                        />
                                    }
                                    label="Show Pressure Constants"
                                    sx={{ width: "fit-content" }}
                                />
                            </React.Fragment>
                        )}
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() => props.setChartOptions({ overlayStates: !overlayStates })}
                                    checked={overlayStates}
                                />
                            }
                            label="Overlay States"
                            sx={{ width: "fit-content" }}
                        />
                        {(props.infoCount > 0 || props.containsBurninTest) && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() => props.setChartOptions({ overlayInfo: !overlayInfo })}
                                        checked={overlayInfo}
                                    />
                                }
                                label={`Overlay ${props.containsBurninTest ? "" : props.infoCount} Messages`}
                                sx={{ width: "fit-content" }}
                            />
                        )}
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() => props.setChartOptions({ plotDutyCycle: !plotDutyCycle })}
                                    checked={plotDutyCycle}
                                />
                            }
                            label="Plot Duty Cycle"
                            sx={{ width: "fit-content" }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() =>
                                        props.setChartOptions({
                                            showAbnormalSeriesValues: !showAbnormalSeriesValues,
                                        })
                                    }
                                    checked={showAbnormalSeriesValues}
                                />
                            }
                            label="Color Abnormal Values on Graph"
                            sx={{ width: "fit-content" }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() => props.setScrollOnCycleClick(!props.scrollOnCycleClick)}
                                    checked={props.scrollOnCycleClick}
                                />
                            }
                            label="Scroll to top on cycle table row click"
                            sx={{ width: "fit-content" }}
                        />

                        <div className="drawer-chart-options-digital-output-container">
                            <fieldset>
                                <legend>{t("Digital Outputs")}</legend>
                                <div className={"mui-three-across-grid-container"}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol1: !overlaySol1,
                                                    })
                                                }
                                                checked={overlaySol1}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Vacuum{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 1 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol2: !overlaySol2,
                                                    })
                                                }
                                                checked={overlaySol2}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Drain{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 2 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol3: !overlaySol3,
                                                    })
                                                }
                                                checked={overlaySol3}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Water{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 3 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol4: !overlaySol4,
                                                    })
                                                }
                                                checked={overlaySol4}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Top Lid{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 4 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol5: !overlaySol5,
                                                    })
                                                }
                                                checked={overlaySol5}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Bottom Lid{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 5 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol6: !overlaySol6,
                                                    })
                                                }
                                                checked={overlaySol6}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Shroud{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 6 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol7: !overlaySol7,
                                                    })
                                                }
                                                checked={overlaySol7}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Accumulator{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 7 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol8: !overlaySol8,
                                                    })
                                                }
                                                checked={overlaySol8}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Seal{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 8 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol9: !overlaySol9,
                                                    })
                                                }
                                                checked={overlaySol9}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Jack{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 9 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol10: !overlaySol10,
                                                    })
                                                }
                                                checked={overlaySol10}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Air Inlet{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 10 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol11: !overlaySol11,
                                                    })
                                                }
                                                checked={overlaySol11}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Oil{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 11 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol12: !overlaySol12,
                                                    })
                                                }
                                                checked={overlaySol12}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Seal Vacuum{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 12 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol13: !overlaySol13,
                                                    })
                                                }
                                                checked={overlaySol13}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Vessel{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 13 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol14: !overlaySol14,
                                                    })
                                                }
                                                checked={overlaySol14}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Odor{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 14 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol15: !overlaySol15,
                                                    })
                                                }
                                                checked={overlaySol15}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Shroud 2{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 15 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol16: !overlaySol16,
                                                    })
                                                }
                                                checked={overlaySol16}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Deodorizer{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 16 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol17: !overlaySol17,
                                                    })
                                                }
                                                checked={overlaySol17}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Drain Alt{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 17 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlaySol18: !overlaySol18,
                                                    })
                                                }
                                                checked={overlaySol18}
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Signal Light{" "}
                                                <Typography variant="overline" color={"primary"}>
                                                    ( Sol 18 )
                                                </Typography>
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayVacPump: !overlayVacPump,
                                                    })
                                                }
                                                checked={overlayVacPump}
                                            />
                                        }
                                        label={<Typography>Vacuum Pump</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayAirPump: !overlayAirPump,
                                                    })
                                                }
                                                checked={overlayAirPump}
                                            />
                                        }
                                        label={<Typography>Air Pump</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayWaterPump: !overlayWaterPump,
                                                    })
                                                }
                                                checked={overlayWaterPump}
                                            />
                                        }
                                        label={<Typography>Water Pump</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayHeaters: !overlayHeaters,
                                                    })
                                                }
                                                checked={overlayHeaters}
                                            />
                                        }
                                        label={<Typography>Heaters</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayDeodorizerLED: !overlayDeodorizerLED,
                                                    })
                                                }
                                                checked={overlayDeodorizerLED}
                                            />
                                        }
                                        label={<Typography>Deodorizer LED</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayGrinderOn: !overlayGrinderOn,
                                                    })
                                                }
                                                checked={overlayGrinderOn}
                                            />
                                        }
                                        label={<Typography>Grinder On</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayGrinderForward: !overlayGrinderForward,
                                                    })
                                                }
                                                checked={overlayGrinderForward}
                                            />
                                        }
                                        label={<Typography>Grinder Forward</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayOil: !overlayOil,
                                                    })
                                                }
                                                checked={overlayOil}
                                            />
                                        }
                                        label="Oil"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    props.setDigitalOutputOverlays({
                                                        overlayOilLevels: !overlayOilLevels,
                                                    })
                                                }
                                                checked={overlayOilLevels}
                                            />
                                        }
                                        label={<Typography>Oil Levels</Typography>}
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </React.Fragment>
    );
};

CycleMetricChartOptions.defaultProps = {
    chartOptions: {},
    digitalOutputOverlays: {},
    useDrawer: false,
};

CycleMetricChartOptions.propTypes = {
    setChartOptions: PropTypes.func.isRequired,
    setDigitalOutputOverlays: PropTypes.func.isRequired,
    chartOptions: PropTypes.object,
    digitalOutputOverlays: PropTypes.object,
    useDrawer: PropTypes.bool,
    scrollOnCycleClick: PropTypes.bool.isRequired,
    setScrollOnCycleClick: PropTypes.func.isRequired,
};

export default CycleMetricChartOptions;
