/*
*  A simple pop-up component used on the Create a Facility/Customer pages to display a helpful link for users to calculate elevation
*  The <Trans/> component is a great example of how to interpolate HTML/dynamic JS elements into a translatable string
*
* Copyright (C) 2018 Sterilis Medical Inc., all rights reserved.
*/

import {Icon, Popup} from "semantic-ui-react";
import React from "react";
import 'details-polyfill';
import { Trans } from 'react-i18next';

const FindFacilityElevationPopup = (props) => {
  return <Popup
  content={
    <Trans ns='translations' i18nKey="facility-elevation-help">
      Need help calculating the facility's elevation? No problem. Use <a rel="noopener noreferrer" target='_blank' href='https://www.advancedconverter.com/map-tools/find-elevation-of-address'>this website</a> to calculate the elevation using the facility's address.
    </Trans>
  }
  on='click'
  position='bottom left'
  trigger={<Icon className={`float-right ${props.iconClass}`} name='question circle outline'/>}
/>;
};

FindFacilityElevationPopup.defaultProps = {
  iconClass: '',
};

export default FindFacilityElevationPopup;