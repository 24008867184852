/*
*  Table containing SD image uploads
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Checkbox} from "semantic-ui-react";
import {timestampSortHook, useShowDeleted} from "../../library/helpers";
import HookTable from "../../library/HookTable";


const SDImageTable = props => {
  const [showDeleted, handleHideDeleted] = useShowDeleted(false);

  const customRowClass = (rowInfo) => {
    if (rowInfo && rowInfo.original) {
      if (rowInfo.original.deleted_status) {
        return 'light-red-tr'
      } else if (rowInfo.original.preferred === true) {
        return 'light-green-tr'
      }
    }
  };
  const {
    t,
    group
  } = props;
  const columns = [
    {
      Header: t('File name'),
      accessor: 'file_name',
      id: 'file_name',
    },
    {
      Header: t('Description'),
      accessor: 'description',
      id: 'description',
    },
    {
      Header: t('Uploaded'),
      accessor: 'uploaded_timestamp',
      id: 'uploaded_timestamp',
      manualWidth: 185,
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss z")
    },
    {
      Header: t('Uploaded by'),
      accessor: 'upload_user',
      id: 'upload_user',
      manualWidth: 150,
    },
    {
      Header: t('Actions'),
      accessor: 'actions',
      id: 'actions',
      manualWidth: 60,
    }

  ];

  const floatLeftHeaderJSX = <div className="table-sub-header">
    {group === 'SterilisSuperUsers' || group === 'SterilisWasteTypeAdmin'?
      <Checkbox label={t('Show archived SD Images')}
                className={'slight-margin-bottom'}
                checked={showDeleted}
                onChange={handleHideDeleted}/> : null}
  </div>;

  return (
    <HookTable
      data={props.SDImageTableArray}
      columns={columns}
      howPagination={props.SDImageTableArray.length > 10}
      tableName='sdImageTable'
      showDeleted={showDeleted}
      floatLeftHeaderJSX={floatLeftHeaderJSX}
      defaultSortBy={{'id': 'uploaded_timestamp', desc: true}}
      customRowClass={true}
      customRowClassFunc={customRowClass}
    />
  )
};

export default translate('translations')(SDImageTable);
