/*
*  Component responsible for Updating Customer Role
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import '../css/EditADeviceOperator.css';
import SimpleLoader from './SimpleLoader';
import {Button, Header, Modal, TransitionablePortal, Dropdown} from 'semantic-ui-react'
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";
import {Trans} from "react-i18next";

const Auth = new AuthService();

class ChangeUserRoleModal extends React.Component {
  state = {
    allowModalToBeClosed: true,
    appLoading: false,
    customerID: null,
    facilityID: []
  };

  componentDidMount() {
    const { CurrentUser, isFacilityTab } = this.props;
    let facilityDropdown = [];
    const customerID = CurrentUser["employee"]["customer"]["id"];

    this.setState({
        modalTitle: this.modalTitle(),
        customerID: customerID,
    });

    if (!isFacilityTab) {
        facilityDropdown = this.props.customerFacilityDropdown.filter((item) => {
            return item.key === customerID;
        })[0].facilities;
    }

    this.setState({
        facilityDropdown,
    });


  }

  changeUserRole = () => {
    const { isFacilityTab, CurrentUser } = this.props;

    const { facilityID } = this.state;

    const employeeID = CurrentUser["employee"]["id"];

    const portaluserID = CurrentUser["id"];

    const groups = isFacilityTab ? "CustomerPortalUsers" : "FacilityAdmin";
    const groupname = isFacilityTab ? "Site Admin" : "Facility Admin";

    const putObjEmployee = {
        facility_ids: facilityID,
        upgradeOperator: true,
    };

    const portalUser = {
        group: groups,
        upgradeUser: true,
    };

    this.setState({
        appLoading: true,
        allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/employee/${employeeID}/`, {
        method: "PATCH",
        body: JSON.stringify(putObjEmployee),
    })
        .then((data) => {
            Auth.fetch(`/api/portal-user/${portaluserID}`, {
                method: "PATCH",
                body: JSON.stringify(portalUser),
            })
                .then((data) => {
                    this.props.fetchSiteAdmins(`?group=CustomerPortalUsers`).then(() => {
                        this.setState(
                            {
                                appLoading: false,
                                allowModalToBeClosed: true,
                            },
                            () => {
                                this.props.notifyGenericSuccess("changed", "to", groupname);
                                this.props.triggerChangeUserRoleModal(null);
                            }
                        );
                        this.props.refreshSiteAdmins(`?group=${groups}`).then(() => {
                            this.props.fetchDeviceOperators();
                        });
                    });
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    this.setState(
                        {
                            appLoading: false,
                            allowModalToBeClosed: true,
                        },
                        () => {
                            this.props.notifyGenericFailure("change", "to", groupname);
                            this.props.triggerChangeUserRoleModal(null);
                        }
                    );
                });
        })
        .catch((err) => {
            Sentry.captureException(err);
            this.setState(
                {
                    appLoading: false,
                    allowModalToBeClosed: true,
                },
                () => {
                    this.props.notifyGenericFailure("change", "to", groupname);
                    this.props.triggerChangeUserRoleModal(null);
                }
            );
        });

  }



  modalTitle = () => {
    const {t, changeRoleTo} = this.props;
    return <p>{t(`Change to ${changeRoleTo}`)}</p>
  };

  render() {
    const {
      appLoading,
      modalTitle,
      facilityDropdown,
      facilityID
    } = this.state;

    const {
      t,
      changeRoleTo,
      isFacilityTab
    } = this.props;


    return (
        <TransitionablePortal
            open={this.props.changeUserRoleModal}
            onOpen={() => setTimeout(() => document.body.classList.add("modal-fade-in"), 0)}
            transition={{ animation: "scale", duration: 500 }}>
            <Modal
                open={true}
                closeOnDimmerClick={this.state.allowModalToBeClosed}
                size="small"
                closeIcon={<Button className="close-modal-btn grey-btn">{t("Close")}</Button>}
                onClose={(event) => {
                    document.body.classList.remove("modal-fade-in");
                    this.props.triggerChangeUserRoleModal(null); //pass null to both edit ids when closing modal
                }}>
                {appLoading ? <SimpleLoader /> : null}
                <Header>
                    <h2>{modalTitle}</h2>
                </Header>
                <Modal.Content className="create-device-operator-cust">
                    <div className="short-modal-text center-text">
                        {isFacilityTab ? (
                            <Trans ns="translations" i18nKey="change-user-role-prompt">
                                Are you sure you want to change the user role to {changeRoleTo}?
                            </Trans>
                        ) : (
                            <Trans ns="translations" i18nKey="change-user-role-prompt">
                                Select Facility to change role to {changeRoleTo}
                            </Trans>
                        )}
                    </div>
                    {!isFacilityTab && (
                        <div className="short-modal-text center-text">
                            <div className="form-group">
                                <label className="fake-float">{t("Facility")}</label>
                                <Dropdown
                                    className="full-width"
                                    search
                                    multiple
                                    selection
                                    id="changeRoleFacility"
                                    options={facilityDropdown}
                                    value={facilityID}
                                    onChange={(none, data) => this.setState({ facilityID: data.value })}
                                />
                            </div>
                        </div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className="ster-btn"
                        value="Submit"
                        type="submit"
                        id="changeUserRoleBtn"
                        onClick={this.changeUserRole}
                        disabled={(!facilityID || facilityID.length === 0) && !isFacilityTab}>
                        {t("Submit")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </TransitionablePortal>
    );
  }
}

export default translate('translations')(ChangeUserRoleModal);
