/*
* An internal page to see a few portal stats
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import SimpleLoader from "../SimpleLoader";
import {composeHoc} from "../library/helpers";
import withAuth from "../withAuth";
import {Card} from 'semantic-ui-react'
import AuthService from "../AuthService";
import * as Sentry from "@sentry/browser";
import ActiveCycleTable from "./ActiveCycleTable";
import ActiveUsersTable from "./ActiveUsersTable";

const Auth = new AuthService();

class PortalStatus extends React.Component {
  state = {
    isLoading: false,
    portalStatus: false,
    lastActiveUsers: [],
    currentlyActiveCycles: [],
  };

  componentDidMount() {
    document.title = 'Portal Status';
    this.setState({isLoading: true});
    Auth.fetch(`/api/portal-status/`, {
      method: 'GET',
    }).then(data => {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.setState({
        isLoading: false,
        portalStatus: data,
        userTimezone,
        lastActiveUsers: data['last_five_active_users'],
        currentlyActiveCycles: data['currently_active_cycles'],
      });
    }).catch(err => {
      Sentry.captureException(err)
    });
  }

  render() {
    const {
      isLoading,
      portalStatus,
    } = this.state;

    return (
		<Card fluid>
			{isLoading ? <SimpleLoader /> : null}
			<Card.Header style={{ margin: "10px" }}>
				<h2>Portal Status</h2>
			</Card.Header>
			<Card.Content className="card-body">
				{portalStatus ? (
					<div className="portal-status-container">
						<div className="flex-row">
							<fieldset className="full-width medium-right-margin">
								<legend>Server settings</legend>
								<div className="display-config-item flex-block-item flex-row">
									<p className="left-label">Domain:</p>{" "}
									<p className="text-right-align">{portalStatus["domain"]}</p>
								</div>
								<div className="display-config-item flex-block-item flex-row">
									<p className="left-label">Environment:</p>{" "}
									<p className="text-right-align">{portalStatus["env"]}</p>
								</div>
								<div className="display-config-item flex-block-item flex-row">
									<p className="left-label">Database host:</p>{" "}
									<p className="text-right-align">{portalStatus["host"]}</p>
								</div>
								<div className="display-config-item flex-block-item flex-row">
									<p className="left-label">Database schema:</p>{" "}
									<p className="text-right-align">{portalStatus["schema"]}</p>
								</div>
								<div className="display-config-item flex-block-item flex-row">
									<p className="left-label">Database user:</p>{" "}
									<p className="text-right-align">{portalStatus["db_user"]}</p>
								</div>
								<div className="flex-row">
									<p>Allowed hosts:</p>{" "}
									<span className="">{portalStatus["allowed_hosts"].join(", ")}</span>
								</div>
								<div className="flex-row">
									<p className="left-label">Python version:</p>
									<p className="text-right-align">{portalStatus["python_version"]}</p>
								</div>
							</fieldset>

							<div className="full-width">
								<h3 className="orange-text padding-left">Active users</h3>
								<ActiveUsersTable
									lastActiveUsers={this.state.lastActiveUsers}
									userTimezone={this.state.userTimezone}
								/>
							</div>
						</div>
						<div className="active-cycle-container slight-margin-top">
							<h3 className="orange-text padding-left">Active cycles</h3>
							<ActiveCycleTable
								currentlyActiveCycles={this.state.currentlyActiveCycles}
								userTimezone={this.state.userTimezone}
							/>
						</div>
					</div>
				) : null}
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(translate('translations'), withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs','SterilisWasteTypeAdmin']))(PortalStatus);
