import React, { useState } from "react";
import { StyledDialog, StyledTableCell, StyledTableRow } from "../Configuration";
import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { WarningTwoTone } from "@mui/icons-material";
import AuthService from "src/components/AuthService";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { resetInitKey, selectSerial } from "../deviceInfoSlice";

const Auth = new AuthService();

const ResetInitializationKey = (props) => {
    const {
        modalDeviceID,
        modalSerial,
        modalCustomerName,
        modalFacilityName,
        resendActivationCodeModal,
        triggerResendActivationCodeModal,
        fromTable,
    } = props;

    const { t } = useTranslation("translations");
    const theme = useTheme();
    const dispatch = useDispatch();
    const { deviceTimezone, resetBiochallengeDialogOpen, selectedDeviceConfig, activationKey } = useSelector(
        (store) => store.deviceInfo
    );
    const loading = useSelector((store) => store.deviceInfo.loading.keyReset);

    const deviceSerial = useSelector(selectSerial);

    //const [loading, setLoading] = useState(false);
    const [deviceId, setDeviceId] = useState(modalDeviceID);
    //const [deviceSerial, setDeviceSerial] = useState(modalSerial);
    const [customerName, setCustomerName] = useState(modalCustomerName);
    const [facilityName, setFacilityName] = useState(modalFacilityName);
    const [activationCode, setActivationCode] = useState(null);
    const [resetComplete, setResetComplete] = useState(false);

    const notifySuccess = () =>
        enqueueSnackbar(`Successfully Reset Init Key For ${deviceSerial}`, { variant: "success" });

    const notifyFailure = () =>
        enqueueSnackbar(`Unable To Reset Init Key For ${deviceSerial}. Please Refresh And Try Again.`, {
            variant: "error",
        });

    const resetInitialiationKey = () => {
        const code = Math.floor(1000 + Math.random() * 9000);
        setActivationCode(code);

        dispatch(
            // @ts-ignore
            resetInitKey({
                deviceId: selectedDeviceConfig.device.id,
                body: {
                    reset_password_key: code,
                    reset_password_request_time: DateTime.now().toISO(),
                    serial_number: deviceSerial,
                    activated: 0,
                },
            })
        ).then((resp) => {
            if (resp.meta.requestStatus === "fulfilled") {
                setResetComplete(true);
                notifySuccess();
            } else if (resp.meta.requestStatue === "rejected") {
                notifyFailure();
            }
        });
    };

    return (
        <StyledDialog open={resendActivationCodeModal} maxWidth={"lg"} fullWidth>
            <DialogTitle>Reset Initialization Key For {deviceSerial}</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Stack alignItems={"center"}>
                        <Skeleton>
                            <Typography variant="h6">To Initialize The Device Enter The Following Code</Typography>
                        </Skeleton>
                        <Skeleton>
                            <Typography variant="h2">0000</Typography>
                        </Skeleton>
                    </Stack>
                ) : resetComplete ? (
                    <Stack spacing={2}>
                        <Typography textAlign={"center"} variant="h6" color={"textPrimary"}>
                            {t("To Initialize The Device Enter The Following Code")}:
                        </Typography>
                        <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                            <Typography variant="h2" color={"primary"}>
                                {activationCode}
                            </Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        <Typography textAlign={"center"}>
                            {t("Are you sure you want to reset the initialization key for this device?")}
                        </Typography>
                        <Divider />
                        <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                            <Typography variant="h6" color={"primary"}>
                                Device Serial:
                            </Typography>
                            <Typography variant="h6" color={"textPrimary"}>
                                {deviceSerial}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                            <Typography variant="h6" color={"primary"}>
                                Customer:
                            </Typography>
                            <Typography variant="h6" color={"textPrimary"}>
                                {customerName}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                            <Typography variant="h6" color={"primary"}>
                                Facility:
                            </Typography>
                            <Typography variant="h6" color={"textPrimary"}>
                                {facilityName}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Alert severity="warning">
                            {t("The device will need to be reinitialized with the new code before it can run a cycle.")}
                        </Alert>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button onClick={() => triggerResendActivationCodeModal(false)}>{t("Close")}</Button>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                        resetInitialiationKey();
                    }}>
                    {resetComplete ? t("Reset Key Again") : t("Reset Key")}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default ResetInitializationKey;
