import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import ToolBar from "./ToolBar";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";

// styles
const Main = styled("main")(({ theme }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
}));

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const Navigation = (props) => {
    const theme = useTheme();
    const location = useLocation();

    return location.pathname === "/login" ? (
        <Box sx={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
            <CssBaseline />
            {props.children}
        </Box>
    ) : (
        <Box
            sx={{
                display: "flex",
                bgcolor: theme.palette.background.default,
                height: "100vh",
            }}>
            <CssBaseline />
            <ToolBar />
            <Box width={"100%"}>
                <Offset />
                <Main theme={theme} sx={{ width: "100%" }}>
                    {props.children}
                </Main>
            </Box>
        </Box>
    );
};

export default Navigation;
