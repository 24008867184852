/*
*  Table which contains file request files available to download for a file request
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC all rights reserved.
*/
import React, {useState} from 'react';
import { withTranslation as translate} from "react-i18next";
import HookTable from "./library/HookTable";
import {AuthReq, timestampSortHook} from "./library/helpers";
import { Button } from 'semantic-ui-react'
import '../css/DownloadDeviceFileTable.css';
import RetrieveArchivedDataModal from "./RetrieveArchivedDataModal";

const DownloadDeviceFileTable = props => {
  const [retrieveArchivedDataModal, setRetrieveArchivedDataModal] = useState(false);

  const handleRetrieveArchivedDataModal = () => {
    if (retrieveArchivedDataModal){
      setRetrieveArchivedDataModal(false)
    } else setRetrieveArchivedDataModal(true)
  };

  const columns = [
    {
      Header: props.t('File Name'),
      accessor: 'file_name',
    },
    {
      Header: props.t('Content start date'),
      accessor: 'content_start_date',
      id: 'content_start_date',
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss a z"),
    },
    {
      Header: props.t('File size'),
      accessor: 'file_size',
      manualWidth: 100
    },
    // the ... spread is important because when showStatus is false, it'll spread the empty [] into the array
    // which will have no effect, which is what we desire. passing null or false will
    // cause tracebacks/an empty column in the table
      ...(props.showStatus ? [{
      Header: props.t('Status'),
      accessor: 'status',
      manualWidth: 150
    }] : []),
    {
      Header: props.t('Actions'),
      accessor: 'actions',
    },
  ];

  return <div>
    <AuthReq
      userGroup={props.group}
      requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers']}
    >
      <Button className='retrieve-btn' onClick={handleRetrieveArchivedDataModal} >{props.t("Restore archived data")}</Button>
    </AuthReq>
    <HookTable
    id='downloadDeviceFilesTable'
    data={props.deviceFileTableArray}
    columns={columns}
    tableName='downloadDeviceFilesTable'
    defaultSortBy={{id: 'content_start_date', desc: true}}
  />
  <AuthReq
          userGroup={props.group}
          requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers']}
        >
          {retrieveArchivedDataModal ?
            <RetrieveArchivedDataModal
              retrieveArchivedDataModal={retrieveArchivedDataModal}
              handleRetrieveArchivedDataModal={handleRetrieveArchivedDataModal}
              deviceSerial={props.deviceSerial}
              userEmail={props.userEmail}
              portalUserId={props.portalUserId}
            /> : null
          }
        </AuthReq>
  </div>
  
};


DownloadDeviceFileTable.defaultProps = {
  showStatus: true,
  deviceFileTableArray : [],
};
export default translate('translations')(DownloadDeviceFileTable);
