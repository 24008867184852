/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';

const FlexBlockItem = props => {
  return <div key={props.itemKey} id={props.id}
    className={`${props.containerClass} flex-block-item ${props.useInlineBlock ? 'inline-block-fbi' : ''}`}>
      {props.label}:
      <span className="flex-block-item-right-justify">{props.value}</span>
    {props.children}
    </div>
};

FlexBlockItem.defaultProps = {
  containerClass: '',
  useInlineBlock: false,
  label: '',
  value: '',
  itemKey: '',
};

export default FlexBlockItem;
