/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    return {
        mode: "light",
        common: {
            black: theme.colors?.darkPaper,
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800,
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800,
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark,
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark,
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark,
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark,
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            200: theme.colors?.grey200,
            300: theme.colors?.grey300,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark,
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper,
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100,
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault,
        },
        waste: {
            sharps: "rgba(255, 138, 128, 1)",
            redBags: "rgba(255, 82, 82, 1)",
            dialysis: "rgba(0, 175, 255, 1)",
            mixed: "rgba(255, 183, 77, 1)",
            yellowBags: "rgba(255, 241, 118, 1)",
            yellowBagsNoGrind: "rgba(253, 216, 53, 1)",
            cannabisMicrobial: "rgba(0, 230, 118, 1)",
            cannabisProduct: "rgba(129, 199, 132, 1)",
            biochallenge: "rgba(128, 203, 196, 1)",
            prv: "rgba(129, 212, 250, 1)",
            liquids: "rgba(2, 119, 189, 1)",
            softlab: "rgba(149, 117, 205, 1)",
            unknown: "rgba(96, 125, 139, 1)",
            glassSlides: "rgba(178, 235, 242, 1)",
            ungrindable: "rgba(240, 98, 146, 1)",
        },
        dueDate: {
            ok: "rgba(0, 230, 118, 1)",
            warning: "rgba(255, 255, 141, 1)",
            due: "rgba(255, 82, 82, 1)",
        },
    };
}
