/*
* Modal which houses the delete document button
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {getFileNameFromUrl} from "../library/helpers";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../SimpleLoader";

class DeleteDocumentModal extends React.Component{
  componentDidMount(){
  }
  render() {
    const {
      t,
      fileToDelete,
      modalLoading,
      allowModalToBeClosed
    } = this.props;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
          open={this.props.showDeleteDocumentModal}
          onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
          transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='mini'
          closeOnDimmerClick={allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerDeleteDocumentModal(null)
          }}
        >
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2 className='red'>{`${t('Delete File')}`}</h2>
          </Header>
          <Modal.Content>
            <h5 className='slight-padding-bottom'>{t('Are you sure you want to delete this file?')}</h5>
            {getFileNameFromUrl(fileToDelete['upload'])}
          </Modal.Content>
          <Modal.Actions>
            <Button className='red-btn'
                    value="Submit"
                    type="submit"
                    id='deleteFileBtn'
                    onClick={() => this.props.deleteFile(fileToDelete)}
                    >{t('Delete File')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(DeleteDocumentModal);
