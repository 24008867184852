/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import PropTypes from "prop-types";
import CycleStat from "../CycleStats/CycleStat";
import { getRoundedNumber, calculateDurations } from "src/components/library/helpers";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, Grid } from "@mui/material";
import { Box, ListItemButton } from "@mui/material";

const BurninItem = (props) => {
  const { test, name, idx, subBurninStatInternalNames } = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const duration_list = {
    scj_extension_duration_avg : 'scj_extension_avg_time',
    scj_retraction_duration_avg : 'scj_retraction_avg_time',
    shroud_open_duration_avg: 'shroud_open_avg_time',
    shroud_close_duration_avg: 'shroud_close_avg_time',
    heat_up_time_delta: 'heat_up_time_delta',
    grind_duration: 'grinder_time_seconds',
    open_toplid_duration:  'open_toplid_seconds',
    open_bottomlid_duration: 'open_bottomlid_seconds'

  }

  const calcInMinutes = {
    scj_extension_duration_avg : false,
    scj_retraction_duration_avg : false,
    shroud_open_duration_avg: false,
    shroud_close_duration_avg: false,
    heat_up_time_delta: true,
    grind_duration: false,
    open_toplid_duration:  false,
    open_bottomlid_duration: false

  }

  const getCycleStatLabel = (name) => {
    const { abnormalValuesForBurninTest, labelPrefix } = props;
    if (name in abnormalValuesForBurninTest) {
      return `${labelPrefix} ${abnormalValuesForBurninTest[name]["display_name"]}`;
    } else {
      return name;
    }
  };

  const getAbnormalValueDefinition = (name) => {
    const { abnormalValuesForBurninTest } = props;
    if (name in abnormalValuesForBurninTest) {
      return abnormalValuesForBurninTest[name];
    }
  };

  return (
    <React.Fragment>
      {subBurninStatInternalNames &&
      subBurninStatInternalNames[name] &&
      subBurninStatInternalNames[name].length > 0 ? (
        <Box>
           <ListItemButton onClick={handleClick} sx={{p:0}}>
            <CycleStat
              label={name in duration_list ? getCycleStatLabel(duration_list[name]) : getCycleStatLabel(name)}
              value={name in duration_list ? calculateDurations(test, name, calcInMinutes[name], true) : getRoundedNumber(test,name,props.usePercent ? 0 : 2)}
              abnormalValues={name in duration_list ? getAbnormalValueDefinition(duration_list[name]) : getAbnormalValueDefinition(name)}
              useInlineBlock={true}
              usePercent={props.usePercent}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{justifyContent: 'flex-end'}}>
            {subBurninStatInternalNames[name].map((subMetric, internalIdx) => {
              // some subMetrics can be defined as objects for additional functionality
              if (typeof subMetric === "object") {
                return (
                  <Grid container justifyContent='right'
                    key={`${subMetric["name"]}-${internalIdx}-${idx}`}
                  >
                    <Box width='90%'>
                      <CycleStat
                        label={getCycleStatLabel(subMetric["name"])}
                        value={subMetric["name"] in duration_list ? subMetric.format(calculateDurations(test, subMetric["name"], calcInMinutes[subMetric["name"]], true)) : subMetric.format(test[subMetric["name"]])}
                        abnormalValues={getAbnormalValueDefinition(
                          subMetric["name"]
                        )}
                      />
                    </Box>
                  </Grid>
                );
              } else {
                return (
                  <Grid container justifyContent='right' key={`${subMetric}-${internalIdx}-${idx}`}>
                    <Box width='90%' pl='10'>
                      <CycleStat
                        label={subMetric in duration_list ? getCycleStatLabel(duration_list[subMetric]) : getCycleStatLabel(subMetric)}
                        value={subMetric in duration_list ? calculateDurations(test, subMetric, calcInMinutes[subMetric], true) : getRoundedNumber(test, subMetric, 2)}
                        abnormalValues={subMetric in duration_list ? getAbnormalValueDefinition(duration_list[subMetric]) : getAbnormalValueDefinition(subMetric)}
                      />
                    </Box>
                  </Grid>
                );
              }
            })}
            </Collapse>
        </Box>
      ) : (
        <CycleStat
          label={name in duration_list ? getCycleStatLabel(duration_list[name]) : getCycleStatLabel(name)}
          value={name in duration_list ? calculateDurations(test, name, calcInMinutes[name], true) : getRoundedNumber(test, name, 2)}
          abnormalValues={name in duration_list ? getAbnormalValueDefinition(duration_list[name]) : getAbnormalValueDefinition(name)}
          useInlineBlock={props.useInlineBlock}
        />
      )}
    </React.Fragment>
  );
};

BurninItem.defaultProps = {
  labelPrefix: "",
  useInlineBlock: false,
  usePercent: false,
};

BurninItem.propTypes = {
  labelPrefix: PropTypes.string,
  useInlineBlock: PropTypes.bool,
  usePercent: PropTypes.bool,
};

export default BurninItem;
