/*
*  Main component for Assigning software to devices
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import {alphabeticalSort, composeHoc, sterilisCustomers} from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import {Button, Card, Dropdown} from "semantic-ui-react";
import ConfirmFirmwareModal from "./ConfirmFirmwareModal";
import {toast} from "react-toastify";
import queryString from "query-string";
import * as Sentry from "@sentry/browser";
import DistributorDropdownFilter from "../../view/distributors/DistributorDropdownFilter";

const Auth = new AuthService();

class AssignFirmwareToDevices extends React.Component {
	state = {
		isLoading: false,
		deviceDropdown: [],
		firmwareDropdown: [],
		selectedDeviceIds: [],
		selectedFirmwareString: null,
		confirmFirmwareModal: false,
		prettyDeviceDisplay: [],
		selectedFirmwareLinuxVersion: null,
		selectedDevice: false,
		selectedFirmware: false,
		distributorFilter: 0,
		distributorDropdownArray: [
			{
				key: 0,
				value: 0,
				text: this.props.t("All Distributors"),
			},
		],
	};

	notifySuccess = (firmware) =>
		toast(`Successfully assigned ${firmware} to the requested devices.`, {
			type: toast.TYPE.DEFAULT,
			autoClose: 15000,
		});

	notifyRemoveFirmwareSuccess = () =>
		toast(`Successfully removed software assignment from the requested devices.`, {
			type: toast.TYPE.DEFAULT,
			autoClose: 15000,
		});

	notifyFailure = () =>
		toast(`Failed to complete operation. Please refresh this page and try again.`, {
			type: toast.TYPE.ERROR,
			autoClose: 5000,
		});

	componentDidMount() {
		const { t } = this.props;
		document.title = t("Assign software to device(s)");
		this.setState({ isLoading: true });
		this.preparePage().then(() => {
			this.setState({ isLoading: false });
		});
	}

	preparePage = async () => {
		await this.fetchFirmware();
		await this.fetchDevices();
	};

	fetchFirmware = () => {
		return Auth.fetch(`/api/firmware/`, {
			method: "GET",
		})
			.then((data) => {
				const firmwareDropdown = data.map((firmware) => {
					return {
						key: firmware["id"],
						value: firmware["id"],
						linux_version_internal: firmware["linux_version_internal"],
						className: firmware["preferred"] ? "light-green-tr" : "",
						firmware_version: firmware["firmware_version"],
						linux_version: firmware["linux_version"],
						// text: `${firmware['firmware_version']} - ${firmware['linux_version']} | ${firmware['description']}`,
						search_string: `${firmware["firmware_version"]} - ${firmware["linux_version"]} ${firmware["description"]}`,
						text: (
							<div>
								{`${firmware["firmware_version"]} - ${firmware["linux_version"]}`}
								<br />
								{firmware["description"]}
							</div>
						),
					};
				});

				// reverse the array so that the latest firmwares are at the top
				const revFirmwareDropdown = firmwareDropdown.reverse();

				revFirmwareDropdown.push({
					key: "None",
					value: "None",
					text: `No software`,
					className: "light-red-tr",
					linux_version_internal: "None",
					firmware_version: "No software",
					search_string: "No software",
				});

				this.setState(
					{
						firmwareDropdown: revFirmwareDropdown,
					},
					() => {
						// if the user has selected a firmware through url
						if (queryString.parse(this.props.location.search).firmware) {
							const firmware = parseInt(queryString.parse(this.props.location.search).firmware, 10);

							this.handleFirmwareChange(null, { value: firmware });
						}
					}
				);
			})
			.catch((err) => {
				this.notifyFailure();
				Sentry.captureException(err);
			});
	};

	customerFacility = (deviceConfig) => {
		// if the customer & facility name are the same, eg hilo, just return the customer name
		if (deviceConfig["customer"] === deviceConfig["facility"]) {
			return deviceConfig["customer"];
		} else {
			return `${deviceConfig["customer"]} - ${deviceConfig["facility"]}`;
		}
	};

	deviceDropdownClass = (deviceConfig) => {
		if (deviceConfig["device"]["deleted"]) {
			return "light-grey-tr";
		} else if (deviceConfig["device"]["banned"]) {
			return "light-red-tr";
		} else if (deviceConfig["device"]["activated"] === false) {
			return "light-yellow-tr";
		}
	};

	fetchDevices = () => {
		const { group } = this.props;
		return Auth.fetch(`/api/export/device-configs/`, {
			method: "GET",
		})
			.then((data) => {
				// take shallow copy of array in state to begin building distributor dropdown array
				const distributorDropdownArray = [...this.state.distributorDropdownArray];

				const deviceDropdown = data
					.filter((deviceConfig) => {
						// Only give Factory Workers the Sterilis Customer device configs
						return group === "FactoryWorkers"
							? sterilisCustomers.includes(deviceConfig["customer_id"])
							: true;
					})
					.map((deviceConfig) => {
						// Create an array of unique distributors
						if (
							distributorDropdownArray.some((elem) => {
								return deviceConfig["distributor_id"] === elem["value"];
							}) === false
						) {
							distributorDropdownArray.push({
								key: deviceConfig["distributor_id"],
								value: deviceConfig["distributor_id"],
								text: deviceConfig["distributor"],
							});
						}

						const deviceCurrentFirmware = deviceConfig["current_firmware"]
							? deviceConfig["current_firmware"]
							: "No software";
						return {
							key: deviceConfig["id"],
							value: deviceConfig["id"],
							serial_number: deviceConfig["serial_number"],
							current_firmware: deviceCurrentFirmware,
							linux_version_internal: deviceConfig["device"]["linux_version_internal"],
							distributor_id: deviceConfig["distributor_id"],
							linux_version: deviceConfig["device"]["linux_version"],
							className: this.deviceDropdownClass(deviceConfig),
							customer_and_facility_names: `${deviceConfig["customer"]} ${deviceConfig["facility"]}`,
							display_string: `${deviceConfig["serial_number"]} ${this.customerFacility(deviceConfig)}`,
							search_string: `${deviceConfig["serial_number"]} ${this.customerFacility(deviceConfig)} ${
								deviceConfig["device"]["linux_version"]
							} ${deviceCurrentFirmware}`,
							text: (
								<div>
									{`${deviceConfig["serial_number"]} ${this.customerFacility(deviceConfig)}`}
									<br />
									{`Current software: ${deviceCurrentFirmware} | Linux version: ${deviceConfig["device"]["linux_version"]}`}
								</div>
							),
						};
					});
				deviceDropdown.sort((a, b) => alphabeticalSort(a, b, "customer_and_facility_names"));
				this.setState(
					{
						deviceDropdown,
						distributorDropdownArray,
					},
					() => {
						this.setState(
							{
								deviceDropdown,
							},
							() => {
								if (queryString.parse(this.props.location.search).device_config) {
									const deviceConfigID = [
										parseInt(queryString.parse(this.props.location.search).device_config, 10),
									];
									this.handleDeviceChange(null, { value: deviceConfigID });
								}
							}
						);
					}
				);
			})
			.catch((err) => {
				this.notifyFailure();
				Sentry.captureException(err);
			});
	};

	handleFirmwareChange = (event, data) => {
		const selectedFirmware = this.state.firmwareDropdown.find((fw) => {
			return fw["value"] === data.value;
		});
		this.setState({
			selectedFirmware: data.value,
			// get the firmware version string before the | delimiter
			selectedFirmwareString: selectedFirmware["firmware_version"],
			selectedFirmwareLinuxVersion: selectedFirmware["linux_version"],
			selectedFirmwareLinuxVersionInternal: selectedFirmware["linux_version_internal"],
		});
	};

	handleDeviceChange = (event, data) => {
		this.setState({ selectedDeviceIds: data.value }, () => {
			const deviceFromDropdown = this.state.deviceDropdown.filter((fw) => {
				return data.value.includes(fw["value"]);
			});
			if (this.state.selectedDeviceIds.length > 0) {
				this.setState({
					//getting the first selected device, to filter out same devices in dropdown.
					selectedDevice: deviceFromDropdown[0],
					selectedDeviceLinuxVersion: deviceFromDropdown[0]["linux_version"],
					selectedDeviceLinuxVersionInternal: deviceFromDropdown[0]["linux_version_internal"],
				});
			} else {
				this.setState({
					selectedDevice: false,
				});
			}
		});
	};

	triggerConfirmationModal = () => {
		const { selectedDeviceIds, deviceDropdown, selectedFirmwareString } = this.state;

		const prettyDeviceDisplay = deviceDropdown.reduce((acc, deviceDropdownItem) => {
			if (selectedDeviceIds.includes(deviceDropdownItem["value"])) {
				acc.push({
					serial: deviceDropdownItem["serial_number"],
					//string initially looks like 708-000131 | Current: v2.5.1.4
					// the first split is to find what's after the |, second split to get what's after the :
					current_fw: deviceDropdownItem["current_firmware"],
					selected_fw: selectedFirmwareString,
				});
			}
			return acc;
		}, []);
		this.setState((prevState) => {
			return {
				confirmFirmwareModal: !prevState.confirmFirmwareModal,
				prettyDeviceDisplay,
			};
		});
	};

	searchStringDropdownSearch = (options, query) => {
		return options.filter((option) => {
			return option["search_string"].toLowerCase().includes(query.toLowerCase());
		});
	};

	handleDistributorFilterChange = (event, data) => {
		this.setState({
			distributorFilter: data.value,
			// clear the selected device on distributor change
			selectedDevice: false,
			selectedDeviceIds: [],
		});
	};

	render() {
		const {
			isLoading,
			deviceDropdown,
			firmwareDropdown,
			selectedFirmwareString,
			selectedFirmware,
			selectedFirmwareLinuxVersion,
			selectedFirmwareLinuxVersionInternal,
			selectedDeviceLinuxVersion,
			selectedDeviceLinuxVersionInternal,
			selectedDevice,
			distributorFilter,
			distributorDropdownArray,
			selectedDeviceIds,
		} = this.state;
		const { t, group } = this.props;

		let filteredFirmware = firmwareDropdown;
		let filteredDevices = deviceDropdown;

		if (selectedDevice) {
			filteredFirmware = firmwareDropdown.filter((firmware) => {
				if (
					selectedDeviceLinuxVersionInternal === "4.19.58" ||
					selectedDeviceLinuxVersionInternal === "5.15.76"
				) {
					return (
						firmware["linux_version_internal"] === "None" ||
						firmware["linux_version_internal"] === selectedDeviceLinuxVersionInternal
					);
				} else {
					return true;
				}
			});
			//STR-586, added a filter to get the firmares as per selected device including "No Software" option.
			// .filter(fw => {
			// return selectedDeviceLinuxVersion.includes('Buster') ?
			//   typeof fw['linux_version'] === "undefined" || fw['linux_version'].includes('Buster') :
			//   typeof fw['linux_version']==="undefined" || !fw['linux_version'].includes('Buster')
			// });

			filteredDevices = deviceDropdown.filter((device) => {
				return selectedDevice["linux_version"] === device["linux_version"];
			});
		} else {
			filteredDevices = deviceDropdown;
		}

		if (selectedFirmware && firmwareDropdown.length > 0 && selectedFirmware !== "None") {
			const firmwareLinuxVersion = firmwareDropdown.find((firmware) => {
				return firmware["value"] === selectedFirmware;
			})["linux_version_internal"];
			filteredDevices = filteredDevices.filter((deviceConfig) => {
				if (
					selectedFirmwareLinuxVersionInternal === "4.9.35" ||
					selectedFirmwareLinuxVersionInternal === "5.15.76"
				) {
					return deviceConfig["linux_version_internal"] === firmwareLinuxVersion;
				} else {
					return true;
				}
			});

			filteredDevices = deviceDropdown.filter((device) => {
				return selectedFirmwareLinuxVersionInternal === device["linux_version_internal"];
			});
		}

		const firmwareDisplayString = selectedFirmwareString ? selectedFirmwareString : t("software");

		if (distributorFilter) {
			filteredDevices = filteredDevices.filter((row) => {
				return row["distributor_id"] === distributorFilter;
			});
		}

		return (
			<Card fluid>
				<Card.Header style={{ margin: "10px" }}>
					<h2>{t("Assign software")}</h2>
				</Card.Header>
				<Card.Content className="card-body">
					{isLoading ? <SimpleLoader /> : null}
					<div className="split-container even-split">
						<div className="wide-desc">
							<p>
								{t(
									"assign-firmware-to-devices.1",
									"First, use the software dropdown to select a version of software"
								)}
								.
							</p>
							<p>
								{t(
									"assign-firmware-to-devices.2",
									"Next, use the device dropdown to choose the device you'd like to assign the selected version of software to"
								)}
								.
							</p>
							<br />
							<p>
								{t(
									"assign-firmware-to-devices.3",
									"The device dropdown will say what software is currently assigned to the device"
								)}
								.{" "}
							</p>
							<p>
								<Trans ns="translations" i18nKey="assign-firmware-to-devices.4">
									When setting software on this page, you're setting the <strong>target</strong>{" "}
									software for a device.
								</Trans>
							</p>
							<p>
								{t(
									"assign-firmware-to-devices.5",
									"As soon as the device is able, it will attempt to upgrade its current software to the portal's target software"
								)}
								.
							</p>
							<p>
								<Trans ns="translations" i18nKey="assign-firmware-to-devices.6">
									Preferred software versions are highlighted in 
									<span className="light-green-tr">green</span>
								</Trans>
							</p>
							<br />
							<div>
								{selectedDeviceLinuxVersion && (
									<p>
										{t("Device linux version")}: <strong>{selectedDeviceLinuxVersion}</strong>
									</p>
								)}
								{selectedFirmwareLinuxVersion && (
									<p>
										{t("Software Linux version")}: <strong>{selectedFirmwareLinuxVersion}</strong>
									</p>
								)}
							</div>
							<br />
						</div>
						<div className="wide-no-flex-items">
							{
								<DistributorDropdownFilter
									group={group}
									onChange={this.handleDistributorFilterChange}
									selectedDistributor={distributorFilter}
									distributorDropdownArray={distributorDropdownArray}
									dropdownClass="wide-dropdown"
									containerClass="form-group"
								/>
							}
							<div className="form-group">
								<label>
									<h4 className="orange-text device-filter-header">{t("Select software version")}</h4>
								</label>
								<Dropdown
									className="wide-dropdown"
									search={this.searchStringDropdownSearch}
									selection
									onChange={this.handleFirmwareChange}
									fluid
									value={this.state.selectedFirmware}
									id="firmwareDropdown"
									options={filteredFirmware}
								/>
							</div>
							<div className="form-group">
								<label>
									<h4 className="orange-text device-filter-header">
										<Trans ns="translations" i18nKey="assign-firmware-to-devices.firmware_label">
											Select devices to assign {{ firmwareDisplayString }} to
										</Trans>
									</h4>
								</label>
								<Dropdown
									className="wide-dropdown"
									fluid
									selection
									multiple
									clearable
									search={this.searchStringDropdownSearch}
									options={filteredDevices}
									value={selectedDeviceIds}
									id="deviceDropdown"
									onChange={this.handleDeviceChange}
									renderLabel={(item) => item["display_string"]}
								/>
							</div>
							<Button
								className="ster-btn float-right"
								value="Submit"
								type="submit"
								disabled={!this.state.selectedDeviceIds.length > 0 || !this.state.selectedFirmware}
								onClick={this.triggerConfirmationModal}
							>
								<Trans ns="translations" i18nKey="assign-firmware-to-devices.assign_btn">
									Assign {{ firmwareDisplayString }}
								</Trans>
							</Button>
						</div>
					</div>
					{this.state.confirmFirmwareModal ? (
						<ConfirmFirmwareModal
							selectedFirmware={this.state.selectedFirmware}
							selectedDevices={this.state.selectedDeviceIds}
							preparePage={this.preparePage}
							triggerConfirmationModal={this.triggerConfirmationModal}
							confirmFirmwareModal={this.state.confirmFirmwareModal}
							notifySuccess={this.notifySuccess}
							notifyFailure={this.notifyFailure}
							prettyDeviceDisplay={this.state.prettyDeviceDisplay}
							selectedFirmwareString={selectedFirmwareString}
						/>
					) : null}
				</Card.Content>
			</Card>
		);
	}
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
      'DistributorAdmins', 'DistributorFSEs','SterilisWasteTypeAdmin'], 'internalPage'))(AssignFirmwareToDevices);
