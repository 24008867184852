/*
* A collection of custom React hooks
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/

import {useState, useEffect, useRef} from "react";
import {exists} from './helpers';

// https://usehooks.com/useLocalStorage/
export function useLocalStorage(key, initialValue) {

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });


  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export function useZoomParams(zoomParams, chartRef, timeList) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (zoomParams) {
      if (chartRef.current) {
        if (chartRef.current.chart) {
          const {
            min,
            max
          } = zoomParams;
          if (exists(min) && exists(max)) {
            chartRef.current.chart.xAxis.forEach(axis => {
              if (axis.min !== min && axis.max !== max) {
                axis.setExtremes(min, max);
              }
            });
          }
        }
      }
    }
  }, [zoomParams]);

  // this use effect is meant to mimic the "unmount" process when you go from one cycle to another
  // and it zooms out the chart, but for cycles of the same type
  useEffect(() => {
    if (hasMounted) {
      chartRef.current.chart.zoomOut();
    }
  }, [timeList])
}


const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};

export function usePageTransition() {
  const [visibility, setVisibility] = useState([false, true]);

  useEffect(() => {
    const handlePageHide = (e) => {
      console.log("page hiding");
      setVisibility([false, e.persisted]);
    };
    const handlePageShow = (e) => {
      console.log("page showing");
      setVisibility([true, e.persisted]);
    };
    const handleVisibilityChange = (e) => {
      console.log("handleVisibilityChange", e);
      console.log("document.visibilityState", document.visibilityState);
      setVisibility([true, e.persisted]);
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      // window.removeEventListener('pagehide', handlePageHide);
      // window.removeEventListener('pageshow', handlePageShow);
      window.removeEventListener('visibilitychange', handlePageShow);
    };
  }, []);

  return visibility;
}