/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import ParsedCycleTable from "./ParsedCycleTable";
import PropTypes from 'prop-types';
import CycleStats from "./CycleStats";
import ChartContainer from "./ChartContainer";
import {Link} from "react-router-dom";
import saveAs from "file-saver";
import * as Sentry from "@sentry/browser";
import AuthService from "../AuthService";
import get from "lodash/get";
import {Checkbox} from "semantic-ui-react";
import moment from "moment";
import AbnormalValuesPopup from "./AbnormalValuesPopup";
import BurninContainer from "./BurninContainer";
import {useLocalStorage} from '../library/hooks'
import {notifyFailureToast} from "../library/helpers";

const Auth = new AuthService();

const ParsedCycleView = props => {
  const [showMessages, setShowMessages] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState(false);
  const cycleType = get(selectedCycle, 'cycle_type', '');
  const cycleStartTime = moment.utc(get(selectedCycle, 'raw_start_time')).format("MM-DD HH:mm:ss z");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [showSteamCycleTrends, setShowSteamCycleTrends] = useState(false);
  const [showGrindCycleTrends, setShowGrindCycleTrends] = useState(false);
  const [showSharedCycleTrends, setShowSharedCycleTrends] = useState(false);

  const [hideAxisLabels, setHideAxisLabels] = useState(true);
  const [showAbnormalTrendValues, setShowAbnormalTrendValues] = useState(false);
  const [zoomParams, setZoomParams] = useState({});


  // on load
  useEffect(() => {
    // we need to find the earliest cycle to select, which will not necessarily the first one in the parsed log array
    // - especially during batch processing
    let lowestIdx = props.parsedLog.findIndex(cycle => cycle.cycle_type !== 'Notification');
    if (lowestIdx !== -1) {
      let tempLowestStartTime = moment(props.parsedLog[lowestIdx].start_time, "YYYY-MM-DD HH:mm:ss z");
      for (const [idx, cycle] of props.parsedLog.entries()) {
        if (cycle.cycle_type !== 'Notification') {
          const cycleStartTime = moment(cycle.start_time, "YYYY-MM-DD HH:mm:ss z");
          if (cycleStartTime.isBefore(tempLowestStartTime)) {
            lowestIdx = idx;
            tempLowestStartTime = cycleStartTime
          }
        }
      }
      const earliestCycle = props.parsedLog[lowestIdx];

      if (props.containsBurninTest) {
        setSelectedIndex(-1);

      } else {
        if (showMessages) {
          setSelectedIndex(lowestIdx);
        } else {
          const lowestIndexWithoutMessages = props.parsedLog
            .filter(cycle => cycle.cycle_type !== 'Notification')
            .findIndex(cycle => cycle.raw_start_time === earliestCycle.raw_start_time);
          setSelectedIndex(lowestIndexWithoutMessages);
        }
        setSelectedCycle(earliestCycle);
      }
    }
  }, [props.parsedLog.length]);

  useEffect(() => {
    // if it's not the default index, we need to do some searching to find the new index
    if (showMessages) {
      const idx = props.parsedLog.findIndex(cycle => cycle.raw_start_time === selectedCycle.raw_start_time);
      setSelectedIndex(idx);
    } else {
      if (selectedCycle) {
        const idx = props.parsedLog
          .filter(cycle => cycle.cycle_type !== 'Notification') // need to filter out notification messages to find true idx
          .findIndex(cycle => cycle.raw_start_time === selectedCycle.raw_start_time);
        setSelectedIndex(idx);
      }
    }
  }, [showMessages]);


  const fetchCycleFromFileID = React.useCallback((ethelFileID, cycleStartLineNumber, cycleEndLineNumber) => {
    props.setIsLoading(true);
    props.setLoadingText('Parsing log for selected cycle...');
    return Auth.fetch(`/log_parser/parse_cycle`, {
      method: 'POST',
      body: JSON.stringify({
        file_id: ethelFileID,
        file_type: "log",
        cycle_start_line_number: cycleStartLineNumber,
        cycle_end_line_number: cycleEndLineNumber,
      }),
    }).then(data => {
      const updatedParsedLog = [...props.parsedLog];
      // TODO: get all of this to work
      data['cycle_list'].forEach(updatedCycle => {
        const idxToUpdate = updatedParsedLog.findIndex(oldCycle => oldCycle.raw_start_time === updatedCycle.start_time);
        if (idxToUpdate !== -1) {
          const infoCount = updatedCycle['info_constants'] ? updatedCycle['info_constants'].length : 0;
          updatedParsedLog[idxToUpdate]['duty_cycle_list'] = updatedCycle['duty_cycle_list'];
          updatedParsedLog[idxToUpdate]['state_list'] = updatedCycle['state_list'];
          updatedParsedLog[idxToUpdate]['info_constants'] = updatedCycle['info_constants'];
          updatedParsedLog[idxToUpdate]['info_count'] = infoCount;
          updatedParsedLog[idxToUpdate]['time_list'] = updatedCycle['time_list'] ? updatedCycle['time_list'].map((time) => {
            return moment.utc(time).format("HH:mm:ss")
          }) : [];
          updatedParsedLog[idxToUpdate]['contains_series_data'] = true;
          updatedParsedLog[idxToUpdate]['string_contains_series_data'] = 'Yes';
          if (updatedCycle.cycle_type === 'SteamCycle') {
            updatedParsedLog[idxToUpdate]['th_list'] = updatedCycle['th_list'];
            updatedParsedLog[idxToUpdate]['tv_list'] = updatedCycle['tv_list'];
            updatedParsedLog[idxToUpdate]['tr_list'] = updatedCycle['tr_list'];
            updatedParsedLog[idxToUpdate]['pa_list'] = updatedCycle['pa_list'];
            updatedParsedLog[idxToUpdate]['ps_list'] = updatedCycle['ps_list'];
            updatedParsedLog[idxToUpdate]['pv_list'] = updatedCycle['pv_list'];
            updatedParsedLog[idxToUpdate]['i_list'] = updatedCycle['i_list'];
          }
          if (updatedCycle.cycle_type === 'GrindCycle') {
            updatedParsedLog[idxToUpdate]['ig_list'] = updatedCycle['ig_list'];
            updatedParsedLog[idxToUpdate]['pa_list'] = updatedCycle['pa_list'];
          }
        }
      });
      props.setIsLoading(false);
      props.setParsedLog(updatedParsedLog);
    }).catch(err => {
      notifyFailureToast();
      console.log(err);
      Sentry.captureException(err);
      props.setIsLoading(false);
    })
  }, []);

  const prettyCycleType = type => {
    if (type === 'SteamCycle') {
      return 'Steam Cycle'
    } else if (type === 'GrindCycle') {
      return 'Grind Cycle'
    } else if (type === 'BurninTest') {
      return 'Burn in Test'
    } else {
      return type
    }
  };

  const downloadFile = (s3FileID, fileName) => {
    props.setLoadingText(`Downloading ${fileName}`);
    props.setIsLoading(true);
    Auth.fetch(`/api/download-file/`, {
      method: 'POST',
      body: JSON.stringify({
        'file_id': s3FileID,
        'file_type': 'log'
      })
    }).then(response => response.blob())
      .catch(err => {
        Sentry.captureException(err)
        err.response.json().then(errInfo => {
          if(errInfo['message']){
            notifyFailureToast(errInfo['message']);
          }
        });
        this.setState({
          isLoading: false
        });
      })
      .then(blob => {
        props.setIsLoading(false);
        saveAs(blob, fileName);
      })
      .catch(err => Sentry.captureException(err))
  };

  const deviceInfo = deviceInfo => {
    return (
        <div>
            <div className="slight-margin-bottom">
                <h2>
                    <span className="orange-text">
                        {showBurninContainer ? "Burn-in Test Results" : prettyCycleType(cycleType)} |
                    </span>{" "}
                    {deviceInfo["serial_number"]}
                </h2>
            </div>
            <div className="slight-margin-bottom">
                <Link target="_blank" to={`/sa/dashboard/${deviceInfo["device_config_id"]}`}>
                    View Dashboard
                </Link>
            </div>
            <div className="slight-margin-bottom">
                <Link target="_blank" to={`/find-a-machine/${deviceInfo["device_config_id"]}`}>
                    View Device Info page
                </Link>
            </div>
            {props.fileID && (
                <div
                    className="clickable-text download-file-on-parser-container"
                    onClick={() => downloadFile(props.fileID, props.fileName)}>
                    Download file to PC
                </div>
            )}
        </div>
    );
  };

  const [trendCycleScore, setTrendCycleScore] = useState(true);
  const trendCycleScoreCheckbox = <Checkbox
    label='Score'
    onChange={() => setTrendCycleScore(!trendCycleScore)}
    checked={trendCycleScore}
  />;

  const [trendCycleDuration, setTrendCycleDuration] = useState(true);
  const trendCycleDurationCheckbox = <Checkbox
    label='Steam Cycle duration'
    onChange={() => setTrendCycleDuration(!trendCycleDuration)}
    checked={trendCycleDuration}
  />;

  const [trendTvAvgHold, setTrendTvAvgHold] = useState(false);
  const trendTvAvgHoldCheckbox = <Checkbox
    label='TV Avg During Hold'
    onChange={() => setTrendTvAvgHold(!trendTvAvgHold)}
    checked={trendTvAvgHold}
  />;

  const [trendTPDiff, setTrendTPDiff] = useState(true);
  const trendTPDiffCheckbox = <Checkbox
    label='TP Diff'
    onChange={() => setTrendTPDiff(!trendTPDiff)}
    checked={trendTPDiff}
  />;

  const [trendFlowSeconds, setTrendFlowSeconds] = useState(false);
  const trendFlowSecondsCheckbox = <Checkbox
    label='Flow Seconds'
    onChange={() => setTrendFlowSeconds(!trendFlowSeconds)}
    checked={trendFlowSeconds}
  />;

  const [trendPumpdownDuration, setTrendPumpdownDuration] = useState(false);
  const trendPumpdownDurationCheckbox = <Checkbox
    label='Steam Pumpdown Time (mins)'
    onChange={() => setTrendPumpdownDuration(!trendPumpdownDuration)}
    checked={trendPumpdownDuration}
  />;

  const [trendDrainingTime, setTrendDrainingTime] = useState(false);
  const trendDrainingTimeCheckbox = <Checkbox
    label='Draining Time (mins)'
    onChange={() => setTrendDrainingTime(!trendDrainingTime)}
    checked={trendDrainingTime}
  />;

  const [trendHeatUpTime, setTrendHeatUpTime] = useState(false);
  const trendHeatUpTimeCheckbox = <Checkbox
    label='Heat Up Time (mins)'
    onChange={() => setTrendHeatUpTime(!trendHeatUpTime)}
    checked={trendHeatUpTime}
  />;

  const [trendHeatUpRateDifference, setHeatUpRateDifference] = useState(false);
  const trendHeatUpRateDifferenceCheckbox = <Checkbox
    label='Heat Up Rate Difference'
    onChange={() => setHeatUpRateDifference(!trendHeatUpRateDifference)}
    checked={trendHeatUpRateDifference}
  />;


  const [trendPumpdownTimeouts, setTrendPumpdownTimeouts] = useState(false);
  const trendPumpdownTimeoutsCheckbox = <Checkbox
    label='Pumpdown Timeouts'
    onChange={() => setTrendPumpdownTimeouts(!trendPumpdownTimeouts)}
    checked={trendPumpdownTimeouts}
  />;

  const [trendTVStart, setTrendTVStart] = useState(false);
  const trendTVStartCheckbox = <Checkbox
    label='TV Start'
    onChange={() => setTrendTVStart(!trendTVStart)}
    checked={trendTVStart}
  />;

  const [trendTVMax, setTrendTVMax] = useState(false);
  const trendTVMaxCheckbox = <Checkbox
    label='TV Max'
    onChange={() => setTrendTVMax(!trendTVMax)}
    checked={trendTVMax}
  />;

  const [trendTRAverage, setTrendTRAverage] = useState(false);
  const trendTRAverageCheckbox = <Checkbox
    label='TR Avg'
    onChange={() => setTrendTRAverage(!trendTRAverage)}
    checked={trendTRAverage}
  />;

  const [trendPVStart, setTrendPVStart] = useState(false);
  const trendPVStartCheckbox = <Checkbox
    label='PV Start'
    onChange={() => setTrendPVStart(!trendPVStart)}
    checked={trendPVStart}
  />;

  const [trendPVMin, setTrendPVMin] = useState(false);
  const trendPVMinCheckbox = <Checkbox
    label='PV Min'
    onChange={() => setTrendPVMin(!trendPVMin)}
    checked={trendPVMin}
  />;

  const [trendPVMax, setTrendPVMax] = useState(false);
  const trendPVMaxCheckbox = <Checkbox
    label='PV Max'
    onChange={() => setTrendPVMax(!trendPVMax)}
    checked={trendPVMax}
  />;

  const [trendPVAverageDuringHold, setTrendPVAverage] = useState(true);
  const trendPVAverageCheckbox = <Checkbox
    label='PV Avg during hold'
    onChange={() => setTrendPVAverage(!trendPVAverageDuringHold)}
    checked={trendPVAverageDuringHold}
  />;

  const [trendStalls, setTrendStalls] = useState(false);
  const trendStallsCheckbox = <Checkbox
    label='Stalls'
    onChange={() => setTrendStalls(!trendStalls)}
    checked={trendStalls}
  />;

  const [trendGrindDuration, setTrendGrindDuration] = useState(true);
  const trendGrindDurationCheckbox = <Checkbox
    label='Grind duration'
    onChange={() => setTrendGrindDuration(!trendGrindDuration)}
    checked={trendGrindDuration}
  />;

  const [trendJack, setTrendJack] = useState(false);
  const trendJackCheckbox = <Checkbox
    label='Jack'
    onChange={() => setTrendJack(!trendJack)}
    checked={trendJack}
  />;

  const [trendNumberStalls, setTrendNumberStalls] = useState(true);
  const trendNumberStallsCheckbox = <Checkbox
    label='Number of Stalls'
    onChange={() => setTrendNumberStalls(!trendNumberStalls)}
    checked={trendNumberStalls}
  />;

  const [trendTries, setTrendTries] = useState(false);
  const trendTriesCheckbox = <Checkbox
    label='Tries'
    onChange={() => setTrendTries(!trendTries)}
    checked={trendTries}
  />;

  const [trendForwardDuration, setTrendForwardDuration] = useState(false);
  const trendForwardDurationCheckbox = <Checkbox
    label='Forward Duration'
    onChange={() => setTrendForwardDuration(!trendForwardDuration)}
    checked={trendForwardDuration}
  />;

  const [trendReverseDuration, setTrendReverseDuration] = useState(false);
  const trendReverseDurationCheckbox = <Checkbox
    label='Reverse Duration'
    onChange={() => setTrendReverseDuration(!trendReverseDuration)}
    checked={trendReverseDuration}
  />;


  const [trendDutyCycleAvg, setTrendDutyCycleAvg] = useState(true);
  const trendDutyCycleAvgCheckbox = <Checkbox
    label='Duty Cycle Avg'
    onChange={() => setTrendDutyCycleAvg(!trendDutyCycleAvg)}
    checked={trendDutyCycleAvg}
  />;

  const [trendDutyCycleStart, setTrendDutyCycleStart] = useState(true);
  const trendDutyCycleStartCheckbox = <Checkbox
    label='Duty Cycle Start'
    onChange={() => setTrendDutyCycleStart(!trendDutyCycleStart)}
    checked={trendDutyCycleStart}
  />;

  const [trendDutyCycleEnd, setTrendDutyCycleEnd] = useState(true);
  const trendDutyCycleEndCheckbox = <Checkbox
    label='Duty Cycle End'
    onChange={() => setTrendDutyCycleEnd(!trendDutyCycleEnd)}
    checked={trendDutyCycleEnd}
  />;

  const cycleTrendOptions = <React.Fragment>
    {
      (props.batchProcessing || cycleType === 'SteamCycle') &&
      <Checkbox
        className='digital-output-toggle'
        toggle
        label='Show Steam Cycle Trends'
        onChange={() => setShowSteamCycleTrends(!showSteamCycleTrends)}
        checked={showSteamCycleTrends}
      />
    }
    {
      (props.batchProcessing || cycleType === 'GrindCycle') &&
      <Checkbox
        className='digital-output-toggle'
        toggle
        label='Show Grind Cycle Trends'
        onChange={() => setShowGrindCycleTrends(!showGrindCycleTrends)}
        checked={showGrindCycleTrends}
      />
    }
    <Checkbox
      className='digital-output-toggle'
      toggle
      label='Show Shared Cycle Trends'
      onChange={() => setShowSharedCycleTrends(!showSharedCycleTrends)}
      checked={showSharedCycleTrends}
    />

    {(showSteamCycleTrends || showGrindCycleTrends || showSharedCycleTrends) && <React.Fragment>
      <Checkbox
        className='digital-output-toggle'
        toggle
        label='Show Axis Labels'
        onChange={() => setHideAxisLabels(!hideAxisLabels)}
        checked={hideAxisLabels}
      />
      <Checkbox
        className='digital-output-toggle'
        toggle
        label='Color Abnormal Trend Values'
        onChange={() => setShowAbnormalTrendValues(!showAbnormalTrendValues)}
        checked={showAbnormalTrendValues}
      />
    </React.Fragment>
    }

    <AbnormalValuesPopup
      abnormalValues={props.abnormalValues}
      containsBurninTest={props.containsBurninTest}
    />
  </React.Fragment>;


  const [showBurninContainer, setShowBurninContainer] = useState(false);
  useEffect(() => {
    setShowBurninContainer(props.containsBurninTest);
  }, [props.containsBurninTest]);

  const [scrollOnCycleClick, _setScrollOnCycleClick] = useLocalStorage('scrollOnCycleClick', false);
  const setScrollOnCycleClick = React.useCallback(value => {
    _setScrollOnCycleClick(value)
  }, []);

  useEffect(() => {
    // when the cycle changes, scroll user to top of page
    if (scrollOnCycleClick) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [selectedCycle]);

  const [showOverlayCycleSelect, setShowOverlayCycleSelect] = useState(false);

  useEffect(() => {
    if (showOverlayCycleSelect === false) {
      _setOverlayedCycles([])
    }
  }, [showOverlayCycleSelect]);

  const [overlayedCycles, _setOverlayedCycles] = useState([]);
  const setOverlayedCycles = tableRow => _setOverlayedCycles(() => {
    return tableRow.length > 0 ? tableRow.map(row => row['original']) : [];
  });
  const parserLeftHeader = Object.keys(props.deviceInfo).length > 0 ? deviceInfo(props.deviceInfo) :
    <h2 className='orange-text'>{prettyCycleType(cycleType)}</h2>;

  return <div>
    {showBurninContainer ?
      <BurninContainer
        abnormalValuesObj={props.abnormalValuesObj}
        parsedLog={props.parsedLog}
        containsBurninTest={props.containsBurninTest}
        setSelectedCycle={setSelectedCycle}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        batchProcessing={props.batchProcessing}
        fetchCycleFromFileID={fetchCycleFromFileID}
        setBatchProcessing={props.setBatchProcessing}
        initialLoadFromBatch={props.initialLoadFromBatch}
        showMessages={showMessages}
        setShowMessages={setShowMessages}
        abnormalValues={props.abnormalValues}
        deviceSerial={get(props.deviceInfo, 'serial_number', '')}
        setShowBurninContainer={setShowBurninContainer}
        showBurninContainer={showBurninContainer}
        parserLeftHeader={parserLeftHeader}
        scrollOnCycleClick={scrollOnCycleClick}
        setScrollOnCycleClick={setScrollOnCycleClick}
        zoomParams={zoomParams}
        setZoomParams={setZoomParams}
        setIsLoading={props.setIsLoading}
        setLoadingText={props.setLoadingText}
        test7CycleIDs={props.test7CycleIDs}

      /> :
      <React.Fragment>

<div className='split-container even-split'>
          <div className={props.containsBurninTest ? 'burnin-parser-table-container' : 'parser-table-container'}>
            <ParsedCycleTable
              data={props.parsedLog}
              setSelectedCycle={setSelectedCycle}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              batchProcessing={props.batchProcessing}
              fetchCycleFromFileID={fetchCycleFromFileID}
              setBatchProcessing={props.setBatchProcessing}
              initialLoadFromBatch={props.initialLoadFromBatch}
              showMessages={showMessages}
              setShowMessages={setShowMessages}
              showBurninContainer={showBurninContainer}
              setShowBurninContainer={setShowBurninContainer}
              showOverlayCycleSelect={showOverlayCycleSelect}
              setShowOverlayCycleSelect={setShowOverlayCycleSelect}
              cycleType={cycleType}
              overlayedCycles={overlayedCycles}
              setOverlayedCycles={setOverlayedCycles}
              setZoomParams={setZoomParams}

              onRowSelectCallback={setOverlayedCycles}
              showOnRowSelectCheckboxes={showOverlayCycleSelect}

            />
          </div>
          <div
            className={`flex-dir-column cycle-stats-container`}>
            {(cycleType === 'SteamCycle' || cycleType === 'GrindCycle') &&
            <CycleStats
              selectedCycle={selectedCycle}
              batchProcessing={props.batchProcessing}

              cycleTrendOptions={cycleTrendOptions}

              showSteamCycleTrends={showSteamCycleTrends}
              showGrindCycleTrends={showGrindCycleTrends}
              showSharedCycleTrends={showSharedCycleTrends}

              trendCycleScoreCheckbox={trendCycleScoreCheckbox}
              trendCycleDurationCheckbox={trendCycleDurationCheckbox}
              trendTvAvgHoldCheckbox={trendTvAvgHoldCheckbox}
              trendTPDiffCheckbox={trendTPDiffCheckbox}

              trendFlowSecondsCheckbox={trendFlowSecondsCheckbox}
              trendPumpdownDurationCheckbox={trendPumpdownDurationCheckbox}
              trendDrainingTimeCheckbox={trendDrainingTimeCheckbox}
              trendHeatUpTimeCheckbox={trendHeatUpTimeCheckbox}
              trendPumpdownTimeoutsCheckbox={trendPumpdownTimeoutsCheckbox}
              trendHeatUpRateDifferenceCheckbox={trendHeatUpRateDifferenceCheckbox}

              trendTVStartCheckbox={trendTVStartCheckbox}
              trendTVMaxCheckbox={trendTVMaxCheckbox}
              trendTRAverageCheckbox={trendTRAverageCheckbox}
              trendPVStartCheckbox={trendPVStartCheckbox}
              trendPVMinCheckbox={trendPVMinCheckbox}
              trendPVMaxCheckbox={trendPVMaxCheckbox}
              trendPVAverageCheckbox={trendPVAverageCheckbox}


              trendGrindDurationCheckbox={trendGrindDurationCheckbox}
              trendStallsCheckbox={trendStallsCheckbox}
              trendJackCheckbox={trendJackCheckbox}
              trendNumberStallsCheckbox={trendNumberStallsCheckbox}
              trendTriesCheckbox={trendTriesCheckbox}
              trendForwardDurationCheckbox={trendForwardDurationCheckbox}
              trendReverseDurationCheckbox={trendReverseDurationCheckbox}

              trendDutyCycleAvgCheckbox={trendDutyCycleAvgCheckbox}
              trendDutyCycleStartCheckbox={trendDutyCycleStartCheckbox}
              trendDutyCycleEndCheckbox={trendDutyCycleEndCheckbox}

              abnormalValuesObj={props.abnormalValuesObj}
            />
            }
          </div>
        </div>

        {parserLeftHeader}
        {
          selectedCycle && <ChartContainer
            batchProcessing={props.batchProcessing}
            setShowBurninContainer={setShowBurninContainer}
            selectedCycle={selectedCycle}

            cycleType={cycleType}
            cycleStartTime={cycleStartTime}

            cycleStartTimes={props.cycleStartTimes}
            steamCycleStartTimes={props.steamCycleStartTimes}
            grindCycleStartTimes={props.grindCycleStartTimes}

            scoreTrend={props.steamCycleTrends['scoreTrend']}
            tvAvgHoldTrend={props.steamCycleTrends['tvAvgHoldTrend']}
            tpDiffTrend={props.steamCycleTrends['tpDiffTrend']}
            durationTrend={props.steamCycleTrends['durationTrend']}

            flowSecondsTimeTrend={props.steamCycleTrends['flowSecondsTimeTrend']}
            steamPumpdownTimeTrend={props.steamCycleTrends['steamPumpdownTimeTrend']}
            drainTimeTrend={props.steamCycleTrends['drainTimeTrend']}
            heatUpTimeTrend={props.steamCycleTrends['heatUpTimeTrend']}
            pumpdownTimeoutTrend={props.steamCycleTrends['pumpdownTimeoutTrend']}
            tvStartTrend={props.steamCycleTrends['tvStartTrend']}
            tvMaxTrend={props.steamCycleTrends['tvMaxTrend']}
            trAvgTrend={props.steamCycleTrends['trAvgTrend']}
            tvAvgDuringHoldTrend={props.steamCycleTrends['tvAvgDuringHoldTrend']}
            pvStartTrend={props.steamCycleTrends['pvStartTrend']}
            pvMinTrend={props.steamCycleTrends['pvMinTrend']}
            pvMaxTrend={props.steamCycleTrends['pvMaxTrend']}
            pvAvgDuringHoldTrend={props.steamCycleTrends['pvAvgDuringHoldTrend']}
            heatUpRateDifferenceTrend={props.steamCycleTrends['heatUpRateDifferenceTrend']}


            grindTriesTrend={props.grindCycleTrends['triesTrend']}
            grindJackTrend={props.grindCycleTrends['jackTrend']}
            grindStallsTrend={props.grindCycleTrends['stallsTrend']}
            grindDurationTrend={props.grindCycleTrends['durationTrend']}
            forwardDurationTrend={props.grindCycleTrends['forwardDurationTrend']}
            reverseDurationTrend={props.grindCycleTrends['reverseDurationTrend']}
            numberStallsTrend={props.grindCycleTrends['numberStallsTrend']}

            facilityType={props.deviceInfo['facility_type']}
            infoCount={selectedCycle['info_count']}

            showSteamCycleTrends={showSteamCycleTrends}
            showGrindCycleTrends={showGrindCycleTrends}
            showSharedCycleTrends={showSharedCycleTrends}
            hideAxisLabels={hideAxisLabels}


            trendCycleScore={trendCycleScore}
            trendCycleDuration={trendCycleDuration}
            trendTvAvgHold={trendTvAvgHold}
            trendTPDiff={trendTPDiff}
            trendFlowSeconds={trendFlowSeconds}
            trendPumpdownDuration={trendPumpdownDuration}
            trendDrainingTime={trendDrainingTime}
            trendHeatUpTime={trendHeatUpTime}
            trendPumpdownTimeouts={trendPumpdownTimeouts}
            trendTVStart={trendTVStart}
            trendTVMax={trendTVMax}
            trendTRAverage={trendTRAverage}
            trendPVStart={trendPVStart}
            trendPVMin={trendPVMin}
            trendPVMax={trendPVMax}
            trendPVAverageDuringHold={trendPVAverageDuringHold}
            trendHeatUpRateDifference={trendHeatUpRateDifference}

            trendGrindDuration={trendGrindDuration}
            trendStalls={trendStalls}
            trendJack={trendJack}
            trendNumberStalls={trendNumberStalls}
            trendTries={trendTries}
            trendForwardDuration={trendForwardDuration}
            trendReverseDuration={trendReverseDuration}

            abnormalValuesObj={props.abnormalValuesObj}
            showAbnormalTrendValues={showAbnormalTrendValues}

            trendDutyCycleAvg={trendDutyCycleAvg}
            trendDutyCycleStart={trendDutyCycleStart}
            trendDutyCycleEnd={trendDutyCycleEnd}

            dutyCycleAvgTrend={props.sharedCycleTrends['dutyCycleAvgTrend']}
            dutyCycleStartTrend={props.sharedCycleTrends['dutyCycleStartTrend']}
            dutyCycleEndTrend={props.sharedCycleTrends['dutyCycleEndTrend']}

            scrollOnCycleClick={scrollOnCycleClick}
            setScrollOnCycleClick={setScrollOnCycleClick}
            zoomParams={zoomParams}
            setZoomParams={setZoomParams}

            overlayedCycles={overlayedCycles}

          />
        }


      </React.Fragment>
    }
  </div>
};

ParsedCycleView.defaultProps = {
  parsedLog: [],
  steamCycleTrends: {},
  grindCycleTrends: {},
  sharedCycleTrends: {},
  abnormalValues: [],
  abnormalValuesObj: {},
  deviceInfo: {},
  cycleStartTimes: [],
  steamCycleStartTimes: [],
  grindCycleStartTimes: [],
  test7CycleIDs: [],
  fileID: '',
  fileName: '',
  batchProcessing: false,
  initialLoadFromBatch: false,
  containsBurninTest: false,
};

ParsedCycleView.propTypes = {
  parsedLog: PropTypes.array,
  steamCycleTrends: PropTypes.object,
  grindCycleTrends: PropTypes.object,
  sharedCycleTrends: PropTypes.object,
  abnormalValues: PropTypes.array,
  deviceInfo: PropTypes.object,
  abnormalValuesObj: PropTypes.object,
  cycleStartTimes: PropTypes.array,
  steamCycleStartTimes: PropTypes.array,
  grindCycleStartTimes: PropTypes.array,
  test7CycleIDs: PropTypes.array,
  fileID: PropTypes.string,
  fileName: PropTypes.string,
  setLoadingText: PropTypes.func,
  setIsLoading: PropTypes.func,
  batchProcessing: PropTypes.bool,
  initialLoadFromBatch: PropTypes.bool,
  containsBurninTest: PropTypes.bool,

};


export default ParsedCycleView;
