import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeviceCycles, setComplianceReport } from "../deviceDashboardSlice";

const ReportTypeToggle = (props) => {
    const dispatch = useDispatch();
    // @ts-ignore
    const { deviceConfigId } = useSelector((store) => store.devicedashboard);
    const [reportType, setReportType] = React.useState("comprehensive");

    const handleReportType = (event, newType) => {
        if (newType !== null && newType !== reportType) {
            if (newType === "comprehensive") {
                dispatch(setComplianceReport(false));
            } else {
                dispatch(setComplianceReport(true));
            }
            setReportType(newType);
        }
    };

    useEffect(() => {
        if (deviceConfigId) {
            // @ts-ignore
            dispatch(fetchDeviceCycles());
        }
    }, [reportType]);

    return (
        <ToggleButtonGroup
            color="secondary"
            value={reportType}
            sx={{ mr: 1 }}
            exclusive
            onChange={handleReportType}
            aria-label="report type">
            <ToggleButton value="comprehensive" aria-label="comprehensive">
                Comprehensive
            </ToggleButton>
            <ToggleButton value="compliance" aria-label="compliance">
                Compliance
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

ReportTypeToggle.propTypes = {};

export default ReportTypeToggle;
