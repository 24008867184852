/*
*  The Active Cycle table, present in the Portal Status & Sterilis Homepage
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Link} from "react-router-dom";
import moment from "moment";
import {serialSortHook, calculateCycleTime, prettyWastetypes, timestampSortHook} from "../library/helpers";
import SimpleLoader from "../SimpleLoader";
import 'details-polyfill';
import HookTable from "../library/HookTable";

const cycleStatus = (cycle) => {
  const cycleStatus = cycle['status'];
  if (cycleStatus.length > 1) {
    return (
      <details>
        <summary>{cycleStatus.length} statuses</summary>
        {
          cycleStatus.map((status, idx) => {
            return <p
              key={`${cycle['id']}-${idx}`}
              className={status['cycle_state_display']['cycle_failed'] ? 'red' : ''}
            >
              {status['cycle_state_display']['status_code']} - {status['cycle_state_display']['message']}
            </p>
          })

        }
      </details>
    )
  } else if (cycleStatus.length === 1) {
    const singleCycleStatus = cycleStatus[0];
    return <p
      key={`${cycle['id']}`}
      className={singleCycleStatus['cycle_state_display']['cycle_failed'] ? 'red' : ''}
    >
      {singleCycleStatus['cycle_state_display']['status_code']} - {singleCycleStatus['cycle_state_display']['message']}
    </p>
  } else {
    return <p key={`${cycle['id']}`}>
      No cycle status for cycle id {cycle['id']}
    </p>
  }
};


const ActiveCycleTable = props => {

  const activeCycleColumns = [
    {
      Header: props.t('Device Serial'),
      accessor: 'device_serial',
      sortType: serialSortHook,
      // manualWidth: 110,
    },
    {
      Header: props.t('Time started'),
      accessor: 'time_started',
      id: 'time_started',
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss z")
    },
    {
      Header: props.t('Cycle time'),
      accessor: 'time_elapsed',
      // manualWidth: 100,
    },
    {
      Header: props.t('Cycle type'),
      accessor: 'cycle_type',
      // manualWidth: 125,
    },
    {
      Header: props.t('Software version'),
      accessor: 'firmware',
    },
    {
      Header: props.t('Device Operator'),
      accessor: 'operator',
      // manualWidth: 125,
    },
    {
      Header: props.t('Cycle status'),
      accessor: 'status',
    },
  ];
  const activeCycles = props['currentlyActiveCycles'].map(cycle => {
    return {
        device_serial: (
            <Link target="_blank" to={`/find-a-machine?serial=${cycle["device_serial"]}`}>
                {cycle["device_serial"]}
            </Link>
        ),
        time_started: (
            <Link target="_blank" to={`/sa/dashboard/${cycle["device_config_id"]}`}>
                {moment.utc(cycle["time_started"]).tz(props.userTimezone).format("YYYY-MM-DD HH:mm:ss z")}
            </Link>
        ),
        // 'time_elapsed': calculateCycleTimeForActiveCycle(cycle),
        time_elapsed: calculateCycleTime(cycle["time_started"], null, cycle["latest_event"], cycle["latest_status"]),
        cycle_type:
            cycle["cycle_type"] === "sterilization"
                ? prettyWastetypes(cycle["waste_type"])
                : prettyWastetypes(cycle["cycle_type"]),
        firmware: cycle["firmware"],
        operator: cycle["operator"],
        status: cycleStatus(cycle),
    };
  });

  return <React.Fragment>
    {props.isLoading ? (
      <SimpleLoader/>
    ) : null}
    <HookTable
      columns={activeCycleColumns}
      data={activeCycles}
      defaultSortBy={{
        id: `time_started`,
        desc: true
      }}
    />
  </React.Fragment>
};

ActiveCycleTable.defaultProps = {
  currentlyActiveCycles : []
};

export default translate('translations')(ActiveCycleTable);
