import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fetchDeviceFiles, selectSerial, setArchiveDialogOpen } from "../deviceInfoSlice";
import SubCard from "src/components/material/Cards/SubCard";
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Skeleton, Tooltip, useTheme } from "@mui/material";
import { DocumentScannerTwoTone, FileDownloadTwoTone, SearchTwoTone } from "@mui/icons-material";
import { bytesToAppropriateUnit, capitalizeFirstLetter, isNotEmptyObject } from "src/components/library/helpers";
import AuthService from "src/components/AuthService";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { Link as RouterLink, useHistory } from "react-router-dom";
import RestoreArchivedDataDialog from "./RestoreArchivedDataDialog";

const Auth = new AuthService();

const DeviceFiles = (props) => {
    const { t } = useTranslation("translations");
    const { deviceFileRequests, deviceFiles, selectedDeviceConfig, archiveDialogOpen, deviceTimezone } = useSelector(
        (store) => store.deviceInfo
    );
    const loading = useSelector((store) => store.deviceInfo.loading.files);
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const [fileName, setFileName] = useState(null);
    //const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const { showStatus } = props;

    const Toolbar = () => {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    const downloadFile = useCallback(
        (data) => () => {
            const fileName = data.fileName;
            const fileNameWithSerial = `${fileName.slice(0, fileName.indexOf("-"))}-${
                selectedDeviceConfig?.device?.serial_number
            }${fileName.slice(fileName.indexOf("-"))}`;

            setDownloading(true);
            setFileName(fileNameWithSerial);

            Auth.fetch(`/api/download-file/`, {
                method: "POST",
                body: JSON.stringify({
                    file_id: data.s3FileId,
                    file_type: "log",
                }),
            })
                .then((resp) => resp.blob())
                .catch((err) => console.error(err))
                .then((blob) => {
                    setDownloading(false);
                    saveAs(blob, fileNameWithSerial);
                })
                .catch((err) => console.error(err));
        },
        [selectedDeviceConfig]
    );

    const columns = [
        {
            headerName: t("File Name"),
            field: "fileName",
            headerAlign: "center",
            headerClassName: "config-logs-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: t("Content Start Date"),
            field: "contentStartDate",
            valueFormatter: ({ value }) =>
                value && DateTime.fromISO(value, { zone: "utc" }).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("File Size"),
            field: "fileSize",
            valueGetter: ({ value }) => bytesToAppropriateUnit(value),
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("File Type"),
            field: "fileType",
            valueGetter: ({ value }) => capitalizeFirstLetter(value),
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("Actions"),
            field: "actions",
            type: "actions",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            getActions: (params) => {
                const actions = [];
                if (params.row.s3FileId) {
                    actions.push(
                        <GridActionsCellItem
                            label="Download File"
                            size="large"
                            onClick={downloadFile(params.row, selectedDeviceConfig)}
                            icon={
                                <Tooltip title={t("Download File")}>
                                    <FileDownloadTwoTone sx={{ fontSize: 25 }} />
                                </Tooltip>
                            }
                        />
                    );
                    if (
                        isNotEmptyObject(selectedDeviceConfig) &&
                        selectedDeviceConfig?.device?.linux_version !== "5.15.76" &&
                        params.row?.fileType === "ethel"
                    ) {
                        actions.push(
                            <GridActionsCellItem
                                label="View In Log Parser"
                                size="large"
                                onClick={() => history.push(`/log-parser?file_id=${params.row?.s3FileId}`)}
                                icon={
                                    <Tooltip title={t("View In Log Parser")}>
                                        <DocumentScannerTwoTone sx={{ fontSize: 25 }} />
                                    </Tooltip>
                                }
                            />
                        );
                    }
                    return actions;
                } else {
                    return [];
                }
            },
            flex: 1,
        },
    ];

    showStatus &&
        columns.push({
            headerName: t("Status"),
            field: "status",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        });

    useEffect(() => {
        if (selectedDeviceConfig) {
            dispatch(fetchDeviceFiles(selectedDeviceConfig?.device?.id));
        }
    }, [selectedDeviceConfig]);

    return (
        <SubCard
            sx={{
                width: "100%",
                "& .config-logs-table--header": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                },
            }}>
            {selectedDeviceConfig && (
                <>
                    <Button
                        onClick={() => dispatch(setArchiveDialogOpen(!archiveDialogOpen))}
                        sx={{ mb: 2 }}
                        color="secondary"
                        variant="outlined">
                        Restore Archived Data
                    </Button>
                    <RestoreArchivedDataDialog open={archiveDialogOpen} />
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <DataGrid
                            disableSelectionOnClick
                            autoHeight
                            columns={columns}
                            rows={deviceFiles}
                            loading={loading || downloading}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "contentStartDate", sort: "desc" }],
                                },
                            }}
                            sx={{
                                fontSize: 14,
                                padding: 2,
                                borderRadius: 4,
                            }}
                            components={{ Toolbar: Toolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    )}
                </>
            )}
        </SubCard>
    );
};

DeviceFiles.propTypes = {};

export default DeviceFiles;
