/*
*  Table which houses the documents which have been uploaded to the Portal.
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {serialSortHook} from "../../library/helpers";
import HookTable from "../../library/HookTable";


const ExportDeviceConfigTable = props => {
  const {
    t,
    deviceConfigs
  } = props;
  const columns = [
    {
      Header: t('Device Serial'),
      accessor: 'serial_number',
      manualWidth: 120,
      sortType: serialSortHook,
    },
    {
      Header: t('Facility'),
      accessor: 'facility_name',
    },
    {
      Header: t('Customer'),
      accessor: 'customer_name'
    },
    {
      Header: t('Software'),
      accessor: 'firmware_version'
    },
    {
      Header: t('Helpful links'),
      accessor: 'links'
    }
  ];

  return (
    <HookTable
      id='exportDeviceConfigTable'
      data={deviceConfigs}
      columns={columns}
      tableName='exportDeviceConfigTable'
      defaultSortBy={{id: 'customer_name'}}
      floatLeftHeaderJSX={props.exportCSVButton}
    />
  )
};

export default translate('translations')(ExportDeviceConfigTable);
