import React from "react";

export const calcTitleMargin = plotBandLength => plotBandLength > 0 ? 175 : 0;
export const calcSingleChartHeight = plotBandLength => plotBandLength > 0 ? 625 : 400;
export const calcCombinedChartHeight = plotBandLength => plotBandLength > 0 ? 800 : 600;
export const dutyCycleConstants = [
  {
    value: 690,
    color: 'black',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: '690 - Air compressor recovers if off',
      y: 20,
    }
  },
  {
    value: 720,
    color: 'black',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: '720 - Air compressor turns off',
      y: -5,
    }
  },
];

export const dutyCycleYAxisLabel = {
  id: 'dutyCycleYAxisLabel',
  title: {
    text: 'Duty Cycle',
    enabled: false,
    style: {
      color: 'black'
    }
  },
  labels: {
    format: `{value}`,
    enabled: false,
    style: {
      color: 'black'
    }
  }
};

export const dutyCycleAvgSeries = ({dutyCycleAvgTrend, showAbnormalTrendValues, abnormalDutyCycleAvgValues}) => {
  return [{
    name: "Duty Cycle Average",
    data: dutyCycleAvgTrend,
    yAxis: 'dutyCycleAvgYAxisLabel',
    color: 'MediumAquaMarine',
    zones: [...showAbnormalTrendValues ? [
      {
        value: abnormalDutyCycleAvgValues['minimum_value'],
        color: 'red'
      },
      {
        value: abnormalDutyCycleAvgValues['maximum_value'],
        color: 'MediumAquaMarine'
      },
      {
        color: 'red'
      },
    ] : []]
  }];
};

export const dutyCycleStartSeries = ({dutyCycleStartTrend, showAbnormalTrendValues, abnormalDutyCycleStartValues}) => {
  return [{
    name: "Duty Cycle Start",
    data: dutyCycleStartTrend,
    yAxis: 'dutyCycleStartYAxisLabel',
    color: 'MediumSeaGreen',
    zones: [...showAbnormalTrendValues ? [
      {
        value: abnormalDutyCycleStartValues['minimum_value'],
        color: 'red'
      },
      {
        value: abnormalDutyCycleStartValues['maximum_value'],
        color: 'MediumSeaGreen'
      },
      {
        color: 'red'
      },
    ] : []]
  }]
};


export const dutyCycleEndSeries = ({dutyCycleEndTrend, showAbnormalTrendValues, abnormalDutyCycleEndValues}) => {
  return [{
    name: "Duty Cycle End",
    data: dutyCycleEndTrend,
    yAxis: 'dutyCycleEndYAxisLabel',
    color: 'SeaGreen',
    zones: [...showAbnormalTrendValues ? [
      {
        value: abnormalDutyCycleEndValues['minimum_value'],
        color: 'red'
      },
      {
        value: abnormalDutyCycleEndValues['maximum_value'],
        color: 'SeaGreen'
      },
      {
        color: 'red'
      },
    ] : []]
  }]
};
export const dutyCycleAvgYAxis = ({hideAxisLabels}) => {
  return [{
    id: 'dutyCycleAvgYAxisLabel',
    title: {
      text: 'Duty Cycle Avg',
      style: {
        color: 'MediumAquaMarine'
      }
    },
    labels: {
      format: `{value}`,
      enabled: hideAxisLabels,
      style: {
        color: 'MediumAquaMarine'
      }
    },
  }];
};
export const dutyCycleStartYAxis = ({hideAxisLabels}) => {
  return [{
    id: 'dutyCycleStartYAxisLabel',
    title: {
      text: 'Duty Cycle Start',
      style: {
        color: 'MediumSeaGreen'
      }
    },
    labels: {
      format: `{value}`,
      enabled: hideAxisLabels,
      style: {
        color: 'MediumSeaGreen'
      }
    },
  }]
};
export const dutyCycleEndYAxis = ({hideAxisLabels}) => {
  return [{
    id: 'dutyCycleEndYAxisLabel',
    title: {
      text: 'Duty Cycle End',
      style: {
        color: 'SeaGreen'
      }
    },
    labels: {
      format: `{value}`,
      enabled: hideAxisLabels,
      style: {
        color: 'SeaGreen'
      }
    },
  }]
};

export const cycleStatHeader = ({is_successful: isSuccessful}) => {
  return <span className={`${isSuccessful ? 'green-text' : 'red-text'}`}>
      {isSuccessful ? 'Success' : 'Fail'}
    </span>
};

export const inRangeInclusive = (value, min, max) => {
  return ((value <= max) && (value >= min));
};

export const zoomButtonOptions = {
  // theme: {
  //   display: 'block'
  // }
};

export const tempConstants = [
  {
    value: 325,
    color: 'fuchsia',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: 'Tr = 325°C Heaters Off'
    }
  },
  {
    value: 170,
    color: 'darkblue',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: 'Th = 170°C Inject Water / Heaters Off',
    }
  },
  {
    value: 136,
    color: 'dodgerblue',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: 'Tv = 136°C Heaters Off',
    }
  },
  {
    value: 134,
    color: 'black',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: 'Tv = 134°C Cooking',
      y: 20,
    }
  },
];

export const pressureConstants = [
  {
    value: 55,
    color: 'lime',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: 'Pv = 55 PSI(g)',
      align: 'right',
    }
  },
  {
    value: 52.3,
    color: 'lime',
    dashStyle: 'shortdash',
    width: 2,
    label: {
      text: 'Pv = 52.3 PSI(g)',
      align: 'right',
      y: 15,
    }
  },
];

export const tempUnit = '°C';
export const presUnit = 'PSI(g)';

export const defaultPlotOptions = {
  series: {
    boostThreshold: 200,
    marker: {
      enabledThreshold: 2
    }
  },
};

export const chartTooltip = (chart, timeList, cycleStartTime, plotBands) => {
  const containsOverlay = chart.points.some(
    point => point.series.userOptions.isOverlayedCycle
  );
  const originalCycleInPoint = containsOverlay ? chart.points.some(
    point => point.series.userOptions.overlayedCycleNumber === 0
  ) : false;

  let overlayedCounter = 0;
  const timestamp = containsOverlay ? 'Multiple Cycles Selected' : (timeList[chart.x] || chart.x);

  const originalCycleHeader = originalCycleInPoint ? `<b>Original Cycle</b><br/><b>${cycleStartTime}</b><br/><br/>` : '';

  let previousIdx = 0;
  let container = chart.points.reduce(function (s, point) {
    let tooltip = ``;
    const {
      overlayedCycleNumber,
      cycleStartTime,
      isOverlayedCycle
    } = point.series.userOptions;
    // for each "point" in the chart, there are N iterations where N is the # of series in chart
    // we only need to find if a digital output/state is on once here
    let activePlotBand = false;
    if( point.point.index !== previousIdx && plotBands.length > 0){
      activePlotBand = plotBands
        .filter(band => band['from'] <= point.point.index && band['to'] >= point.point.index)
        .reduce((string, activeBand) => {
          string += `${activeBand.name}<br/>`;
          return string;
        }, '');
      previousIdx =  point.point.index;
    }
    if (containsOverlay) {
      if (isOverlayedCycle && (overlayedCycleNumber !== overlayedCounter)) {
        tooltip += `</div>`;
        tooltip += `<div class="tooltip-sub-container">`;
        tooltip += (`<b>Overlayed Cycle</b><br/><b>${cycleStartTime}</b><br/><br/>`);
        overlayedCounter = overlayedCycleNumber;
      }
    }
    if(activePlotBand){
      tooltip += `<br/>${activePlotBand}<br/>`
    }
    tooltip += `<span style="color:${point.color}">\u25CF</span>  `;
    tooltip += (`${point.series.name}: ${point.y.toFixed(2)} ${point.series.userOptions.unit}`);
    return s + tooltip + '</br>'
  }, `<b>${timestamp}</b><br/><div style='display: flex; flex-direction: row; '><div class="tooltip-sub-container">${originalCycleHeader}`);
  container += `</div></div>`;
  return container;

};

export const getTempSeries = (
  thList,
  tvList,
  trList,
  dutyCycleList,
  plotDutyCycle,
  overlayedCycleNumber = 0,
  isOverlayedCycle = false,
  cycleStartTime = '',
) => {
  return [
    {
      name: "Th",
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime,
      data: thList,
      yAxis: 0,
      color: 'darkblue',
      unit: tempUnit,
    },
    {
      name: "Tv",
      data: tvList,
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime,
      yAxis: 0,
      color: 'dodgerblue',
      unit: tempUnit,
    },
    {
      name: "Tr",
      data: trList,
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime,
      yAxis: 0,
      color: 'fuchsia',
      unit: tempUnit,
    },
    ...plotDutyCycle ? getDutyCycleSeries(
      dutyCycleList,
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime) : []
  ]
};

export const getPresSeries = (
  paList,
  psList,
  pvList,
  dutyCycleList,
  plotDutyCycle,
  overlayedCycleNumber = 0,
  isOverlayedCycle = false,
  cycleStartTime = '',
  isCombined = false,
) => {
  return [
    {
      name: "Pa",
      overlayedCycleNumber: overlayedCycleNumber,
      isOverlayedCycle: isOverlayedCycle,
      cycleStartTime,
      data: paList,
      yAxis: isCombined ? 1 : 0,
      unit: presUnit,
      color: 'lightseagreen',
    },
    {
      name: "Ps",
      overlayedCycleNumber: overlayedCycleNumber,
      isOverlayedCycle: isOverlayedCycle,
      cycleStartTime,
      data: psList,
      yAxis: isCombined ? 1 : 0,
      unit: presUnit,
      color: 'royalblue',
    },
    {
      name: "Pv",
      overlayedCycleNumber: overlayedCycleNumber,
      isOverlayedCycle: isOverlayedCycle,
      cycleStartTime,
      data: pvList,
      yAxis: isCombined ? 1 : 0,
      unit: presUnit,
      color: 'lime',
    },
    ...plotDutyCycle ? getDutyCycleSeries(
      dutyCycleList,
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime) : []
  ]
};

export const getDutyCycleSeries = (
  dutyCycleList,
  overlayedCycleNumber = 0,
  isOverlayedCycle = false,
  cycleStartTime = '',
) => {
  return [{
    name: "Duty Cycle",
    overlayedCycleNumber,
    isOverlayedCycle,
    cycleStartTime,
    data: dutyCycleList,
    unit: '',
    yAxis: 'dutyCycleYAxisLabel',
    color: 'black',
  }]
};

export const getCombinedSeries = (
  thList,
  tvList,
  trList,
  paList,
  psList,
  pvList,
  dutyCycleList,
  plotDutyCycle,
  overlayedCycleNumber = 0,
  isOverlayedCycle = false,
  cycleStartTime = '',
  includeTemp = true,
  includePressure = true
) => {
  return [
    ...includeTemp ? getTempSeries(
      thList,
      tvList,
      trList,
      [],
      false, // always pass false in combined, in combined duty cycle has it's own series
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime
    ) : [],
    ...includePressure ? getPresSeries(
      paList,
      psList,
      pvList,
      [],
      false, // always pass false in combined, in combined duty cycle has it's own series
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime,
      true
    ) : [],
    ...plotDutyCycle ? getDutyCycleSeries(
      dutyCycleList,
      overlayedCycleNumber,
      isOverlayedCycle,
      cycleStartTime) : []
  ];
};


export const getChartLegend = (plotDutyCycle, isCombined = true) => {
  return {
    width: plotDutyCycle ? (isCombined ? 700 : 400) : (isCombined ? 600 : 300),
    itemWidth: 100
  }
};