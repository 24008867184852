/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useRef, useEffect, useState} from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {
  calcSingleChartHeight,
  calcTitleMargin,
  chartTooltip,
  defaultPlotOptions,
  zoomButtonOptions
} from "./ChartHelpers";
import {useZoomParams} from "../library/hooks";
import moment from "moment";

import boost from 'highcharts/modules/boost';

boost(Highcharts);

const CurrentChart = props => {
  const {t} = useTranslation('translations');
  const titleMargin = calcTitleMargin(props.plotBands.length);
  const singleChartHeight = calcSingleChartHeight(props.plotBands.length);

  const getCurrentSeries = (iList, combineTPGraphs, overlayedCycleNumber = 0,
                            isOverlayedCycle = false, cycleStartTime = ''
  ) => {
    return [
      {
        name: "Current",
        data: iList,
        unit: 'I(A)',
        overlayedCycleNumber,
        isOverlayedCycle,
        cycleStartTime,
      },
      ...combineTPGraphs ? [{
        name: "Current",
        color: 'lightblue',
        yAxis: 1,
        data: iList,
        visible: false,
        showInLegend: false
      }] : [],
    ];
  };

  const currentSeries = React.useMemo(() => {
    const selectedCycle = getCurrentSeries(
      props.iList,
      props.combineTPGraphs,
    );
    if (props.overlayedCycles.length > 0) {
      const {
        cycleType
      } = props;
      const overlayedSeries = props.overlayedCycles.flatMap((overlayedCycle, idx) => {
        const counter = idx + 1;
        const cycleStartTime = moment.utc(overlayedCycle['raw_start_time']).format("MM-DD HH:mm:ss z");
        return getCurrentSeries(
          cycleType === 'GrindCycle' ? overlayedCycle.ig_list : overlayedCycle.i_list,
          props.combineTPGraphs,
          counter,
          true,
          cycleStartTime);
      });
      return [...selectedCycle, ...overlayedSeries];
    }
    return selectedCycle

  }, [props.overlayedCycles, props.timeList, props.showAbnormalSeriesValues, props.combineTPGraphs]);
  const tooltip = function () {
    return chartTooltip(this, props.timeList, props.cycleStartTime, props.plotBands);
  };

  const [currentChartOptions, setCurrentChartOptions] = useState({
    chart: {
      zoomType: 'x',
      height: singleChartHeight,
      ignoreHiddenSeries: false,
      resetZoomButton: zoomButtonOptions
    },
    plotOptions: defaultPlotOptions,
    title: {
      text: `${props.customTitlePrefix && props.customTitlePrefix} Current (A)`,
      margin: titleMargin,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      animation: false,
      formatter: tooltip
    },
    legend: {
      width: props.plotDutyCycle ? 200 : 100,
      itemWidth: 100
    },
    xAxis: [{
      categories: props.timeList,
      crosshair: true,
      plotBands: props.plotBands,
      plotLines: props.overlayInfo && props.infoConstants,
      events: {
        afterSetExtremes: function (event) {
          if (event.trigger === 'zoom' && props.setZoomParams) {
            props.setZoomParams({min: event.min, max: event.max})
          }
        }
      }
    }],
    yAxis: [
        {
        title: {
          text: t('Current (A)'),
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: `{value} A`,
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
      },
        ...props.combineTPGraphs ? [{
          title: {
            text: t('Current (A)'),
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          labels: {
            format: `{value} Amps`,
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: true,
        }] : []
      ],
    credits: false,
    series: currentSeries,
  });

  useEffect(() => {
    const updatedOptions = {
      chart: {
        height: singleChartHeight,
      },
      title: {
        margin: titleMargin,
      },
      plotOptions: defaultPlotOptions,
      legend: {
        width: props.plotDutyCycle ? 200 : 100,
        itemWidth: 100
      },
      tooltip: {
        formatter: tooltip
      },
      xAxis: [{
        categories: props.timeList,
        plotBands: props.plotBands,
        plotLines: props.overlayInfo && props.infoConstants,
      }],
      yAxis: [
        {
        title: {
          text: t('Current (A)'),
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: `{value} A`,
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
      },
        ...props.combineTPGraphs ? [{
          title: {
            text: t('Current (A)'),
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          labels: {
            format: `{value} Amps`,
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: true,
        }] : []
      ],
      series: currentSeries,
    };
    setCurrentChartOptions(updatedOptions)
  }, [props.timeList, props.plotBands, props.overlayInfo, props.infoConstants,
    props.burninChart, currentSeries, props.combineTPGraphs]);
  const currentRef = useRef();

  useZoomParams(props.zoomParams, currentRef, props.timeList);
  return <HighchartsReact
    ref={currentRef}
    highcharts={Highcharts}
    options={currentChartOptions}
  />
};

CurrentChart.defaultProps = {
  iList: [],
  timeList: [],
  plotBands: [],
  overlayInfo: false,
  overlayStates: false,
  combineTPGraphs: true,
  abnormalIValues: {},
  showAbnormalSeriesValues: false,
  customTitlePrefix: '',
  overlayedCycles: [],
  isTPACurrentGraph: false,

};

CurrentChart.propTypes = {
  iList: PropTypes.array,
  timeList: PropTypes.array,
  plotBands: PropTypes.array,
  overlayInfo: PropTypes.bool,
  overlayStates: PropTypes.bool,
  isTPACurrentGraph: PropTypes.bool,
  combineTPGraphs: PropTypes.bool,
  abnormalIValues: PropTypes.object,
  showAbnormalSeriesValues: PropTypes.bool,
  customTitlePrefix: PropTypes.string,
  zoomParams: PropTypes.object,
  setZoomParams: PropTypes.func,
  overlayedCycles: PropTypes.array,
};

export default CurrentChart;
