/*
*  Component which houses the Cycle Summary chart, detailing the # of XYZ waste type cycles a device has ran
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

class CycleSummary extends React.Component {
  state = {
    options: {},
  };

  componentDidMount() {
    this.setState({
      dashboardResized: this.props.dashboardResized
    });
    if (this.props.deviceCycles) {
      this.populateChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceCycles !== this.props.deviceCycles) {
      this.populateChart();
    }
  }

  checkForNonZeroArray = (array) => {
    const maxNumber = Math.max.apply(null, array);
    return (maxNumber !== 0);
  };

  /*
  *   All of this logic was copy & pasted from the v1 implementation.
  * */
  calculateCycleSummary = (deviceCycles) => {
    const {
      userTimezone,
      t
    } = this.props;
    const unknownArray = []; // blank
    const dialysisArray = []; // Dialysis
    const mixedArray = [];  // Mixed
    const redBagArray = []; // Red Bags
    const sharpsArray = []; // Sharps
    const yellowBagArray = []; // Yellow bags
    const cannabisArray = []; // Cannabis Microbial Waste
    const cannabisProductArray = []; // Cannabis Product Waste
    const liquidsProductArray = []; // Liquids
    const softwasteProductArray = []; // Soft Lab Waste
    const xAxisDateArray = []; // Date array for x axis
    const yellowBagNoGrindArray = []; // Yellow bags no grind

    for (const cycle in deviceCycles) {
      const date = moment.utc(deviceCycles[cycle].time_started).tz(userTimezone).format("YYYY-MM-DD");
      if (!xAxisDateArray.includes(date)) {
        xAxisDateArray.push(date);
      }
      const indexOfRecentPush = xAxisDateArray.indexOf(date);

      if (deviceCycles[cycle]['waste_type'] === 'dialysis') {
        let currentCycleCount = dialysisArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        dialysisArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'mixed') {
        let currentCycleCount = mixedArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        mixedArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'red_bags') {
        let currentCycleCount = redBagArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        redBagArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'sharps') {
        let currentCycleCount = sharpsArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        sharpsArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'unknown') {
        let currentCycleCount = unknownArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        unknownArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'yellow_bags') {
        let currentCycleCount = yellowBagArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        yellowBagArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'yellow_bags_nogrind') {
        let currentCycleCount = yellowBagNoGrindArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        yellowBagNoGrindArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'cannabis') {
        let currentCycleCount = cannabisArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        cannabisArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'cannabis_product') {
        let currentCycleCount = cannabisProductArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        cannabisProductArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'liquids') {
        let currentCycleCount = liquidsProductArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        liquidsProductArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'soft_lab_waste'){
        let currentCycleCount = softwasteProductArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount ? ++currentCycleCount : 1;
        softwasteProductArray[indexOfRecentPush] = currentCycleCount;
      }

    }

    //Since Highcharts requires array primitives and we need our X axis array and our Y axis arrays to line up index wise
    //We need to fill every Y axis array index with 0s where they do not currently have a value
    //The or syntax is just shorthand for if(!sharpsArray[day])
    for (let day in xAxisDateArray) {
      sharpsArray[day] = sharpsArray[day] || 0;
      redBagArray[day] = redBagArray[day] || 0;
      mixedArray[day] = mixedArray[day] || 0;
      dialysisArray[day] = dialysisArray[day] || 0;
      unknownArray[day] = unknownArray[day] || 0;
      yellowBagArray[day] = yellowBagArray[day] || 0;
      yellowBagNoGrindArray[day] = yellowBagNoGrindArray[day] || 0;
      cannabisArray[day] = cannabisArray[day] || 0;
      cannabisProductArray[day] = cannabisProductArray[day] || 0;
      liquidsProductArray[day] = liquidsProductArray[day] || 0;
      softwasteProductArray[day] = softwasteProductArray[day] || 0;
    }

    const cycleSummarySeries = [];

    if (this.checkForNonZeroArray(sharpsArray)) {
      cycleSummarySeries.push({
        name: t('Sharps'),
        type: 'column',
        color: '#ff9492',
        data: sharpsArray.slice(0),
        tooltip: {
          valueSuffix: t(' sharps cycles')
        }
      })
    }
    if (this.checkForNonZeroArray(redBagArray)) {
      cycleSummarySeries.push({
        name: t('Red bags'),
        type: 'column',
        color: '#cb0000',
        data: redBagArray.slice(0),
        tooltip: {
          valueSuffix: t(' red bag cycles')
        }
      })
    }
    if (this.checkForNonZeroArray(dialysisArray)) {
      cycleSummarySeries.push({
        name: t('Dialysis'),
        type: 'column',
        color: '#3a97ff',
        data: dialysisArray.slice(0),
        tooltip: {
          valueSuffix: t(' dialysis cycles')
        }
      })
    }
    if (this.checkForNonZeroArray(mixedArray)) {
      cycleSummarySeries.push({
        name: t('Mixed'),
        type: 'column',
        color: '#ffa955',
        data: mixedArray.slice(0),
        tooltip: {
          valueSuffix: t(' mixed cycles')
        }
      })
    }
    if (this.checkForNonZeroArray(unknownArray)) {
      cycleSummarySeries.push({
        name: t('Unknown'),
        type: 'column',
        data: unknownArray.slice(0),
        tooltip: {
          valueSuffix: t(' unknown cycles')
        }
      })
    }
    if (this.checkForNonZeroArray(yellowBagArray)) {
      cycleSummarySeries.push({
        name: t('Yellow bags'),
        type: 'column',
        color: '#ffd429',
        data: yellowBagArray.slice(0),
        tooltip: {
          valueSuffix: t(' yellow bag cycles')
        }
      })
    }
    if (this.checkForNonZeroArray(yellowBagNoGrindArray)) {
      cycleSummarySeries.push({
        name: t('Yellow bags no grind'),
        type: 'column',
        color: '#64b5f6',
        data: yellowBagNoGrindArray.slice(0),
        tooltip: {
          valueSuffix: t(' yellow bag no grind cycles')
        }
      })
    }
    if (this.checkForNonZeroArray(cannabisArray)) {
      cycleSummarySeries.push({
        name: t('Cannabis Microbial Waste'),
        type: 'column',
        color: '#21cc1b',
        data: cannabisArray.slice(0),
        tooltip: {
          valueSuffix: t(' cannabis microbial cycles')
        }
      })
    }

    if (this.checkForNonZeroArray(cannabisProductArray)) {
      cycleSummarySeries.push({
        name: t('Cannabis Product Waste'),
        type: 'column',
        color: '#156813',
        data: cannabisProductArray.slice(0),
        tooltip: {
          valueSuffix: t(' cannabis product cycles')
        }
      })
    }

    if (this.checkForNonZeroArray(liquidsProductArray)) {
      cycleSummarySeries.push({
        name: t('Liquids'),
        type: 'column',
        color: '#1d2e68',
        data: liquidsProductArray.slice(0),
        tooltip: {
          valueSuffix: t(' liquid cycles')
        }
      })
    }

    if (this.checkForNonZeroArray(softwasteProductArray)) {
      cycleSummarySeries.push({
        name: t('Soft Lab Waste'),
        type: 'column',
        color: '#8e24aa',
        data: softwasteProductArray.slice(0),
        tooltip: {
          valueSuffix: t(' soft lab cycles')
        }
      })
    }




    const options = {
      chart: {
        zoomType: 'x',
      },
      credits: {
        enabled: false
      },
      title: {
        text: t('Cycle Summary'),
        style: {
          color: '#009FC2',
          fontSize: '20px',
          fontFamily: 'Arial'
        }
      },
      // subtitle: {
      //   text: deviceFacility
      // },
      xAxis: [{
        categories: xAxisDateArray,
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: `{value} ${t('cycles')}`,
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: t('Cycles'),
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'vertical',
        floating: true,
        align: 'left',
        verticalAlign: 'top',
        y: 9,
        backgroundColor: 'rgba(0,0,0,0)'
      },
      series: cycleSummarySeries
    };
    this.setState({
      options
    });
  };

  populateChart = () => {
    const {
      deviceCycles
    } = this.props;
    this.calculateCycleSummary(deviceCycles);
  };


  render() {
    return (
      <div className={this.props.graphContainerClass}>
        <div id='cycleSummaryChart' className={this.props.className}>
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            updateArgs={[true, true, true]}
          />
        </div>
      </div>
    )
  }
}

export default translate('translations')(CycleSummary);
