import { CheckCircleTwoTone, CloseTwoTone, ErrorTwoTone, InfoTwoTone, WarningTwoTone } from "@mui/icons-material";
import { IconButton, styled, useTheme } from "@mui/material";
import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from "notistack";
import React from "react";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
    "&.notistack-MuiContent-success": {
        backgroundColor: theme.palette.success.dark,
    },
    "&.notistack-MuiContent-error": {
        backgroundColor: theme.palette.error.dark,
    },
    "&.notistack-MuiContent-warning": {
        backgroundColor: theme.palette.warning.dark,
    },
    "&.notistack-MuiContent-info": {
        backgroundColor: theme.palette.info.dark,
    },
}));

const MaterialSnackBarProvider = (props) => {
    const theme = useTheme();

    return (
        <SnackbarProvider
            maxSnack={4}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={5000}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
            }}
            action={(snackbarId) => (
                <IconButton
                    sx={{ color: theme.palette.common.white }}
                    size="small"
                    onClick={() => closeSnackbar(snackbarId)}>
                    <CloseTwoTone fontSize="inherit" />
                </IconButton>
            )}
            iconVariant={{
                success: <CheckCircleTwoTone sx={{ marginRight: 1, color: theme.palette.success.light }} />,
                error: <ErrorTwoTone sx={{ marginRight: 1, color: theme.palette.error.light }} />,
                warning: <WarningTwoTone sx={{ marginRight: 1, color: theme.palette.warning.light }} />,
                info: <InfoTwoTone sx={{ marginRight: 1, color: theme.palette.info.contrastText }} />,
            }}>
            {props.children}
        </SnackbarProvider>
    );
};

export default MaterialSnackBarProvider;
