/*
* The device table inside the facility table, which is inside the Customer table on the View Customers page
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {customRowClass} from "../devices/DeviceTable"
import {commStatusSortHook, isGroupSterilisDistAdminOrFSE, serialSortHook} from "../../library/helpers";
import HookTable from "../../library/HookTable";

const SimpleDeviceTable = props => {
  const {
    t,
    group,
  } = props;

  const columns = [
    {
      Header: <Trans ns="translations" i18nKey="communication_status_br">
        Communication<br/>Status
      </Trans>,
      accessor: 'comm_status',
      sortType: commStatusSortHook,
    },
    {
      Header: t('Serial Number'),
      accessor: 'serial_number',
      sortType: serialSortHook,
    },
    {
      Header: t('Software Version'),
      accessor: 'software_version',
    },
    {
      Header: t('Links'),
      accessor: 'links',
    },
    ...isGroupSterilisDistAdminOrFSE(group) ? [{
      Header: t('Actions'),
      accessor: 'actions',
      className: 'actions-column',
    }] : [],
  ];
  return (
    props.data.length > 0 &&
    <HookTable
      tableName='deviceTable'
      columns={columns}
      data={props.data}
      showPagination={props.data.length > 10}
      customRowClass={true}
      innerTable={true}
      showSearch={false}
      renderEmptyRow={false}
      customRowClassFunc={customRowClass}
      defaultSortBy={{ id: 'serial_number'}}
    />
  )
};


export default translate('translations')(SimpleDeviceTable);
