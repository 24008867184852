// @ts-ignore
import { Box, CircularProgress, useTheme } from "@mui/material";
import Highcharts from "highcharts/highstock";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SterilisChart from "src/components/material/Highcharts/SterilisChart";
import { DateTime } from "luxon";
import SubCard from "src/components/material/Cards/SubCard";
import { convertWeight } from "src/components/library/helpers";

// @ts-ignore
const WasteProcessedPanel = (props) => {
    const [options, setOptions] = React.useState(null);
    // @ts-ignore
    const { deviceCycles, userTimezone, weightUnit } = useSelector((store) => store.devicedashboard);
    const { t } = useTranslation("translations");
    // @ts-ignore
    const loading = useSelector((store) => store.devicedashboard.loading.wasteProcessed);
    const theme = useTheme();
    const checkForNonZeroArray = (array) => {
        const maxNumber = Math.max.apply(null, array);
        return maxNumber !== 0;
    };

    useEffect(() => {
        calculateWasteProcessedSummary(deviceCycles);
    }, [deviceCycles]);

    const getConvertedWeight = (weight) => {
        return weight ? Number(convertWeight(weight, { weight: weightUnit, roundPrecision: 0.1, showUnit: false })) : 0;
    };

    const calculateWasteProcessedSummary = (deviceCycles) => {
        const totalArray = []; // blank
        const unknownArray = []; // blank
        const dialysisArray = []; // Dialysis
        const mixedArray = []; // Mixed
        const redBagArray = []; // Red Bags
        const sharpsArray = []; // Sharps
        const yellowBagArray = []; // Yellow bags
        const cannabisArray = []; // Cannabis Microbial Waste
        const cannabisProductArray = []; // Cannabis Product Waste
        const liquidsProductArray = []; // Liquids
        const softwasteProductArray = []; // Soft Lab Waste
        const yellowBagNoGrindArray = []; // Yellow bags no grind
        const glassSlidesArray = []; // Glass Slides
        const ungrindableArray = []; // Ungrindable Waste
        const xAxisDateArray = []; // Date array for x axis

        for (const cycle in deviceCycles) {
            if (deviceCycles[cycle]["cycle_state"] !== "Completed successfully") {
                continue;
            }

            const date = DateTime.fromISO(deviceCycles[cycle].time_started, { zone: userTimezone }).toISODate();

            if (!xAxisDateArray.includes(date)) {
                xAxisDateArray.push(date);
            }

            const indexOfRecentPush = xAxisDateArray.indexOf(date);

            let totalCycleCount = totalArray[indexOfRecentPush];
            totalCycleCount = totalCycleCount
                ? totalCycleCount + deviceCycles[cycle].weight
                : deviceCycles[cycle].weight;
            totalArray[indexOfRecentPush] = totalCycleCount;

            if (deviceCycles[cycle]["waste_type"] === "dialysis") {
                let currentCycleCount = dialysisArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                dialysisArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "mixed") {
                let currentCycleCount = mixedArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                mixedArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "red_bags") {
                let currentCycleCount = redBagArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                redBagArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "sharps") {
                let currentCycleCount = sharpsArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                sharpsArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "unknown") {
                let currentCycleCount = unknownArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                unknownArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "yellow_bags") {
                let currentCycleCount = yellowBagArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                yellowBagArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "yellow_bags_nogrind") {
                let currentCycleCount = yellowBagNoGrindArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                yellowBagNoGrindArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "cannabis") {
                let currentCycleCount = cannabisArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                cannabisArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "cannabis_product") {
                let currentCycleCount = cannabisProductArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                cannabisProductArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "liquids") {
                let currentCycleCount = liquidsProductArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                liquidsProductArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "soft_lab_waste") {
                let currentCycleCount = softwasteProductArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                softwasteProductArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "glass_slides") {
                let currentCycleCount = glassSlidesArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                glassSlidesArray[indexOfRecentPush] = currentCycleCount;
            } else if (deviceCycles[cycle]["waste_type"] === "ungrindable_waste") {
                let currentCycleCount = ungrindableArray[indexOfRecentPush];
                currentCycleCount = currentCycleCount
                    ? currentCycleCount + deviceCycles[cycle].weight
                    : deviceCycles[cycle].weight;
                ungrindableArray[indexOfRecentPush] = currentCycleCount;
            }
        }

        //Since Highcharts requires array primitives and we need our X axis array and our Y axis arrays to line up index wise
        //We need to fill every Y axis array index with 0s where they do not currently have a value
        //The or syntax is just shorthand for if(!sharpsArray[day])
        for (let day in xAxisDateArray) {
            totalArray[day] = getConvertedWeight(totalArray[day]);
            sharpsArray[day] = getConvertedWeight(sharpsArray[day]);
            redBagArray[day] = getConvertedWeight(redBagArray[day]);
            mixedArray[day] = getConvertedWeight(mixedArray[day]);
            dialysisArray[day] = getConvertedWeight(dialysisArray[day]);
            unknownArray[day] = getConvertedWeight(unknownArray[day]);
            yellowBagArray[day] = getConvertedWeight(yellowBagArray[day]);
            yellowBagNoGrindArray[day] = getConvertedWeight(yellowBagNoGrindArray[day]);
            cannabisArray[day] = getConvertedWeight(cannabisArray[day]);
            cannabisProductArray[day] = getConvertedWeight(cannabisProductArray[day]);
            liquidsProductArray[day] = getConvertedWeight(liquidsProductArray[day]);
            softwasteProductArray[day] = getConvertedWeight(softwasteProductArray[day]);
            glassSlidesArray[day] = getConvertedWeight(glassSlidesArray[day]);
            ungrindableArray[day] = getConvertedWeight(ungrindableArray[day]);
        }

        const wasteSummarySeries = [];
        if (checkForNonZeroArray(totalArray)) {
            wasteSummarySeries.push({
                name: t("Total Waste Processed"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.unknown,
                data: totalArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
                visible: false,
            });
        }

        if (checkForNonZeroArray(sharpsArray)) {
            wasteSummarySeries.push({
                name: t("Sharps"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.sharps,
                data: sharpsArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }
        if (checkForNonZeroArray(redBagArray)) {
            wasteSummarySeries.push({
                name: t("Red Bags"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.redBags,
                data: redBagArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }
        if (checkForNonZeroArray(dialysisArray)) {
            wasteSummarySeries.push({
                name: t("Dialysis"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.dialysis,
                data: dialysisArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }
        if (checkForNonZeroArray(mixedArray)) {
            wasteSummarySeries.push({
                name: t("Mixed"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.mixed,
                data: mixedArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }
        if (checkForNonZeroArray(unknownArray)) {
            wasteSummarySeries.push({
                name: t("Waste Processed"),
                type: "column",
                data: unknownArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }
        if (checkForNonZeroArray(yellowBagArray)) {
            wasteSummarySeries.push({
                name: t("Yellow Bags"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.yellowBags,
                data: yellowBagArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }
        if (checkForNonZeroArray(yellowBagNoGrindArray)) {
            wasteSummarySeries.push({
                name: t("Yellow Bags No Grind"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.yellowBagsNoGrind,
                data: yellowBagNoGrindArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }
        if (checkForNonZeroArray(cannabisArray)) {
            wasteSummarySeries.push({
                name: t("Cannabis Microbial Waste"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.cannabisMicrobial,
                data: cannabisArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }

        if (checkForNonZeroArray(cannabisProductArray)) {
            wasteSummarySeries.push({
                name: t("Cannabis Product Waste"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.cannabisProduct,
                data: cannabisProductArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }

        if (checkForNonZeroArray(liquidsProductArray)) {
            wasteSummarySeries.push({
                name: t("Liquids"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.liquids,
                data: liquidsProductArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }

        if (checkForNonZeroArray(softwasteProductArray)) {
            wasteSummarySeries.push({
                name: t("Soft Lab Waste"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.softlab,
                data: softwasteProductArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }

        if (checkForNonZeroArray(glassSlidesArray)) {
            wasteSummarySeries.push({
                name: t("Glass Slides"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.glassSlides,
                data: glassSlidesArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }

        if (checkForNonZeroArray(ungrindableArray)) {
            wasteSummarySeries.push({
                name: t("Ungrindable Waste"),
                type: "column",
                // @ts-ignore
                color: theme.palette.waste.ungrindable,
                data: ungrindableArray.slice(0),
                tooltip: {
                    valueSuffix: `${weightUnit}`,
                },
            });
        }

        const options = {
            chart: {
                zoomType: "x",
            },
            credits: {
                enabled: false,
            },
            title: {
                text: t("Waste Processed"),
            },
            xAxis: [
                {
                    categories: xAxisDateArray,
                    crosshair: true,
                    reversed: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    labels: {
                        format: `{value} ${weightUnit}`,
                    },
                    title: {
                        text: t("Weight"),
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
            },
            plotOptions: {
                column: {
                    stacking: "normal",
                },
                series: {
                    events: {
                        legendItemClick: function (event) {
                            if (event.target.name === t("Total Waste Processed")) {
                                return false;
                            }
                        },
                    },
                },
            },
            legend: {
                labelFormatter: function () {
                    var total = 0;
                    for (var i = this.yData.length; i--; ) {
                        total += this.yData[i];
                    }
                    if (this.name === t("Total Waste Processed")) {
                        return `<b style="color:black;"> ${this.name} : <b/> ${Math.round(total)} ${weightUnit} <br/>`;
                    } else if (this.name === t("Liquids")) {
                        return (
                            this.name +
                            " : " +
                            Math.round(total) +
                            weightUnit +
                            " ( " +
                            Math.round(total * 453.59237) +
                            " ml )"
                        );
                    } else {
                        return this.name + " : " + Math.round(total) + weightUnit;
                    }
                },
            },
            series: wasteSummarySeries,
        };

        setOptions(options);
    };

    return (
        <SubCard sx={{ width: "100%" }}>
            {loading ? (
                <Box
                    sx={{
                        p: 20,
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                    }}>
                    <CircularProgress color="secondary" size={60} />
                </Box>
            ) : (
                <Box sx={{ marginTop: 2 }}>
                    <SterilisChart highcharts={Highcharts} options={options} />
                </Box>
            )}
        </SubCard>
    );
};

export default WasteProcessedPanel;
