/*
 *  Main component for the Sterilis Dashboard
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import {withTranslation as translate} from "react-i18next";
import {alphabeticalSort, mobileCheck, sterilisCustomers, isGroupSterilisOrDist, composeHoc} from "../library/helpers";
import AuthService from "../AuthService";
import * as Sentry from "@sentry/browser";
import DashboardMobile from "./DashboardMobile";
import DashboardLarge from "./DashboardLarge";
import DashboardMedium from "./DashboardMedium";
import DashboardNoCycles from "./DashboardNoCycles";
import LoadingDashboard from "./LoadingDashboard";
import {isGroupSterilis} from '../library/helpers'
import moment from "moment";
import {Checkbox} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
const Auth = new AuthService();
const AbortController = window.AbortController;

class DashboardMain extends React.Component {
  state = {
    isLoading: false,
    width: 0,
    height: 0,
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    deviceTimezone: null,
    fromDate: "",
    toDate: "",
    deviceConfigID: null,
    deviceFacility: "",
    deviceCycles: [],
    complianceReport: false,
    comprehensiveReport: false,
    cycleTableIsExpanded: false,
    secondsUntilRefresh: 40,
    isError: false,
    currentPressureUnit: "PSI(a)",
    customerMachines: [],
    filteredCustomerMachines: [],
    biochallengeInfo: [],
    customerDevicesOnly: true,
    rawCustomerMachines: [],
    abortController: new AbortController(),
    weight_unit: "",
  };

  componentDidMount() {
    document.title = "Device Dashboard";
    const { group } = this.props;

    // Sterilis people want to see Comprehensive view by default.
    // Customers want to see compliant.
    let complianceReport = false;
    let comprehensiveReport = true;
    if (isGroupSterilisOrDist(group)) {
      complianceReport = false;
      comprehensiveReport = true;
    } else {
      complianceReport = true;
      comprehensiveReport = false;
    }

    // Group logic for the Customer Devices Only checkbox/filter
    let customerDevicesOnly = true;
    if (group === "FactoryWorkers") {
      customerDevicesOnly = false;
    }

    this.setState(
      {
        fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        deviceConfigID: Number(this.props.match.params["device_id"]),
        complianceReport,
        comprehensiveReport,
        customerDevicesOnly,
      },
      () => {
        this.updateWindowDimensions();
        document.addEventListener(
          "visibilitychange",
          this.handleVisibilityChange,
          false
        );
        window.addEventListener("resize", this.updateWindowDimensions);
        this.prepareDashboard(true);
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
    this.state.abortController.abort();
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      console.log("doc was hidden");
      this.setState({
        pageInactive: true,
      });
    } else {
      console.log("doc was shown");
      this.setState({
        pageInactive: false,
      });
    }
  };

  prepareDashboard = (initialLoad) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.fetchData(initialLoad).then(() => {
          this.setState({
            isLoading: false,
          });
        });
      }
    );
  };

  /*
   * Used to refresh data on an existing instance of the dashboard
   * */
  refreshData = async (selectedCycle) => {
    await this.fetchCycleEvents(selectedCycle);
    await this.fetchDeviceCycles();
    await this.fetchBiochallengeInfo();
  };

  /*
    Used to fetch fresh data, on dashboard load or on a device selection
  */
  fetchData = async (initialLoad) => {
    // if it's the initialLoad, we need to retrieve the Device dropdown from /api/customer-machines
    if (initialLoad) {
      await this.fetchCustomerDevices(false);
    }
    await this.fetchDeviceCycles();
    await this.fetchCycleEvents(false);
    await this.fetchBiochallengeInfo();
  };

  /*
    This function evokes the get-device-cycles endpoint, which retrieves information about
    the cycles the device has ran, as well as some meta information about the device & it's facility.
   */
  fetchDeviceCycles = () => {
    const deviceConfigID =
      this.state.deviceConfigID || this.props.match.params["device_id"];
    const { fromDate, toDate, complianceReport } = this.state;

    return Auth.fetch(
      `/api/get-device-cycles?` +
        new URLSearchParams({
          device_config_id: deviceConfigID,
          // Add/Subtract 1 day so that the date range is inclusive
          from_date: moment.utc(fromDate).subtract("1", "days").format(),
          to_date: moment.utc(toDate).add("1", "days").format(),
          compliance_report: complianceReport,
          enable_date_adjustment: true,
        }),
      {
        method: "GET",
        signal: this.state.abortController.signal,
      }
    )
      .then((data) => {
        if (data["cycle_metadata"]["changed_from_date"]) {
          this.fetchCycleEvents(data["cycle_metadata"]["latest_cycle_id"]).then(
            () => {
              this.setState({
                deviceCycles: data["device_cycles"],
                deviceFacility: data["device_metadata"]["device_facility_name"],
                deviceFacilityFullAddress:
                  data["device_metadata"]["device_facility_full_address"],
                deviceSerial: data["device_metadata"]["device_serial"],
                fromDate: moment
                  .utc(data["cycle_metadata"]["new_from_date"])
                  .format("YYYY-MM-DD"),
                weight_unit: data["device_metadata"]["weight_unit"],
              });
            }
          );
        } else {
          // If fetching cycles yielded nothing, and the user is on complianceReport, try setting it to comprehensive
          // report and then fetching data. there may only be error'd out cycles for this device
          if (data["device_cycles"].length === 0 && complianceReport === true) {
            this.setComprehensiveReport();
          } else {
            this.setState({
              deviceCycles: data["device_cycles"],
              deviceFacility: data["device_metadata"]["device_facility_name"],
              deviceFacilityFullAddress:
                data["device_metadata"]["device_facility_full_address"],
              deviceSerial: data["device_metadata"]["device_serial"],
              weight_unit: data["device_metadata"]["weight_unit"],
            });
          }
        }
      })
      .catch((err) => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.setState({ isLoading: false });
        }
      });
  };
  /*
    This function evokes the get-cycle-events endpoint. It's used to retrieve information about a given cycle.
    When cycleID is present, that means the user has specifically chosen a cycle - ie, they've clicked a row
    in the cycle table.

    When cycleID is false, the dashboard is loading for the first time with the selected device.
   */

  fetchCycleEvents = (cycleID) => {
    const deviceConfigID =
      this.state.deviceConfigID || this.props.match.params["device_id"];
    const { fromDate, toDate, complianceReport } = this.state;
    const cycleEventParams = new URLSearchParams({
      device_config_id: deviceConfigID,
      // Add/Subtract 1 day so that the date range is inclusive
      from_date: moment.utc(fromDate).subtract("1", "days").format(),
      to_date: moment.utc(toDate).add("1", "days").format(),
      compliance_report: complianceReport,
    });

    if (cycleID) {
      cycleEventParams.append("cycle_id", cycleID);
      this.setState({
        selectedCycle: cycleID,
      });
    }

    return Auth.fetch(
      `/api/get-cycle-events?${cycleEventParams.toString()}`,
      {
        method: "GET",
        signal: this.state.abortController.signal,
      }
    )
      .then((data) => {
        this.setState({
          cycleEvents: data["cycle_events"],
          cycleMetadata: data["cycle_metadata"],
        });
      })
      .catch((err) => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.setState({ isLoading: false, isError: true });
        }
      });
  };

  fetchBiochallengeInfo = () => {
    const deviceConfigID =
      this.state.deviceConfigID || this.props.match.params["device_id"];
    return Auth.fetch(
      `/api/get-biochallenge-info?` +
        new URLSearchParams({
          device_config_id: deviceConfigID,
        }),
      {
        method: "GET",
        signal: this.state.abortController.signal,
      }
    )
      .then((data) => {
        this.setState({
          biochallengeInfo: data,
        });
      })
      .catch((err) => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.setState({ isLoading: false, isError: true });
        }
      });
  };

  // const deviceOptions =
  // [ { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' }, { key: 'bc', value: 'bc', flag: 'bc', text: 'bc' } ];
  fetchCustomerDevices = () => {
    const { group } = this.props;
    return Auth.fetch(`/api/customer-machines/`, {
      method: "GET",
      signal: this.state.abortController.signal,
    })
      .then((data) => {
        const customerMachines = [];
        const { deviceConfigID } = this.state;
        data.forEach((customer) => {
          customer["facilities"].forEach((facility) => {
            facility["device_configs"].forEach((deviceConfig) => {
              // We don't want to / don't need to display the Customer name for Customers

              if (deviceConfig["id"] === deviceConfigID) {
                this.setState(
                  {
                    deviceTimezone:
                      facility["region_setting"]["timezone"]["timezone"],
                    deviceSerial: deviceConfig["device"]["serial_number"],
                  },
                  () => {
                    document.title = `${this.state.deviceSerial} Dashboard`;
                  }
                );
              }

              if (isGroupSterilisOrDist(group)) {
                customerMachines.push({
                  key: deviceConfig.id,
                  text: `${customer["customer_name"]} @ ${facility["facility_name"]} : ${deviceConfig["device"]["serial_number"]}`,
                  value: deviceConfig.id,
                  customer_id: customer.id,
                });
              } else {
                customerMachines.push({
                  key: deviceConfig.id,
                  text: `${facility["facility_name"]} : ${deviceConfig["device"]["serial_number"]}`,
                  value: deviceConfig.id,
                });
              }
            });
          });
        });

        const { customerDevicesOnly } = this.state;

        customerMachines.sort((a, b) => alphabeticalSort(a, b, "text"));

        if (customerDevicesOnly) {
          const filteredCustomerMachines = customerMachines.filter(
            (deviceDropdownItem) => {
              // always show the selected device in the dropdown menu
              return deviceConfigID === deviceDropdownItem["value"]
                ? true
                : !sterilisCustomers.includes(
                    deviceDropdownItem["customer_id"]
                  );
            }
          );
          this.setState({
            filteredCustomerMachines,
            customerMachines,
            rawCustomerMachines: data,
          });
        } else {
          this.setState({
            filteredCustomerMachines: customerMachines,
            customerMachines,
            rawCustomerMachines: data,
          });
        }
      })
      .catch((err) => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.setState({ isLoading: false, isError: true });
        }
      });
  };

  // fromDateClick = debounce((timestamp) => this.fromDateClickDebounce(timestamp), 3000);
  fromDateClick = (timestamp) => {
    this.setState({
      fromDate: moment.utc(timestamp).format("YYYY-MM-DD"),
    });
  };

  toDateClick = (timestamp) => {
    this.setState({
      toDate: moment.utc(timestamp).format("YYYY-MM-DD"),
    });
  };

  // toDateClick = debounce((timestamp) => this.toDateClickDebounce(timestamp), 3000);

  selectDevice = (e, data) => {
    // Only query dashboard on dropdown if the value has changed
    if (data.value !== this.state.deviceConfigID) {
      const { rawCustomerMachines } = this.state;
      let deviceTimezone = null;
      let deviceSerial = null;
      // Find the device's timezone through the rawCustomerMachines array of objs
      rawCustomerMachines.forEach((customer) => {
        customer["facilities"].forEach((facility) => {
          facility["device_configs"].forEach((deviceConfig) => {
            if (deviceConfig["id"] === data.value) {
              deviceSerial = deviceConfig["device"]["serial_number"];
              deviceTimezone =
                facility["region_setting"]["timezone"]["timezone"];
            }
          });
        });
      });
      this.setState(
        {
          deviceConfigID: data.value,
          deviceTimezone,
          selectedCycle: null,
          deviceSerial,
        },
        () => {
          document.title = `${this.state.deviceSerial} Dashboard`;
          this.props.history.push(`/sa/dashboard/${data.value}`);
          this.prepareDashboard(false);
        }
      );
    }
  };

  setComprehensiveReport = () => {
    this.setState(
      {
        complianceReport: false,
        comprehensiveReport: true,
      },
      () => this.prepareDashboard(false)
    );
  };

  setComplianceReport = () => {
    this.setState(
      {
        complianceReport: true,
        comprehensiveReport: false,
      },
      () => this.prepareDashboard(false)
    );
  };

  expandCycleTable = () => {
    this.setState((prevState) => {
      return { cycleTableIsExpanded: !prevState.cycleTableIsExpanded };
    });
  };

  /*
    This is used in PressureTemperatureGraph.js

    I had to hoist the logic in this func up from that component because other components needed
    the currently set pressure unit.
   */
  setCurrentPressureUnit = (callbackFunction) => {
    const { currentPressureUnit } = this.state;
    if (currentPressureUnit === "PSI(g)") {
      this.setState(
        {
          currentPressureUnit: "PSI(a)",
        },
        () => callbackFunction()
      );
    } else {
      this.setState(
        {
          currentPressureUnit: "PSI(g)",
        },
        () => callbackFunction()
      );
    }
  };

  filterCustomerDevices = () => {
    this.setState(
      (prevState) => {
        return { customerDevicesOnly: !prevState.customerDevicesOnly };
      },
      () => {
        const { customerDevicesOnly, customerMachines } = this.state;

        if (customerDevicesOnly) {
          const filteredCustomerMachines = customerMachines.filter(
            (deviceDropdownItem) => {
              return !sterilisCustomers.includes(
                deviceDropdownItem["customer_id"]
              );
            }
          );
          this.setState({
            filteredCustomerMachines,
          });
        } else {
          this.setState({
            filteredCustomerMachines: customerMachines,
          });
        }
      }
    );
  };

  dashboardLayout = (width, height, deviceCycles) => {
    const { group, t } = this.props;

    const filterCustomerDevices = (
      <React.Fragment>
        {isGroupSterilis(group) ? (
          <Checkbox
            label={{
              children: t("Customer Devices Only"),
              className: "date-label",
            }}
            name="customerDevicesOnly"
            id="customerDevicesOnly"
            className="customer-devices-checkbox"
            checked={this.state.customerDevicesOnly}
            onChange={this.filterCustomerDevices}
          />
        ) : null}
      </React.Fragment>
    );

    if (deviceCycles.length <= 0) {
      return (
        <DashboardNoCycles
          fromDateClick={this.fromDateClick}
          toDateClick={this.toDateClick}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          prepareDashboard={this.prepareDashboard}
          customerMachines={this.state.filteredCustomerMachines}
          selectDevice={this.selectDevice}
          deviceConfigID={this.state.deviceConfigID}
          selectedCycle={this.state.selectedCycle}
          pageInactive={this.state.pageInactive}
          refreshData={this.refreshData}
          filterCustomerDevices={filterCustomerDevices}
        />
      );
    } else if (mobileCheck()) {
      return (
        <DashboardMobile
          width={width}
          height={height}
          cycleEvents={this.state.cycleEvents}
          cycleMetadata={this.state.cycleMetadata}
          deviceCycles={this.state.deviceCycles}
          userTimezone={this.state.userTimezone}
          deviceTimezone={this.state.deviceTimezone}
          fetchCycleEvents={this.fetchCycleEvents}
          fromDateClick={this.fromDateClick}
          toDateClick={this.toDateClick}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          prepareDashboard={this.prepareDashboard}
          customerMachines={this.state.filteredCustomerMachines}
          selectDevice={this.selectDevice}
          deviceConfigID={this.state.deviceConfigID}
          deviceFacility={this.state.deviceFacility}
          complianceReport={this.state.complianceReport}
          comprehensiveReport={this.state.comprehensiveReport}
          setComprehensiveReport={this.setComprehensiveReport}
          setComplianceReport={this.setComplianceReport}
          expandCycleTable={this.expandCycleTable}
          cycleTableIsExpanded={this.state.cycleTableIsExpanded}
          biochallengeInfo={this.state.biochallengeInfo}
          selectedCycle={this.state.selectedCycle}
          pageInactive={this.state.pageInactive}
          refreshData={this.refreshData}
          deviceFacilityFullAddress={this.state.deviceFacilityFullAddress}
          deviceSerial={this.state.deviceSerial}
          setCurrentPressureUnit={this.setCurrentPressureUnit}
          currentPressureUnit={this.state.currentPressureUnit}
          isLoading={this.state.isLoading}
          filterCustomerDevices={filterCustomerDevices}
        />
      );
    } else if (width > 1200) {
      return (
        <DashboardLarge
          width={width}
          height={height}
          cycleEvents={this.state.cycleEvents}
          cycleMetadata={this.state.cycleMetadata}
          deviceCycles={this.state.deviceCycles}
          userTimezone={this.state.userTimezone}
          deviceTimezone={this.state.deviceTimezone}
          fetchCycleEvents={this.fetchCycleEvents}
          fromDateClick={this.fromDateClick}
          toDateClick={this.toDateClick}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          prepareDashboard={this.prepareDashboard}
          customerMachines={this.state.filteredCustomerMachines}
          selectDevice={this.selectDevice}
          deviceConfigID={this.state.deviceConfigID}
          deviceFacility={this.state.deviceFacility}
          complianceReport={this.state.complianceReport}
          comprehensiveReport={this.state.comprehensiveReport}
          setComprehensiveReport={this.setComprehensiveReport}
          setComplianceReport={this.setComplianceReport}
          expandCycleTable={this.expandCycleTable}
          cycleTableIsExpanded={this.state.cycleTableIsExpanded}
          biochallengeInfo={this.state.biochallengeInfo}
          selectedCycle={this.state.selectedCycle}
          pageInactive={this.state.pageInactive}
          refreshData={this.refreshData}
          deviceFacilityFullAddress={this.state.deviceFacilityFullAddress}
          deviceSerial={this.state.deviceSerial}
          setCurrentPressureUnit={this.setCurrentPressureUnit}
          currentPressureUnit={this.state.currentPressureUnit}
          filterCustomerDevices={filterCustomerDevices}
          weight_unit={this.state.weight_unit}
        />
      );
    } else if (width <= 1200 && width > 980) {
      return (
        <DashboardMedium
          width={width}
          height={height}
          cycleEvents={this.state.cycleEvents}
          cycleMetadata={this.state.cycleMetadata}
          deviceCycles={this.state.deviceCycles}
          userTimezone={this.state.userTimezone}
          deviceTimezone={this.state.deviceTimezone}
          fetchCycleEvents={this.fetchCycleEvents}
          fromDateClick={this.fromDateClick}
          toDateClick={this.toDateClick}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          prepareDashboard={this.prepareDashboard}
          customerMachines={this.state.filteredCustomerMachines}
          selectDevice={this.selectDevice}
          deviceConfigID={this.state.deviceConfigID}
          deviceFacility={this.state.deviceFacility}
          complianceReport={this.state.complianceReport}
          comprehensiveReport={this.state.comprehensiveReport}
          setComprehensiveReport={this.setComprehensiveReport}
          setComplianceReport={this.setComplianceReport}
          expandCycleTable={this.expandCycleTable}
          cycleTableIsExpanded={this.state.cycleTableIsExpanded}
          biochallengeInfo={this.state.biochallengeInfo}
          selectedCycle={this.state.selectedCycle}
          pageInactive={this.state.pageInactive}
          refreshData={this.refreshData}
          deviceFacilityFullAddress={this.state.deviceFacilityFullAddress}
          deviceSerial={this.state.deviceSerial}
          setCurrentPressureUnit={this.setCurrentPressureUnit}
          currentPressureUnit={this.state.currentPressureUnit}
          filterCustomerDevices={filterCustomerDevices}
          weight_unit={this.state.weight_unit}
        />
      );
    } else {
      return (
        <DashboardMobile
          width={width}
          height={height}
          cycleEvents={this.state.cycleEvents}
          cycleMetadata={this.state.cycleMetadata}
          deviceCycles={this.state.deviceCycles}
          userTimezone={this.state.userTimezone}
          deviceTimezone={this.state.deviceTimezone}
          fetchCycleEvents={this.fetchCycleEvents}
          fromDateClick={this.fromDateClick}
          toDateClick={this.toDateClick}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          prepareDashboard={this.prepareDashboard}
          customerMachines={this.state.filteredCustomerMachines}
          selectDevice={this.selectDevice}
          deviceConfigID={this.state.deviceConfigID}
          deviceFacility={this.state.deviceFacility}
          complianceReport={this.state.complianceReport}
          comprehensiveReport={this.state.comprehensiveReport}
          setComprehensiveReport={this.setComprehensiveReport}
          setComplianceReport={this.setComplianceReport}
          expandCycleTable={this.expandCycleTable}
          cycleTableIsExpanded={this.state.cycleTableIsExpanded}
          biochallengeInfo={this.state.biochallengeInfo}
          selectedCycle={this.state.selectedCycle}
          pageInactive={this.state.pageInactive}
          refreshData={this.refreshData}
          deviceFacilityFullAddress={this.state.deviceFacilityFullAddress}
          deviceSerial={this.state.deviceSerial}
          setCurrentPressureUnit={this.setCurrentPressureUnit}
          currentPressureUnit={this.state.currentPressureUnit}
          isLoading={this.state.isLoading}
          filterCustomerDevices={filterCustomerDevices}
          weight_unit={this.state.weight_unit}
        />
      );
    }
  };

  render() {
    const { isLoading, height, width, deviceCycles } = this.state;

    const dashboardComponent = this.dashboardLayout(
      width,
      height,
      deviceCycles
    );

    return (
      <div>
        {isLoading ? (
          <LoadingDashboard
            customerMachines={this.state.customerMachines}
            deviceConfigID={this.state.deviceConfigID}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            fromDateClick={this.fromDateClick}
            toDateClick={this.toDateClick}
            prepareDashboard={this.prepareDashboard}
            selectedCycle={this.state.selectedCycle}
            pageInactive={this.state.pageInactive}
            refreshData={this.refreshData}
          />
        ) : (
          dashboardComponent
        )}
      </div>
    );
    // return <div>{dashboardComponent}</div>;
  }
}

export default composeHoc(withRouter, translate("translations"))(DashboardMain);
