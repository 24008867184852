/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

const SteamCycleTrendsChart = props => {
  const {t} = useTranslation('translations');
  const combinedSteamCycleTrendOptions = {
    chart: {
      zoomType: 'x',
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        }
      }
    },
    tooltip: {
      shared: true,
    },
    title: {
      text: 'Steam Cycle Trends'
    },
    credits: false,
    xAxis: [{
      categories: props.cycleStartTimes,
      crosshair: true,
    }],
    yAxis: [
      ...props.trendCycleScore ? [{
        id: 'scoreYAxisLabel',
        labels: {
          format: 'Score: {value}',
          enabled: props.hideAxisLabels,
          style: {
            color: 'dodgerblue'
          }
        },
        title: {
          text: t('Score'),
          style: {
            color: 'dodgerblue'
          }
        },
      }] : [],
      ...props.trendCycleDuration ? [
        {
          id: 'cycleDurationYAxisLabel',
          title: {
            text: t('Cycle Duration'),
            style: {
              color: "lightseagreen"
            }
          },
          labels: {
            format: `{value} mins`,
            enabled: props.hideAxisLabels,
            style: {
              color: "lightseagreen"
            }
          },
        }] : [],
      ...props.trendTvAvgHold ? [{
        id: 'tvHoldYAxisLabel',
        title: {
          text: t('TV Avg Hold'),
          style: {
            color: 'fuchsia'
          }
        },
        labels: {
          format: `{value}°C`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'fuchsia'
          }
        },
      }] : [],
      ...props.trendTPDiff ? [{
        id: 'tpDiffYAxisLabel',
        title: {
          text: t('T-P diff'),
          style: {
            color: 'Orange'
          }
        },
        labels: {
          format: `{value}°C`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'Orange'
          }
        },
      }] : [],
      ...props.trendFlowSeconds ? [{
        id: 'flowSecondsYAxisLabel',
        title: {
          text: t('Flow Seconds'),
          style: {
            color: 'HotPink'
          }
        },
        labels: {
          format: `{value} secs`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'HotPink'
          }
        },
      }] : [],
      ...props.trendPumpdownDuration ? [{
        id: 'pumpdownDurationYAxisLabel',
        title: {
          text: t('Pumpdown duration'),
          style: {
            color: 'PeachPuff'
          }
        },
        labels: {
          format: `{value} mins`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'PeachPuff'
          }
        },
      }] : [],
      ...props.trendDrainingTime ? [{
        id: 'drainTimeYAxisLabel',
        title: {
          text: t('Drain time'),
          style: {
            color: 'SpringGreen'
          }
        },
        labels: {
          format: `{value} mins`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'SpringGreen'
          }
        },
      }] : [],
      ...props.trendHeatUpTime ? [{
        id: 'heatUpTimeYAxis',
        title: {
          text: t('Heat up time'),
          style: {
            color: 'MediumTurquoise'
          }
        },
        labels: {
          format: `{value} mins`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'MediumTurquoise'
          }
        },
      }] : [],
      ...props.trendPumpdownTimeouts ? [{
        id: 'pumpdownTimeoutsYAxis',
        title: {
          text: t('Pumpdown Timeouts'),
          style: {
            color: 'LightSalmon'
          }
        },
        labels: {
          format: `{value} timeouts`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'LightSalmon'
          }
        },
      }] : [],
      ...props.trendTVStart ? [{
        id: 'tvStartYAxisLabel',
        title: {
          text: t('TV Start'),
          style: {
            color: 'BlueViolet'
          }
        },
        labels: {
          format: `{value}°C`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'BlueViolet'
          }
        },
      }] : [],
      ...props.trendTVMax ? [{
        id: 'tvMaxYAxisLabel',
        title: {
          text: t('TV Max'),
          style: {
            color: 'DarkSeaGreen'
          }
        },
        labels: {
          format: `{value}°C`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'DarkSeaGreen'
          }
        },
      }] : [],
      ...props.trendTRAverage ? [{
        id: 'trAvgYAxisLabel',
        title: {
          text: t('TR Average'),
          style: {
            color: 'DarkSlateGrey'
          }
        },
        labels: {
          format: `{value}°C`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'DarkSlateGrey'
          }
        },
      }] : [],
      ...props.trendPVStart ? [{
        id: 'pvStartYAxisLabel',
        title: {
          text: t('Pv Start'),
          style: {
            color: 'MediumTurquoise'
          }
        },
        labels: {
          format: `{value} PSI(g)`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'MediumTurquoise'
          }
        },
      }] : [],
      ...props.trendPVMin ? [{
        id: 'pvMinYAxisLabel',
        title: {
          text: t('PV Min'),
          style: {
            color: 'DarkOliveGreen'
          }
        },
        labels: {
          format: `{value} PSI(g)`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'DarkOliveGreen'
          }
        },
      }] : [],
      ...props.trendPVMax ? [{
        id: 'pvMaxYAxisLabel',
        title: {
          text: t('PV Max'),
          style: {
            color: 'LemonChiffon'
          }
        },
        labels: {
          format: `{value} PSI(g)`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'LemonChiffon'
          }
        },
      }] : [],
      ...props.trendPVAverageDuringHold ? [{
        id: 'pvAvgYAxisLabel',
        title: {
          text: t('PV Avg'),
          style: {
            color: 'CornflowerBlue'
          }
        },
        labels: {
          format: `{value} PSI(g)`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'CornflowerBlue'
          }
        },
      }] : [],
      ...props.trendHeatUpRateDifference ? [{
        id: 'heatUpRateDifferenceYAxisLabel',
        title: {
          text: t('Heat Up Rate Difference'),
          style: {
            color: 'Crimson'
          }
        },
        labels: {
          format: `{value}`,
          enabled: props.hideAxisLabels,
          style: {
            color: 'Crimson'
          }
        },
      }] : [],



    ],
    series: [
      ...props.trendCycleScore ? [{
        name: "Score",
        data: props.scoreTrend,
        yAxis: 'scoreYAxisLabel',
        color: 'dodgerblue',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalScoreValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalScoreValues['maximum_value'],
            color: 'dodgerblue'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendTvAvgHold ? [{
        name: "Tv Avg during Hold",
        data: props.tvAvgHoldTrend,
        yAxis: 'tvHoldYAxisLabel',
        color: 'fuchsia',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalTvAvgDuringHoldValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalTvAvgDuringHoldValues['maximum_value'],
            color: 'fuchsia'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendTPDiff ? [{
        name: "T-P Diff",
        data: props.tpDiffTrend,
        yAxis: 'tpDiffYAxisLabel',
        color: 'Orange',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalTPDiffValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalTPDiffValues['maximum_value'],
            color: 'Orange'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendCycleDuration ? [{
        name: "Cycle Duration",
        data: props.durationTrend,
        yAxis: 'cycleDurationYAxisLabel',
        color: "lightseagreen",
        zones : [],
      }] : [],
      ...props.trendFlowSeconds ? [{
        name: "Flow Seconds",
        data: props.flowSecondsTimeTrend,
        yAxis: 'flowSecondsYAxisLabel',
        color: 'HotPink',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalFlowSecondsValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalFlowSecondsValues['maximum_value'],
            color: 'HotPink'
          },
          {
            color: 'red'
          },
        ] : []],
      }] : [],
      ...props.trendPumpdownDuration ? [{
        name: "Pumpdown Duration",
        data: props.steamPumpdownTimeTrend,
        yAxis: 'pumpdownDurationYAxisLabel',
        color: 'PeachPuff',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalSteamPumpdownValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalSteamPumpdownValues['maximum_value'],
            color: 'PeachPuff'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],
      ...props.trendDrainingTime ? [{
        name: "Drain Time",
        data: props.drainTimeTrend,
        yAxis: 'drainTimeYAxisLabel',
        color: 'SpringGreen',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalDrainTimeValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalDrainTimeValues['maximum_value'],
            color: 'SpringGreen'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],
      ...props.trendHeatUpTime ? [{
        name: "Heat up time",
        data: props.heatUpTimeTrend,
        yAxis: 'heatUpTimeYAxis',
        color: 'MediumTurquoise',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalHeatUpTimeValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalHeatUpTimeValues['maximum_value'],
            color: 'MediumTurquoise'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],
      ...props.trendPumpdownTimeouts ? [{
        name: "Pumpdown Timeouts",
        data: props.pumpdownTimeoutTrend,
        yAxis: 'pumpdownTimeoutsYAxis',
        color: 'LightSalmon',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalPumpdownTimeoutsValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalPumpdownTimeoutsValues['maximum_value'],
            color: 'LightSalmon'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],
      ...props.trendTVStart ? [{
        name: "TV Start",
        data: props.tvStartTrend,
        yAxis: 'tvStartYAxisLabel',
        color: 'BlueViolet',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalTvStartValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalTvStartValues['maximum_value'],
            color: 'BlueViolet'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],
      ...props.trendTVMax ? [{
        name: "TV Max",
        data: props.tvMaxTrend,
        yAxis: 'tvMaxYAxisLabel',
        color: 'DarkSeaGreen',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalTvMaxValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalTvMaxValues['maximum_value'],
            color: 'DarkSeaGreen'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],

      ...props.trendTRAverage ? [{
        name: "TR Average",
        data: props.trAvgTrend,
        yAxis: 'trAvgYAxisLabel',
        color: 'DarkSlateGrey',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalTrAvgValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalTrAvgValues['maximum_value'],
            color: 'DarkSlateGrey'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],

      ...props.trendPVStart ? [{
        name: "PV Start",
        data: props.pvStartTrend,
        yAxis: 'pvStartYAxisLabel',
        color: 'MediumTurquoise',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalPvStartValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalPvStartValues['maximum_value'],
            color: 'MediumTurquoise'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],
      ...props.trendPVMin ? [{
        name: "PV Min",
        data: props.pvMinTrend,
        yAxis: 'pvMinYAxisLabel',
        color: 'DarkOliveGreen',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalPvMinValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalPvMinValues['maximum_value'],
            color: 'DarkOliveGreen'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],

      ...props.trendPVMax ? [{
        name: "PV Max",
        data: props.pvMaxTrend,
        yAxis: 'pvMaxYAxisLabel',
        color: 'LemonChiffon',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalPvMaxValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalPvMaxValues['maximum_value'],
            color: 'LemonChiffon'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],

      ...props.trendPVAverageDuringHold ? [{
        name: "PV Average during Hold",
        data: props.pvAvgDuringHoldTrend,
        yAxis: 'pvAvgYAxisLabel',
        color: 'CornflowerBlue',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalPvAvgDuringHoldValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalPvAvgDuringHoldValues['maximum_value'],
            color: 'CornflowerBlue'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],

      ...props.trendHeatUpRateDifference ? [{
        name: "Heat Up Rate Difference",
        data: props.heatUpRateDifferenceTrend,
        yAxis: 'heatUpRateDifferenceYAxisLabel',
        color: 'Crimson',
        zones: [...props.showAbnormalTrendValues ? [
          {
            value: props.abnormalHeatUpRateDifferenceValues['minimum_value'],
            color: 'red'
          },
          {
            value: props.abnormalHeatUpRateDifferenceValues['maximum_value'],
            color: 'Crimson'
          },
          {
            color: 'red'
          },
        ] : []]
      }] : [],


    ],
  };

  return <HighchartsReact
    highcharts={Highcharts}
    options={combinedSteamCycleTrendOptions}
  />
};

SteamCycleTrendsChart.defaultProps = {
  combineSteamCycleTrends: true,
  trendCycleOutcome: false,
  trendCycleScore: true,
  trendCycleDuration: true,
  trendTvAvgHold: true,
  trendTPDiff: true,
  trendHeatUpRateDifference: true,
  showAbnormalTrendValues: false,

  scoreTrend: [],
  tvAvgHoldTrend: [],
  tpDiffTrend: [],
  durationTrend: [],
  cycleStartTimes: [],

  flowSecondsTimeTrend: [],
  steamPumpdownTimeTrend: [],
  drainTimeTrend: [],
  heatUpTimeTrend: [],
  pumpdownTimeoutTrend: [],
  tvStartTrend: [],
  tvMaxTrend: [],
  trAvgTrend: [],
  tvAvgDuringHoldTrend: [],
  pvStartTrend: [],
  pvMinTrend: [],
  pvMaxTrend: [],
  pvAvgDuringHoldTrend: [],
  heatUpRateDifferenceTrend: [],

  abnormalScoreValues: {},
  abnormalFlowSecondsValues: {},
  abnormalSteamPumpdownValues: {},
  abnormalDrainTimeValues: {},
  abnormalHeatUpTimeValues: {},
  abnormalPumpdownTimeoutsValues: {},
  abnormalTPDiffValues: {},
  abnormalTvStartValues: {},
  abnormalTvMaxValues: {},
  abnormalTrAvgValues: {},
  abnormalTvAvgDuringHoldValues: {},
  abnormalPvStartValues: {},
  abnormalPvMinValues: {},
  abnormalPvMaxValues: {},
  abnormalPvAvgDuringHoldValues: {},
  abnormalHeatUpRateDifferenceValues: {},
};

SteamCycleTrendsChart.propTypes = {
  combineSteamCycleTrends: PropTypes.bool,
  trendCycleOutcome: PropTypes.bool,
  trendCycleScore: PropTypes.bool,
  trendCycleDuration: PropTypes.bool,
  trendTvAvgHold: PropTypes.bool,
  trendTPDiff: PropTypes.bool,
  trendHeatUpRateDifference: PropTypes.bool,
  showAbnormalTrendValues: PropTypes.bool,

  scoreTrend: PropTypes.array,
  tvAvgHoldTrend: PropTypes.array,
  tpDiffTrend: PropTypes.array,
  durationTrend: PropTypes.array,
  cycleStartTimes: PropTypes.array,

  flowSecondsTimeTrend: PropTypes.array,
  steamPumpdownTimeTrend: PropTypes.array,
  drainTimeTrend: PropTypes.array,
  heatUpTimeTrend: PropTypes.array,
  pumpdownTimeoutTrend: PropTypes.array,
  tvStartTrend: PropTypes.array,
  tvMaxTrend: PropTypes.array,
  trAvgTrend: PropTypes.array,
  tvAvgDuringHoldTrend: PropTypes.array,
  pvStartTrend: PropTypes.array,
  pvMinTrend: PropTypes.array,
  pvMaxTrend: PropTypes.array,
  pvAvgDuringHoldTrend: PropTypes.array,
  heatUpRateDifferenceTrend: PropTypes.array,

  abnormalScoreValues: PropTypes.object,
  abnormalFlowSecondsValues: PropTypes.object,
  abnormalSteamPumpdownValues: PropTypes.object,
  abnormalDrainTimeValues: PropTypes.object,
  abnormalHeatUpTimeValues: PropTypes.object,
  abnormalPumpdownTimeoutsValues: PropTypes.object,
  abnormalTPDiffValues: PropTypes.object,
  abnormalTvStartValues: PropTypes.object,
  abnormalTvMaxValues: PropTypes.object,
  abnormalTrAvgValues: PropTypes.object,
  abnormalTvAvgDuringHoldValues: PropTypes.object,
  abnormalPvStartValues: PropTypes.object,
  abnormalPvMinValues: PropTypes.object,
  abnormalPvMaxValues: PropTypes.object,
  abnormalPvAvgDuringHoldValues: PropTypes.object,
  abnormalHeatUpRateDifferenceValues: PropTypes.object,
};

export default SteamCycleTrendsChart;
