/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FlexBlockItem from "../library/FlexBlockItem";

const CycleParameters = props => {
  const [parameters, setParameters] = useState([]);

  useEffect(() => {
    const params = Object.entries(props.cycleParameters).map(([key, value]) => {
      return {'name': key, 'value': value}
    });
    setParameters(params)
  }, [props.cycleParameters]);

  return <React.Fragment>
    {parameters.length > 0 &&
    <fieldset>
      <legend>Cycle Parameters</legend>
      {parameters.map((cycleParam, idx) => {
        return <React.Fragment key={`${idx}-${cycleParam['name']}`}>
          <FlexBlockItem
            label={cycleParam['name']}
            value={cycleParam['value']}
          />
        </React.Fragment>
      })}
    </fieldset>
    }
  </React.Fragment>
};

CycleParameters.defaultProps = {
  cycleParameters: {},
};

CycleParameters.propTypes = {
  cycleParameters: PropTypes.object,

};

export default CycleParameters;
