/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "../../../AuthService";
import SimpleLoader from "../../../SimpleLoader";
import * as Sentry from "@sentry/browser";
const Auth = new AuthService();

class UnretireFacilityModal extends React.Component{
  state = {
    modalLoading : false,
    allowModalToBeClosed: true,
  };

  unretireFacility = () => {
    const {
      facility
    } = this.props;
    this.setState({
      modalLoading : true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/facility/${facility['id']}/`, {
      method : 'DELETE',
    }).then(() => {
     this.setState({
       modalLoading : false,
       allowModalToBeClosed: true,
     }, () => {
       this.props.preparePage();
       this.props.triggerUnretireFacilityModal(null);
       this.props.notifySuccess('Reactivate', 'facility', facility['facility_name'])
     });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
         modalLoading : false,
        allowModalToBeClosed: true,
       }, () => {
        this.props.triggerUnretireFacilityModal();
        this.props.notifyFailure()
       });
    });
  };
  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
          open={this.props.unretireFacilityModal}
          onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
          transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerUnretireFacilityModal(null)
          }}
        >
          <Header>
            <h2 className='light-green'>{t('Reactivate Facility') }</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
           <div className='short-modal-text center-text'>
             <p>Restoring this facility will allow devices to be assigned to it.</p>
           </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='green-btn'
                    value="Submit"
                    type="submit"
                    id='unretireFacilityBtn'
                    onClick={this.unretireFacility}
                    >{t('Reactivate Facility')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(UnretireFacilityModal);
