import React from "react";
import moment from "moment";
import { Duration } from "luxon";

export const calcTitleMargin = (plotBandLength) => (plotBandLength > 0 ? 175 : 0);
export const calcSingleChartHeight = (plotBandLength) => (plotBandLength > 0 ? 625 : 400);
export const calcCombinedChartHeight = (plotBandLength) => (plotBandLength > 0 ? 800 : 600);
export const dutyCycleConstants = [
    {
        value: 690,
        color: "black",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "690 - Air compressor recovers if off",
            y: 20,
        },
    },
    {
        value: 720,
        color: "black",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "720 - Air compressor turns off",
            y: -5,
        },
    },
];

export const dutyCycleYAxisLabel = {
    id: "dutyCycleYAxisLabel",
    title: {
        text: "Duty Cycle",
        enabled: false,
        style: {
            color: "black",
        },
    },
    labels: {
        format: `{value}`,
        enabled: false,
        style: {
            color: "black",
        },
    },
};

export const dutyCycleAvgSeries = (dutyCycleAvgTrend, props) => {
    const { showAbnormalTrendValues, abnormalDutyCycleAvgValues } = props;
    return [
        {
            name: "Duty Cycle Average",
            data: dutyCycleAvgTrend,
            yAxis: "dutyCycleAvgYAxisLabel",
            color: "MediumAquaMarine",
            zones: [
                ...(showAbnormalTrendValues
                    ? [
                          {
                              value: abnormalDutyCycleAvgValues["minimum_value"],
                              color: "red",
                          },
                          {
                              value: abnormalDutyCycleAvgValues["maximum_value"],
                              color: "MediumAquaMarine",
                          },
                          {
                              color: "red",
                          },
                      ]
                    : []),
            ],
        },
    ];
};

export const dutyCycleStartSeries = (dutyCycleStartTrend, props) => {
    const { showAbnormalTrendValues, abnormalDutyCycleStartValues } = props;
    return [
        {
            name: "Duty Cycle Start",
            data: dutyCycleStartTrend,
            yAxis: "dutyCycleStartYAxisLabel",
            color: "MediumSeaGreen",
            zones: [
                ...(showAbnormalTrendValues
                    ? [
                          {
                              value: abnormalDutyCycleStartValues["minimum_value"],
                              color: "red",
                          },
                          {
                              value: abnormalDutyCycleStartValues["maximum_value"],
                              color: "MediumSeaGreen",
                          },
                          {
                              color: "red",
                          },
                      ]
                    : []),
            ],
        },
    ];
};

export const dutyCycleEndSeries = (dutyCycleEndTrend, props) => {
    const { showAbnormalTrendValues, abnormalDutyCycleEndValues } = props;
    return [
        {
            name: "Duty Cycle End",
            data: dutyCycleEndTrend,
            yAxis: "dutyCycleEndYAxisLabel",
            color: "SeaGreen",
            zones: [
                ...(showAbnormalTrendValues
                    ? [
                          {
                              value: abnormalDutyCycleEndValues["minimum_value"],
                              color: "red",
                          },
                          {
                              value: abnormalDutyCycleEndValues["maximum_value"],
                              color: "SeaGreen",
                          },
                          {
                              color: "red",
                          },
                      ]
                    : []),
            ],
        },
    ];
};
export const dutyCycleAvgYAxis = ({ hideAxisLabels }) => {
    return [
        {
            id: "dutyCycleAvgYAxisLabel",
            title: {
                text: "Duty Cycle Avg",
                style: {
                    color: "MediumAquaMarine",
                },
            },
            labels: {
                format: `{value}`,
                enabled: hideAxisLabels,
                style: {
                    color: "MediumAquaMarine",
                },
            },
        },
    ];
};
export const dutyCycleStartYAxis = ({ hideAxisLabels }) => {
    return [
        {
            id: "dutyCycleStartYAxisLabel",
            title: {
                text: "Duty Cycle Start",
                style: {
                    color: "MediumSeaGreen",
                },
            },
            labels: {
                format: `{value}`,
                enabled: hideAxisLabels,
                style: {
                    color: "MediumSeaGreen",
                },
            },
        },
    ];
};
export const dutyCycleEndYAxis = ({ hideAxisLabels }) => {
    return [
        {
            id: "dutyCycleEndYAxisLabel",
            title: {
                text: "Duty Cycle End",
                style: {
                    color: "SeaGreen",
                },
            },
            labels: {
                format: `{value}`,
                enabled: hideAxisLabels,
                style: {
                    color: "SeaGreen",
                },
            },
        },
    ];
};

export const cycleStatHeader = ({ is_successful: isSuccessful }) => {
    return <span className={`${isSuccessful ? "green-text" : "red-text"}`}>{isSuccessful ? "Success" : "Fail"}</span>;
};

export const inRangeInclusive = (value, min, max) => {
    // Added for catching duration fields
    if (
        typeof value === "string" &&
        (typeof min === "number" || value.includes("time") || value.includes("_duration"))
    ) {
        value = Duration.fromISOTime(value).as("seconds");
    }
    return value <= max && value >= min;
};

export const zoomButtonOptions = {
    // theme: {
    //   display: 'block'
    // }
};

export const tempConstants = [
    {
        value: 325,
        color: "fuchsia",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "Tr = 325°C Heaters Off",
        },
    },
    {
        value: 170,
        color: "darkblue",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "Th = 170°C Inject Water / Heaters Off",
        },
    },
    {
        value: 136,
        color: "dodgerblue",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "Tv = 136°C Heaters Off",
        },
    },
    {
        value: 134,
        color: "black",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "Tv = 134°C Cooking",
            y: 20,
        },
    },
];

export const pressureConstants = [
    {
        value: 55,
        color: "lime",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "Pv = 55 PSI(g)",
            align: "right",
        },
    },
    {
        value: 52.3,
        color: "lime",
        dashStyle: "shortdash",
        width: 2,
        label: {
            text: "Pv = 52.3 PSI(g)",
            align: "right",
            y: 15,
        },
    },
];

export const tempUnit = "°C";
export const presUnit = "PSI(g)";

export const defaultPlotOptions = {
    series: {
        boostThreshold: 200,
        marker: {
            enabledThreshold: 2,
        },
    },
};

export const chartTooltip = (chart, cycleStartTime, plotBands) => {
    const containsOverlay = chart.points.some((point) => point.series.userOptions.isOverlayedCycle);
    const originalCycleInPoint = containsOverlay
        ? chart.points.some((point) => point.series.userOptions.overlayedCycleNumber === 0)
        : false;

    let overlayedCounter = 0;
    const timestamp = containsOverlay ? "Multiple Cycles Selected" : moment.utc(chart.x).local().format("hh:mm:ss A");

    const originalCycleHeader = originalCycleInPoint
        ? `<b>Original Cycle</b><br/><b>${cycleStartTime}</b><br/><br/>`
        : "";

    let previousIdx = 0;
    let container = chart.points.reduce(function (s, point) {
        let tooltip = ``;
        const { overlayedCycleNumber, cycleStartTime, isOverlayedCycle } = point.series.userOptions;
        // for each "point" in the chart, there are N iterations where N is the # of series in chart
        // we only need to find if a digital output/state is on once here
        let activePlotBand = false;
        if (point.point.index !== previousIdx && plotBands.length > 0) {
            activePlotBand = plotBands
                .filter((band) => band["from"] <= point.point.index && band["to"] >= point.point.index)
                .reduce((string, activeBand) => {
                    string += `${activeBand.name}<br/>`;
                    return string;
                }, "");
            previousIdx = point.point.index;
        }
        if (containsOverlay) {
            if (isOverlayedCycle && overlayedCycleNumber !== overlayedCounter) {
                tooltip += `</div>`;
                tooltip += `<div class="tooltip-sub-container">`;
                tooltip += `<b>Overlayed Cycle</b><br/><b>${cycleStartTime}</b><br/><br/>`;
                overlayedCounter = overlayedCycleNumber;
            }
        }
        if (activePlotBand) {
            tooltip += `<br/>${activePlotBand}<br/>`;
        }
        tooltip += `<span style="color:${point.color}">\u25CF</span>  `;
        tooltip += `${point.series.name}: ${point.y.toFixed(2)} ${point.series.userOptions.unit}`;
        return s + tooltip + "</br>";
    }, `<b>${timestamp}</b><br/><div style='display: flex; flex-direction: row; '><div class="tooltip-sub-container">${originalCycleHeader}`);
    container += `</div></div>`;
    return container;
};

export const getTempSeries = (
    thList,
    tvList,
    trList,
    dutyCycleList,
    plotDutyCycle,
    overlayedCycleNumber = 0,
    isOverlayedCycle = false,
    cycleStartTime = ""
) => {
    return [
        {
            name: "Th",
            overlayedCycleNumber,
            isOverlayedCycle,
            cycleStartTime,
            data: thList,
            yAxis: 0,
            color: "darkblue",
            unit: tempUnit,
        },
        {
            name: "Tv",
            data: tvList,
            overlayedCycleNumber,
            isOverlayedCycle,
            cycleStartTime,
            yAxis: 0,
            color: "dodgerblue",
            unit: tempUnit,
        },
        {
            name: "Tr",
            data: trList,
            overlayedCycleNumber,
            isOverlayedCycle,
            cycleStartTime,
            yAxis: 0,
            color: "fuchsia",
            unit: tempUnit,
        },
        ...(plotDutyCycle
            ? getDutyCycleSeries(dutyCycleList, overlayedCycleNumber, isOverlayedCycle, cycleStartTime)
            : []),
    ];
};

export const getPresSeries = (
    paList,
    psList,
    pvList,
    dutyCycleList,
    plotDutyCycle,
    overlayedCycleNumber = 0,
    isOverlayedCycle = false,
    cycleStartTime = "",
    isCombined = false
) => {
    return [
        {
            name: "Pa",
            overlayedCycleNumber: overlayedCycleNumber,
            isOverlayedCycle: isOverlayedCycle,
            cycleStartTime,
            data: paList,
            yAxis: isCombined ? 1 : 0,
            unit: presUnit,
            color: "lightseagreen",
        },
        {
            name: "Ps",
            overlayedCycleNumber: overlayedCycleNumber,
            isOverlayedCycle: isOverlayedCycle,
            cycleStartTime,
            data: psList,
            yAxis: isCombined ? 1 : 0,
            unit: presUnit,
            color: "royalblue",
        },
        {
            name: "Pv",
            overlayedCycleNumber: overlayedCycleNumber,
            isOverlayedCycle: isOverlayedCycle,
            cycleStartTime,
            data: pvList,
            yAxis: isCombined ? 1 : 0,
            unit: presUnit,
            color: "lime",
        },
        ...(plotDutyCycle
            ? getDutyCycleSeries(dutyCycleList, overlayedCycleNumber, isOverlayedCycle, cycleStartTime)
            : []),
    ];
};

export const getDutyCycleSeries = (
    dutyCycleList,
    overlayedCycleNumber = 0,
    isOverlayedCycle = false,
    cycleStartTime = ""
) => {
    return [
        {
            name: "Duty Cycle",
            overlayedCycleNumber,
            isOverlayedCycle,
            cycleStartTime,
            data: dutyCycleList,
            unit: "",
            yAxis: "dutyCycleYAxisLabel",
            color: "black",
        },
    ];
};

export const getCombinedSeries = (
    thList,
    tvList,
    trList,
    paList,
    psList,
    pvList,
    dutyCycleList,
    plotDutyCycle,
    overlayedCycleNumber = 0,
    isOverlayedCycle = false,
    cycleStartTime = "",
    includeTemp = true,
    includePressure = true
) => {
    return [
        ...(includeTemp
            ? getTempSeries(
                  thList,
                  tvList,
                  trList,
                  [],
                  false, // always pass false in combined, in combined duty cycle has it's own series
                  overlayedCycleNumber,
                  isOverlayedCycle,
                  cycleStartTime
              )
            : []),
        ...(includePressure
            ? getPresSeries(
                  paList,
                  psList,
                  pvList,
                  [],
                  false, // always pass false in combined, in combined duty cycle has it's own series
                  overlayedCycleNumber,
                  isOverlayedCycle,
                  cycleStartTime,
                  true
              )
            : []),
        ...(plotDutyCycle
            ? getDutyCycleSeries(dutyCycleList, overlayedCycleNumber, isOverlayedCycle, cycleStartTime)
            : []),
    ];
};

export const getChartLegend = (plotDutyCycle, isCombined = true) => {
    return {
        width: plotDutyCycle ? (isCombined ? 700 : 400) : isCombined ? 600 : 300,
        itemWidth: 100,
    };
};

const translucent_colors = [
    "rgba(63, 165, 191, 0.2)",
    "rgba(63, 104, 191, 0.2)",
    "rgba(193, 66, 66, 0.2)",
    "rgba(543, 212, 312, 0.2)",
    "rgba(191, 127, 63, 0.2)",
    "rgba(193, 66, 66, 0.2)",
    "rgba(55, 66, 77, 0.2)",
    "rgba(121, 251, 121, 0.2)",
    "rgba(215, 2, 211, 0.2)",
    "rgba(55, 66, 212, 0.2)",
    "rgba(1, 2, 3, 0.2)",
    "rgba(212, 55, 12, 0.2)",
    "rgba(151, 231, 251, 0.2)",
    "rgba(151, 112, 12, 0.2)",
    "rgba(11, 121, 51, 0.2)",
    "rgba(23, 32, 51, 0.2)",
    "rgba(66, 55, 121, 0.2)",
    "rgba(35, 182, 69, 0.2)",
];

export const getStatePlotBands = (stateList) => {
    let statePlotList = [];
    let from = stateList[0][0];
    let val = stateList[0][1];
    for (let i = 0; i < stateList.length; i++) {
        const lastElement = i === stateList.length - 1;
        if (lastElement || stateList[i][1] !== stateList[i + 1][1]) {
            let to = stateList[i][0];
            let res = { name: val, from_: from, to: to };
            statePlotList.push(res);
            from = !lastElement && stateList[i + 1][0];
            val = !lastElement && stateList[i + 1][1];
        }
    }

    let statePlotColor = {};
    const uniqueState = [...new Set(statePlotList.map((item) => item.name))];
    for (let i = 0; i < uniqueState.length; i++) {
        statePlotColor[uniqueState[i]] = translucent_colors[i];
    }

    statePlotList.map((band) => {
        delete Object.assign(band, { ["from"]: band["from_"] })["from_"];
        const plot_band_label = {
            text: band.name,
            y: -5,
            rotation: 90,
            textAlign: "right",
        };
        band.color = statePlotColor[band.name];
        band.zIndex = 4;
        band.label = plot_band_label;
    });

    return statePlotList;
};

const DigitalOutputSkeleton = [
    {
        id: "vacuum_valve",
        name: "Vacuum Valve",
        color: "rgba(63, 165, 191, 0.2)",
        label: {
            text: "Vacuum Valve",
            textAlign: "center",
            verticalAlign: "bottom",
            y: 0,
        },
    },
    {
        id: "drain_valve",
        name: "Drain Valve",
        color: "rgba(63, 191, 155, 0.2)",
        label: {
            text: "Drain Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "water_valve",
        name: "Water Valve",
        color: "rgba(65, 191, 63, 0.2)",
        label: {
            text: "Water Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "top_lid_valve",
        name: "Top Lid Valve",
        color: "rgba(191, 150, 63, 0.2)",
        label: {
            text: "Top Lid Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "bottom_lid_valve",
        name: "Bottom Lid Valve",
        color: "rgba(191, 116, 63, 0.2)",
        label: {
            text: "Bottom Lid Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "shroud_valve",
        name: "Shroud Valve",
        color: "rgba(191, 116, 63, 0.2)",
        label: {
            text: "Shroud Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "accumulator_valve",
        name: "Accumulator Valve",
        color: "rgba(157, 63, 191, 0.2)",
        label: {
            text: "Accumulator Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "seal_valve",
        name: "Seal Valve",
        color: "rgba(191, 63, 155, 0.2)",
        label: {
            text: "Seal Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "jack_valve",
        name: "Jack Valve",
        color: "rgba(201, 144, 185, 0.2)",
        label: {
            text: "Jack Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "air_inlet_valve",
        name: "Air Inlet Valve",
        color: "rgba(201, 144, 145, 0.2)",
        label: {
            text: "Air Inlet Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "oil_valve",
        name: "Oil Valve",
        color: "rgba(117, 244, 142, 0.2)",
        label: {
            text: "Oil Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "seal_vacuum_valve",
        name: "Seal Vacuum Valve",
        color: "rgba(16, 214, 166, 0.2)",
        label: {
            text: "Seal Vacuum Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "vessel_valve",
        name: "Vessel Valve",
        color: "rgba(117, 244, 142, 0.2)",
        label: {
            text: "Vessel Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "odor_valve",
        name: "Odor Valve",
        color: "rgba(55, 22, 33, 0.2)",
        label: {
            text: "Odor Valve",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "shroud_2",
        name: "Shroud 2",
        color: "rgba(77, 123, 51, 0.2)",
        label: {
            text: "Shroud 2",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "deodorizer_spray",
        name: "Deodorizer Spray",
        color: "rgba(117, 55, 33, 0.2)",
        label: {
            text: "Deodorizer Spray",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "drain_valve_alt",
        name: "Drain Valve Alt",
        color: "rgba(21, 77, 142, 0.2)",
        label: {
            text: "Drain Valve Alt",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "signal_light",
        name: "Signal Light",
        color: "rgba(231, 244, 13, 0.2)",
        label: {
            text: "Signal Light",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "vac_pump",
        name: "Vacuum Pump",
        color: "rgba(241, 222, 54, 0.2)",
        label: {
            text: "Vacuum Pump",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "pressure_pump",
        name: "Pressure Pump",
        color: "rgba(137, 232, 234, 0.2)",
        label: {
            text: "Pressure Pump",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "water_pump",
        name: "Water Pump",
        color: "rgba(16, 164, 213, 0.2)",
        label: {
            text: "Water Pump",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "heater",
        name: "Heater",
        color: "rgba(213, 85, 16, 0.2)",
        label: {
            text: "Heater",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "deodorizer_led",
        name: "Deodorizer LED",
        color: "rgba(205, 123, 321, 0.2)",
        label: {
            text: "Deodorizer LED",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "grinder_on",
        name: "Grinder On",
        color: "rgba(125, 51, 35, 0.2)",
        label: {
            text: "Grinder On",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "grinder_fwd",
        name: "Grinder Fwd",
        color: "rgba(121, 232, 51, 0.2)",
        label: {
            text: "Grinder Fwd",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "oil",
        name: "Oil",
        color: "rgba(11, 22, 33, 0.2)",
        label: {
            text: "Oil",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
    {
        id: "oil_level",
        name: "Oil Level",
        color: "rgba(51, 212, 12, 0.2)",
        label: {
            text: "Oil Level",
            y: -5,
            rotation: 90,
            textAlign: "right",
        },
    },
];

export const dioPlotBands = (dioList, digitalOutput) => {
    let plotList = [];
    let from = dioList[0][0];
    let val = dioList[0][1];
    for (let i = 0; i < dioList.length; i++) {
        const lastElement = i === dioList.length - 1;
        if (lastElement || dioList[i][1] !== dioList[i + 1][1]) {
            let to = dioList[i][0];
            let res = { name: val, from_: from, to: to };
            plotList.push(res);
            from = !lastElement && dioList[i + 1][0];
            val = !lastElement && dioList[i + 1][1];
        }
    }

    let digitalOutputPlotBand = [];
    plotList.forEach((element) => {
        let currentObj = digitalOutput.find((obj) => obj.id === element.name);
        let swtichedOn = Object.keys(currentObj).filter((k) => currentObj[k] === true);
        Object.entries(swtichedOn).forEach(([key, value]) => {
            let DOdata = Object.create(DigitalOutputSkeleton.find((obj) => obj.id === value));
            DOdata["from"] = element.from_;
            DOdata["to"] = element.to;
            digitalOutputPlotBand.push(DOdata);
        });
    });
    var results = digitalOutputPlotBand.reduce(function (results, org) {
        (results[org.id] = results[org.id] || []).push(org);
        return results;
    }, {});
    return results;
};

export const getDigitalOutputPlotBands = (digitalOutputPlotList, digitalOutput) => {
    let digitalOutputPlotBand = [];
    digitalOutputPlotList.forEach((element) => {
        let currentObj = digitalOutput.find((obj) => obj.id === element.name);
        let swtichedOn = Object.keys(currentObj).filter((k) => currentObj[k] === true);
        Object.entries(swtichedOn).forEach(([key, value]) => {
            let DOdata = Object.create(DigitalOutputSkeleton.find((obj) => obj.id === value));
            DOdata["from"] = element.from_;
            DOdata["to"] = element.to;
            digitalOutputPlotBand.push(DOdata);
        });
    });
    var results = digitalOutputPlotBand.reduce(function (results, org) {
        (results[org.id] = results[org.id] || []).push(org);
        return results;
    }, {});
    return results;
};

export const getNotificationPlotLines = (notifications) => {
    let notificationPlotLines = [];
    notifications.forEach((notification) => {
        const plotData = {
            value: Date.parse(notification.time),
            width: 3,
            color: "black",
            label: {
                text: notification.message,
                x: 10,
            },
        };
        notificationPlotLines.push(plotData);
    });
    return notificationPlotLines;
};
