/*
 *  Container component for the Sterilis Summary Dashboard
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { composeHoc } from "../library/helpers";
import SummaryDashboardMain from "./SummaryDashboardMain";
import { withTranslation as translate } from "react-i18next";
import withAuth from "../withAuth";

class SummaryDashboardContainer extends React.Component {
  render() {
    return <SummaryDashboardMain {...this.props} />;
  }
}

export default composeHoc(
  translate("translations"),
  withAuth([
    "SterilisSuperUsers",
    "CustomerPortalUsers",
    "SterilisPortalUsers",
    "FSEs",
    "ExternalFSEs",
    "FactoryWorkers",
    "DistributorAdmins",
    "DistributorReadOnly",
    "DistributorFSEs",
    "SterilisWasteTypeAdmin",
    "FacilityAdmin"
  ])
)(SummaryDashboardContainer);
