/*
 *  Modal which initiates the generation of a Device's cycle report
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions Inc., all rights reserved.
 */
import React, {useState, useEffect, useContext} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";
import {generateCyclePDFData} from './library/helpers'
import {cycleReport, cycleCSV} from "./dashboard/CycleReport";
import SimpleLoader from "./SimpleLoader";
import {UserContext} from "./library/UserContext";

const Auth = new AuthService();


const CycleReportModal = props => {
  const {t} = useTranslation('translations');
  const [currentPressureUnit, setCurrentPressureUnit] = useState('PSI(g)');
  const [allowModalToBeClosed, setAllowModalToBeClosed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, _setFromDate] = useState();
  const [toDate, _setToDate] = useState();
  const userContext = useContext(UserContext);

  useEffect(() => {
    _setFromDate(moment().subtract(1, "months").format("YYYY-MM-DD"));
    _setToDate(moment().format("YYYY-MM-DD"));
  }, []);

  const setFromDate = timestamp => _setFromDate(moment(timestamp).format("YYYY-MM-DD"));
  const setToDate = timestamp => _setToDate(moment(timestamp).format("YYYY-MM-DD"));

  const generateCycleReport = (fileType) => {
    const {deviceConfigForReport, complianceReport} = props;
    setIsLoading(true);
    setAllowModalToBeClosed(false);

    Auth.fetch(
      `/api/get-device-cycles?` +
        new URLSearchParams({
          device_config_id: deviceConfigForReport["id"],
          // Add/Subtract 1 day so that the date range is inclusive
          from_date: moment.utc(fromDate).subtract("1", "days").format(),
          to_date: moment.utc(toDate).add("1", "days").format(),
          compliance_report: complianceReport,
          enable_date_adjustment: true,
        }),
      {
          method: "GET",
      }
    ).then((data) => {
        const { user } = userContext;
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const facilityInfo =
          data["device_metadata"]["device_facility_full_address"];

        //  Facility_type will be equal to 'airport' if it's an airport facility
        const isAirport =
          data["device_metadata"]["facility_type"] === "airport";

        // Comprehensive/Compliance report booleans will always be the opposite of one another
        const comprehensiveReport = !complianceReport;

        const deviceSerial = data["device_metadata"]["device_serial"];

        const cycleRows = generateCyclePDFData(
          data["device_cycles"],
          userTimezone,
          t,
          user["customer_unit_preference"]
        );

        if (fileType === "pdf") {
          cycleReport(
            facilityInfo,
            isAirport,
            comprehensiveReport,
            deviceSerial,
            currentPressureUnit,
            cycleRows
          );
        } else if (fileType === "csv") {
          cycleCSV(
            cycleRows,
            currentPressureUnit,
            comprehensiveReport,
            deviceSerial
          );
        }

        setIsLoading(false);
        setAllowModalToBeClosed(true);
        props.triggerCycleReportModal(null, null);
      })
      .catch((err) => {
        Sentry.captureException(err);
        setIsLoading(false);
        setAllowModalToBeClosed(true);
      });
  };

  const inlineStyle = {
    modal: {
      // marginTop: topMargin,
      marginTop: "0px !important",
      marginLeft: "auto",
      marginRight: "auto"
    },
    textArea: {
      marginTop: "50px",
      width: "400px"
    }
  };

  return <TransitionablePortal
    open={props.generateCycleReportModal}
    onOpen={() => setTimeout(() => document.body.classList.add("modal-fade-in"), 0)}
    transition={{animation: "scale", duration: 500}}
  >
    <Modal
      style={inlineStyle.modal}
      open={true}
      size="small"
      closeOnDimmerClick={allowModalToBeClosed}
      closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
      onClose={event => {
        document.body.classList.remove("modal-fade-in");
        props.triggerCycleReportModal(null, null);
      }}
    >
      <Header>
        <h2><span className='orange-text'>{props.deviceConfigForReport.device.serial_number} | </span>
          {t(`Generate ${props.complianceReport ? 'Compliance' : 'Comprehensive'} Cycle Report`)}</h2>
      </Header>
      {isLoading ? (
        <SimpleLoader/>
      ) : null}
      <Modal.Content className="cycle-report-modal-content">
        <div className="cycle-report-container">
          <div className="nav-group from-date-div">
            <label className="date-label">{t("Report Start Date")}</label>
            <DayPickerInput onDayChange={day => setFromDate(day)} value={fromDate}/>
          </div>
          <div className="nav-group from-date-div">
            <label className="date-label">{t("Report End Date")}</label>
            <DayPickerInput onDayChange={day => setToDate(day)} value={toDate}/>
          </div>
          <div className="nav-group">
            <label className="date-label">{t("Pressure Unit")}</label>
            <Dropdown
              value={currentPressureUnit}
              onChange={(e, data) => setCurrentPressureUnit(data.value)}
              selection
              className='psi-dropdown'
              options={
                [
                  {
                    text: 'PSI(g)',
                    value: 'PSI(g)'
                  },
                  {
                    text: 'PSI(a)',
                    value: 'PSI(a)'
                  }
                ]
              }
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="ster-btn"
          onClick={() => generateCycleReport('pdf')}
          type="submit"
          id="cycleReportPDFBtn"
        >
          {t("Generate PDF Report")}
        </Button>
        <Button
          className="ster-btn"
          onClick={() => generateCycleReport('csv')}
          type="submit"
          id="cycleReportCsvBtn"
        >
          {t("Generate CSV Report")}
        </Button>
        <div id='downloadDiv' className="download-div"/>
      </Modal.Actions>
    </Modal>
  </TransitionablePortal>
};

CycleReportModal.defaultProps = {};

CycleReportModal.propTypes = {};

export default CycleReportModal;
