/*
*  Component responsible for the Cycles per Device Operator chart on the dashboard.
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import Highcharts from "highcharts/highstock";
import {pieColors} from "../library/helpers";
import HighchartsReact from "highcharts-react-official";

class UserPieChart extends React.Component{
  state = {
    options : {},
  };
  componentDidMount(){
    //If this prop exists, the xhr calls have finished.
    if(this.props.deviceCycles){
      this.populateChart();
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.deviceCycles !== this.props.deviceCycles) {
      this.populateChart();
    }
  }

  /*
  *   All of this logic was copy & pasted from the v1 implementation.
  * */
  calculateTopUsers = (deviceCycles) => {
    const userCycles = {};
    deviceCycles.forEach((cycle) => {
      const currentUser = cycle['full_name'];
      userCycles.hasOwnProperty(currentUser) ? userCycles[currentUser]++ : userCycles[currentUser] = 1;
    });

    const props = Object.keys(userCycles).map(function (key) {
        return {key: key, value: this[key]};
    }, userCycles);
    props.sort(function (p1, p2) {
        return p2.value - p1.value;
    });
    const topUsers = props.slice(0, 5);

    const finalUserObj = {};
    finalUserObj['Other'] = 0;
    const otherUserObj = {};
    for (let user in userCycles) {
      if (this.searchObj(user, topUsers)) {
        finalUserObj[user] = userCycles[user];
      } else {
        finalUserObj['Other']+=userCycles[user];
        otherUserObj[user] = userCycles[user];
      }
    }
    //If there are 0 'Other' users, just remove the 'Other' key
    finalUserObj['Other'] === 0 && delete finalUserObj['Other'];

    const seriesData = [];
    Object.keys(finalUserObj).forEach((user) => {
      seriesData.push({
        name : user,
        y : finalUserObj[user]
      })
    });
    return seriesData;
  };

  searchObj = (nameKey, myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].key === nameKey) {
        return true;
      }
    }
    return false;
  };

  populateChart = () => {
    const {
      deviceCycles, t
    } = this.props;
    const deviceUserSeries = this.calculateTopUsers(deviceCycles);
    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: t('Cycles per Device Operator'),
        style: {
          color: '#009FC2',
          fontSize: '20px',
          fontFamily: 'Arial',
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name} <b>{point.percentage:.1f}% : {point.y} cycles</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          size: '50%',
          colors: pieColors,
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            padding: 0,
            format: `<b>{point.name}</b><br>{point.y} ${t('cycles')}<br>{point.percentage:.1f}%`,
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              fontWeight: 'normal'
            }
          }
        }
      },
      series: [{
        name : t('User'),
        colorByPoint : true,
        data : deviceUserSeries,
      }]
    };
    this.setState({
      options
    })
  };


  render() {
    return (
      <div className={this.props.graphContainerClass}>
        <div id='userPieChart' className={this.props.className}>
          <HighchartsReact
              highcharts={Highcharts}
              options={this.state.options}
              updateArgs={[true, true, true]}
            />
        </div>
      </div>
    )
  }
}

export default translate('translations')(UserPieChart);
