/*
*  Device Health table
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import HookTable from "../library/HookTable";
import {timestampSortHook} from "../library/helpers";

const DeviceHealthTable = props => {
  const {
    t,
    criticalFilter,
    fromDate,
    toDate
  } = props;

  const customRowClass = (rowInfo) => {
    if (rowInfo && rowInfo.original) {
      if (rowInfo.original.error_category === "Critical") {
        return 'red-tr'
      } else if (rowInfo.original.error_category === "Major") {
        return 'light-red-tr'
      } else if (rowInfo.original.error_category === "Minor") {
        return 'light-yellow-tr'
      }
    }
  };

  const columns = [
    {
      Header: t('Failed at'),
      accessor: 'error_timestamp',
      id: 'error_timestamp',
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss z")
    },
    {
      Header: t('Device Serial'),
      accessor: 'device_serial',
      id: 'device_serial',
    },
    {
      Header: t('Facility'),
      accessor: 'facility',
      id: 'facility',
    },
    {
      Header: t('Customer'),
      accessor: 'customer',
      id: 'customer',
    },
    {
      Header: t('Error code'),
      accessor: 'error_code',
      id: 'error_code',
    },
    {
      Header: t('Description'),
      accessor: 'error_desc',
      id: 'error_desc',
    },
    {
      Header: t('Machine state'),
      accessor: 'machine_state',
      id: 'machine_state',
    },
    {
      Header: t('Category'),
      accessor: 'error_category',
      id: 'error_category',
    }
  ];

  let deviceHealthTable = props.deviceHealthTableArray
      if (criticalFilter) {
        deviceHealthTable = deviceHealthTable.filter(row => {
          if (criticalFilter === 'critical') {
            return row['error_category'] === 'Critical' && row['error_date'] >= fromDate && row['error_date'] <= toDate;
          } else if (criticalFilter === 'major') {
            return row['error_category'] === 'Major' && row['error_date'] >= fromDate && row['error_date'] <= toDate;
          } else if (criticalFilter === 'minor') {
            return row['error_category'] === 'Minor' && row['error_date'] >= fromDate && row['error_date'] <= toDate;
          }else {
            return row['error_date'] >= fromDate && row['error_date'] <= toDate;
          }
        })
      }

  return (
    <HookTable
      id='deviceHealthTable'
      data={deviceHealthTable}
      columns={columns}
      tableName='deviceHealthTable'
      defaultSortBy={{id: 'error_timestamp', desc: true}}
      customRowClass={true}
      customRowClassFunc={customRowClass}
    />
  )
};
export default translate('translations')(DeviceHealthTable);
