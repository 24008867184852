import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SubCard from "src/components/material/Cards/SubCard";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { fetchDeviceTimeline } from "../deviceInfoSlice";
import { dateTimeFilterRegex, isNotEmptyObject } from "src/components/library/helpers";

const DeviceTimeline = (props) => {
    const { t } = useTranslation("translations");
    const { deviceTimelineList, selectedDeviceConfig } = useSelector((store) => store.deviceInfo);
    const loading = useSelector((store) => store.deviceInfo.loading.timeline);
    const theme = useTheme();
    const dispatch = useDispatch();

    const Toolbar = (props) => {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    const columns = [
        {
            headerName: t("Assignment Date"),
            type: "dateTime",
            field: "created_date",
            valueGetter: ({ value }) => {
                return value && new Date(value);
            },
            headerAlign: "center",
            headerClassName: "config-logs-table--header",
            align: "center",
            flex: 1,
            getApplyQuickFilterFn: (value) => {
                if (!value || !dateTimeFilterRegex.test(value)) {
                    return null;
                }
                return (params) => {
                    if (params.value instanceof Date) {
                        return params.value.toLocaleString().includes(value);
                    }
                };
            },
        },
        {
            headerName: t("Removal Date"),
            field: "deleted_date",
            type: "dateTime",
            valueGetter: ({ value }) => {
                return value && new Date(value);
            },
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
            getApplyQuickFilterFn: (value) => {
                if (!value || !dateTimeFilterRegex.test(value)) {
                    return null;
                }
                return (params) => {
                    if (params.value instanceof Date) {
                        return params.value.toLocaleString().includes(value);
                    }
                };
            },
        },
        {
            headerName: t("Customer"),
            field: "customer",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("Facility"),
            field: "facility",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("Cycle Count"),
            field: "cycle_count",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("Error Count"),
            field: "error_count",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
    ];

    useEffect(() => {
        selectedDeviceConfig && dispatch(fetchDeviceTimeline(selectedDeviceConfig?.device?.id));
    }, [selectedDeviceConfig]);

    return (
        isNotEmptyObject(selectedDeviceConfig) && (
            <SubCard
                sx={{
                    width: "100%",
                    "& .config-logs-table--header": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                    },
                }}>
                <DataGrid
                    disableSelectionOnClick
                    autoHeight
                    columns={columns}
                    rows={deviceTimelineList}
                    loading={loading}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "created_date", sort: "desc" }],
                        },
                    }}
                    sx={{
                        fontSize: 14,
                        padding: 2,
                        borderRadius: 4,
                    }}
                    components={{ Toolbar: Toolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </SubCard>
        )
    );
};

DeviceTimeline.propTypes = {};

export default DeviceTimeline;
