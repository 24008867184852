import {
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchCustomerDevices,
    setSelectedDevice,
    setDeviceConfigId,
    setCustomerDevicesOnly,
} from "../DeviceDashboard/deviceDashboardSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const DeviceDropdown = () => {
    // @ts-ignore
    const { filteredCustomerDevices, customerDevices, selectedDevice, customerDevicesOnly } = useSelector(
        // @ts-ignore
        (store) => store.devicedashboard
    );
    // @ts-ignore
    const loading = useSelector((store) => store.devicedashboard.loading.devices);

    const dispatch = useDispatch();
    const history = useHistory();

    const [devices, setDevices] = React.useState(filteredCustomerDevices);

    useEffect(() => {
        if (customerDevicesOnly) {
            setDevices(filteredCustomerDevices);
        } else {
            setDevices(customerDevices);
        }
    }, [customerDevicesOnly, customerDevices, filteredCustomerDevices]);

    const handleChange = (event, newValue) => {
        dispatch(setSelectedDevice(newValue));
        dispatch(setDeviceConfigId(newValue.id));
        history.push(`/sa/dashboard/${newValue.id}`);
    };

    return (
        <Stack direction={"row"} spacing={1}>
            <Autocomplete
                autoHighlight
                fullWidth
                id="device-dropdown"
                value={selectedDevice}
                options={devices}
                onChange={(event, newVal) => {
                    handleChange(event, newVal);
                }}
                getOptionLabel={(option) => option.label}
                sx={{ width: { md: 300, xl: 600 } }}
                renderInput={(params) => (
                    <TextField
                        size="small"
                        {...params}
                        label="Devices"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="secondary" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        defaultChecked
                        value={customerDevicesOnly}
                        onChange={(event) => dispatch(setCustomerDevicesOnly(event.target.checked))}
                    />
                }
                label="Customer Devices Only"
            />
        </Stack>
    );
};

export default DeviceDropdown;
