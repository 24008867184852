/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  dutyCycleAvgSeries,
  dutyCycleAvgYAxis, dutyCycleEndSeries,
  dutyCycleEndYAxis,
  dutyCycleStartSeries,
  dutyCycleStartYAxis
} from "./ChartHelpers";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const SharedCycleTrendsChart = props => {
  const sharedCycleTrendsOptions = {
    chart: {
      zoomType: 'x',
      resetZoomButton: {
        position: {
          x: 0,
          y: 0,
        }
      }
    },
    tooltip: {
      shared: true,
    },
    title: {
      text: 'Shared Cycle Trends'
    },
    credits: false,
    xAxis: [{
      categories: props.cycleStartTimes,
      crosshair: true,
    }],
    yAxis: [
      ...props.trendDutyCycleAvg && dutyCycleAvgYAxis(props),
      ...props.trendDutyCycleStart && dutyCycleStartYAxis(props),
      ...props.trendDutyCycleEnd && dutyCycleEndYAxis(props),
    ],
    series: [
      ...props.trendDutyCycleAvg && dutyCycleAvgSeries(props),
      ...props.trendDutyCycleStart && dutyCycleStartSeries(props),
      ...props.trendDutyCycleEnd && dutyCycleEndSeries(props)
    ],
  };

  return <HighchartsReact
    highcharts={Highcharts}
    options={sharedCycleTrendsOptions}
  />
};

SharedCycleTrendsChart.defaultProps = {
  trendDutyCycleAvg: true,
  trendDutyCycleStart: true,
  trendDutyCycleEnd: true,

  dutyCycleAvgTrend: [],
  dutyCycleStartTrend: [],
  dutyCycleEndTrend: [],

  abnormalDutyCycleAvgValues: {},
  abnormalDutyCycleStartValues: {},
  abnormalDutyCycleEndValues: {},

};

SharedCycleTrendsChart.propTypes = {
  trendDutyCycleAvg: PropTypes.bool,
  trendDutyCycleStart: PropTypes.bool,
  trendDutyCycleEnd: PropTypes.bool,

  dutyCycleAvgTrend: PropTypes.array,
  dutyCycleStartTrend: PropTypes.array,
  dutyCycleEndTrend: PropTypes.array,

  abnormalDutyCycleAvgValues: PropTypes.object,
  abnormalDutyCycleStartValues: PropTypes.object,
  abnormalDutyCycleEndValues: PropTypes.object,
};

export default SharedCycleTrendsChart;
