/*
 * Simple loading spinner component
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */

import React from "react";
import { Backdrop, CircularProgress, Typography, Box } from "@mui/material";

class SimpleBackdrop extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <Backdrop
            sx={{
              position: "absolute",
              backgroundColor: "rgba(255,255,255,.85)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
            <Box
              sx={{
                top: 100,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2" component="div">
                {this.props.loaderText}
              </Typography>
            </Box>
            <Box
              sx={{
                top: 200,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.props.cancelButton && this.props.cancelButton}
            </Box>
          </Backdrop>
        </div>
      </React.Fragment>
    );
  }
}

export default SimpleBackdrop;
