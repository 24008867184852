import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const NoCycles = () => {
    return (
        <Card>
            <CardContent>
                <Typography variant="h2" color={"secondary.light"} textAlign={"center"}>
                    No Cycles Found.
                </Typography>
            </CardContent>
        </Card>
    );
};

export default NoCycles;
