import React from 'react';
import { withTranslation as translate} from "react-i18next";

const ErrorPage = () => (
  <main className='error-page-container center-text'>
    <h2>Oh no, something went wrong!</h2>
    {/*<div className='machine-icon-big'>*/}
      {/*<img src={sadDevice} alt="Sad Sterilis Device"/>*/}
    {/*</div>*/}
    <h3>We're sorry about the inconvenience.</h3>
    <h3 className='orange-text'>The software team has been notified and they will work to fix the problem.</h3>
  </main>
);

export default translate('translations')(ErrorPage);