/*
*  Form for creating Distributors
*
* Copyright (C) 2018, 2019, 2020 Sterilis Medical Inc., all rights reserved.
*/

import React, {useEffect, useState} from "react";
import {FloatLabelDropdown, FloatLabelInput} from "../../library/FloatLabelInputs";
import {useFormContext} from "react-hook-form";
import AuthService from "../../AuthService";
import {useTranslation} from "react-i18next";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

const DistributorForm = props => {
  const {t} = useTranslation('translations');
  const {setValue} = useFormContext();
  const [distributorDropdownOptions, setDistributorDropdownOptions] = useState([]);

  useEffect(() => {
    getDistributors()
  }, []);

  const getDistributors = () => {
    return Auth.fetch(`/api/distributor/`)
      .then(data => {
        const distributorDropdown = data.map(distributor => {
          return {
            key: distributor['id'],
            value: distributor['id'],
            text: distributor['distributor_name'],
          }
        });
        setDistributorDropdownOptions(distributorDropdown)
      })
      .catch(err => {
        Sentry.captureException(err);
      })
  };

  // If the user is trying to create a Distributor, we need to show them the Distributor name input field
  const createDist = props['create'] === 'distributor';
  return <React.Fragment>
    {createDist ?
      <FloatLabelInput
        registerParams={{required: 'Required'}}
        required={true}
        name={'distributorName'}
        labelText={t('Distributor Name')}
        errors={props['errors']}
      /> :
      <FloatLabelDropdown
        registerParams={{required: true}}
        name={'customerDistributor'}
        labelText={'Distributor'}
        options={distributorDropdownOptions}
        inputClass='forty-height'
        useFirstItemAsDefault={true}
        onChangeCallback={() => {
          setValue('facilityCustomer', '');
        }}
      />
    }
  </React.Fragment>
};

export default DistributorForm;