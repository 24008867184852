import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {},
    group: localStorage.getItem("group_token"),
    selectedUserTypeDisplay: "",
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setGroup(state, action) {
            state.group = action.payload;
        },
        setSelectedUserTypeDisplay(state, action) {
            state.selectedUserTypeDisplay = action.payload;
        },
    },
});

export const { setUser, setGroup, setSelectedUserTypeDisplay } = userSlice.actions;

export default userSlice.reducer;
