import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Toolbar, IconButton, Box, useTheme, CssBaseline } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SterilisLogoWhite from "../../../images/sterilis-white.png";
import UserMenu from "./UserMenu";
import AppDrawer from "./AppDrawer";
import { Link, useLocation } from "react-router-dom";
import AuthService from "src/components/AuthService";
import { isGroupSterilisOrDist } from "src/components/library/helpers";
import ExportAllErrorsModal from "src/components/export/all_errors/ExportAllErrorsModal";
import CustomerDeviceReportModal from "src/components/export/customer_device_report/CustomerDeviceReportModal";
import ExportDeviceConfigsModal from "src/components/export/configs/ExportDeviceConfigsModal";
import DeviceSpreadsheetModal from "src/components/export/device_spreadsheet/DeviceSpreadsheetModal";
const Auth = new AuthService();

const drawerWidth = 300;

const drawerRequiredRoles = [
    "SterilisSuperUsers",
    "SterilisPortalUsers",
    "FSEs",
    "ExternalFSEs",
    "FactoryWorkers",
    "DistributorAdmins",
    "DistributorReadOnly",
    "DistributorFSEs",
    "SterilisWasteTypeAdmin",
];

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
    // @ts-ignore
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const ToolBar = (props) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [showAllErrorsModal, setShowAllErrorsModal] = React.useState(false);
    const [showCustomerDeviceModal, setShowCustomerDeviceModal] = React.useState(false);
    const [showDeviceConfigsModal, setShowDeviceConfigsModal] = React.useState(false);
    const [showDeviceSpreadsheetModal, setShowDeviceSpreadsheetModal] = React.useState(false);
    const group = Auth.getGroup();
    const navLink = isGroupSterilisOrDist(group) ? "/" : "/sa";
    const location = useLocation();
    useEffect(() => {
        // force a re-render on location change... REALLY not the best way to do this, but
        // I can't figure out how to get the Nav to re-render when logout/login happens otherwise
    }, [location]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleAllErrorsModal = () => {
        setShowAllErrorsModal(!showAllErrorsModal);
    };
    const handleCustomerDeviceModal = () => {
        setShowCustomerDeviceModal(!showCustomerDeviceModal);
    };
    const handleDeviceConfigsModal = () => {
        setShowDeviceConfigsModal(!showDeviceConfigsModal);
    };
    const handleDeviceSpreadsheetModal = () => {
        setShowDeviceSpreadsheetModal(!showDeviceSpreadsheetModal);
    };

    return Auth.loggedIn() ? (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                // @ts-ignore
                open={open}
                component="nav">
                <Toolbar>
                    {drawerRequiredRoles.includes(group) ? (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                color: theme.palette.common.white,
                                ...(open && { display: "none" }),
                            }}>
                            <MenuIcon />
                        </IconButton>
                    ) : null}

                    <Box sx={{ flexGrow: 1 }}>
                        <Link to={navLink}>
                            <img
                                src={SterilisLogoWhite}
                                style={{ marginTop: 5 }}
                                height="40"
                                width="40"
                                alt="Sterilis Logo"
                            />
                        </Link>
                    </Box>
                    <UserMenu group={group} username={Auth.getProfile().username} />
                </Toolbar>
            </AppBar>
            {drawerRequiredRoles.includes(group) ? (
                <>
                    <AppDrawer
                        handleAllErrorsModal={handleAllErrorsModal}
                        handleCustomerDeviceModal={handleCustomerDeviceModal}
                        handleDeviceConfigsModal={handleDeviceConfigsModal}
                        handleDeviceSpreadsheetModal={handleDeviceSpreadsheetModal}
                        group={group}
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                    />
                    <ExportAllErrorsModal
                        triggerExportAllErrorsModal={handleAllErrorsModal}
                        exportAllErrorsModal={showAllErrorsModal}
                    />
                    <CustomerDeviceReportModal
                        triggerCustomerDeviceReportModal={handleCustomerDeviceModal}
                        customerDeviceReportModal={showCustomerDeviceModal}
                    />
                    <ExportDeviceConfigsModal
                        triggerExportDeviceConfigsModal={handleDeviceConfigsModal}
                        exportDeviceConfigsModal={showDeviceConfigsModal}
                        group={group}
                    />
                    <DeviceSpreadsheetModal
                        triggerDeviceSpreadsheetModal={handleDeviceSpreadsheetModal}
                        showDeviceSpreadsheetModal={showDeviceSpreadsheetModal}
                    />
                </>
            ) : null}
        </>
    ) : null;
};

ToolBar.propTypes = {
	open: PropTypes.bool,
	handleDrawerOpen: PropTypes.func,
	drawerWidth: PropTypes.number,
};

export default ToolBar;
