/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {
  calculateCycleTime,
  copyToClipboard,
  getDisplayWeight,
  prettyWastetypes,
  timestampSortHook,
  cleanCycleType
} from "../library/helpers";
import {useTranslation} from "react-i18next";
import get from "lodash/get";
import {Button, Icon} from "semantic-ui-react";
import {cycleCSV, cycleReport} from "./CycleReport";
import HookTable from "../library/HookTable";
import {UserContext} from "../library/UserContext";

const DashboardCycleTable = props => {
  const [cycleTableArray, setCycleTableArray] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const {t} = useTranslation('translations');

  const userContext = useContext(UserContext);
  const {
    user
  } = userContext;

  const cycleTimeJSX = cycle => {
    const cycleTime = calculateCycleTime(cycle['time_started'], cycle['time_ended'], cycle['latest_cycle_event_timestamp'], cycle['latest_cycle_status_timestamp']);
    const steamTime = Math.round(cycle['steam_time']);
    const cookTime = Math.round(cycle['cook_time']);
    return <div data-cycletime={cycleTime}>
      <div>{t('Cycle')}: {cycleTime}</div>
      <div className='mini-padding-top'>{t('Steam')}: {steamTime} {steamTime > 1 ? t('mins') : t('min')}</div>
      <div className='mini-padding-top'>{t('Cook')}: {cookTime} {cookTime > 1 ? t('mins') : t('min')}</div>
    </div>;
  };

  useEffect(() => {
    const {
      deviceCycles,
      userTimezone,
      deviceTimezone,
    } = props;
    // If deviceCycles exists, the xhr calls have finished
    if (deviceCycles.length > 0) {
      const cycleTableArray = deviceCycles.map((cycle, idx) => {
        // if the device timezone is present, use that one, if not, use the user's
        const cycleTime = moment.utc(cycle['time_started']).tz(deviceTimezone ? deviceTimezone : userTimezone).format("YYYY-MM-DD HH:mm:ss z");
        if (!cycle['time_ended']) {
          setHighlightedIndex(idx);
        }
        const steamTime = Math.round(cycle['steam_time']);
        const cookTime = Math.round(cycle['cook_time']);
        return {
            time_started: cycleTime,
            cycle_id: cycle["cycle_id"],
            weight: getDisplayWeight(cycle["weight"], cycle["waste_type"], user["customer_unit_preference"]),
            waste_type: t(prettyWastetypes(cycle["waste_type"])),
            cycle_type: t(cleanCycleType(cycle["cycle_type"])),
            cycle_state: t(cycle["cycle_state"]),
            time_ended: cycle["time_ended"],
            cycle_time_jsx: cycleTimeJSX(cycle),
            cycle_time: calculateCycleTime(
                cycle["time_started"],
                cycle["time_ended"],
                cycle["latest_cycle_event_timestamp"],
                cycle["latest_cycle_status_timestamp"]
            ),
            user: cycle["full_name"],
            max_pres_A: parseFloat(cycle["max_pvA"]).toFixed(2),
            max_pres_G: parseFloat(cycle["max_pvG"]).toFixed(2),
            max_temp: cycle["max_tv"],
            serial_number: cycle["serial_number"],
            simple_time_started: cycleTime,
            steam_time: `${steamTime} ${steamTime > 1 ? t("mins") : t("min")}`,
            cook_time: `${cookTime} ${cookTime > 1 ? t("mins") : t("min")}`,
            cycle_comments: cycle["cycle_comments"],
        };
      });

      setCycleTableArray(cycleTableArray);
    }
  }, [props.deviceCycles]);

  const cycleClick = (row) => {
    const cycleID = row['original']['cycle_id'];
    setSelectedIndex(row.index);

    // \t character didn't work in format string, using 5 spaces instead
    const copyString = `${get(row, "original.simple_time_started", "")}     ${get(
        row,
        "original.weight",
        ""
    )}     ${get(row, "original.waste_type", "")}     ${get(row, "original.cycle_type", "")}     ${get(
        row,
        "original.cycle_time",
        ""
    )}     ${get(row, "original.user", "")}     Cycle: ${row["original"]["time_started"]["key"]}`;

    copyToClipboard(copyString);
    props.fetchCycleEvents(cycleID)
  };

  const cycleTableContainer = () => {
    if (props.cycleTableIsExpanded) {
      return 'expanded-cycle-table'
    } else if (props.dashboardSize === 'medium' || props.dashboardSize === 'mobile') {
      return 'full-cycle-table'
    } else {
      return 'cycle-table'
    }
  };

  const columns = [
      {
          Header: t("Date & time"),
          accessor: "time_started",
          id: "time_started",
          manualWidth: 100,
          sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss z"),
      },
      {
          Header: t("Weight"),
          accessor: "weight",
          // manualWidth: 65,
      },
      {
          Header: t("Load type"),
          accessor: "waste_type",
          manualWidth: 80,
      },
      {
          Header: t("Cycle type"),
          accessor: "cycle_type",
      },
      {
          Header: t("Cycle status"),
          accessor: "cycle_state",
      },
      {
          Header: t("Cycle times"),
          accessor: "cycle_time_jsx",
          manualWidth: 130,
          sortType: (a, b, columnID) => {
              // values look like '86 mins'; split on space and take the minute number
              const aValue = Number(a.original[columnID]["props"]["data-cycletime"].split(" ")[0]);
              const bValue = Number(b.original[columnID]["props"]["data-cycletime"].split(" ")[0]);
              return aValue < bValue ? 1 : -1;
          },
      },
      {
          Header: t("User"),
          accessor: "user",
      },
  ];


  return <div className={cycleTableContainer()}>
    <div className='cycle-table-header slight-margin-bottom'>
      <div className='cycle-report-div'>
        <h2>{t('Cycle Report')}</h2>
        <span className='orange-text'>{t('The active cycle is displayed in this color')}.</span>
      </div>
      <div className='btn-div'>
        <div className='report-btn-div'>
          {cycleTableArray.length > 0 ?
            <React.Fragment>
              <Button
                className='mini-btn ster-btn'
                onClick={() => cycleReport(props.deviceFacilityFullAddress,
                  props.isAirport,
                  props.comprehensiveReport,
                  props.deviceSerial,
                  props.currentPressureUnit,
                  cycleTableArray
                )}
              >
                PDF
              </Button>
              <Button
                className='mini-btn ster-btn'
                onClick={() => cycleCSV(
                  cycleTableArray,
                  props.currentPressureUnit,
                  props.comprehensiveReport,
                  props.deviceSerial,
                )}
              >
                CSV
              </Button>
            </React.Fragment> : null
          }
          <Button
            id='comprehensiveReportBtn'
            className={`${props.comprehensiveReport ? 'activated-btn ' : 'unactivated-btn '}
                dashboard-btn report-btn left-btn-no-padding`}
            onClick={props.setComprehensiveReport}
          >
            {t('Comprehensive')}
          </Button>
          <Button
            id='complianceReportBtn'
            className={`${props.complianceReport ? 'activated-btn ' : 'unactivated-btn '} dashboard-btn report-btn`}
            onClick={props.setComplianceReport}
          >
            {t('Compliance')}
          </Button>
        </div>
        <Icon
          className='expand-btn'
          onClick={props.expandCycleTable}
          size='large'
          name='expand arrows alternate'
        />
      </div>

    </div>
    <HookTable
      id='cycleTable'
      data={cycleTableArray}
      columns={columns}
      renderEmptyRow={false}
      defaultSortBy={{id: 'time_started', desc: true}}
      tableName='cycleTable'
      onClickCallback={cycleClick}
      highlightedIndex={highlightedIndex}
      selectedIndex={selectedIndex}
      enableOnRowClickCallback={true}
    />
    <div id='downloadDiv' className="download-div"/>
  </div>
};

DashboardCycleTable.defaultProps = {
  deviceCycles: [],
};

DashboardCycleTable.propTypes = {
  deviceCycles: PropTypes.array,
  fetchCycleEvents: PropTypes.func.isRequired,
  setComprehensiveReport: PropTypes.func.isRequired,
  setComplianceReport: PropTypes.func.isRequired,
  expandCycleTable: PropTypes.func.isRequired,
};

export default DashboardCycleTable;
