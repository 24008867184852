/*
*  Modal which can reset the activation code for a device
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../../SimpleLoader";
import AuthService from "../../AuthService";
import * as Sentry from "@sentry/browser";
import moment from "moment";
const Auth = new AuthService();

class ResendActivationCodeModal extends React.Component{
  state = {
    putFinished : false,
    modalLoading : false,
    allowModalToBeClosed: true,
  };
  componentDidMount(){
    this.setState({
      resendActivationCodeDeviceID : this.props.modalDeviceID,
      resendActivationCodeDeviceSerial : this.props.modalSerial,
      resendActivationCodeCustomerName: this.props.modalCustomerName,
      resendActivationCodeFacilityName: this.props.modalFacilityName,
    })
  }
  resetInitKey = () => {
    const {
      resendActivationCodeDeviceID,
      resendActivationCodeDeviceSerial
    } = this.state;

    const activationCode = Math.floor(1000 + Math.random() * 9000);

    this.setState({
      modalLoading : true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/device/${resendActivationCodeDeviceID}/`, {
      method : 'PATCH',
      body: JSON.stringify({
        'reset_password_key' : activationCode,
        'reset_password_request_time' : moment().format(),
        'serial_number' : resendActivationCodeDeviceSerial,
        'activated' : 0
      })
    }).then(data => {
      this.setState({
        modalLoading : false,
        putFinished : true,
        allowModalToBeClosed: true,
        activationCode
      }, () => this.props.preparePage(this.props.prepareParameter));
    }).catch(err => {
      Sentry.captureException(err);
      this.props.notifyFailure();
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: true,
      });
    });


  };
  render() {
    const {
      t,
      fromTable
    } = this.props;
    const {
      resendActivationCodeDeviceSerial,
      resendActivationCodeCustomerName,
      resendActivationCodeFacilityName,
      modalLoading,
      putFinished,
      activationCode
    } = this.state;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.resendActivationCodeModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='small'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerResendActivationCodeModal(null);
          }}
        >
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2 className='red'>{t('Reset initialization key for')} {resendActivationCodeDeviceSerial}</h2>
          </Header>
          <Modal.Content>
            {putFinished ?
              <div className='short-modal-text center-text'>
                <p>{t('To initialize the device')}, {t('input this code')}:</p>
                <h2>{activationCode}</h2>
                {fromTable &&
                <p>{t('This key will be underneath this device\'s actions menu while it\'s active')}.</p>
                }
              </div>
              :
              <div className='short-modal-text center-text'>
                <p className='slight-padding-bottom'>{t('Are you sure you want to reset the initialization key for this device')}?</p>
                <hr/>
                <p><span className='ster-blue'><strong>{t('Device Serial')}: </strong></span> {resendActivationCodeDeviceSerial}</p>
                <p><span className='ster-blue'><strong>{t('Customer')}: </strong></span>{resendActivationCodeCustomerName}</p>
                <p><span className='ster-blue'><strong>{t('Facility')}: </strong></span>{resendActivationCodeFacilityName}</p>
                <hr/>
                <p className='slight-padding-top'>{t('The device will need to be reinitialized with the new code before it can run a cycle')}.</p>
              </div>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button className={putFinished ? 'grey-btn' : 'red-btn'}
                    value="Submit"
                    type="submit"
                    id='resetInitializationKey'
                    onClick={this.resetInitKey}
                    >{putFinished ? t('Reset Key again') : t('Reset Key')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(ResendActivationCodeModal);
