/*
* Small functional component to display a warning when archiving/suspending a customer/facility
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

const warningIcon = <Icon className='red' size='large' name='warning sign'/>;

const CycleReportWarning = props => {
  const {
    action,
    noun,
    actionPastTense,
    name,
  } = props;
  const { t } = useTranslation('translations');
  return (
    <div className="cycle-report-warning__container">
      <div className="cycle-report-warning__header">
        {warningIcon} <p>
        <Trans ns="translations" i18nKey="cycle-report-warning.header">
          Before you {{action}} this {{noun}},
        </Trans>
      </p> {warningIcon}
      </div>
      <div className="cycle-report-warning__inner-div">
        <p>{t('cycle-report-warning.1', 'You may want to generate a Customer Cycle Report for them.')}</p>
        {props.noun === 'customer' &&
        <p>
          <Trans ns="translations" i18nKey="cycle-report-warning.2">
            Once the customer has been {{actionPastTense}}, their portal users will no longer be able to generate the reports for themselves.
          </Trans>
        </p>
        }
        <Link target="_blank" to={`/sa?action=customer-cycle-report?customer_id=${props.customerID}`}>
          <Trans ns="translations" i18nKey="cycle-report-warning.3">
              Click here to generate a Customer Cycle Report for {{name}}
          </Trans>
        </Link>
      </div>
    </div>
  );
};

export default CycleReportWarning;
