/*
* Re-usable component for Device selection. Includes Customer Only and Distributor filtration
* Uses a RHF powered dropdown
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {FloatLabelDropdown} from "./FloatLabelInputs";
import {Checkbox} from "semantic-ui-react";
import DistributorDropdownFilter from "../view/distributors/DistributorDropdownFilter";
import {AuthReq, sterilisCustomers} from "./helpers";
import {useTranslation} from "react-i18next";

const DeviceDropdown = props => {
  const [showDistributorDeviceFilter, setShowDistributorDeviceFilter] = useState(false);
  const [showCustomerDevicesOnly, setShowCustomerDevicesOnly] = useState(props.showCustomerDevicesOnly);
  const [distributorFilter, setDistributorFilter] = useState(0);
  const [deviceDropdownOptions, setDeviceDropdownOptions] = useState([]);
  const {t} = useTranslation('translations');

  const filterDropdown = () => {
    let filteredDevices = props.deviceDropdownOptions;

    if (showCustomerDevicesOnly) {
      filteredDevices = filteredDevices.filter(deviceDropdownItem => !sterilisCustomers.includes(deviceDropdownItem['customer_id']));
    }

    if(distributorFilter){
      filteredDevices = filteredDevices.filter(deviceDropdownItem => distributorFilter === deviceDropdownItem['distributor_id']);
    }

    setDeviceDropdownOptions(filteredDevices);
  };


  useEffect(() => {
    setDeviceDropdownOptions(props.deviceDropdownOptions);
    filterDropdown();
  }, [props.deviceDropdownOptions.length]);

  useEffect(() => {
    filterDropdown();
  }, [showCustomerDevicesOnly, distributorFilter, showDistributorDeviceFilter]);

  const toggleShowDistributorDeviceFilter = () => {
    if(showDistributorDeviceFilter){
      setDistributorFilter(0)
    }
    setShowDistributorDeviceFilter(!showDistributorDeviceFilter)
	};

  return <div className='flex-dir-column'>
    <AuthReq
      userGroup={props.group}
      requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers','SterilisWasteTypeAdmin']}
    >
      <Checkbox
        label={{
          children: t('Filter by distributor'),
        }}
        name='filterDevicesByDistributor'
        id='filterDevicesByDistributor'
        className='slight-margin-bottom'
        checked={showDistributorDeviceFilter}
        onChange={toggleShowDistributorDeviceFilter}
      />
      {showDistributorDeviceFilter &&
      <DistributorDropdownFilter
        group={props.group}
        onChange={(event, {value}) => setDistributorFilter(value)}
        selectedDistributor={distributorFilter}
        distributorDropdownArray={props.distributorDropdownArray}
        containerClass='form-group'
        filterLabel='Filter Devices by Distributor'
        labelTextColor='ster-blue'
      />
      }
      {
        props.showCustomerDevicesOnlyCheckbox &&
        <Checkbox
          label={{
            children: t('Show Customer Devices Only'),
          }}
          name='customerDevicesOnly'
          id='customerDevicesOnly'
          className='slight-margin-bottom'
          checked={showCustomerDevicesOnly}
          onChange={() => setShowCustomerDevicesOnly(!showCustomerDevicesOnly)}
        />
      }
    </AuthReq>
    <FloatLabelDropdown
      registerParams={props.dropdownRegisterParams}
      name={props.dropdownName}
      labelText={props.dropdownLabel}
      boldHeader={true}
      options={deviceDropdownOptions}
      inputClass={props.dropdownInputClass}
      useFirstItemAsDefault={props.dropdownFirstItemAsDefault}
    />
  </div>
};

DeviceDropdown.defaultProps = {
  showCustomerDevicesOnlyCheckbox: true,
  showCustomerDevicesOnly: true,
  dropdownRegisterParams: {},
  dropdownName: '',
  dropdownLabel: '',
  deviceDropdownOptions: [],
  distributorDropdownArray: [],
  dropdownInputClass: '',
  dropdownFirstItemAsDefault: false,
};

DeviceDropdown.propTypes = {
  showCustomerDevicesOnlyCheckbox: PropTypes.bool,
  showCustomerDevicesOnly: PropTypes.bool,
  dropdownRegisterParams: PropTypes.object,
  dropdownName: PropTypes.PropTypes.string,
  dropdownLabel: PropTypes.PropTypes.string,
  deviceDropdownOptions: PropTypes.PropTypes.array,
  distributorDropdownArray: PropTypes.PropTypes.array,
  dropdownInputClass: PropTypes.PropTypes.string,
  dropdownFirstItemAsDefault: PropTypes.bool,
};

export default DeviceDropdown;
