/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import DashboardNav from "./DashboardNav";

const DashboardNoCycles = props => (
  <div className='customer-container'>
    <DashboardNav
      toDateClick={props.toDateClick}
      fromDateClick={props.fromDateClick}
      fromDate={props.fromDate}
      toDate={props.toDate}
      prepareDashboard={props.prepareDashboard}
      customerMachines={props.customerMachines}
      selectDevice={props.selectDevice}
      deviceConfigID={props.deviceConfigID}
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
      filterCustomerDevices={props.filterCustomerDevices}
    />
    <div className="no-cycles-found-dialogue">
      <h2>There was an issue retrieving data for this device.</h2>
      <h3>This device has not run any cycles between the given date range.</h3>
    </div>
  </div>
);


export default translate('translations')(DashboardNoCycles);
