import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import CycleReportTable from "./CycleReportTable";
import { useTranslation } from "react-i18next";
import ReportTypeToggle from "./ReportTypeToggle";
import SubCard from "src/components/material/Cards/SubCard";

const CycleReportContainer = () => {
    const { t } = useTranslation("translations");
    return (
        <SubCard
            sx={{ width: "100%" }}
            title={t("Cycle Report")}
            titleProps={{ color: "primary", fontWeight: "bold" }}
            titleVariant={"h4"}
            secondary={<ReportTypeToggle />}>
            <CardContent sx={{ padding: 0 }}>
                <CycleReportTable />
            </CardContent>
        </SubCard>
    );
};

export default CycleReportContainer;
