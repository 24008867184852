import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "src/components/AuthService";

const auth = new AuthService();

const initialState = {
    loading: false,
    editUser: null,
    updatedUser: {
        id: null,
    },
    updatedEmployee: {
        id: null,
    },
    updatedOperator: {
        id: null,
    },
    userCustomer: null,
    pinMin: 4,
    pinMax: 7,
    editUserModalOpen: false,
    failed: false,
    successText: "",
};

const createUserSlice = createSlice({
    name: "editUser",
    initialState,
    reducers: {
        setEditUser(state, action) {
            state.editUser = action.payload;
            state.updatedUser.id = action.payload?.id;
            state.updatedEmployee.id = action.payload?.employee.id;
            state.updatedOperator.id =
                action.payload.employee?.device_user.length > 0 ? action.payload.employee.device_user[0].id : null;
        },
        setUpdatedUser(state, action) {
            state.updatedUser = action.payload;
        },
        updateUserField(state, action) {
            state.updatedUser[action.payload?.field] = action.payload?.value;
        },
        updateEmployeeField(state, action) {
            state.updatedEmployee[action.payload?.field] = action.payload?.value;
        },
        updateOperatorField(state, action) {
            state.updatedOperator[action.payload?.field] = action.payload?.value;
        },
        setEditUserModalOpen(state, action) {
            state.editUserModalOpen = action.payload;
        },
        setUserCustomer(state, action) {
            state.userCustomer = action.payload;
        },
        setPinMin(state, action) {
            state.pinMin = action.payload;
        },
        setPinMax(state, action) {
            state.pinMax = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setFailed(state, action) {
            state.failed = action.payload;
        },
        setSuccessText(state, action) {
            state.successText = action.payload;
        },
        resetEditState(state, action) {
            return initialState;
        },
    },
});

export const fetchSiteAdmins = createAsyncThunk("customer/fetchSiteAdmins", async (queryString) => {
    const response = await auth.fetch(`/api/portal-user${queryString}`);
    return response;
});

export const {
    setEditUser,
    setEditUserModalOpen,
    setUserCustomer,
    setUpdatedUser,
    updateUserField,
    updateEmployeeField,
    updateOperatorField,
    setPinMin,
    setPinMax,
    setLoading,
    resetEditState,
    setFailed,
    setSuccessText,
} = createUserSlice.actions;

export default createUserSlice.reducer;
