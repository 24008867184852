/*
*  Home component for the Customer facing application
*
* Copyright (C) 2018, 2019 Sterilis Solutions Inc., all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Trans} from "react-i18next";
import {AuthReq, composeHoc, isGroupSterilisOrDist} from "./library/helpers";
import withAuth from "./withAuth";
import CustomerMachines from './CustomerMachines'
import CustomerUsers from './CustomerUsers'
import SiteAdmins from './SiteAdmins'
import { Tab, Dropdown } from "semantic-ui-react";
import { Button as MuiButton } from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../css/CustomerApp.css";
import UploadDocument from "./document_upload/UploadDocument";
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import CreateUserModal from "src/features/User/Create/CreateUserModal";
import { AddCircleTwoTone } from "@mui/icons-material";
import { connect } from "react-redux";
import {
    setCreateModalOpen,
    setCustomer,
    setUserType,
    setUserTypeDisplay,
} from "src/features/User/Create/createUserSlice";
import { setActiveTab } from "src/features/Customer/customerSlice";
import EditUserModal from "src/features/User/Edit/EditUserModal";
import EditOperatorModal from "src/features/User/Edit/EditOperatorModal";
const AbortController = window.AbortController;

const Auth = new AuthService();

class CustomerApp extends React.Component {
    state = {
        appLoading: false,
        addOperatorModal: false,
        simpleFacilityObj: {},
        simpleCustomerObj: {},
        finishedLoading: false,
        numberOfCreatedOperators: 0,
        selectedCustomer: -1,
        action: null,
        filterByCustomerDropdown: [],
        customerAppAbortController: new AbortController(),
    };

    notifySuccess = (employeeName) =>
        toast(`Successfully created device operator ${employeeName}`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });
    notifyEditSuccess = (employeeName) =>
        toast(`Successfully edited device operator ${employeeName}`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });

    notifyUpgradeSuccess = (employeeName) =>
        toast(`Successfully upgraded device operator ${employeeName} to Site Administrator.`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });

    notifyUpgradeFailure = (employeeName) =>
        toast(`Failed to upgrade device operator ${employeeName} to Site Administrator.`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    notifyFailure = (employeeName) =>
        toast(`Failed to create device operator ${employeeName}. Please refresh the page and try again.`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });
    notifyEditFailure = (employeeName) =>
        toast(`Failed to edit device operator ${employeeName}. Please refresh the page and try again.`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    notifySiteAdminSuccess = (employeeName) =>
        toast(`Successfully created site admin ${employeeName}`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });
    notifySiteAdminFailure = (employeeName) =>
        toast(`Failed to create site admin ${employeeName}`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    notifyGenericSuccess = (operation, subject, name) =>
        toast(`Successfully ${operation} ${subject} ${name}`, { type: toast.TYPE.DEFAULT, autoClose: 5000 });

    notifyGenericFailure = (operation, subject, name) =>
        toast(`Failed to ${operation} ${subject} ${name}`, { type: toast.TYPE.ERROR, autoClose: 5000 });

    notifyOperatorDeactivationSuccess = (employeeName) =>
        toast(`Successfully archived device operator ${employeeName}`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });
    notifyOperatorDeactivationFailure = (employeeName) =>
        toast(`Failed to arcive device operator ${employeeName}`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    notifyOperatorReactivationSuccess = (employeeName) =>
        toast(`Successfully reactivated device operator ${employeeName}`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });
    notifyOperatorReactivationFailure = (employeeName) =>
        toast(`Failed to reactivate device operator ${employeeName}`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    notifyResendActivationEmailSuccess = (employeeEmail) =>
        toast(`Reactivation email successfully sent to ${employeeEmail}`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });
    notifyResendActivationEmailFailure = (employeeEmail) =>
        toast(`Failed to send reactivation email to ${employeeEmail}`, {
            type: toast.TYPE.ERROR,
            autoClose: 5000,
        });

    notifyNewDocument = () =>
        toast("New documents are available to download!", {
            type: toast.TYPE.WARNING,
            autoClose: 7500,
            bodyClassName: "big-notif",
        });

    componentDidMount() {
        //this.fetchDropdownData();
        document.title = "Sterilis Site Admin Portal";
        this.preparePage().then(() => {
            const action = queryString.parse(this.props.location.search).action;
            if (action) {
                //clear the action so the user won't get an action popup again if they refresh the page
                this.props.history.push("/sa");
            }
            this.setState({
                action,
            });
        });
    }

    componentWillUnmount() {
        this.state.customerAppAbortController.abort();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.customerLastViewedDocumentTime !== prevState.customerLastViewedDocumentTime) {
            this.displayNewDocumentToast();
        }
    }

    preparePage = async () => {
        const { group } = this.props;
        if (group === "CustomerPortalUsers" || group === "FacilityAdmin") {
            await this.fetchUserInfo();
        }
    };

    fetchUserInfo = () => {
        const portalUserID = this.props.user.user_id;
        // If they're a customer, we want to keep track of the documents they look at.
        // To do this, we're going to fetch the last time they viewed a document (portal_user.last_viewed_documents)
        // and store that value into state. We'll pass that state value into UploadDocument and then
        // render a toast if they have new documents.
        // Fetch their latest portal-user info
        return Auth.fetch(`/api/portal-user/${portalUserID}/`)
            .then((data) => {
                const customerLastViewedDocumentTime = data.last_viewed_documents;
                this.setState({
                    customerLastViewedDocumentTime: customerLastViewedDocumentTime,
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };

    handleDeviceOperatorModal = () => {
        this.setState((prevState) => {
            return { addOperatorModal: !prevState.addOperatorModal };
        });
    };

    hoistData = (simpleCustomerFacilityObj, customerDropdown, customerFacilityDropdown) => {
        this.setState({
            simpleCustomerFacilityObj: simpleCustomerFacilityObj,
            customerDropdown: customerDropdown,
            customerFacilityDropdown: customerFacilityDropdown,
            finishedLoading: true,
        });
    };

    // A generic hoisting function, used to hoist functions and variables
    hoistFunction = (funcName, func) => {
        this.setState({
            [funcName]: func,
        });
    };

    onTabChange = (event, data) => {
        this.props.setActiveTab(data.activeIndex);
        // If it's the documents tab
        if (data.activeIndex === 4) {
            // Set their last_viewed_documents to today's date
            const portalUserID = this.props.user.user_id;
            Auth.fetch(`/api/portal-user/${portalUserID}/`, {
                method: "PATCH",
                body: JSON.stringify({
                    last_viewed_documents: new Date(), // The backend will auto convert this timezone'd date to UTC
                }),
            }).catch((err) => {
                Sentry.captureException(err);
            });
        }
    };

    displayNewDocumentToast = () => {
        // If there is a latestUploadTimestamp value in state,
        // UploadDocument.js' preparePage has been ran and latestUploadTimestamp has been evaluated
        if (this.state.latestUploadTimestamp) {
            const { latestUploadTimestamp, customerLastViewedDocumentTime } = this.state;
            // If the latest upload time is greater than the last time the user looked at the documents tab,
            // display a toast to tell them there's new documents to download
            if (moment.utc(latestUploadTimestamp).isAfter(moment.utc(customerLastViewedDocumentTime))) {
                this.notifyNewDocument();
            }
        }
    };

    handleCustomerChange = (none, data) => {
        this.setState({
            selectedCustomer: data.value,
        });
    };

    handleNewOperatorClick = () => {
        this.props.setCustomer({
            id: this.props.user.customer_id,
            value: this.props.user.customer_id,
            label: this.props.user.customer,
            name: this.props.user.customer,
        });
        this.props.setUserType("operator");
        this.props.setUserTypeDisplay("Device Operator");
        this.props.setCreateModalOpen(true);
    };

    render() {
        const { finishedLoading } = this.state;
        const { t, group } = this.props;
        const isCurrentUserSterilisOrDist = isGroupSterilisOrDist(group);
        const filterByDiv = (
            <div className="right-actions">
                <div className="filter-by-div">
                    <p className="filter-by item-side-padding">{t("FILTER BY CUSTOMER")}</p>
                    <Dropdown
                        className="item-side-padding filter-by-customer-dropdown"
                        search
                        selection
                        options={this.state.filterByCustomerDropdown}
                        value={this.state.selectedCustomer}
                        onChange={this.handleCustomerChange}
                    />
                </div>
            </div>
        );

        const deviceOperatorBtn = (
            <div className="right-actions negative-bottom-margin">
                <AuthReq
                    userGroup={group}
                    requiredRoles={[
                        "SterilisSuperUsers",
                        "SterilisPortalUsers",
                        "DistributorAdmins",
                        "FSEs",
                        "ExternalFSEs",
                        "CustomerPortalUsers",
                        "SterilisWasteTypeAdmin",
                        "FacilityAdmin",
                    ]}>
                    {/* <Button onClick={this.handleDeviceOperatorModal}
                  id='createDeviceUserBtn'
                  disabled={!finishedLoading}
                  className='ster-btn-wide grey-btn'
                  primary>{t('NEW OPERATOR')}
            <Icon className='new-user-icon' name='plus'/>
          </Button> */}
                    <MuiButton
                        id="createOperatorBtn"
                        size="large"
                        disabled={!finishedLoading}
                        endIcon={<AddCircleTwoTone />}
                        variant="contained"
                        onClick={() => this.handleNewOperatorClick()}>
                        {t(`NEW OPERATOR`)}
                    </MuiButton>
                </AuthReq>
            </div>
        );
        const panes = [
            {
                menuItem: t("Devices"),
                pane: {
                    key: "tab1",
                    className: "customer-tab-div",
                    content: (
                        <CustomerMachines
                            group={this.props.group}
                            id="machineTab"
                            hoistData={this.hoistData}
                            numberOfCreatedOperators={this.state.numberOfCreatedOperators}
                            notifyFailure={this.notifyFailure}
                            filterByDiv={filterByDiv}
                            selectedCustomer={this.state.selectedCustomer}
                            hoistFunction={this.hoistFunction}
                            isCurrentUserSterilisOrDist={isCurrentUserSterilisOrDist}
                            action={this.state.action}
                            customerAppAbortController={this.state.customerAppAbortController}
                        />
                    ),
                },
            },
            {
                menuItem: t("Site Administrators"),
                pane: {
                    key: "tab3",
                    className: "customer-tab-div",
                    content: (
                        <SiteAdmins
                            id="siteAdminTab"
                            simpleCustomerFacilityObj={this.state.simpleCustomerFacilityObj}
                            className="customer-tab-div"
                            notifySiteAdminSuccess={this.notifySiteAdminSuccess}
                            finishedLoading={this.state.finishedLoading}
                            group={this.props.group}
                            handleDeviceOperatorModal={this.handleDeviceOperatorModal}
                            simpleCustomerObj={this.state.simpleCustomerObj}
                            simpleCustomerNameObj={this.state.simpleCustomerNameObj}
                            notifySiteAdminFailure={this.notifySiteAdminFailure}
                            customerDropdown={this.state.customerDropdown}
                            customerFacilityDropdown={this.state.customerFacilityDropdown}
                            fetchDeviceOperators={this.state.fetchDeviceOperators} //gets hoisted from CustomerUsers
                            user={this.props.user}
                            hoistFunction={this.hoistFunction}
                            notifyResendActivationEmailSuccess={this.notifyResendActivationEmailSuccess}
                            notifyResendActivationEmailFailure={this.notifyResendActivationEmailFailure}
                            createUserThroughButton={true}
                            allUsers={false}
                            notifyGenericSuccess={this.notifyGenericSuccess}
                            notifyGenericFailure={this.notifyGenericFailure}
                            filterByDiv={filterByDiv}
                            selectedCustomer={this.state.selectedCustomer}
                            isCurrentUserSterilisOrDist={isCurrentUserSterilisOrDist}
                            //gets hoisted from CustomerMachines, used to get new pins after creating new operator
                            fetchCustomerMachines={this.state.fetchCustomerMachines}
                            action={this.state.action}
                            customerAppAbortController={this.state.customerAppAbortController}
                            isFacilityTab={false}
                            refreshSiteAdmins={this.state.fetchFacilityAdmins} //gets hoisted from SiteAdmins
                        />
                    ),
                },
            },
            {
                menuItem: t("Facility Administrators"),
                pane: {
                    key: "tab5",
                    className: "customer-tab-div",
                    content: (
                        <SiteAdmins
                            id="facilityAdminTab"
                            simpleCustomerFacilityObj={this.state.simpleCustomerFacilityObj}
                            className="customer-tab-div"
                            notifySiteAdminSuccess={this.notifySiteAdminSuccess}
                            finishedLoading={this.state.finishedLoading}
                            group={this.props.group}
                            handleDeviceOperatorModal={this.handleDeviceOperatorModal}
                            simpleCustomerObj={this.state.simpleCustomerObj}
                            simpleCustomerNameObj={this.state.simpleCustomerNameObj}
                            notifySiteAdminFailure={this.notifySiteAdminFailure}
                            customerDropdown={this.state.customerFacilityDropdown}
                            fetchDeviceOperators={this.state.fetchDeviceOperators} //gets hoisted from CustomerUsers
                            user={this.props.user}
                            hoistFunction={this.hoistFunction}
                            notifyResendActivationEmailSuccess={this.notifyResendActivationEmailSuccess}
                            notifyResendActivationEmailFailure={this.notifyResendActivationEmailFailure}
                            createUserThroughButton={true}
                            allUsers={false}
                            notifyGenericSuccess={this.notifyGenericSuccess}
                            notifyGenericFailure={this.notifyGenericFailure}
                            filterByDiv={filterByDiv}
                            selectedCustomer={this.state.selectedCustomer}
                            isCurrentUserSterilisOrDist={isCurrentUserSterilisOrDist}
                            //gets hoisted from CustomerMachines, used to get new pins after creating new operator
                            fetchCustomerMachines={this.state.fetchCustomerMachines}
                            action={this.state.action}
                            customerAppAbortController={this.state.customerAppAbortController}
                            isFacilityTab={true}
                            refreshSiteAdmins={this.state.fetchCustomerAdmins} //gets hoisted from SiteAdmins
                        />
                    ),
                },
            },
            {
                menuItem: t("Operators"),
                pane: {
                    key: "tab2",
                    className: "customer-tab-div",
                    content: (
                        <CustomerUsers
                            id="operatorTab"
                            simpleCustomerFacilityObj={this.state.simpleCustomerFacilityObj}
                            className="customer-tab-div"
                            notifyEditSuccess={this.notifyEditSuccess}
                            notifyEditFailure={this.notifyEditFailure}
                            deviceOperatorBtn={deviceOperatorBtn}
                            hoistFunction={this.hoistFunction}
                            fetchSiteAdmins={this.state.fetchSiteAdmins} //gets hoisted from SiteAdmins
                            group={this.props.group}
                            notifyOperatorDeactivationSuccess={this.notifyOperatorDeactivationSuccess}
                            notifyOperatorDeactivationFailure={this.notifyOperatorDeactivationFailure}
                            notifyOperatorReactivationSuccess={this.notifyOperatorReactivationSuccess}
                            notifyOperatorReactivationFailure={this.notifyOperatorReactivationFailure}
                            notifyGenericSuccess={this.notifyGenericSuccess}
                            notifyGenericFailure={this.notifyGenericFailure}
                            filterByDiv={filterByDiv}
                            selectedCustomer={this.state.selectedCustomer}
                            isCurrentUserSterilisOrDist={isCurrentUserSterilisOrDist}
                            //gets hoisted from CustomerMachines, used to get new pins after editing an operator
                            fetchCustomerMachines={this.state.fetchCustomerMachines}
                            notifyUpgradeSuccess={this.notifyUpgradeSuccess}
                            notifyUpgradeFailure={this.notifyUpgradeFailure}
                            customerAppAbortController={this.state.customerAppAbortController}
                            customerDropdown={this.state.customerFacilityDropdown}
                            refreshSiteAdmins={this.state.fetchCustomerAdmins} //gets hoisted from SiteAdmins
                            notifyResendActivationEmailSuccess={this.notifyResendActivationEmailSuccess}
                            notifyResendActivationEmailFailure={this.notifyResendActivationEmailFailure}
                        />
                    ),
                },
            },
            {
                menuItem: t("Documents"),
                pane: {
                    key: "tab4",
                    className: "customer-tab-div",
                    content: (
                        <UploadDocument
                            group={this.props.group}
                            portalUserID={this.props.user.user_id}
                            // customerLastViewedDocumentTime={this.state.customerLastViewedDocumentTime}
                            hoistFunction={this.hoistFunction}
                            fromCustomerApp={true}
                            customerAppAbortController={this.state.customerAppAbortController}
                        />
                    ),
                },
            },
        ];
        return (
            <div>
                <CreateUserModal fetchSiteAdmins={this.state.fetchSiteAdmins} />
                <EditUserModal fetchSiteAdmins={this.state.fetchSiteAdmins} />
                <EditOperatorModal fetchSiteAdmins={this.state.fetchSiteAdmins} />
                <div className="customer-container">
                    <Tab
                        className="customer-tabs"
                        panes={panes}
                        renderActiveOnly={false}
                        onTabChange={this.onTabChange}
                    />
                </div>
                <div className="customer-footer">
                    <div className="footer-nav">
                        <div className="footer-hyperlink-div">
                            <Link className="left-hyperlink hyperlink" to="/legal">
                                <p>{t("LEGAL")}</p>
                            </Link>
                        </div>
                        <p className="footer-p">
                            <Trans ns="translations" i18nKey="sterilis-footer">
                                Sterilis Solutions LLC © 2023 All Rights Reserved.
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(setUserType(userType)),
        setCustomer: (customer) => dispatch(setCustomer(customer)),
        setUserTypeDisplay: (typeDisplay) => dispatch(setUserTypeDisplay(typeDisplay)),
        setCreateModalOpen: (open) => dispatch(setCreateModalOpen(open)),
        setActiveTab: (activeIndex) => dispatch(setActiveTab(activeIndex)),
    };
};

export default composeHoc(
    withRouter,
    translate("translations"),
    withAuth([
        "SterilisSuperUsers",
        "CustomerPortalUsers",
        "SterilisPortalUsers",
        "FSEs",
        "ExternalFSEs",
        "FactoryWorkers",
        "DistributorAdmins",
        "DistributorReadOnly",
        "DistributorFSEs",
        "SterilisWasteTypeAdmin",
        "FacilityAdmin",
    ]),
    connect(null, mapDispatchToProps)
)(CustomerApp);
