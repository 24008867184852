/*
* A few wrappers for the Create Distribibutor/Customer/Facility mega-component,
*  meant to feed high level information into the child components
*
* Copyright (C) 2018 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import withAuth from "../../withAuth";
import CreateDistributorCustomerFacility from "./CreateDistributorCustomerFacility";

const DistWrapper = (props) => (
	<React.Fragment>
		<CreateDistributorCustomerFacility create="distributor" group={props.group} history={props.history} />
	</React.Fragment>
);

export const CreateDistributorWrapper = withAuth(
	["SterilisSuperUsers", "SterilisPortalUsers", "SterilisWasteTypeAdmin"],
	"internalPage"
)(DistWrapper);

const CustomerWrapper = (props) => (
	<React.Fragment>
		<CreateDistributorCustomerFacility create="customer" group={props.group} history={props.history} />
	</React.Fragment>
);

export const CreateCustomerWrapper = withAuth(
	["SterilisSuperUsers", "SterilisPortalUsers", "DistributorAdmins", "SterilisWasteTypeAdmin"],
	"internalPage"
)(CustomerWrapper);

const FacilityWrapper = (props) => (
	<React.Fragment>
		<CreateDistributorCustomerFacility create="facility" group={props.group} history={props.history} />
	</React.Fragment>
);

export const CreateFacilityWrapper = withAuth(
	["SterilisSuperUsers", "SterilisPortalUsers", "DistributorAdmins", "SterilisWasteTypeAdmin"],
	"internalPage"
)(FacilityWrapper);
