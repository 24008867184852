/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import {composeHoc} from "../../library/helpers";
import withAuth from "../../withAuth";
import '../../../css/view/Main.css'
import {Card} from "semantic-ui-react";
import {toast} from "react-toastify";
import SiteAdmins from "../../SiteAdmins";
import EditUserModal from "src/features/User/Edit/EditUserModal";
const AbortController = window.AbortController;
const customerAppAbortController = new AbortController();


class ViewUsers extends React.Component {
    state = {
        isLoading: false,
    };
    notifyGenericSuccess = (operation, subject, name) =>
        toast(`Successfully ${operation} ${subject} ${name}`, { type: toast.TYPE.DEFAULT, autoClose: 5000 });

    notifyGenericFailure = (operation, subject, name) =>
        toast(`Failed to ${operation} ${subject} ${name}`, { type: toast.TYPE.ERROR, autoClose: 5000 });

    notifyResendActivationEmailSuccess = (employeeEmail) =>
        toast(`Reactivation email successfully sent to ${employeeEmail}`, {
            type: toast.TYPE.DEFAULT,
            autoClose: 5000,
        });
    notifyResendActivationEmailFailure = (employeeEmail) =>
        toast(`Failed to send reactivation email to ${employeeEmail}`, { type: toast.TYPE.ERROR, autoClose: 5000 });
    componentDidMount() {
        document.title = "View Users";
    }

    // A generic hoisting function, used to hoist functions and variables
    hoistFunction = (funcName, func) => {
        this.setState({
            [funcName]: func,
        });
    };

    render() {
        const { isLoading } = this.state;
        const { t } = this.props;
        return (
            <Card fluid>
                {isLoading ? <SimpleLoader /> : null}
                <Card.Header style={{ margin: "10px" }}>
                    <h2>{t("View Portal Users")}</h2>
                </Card.Header>
                {/*<ToastContainer />*/}
                <Card.Content className="card-body">
                    <EditUserModal fetchSiteAdmins={this.state.fetchSiteAdmins} />
                    <SiteAdmins
                        notifySiteAdminSuccess={this.notifySiteAdminSuccess}
                        finishedLoading={this.state.finishedLoading}
                        group={this.props.group}
                        handleDeviceOperatorModal={this.handleDeviceOperatorModal}
                        simpleCustomerObj={this.state.simpleCustomerObj}
                        simpleCustomerNameObj={this.state.simpleCustomerNameObj}
                        notifySiteAdminFailure={this.notifySiteAdminFailure}
                        user={this.props.user}
                        hoistFunction={this.hoistFunction}
                        notifyResendActivationEmailSuccess={this.notifyResendActivationEmailSuccess}
                        notifyResendActivationEmailFailure={this.notifyResendActivationEmailFailure}
                        createUserThroughButton={false}
                        allUsers={true}
                        notifyGenericSuccess={this.notifyGenericSuccess}
                        notifyGenericFailure={this.notifyGenericFailure}
                        selectedCustomer={-1}
                        customerAppAbortController={customerAppAbortController}
                    />
                </Card.Content>
            </Card>
        );
    }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
      'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin'], 'internalPage'))(ViewUsers);
