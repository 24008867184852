/*
*  Legal page, just contains the Terms and Conditions
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import SimpleLoader from "../SimpleLoader";
import {Card} from "semantic-ui-react";
import {composeHoc} from "../library/helpers";
import withAuth from "../withAuth";
import AuthService from "../AuthService";
import * as Sentry from "@sentry/browser";
const Auth = new AuthService();

class LegalNotice extends React.Component {
  state = {
    isLoading: false,
    termsAccepted: false,
    tacURL: '',
  };

  componentDidMount() {
    document.title = 'Sterilis Legal';
    this.setState({isLoading: true});
    // TODO: this whole logic really relies on a single TaC being preferred
    Auth.fetch(`/api/terms-and-conditions-documents/?preferred=True`, {
      method: 'GET',
    }).then(data => {
      this.setState({
        isLoading: false,
        tacURL: data[0]['upload'],
        tacID: data[0]['id']
      });
    }).catch(err => {
      Sentry.captureException(err);
    });
  }

  render() {
    const {
      isLoading,
      tacURL
    } = this.state;
    const {
      t
    } = this.props;
    return (
      <Card className='customer-container' fluid>
        {isLoading && (
          <SimpleLoader/>
        )}
        <Card.Header style={{'margin': '10px'}}>
          <h2 className='center-text'>{t('Sterilis Legal Notice')}</h2>
        </Card.Header>
        <Card.Content className="card-body">
          <iframe title='Sterilis TaC' src={tacURL} width="100%" height="600px">
          </iframe>
          <p>{t('PDF not loading? Click')} <a target="_blank" href={tacURL}>{t('here')}</a> {t('to open the PDF in a new tab')}</p>
        </Card.Content>
      </Card>
    )
  }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers', 'CustomerPortalUsers','SterilisWasteTypeAdmin', 'FacilityAdmin'], 'termsPage'))(LegalNotice);
