/*
 * MUI Dialog(Modal) component for Abnormal Values
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import PropTypes from "prop-types";
import AbnormalValuesTable from "./AbnormalValuesTable";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tooltip,
  Fab,
  Typography,
} from "@mui/material";
import TableChartIcon from "@mui/icons-material/TableChart";

const AbnormalValuesModal = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <React.Fragment>
          {props.abnormalValues.length > 0 && (
              <React.Fragment>
                  <Box
                      sx={{
                          transform: "translateZ(0px)",
                          flexGrow: 1,
                          position: "fixed",
                          bottom: 50,
                          right: 30,
                          zIndex: 2000,
                      }}
                  >
                      <Tooltip
                          title={
                              props.containsBurninTest
                                  ? "Show Burnin Test Parameters"
                                  : `Show Abnormal Value Definitions`
                          }
                          placement="left"
                      >
                          <Fab color="secondary" aria-label="copy" size="small" onClick={handleClickOpen}>
                              <TableChartIcon />
                          </Fab>
                      </Tooltip>
                  </Box>
                  <Dialog
                      open={open}
                      onClose={handleClose}
                      fullWidth={true}
                      maxWidth="lg"
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="alert-dialog-title">
                          <Typography variant="h5" color="secondary">
                              {props.containsBurninTest ? "Abnormal Burnin Parameters" : "Abnormal Value Parameters"}
                          </Typography>
                      </DialogTitle>
                      <Divider />
                      <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              <AbnormalValuesTable
                                  abnormalValues={props.abnormalValues}
                                  containsBurninTest={props.containsBurninTest}
                              />
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={handleClose} autoFocus>
                              Close
                          </Button>
                      </DialogActions>
                  </Dialog>
              </React.Fragment>
          )}
      </React.Fragment>
  );
};

AbnormalValuesModal.defaultProps = {
  abnormalValues: [],
  containsBurninTest: false,
};

AbnormalValuesModal.propTypes = {
  abnormalValues: PropTypes.array,
  containsBurninTest: PropTypes.bool,
};

export default AbnormalValuesModal;
