/*
*  Main component for downloading requested device files
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import SimpleLoader from "./SimpleLoader";
import {composeHoc, getFileNameFromUrl, bytesToAppropriateUnit} from "./library/helpers";
import withAuth from "./withAuth";
import AuthService from "./AuthService";
import {Card} from "semantic-ui-react";
import DownloadDeviceFileTable from "./DownloadDeviceFileTable";
import saveAs from "file-saver";
import * as Sentry from "@sentry/browser";
import {Link} from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const Auth = new AuthService();

class DownloadArchivedFileRequest extends React.Component {
  state = {
    isLoading: false,
  };
  notifyGenericFailure = () => toast(`Server error. Please refresh this page and try again. If the problem persists, please contact support.`
    , {type: toast.TYPE.ERROR, autoClose: 5000});

  componentDidMount() {
    document.title = 'Download Archived Device Files';

    // isLoading is set to true in componentDidMount and no where else on purpose
    // This allows the loader to only appear on initial load, and not when SimpleRefreshTimer invokes preparePage
    this.setState({
      isLoading: true
    });
    // The promise is ignored here, but we do use it in SimpleRefreshTimer
    this.preparePage()
  }

  preparePage = async () => {
    await this.fetchDeviceFiles();
  };

  fetchDeviceFiles = () => {
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const fileRequestID = this.props.match.params["file_request_id"];
		return Auth.fetch(`/api/archived-file-request/${fileRequestID}/`, {
			method: "GET",
		})
			.then((data) => {
				document.title = `Archived Files`;

				const deviceFileTableArray = data.restored_files.map((file) => {
					return {
						file_name: file.s3_file,
						content_start_date: moment.utc(file.restore_date).format("YYYY-MM-DD HH:mm:ss"),
						file_size: bytesToAppropriateUnit(file.file_size),
						actions: this.fileActions(file),
					};
				});

				this.setState({
					isLoading: false,
					deviceFileTableArray,
					deviceSerial: data.device_serial,
					userDisplayName: data.requester_name,
					userEmail: data.requester_email,
					requestDate: moment.utc(data.request_date).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z"),
				});
			})
			.catch((err) => {
				Sentry.captureException(err);
				this.notifyGenericFailure();
				this.setState({
					isLoading: false,
				});
			});
  };

  fileActions = (file) => {
		return (
			<React.Fragment>
				<div className="clickable-text slight-margin-bottom" onClick={() => this.downloadFile(file)}>
					Download file to PC
				</div>
			</React.Fragment>
		);
  };

  downloadFile = (upload) => {
		const fileID = upload["file_id"];
		const fileName = getFileNameFromUrl(upload["download_path"]);

		const { deviceSerial } = this.state;
		// Some string manipulation logic to turn commdaemon-2019-09-27-09-17-01.log into commdaemon-SERIAL_NUMBER-2019-09-27-09-17-01.log
		const fileNameWithSerial = `${fileName.slice(0, fileName.indexOf("-"))}-${deviceSerial}${fileName.slice(
			fileName.indexOf("-")
		)}`;

		this.setState({
			isDownloading: true,
			fileName: fileNameWithSerial,
		});
		Auth.fetch(`/api/download-archived-file/`, {
			method: "POST",
			body: JSON.stringify({
				file_id: fileID,
			}),
		})
			.then((response) => response.blob())
			.catch((err) => {
				Sentry.captureException(err);
				this.notifyGenericFailure();
				this.setState({
					isLoading: false,
				});
			})
			.then((blob) => {
				this.setState({ isDownloading: false });
				saveAs(blob, fileNameWithSerial);
			})
			.catch((err) => {
				Sentry.captureException(err);
			});
  };

  render() {
    const {
      isLoading,
      deviceFileTableArray,
      isDownloading,
      fileName,
      deviceSerial,
      userDisplayName,
      requestDate,
    } = this.state;

    const {
      t
    } = this.props;
    return (
		<Card fluid>
			{isLoading && <SimpleLoader />}
			{isDownloading && <SimpleLoader loaderText={`Downloading ${fileName}`} />}
			<Card.Header style={{ margin: "10px" }}>
				<h2 id="getDeviceFileHeader">{t("Download requested device files")}</h2>
			</Card.Header>
			<Card.Content className="card-body">
				<div className="file-request-metainfo">
					<div>
						<h5>{t("Requested by")}</h5> <p>{userDisplayName}</p>
					</div>
					<div>
						<h5>{t("Requested at")}</h5> <p>{requestDate}</p>
					</div>
					<div>
						<h5>{t("Device")}</h5>
						<Link target="_blank" id="linkToFindAMachine" to={`/find-a-machine?serial=${deviceSerial}`}>
							{deviceSerial}
						</Link>
					</div>
				</div>

				<DownloadDeviceFileTable deviceFileTableArray={deviceFileTableArray} showStatus={false} />
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
    'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs']))(DownloadArchivedFileRequest);
