/*
*  Modal which has the 'Upload a new Document' dialogue in it
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {getFileNameFromUrl} from "../library/helpers";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "../AuthService";
import SimpleLoader from "../SimpleLoader";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();


class UploadDocumentModal extends React.Component {
  state = {
    documentDesc: '',
    modalLoading: false,
    allowModalToBeClosed: true,
  };

  componentDidMount() {
  }

  uploadDocument = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const {fileToBeUploaded, documentDesc} = this.state;
    formData.append('upload', fileToBeUploaded);
    formData.append('description', documentDesc);
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    Auth.file_fetch(`/api/s3-file/`, {
      method: 'POST',
      body: formData
    }).then(data => {
      this.props.notifyUploadSuccess(
        getFileNameFromUrl(data['upload'])
      );
      this.setState({
        documentDesc: '',
        modalLoading: false,
        allowModalToBeClosed: true,
      });
      this.props.triggerUploadDocumentModal();
      this.props.preparePage();
    }).catch(err => {
      this.props.notifyUploadFailure();
      this.setState({
        documentDesc: '',
        modalLoading: false,
        allowModalToBeClosed: true,
      });
      this.props.triggerUploadDocumentModal();
      this.props.preparePage();
      Sentry.captureException(err)
    });
  };

  handleChange = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    this.setState({
      [name]: value
    });
  };

  // Set documentTitle to the first selected file's name
  handleFileChange = (selectorFiles) => {
    if (selectorFiles.length > 0) {
      this.setState({
        documentTitle: selectorFiles[0].name,
        fileToBeUploaded: selectorFiles[0]
      })
    }
  };

  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.showUploadDocumentModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size="small"
          className='upload-document--modal'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerUploadDocumentModal()
          }}
        >
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2>{t('Upload a new Document')}</h2>
          </Header>
          <Modal.Content>
            <form id='uploadDocumentForm' onSubmit={this.uploadDocument}>
              <div className="form-group reduced-width">
                <label className="fake-float" htmlFor="uploadDocumentInput">{t('Upload Document')}</label>
                <input type="file"
                       id="uploadDocumentInput"
                       className="form-control"
                  // accept="image/*,.pdf, .doc, .docx, .pptx, .ppt"
                       onChange={(e) => this.handleFileChange(e.target.files)}
                       required/>
              </div>
              {/*<div className="form-group reduced-width">*/}
              {/*<input name='documentTitle'*/}
              {/*type="text"*/}
              {/*id="documentTitleInput"*/}
              {/*className="form-control"*/}
              {/*value={this.state.documentTitle}*/}
              {/*onChange={this.handleChange}*/}
              {/*required/>*/}
              {/*<label className="form-control-placeholder" htmlFor="uploadDocumentInput">{t('Document Title')}</label>*/}
              {/*</div>*/}
              <div className="form-group reduced-width">
                <textarea name='documentDesc'
                  // type="text"
                          id="documentDescInput"
                          className="form-control tall-textarea"
                          onChange={this.handleChange}
                          value={this.state.documentDesc}
                />
                <label className="form-control-placeholder"
                       htmlFor="documentDescInput">{t('Document Description')}</label>
              </div>
              <p className='modal-text'>Please be aware that every Site Administrator will be emailed a link to the document after the document is successfully uploaded.</p>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='uploadDocumentForm'
                    id='uploadDocumentBtn'
            >{t('Upload Document')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(UploadDocumentModal);
