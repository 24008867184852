/*
*  Table which contains file request files available to download for a file request
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC all rights reserved.
*/
import React, {useMemo, useState} from 'react';
import HookTable from "../library/HookTable";
import {bytesToAppropriateUnit, timestampSortHook} from "../library/helpers";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Table as SemanticTable} from "semantic-ui-react";
import moment from "moment";

const maxNumberOfFiles = 10;

const AvailableEthelFilesTable = props => {
  const {t} = useTranslation('translations');
  const [errorCount, setErrorCount] = useState(0);
  const columns = [
    {
      Header: t('Select file'),
      accessor: 'checkbox',
      manualWidth: 100,
    },
    {
      Header: t('File Name'),
      accessor: 'file_name',
    },
    {
      Header: t('Content start date'),
      accessor: 'content_start_date',
      id: 'content_start_date',
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss a z"),
    },
    {
      Header: t('File size'),
      accessor: 'file_size',
      manualWidth: 100
    },
    ...errorCount > 0 ? [{
      Header: t('Error Info'),
      accessor: 'error_info',
    }] : [],
  ];

  const filesSelected = useMemo(() => {
    return Object.keys(props.ethelFilesToParse).filter(key => props.ethelFilesToParse[key])
  }, [props.ethelFilesToParse]);

  const processLogsHeader = <SemanticTable.Header fullWidth>
    <SemanticTable.Row>
      <SemanticTable.HeaderCell colSpan={columns.length}>
        <div className="flex-dir-column available-ethel-files-table-header">
          <div>
            <p className={filesSelected.length > maxNumberOfFiles ? 'red-text' : ''}>
              Select up to {maxNumberOfFiles} files to parse
            </p>
          </div>
          <div>
            {
              filesSelected.length > 0 && <Button
                className='ster-btn important-slight-margin-top no-margin-right'
                value="Submit"
                type="submit"
                onClick={() => props.processBatchFiles(filesSelected)}
                disabled={filesSelected.length < 1 || filesSelected.length > maxNumberOfFiles}
              >Parse {filesSelected.length > 1 ? `${filesSelected.length} Logs` : 'Log'}</Button>
            }
          </div>
        </div>
      </SemanticTable.HeaderCell>
    </SemanticTable.Row>
  </SemanticTable.Header>;

  const getErrorJsx = error => {
    if (error['sw_error_msg']) {
      return error['sw_error_msg']
    }
    if (error['hw_error_code']) {
      return <details>
        <summary>
          {error['hw_error_code']['error_code']}
        </summary>
        {error['hw_error_code']['description']}
      </details>
    }

  };

  const parseTableData = tableData => {
    let numberOfErrors = 0;
    const data = tableData.filter(file => file['file_request_s3_file'] && file['status'] === 'OnPortal')
      .map(file => {
        const fileRequestError = file['file_request']['device_error'];
        if (fileRequestError) {
          numberOfErrors += 1;
        }
        return {
          'checkbox': <Checkbox
            checked={props.ethelFilesToParse[file['file_request_s3_file']['id']]}
            onChange={() => props.toggleEthelLogParse(file['file_request_s3_file']['id'])}
          />,
          'file_name': file['device_file_name'],
          'file_request_s3_file_id': file['file_request_s3_file']['id'],
          'content_start_date': moment.utc(file.content_start_date).format("YYYY-MM-DD HH:mm:ss z"),
          'file_size': bytesToAppropriateUnit(file['file_size']),
          'device_error': fileRequestError,
          'error_info': fileRequestError ? getErrorJsx(fileRequestError) : '',
        }
      });

    setErrorCount(numberOfErrors);
    return data;
  };

  const customRowClass = (rowInfo) => {
    if (rowInfo && rowInfo.original) {
      if (rowInfo.original.device_error) {
        return 'light-red-tr'
      }
    }
  };
  const availableEthelFilesTableData = useMemo(() => {
    return parseTableData(props.availableEthelFilesTableArray)
  }, [props.availableEthelFilesTableArray.length, props.ethelFilesToParse]);

  return <div className='available-ethel-files-container slight-margin-top'>
    <HookTable
      customHeader={processLogsHeader}
      id='availableEthelFilesTable'
      data={availableEthelFilesTableData}
      columns={columns}
      customRowClass={true}
      customRowClassFunc={customRowClass}
      defaultPageSize={availableEthelFilesTableData.length > 10 ? 20 : 10}
      tableContainerClass='available-files-table'
      tableName='availableEthelFilesTable'
      defaultSortBy={{id: 'content_start_date', desc: true}}
    />
  </div>
};


AvailableEthelFilesTable.defaultProps = {
  availableEthelFilesTableArray: [],
  ethelFilesToParse: {},
  toggleEthelLogParse: () => {
  },
  processBatchFiles: () => {
  },
};
export default AvailableEthelFilesTable;
