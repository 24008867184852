import React from "react";
import PropTypes from "prop-types";
import { Box, Skeleton, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";

const ActiveUsersTable = (props) => {
    const { t } = useTranslation("translations");
    const theme = useTheme();
    const { users, loading } = props;

    const columns = [
        {
            headerName: t("Username"),
            field: "username",
            headerAlign: "center",
            headerClassName: "active-users-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: t("Name"),
            field: "employee__full_name",
            headerAlign: "center",
            headerClassName: "active-users-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: t("Last Visit"),
            field: "last_login",
            type: "dateTime",
            valueGetter: ({ value }) => value && new Date(value),
            headerAlign: "center",
            headerClassName: "active-users-table--header",
            align: "center",
            flex: 1,
        },
    ];
    return (
        <Box
            sx={{
                width: "100%",
                "& .active-users-table--header": {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.contrastText,
                },
            }}>
            {loading ? (
                <Skeleton width="100%" height={300} />
            ) : (
                <DataGrid
                    disableSelectionOnClick
                    autoHeight
                    // @ts-ignore
                    columns={columns}
                    density="compact"
                    rows={users}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "last_login", sort: "desc" }],
                        },
                    }}
                    getRowId={(row) => row.username}
                    sx={{
                        fontSize: 14,
                    }}
                />
            )}
        </Box>
    );
};

ActiveUsersTable.propTypes = {
    users: PropTypes.array,
    loading: PropTypes.bool,
};

export default ActiveUsersTable;
