import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SubCard from "src/components/material/Cards/SubCard";
import { Collapse, List, ListItem, ListItemButton, ListItemText, Skeleton, Tooltip, Typography } from "@mui/material";
import {
    CancelTwoTone,
    CheckCircleTwoTone,
    ErrorTwoTone,
    ExpandLessTwoTone,
    ExpandMoreTwoTone,
    RemoveCircleTwoTone,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthService from "src/components/AuthService";
import { DateTime, Duration } from "luxon";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "src/components/library/helpers";
import { get } from "lodash";

const Auth = new AuthService();

const DeviceStatusPanel = (props) => {
    const { t } = useTranslation("translations");
    const { selectedDeviceConfig, deviceTimezone, selectedDevice } = useSelector((store) => store.deviceInfo);

    const loading = useSelector((store) => store.deviceInfo.loading.deviceConfig);
    const [softwareOpen, setSoftwareOpen] = useState(false);
    const [statusOpen, setStatusOpen] = useState(false);
    const [timeLastCommunicated, setTimeLastCommunicated] = useState(null);
    const [lastCycle, setLastCycle] = useState(null);

    const fetchCommunicationStatus = () => {
        if (selectedDeviceConfig) {
            const deviceId = selectedDeviceConfig?.device?.id;
            if (deviceId) {
                Auth.fetch(`/api/device/${deviceId}`)
                    .then((data) => {
                        setTimeLastCommunicated(data["time_of_last_communication"]);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    };

    const fetchLastCycle = () => {
        return Auth.fetch(`/api/device-last-cycle?device_config_id=${selectedDeviceConfig?.id}`).then((data) => {
            setLastCycle(data);
        });
    };

    const getCommunicationStatusIcon = (timeLastCommunicated) => {
        if (timeLastCommunicated) {
            const timeDelta = Date.now() / 1000 - Date.parse(timeLastCommunicated) / 1000;

            const duration = Duration.fromObject({ seconds: timeDelta }).shiftTo("days", "hours", "minutes", "seconds");
            const formattedDelta = `${duration.days} days ${duration.hours} hours ${
                duration.minutes
            } minutes ${Math.round(duration.seconds)} seconds`;

            if (timeDelta < 300) {
                return (
                    <Tooltip title={formattedDelta}>
                        <CheckCircleTwoTone fontSize="large" color="success" />
                    </Tooltip>
                );
            } else if (timeDelta > 300 && timeDelta < 86400) {
                return (
                    <Tooltip title={formattedDelta}>
                        <RemoveCircleTwoTone fontSize="large" color="warning" />
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={formattedDelta}>
                        <CancelTwoTone fontSize="large" color="error" />
                    </Tooltip>
                );
            }
        }
    };

    const getCurrentFirmware = () => {
        const currentFirmware = selectedDeviceConfig?.current_firmware;
        const targetFirmware = selectedDeviceConfig?.target_firmware;

        const currentFirmwareLink = (
            <Link
                target="_blank"
                id="linkToChangeFirmware"
                to={`/assign/software-to-devices?device_config=${selectedDeviceConfig?.id}`}>
                {get(currentFirmware, "firmware_version", t("No target software"))}
            </Link>
        );

        const targetFirmwareLink = (
            <Link
                target="_blank"
                id="linkToChangeFirmware"
                to={`/assign/software-to-devices?device_config=${selectedDeviceConfig?.id}`}>
                {get(targetFirmware, "firmware_version", t("No target software"))}
            </Link>
        );

        const preferredSoftwareIcon = () => {
            if (currentFirmware?.preferred) {
                return (
                    <Tooltip title={t("device-info.block.pref_sw")}>
                        <CheckCircleTwoTone color="success" />
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={t("device-info.block.not_pref_sw")}>
                        <ErrorTwoTone color="warning" />
                    </Tooltip>
                );
            }
        };

        if (currentFirmware && targetFirmware) {
            return (
                <>
                    <ListItem key={"current-firmware"} disablePadding secondaryAction={currentFirmwareLink}>
                        <ListItemButton sx={{ borderRadius: 3 }} onClick={() => setSoftwareOpen(!softwareOpen)}>
                            {softwareOpen ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
                            {preferredSoftwareIcon()}
                            <ListItemText primary={t("Software") + ": "} sx={{ paddingLeft: 0.5 }} />
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={softwareOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem
                                key={"expand-target-firmware"}
                                sx={{ pl: 8 }}
                                secondaryAction={targetFirmwareLink}>
                                <ListItemText primary={t("Target Assignment") + ":"} />
                            </ListItem>
                            <ListItem
                                key={"expand-current-firmware"}
                                sx={{ pl: 8 }}
                                secondaryAction={currentFirmwareLink}>
                                <ListItemText primary={t("Current Assignment") + ":"} />
                            </ListItem>
                        </List>
                    </Collapse>
                </>
            );
        } else if (currentFirmware) {
            return (
                <>
                    <ListItem key={"current-firmware"} disablePadding secondaryAction={currentFirmwareLink}>
                        {preferredSoftwareIcon()}
                        <ListItemText primary={t("Software") + ":"} sx={{ paddingLeft: 0.5 }} />
                    </ListItem>
                </>
            );
        }
    };

    const getCycleStatusList = (cycle, timezone) => {
        const status = get(cycle, "status", null);
        if (status) {
            if (status.length > 1) {
                const lastStatus = status.reduce((acc, curr) => {
                    return DateTime.fromISO(curr?.timestamp) > DateTime.fromISO(acc) ? acc : curr;
                });

                return (
                    <>
                        <ListItem
                            disablePadding
                            key={"last-cycle-status"}
                            secondaryAction={
                                <Typography color={lastStatus?.cycle_state_display?.cycle_failed ? "error" : ""}>
                                    {lastStatus?.cycle_state_display?.message}
                                </Typography>
                            }>
                            <ListItemButton sx={{ borderRadius: 3 }} onClick={() => setStatusOpen(!statusOpen)}>
                                {statusOpen ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
                                <ListItemText primary={t("Status of Last Cycle") + ":"} />
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={statusOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {status.map((status, idx) => {
                                    return (
                                        <ListItem
                                            key={`${status}-${idx}`}
                                            sx={{ pl: 8 }}
                                            secondaryAction={status?.cycle_state_display?.message}>
                                            <ListItemText
                                                primary={DateTime.fromISO(status?.timestamp, {
                                                    zone: timezone,
                                                }).toLocaleString({
                                                    ...DateTime.DATETIME_SHORT_WITH_SECONDS,
                                                    timeZoneName: "short",
                                                })}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Collapse>
                    </>
                );
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            //fetchCommunicationStatus();
        }, 30000);

        if (selectedDeviceConfig) {
            fetchCommunicationStatus();
            fetchLastCycle();
        }

        return () => clearInterval(interval);
    }, [selectedDeviceConfig]);

    return (
        <SubCard content={true} sx={{ width: "100%", minHeight: 225 }}>
            {selectedDevice && (
                <List>
                    {loading ? (
                        <>
                            <Skeleton width={"100%"}>
                                <ListItem
                                    key={"connection-status"}
                                    secondaryAction={getCommunicationStatusIcon(timeLastCommunicated)}>
                                    <ListItemText primary={t("Connection Status") + ":"} />
                                </ListItem>
                            </Skeleton>

                            <Skeleton width={"100%"}>
                                <ListItem
                                    key={"raspberrypi-verision"}
                                    secondaryAction={selectedDeviceConfig?.device?.pi_rev}>
                                    <ListItemText primary={t("Raspberry Pi Version") + ":"} />
                                </ListItem>
                            </Skeleton>

                            <Skeleton width={"100%"}>
                                <ListItem key={"linux-version"} secondaryAction={selectedDeviceConfig?.linux_version}>
                                    <ListItemText primary={t("Linux Version") + ":"} />
                                </ListItem>
                            </Skeleton>

                            <Skeleton width={"100%"}>
                                <ListItem
                                    key={"network-type"}
                                    secondaryAction={capitalizeFirstLetter(selectedDeviceConfig?.device?.network_type)}>
                                    <ListItemText primary={t("Network Type") + ":"} />
                                </ListItem>
                            </Skeleton>
                        </>
                    ) : (
                        <>
                            {getCurrentFirmware()}
                            <ListItem
                                key={"connection-status"}
                                secondaryAction={getCommunicationStatusIcon(timeLastCommunicated)}>
                                <ListItemText primary={t("Connection Status") + ":"} />
                            </ListItem>
                            <ListItem
                                key={"raspberrypi-verision"}
                                secondaryAction={selectedDeviceConfig?.device?.pi_rev}>
                                <ListItemText primary={t("Raspberry Pi Version") + ":"} />
                            </ListItem>
                            <ListItem key={"linux-version"} secondaryAction={selectedDeviceConfig?.linux_version}>
                                <ListItemText primary={t("Linux Version") + ":"} />
                            </ListItem>
                            <ListItem
                                key={"network-type"}
                                secondaryAction={capitalizeFirstLetter(selectedDeviceConfig?.device?.network_type)}>
                                <ListItemText primary={t("Network Type") + ":"} />
                            </ListItem>
                            {getCycleStatusList(lastCycle, deviceTimezone)}
                        </>
                    )}
                </List>
            )}
        </SubCard>
    );
};

DeviceStatusPanel.propTypes = {};

export default DeviceStatusPanel;
