import React from 'react'
import '../css/WaveLoader.css';

const WaveLoader = props => (
  <div className="wave-wrapper">
    <div className="wave"/>
  </div>
);

export default (WaveLoader);
