/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox,} from "semantic-ui-react";
import {exists} from "../library/helpers";
import moment from "moment";
import {inRangeInclusive} from "./ChartHelpers";
import FlexBlockItem from "../library/FlexBlockItem";
import BurninTestChart from "./BurninTestChart";
import SimpleLoader from "../SimpleLoader";
import BurninTest7Results from "./BurninTest7Results";
import BurninItem from "./BurninItem";
import CycleStats from "./CycleStats";

const BurninStats = props => {
  const [showBurninTestChart, setShowBurninTestChart] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [showCycleMetrics, setShowCycleMetrics] = useState(false);
  const [latestTest, setLatestTest] = useState({});
  const [remainingTests, setRemainingTests] = useState({});
  const [displayRemainingTestsChart, setDisplayRemainingTestsChart] = useState({});

  const [burninTestSuccess, setBurninTestSuccess] = useState(false);

  const findLatestBurninTest = burninTests => {
    if (burninTests.length === 0) {
      return {}
    } else if (burninTests.length === 1) {
      return burninTests[0]
    } else if (burninTests.length > 1) {
      return burninTests.reduce((a, b) => {
        const aDate = moment(a.raw_start_time);
        const bDate = moment(b.raw_start_time);
        const isALogLatest = aDate.isAfter(bDate);
        return isALogLatest ? a : b;
      });
    }
  };

  useEffect(() => {
    const {
      burninTestList
    } = props;
    const latestTest = findLatestBurninTest(burninTestList);
    const remainingTests = burninTestList.filter(test => {
      return test.raw_start_time !== latestTest.raw_start_time
    });
    props.setBurninTest(latestTest);
    setLatestTest(latestTest);

    const displayRemainingTestsChart = remainingTests.reduce((acc, curr, idx) => {
      acc[idx] = false;
      return acc;
    }, {});
    setRemainingTests(remainingTests);
    setDisplayRemainingTestsChart(displayRemainingTestsChart);

    const isSuccessful = calculateBurninSuccess(latestTest);
    setBurninTestSuccess(isSuccessful);
    setShowBurninTestChart(isSuccessful === false);
    setFinishedLoading(true)

  }, [props.burninTestList]);

  const calculateBurninSuccess = (burninTestValues) => {
    const {
      abnormalValuesForBurninTest
    } = props;
    let isSuccessful = true;
    Object.keys(abnormalValuesForBurninTest).forEach(key => {
      const abnormalDefinition = abnormalValuesForBurninTest[key];
      const burninValue = burninTestValues[abnormalDefinition['value_name']];
      if (exists(burninValue) && // check that the burnin value exists,
        isSuccessful === true &&  // and that this test hasn't already failed from a previous value
        (exists(abnormalDefinition['maximum_value']) // and that there are valid, non null min/max definitions for the item
          && exists(abnormalDefinition['minimum_value']))) {
        isSuccessful = inRangeInclusive(burninValue, abnormalDefinition['minimum_value'], abnormalDefinition['maximum_value']);
      }
    });
    return isSuccessful;
  };

  const noBurninTestFound = () => {
    if (props.testNumber === 7) {
      const {
        burninTest7Cycles
      } = props;
      const count = burninTest7Cycles.length;
      const textColor = (count === 0 || count > 4) ? 'red-text' : count === 4 ? 'green-text' : 'black-text';
      const cycleString = `${count}/4 cycle${count > 1 || count === 0 ? `s` : ''} selected for Burnin Test 7`;

      return <div className='burnin-stats-container forced-min-height'>
        {props.testLoading &&
        <SimpleLoader loaderText='Calculating Burnin Test 7 using selected cycles...'/>
        }
        <fieldset>
          <legend>{props.testTitle}</legend>
          {props.selectCyclesForBurninTest7Toggle}
          <h4 className={`${textColor} center-text slight-margin-top`}>
            {cycleString}
          </h4>
          {
            burninTest7Cycles.map((cycle, idx) => {
              return <React.Fragment key={`${cycle['start_time']}-${idx}`}>
                <FlexBlockItem
                  label={`Selected Cycle ${idx + 1}`}
                  value={cycle['start_time']}
                />
              </React.Fragment>
            })
          }
          {count === 4 && <Button
            className='ster-btn'
            value="Submit"
            onClick={() => props.fetchBurninTest7Cycles()}
            type="submit"
          >Calculate Test 7</Button>
          }
        </fieldset>
      </div>

    } else {
      return <div className='burnin-stats-container no-burnin-test-found-container'>
        <h4 className='red-text center-text slight-margin-top'>
          No data for Burnin Test {props.testNumber} found in parsed log
        </h4>
      </div>
    }
  };

  const burninTestState = (isSuccessful) => {
    return <span className={`${isSuccessful ? 'green-text' : 'red-text'}`}>
      {isSuccessful ? 'Success' : 'Fail'}
    </span>
  };

  const remainingTestHeader = () => {
    const {
      testNumber
    } = props;
    const conditionalS = remainingTests.length > 1 ? 's' : '';
    return `Older Test ${testNumber}${conditionalS} found in parsed log. Click to show ${remainingTests.length} earlier test${conditionalS}`
  };

  const burninTestChartToggle = <Checkbox
    className='digital-output-toggle'
    toggle
    label={`Show Chart for Test ${props.testNumber}`}
    onChange={() => setShowBurninTestChart(!showBurninTestChart)}
    checked={showBurninTestChart}
  />;

  const cycleMetricsToggle = <Checkbox
    className='digital-output-toggle'
    toggle
    label={`Show Cycle Metrics for Test ${props.testNumber}`}
    onChange={() => setShowCycleMetrics(!showCycleMetrics)}
    checked={showCycleMetrics}
  />;

  return <React.Fragment>
    {finishedLoading &&
    <div className={`flex-dir-column even-split`}>
      {Object.keys(latestTest).length > 0 ?
        <React.Fragment>
          {props.testNumber === 7 ?
            <BurninTest7Results
              abnormalValuesObj={props.abnormalValuesObj}
              digitalOutputOverlays={props.digitalOutputOverlays}
              chartOptions={props.chartOptions}
              burninTest7={latestTest}
              testTitle={props.testTitle}
              burninStatInternalNames={props.burninStatInternalNames}
              abnormalValuesForBurninTest={props.abnormalValuesForBurninTest}
              burninTestState={burninTestState}
            /> :
            <React.Fragment>
              <div className='flex-dir-row'>
                <div className='burnin-stats-container'>
                  <fieldset>
                    <legend>{props.testTitle} {burninTestState(burninTestSuccess)}</legend>
                    {props.burninTestCycle && burninTestChartToggle}
                    {props.burninTestCycle && cycleMetricsToggle}
                    <FlexBlockItem
                      label={'Test Date'}
                      value={moment.utc(latestTest.raw_start_time).format("MM/DD HH:mm:ss")}
                    />
                    {
                      props.testNumber !== 7 && props.burninStatInternalNames.map((name, idx) => {
                        return <BurninItem
                          test={latestTest}
                          name={name}
                          idx={idx}
                          key={`${name}-${idx}`}
                          subBurninStatInternalNames={props.subBurninStatInternalNames}
                          abnormalValuesForBurninTest={props.abnormalValuesForBurninTest}
                          usePercent={props.usePercent}
                        />
                      })
                    }
                  </fieldset>
                </div>
                {showCycleMetrics &&
                <CycleStats
                  selectedCycle={props.burninTestCycle}
                  abnormalValuesObj={props.abnormalValuesObj}
                  fromBurninTest={true}
                />
                }
              </div>
              {
                showBurninTestChart && props.burninTestCycle &&
                <BurninTestChart
                  customTitlePrefix={props.testTitle}
                  burninTestCycle={props.burninTestCycle}
                  abnormalValuesObj={props.abnormalValuesObj}
                  digitalOutputOverlays={props.digitalOutputOverlays}
                  chartOptions={props.chartOptions}
                />
              }
              {
                remainingTests.length >= 1 &&
                <details>
                  <summary className='remaining-cycle-stat-summary'>{remainingTestHeader()}</summary>
                  <div className='previous-burnin-tests'>
                    {
                      remainingTests.map((test, idx) => {
                        return <React.Fragment key={`fragment-${idx}`}>
                          <div className='burnin-stats-container'>
                            <fieldset key={`fieldset-${idx}`}>
                              <legend>{`Previous Test ${props.testNumber}`}: {burninTestState(calculateBurninSuccess(test))}</legend>
                              {
                                // some burnin tests do not have t/p/ia series data
                                test['cycles'] &&
                                <Checkbox
                                  className='digital-output-toggle'
                                  toggle
                                  label='Show Chart'
                                  onChange={() => setDisplayRemainingTestsChart(prevState => ({
                                    ...prevState,
                                    [idx]: !prevState[idx]
                                  }))}
                                  checked={displayRemainingTestsChart[idx]}
                                />
                              }

                              <FlexBlockItem
                                label={'Test Date'}
                                value={moment.utc(test.raw_start_time).format("MM/DD HH:mm:ss")}
                              />
                              {
                                props.burninStatInternalNames.map((name, internalIdx) => {
                                  return <BurninItem
                                    test={test}
                                    name={name}
                                    idx={internalIdx}
                                    key={`${name}-${idx}`}
                                    subBurninStatInternalNames={props.subBurninStatInternalNames}
                                    abnormalValuesForBurninTest={props.abnormalValuesForBurninTest}
                                    usePercent={props.usePercent}
                                  />
                                })
                              }
                            </fieldset>
                          </div>

                          {
                            displayRemainingTestsChart[idx] &&
                            test['cycles'] && // some burnin tests do not have t/p/ia series data
                            <BurninTestChart
                              customTitlePrefix={`Previous Test ${props.testNumber}`}
                              burninTestCycle={test['cycles'] ? test['cycles'][0] : {}}
                              abnormalValuesObj={props.abnormalValuesObj}
                              olderTestGraph={true}
                              digitalOutputOverlays={props.digitalOutputOverlays}
                              chartOptions={props.chartOptions}
                            />
                          }
                        </React.Fragment>
                      })
                    }
                  </div>
                </details>
              }
            </React.Fragment>
          }
        </React.Fragment>
        : noBurninTestFound()
      }
    </div>
    }
  </React.Fragment>
};

BurninStats.defaultProps = {
  abnormalValuesForBurninTest: {},
  burninTestList: [],
  burninStatInternalNames: [],
  subBurninStatInternalNames: {},
  testTitle: '',
  showChart: false,
  testNumber: 0,
  burninTest7Cycles: [],
};

BurninStats.propTypes = {
  abnormalValuesForBurninTest: PropTypes.object.isRequired,
  setBurninTest: PropTypes.func.isRequired,
  burninTestList: PropTypes.array.isRequired,
  burninStatInternalNames: PropTypes.array.isRequired,
  subBurninStatInternalNames: PropTypes.object,
  testTitle: PropTypes.string,
  testNumber: PropTypes.number,
  burninTest7Cycles: PropTypes.array,
};

export default BurninStats;
