/*
 * Helper function file
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import Highcharts from "highcharts/highstock";
import moment from "moment";
import React, { useState } from "react";
import get from "lodash/get";
import { toast } from "react-toastify";
import Qty from "js-quantities";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

export const SECONDS_IN_HOUR = 60 * 60;
export const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
export const SECONDS_IN_WEEK = 7 * SECONDS_IN_DAY;
// Month is defined as 28 days
export const SECONDS_IN_MONTH = 28 * SECONDS_IN_DAY;

// Taken straight from the Honeybee source code
export const PRV_INTERVAL = 60 * 60 * 24 * 7 * 28;

export const sterilisCustomers = [1, 3, 999];

export const composeHoc = (...hocs) => {
    if (hocs.length) {
        return (component) => hocs.reduce((c, hoc) => hoc(c), component);
    }
    return (component) => component;
};

export const vowelCheck = (s) => {
    return /^[aeiou]$/i.test(s.toLowerCase()[0]);
};

export const isGroupSterilis = (group) => {
    const sterGroups = [
        "SterilisSuperUsers",
        "SterilisPortalUsers",
        "FSEs",
        "ExternalFSEs",
        "FactoryWorkers",
        "SterilisWasteTypeAdmin",
    ];
    return sterGroups.includes(group);
};

export const isGroupSterilisOrDist = (group) => {
    const sterGroups = [
        "SterilisSuperUsers",
        "SterilisPortalUsers",
        "FSEs",
        "ExternalFSEs",
        "FactoryWorkers",
        "DistributorAdmins",
        "DistributorReadOnly",
        "DistributorFSEs",
        "SterilisWasteTypeAdmin",
    ];
    return sterGroups.includes(group);
};

export const isGroupSterilisDistAdminOrFSE = (group) => {
    const sterGroups = [
        "SterilisSuperUsers",
        "SterilisPortalUsers",
        "FSEs",
        "ExternalFSEs",
        "FactoryWorkers",
        "DistributorAdmins",
        "DistributorFSEs",
        "SterilisWasteTypeAdmin",
    ];
    return sterGroups.includes(group);
};

export const isGroupAdmin = (group) => {
    const sterGroups = ["SterilisSuperUsers", "SterilisPortalUsers", "DistributorAdmins", "SterilisWasteTypeAdmin"];
    return sterGroups.includes(group);
};

export const isGroupAdminOrDistFse = (group) => {
    const groups = [
        "SterilisSuperUsers",
        "SterilisPortalUsers",
        "DistributorAdmins",
        "SterilisWasteTypeAdmin",
        "DistributorFSEs",
    ];
    return groups.includes(group);
};

export const isGroupDist = (group) => {
    const distGroups = ["DistributorAdmins", "DistributorReadOnly", "DistributorFSEs"];
    return distGroups.includes(group);
};

export const isGroupSterilisSuperUserOrFSE = (group) => {
    const sterGroups = ["SterilisSuperUsers", "SterilisPortalUsers", "FSEs", "SterilisWasteTypeAdmin"];
    return sterGroups.includes(group);
};

export const isGroupSuperUser = (group) => {
    const superUserGroup = ["SterilisSuperUsers", "SterilisWasteTypeAdmin"];
    return superUserGroup.includes(group);
};

export const isGroupCustomer = (group) => {
    const customerGroup = ["CustomerPortalUsers", "FacilityAdmin"];
    return customerGroup.includes(group);
};

export const isGroupNotCustomer = (group) => {
    const notCustomer = [
        "SterilisSuperUsers",
        "SterilisPortalUsers",
        "FSEs",
        "FactoryWorkers",
        "ExternalFSEs",
        "DistributorAdmins",
        "DistributorReadOnly",
        "DistributorFSEs",
        "SterilisWasteTypeAdmin",
    ];
    return notCustomer.includes(group);
};

export const isGroupInGroups = (group, groups) => {
    return groups.includes(group);
};

export const LOCAL_DATETIME_FORMAT = { ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: "short" };

export const prettyGroupName = (groupName, isDistributor = false) => {
    if (groupName === "SterilisWasteTypeAdmin") {
        return "Sterilis Waste Type Admin";
    } else if (groupName === "SterilisSuperUsers") {
        return "Sterilis Super User";
    } else if (groupName === "CustomerPortalUsers") {
        return "Customer Site Admin";
    } else if (groupName === "FacilityAdmin") {
        return "Facility Admin";
    } else if (groupName === "SterilisPortalUsers") {
        return "Sterilis Portal User";
    } else if (groupName === "FactoryWorkers") {
        return "Factory Worker";
    } else if (groupName === "FSEs") {
        return "Field Service Engineer";
    } else if (groupName === "ExternalFSEs") {
        return "External Field Service Engineer";
    } else if (groupName === "DistributorAdmins") {
        if (isDistributor) {
            return "Admin";
        } else {
            return "Distributor Admin";
        }
    } else if (groupName === "DistributorReadOnly") {
        if (isDistributor) {
            return "Read Only";
        } else {
            return "Distributor Read Only";
        }
    } else if (groupName === "DistributorFSEs") {
        if (isDistributor) {
            return "Field Service Engineer";
        } else {
            return "Distributor Field Service Engineer";
        }
    } else {
        return groupName;
    }
};

export const mobileCheck = () => {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true; // eslint-disable-line
    })(navigator.userAgent || navigator.vendor || window.opera); // eslint-disable-line
    return check;
};

// Make monochrome colors
export const pieColors = (function () {
    let colors = [],
        base = Highcharts.getOptions().colors[0],
        i;

    for (i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(new Highcharts.Color(base).brighten((i - 3) / 7).get());
    }
    return colors;
})();

export const useShowDeleted = (defaultValue = false) => {
    const [showDeleted, setShowDeleted] = useState(defaultValue);
    const handleHideDeleted = () => setShowDeleted(!showDeleted);
    return [showDeleted, handleHideDeleted];
};

export const serialSortHook = (a, b, columnID) => {
    const aSerial = a.original[columnID].props.children.split("-")[1];
    const bSerial = b.original[columnID].props.children.split("-")[1];
    return aSerial > bSerial ? 1 : -1;
};

export const colorSortHook = (a, b, columnID) => {
    const aValue = a.original[columnID]["props"]["data-sort"];
    const bValue = b.original[columnID]["props"]["data-sort"];
    return aValue < bValue ? 1 : -1;
};

export const epochSort = (a, b, columnID) => {
    const aDate = moment.unix(a.original[columnID].props["data-search"]);
    const bDate = moment.unix(b.original[columnID].props["data-search"]);
    return aDate.isAfter(bDate) ? 1 : -1;
};

export const timestampSortHook = (a, b, columnID, format) => {
    // need to use moment, native js doesn't recognize any non-north american 3 letter character timezone eg HST
    const aDate = moment(a.original[columnID], format);
    const bDate = moment(b.original[columnID], format);
    return aDate.isAfter(bDate) ? 1 : -1;
};

export const jsxTimestampSort = (a, b, format) => {
    const aDate = moment(a["props"]["children"], format);
    const bDate = moment(b["props"]["children"], format);
    return aDate.isAfter(bDate) ? 1 : -1;
};

export const alphabeticalJsxSort = (a, b, columnID) => {
    const aText = a.original[columnID]["props"]["children"].toLowerCase();
    const bText = b.original[columnID]["props"]["children"].toLowerCase();
    return aText < bText ? 1 : -1;
};

export const jsxTimestampSortHook = (a, b, columnID, format) => {
    const aDate = moment(a.original[columnID]["props"]["children"], format);
    const bDate = moment(b.original[columnID]["props"]["children"], format);
    return aDate.isAfter(bDate) ? 1 : -1;
};

export const commStatusSortHook = (a, b, columnID) => {
    if (a.original[columnID].props["data-value"] === b.original[columnID].props["data-value"]) {
        return a.original[columnID].props["data-value"] > b.original[columnID].props["data-value"] ? 1 : -1;
    }
    return a.original[columnID].props["data-value"] > b.original[columnID].props["data-value"] ? 1 : -1;
};

export const attrTimestampSortHook = (a, b, columnID, format) => {
    const aDate = moment(a.original[columnID]["props"]["data-date"], format);
    const bDate = moment(b.original[columnID]["props"]["data-date"], format);
    return aDate.isBefore(bDate) ? 1 : -1;
};

export const capitalizeFirstLetter = (string) => {
    return string && string[0].toUpperCase() + string.slice(1);
};

export const capitalizeFullString = (string) => {
    return string && string.toUpperCase();
};

export const getFileNameFromUrl = (url) => {
    const fileName = /([^/]+)(?=[?])/.exec(url)[0];
    // Remove the %20s from the file name, s3 puts them there
    return fileName.replace(/%20/g, " ");
};

export const alphabeticalSort = (a, b, keyToSortOn) => {
    const textA = a[keyToSortOn].toUpperCase();
    const textB = b[keyToSortOn].toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
};

export const prettyWastetypes = (wasteType) => {
    switch (wasteType) {
        case "red_bags":
            return "Red Bags";
        case "sharps":
            return "Sharps";
        case "yellow_bags":
            return "Yellow Bags";
        case "cannabis":
            return "Cannabis Microbial Waste";
        case "cannabis_product":
            return "Cannabis Product Waste";
        case "mixed":
            return "Mixed";
        case "dialysis":
            return "Dialysis";
        case "prv_test":
            return "PRV Test";
        case "biochallenge":
            return "Indicator";
        case "biovalidation":
            return "Biovalidation";
        case "bio":
            return "Indicator";
        case "prv":
            return "PRV Test";
        case "unknown":
            return "Unknown";
        case "inspect":
            return "Inspect";
        case "liquids":
            return "Liquids";
        case "soft_lab_waste":
            return "Soft Lab Waste";
        case "yellow_bags_nogrind":
            return "Yellow Bags No Grind";
        case "glass_slides":
            return "Glass Slides";
        case "ungrindable_waste":
            return "Ungrindable Waste";
        default:
            return wasteType;
    }
};

export const ConditionalWrap = ({ condition, wrap, children }) => (condition ? wrap(children) : children);

export const prettyMins = (time) => {
    return time > 1 ? "mins" : "min";
};

/*
  Takes in UTC Django timestamps & does moment.js math on them to figure out a cycle's cycle time (NOT steam time)
*/
export const calculateCycleTime = (timeStarted, timeEnded, lastEventTimestamp, lastStatusTimestamp) => {
    if (timeStarted && timeEnded) {
        const timeElapsed = moment.utc(timeEnded).diff(moment.utc(timeStarted), "minutes");
        return `${timeElapsed} ${prettyMins(timeElapsed)}`;
    } else if (lastEventTimestamp && lastStatusTimestamp) {
        const latestEvent = moment.utc(lastEventTimestamp);
        const latestStatus = moment.utc(lastStatusTimestamp);
        if (latestEvent.isAfter(latestStatus)) {
            const timeElapsed = latestEvent.diff(moment.utc(timeStarted), "minutes");
            return `${timeElapsed} ${prettyMins(timeElapsed)}`;
        } else {
            const timeElapsed = latestStatus.diff(moment.utc(timeStarted), "minutes");
            return `${timeElapsed} ${prettyMins(timeElapsed)}`;
        }
    } else {
        return `No cycle event or cycle status records this cycle.`;
    }
};

// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
// Copies text to the clipboard

export const copyToClipboard = (function initClipboardText() {
    const textarea = document.createElement("textarea");

    // Move it off screen.
    textarea.style.cssText = "position: absolute; left: -99999em";

    // Set to readonly to prevent mobile devices opening a keyboard when
    // text is .select()'ed.
    textarea.setAttribute("readonly", true);

    document.body.appendChild(textarea);

    return function setClipboardText(text) {
        textarea.value = text;

        // Check if there is any content selected previously.
        const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

        // iOS Safari blocks programmtic execCommand copying normally, without this hack.
        // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textarea.contentEditable;
            textarea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textarea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textarea.setSelectionRange(0, 999999);
            textarea.contentEditable = editable;
        } else {
            textarea.select();
        }

        try {
            const result = document.execCommand("copy");

            // Restore previous selection.
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }

            return result;
        } catch (err) {
            return false;
        }
    };
})();

export const generateCyclePDFData = (deviceCycles, userTimezone, t, customerUnitPreference) => {
    return deviceCycles.map((cycle) => {
        const cycleTime = moment
            .utc(cycle["time_started"])
            .tz(userTimezone)
            .format("YYYY-MM-DD HH:mm:ss z")
            .split(/\s(.+)/);
        const steamTime = Math.round(cycle["steam_time"]);
        const cookTime = Math.round(cycle["cook_time"]);
        return {
            weight: getDisplayWeight(cycle["weight"], cycle["waste_type"], customerUnitPreference),
            waste_type: t(prettyWastetypes(cycle["waste_type"])),
            cycle_state: t(cycle["cycle_state"]),
            user: cycle["full_name"],
            time_ended: cycle["time_ended"],
            cycle_time: calculateCycleTime(
                cycle["time_started"],
                cycle["time_ended"],
                cycle["latest_cycle_event_timestamp"],
                cycle["latest_cycle_status_timestamp"]
            ),
            time_started: moment.utc(cycle["time_started"]).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z"),
            max_pres_A: parseFloat(cycle["max_pvA"]).toFixed(2),
            max_pres_G: parseFloat(cycle["max_pvG"]).toFixed(2),
            max_temp: cycle["max_tv"],
            serial_number: cycle["serial_number"],
            simple_time_started: `${cycleTime[0]} ${cycleTime[1]} ${cycleTime[2]}`,
            cycle_comments: cycle["cycle_comments"] ? cycle["cycle_comments"] : "",
            steam_time: `${steamTime} ${steamTime > 1 ? t("mins") : t("min")}`,
            cook_time: `${cookTime} ${cookTime > 1 ? t("mins") : t("min")}`,
            facility_name: cycle["facility_name"],
        };
    });
};

// https://gist.github.com/lanqy/5193417
export const bytesToAppropriateUnit = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const AuthReq = (props) => {
    return props["requiredRoles"].includes(props["userGroup"]) ? (
        <React.Fragment>{props.children}</React.Fragment>
    ) : null;
};

export const getRoundedNumber = (obj, key, toFixed = 0, fillZeroIfNone = false) => {
    const number = get(obj, key, false);
    if (number === 0) {
        return "0";
    } else if (number) {
        if (typeof number === "number") {
            if (number < 1) {
                toFixed = 2;
            }
            return number.toFixed(toFixed);
        } else {
            if (number < 1) {
                toFixed = 2;
            }
            // If date.parse returns something, this is a date value
            if (Date.parse(number)) {
                return number;
            } else {
                return Number(number).toFixed(toFixed);
            }
        }
    } else {
        if (fillZeroIfNone) {
            return 0;
        } else {
            return null;
        }
    }
};

export const roundNumber = (num, toFixed = 2) => {
    const numberStr = typeof num === "number" ? num.toString() : num;
    const dec = numberStr.split(".")[1];
    const len = dec && dec.length > 0 ? toFixed : 0;
    return num.toFixed(len);
};

export const notifyFailureToast = (customMsg = false) =>
    toast(customMsg || `Operation failed. Please refresh this page and try again.`, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
    });

export const notifySuccessToast = (customMsg = false) =>
    toast(customMsg || `Success!`, {
        type: toast.TYPE.DEFAULT,
        autoClose: 5000,
    });

export const exists = (variable) => {
    return variable !== null && typeof variable !== "undefined";
};

const MLS_PER_LB_WATER = 453.59237;

/**
 * The function `getDisplayWeight` converts a given weight value to the preferred unit of measurement
 * based on the waste type or customer preference.
 * @param value - The `value` parameter represents the weight of the waste material.
 * @param wasteType - The `wasteType` parameter in the `getDisplayWeight` function is used to determine
 * how to display the weight based on the type of waste. If the waste type is "liquids", the function
 * converts the weight to milliliters. Otherwise, it uses the customer's unit preference to convert
 * @param [customerUnitPreference] - The `customerUnitPreference` parameter is an object that specifies
 * the customer's preferred unit of weight measurement. By default, it is set to an object with a
 * `weight` property set to "lb" (pounds). This parameter allows the function `getDisplayWeight` to
 * customize the displayed weight based
 * @returns The function `getDisplayWeight` returns either an empty string if the value is 0 or null,
 * or it converts the weight value based on the waste type. If the waste type is "liquids", it converts
 * the weight to milliliters and returns it with the unit "ml". Otherwise, it calls the `convertWeight`
 * function with the weight value and the customer unit preference object, and
 */
export const getDisplayWeight = (
    value,
    wasteType,
    customerUnitPreference = {
        weight: "lb",
    }
) => {
    // if the value is 0, a bio-challenge/prv test/inspect waste type
    if (value === 0 || value === "0" || value === null) {
        return "";
    }
    if (wasteType === "liquids") {
        const liquidsConversion = Math.round(value * MLS_PER_LB_WATER);
        return `${liquidsConversion} ml`;
    }
    return convertWeight(value, { ...customerUnitPreference, roundPrecision: 0.1 });
};

/**
 * The `convertWeight` function converts a weight value to a specified unit of measurement with
 * customizable options for rounding precision and displaying the unit.
 * @param value - The `value` parameter represents the weight value that you want to convert.
 * @param [] - The `convertWeight` function takes in two parameters:
 * @returns The `convertWeight` function returns a string representation of the weight value converted
 * to the customer's preferred unit of weight. If `showUnit` is set to true, the function returns the
 * weight value with the unit included. If `showUnit` is set to false, the function returns only the
 * numerical value without the unit.
 */
export const convertWeight = (
    value,
    { weight: customerUnitPreference = "lb", roundPrecision = 0.01, showUnit = true } = {}
) => {
    const qty = Qty(value, "lb").to(customerUnitPreference).toPrec(roundPrecision);

    if (showUnit) {
        return qty.toString();
    } else {
        return qty.toString().split(" ")[0];
    }
};

export const getPortalBaseURL = (env) => {
    let subDomain = "";
    switch (env) {
        case "dev":
            subDomain = "staging";
            break;
        case "testbed":
            subDomain = "testbed";
            break;
        case "prod":
            subDomain = "portal";
            break;
        default:
            subDomain = "portal";
    }
    return `https://${subDomain}.sterilissolutions.com`;
};

export const userTypeFromGroup = (group) => {
    if (isGroupSterilis(group)) {
        return "sterilis";
    } else if (isGroupDist(group)) {
        return "distributor";
    } else if (isGroupCustomer(group)) {
        return "customer";
    } else {
        return "operator";
    }
};

export const cleanCycleType = (cycleType) => {
    if (cycleType === "sterilization") {
        return "Steam Sterilization";
    } else if (cycleType === "biochallenge") {
        return "Bio-Challenge";
    } else if (cycleType === "biovalidation") {
        return "Bio-Validation";
    } else if (cycleType === "inspectgrinder") {
        return "Grinder Inspection";
    } else if (cycleType === "prv_test") {
        return "PRV-Test";
    } else if (cycleType === "grind_only") {
        return "Grind Only – Undiscernible";
    } else if (cycleType === "inspectbottomlid") {
        return "Bottom Lid Inspection";
    } else {
        return cycleType;
    }
};

export const calculateDurations = (obj, key, calcMinutes = false, fillZeroIfNone = false) => {
    key = key === "heatup_time" ? "ramp_duration" : key;
    if (key === "flow_seconds") {
        key = "flow_duration";
        calcMinutes = false;
    }
    if (obj[key] == null) {
        if (fillZeroIfNone) {
            return 0;
        } else {
            return null;
        }
    }

    const durationInSeconds = moment(obj[key], "HH:mm:ss").diff(moment().startOf("day"), "seconds");
    if (calcMinutes) {
        return Math.round(durationInSeconds / 60);
    }
    return durationInSeconds;
};

export const processBurninData = (burnindata) => {
    if (burnindata.test_type === 7) {
        let totalDuration = 0;
        burnindata.flow_seconds = calculateDurations(burnindata, "flow_duration", false, true);
        burnindata.pumpdown_duration_minutes = calculateDurations(burnindata, "pumpdown_duration", true, true);
        burnindata.draining_duration_minutes = calculateDurations(burnindata, "draining_duration", true, true);
        burnindata.heatup_time = calculateDurations(burnindata, "ramp_duration", true, true);

        burnindata.steam_cycle.forEach((cycle) => {
            cycle.pretty_cycle_type = "Steam Cycle";
            cycle.cycle_type = "SteamCycle";
            // data.steam_cycle.is_successful = data.successful
            cycle.duration_minutes = Math.round(
                (new Date(cycle.end_time).getTime() - new Date(cycle.start_time).getTime()) / 60 / 1000
            );
            totalDuration += cycle.duration_minutes;

            cycle.flow_seconds = calculateDurations(cycle, "flow_duration", false, true);
            cycle.pumpdown_duration_minutes = calculateDurations(cycle, "pumpdown_duration", true, true);
            cycle.draining_duration_minutes = calculateDurations(cycle, "draining_duration", true, true);
            cycle.heatup_time = calculateDurations(cycle, "ramp_duration", true, true);
        });

        burnindata.duration_minutes = totalDuration / 4;
    } else {
        burnindata.forEach((data) => {
            if (data.steam_cycle) {
                data.steam_cycle.pretty_cycle_type = "Steam Cycle";
                data.steam_cycle.cycle_type = "SteamCycle";
                // data.steam_cycle.is_successful = data.successful
                data.steam_cycle.duration_minutes = Math.round(
                    (new Date(data.steam_cycle.end_time).getTime() - new Date(data.steam_cycle.start_time).getTime()) /
                        60 /
                        1000
                );
            }

            if (data.grind_cycle) {
                data.grind_cycle.pretty_cycle_type = "Grind Cycle";
                data.grind_cycle.cycle_type = "GrindCycle";
                data.grind_cycle.duration_minutes = Math.round(
                    (new Date(data.grind_cycle.end_time).getTime() - new Date(data.grind_cycle.start_time).getTime()) /
                        60 /
                        1000
                );
            }
        });
    }

    return burnindata;
};

export const isNotEmptyObject = (object) => {
    return object && Object.keys(object).length > 0;
};

export const isEmptyObject = (object) => {
    return object && Object.keys(object).length <= 0;
};


export const dateTimeFilterRegex = new RegExp(/^(\d*)\/?(\d*)\/?(\d*),?\s?(\d{0,2}:?\d{0,2}:?\d{0,2})\s?(AM|PM)?$/);
