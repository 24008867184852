import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";

const FormSelect = ({ name, control, label, errors, options, rules, ...rest }) => {
    const generateOptions = () => {
        return options.map((option) => {
            return (
                <MenuItem key={option.id} value={option.value}>
                    {option.label}
                </MenuItem>
            );
        });
    };

    return (
        <FormControl fullWidth sx={{ minWidth: 120 }}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Controller
                name={name}
                rules={rules}
                control={control}
                // @ts-ignore
                render={({ value, ref, onChange }) => (
                    <Select
                        fullWidth
                        error={!!errors}
                        labelId={`${name}-label`}
                        label={label}
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        variant="outlined"
                        {...rest}>
                        {generateOptions()}
                    </Select>
                )}
            />
            {errors ? <FormHelperText>{errors.message}</FormHelperText> : null}
        </FormControl>
    );
};

FormSelect.defaultProps = {
    autoComplete: "",
};

FormSelect.propTypes = {
    name: PropTypes.string,
    control: PropTypes.any,
    label: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    autoComplete: PropTypes.string,
};

export default FormSelect;
