/*
 *  Component which holds the Device & date range pickers
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { Button, Container, Icon, Step, Dropdown } from "semantic-ui-react";
import { withTranslation as translate } from "react-i18next";
import DayPickerInput from "react-day-picker/DayPickerInput";
import RefreshCountdownTimer from "../dashboard/RefreshCountdownTimer";
import {Link} from 'react-router-dom';

import "react-day-picker/lib/style.css";
import "../../css/summary-dashboard/SummaryDashboardNav.css";

function refreshPage(){ 
  setTimeout(()=>{
    window.location.reload();
  }, 500);
};

const SummaryDashboardNav = (props) => (
  
  <Container>
    <RefreshCountdownTimer
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
    />
    <div className="dashboard-nav">
      <div className="nav-group">
        <div>
          <Step.Group fluid>
          <Step>
          <Link to={"/sa"} >
              <Icon name="home" className="steps-icon" />
              <Step.Content>
                <Step.Title as="h2">{props.t("Home")}</Step.Title>
              </Step.Content>
          </Link>
            </Step>
          {props.isFacility ? (
            <Step >
              <Link to={`/sa/summary-dashboard/${props.customerId}` } onClick={refreshPage}>
              <Icon name="user circle outline" className="steps-icon" />
              <Step.Content>
                <Step.Title as="h2">{props.customerName}</Step.Title>
              </Step.Content>
              </Link>
            </Step>
          
          ) : (
            <Step>
              <div>
              <Icon name="user circle outline" className="steps-icon" />
              <Step.Content>
                <Step.Title as="h2">{props.customerName}</Step.Title>
              </Step.Content>
              </div>
            </Step>

          )}
            {props.isFacility ? (
              <Step>
                <div>
                <Icon name="building outline" className="steps-icon" />
                <Step.Content>
                  <Dropdown
                    className="steps-icon"
                    button
                    labeled
                    defaultValue={props.facilityID}
                    options={props.facilities}
                    onChange= {props.selectedFacility}
                  />
                </Step.Content>
                </div>
              </Step>
            ) : <Step>
              <div>
            <Icon name="building outline" className="steps-icon" />
            <Step.Content>
            <Dropdown
              className="steps-icon"
              button
              labeled
              options={props.facilities}
              text={props.t("All facilities")}
              selectOnBlur={false}
              onChange= {props.selectedFacility}
            />
            </Step.Content>
            </div>
          </Step>}
          </Step.Group>
        </div>
      </div>
      <div className="dashboard-nav-right">
        {props.toDateClick && props.fromDateClick ? (
          <div className="date-group">
            <div className="nav-group from-date-div dashboard-datepicker">
              <label className="date-label">{props.t("From")}</label>
              <DayPickerInput
                onDayChange={(day) => props.fromDateClick(day)}
                value={props.fromDate}
              />
            </div>
            <div className="nav-group dashboard-datepicker">
              <label className="date-label">{props.t("To")}</label>
              <DayPickerInput
                onDayChange={(day) => props.toDateClick(day)}
                value={props.toDate}
              />
            </div>
          </div>
        ) : null}

        {props.prepareDashboard ? (
          <Button
            className="ster-btn show-data-btn"
            onClick={() => props.prepareDashboard(false)}
          >
            {props.t("Show Data")}
          </Button>
        ) : null}
      </div>
    </div>
  </Container>
);

export default translate("translations")(SummaryDashboardNav);
