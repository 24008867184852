/*
*  Initialization of i18n library
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import HttpBackend from "i18next-http-backend";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { config } from "../portal-environment";

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        load: "languageOnly",
        // have a common namespace used around the full app
        ns: "translations",
        defaultNS: "translations",
        debug: config.env !== "production",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            backends: [HttpBackend, Backend],
            backendOptions: [
                {
                    loadPath: "/locales/{{lng}}/{{ns}}.json",
                    addPath: "/locales/{{lng}}/{{ns}}",
                },
            ],
        },

        react: {
            useSuspense: true,
        },
    });


export default i18n;
