import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material/styles";

const SterilisChart = (props) => {
    const theme = useTheme();
    Highcharts.setOptions({
        title: {
            style: {
                fontFamily: "Poppins",
                color: theme.palette.primary.main,
                fontSize: "20px",
            },
        },
        subtitle: {
            style: {
                fontFamily: "Poppins",
                color: theme.palette.secondary.main,
            },
        },
        legend: {
            itemStyle: {
                fontFamily: "Poppins",
                color: theme.palette.text.secondary,
            },
            itemHiddenStyle: {
                textDecoration: undefined,
                color: theme.palette.divider,
            },
        },
        xAxis: {
            labels: {
                style: {
                    fontFamily: "Poppins",
                    color: theme.palette.text.disabled,
                },
            },
        },
        yAxis: {
            labels: {
                style: {
                    fontFamily: "Poppins",
                    color: theme.palette.text.disabled,
                },
            },
            title: {
                style: {
                    fontFamily: "Poppins",
                    color: theme.palette.secondary.light,
                },
            },
        },
        plotOptions: {
            column: {
                borderRadius: theme.shape.borderRadius,
            },
        },
        accessibility: {
            enabled: false,
        },
    });

    return <HighchartsReact highcharts={Highcharts} {...props} />;
};

SterilisChart.propTypes = {
    highcharts: PropTypes.any,
    options: PropTypes.object,
    updateArgs: PropTypes.arrayOf(PropTypes.bool),
};

export default SterilisChart;
