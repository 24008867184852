/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FlexBlockItem from '../library/FlexBlockItem';
import {roundNumber} from '../library/helpers'

const BurninScore = props => {
  const {burninScore, setBurninScore, setScoreCalculated} = props;
  const [burninTestResults, setBurninTestResults] = useState([]);
  const [pointsPossibleByTest, setPointsPossibleByTest] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    'total': 0,
  });

  useEffect(() => {
    const {
      burninTest1,
      burninTest2,
      burninTest3,
      burninTest4,
      burninTest5,
      burninTest6,
      burninTest7,
      abnormalBurninValues,
    } = props;

    const burninResultsArray = [];

    const burninTest1Results = {
      'test': 1,
      'missing': Object.keys(burninTest1).length <= 0,
      'score': 0,
    };
    const burninTest2Results = {
      'test': 2,
      'missing': Object.keys(burninTest2).length <= 0,
      'score': 0,
    };
    const burninTest3Results = {
      'test': 3,
      'missing': Object.keys(burninTest3).length <= 0,
      'score': 0,
    };
    const burninTest4Results = {
      'test': 4,
      'missing': Object.keys(burninTest4).length <= 0,
      'score': 0,
    };

    const burninTest5Results = {
      'test': 5,
      'missing': Object.keys(burninTest5).length <= 0,
      'score': 0,
    };
    const burninTest6Results = {
      'test': 6,
      'missing': Object.keys(burninTest6).length <= 0,
      'score': 0,
    };

    const burninTest7Results = {
      'test': 7,
      'missing': Object.keys(burninTest7).length <= 0,
      'score': 0,
    };


    Object.keys(burninTest1).forEach(burninStat => {
      const abnormalDefinition = abnormalBurninValues['1'][burninStat];
      const burninValue = burninTest1[burninStat];
      if (abnormalDefinition) {
        if ((burninValue <= abnormalDefinition['maximum_value']) && (burninValue >= abnormalDefinition['minimum_value'])) {
          burninTest1Results['score'] += abnormalDefinition['score_coefficient']
        }
      }
    });

    burninResultsArray.push(burninTest1Results);


    Object.keys(burninTest2).forEach(burninStat => {
      const abnormalDefinition = abnormalBurninValues['2'][burninStat];
      const burninValue = burninTest2[burninStat];
      if (abnormalDefinition) {
        if ((burninValue <= abnormalDefinition['maximum_value']) && (burninValue >= abnormalDefinition['minimum_value'])) {
          burninTest2Results['score'] += abnormalDefinition['score_coefficient']
        }
      }
    });
    burninResultsArray.push(burninTest2Results);

    Object.keys(burninTest3).forEach(burninStat => {
      const abnormalDefinition = abnormalBurninValues['3'][burninStat];
      const burninValue = burninTest3[burninStat];
      if (abnormalDefinition) {
        if ((burninValue <= abnormalDefinition['maximum_value']) && (burninValue >= abnormalDefinition['minimum_value'])) {
          burninTest3Results['score'] += abnormalDefinition['score_coefficient']
        }
      }
    });

    burninResultsArray.push(burninTest3Results);
    Object.keys(burninTest4).forEach(burninStat => {
      const abnormalDefinition = abnormalBurninValues['4'][burninStat];
      const burninValue = burninTest4[burninStat];
      if (abnormalDefinition) {
        if ((burninValue <= abnormalDefinition['maximum_value']) && (burninValue >= abnormalDefinition['minimum_value'])) {
          burninTest4Results['score'] += abnormalDefinition['score_coefficient']
        }
      }
    });

    burninResultsArray.push(burninTest4Results);


    Object.keys(burninTest5).forEach(burninStat => {
      const abnormalDefinition = abnormalBurninValues['5'][burninStat];
      const burninValue = burninTest5[burninStat];
      if (abnormalDefinition) {
        if ((burninValue <= abnormalDefinition['maximum_value']) && (burninValue >= abnormalDefinition['minimum_value'])) {
          burninTest5Results['score'] += abnormalDefinition['score_coefficient']
        }
      }
    });

    burninResultsArray.push(burninTest5Results);

    Object.keys(burninTest6).forEach(burninStat => {
      const abnormalDefinition = abnormalBurninValues['6'][burninStat];
      const burninValue = burninTest6[burninStat];
      if (abnormalDefinition) {
        if ((burninValue <= abnormalDefinition['maximum_value']) && (burninValue >= abnormalDefinition['minimum_value'])) {
          burninTest6Results['score'] += abnormalDefinition['score_coefficient']
        }
      }
    });

    burninResultsArray.push(burninTest6Results);

    Object.keys(burninTest7).forEach(burninStat => {
      const abnormalDefinition = abnormalBurninValues['7'][burninStat];
      const burninValue = burninTest7[burninStat];
      if (abnormalDefinition) {
        if ((burninValue <= abnormalDefinition['maximum_value']) && (burninValue >= abnormalDefinition['minimum_value'])) {
          burninTest7Results['score'] += abnormalDefinition['score_coefficient']
        }
      }
    });

    burninResultsArray.push(burninTest7Results);

    const hasAnyBurninTests = burninResultsArray.some(test => test['missing'] === false);

    if (hasAnyBurninTests) {
      const burninScore = burninResultsArray.reduce((acc, curr) => {
        acc += curr['score'];
        return acc;
      }, 0);

      setBurninScore(burninScore);
      setScoreCalculated(true);
      setBurninTestResults(burninResultsArray)

    }

  }, [props.burninTest1, props.burninTest2, props.burninTest3, props.burninTest4,
    props.burninTest5, props.burninTest6, props.burninTest7]);

  useEffect(() => {
    const {
      abnormalBurninValues
    } = props;

    const pointsByTest = {...pointsPossibleByTest};


    Object.entries(abnormalBurninValues).forEach(([burninTestNumber, burninTestAbnormalDefinitions]) => {
      pointsByTest[burninTestNumber] = Object.entries(burninTestAbnormalDefinitions).reduce((score, [name, definition]) => {
        if (definition['score_coefficient']) {
          score += definition['score_coefficient'];
          pointsByTest['total'] += definition['score_coefficient'];
        }
        return score;
      }, 0);
    });


    setPointsPossibleByTest(pointsByTest);


  }, [props.abnormalBurninValues]);


  const scoreLabel = () => {
    const scorePassThreshHold = 90;
    const scoreFailed = burninScore < scorePassThreshHold;

    const scoreIncomplete = Object.keys(burninTestResults).some(test => {
      return burninTestResults[test]['missing']
    });

    const completedTests = Object.keys(burninTestResults).reduce((acc, test) => {
      if (burninTestResults[test]['missing'] === false) {
        acc += 1;
      }
      return acc;
    }, 0);


    const numberOfCompletedTests = `(${completedTests} out of ${Object.keys(burninTestResults).length} tests completed)`;

    return <h2 className='black-text flex-dir-column center-text'>
      <div>
        Burn-In Score: <span className={scoreFailed ? 'red-text' : 'green-text'}>
        {burninScore} - {scoreIncomplete ? 'Incomplete' : scoreFailed ? 'Fail' : 'Pass'}
        </span>
      </div>
      <div className={scoreFailed ? 'red-text' : 'green-text'}>{numberOfCompletedTests}</div>
    </h2>
  };

  const testSummaryLabel = ({missing}) => {
    return missing ? '(Incomplete)' : '';
  };


  return <div className='burnin-stats-container'>
    {scoreLabel()}
    <details>
      <summary className='remaining-cycle-stat-summary'>Show Score Breakdown by Burn-In Test</summary>
      <div>
        {
          burninTestResults.map((result, idx) => {
            return <React.Fragment key={idx}>
              <FlexBlockItem
                label={`Test ${result['test']} ${testSummaryLabel(result)}`}
                value={`${roundNumber(result['score'])} out of ${roundNumber(pointsPossibleByTest[result['test']])}`}
                itemKey={`${result['test']}-${idx}`}
              />
            </React.Fragment>
          })
        }
      </div>
      <div>
        <FlexBlockItem
          label={`Total Possible Points`}
          value={`${burninScore} out of ${pointsPossibleByTest['total']}`}
        />
      </div>
    </details>
  </div>
};

BurninScore.defaultProps = {
  burninTest1: {},
  burninTest2: {},
  burninTest3: {},
  burninTest4: {},
  burninTest5: {},
  burninTest6: {},
  burninTest7: {},
  abnormalBurninValues: {},

};

BurninScore.propTypes = {
  burninTest1: PropTypes.object,
  burninTest2: PropTypes.object,
  burninTest3: PropTypes.object,
  burninTest4: PropTypes.object,
  burninTest5: PropTypes.object,
  burninTest6: PropTypes.object,
  burninTest7: PropTypes.object,
  abnormalBurninValues: PropTypes.object,
  setScoreCalculated: PropTypes.func.isRequired,
  setBurninScore: PropTypes.func.isRequired,

};

export default BurninScore;
