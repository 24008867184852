/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { Trans,withTranslation as translate} from "react-i18next";
import {Button, Card, Dropdown} from "semantic-ui-react";
import SimpleLoader from "../SimpleLoader";
import {composeHoc} from "../library/helpers";
import withAuth from "../withAuth";
import AuthService from "../AuthService";
import * as Sentry from "@sentry/browser";
import {toast} from "react-toastify";
import debounce from "lodash/debounce";
import moment from "moment";
import { withRouter } from "react-router-dom";
const Auth = new AuthService();

class UploadFirmware extends React.Component {
  state = {
    isLoading: false,
    firmwareVersion: '',
    checksum: '',
    description: '',
    uploadedSoftware: '',
    checksumValidationError: false,
    setChecksumShake: false,
    setFileNameShake: false,
    fileNameValidationError: false,
    linuxVersion : '4.19.58',
  };
  notifySuccess = (firmwareVersion) => toast(`Successfully uploaded software ${firmwareVersion}`, {
    type: toast.TYPE.DEFAULT,
    autoClose: 15000
  });
  notifyUploadFailure = () => toast(`Failed to upload file. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });
  notifyUploadFailureWithReason = (reason) => toast(`Failed to upload file. Server response: ${reason}`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  componentDidMount() {
    document.title = 'Upload Software';
  }

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    name === 'checksum' && this.debouncedCheckChecksum(value);
    this.setState({[name]: value});
  };

  debouncedCheckChecksum = debounce((checksum) => this.checkChecksum(checksum), 500);

  checkChecksum = (checksum) => {
    this.setState({
      checksumValidationError: checksum.length !== 40
    }, () => checksum.length !== 40 && this.shakeChecksum());
  };

  uploadSoftware = e => {
    e.preventDefault();
    const {
      firmwareVersion,
      checksum,
      description,
      uploadedSoftware,
      linuxVersion,
    } = this.state;


    const formData = new FormData();
    formData.append('s3_location', uploadedSoftware);
    formData.append('description', description);
    formData.append('checksum', checksum);
    formData.append('firmware_version', firmwareVersion);
    formData.append('linux_version', linuxVersion);
    formData.append('uploaded_timestamp', moment.utc().format("YYYY-MM-DD HH:mm:ss"));

    this.setState({
      isLoading: true
    });
    Auth.file_fetch(`/api/firmware`, {
      method: 'POST',
      body: formData
    }).then(data => {
      this.notifySuccess(data['firmware_version']);
      this.setState({
        isLoading: false
      }, () => this.props.history.replace('/view/software'));
    }).catch(err => {
      err.response.json().then(resp => {
        if (resp['firmware_version']) {
          this.notifyUploadFailureWithReason(resp['firmware_version'])
        } else {
          this.notifyUploadFailure();
        }
      });
      Sentry.captureException(err);
      this.setState({
        isLoading: false
      });
    })
  };

  handleFileChange = (selectorFiles) => {
    if (selectorFiles.length > 0) {
      const fileName = selectorFiles[0].name.split(".")[0];
      const fileNameValidationError = !(moment.unix(fileName).isValid());

      fileNameValidationError && this.shakeFileName();

      this.setState({
        documentTitle: selectorFiles[0].name,
        uploadedSoftware: selectorFiles[0],
        fileNameValidationError
      })
    }
  };

  shakeChecksum = () => {
    this.setState({
      setChecksumShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setChecksumShake: false,
        })
      }, 1000)
    })
  };

  shakeFileName = () => {
    this.setState({
      setFileNameShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setFileNameShake: false,
        })
      }, 1000)
    })
  };

  handleLinuxChange = (event, data) => {
    this.setState({
      linuxVersion: data.value,
    });
  };


  render() {
    const {
      isLoading,
      checksumValidationError,
      setChecksumShake,
      fileNameValidationError,
      setFileNameShake
    } = this.state;
    const {
      t
    } = this.props;

    const linuxDropdownOptions = [
		{
			key: "4.19.58",
			value: "4.19.58",
			text: "Buster/DHCP 4.19+",
		},
		{
			key: "4.9.35",
			value: "4.9.35",
			text: "Jessie/NM 4.9.35-v7",
		},
		{
			key: "5.15.76",
			value: "5.15.76",
			text: "Bullseye/DHCP 5.15+",
		},
	];

    return (
		<Card fluid>
			{isLoading ? <SimpleLoader /> : null}
			<Card.Header style={{ margin: "10px" }}>
				<h2>
					<Trans ns="translations" i18nKey="upload-software.header">
						Upload Software
					</Trans>
				</h2>
			</Card.Header>
			<Card.Content className="card-body">
				<div className="split-container even-split">
					<div className="wide-desc">
						<h3 className="orange-text">
							<Trans ns="translations" i18nKey="upload-software.subheader">
								Upload a new version of device software here
							</Trans>
						</h3>
						<p>
							<Trans ns="translations" i18nKey="upload-software.1">
								<strong>Version name</strong> is a <strong>unique</strong> string describing which
								version of device software the new upload is.
							</Trans>
						</p>
						<p className="important-left-margin">
							e.g <strong>v2.4.1-1864-r22d5e1e</strong>, <strong>v2.4_rc5-1852-r6ffed07</strong>
						</p>
						<p
							className={`${setChecksumShake && " shake "} ${
								checksumValidationError && "label-validation-error"
							}`}
						>
							<Trans ns="translations" i18nKey="upload-software.2">
								<strong>Checksum</strong> is a <strong>40 character long hex string</strong> generated
								by running the{" "}
								<a
									rel="noopener noreferrer"
									href="https://en.wikipedia.org/wiki/Sha1sum"
									target="_blank"
								>
									sha1sum
								</a>{" "}
								script against the new upload.
							</Trans>
						</p>
						<p className="important-left-margin">
							e.g <strong>963a9ac90f66f7b74779b37495ef275079adb93a</strong>
						</p>
						<p>
							<Trans ns="translations" i18nKey="upload-software.3">
								<strong>Description</strong> is an optional field used to help describe the new upload
								to other members of Sterilis.
							</Trans>
						</p>
						<p className="important-left-margin">
							<Trans ns="translations" i18nKey="upload-software.4">
								e.g <strong>'Grind improvements, cook time changes, bug fixes'</strong>
							</Trans>
						</p>
						<p
							className={`${setFileNameShake && " shake "} ${
								fileNameValidationError && "label-validation-error"
							}`}
						>
							<Trans ns="translations" i18nKey="upload-software.5">
								The <strong>file</strong> must be of type <strong>.tgz</strong> and the filename must be
								a valid UNIX Epoch date.
							</Trans>
						</p>
						<p className="important-left-margin">
							e.g <strong>1551470951.tgz</strong>
						</p>
					</div>
					<div className="wide-no-flex-items">
						<form id="uploadSoftwareForm" className="slight-padding-top" onSubmit={this.uploadSoftware}>
							<div className="form-group slight-margin-top">
								<input
									value={this.state.firmwareVersion}
									name="firmwareVersion"
									onChange={this.handleChange}
									type="text"
									id="firmwareVersionInput"
									className="form-control"
									required
								/>
								<label className="form-control-placeholder" htmlFor="firmwareVersionInput">
									{t("Version name")}
								</label>
							</div>
							<div className="form-group">
								<label className="fake-float">{t("Linux version")}</label>
								<Dropdown
									className="wide-dropdown"
									search
									selection
									onChange={this.handleLinuxChange}
									fluid
									value={this.state.linuxVersion}
									id="linuxDropdown"
									options={linuxDropdownOptions}
								/>
							</div>
							<div className="form-group">
								<input
									value={this.state.checksum}
									name="checksum"
									onChange={this.handleChange}
									type="text"
									id="checksumInput"
									className="form-control"
									autoComplete="nope"
									required
								/>
								<label
									className={`form-control-placeholder ${
										checksumValidationError && "label-validation-error"
									}`}
									htmlFor="checksumInput"
								>
									{t("Checksum")}
								</label>
							</div>
							<div className="form-group">
								<label className="fake-float">{t("Description")}</label>
								<textarea
									value={this.state.description}
									name="description"
									onChange={this.handleChange}
									id="descriptionTextArea"
									rows="5"
									className="form-control"
								/>
							</div>
							<div className="form-group reduced-width">
								<label
									className={`fake-float ${fileNameValidationError && "label-validation-error"}`}
									htmlFor="uploadDocumentInput"
								>
									{t("File")}
								</label>
								<input
									type="file"
									id="uploadFileInput"
									className="form-control"
									accept=".tgz"
									onChange={(e) => this.handleFileChange(e.target.files)}
									required
								/>
							</div>
							<Button
								className="ster-btn float-right"
								value="Submit"
								type="submit"
								form="uploadSoftwareForm"
								id="uploadSoftwareBtn"
								disabled={checksumValidationError || fileNameValidationError}
							>
								{t("Upload software")}
							</Button>
						</form>
					</div>
				</div>
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(withRouter, translate('translations'),
  withAuth(['SterilisSuperUsers','SterilisWasteTypeAdmin'], 'internalPage'))(UploadFirmware);
