import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import { DateTime } from "luxon";
import AuthService from "src/components/AuthService";
import { alphabeticalSort, calculateDurations, prettyWastetypes } from "src/components/library/helpers";

const auth = new AuthService();

const initialState = {
    deviceConfigId: null,
    deviceDropdown: [],
    abnormalParserValues: [],
    abnormalParserValuesObj: [],
    loading: false,
    loadingText: "",
    metaIds: [],
    cycles: [],
    cycleData: [],
    digitalOutput: [],
    selectedCycle: {},
    selectedIndex: 0,
    toDate: null,
    fromDate: null,
    steamCycleTrends: {
        scoreTrend: [],
        tvAvgHoldTrend: [],
        tpDiffTrend: [],
        durationTrend: [],
        flowSecondsTimeTrend: [],
        steamPumpdownTimeTrend: [],
        drainTimeTrend: [],
        heatUpTimeTrend: [],
        pumpdownTimeoutTrend: [],
        tvStartTrend: [],
        tvMaxTrend: [],
        trAvgTrend: [],
        tvAvgDuringHoldTrend: [],
        pvStartTrend: [],
        pvMinTrend: [],
        pvMaxTrend: [],
        pvAvgDuringHoldTrend: [],
        heatUpRateDifferenceTrend: [],
    },
    grindCycleTrends: {
        durationTrend: [],
        triesTrend: [],
        stallsTrend: [],
        jackTrend: [],
        numberStallsTrend: [],
        forwardDurationTrend: [],
        reverseDurationTrend: [],
    },
    sharedCycleTrends: {
        dutyCycleAvgTrend: [],
        dutyCycleStartTrend: [],
        dutyCycleEndTrend: [],
    },
    containsBurninTest: false,
    show: {
        cycleSelection: false,
        results: false,
        bit: false,
    },
};

const metricSlice = createSlice({
    name: "metric",
    initialState,
    reducers: {
        setConfigId(state, action) {
            state.deviceConfigId = action.payload;
        },
        setDeviceDropdown(state, action) {
            state.deviceDropdown = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setLoadingText(state, action) {
            state.loadingText = action.payload;
        },
        setMetaIds(state, action) {
            state.metaIds = action.payload;
        },
        setCycles(state, action) {
            state.cycles = action.payload;
        },
        setCycleData(state, action) {
            state.cycleData = action.payload;
        },
        addCycleData(state, action) {
            state.cycleData.push(action.payload);
        },
        setSelectedCycle(state, action) {
            if (state.selectedCycle !== action.payload) {
                state.selectedCycle = action.payload;
            }
        },
        setSelectedIndex(state, action) {
            state.selectedIndex = action.payload;
        },
        setSteamCycleTrends(state, action) {
            state.steamCycleTrends = action.payload;
        },
        addSteamCycleTrend(state, action) {
            state.steamCycleTrends[action.payload.key].push(action.payload.value);
        },
        clearSteamCycleTrends(state) {
            state.steamCycleTrends = initialState.steamCycleTrends;
        },
        setGrindCycleTrends(state, action) {
            state.grindCycleTrends = action.payload;
        },
        addGrindCycleTrend(state, action) {
            state.grindCycleTrends[action.payload.key].push(action.payload.value);
        },
        clearGrindCycleTrends(state) {
            state.grindCycleTrends = initialState.grindCycleTrends;
        },
        setSharedCycleTrends(state, action) {
            state.sharedCycleTrends = action.payload;
        },
        addSharedCycleTrend(state, action) {
            state.sharedCycleTrends[action.payload.key].push(action.payload.value);
        },
        clearSharedCycleTrends(state) {
            state.sharedCycleTrends = initialState.sharedCycleTrends;
        },
        setDigitalOutput(state, action) {
            state.digitalOutput = action.payload;
        },
        setContainsBurninTest(state, action) {
            state.containsBurninTest = action.payload;
        },
        setShowCycleSelection(state, action) {
            state.show.cycleSelection = action.payload;
        },
        setShowResults(state, action) {
            state.show.results = action.payload;
        },
        setShowBit(state, action) {
            state.show.bit = action.payload;
        },
        setToDate(state, action) {
            state.toDate = action.payload;
        },
        setFromDate(state, action) {
            state.fromDate = action.payload;
        },
        resetMetricState(state) {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDigitalOutput.pending, (state, action) => {
                state.digitalOutput = [];
            })
            .addCase(fetchDigitalOutput.fulfilled, (state, action) => {
                state.digitalOutput = action.payload;
            })
            .addCase(fetchDigitalOutput.rejected, (state, action) => {
                state.digitalOutput = [];
            })
            .addCase(fetchDeviceDropdown.pending, (state) => {
                state.loading = true;
                state.loadingText = t("Loading Devices");
            })
            .addCase(fetchDeviceDropdown.fulfilled, (state, action) => {
                state.loading = false;
                state.loadingText = "";
                state.deviceDropdown = action.payload;
            })
            .addCase(fetchDeviceDropdown.rejected, (state, action) => {
                state.loading = false;
                state.loadingText = "";
                state.deviceDropdown = [];
            })
            .addCase(fetchAbnormalParserValues.pending, (state) => {
                state.abnormalParserValues = initialState.abnormalParserValues;
                state.abnormalParserValuesObj = initialState.abnormalParserValuesObj;
            })
            .addCase(fetchAbnormalParserValues.fulfilled, (state, action) => {
                state.abnormalParserValues = action.payload;

                const abnormalValueObj = action.payload.reduce((acc, cur) => {
                    acc[cur["value_name"]] = {
                        ...cur,
                    };
                    return acc;
                }, {});

                state.abnormalParserValuesObj = abnormalValueObj;
            })
            .addCase(fetchAbnormalParserValues.rejected, (state, action) => {
                state.abnormalParserValues = initialState.abnormalParserValues;
                state.abnormalParserValuesObj = initialState.abnormalParserValuesObj;
            })
            .addCase(fetchAvailableCycles.pending, (state) => {
                state.cycles = [];
                state.cycleData = [];
                state.steamCycleTrends = initialState.steamCycleTrends;
                state.grindCycleTrends = initialState.grindCycleTrends;
                state.sharedCycleTrends = initialState.sharedCycleTrends;
                state.selectedCycle = initialState.selectedCycle;
                state.loading = true;
                state.loadingText = t("Loading Cycles");
            })
            .addCase(fetchAvailableCycles.fulfilled, (state, action) => {
                state.cycles = action.payload;
                state.cycleData = initialState.cycleData;
                state.show.results = false;
                state.show.cycleSelection = true;
                state.loading = false;
                state.loadingText = "";
            })
            .addCase(fetchAvailableCycles.rejected, (state, action) => {
                state.loading = false;
                state.loadingText = "";
                state.show.cycleSelection = false;
            })
            .addCase(fetchCycleEvents.pending, (state) => {
                state.loading = true;
                state.loadingText = "Loading Cycle Events";
                state.show.results = false;
            })
            .addCase(fetchCycleEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.loadingText = "";
                const cycle = action.payload;
                let eventData = {};
                cycle.start_time = 0;

                if (cycle.notifications) {
                    if (cycle.steam_cycle) {
                        const steam_notifications = cycle.notifications.filter((msg) => {
                            return (
                                msg.type === "vessel" &&
                                msg.time >= cycle.steam_cycle.start_time &&
                                msg.time <= cycle.steam_cycle.end_time
                            );
                        });
                        if (steam_notifications) {
                            cycle.steam_cycle.notifications = steam_notifications;
                            cycle.steam_cycle.info_count = steam_notifications.length;
                        }
                    }

                    if (cycle.grind_cycle) {
                        const grind_notifications = cycle.notifications.filter((msg) => {
                            return (
                                msg.type === "grinder" &&
                                msg.message !== "Grinder Moving To: GS_IDLE" &&
                                msg.time >= cycle.grind_cycle.start_time &&
                                msg.time <= cycle.grind_cycle.end_time
                            );
                        });
                        if (grind_notifications) {
                            cycle.grind_cycle.notifications = grind_notifications;
                            cycle.grind_cycle.info_count = grind_notifications.length;
                        }
                    }
                }
                if (cycle?.steam_cycle?.start_time) {
                    const startTime = new Date(cycle.steam_cycle.start_time).getTime();
                    cycle.start_time = startTime > cycle.start_time ? startTime : cycle.start_time;
                    cycle.steam_cycle.pretty_cycle_type = "Steam Cycle";
                    cycle.steam_cycle.cycle_type = "SteamCycle";
                    cycle.steam_cycle.cycle_id = cycle.cycle_id.toString();
                    cycle.steam_cycle.pretty_waste_type = prettyWastetypes(cycle.waste_type);
                    cycle.steam_cycle.is_successful = cycle.successful;
                    cycle.steam_cycle.duration_minutes = Math.round(
                        (new Date(cycle.steam_cycle.end_time).getTime() -
                            new Date(cycle.steam_cycle.start_time).getTime()) /
                            60 /
                            1000
                    );
                    eventData.steam_cycle = cycle.steam_cycle;
                    eventData.start_time = cycle.start_time;

                    // Add trend data
                    state.steamCycleTrends.scoreTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["score"] ? cycle.steam_cycle["score"].toFixed(2) : 0),
                    ]);
                    state.steamCycleTrends.scoreTrend = state.steamCycleTrends.scoreTrend.sort((a, b) => a[0] - b[0]);

                    state.steamCycleTrends.durationTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["duration_minutes"]),
                    ]);
                    state.steamCycleTrends.durationTrend = state.steamCycleTrends.durationTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.tvAvgHoldTrend.push([
                        startTime,
                        Number(
                            cycle.steam_cycle["tv_average_during_hold"]
                                ? cycle.steam_cycle["tv_average_during_hold"].toFixed(2)
                                : 0
                        ),
                    ]);
                    state.steamCycleTrends.tvAvgHoldTrend = state.steamCycleTrends.tvAvgHoldTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.tpDiffTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["tp_diff"] ? cycle.steam_cycle["tp_diff"].toFixed(2) : 0),
                    ]);
                    state.steamCycleTrends.tpDiffTrend = state.steamCycleTrends.tpDiffTrend.sort((a, b) => a[0] - b[0]);

                    state.steamCycleTrends.flowSecondsTimeTrend.push([
                        startTime,
                        cycle.steam_cycle["flow_duration"]
                            ? calculateDurations(cycle.steam_cycle, "flow_duration", false, true)
                            : 0,
                    ]);
                    state.steamCycleTrends.flowSecondsTimeTrend = state.steamCycleTrends.flowSecondsTimeTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.steamPumpdownTimeTrend.push([
                        startTime,
                        calculateDurations(cycle.steam_cycle, "pumpdown_duration", true, true),
                    ]);
                    state.steamCycleTrends.steamPumpdownTimeTrend = state.steamCycleTrends.steamPumpdownTimeTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.drainTimeTrend.push([
                        startTime,
                        calculateDurations(cycle.steam_cycle, "draining_duration", true, true),
                    ]);
                    state.steamCycleTrends.drainTimeTrend = state.steamCycleTrends.drainTimeTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.heatUpTimeTrend.push([
                        startTime,
                        calculateDurations(cycle, "ramp_duration", true, true),
                    ]);
                    state.steamCycleTrends.heatUpTimeTrend = state.steamCycleTrends.heatUpTimeTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.pumpdownTimeoutTrend.push([
                        startTime,
                        Number(
                            cycle.steam_cycle["pumpdown_timeout_count"]
                                ? cycle.steam_cycle["pumpdown_timeout_count"].toFixed(2)
                                : 0
                        ),
                    ]);
                    state.steamCycleTrends.pumpdownTimeoutTrend = state.steamCycleTrends.pumpdownTimeoutTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.tvStartTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["tv_start"] ? cycle.steam_cycle["tv_start"].toFixed(2) : 0),
                    ]);
                    state.steamCycleTrends.tvStartTrend = state.steamCycleTrends.tvStartTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.tvMaxTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["tv_max"] ? cycle.steam_cycle["tv_max"].toFixed(2) : 0),
                    ]);
                    state.steamCycleTrends.tvMaxTrend = state.steamCycleTrends.tvMaxTrend.sort((a, b) => a[0] - b[0]);

                    state.steamCycleTrends.trAvgTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["tr_average"] ? cycle.steam_cycle["tr_average"].toFixed(2) : 0),
                    ]);
                    state.steamCycleTrends.trAvgTrend = state.steamCycleTrends.trAvgTrend.sort((a, b) => a[0] - b[0]);

                    state.steamCycleTrends.tvAvgDuringHoldTrend.push([
                        startTime,
                        Number(
                            cycle.steam_cycle["tv_average_during_hold"]
                                ? cycle.steam_cycle["tv_average_during_hold"].toFixed(2)
                                : 0
                        ),
                    ]);
                    state.steamCycleTrends.tvAvgDuringHoldTrend = state.steamCycleTrends.tvAvgDuringHoldTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.pvStartTrend.push(cycle.steam_cycle["pv_start"] ? cycle["pv_start"] : 0);
                    state.steamCycleTrends.pvStartTrend = state.steamCycleTrends.pvStartTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.pvMinTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["pv_min"] ? cycle.steam_cycle["pv_min"].toFixed(2) : 0),
                    ]);
                    state.steamCycleTrends.pvMinTrend = state.steamCycleTrends.pvMinTrend.sort((a, b) => a[0] - b[0]);

                    state.steamCycleTrends.pvMaxTrend.push([
                        startTime,
                        Number(cycle.steam_cycle["pv_max"] ? cycle.steam_cycle["pv_max"].toFixed(2) : 0),
                    ]);
                    state.steamCycleTrends.pvMaxTrend = state.steamCycleTrends.pvMaxTrend.sort((a, b) => a[0] - b[0]);

                    state.steamCycleTrends.pvAvgDuringHoldTrend.push([
                        startTime,
                        Number(
                            cycle.steam_cycle["pv_average_during_hold"]
                                ? cycle.steam_cycle["pv_average_during_hold"].toFixed(2)
                                : 0
                        ),
                    ]);
                    state.steamCycleTrends.pvAvgDuringHoldTrend = state.steamCycleTrends.pvAvgDuringHoldTrend.sort(
                        (a, b) => a[0] - b[0]
                    );

                    state.steamCycleTrends.heatUpRateDifferenceTrend.push([
                        startTime,
                        Number(
                            cycle.steam_cycle["heat_up_rate_delta"]
                                ? cycle.steam_cycle["heat_up_rate_delta"].toFixed(2)
                                : 0
                        ),
                    ]);
                    state.steamCycleTrends.heatUpRateDifferenceTrend =
                        state.steamCycleTrends.heatUpRateDifferenceTrend.sort((a, b) => a[0] - b[0]);

                    // Shared Trend Data
                    state.sharedCycleTrends.dutyCycleStartTrend.push([
                        startTime,
                        Number(Math.round(cycle.steam_cycle["duty_cycle_start"])),
                    ]);
                    state.sharedCycleTrends.dutyCycleEndTrend.push([
                        startTime,
                        Number(Math.round(cycle.steam_cycle["duty_cycle_end"])),
                    ]);
                    state.sharedCycleTrends.dutyCycleAvgTrend.push([
                        startTime,
                        Number(Math.round(cycle.steam_cycle["duty_cycle_average"])),
                    ]);
                }
                if (cycle?.grind_cycle?.start_time) {
                    const startTime = new Date(cycle.grind_cycle.start_time).getTime();
                    cycle.start_time = startTime > cycle.start_time ? startTime : cycle.start_time;
                    cycle.grind_cycle.pretty_cycle_type = "Grind Cycle";
                    cycle.grind_cycle.cycle_type = "GrindCycle";
                    cycle.grind_cycle.cycle_id = cycle.cycle_id.toString();
                    cycle.grind_cycle.pretty_waste_type = prettyWastetypes(cycle.waste_type);
                    cycle.grind_cycle.is_successful = cycle.successful;
                    cycle.grind_cycle.duration_minutes = Math.round(
                        (new Date(cycle.grind_cycle.end_time).getTime() -
                            new Date(cycle.grind_cycle.start_time).getTime()) /
                            60 /
                            1000
                    );
                    eventData.grind_cycle = cycle.grind_cycle;
                    eventData.start_time = cycle.start_time;

                    // Add grind trend data
                    state.grindCycleTrends.durationTrend.push([
                        startTime,
                        Number(Math.round(cycle.grind_cycle["duration_minutes"])),
                    ]);
                    state.grindCycleTrends.triesTrend.push([
                        startTime,
                        cycle.grind_cycle["attempts"] ? cycle.grind_cycle["attempts"] : 0,
                    ]);
                    state.grindCycleTrends.stallsTrend.push([
                        startTime,
                        cycle.grind_cycle["stalls"] ? cycle.grind_cycle["stalls"] : 0,
                    ]);
                    state.grindCycleTrends.jackTrend.push([
                        startTime,
                        cycle.grind_cycle["jack"] ? cycle.grind_cycle["jack"] : 0,
                    ]);
                    state.grindCycleTrends.numberStallsTrend.push([
                        startTime,
                        cycle.grind_cycle["stalls"] ? cycle.grind_cycle["stalls"] : 0,
                    ]);
                    state.grindCycleTrends.forwardDurationTrend.push([
                        startTime,
                        calculateDurations(cycle.grind_cycle, "forward_duration", true, true),
                    ]);
                    state.grindCycleTrends.reverseDurationTrend.push([
                        startTime,
                        calculateDurations(cycle.grind_cycle, "reverse_duration", true, true),
                    ]);

                    // Shared Trend Data
                    state.sharedCycleTrends.dutyCycleStartTrend.push([
                        startTime,
                        Number(Math.round(cycle.grind_cycle["duty_cycle_start"])),
                    ]);
                    state.sharedCycleTrends.dutyCycleEndTrend.push([
                        startTime,
                        Number(Math.round(cycle.grind_cycle["duty_cycle_end"])),
                    ]);
                    state.sharedCycleTrends.dutyCycleAvgTrend.push([
                        startTime,
                        Number(Math.round(cycle.grind_cycle["duty_cycle_average"])),
                    ]);
                }

                state.cycleData.push(eventData);
                state.show.cycleSelection = false;
                state.show.results = true;
            })
            .addCase(fetchCycleEvents.rejected, (state, action) => {
                state.loading = false;
                state.loadingText = "";
            });
    },
});

export const selectEarliestCycle = (state) => {
    if (state.metric.cycleData) {
        let cycle = state.metric.cycleData.reduce((a, b) => {
            const aTime = DateTime.fromISO(a.end_time);
            const bTime = DateTime.fromISO(b.end_time);
            return aTime >= bTime ? a : b;
        });
        return cycle;
    } else {
        return {};
    }
};

export const fetchDigitalOutput = createAsyncThunk("metric/fetchDigitalOutput", async () => {
    const response = await auth.fetch(`/metrics/digital_output`);
    return response;
});

export const fetchDeviceDropdown = createAsyncThunk("metric/fetchDeviceDropdown", async () => {
    const response = await auth.fetch(`/api/get-serials/`);
    const serialFacilityCustomerArray = response.map((item) => {
        return {
            key: item["id"],
            value: item["id"],
            text: `${item["customer"]} : ${item["facility"]} : ${item["serial_number"]}`,
            customer_id: item["customer_id"],
            device_id: item["device_id"],
        };
    });

    serialFacilityCustomerArray.sort((a, b) => alphabeticalSort(a, b, "text"));
    return serialFacilityCustomerArray;
});

export const fetchAbnormalParserValues = createAsyncThunk("metric/fetchAbnormalParserValues", async () => {
    const response = await auth.fetch(`/metrics/abnormal_parser_values`);
    return response;
});

export const fetchAvailableCycles = createAsyncThunk(
    "metric/fetchAvailableCycles",
    /** @param params {{ deviceConfig: string, startDate: string, endDate: string }} */
    async (params) => {
        if (params.deviceConfig && params.startDate && params.endDate) {
            const response = await auth.fetch(
                `/metrics/cycle?` +
                    new URLSearchParams({
                        start_date: params.startDate,
                        end_date: params.endDate,
                        device_config_id: params.deviceConfig,
                    })
            );
            return response.filter((cycle) => cycle.metadata !== null);
        }
    }
);

export const fetchCycleEvents = createAsyncThunk("metric/fetchCycleEvents", async (metadataId) => {
    const response = await auth.fetch(`/metrics/metadata/${metadataId}/cycle_events`);
    return response;
});

export const {
    setConfigId,
    setDeviceDropdown,
    setLoading,
    setLoadingText,
    setMetaIds,
    setCycles,
    setCycleData,
    addCycleData,
    setSelectedCycle,
    setSelectedIndex,
    setSteamCycleTrends,
    addSteamCycleTrend,
    clearSteamCycleTrends,
    setGrindCycleTrends,
    addGrindCycleTrend,
    clearGrindCycleTrends,
    setSharedCycleTrends,
    addSharedCycleTrend,
    clearSharedCycleTrends,
    setDigitalOutput,
    setContainsBurninTest,
    setShowCycleSelection,
    setShowResults,
    setShowBit,
    setToDate,
    setFromDate,
    resetMetricState,
} = metricSlice.actions;

export default metricSlice.reducer;
