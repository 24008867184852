/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../../SimpleLoader";
import * as Sentry from "@sentry/browser";
import AuthService from "../../AuthService";
const Auth = new AuthService();

class UnretireDeviceModal extends React.Component{
  state = {
    modalLoading : false,
    allowModalToBeClosed: true,
  };
  componentDidMount(){
    this.setState({
      unretireDeviceID : this.props.modalDeviceID,
      unretireDeviceSerial : this.props.modalSerial,
    })
  }
  retireDevice = () => {
    const {
      unretireDeviceID,
      unretireDeviceSerial
    } = this.state;

    this.setState({
      modalLoading : true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/device/${unretireDeviceID}/`, {
      method : 'DELETE',
    }).then(data => {
      this.props.preparePage(this.props.prepareParameter).then(() => {
        this.setState({
          modalLoading: false,
          allowModalToBeClosed: false
        }, () => {
          this.props.triggerUnretireDeviceModal(null);
          this.props.notifySuccess("reactivated" , "device", unretireDeviceSerial);
        });
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.props.notifyFailure();
      this.props.triggerUnretireDeviceModal(null);
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: false
      });
    });


  };
  render() {
    const {
      t
    } = this.props;
    const {
      unretireDeviceSerial,
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.unretireDeviceModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='small'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerUnretireDeviceModal(null);
          }}
        >
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2 className='green'>{t('Reactivate')} {unretireDeviceSerial}</h2>
          </Header>
          <Modal.Content>
            <div className='short-modal-text center-text'>
              <p>Are you sure you want to reactivate <strong>{this.props.modalSerial}</strong>?</p>
              <p>The device will be able to run cycles and communicate with the portal again.</p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='green-btn'
                    value="Submit"
                    type="submit"
                    id='retireDeviceBtn'
                    onClick={this.retireDevice}
                    >{t('Reactivate Device')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(UnretireDeviceModal);
