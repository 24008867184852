/*
*  Modal for generating Customer-wide Compliance and Comprehensive reports
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate, Trans} from "react-i18next";
import {Button, Dropdown, Header, Icon, Modal, Popup, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../SimpleLoader";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import AuthService from "../AuthService";
import {generateCyclePDFData} from '../library/helpers'
import {cycleCSV, cycleReport} from "../dashboard/CycleReport";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class CustomerCycleReportModal extends React.Component {
  state = {
    currentPressureUnit: 'PSI(g)',
    allowModalToBeClosed: true,
    modalLoading: false,
    selectedCustomerID: null,
    complianceReport: false, // comprehensive report hardcoded
    customerOptions: [],
    customerWeightUnitOptions: [],
    fileType: 'pdf',
    weightUnitPref: 'lb',
    prettyCustomerName: null,
  };

  notifyNoCycles = (customerName) => toast(`${customerName} has not ran any cycles during the requested time period.`, {
    type: toast.TYPE.DEFAULT,
    autoClose: 10000
  });

  notifyFailure = () => toast(`Operation failed. Please refresh this page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });


  componentDidMount() {
    this.setState({
      modalLoading: true
    }, () => (this.props.isCurrentUserSterilisOrDist ? this.fetchCustomers() : this.fetchCustomersCustomer()).then(
      () => this.fetchCustomerOptions().then(
        () => this.setState({
          modalLoading: false,
          fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
          toDate: moment().format("YYYY-MM-DD"),
          selectedCustomerID: this.props.customerCycleReportCustomerID
        }))));
  }

  fetchCustomerOptions = () => {
    return Auth.fetch(`/api/customer/`, {
      method: 'OPTIONS',
    }).then(data => {
      const customerWeightUnitOptions = data['customer_metadata']['weight_unit_preference']['choices'].map(choice => {
        return {
          key: choice['value'],
          value: choice['value'],
          text: choice['display_name']
        }
      });
      this.setState({
        customerWeightUnitOptions,
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.props.notifyFailure();
      this.setState({
        customerWeightUnitOptions: [],
      });
    });
  };

  fetchCustomersCustomer = () => {
    return Auth.fetch(`/api/user-info/`)
      .then(data => {
        this.setState({
          selectedCustomerID: data['customer_id']
        })
      }).catch(err => {
        this.notifyFailure();
        Sentry.captureException(err);
      });
  };

  fetchCustomers = () => {
    return Auth.fetch(`/api/customer/?show_deleted=true`)
      .then(data => {
        const customerOptions = data.map((customer) => {
          return {
            key: customer.id,
            value: customer.id,
            text: customer.customer_name,
            className: this.customerDropdownClass(customer),
          }
        });

        customerOptions.sort((a, b) => {
          const tempAText = a.text.toUpperCase();
          const tempBText = b.text.toUpperCase();
          if (tempAText < tempBText) {
            return -1;
          }
          if (tempAText > tempBText) {
            return 1;
          }
          // names must be equal
          return 0;
        });
        this.setState({
          customerOptions
        })
      }).catch(err => {
        this.notifyFailure();
        Sentry.captureException(err);
      });
  };

  customerDropdownClass = customer => {
    if (customer.deleted) {
      return 'light-grey-tr'
    } else if (customer.banned) {
      return 'light-red-tr'
    }
  };

  selectPressureUnit = (e, data) => {
    this.setState({
      currentPressureUnit: data.value
    })
  };

  selectCustomer = (e, data) => {
    this.setState({
      selectedCustomerID: data.value,
      customerValidationError: false,
      prettyCustomerName: e.currentTarget.textContent,
    })
  };

  selectFileType = (e, data) => {
    this.setState({
      fileType: data.value
    })
  };

  selectReportType = (e, data) => {
    this.setState({
      complianceReport: data.value
    })
  };


  generateCycleReport = (e) => {
    e.preventDefault();
    const {fromDate, toDate, currentPressureUnit, complianceReport, selectedCustomerID, fileType} = this.state;
    const {t} = this.props;
    // required attribute doesn't work for semantic ui dropdown menus and they have no plans to fix it :)
    // so we need to manually check that the customerID was selected and manually provoke html5 form validation
    if (selectedCustomerID === undefined) {
      this.setState({
        customerValidationError: true
      });
      return;
    }

    this.setState({
      modalLoading: true,
    });

    Auth.fetch(`/api/get-customer-cycles?` +
        new URLSearchParams({
          customer_id: selectedCustomerID,
          // Add/Subtract 1 day so that the date range is inclusive
          from_date: moment.utc(fromDate).subtract("1", "days").format(),
          to_date: moment.utc(toDate).add("1", "days").format(),
          compliance_report: complianceReport,
        }),
      {
          method: "GET",
      }
    ).then((data) => {
        if (data["device_cycles"].length <= 0) {
          const { prettyCustomerName } = this.state;
          this.setState(
            {
              modalLoading: false,
            },
            () => {
              this.notifyNoCycles(
                prettyCustomerName ? prettyCustomerName : "Customer"
              );
            }
          );
          return;
        }
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const customerName = data["device_metadata"]["customer_name"];

        //  Facility_type will be equal to 'airport' if it's an airport facility
        const isAirport =
          data["device_metadata"]["facility_type"] === "airport";

        // Comprehensive/Compliance report booleans will always be the opposite of one another
        const comprehensiveReport = !complianceReport;

        const deviceSerial = data["device_metadata"]["device_serial"];

        const cycleRows = generateCyclePDFData(
          data["device_cycles"],
          userTimezone,
          t,
          { weight: this.state.weightUnitPref }
        );

        if (fileType === "pdf") {
          cycleReport(
            customerName,
            isAirport,
            comprehensiveReport,
            deviceSerial,
            currentPressureUnit,
            cycleRows,
            true
          );
        } else if (fileType === "csv") {
          cycleCSV(
            cycleRows,
            currentPressureUnit,
            comprehensiveReport,
            customerName,
            true
          );
        }

        this.setState(
          {
            appLoading: false,
            allowModalToBeClosed: true,
          },
          () => this.props.triggerCustomerCycleReportModal(false)
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
        this.notifyFailure();
        this.setState({
          modalLoading: false,
        });
      });
  };

  fromDateClick = timestamp => {
    this.setState({
      fromDate: moment(timestamp).format("YYYY-MM-DD")
    });
  };

  toDateClick = timestamp => {
    this.setState({
      toDate: moment(timestamp).format("YYYY-MM-DD")
    });
  };

  render() {
    const {
      modalLoading,
      fromDate,
      toDate,
      customerOptions,
    } = this.state;
    const {
      t
    } = this.props;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const pressureOptions = [
      {
        text: 'PSI(g)',
        value: 'PSI(g)'
      },
      {
        text: 'PSI(a)',
        value: 'PSI(a)'
      }
    ];
    const fileTypeOptions = [
      {
        text: 'PDF',
        value: 'pdf'
      },
      {
        text: 'CSV',
        value: 'csv'
      }
    ];

    // These are values are technically complianceReport yes or no
    const reportOptions = [
      {
        text: 'Comprehensive report',
        value: false
      },
      {
        text: 'Compliance Report',
        value: true
      }
    ];


    const explanationPopup = <Popup
      content={
        <div>
          <p>
            <Trans ns='translations' i18nKey='customer-cycle-report-help.1'>
              The Customer Cycle Report is similar to the device Comprehensive and Compliance reports except it's
              customer-wide and it's guaranteed to contain only the requested customer's data.
            </Trans>
          </p>
          <p>
            <Trans ns='translations' i18nKey='customer-cycle-report-help.2'>
              In the event we stop doing business with a customer, this tool is extremely useful because it allows
              Sterilis
              employees to generate the reports that our Customers need in order to remain OSHA compliant.
            </Trans>
          </p>
        </div>
      }
      on='click'
      position='bottom right'
      trigger={<Icon className='float-right' name='question circle outline'/>}
    />;

    return (
      <TransitionablePortal
        open={this.props.showCustomerCycleReportModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='small'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn ster-grey-ui'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerCustomerCycleReportModal(false)
          }}
        >
          <Header>
            <h2>{t(`Generate ${this.state.complianceReport ? 'Compliance' : 'Comprehensive'} Cycle Report`)} {explanationPopup}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content className="cycle-report-modal-content">
            <form
              id='customerCycleReportForm'
              onSubmit={this.generateCycleReport}
            >
              <div className="customer-cycle-report-container">
                <div className="flex-dir-row even-split medium-padding-bottom">
                  <div className="nav-group medium-width">
                    <label className="date-label">{t("Start date")}</label>
                    <DayPickerInput onDayChange={day => this.fromDateClick(day)} value={fromDate}/>
                  </div>
                  <div className="nav-group medium-width">
                    <label className="date-label">{t("End date")}</label>
                    <DayPickerInput
                      onDayChange={day => this.toDateClick(day)}
                      value={toDate}/>
                  </div>
                </div>
                <div className="flex-dir-row even-split medium-padding-bottom">
                  <div className="nav-group medium-width">
                    <label className="date-label">{t("Pressure Unit")}</label>
                    <Dropdown
                      value={this.state.currentPressureUnit}
                      onChange={this.selectPressureUnit}
                      selection
                      fluid
                      required
                      // className='psi-dropdown'
                      options={pressureOptions}
                    />
                  </div>
                  {this.props.isCurrentUserSterilisOrDist &&
                  <div className="nav-group medium-width">
                    <label
                      className={`${this.state.customerValidationError ? 'float-label-invalid' : ''} date-label`}>{t("Customer")}</label>
                    <Dropdown
                      value={this.state.selectedCustomerID}
                      onChange={this.selectCustomer}
                      selection
                      fluid
                      search
                      // className='psi-dropdown'
                      className={`${this.state.customerValidationError ? 'float-input-invalid' : ''}`}
                      options={customerOptions}
                    />
                  </div>
                  }
                </div>
                <div className="flex-dir-row even-split medium-padding-bottom">
                  <div className="nav-group medium-width">
                    <label className="date-label">{t("File Type")}</label>
                    <Dropdown
                      value={this.state.fileType}
                      onChange={this.selectFileType}
                      selection
                      fluid
                      required
                      options={fileTypeOptions}
                    />
                  </div>
                  <div className="nav-group medium-width">
                    <label className="date-label">{t("Report Type")}</label>
                    <Dropdown
                      value={this.state.complianceReport}
                      onChange={this.selectReportType}
                      selection
                      fluid
                      required
                      options={reportOptions}
                    />
                  </div>
                </div>
                <div className="flex-dir-row even-split medium-padding-bottom">
                  <div className="nav-group medium-width">
                    <label className="date-label">{t("Weight Unit Preference")}</label>
                    <Dropdown
                      value={this.state.weightUnitPref}
                      onChange={(event, data) => {
                        this.setState({
                          weightUnitPref: data.value
                        });
                      }}
                      selection
                      fluid
                      required
                      options={this.state.customerWeightUnitOptions}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="ster-btn"
              type="submit"
              id="cycleReportPDFBtn"
              form='customerCycleReportForm'
              disabled={this.state.customerValidationError}
            >
              {this.state.fileType === 'pdf' ? t("Generate PDF Report") : t("Generate CSV Report")}
            </Button>
            <div id='downloadDiv' className="download-div"/>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(CustomerCycleReportModal);
