/*
*  Modal for suspending a Facility
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import SimpleLoader from "../../../SimpleLoader";
import AuthService from "../../../AuthService";

const Auth = new AuthService();

class UnbanFacilityModal extends React.Component {
  state = {
    modalLoading: false,
    allowModalToBeClosed: true,
  };
  unbanFacility = () => {
    const {
      facility
    } = this.props;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    const facilityID = facility['id'];

    Auth.fetch(`/api/facility/${facilityID}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        banned: false
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.preparePage();
        this.props.triggerUnbanFacilityModal(null);
        this.props.notifySuccess('reactivated', 'facility', data['facility_name'])
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerUnbanFacilityModal(null);
        this.props.notifyFailure()
      });
    });
  };

  render() {
    const {
      t,
      facility
    } = this.props;
    const {
      modalLoading
    } = this.state;
    const facilityName = facility['facility_name'];

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.unbanFacilityModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerUnbanFacilityModal(null)
          }}
        >
          <Header>
            <h2 className='light-green'>{t('Reactivate Facility')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            <div className='short-modal-text center-text'>
              <p>Are you sure you want to reactivate <strong>{facilityName}</strong>?</p>
              <p>Their devices will be able to communicate with the portal. </p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='green-btn'
                    value="Submit"
                    type="submit"
                    id='banFacilityBtn'
                    onClick={this.unbanFacility}
            >{t('Reactivate Facility')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(UnbanFacilityModal);
