import React from "react";
import PropTypes from "prop-types";
import { Avatar, CardContent, List, ListItem, ListItemText, Skeleton, useTheme } from "@mui/material";
import SubCard from "src/components/material/Cards/SubCard";
import { TroubleshootTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateTime, Interval } from "luxon";

const PrvInformation = (props) => {
    const theme = useTheme();
    const { t } = useTranslation("translations");
    const { selectedDevice, selectedDeviceConfig, deviceTimezone } = useSelector((store) => store.deviceInfo);
    const loading = useSelector((store) => store.deviceInfo.loading.deviceConfig);
    const lastPrv = DateTime.fromISO(selectedDeviceConfig?.device?.time_last_prv_test, {
        zone: deviceTimezone,
    });
    const lastPrvString = lastPrv.isValid
        ? lastPrv.toLocaleString({
              ...DateTime.DATETIME_SHORT_WITH_SECONDS,
              timeZoneName: "short",
          })
        : "";

    const nextPrv = lastPrv.plus({ months: 6 });
    const nextPrvString = nextPrv.isValid
        ? nextPrv.toLocaleString({
              ...DateTime.DATETIME_SHORT_WITH_SECONDS,
              timeZoneName: "short",
          })
        : "";

    const intervalRemaining = lastPrv.isValid && nextPrv.isValid && Interval.fromDateTimes(lastPrv, nextPrv);
    const daysRemaining = intervalRemaining.isValid ? intervalRemaining.length("days") : 0;
    const backgroundColor = daysRemaining < 15 ? theme.palette.error.light : null;

    return (
        <SubCard
            title={t("PRV Test Information")}
            sx={{ width: "100%" }}
            avatar={
                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                    <TroubleshootTwoTone />
                </Avatar>
            }>
            {selectedDevice && (
                <CardContent>
                    <List>
                        {loading ? (
                            <>
                                <Skeleton width={"100%"}>
                                    <ListItem secondaryAction={lastPrvString}>
                                        <ListItemText primary={t("Last PRV Test") + ":"} />
                                    </ListItem>
                                </Skeleton>
                                <Skeleton width={"100%"}>
                                    <ListItem secondaryAction={nextPrvString} sx={{ bgcolor: backgroundColor }}>
                                        <ListItemText primary={t("Next PRV Test") + ":"} />
                                    </ListItem>
                                </Skeleton>
                            </>
                        ) : (
                            <>
                                <ListItem secondaryAction={lastPrvString}>
                                    <ListItemText primary={t("Last PRV Test") + ":"} />
                                </ListItem>
                                <ListItem secondaryAction={nextPrvString} sx={{ bgcolor: backgroundColor }}>
                                    <ListItemText primary={t("Next PRV Test") + ":"} />
                                </ListItem>
                            </>
                        )}
                    </List>
                </CardContent>
            )}
        </SubCard>
    );
};

PrvInformation.propTypes = {};

export default PrvInformation;
