/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import PropTypes from 'prop-types';
import HookTable from "../library/HookTable";

const AbnormalValuesTable = props => {
  const abnormalValueArray = props.abnormalValues;
  const abnormalValuesTableArray = props.containsBurninTest ?
    abnormalValueArray.filter(val => val.burnin_test !== 0) :  // get all burnin tests aka burnin test != 0
    abnormalValueArray.filter(val => val.burnin_test === 0); // get all non burnin tests aka burnin test == 0

  const columns = [
    {
      Header: 'Value name',
      accessor: 'display_name',
      id: 'display_name',
    },
    {
      Header: 'Minimum',
      accessor: 'minimum_value',
      id: 'minimum_value',
    },
    {
      Header: 'Maximum',
      accessor: 'maximum_value',
      id: 'maximum_value',
    },
    ...props.containsBurninTest ? [{
      Header: 'Test #',
      accessor: 'burnin_test',
      id: 'burnin_test',
    }] : [],
    ...props.containsBurninTest ? [{
      Header: 'Score Coefficient',
      accessor: 'score_coefficient',
      id: 'score_coefficient',
    }] : [],

  ];

  return <HookTable
    id='availableEthelFilesTable'
    data={abnormalValuesTableArray}
    columns={columns}
    showSearchInHeader={true}
    showPaginationInFooter={true}
    showPaginationInHeader={false}
    // showPaginationSelect={false}
    defaultPageSize={10}
    tableName='abnormalValueTable'
    defaultSortBy={props.containsBurninTest ? {id: 'burnin_test'} : {id: 'display_name'}}
  />
};

AbnormalValuesTable.defaultProps = {
  abnormalValues: [],
  containsBurninTest: false,
};

AbnormalValuesTable.propTypes = {
  abnormalValues: PropTypes.array,
  containsBurninTest: PropTypes.bool,
};

export default AbnormalValuesTable;
