/*
 * React router file, handles all front end routing
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */

import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import App from "./App";
import NotFound from "./NotFound";
import ActivateAccount from "./ActivateAccount";
import PortalStatus from "./portal_status/PortalStatus";
import CustomerApp from "./CustomerApp";
import CreateAMachine from "./create/CreateAMachine";
import ViewDevices from "./view/devices/ViewDevices";
import ViewCustomers from "./view/customers/ViewCustomers";
import ViewUsers from "./view/users/ViewUsers";
import ViewFirmware from "./view/firmware/ViewFirmware";
import ViewRegionSettings from "./view/region_settings/ViewRegionSettings";
import ViewDistributors from "src/features/Distributor/View/ViewDistributors";
import ExportDevicesByConfig from "./export/by_config/ExportDevicesByConfig";
import ExportDevicesBySpecificErrors from "./export/specific_errors/ExportDevicesBySpecificErrors";
import ServiceSummary from "./export/service_summary/ServiceSummary";
import AssignFirmwareToDevices from "./assign/firmware_to_device/AssignFirmwareToDevices";
import AssignDeviceToFacility from "./assign/device_to_facility/AssignDeviceToFacility";
import ForgotPassword from "./ForgotPassword";
import UserSettingsWrapper from "./user_settings/UserSettingsWrapper";
import ReleaseNotesWrapper from "./release_notes/ReleaseNotesWrapper";
import UploadFirmware from "./upload/UploadFirmware";
import UploadDocumentContainer from "./document_upload/UploadDocumentContainer";
import RecoverAccount from "./RecoverAccount";
import TermsAndConditions from "./terms_and_conditions/TermsAndConditions";
import LegalNotice from "./legal/LegalNotice";
import ErrorBoundary from "./ErrorBoundary";
import DownloadFileRequest from "./DownloadFileRequest";
import DownloadArchivedFileRequest from "./DownloadArchivedFileRequest";
import ErrorPage from "./ErrorPage";
import Login from "./Login";
import DashboardWrapper from "./dashboard/DashboardWrapper";
import UploadSDImage from "./upload/UploadSDImage";
import {
    CreateDistributorWrapper,
    CreateCustomerWrapper,
    CreateFacilityWrapper,
} from "./create/DistributorCustomerFacility/Wrappers";
import LogParserHomepage from "./log_parser/LogParserHomepage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "./library/UserContext";
import { createBrowserHistory } from "history";
import ViewSDImages from "./view/sd_image/ViewSDImages";
import SummaryDashboardContainer from "./summary_dashboard/SummaryDashboardContainer";
import DeviceHealthDashboard from "./device_health/DeviceHealthDashboard";
import CycleMetricHomePage from "../features/CycleMetric/CycleMetricHomePage";
import MetricResults from "../features/CycleMetric/Results";
import Navigation from "./material/Navigation";
import DeviceDashboard from "src/features/Device/DeviceDashboard/DeviceDashboard";
import CreateUser from "src/features/User/Create/CreateUser";
import DeviceInformation from "src/features/Device/DeviceInformation/DeviceInformation";
import MaterialSnackBarProvider from "src/context/MaterialSnackBarProvider";
const history = createBrowserHistory();

function SterRouter() {
    return (
        <ErrorBoundary>
            <Router history={history}>
                <Switch>
                    <UserContext.Provider value={{}}>
                        <div className="app-container">
                            <ToastContainer />
                            <MaterialSnackBarProvider>
                                <Navigation>
                                    <Switch>
                                        <Route exact path="/activate/:activation_key" component={ActivateAccount} />
                                        <Route exact path="/login" component={Login} />
                                        <Redirect from="/auth/login" to="/login" />
                                        <Route exact path="/forgot-password" component={ForgotPassword} />
                                        <Route
                                            exact
                                            path="/recover-account/:activation_key"
                                            component={RecoverAccount}
                                        />
                                        <Route exact path="/sa" component={CustomerApp} />
                                        <Redirect from="/sa/admin" to="/sa" />
                                        <Route
                                            exact
                                            path="/sa/dashboard/legacy/:device_id"
                                            component={DashboardWrapper}
                                        />
                                        <Route
                                            exact
                                            path="/sa/summary-dashboard/:customer_id"
                                            component={SummaryDashboardContainer}
                                        />
                                        <Route
                                            exact
                                            path="/sa/summary-dashboard/:customer_id/:facility_id"
                                            component={SummaryDashboardContainer}
                                        />
                                        <Route exact path="/sa/dashboard/:device_id" component={DeviceDashboard} />

                                        {/*Internal pages*/}
                                        <Route exact path="/" render={() => <App />} />
                                        <Route exact path="/find-a-machine" component={DeviceInformation} />
                                        <Route exact path="/find-a-machine/:device_id" component={DeviceInformation} />
                                        <Route exact path="/files/:file_request_id" component={DownloadFileRequest} />
                                        <Route
                                            exact
                                            path="/archived-files/:file_request_id"
                                            component={DownloadArchivedFileRequest}
                                        />
                                        <Route path="/create/device" component={CreateAMachine} />
                                        {/* <Route path="/create/v2/user" component={CreateDistributorUserWrapper} /> */}
                                        <Route path="/create/v2/user" component={CreateUser} />

                                        <Route path="/create/distributor" component={CreateDistributorWrapper} />
                                        <Route path="/create/v2/customer" component={CreateCustomerWrapper} />
                                        <Route path="/create/v2/facility" component={CreateFacilityWrapper} />

                                        <Route path="/view/devices" component={ViewDevices} />
                                        <Route path="/view/customers" component={ViewCustomers} />
                                        <Route path="/view/users" component={ViewUsers} />
                                        <Route path="/view/regions" component={ViewRegionSettings} />
                                        <Route path="/view/software" component={ViewFirmware} />
                                        <Route path="/view/sd-image" component={ViewSDImages} />
                                        <Route path="/view/distributors" component={ViewDistributors} />
                                        <Route path="/log-parser" component={LogParserHomepage} />

                                        <Route path="/cycle-metrics/:metadata_id" component={CycleMetricHomePage} />
                                        <Route path="/cycle-metrics" component={CycleMetricHomePage} />
                                        <Route path="/results" component={MetricResults} />

                                        <Route path="/export/by-config" component={ExportDevicesByConfig} />
                                        <Route
                                            path="/export/specific-errors"
                                            component={ExportDevicesBySpecificErrors}
                                        />
                                        <Route path="/export/service-summary" component={ServiceSummary} />

                                        <Route path="/assign/software-to-devices" component={AssignFirmwareToDevices} />
                                        <Route path="/assign/device-to-facility" component={AssignDeviceToFacility} />

                                        <Route path="/upload/document" component={UploadDocumentContainer} />
                                        <Route path="/upload/software" component={UploadFirmware} />
                                        <Route path="/upload/sd-image" component={UploadSDImage} />
                                        <Route path="/portal-status" component={PortalStatus} />

                                        <Route path="/user-settings" component={UserSettingsWrapper} />
                                        <Route path="/release-notes" component={ReleaseNotesWrapper} />

                                        <Route path="/terms" component={TermsAndConditions} />
                                        <Route path="/legal" component={LegalNotice} />

                                        <Route path="/err" component={ErrorPage} />
                                        <Route path="/view/devicehealth" component={DeviceHealthDashboard} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </Navigation>
                            </MaterialSnackBarProvider>
                        </div>
                    </UserContext.Provider>
                </Switch>
            </Router>
        </ErrorBoundary>
    );
}

export default SterRouter;
