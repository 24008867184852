/*
*  View firmware table
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import {composeHoc, AuthReq} from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import { withRouter } from "react-router-dom";
import FirmwareTable from "./FirmwareTable";
import EditFirmwareModal from "./EditFirmwareModal";
import DeleteFirmwareModal from "./DeleteFirmwareModal";
import {Button, Card, Dropdown, Popup} from "semantic-ui-react";
import moment from "moment";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class ViewFirmware extends React.Component {
  state = {
    isLoading: false,
    firmware: null,
    firmwareTableArray: [],
  };
  notifySuccess = (action, firmwareVersion) =>
    toast(`Successfully ${action} firmware ${firmwareVersion}`, {type: toast.TYPE.DEFAULT, autoClose: 5000});


  notifyFailure = () =>
    toast(`Failed to complete the operation. Please refresh the page and try again.`, {
      type: toast.TYPE.ERROR,
      autoClose: 5000
    });

  componentDidMount() {
    document.title = 'View Software';
    this.preparePage();
  }

  preparePage = () => {
    this.setState({
      isLoading: true
    });
    Auth.fetch(`/api/firmware?show_deleted=true`)
      .then(data => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const firmwareTableArray = data.map(firmware => {

          let creationEpoch = <p>File not on s3 - migration problem?</p>;
          // https://stackoverflow.com/a/8376542
          if (firmware['s3_location']) {
            const indexOfEpoch = firmware['s3_location'].lastIndexOf('/');
            const creationTime = firmware['s3_location']
              .substring(indexOfEpoch + 1)
              .replace(/\.[^/.]+$/, ""); //remove the .tgz file extension

            const prettyDate = moment.utc(creationTime * 1000).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z");
            creationEpoch = <p data-search={creationTime} title={prettyDate}>{creationTime}</p>
          }
          return {
            'firmware_version': firmware['firmware_version'],
            'linux_version': firmware['linux_version'],
            'creation_date': creationEpoch,
            'file_name': 'filename',
            'checksum': firmware['checksum'],
            'description': firmware['description'],
            'uploaded_timestamp': moment.utc(firmware['uploaded_timestamp']).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z"),
            'upload_user': firmware['upload_user']['employee']['full_name'],
            'deleted_status': firmware['deleted'],
            'preferred': firmware['preferred'],
            'actions': this.firmwareActions(firmware)
          }
        });

        this.setState({
          firmwareTableArray,
          isLoading: false,
        })
      }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        isLoading: false,
      })
    });
  };

  firmwareActions = (firmware) => {
    const {
      t,
      group
    } = this.props;
    return (
      <Dropdown
                id={firmware['id']}
                pointing='top right'
                icon='ellipsis horizontal'>
        <Dropdown.Menu>
          <Dropdown.Header content={<h4>{t('Software Actions')}</h4>}/>
          <Dropdown.Divider/>
          <AuthReq
            userGroup={group}
            requiredRoles={['SterilisSuperUsers','SterilisWasteTypeAdmin', 'SterilisPortalUsers']}
          >
            {
              firmware['deleted'] === null &&
              <Dropdown.Item
                id={'prefer-' + firmware['id']}
                onClick={() => this.flagAsPreferredFirmware(firmware)}
                text={
                  <h5>{firmware['preferred'] ? t('Remove preferred software status') : t('Set as preferred software')}</h5>}
              />
            }
            {
              firmware['deleted'] === null &&
              <Dropdown.Item
                id={'edit-' + firmware['id']}
                onClick={() => this.triggerEditFirmwareModal(firmware)}
                text={<h5>{t('Edit software')}</h5>}
              />
            }
            <Dropdown.Item
              id={'delete-' + firmware['id']}
              onClick={() => this.triggerDeleteFirmwareModal(firmware)}
              text={
                <h5>
                  {`${firmware['deleted'] ? t('Reactivate') : t('Archive')} ${t('software')}`}
                </h5>
              }
            />
          </AuthReq>
          {
            firmware['deleted'] === null &&
            <Dropdown.Item
              id={'assign-' + firmware['id']}
              onClick={() => this.props.history.push(`/assign/software-to-devices?firmware=${firmware['id']}`)}
              text={<h5>{t('Assign software to device(s)')}</h5>}
            />
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  };

  triggerEditFirmwareModal = (firmware) => {
    this.setState((prevState) => {
      return {
        editFirmwareModal: !prevState.editFirmwareModal,
        firmware
      };
    });
  };

  triggerDeleteFirmwareModal = (firmware) => {
    this.setState((prevState) => {
      return {
        deleteFirmwareModal: !prevState.deleteFirmwareModal,
        firmware
      };
    });
  };

  flagAsPreferredFirmware = firmware => {
    const newPreferredValue = firmware['preferred'] ? 0 : 1;

    this.setState({
      isLoading: true
    });


    Auth.fetch(`/api/firmware/${firmware['id']}`, {
      method: 'PATCH',
      body: JSON.stringify({
        'preferred': newPreferredValue
      })
    }).then(data => {
      newPreferredValue === 1 ?
        toast(`Set ${data['firmware_version']} as preferred software.`, {type: toast.TYPE.DEFAULT, autoClose: 5000})
        : toast(`Removed preferred software status from ${data['firmware_version']}.`, {
          type: toast.TYPE.DEFAULT,
          autoClose: 5000
        });
      this.preparePage();
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        isLoading: false
      }, () => {
        this.notifyFailure();
      });
    });


  };

  render() {
    const {
      isLoading,
      firmwareTableArray,
      editFirmwareModal,
      deleteFirmwareModal,
      firmware
    } = this.state;
    const {t, group,} = this.props;
    return (
		<Card fluid>
			{isLoading ? <SimpleLoader /> : null}
			<Card.Header style={{ margin: "10px" }}>
				<h2>{t("View Software")}</h2>
			</Card.Header>
			<Card.Content className="card-body">
				<div className="view-firmware-header">
					<AuthReq userGroup={group} requiredRoles={["SterilisSuperUsers", "SterilisWasteTypeAdmin"]}>
						<Link to="/upload/software">{t("Upload new software")}</Link>
					</AuthReq>
					<Popup
						content={
							<div>
								<div className="small-width minor-padding light-green-tr">
									{t("Software has been designated as a preferred version")}
								</div>
								<div className="small-width minor-padding light-red-tr">
									{t("Software has been archived")}
								</div>
							</div>
						}
						on="click"
						position="bottom right"
						trigger={<Button className="grey-btn table-legend" content={t("Show Table Legend")} />}
					/>
				</div>
				<FirmwareTable
					group={this.props.group}
					className="slight-margin-top"
					firmwareTableArray={firmwareTableArray}
				/>

				{editFirmwareModal ? (
					<EditFirmwareModal
						firmware={firmware}
						preparePage={this.preparePage}
						triggerEditFirmwareModal={this.triggerEditFirmwareModal}
						editFirmwareModal={editFirmwareModal}
						notifySuccess={this.notifySuccess}
						notifyFailure={this.notifyFailure}
					/>
				) : null}

				{deleteFirmwareModal ? (
					<DeleteFirmwareModal
						firmware={firmware}
						preparePage={this.preparePage}
						triggerDeleteFirmwareModal={this.triggerDeleteFirmwareModal}
						deleteFirmwareModal={deleteFirmwareModal}
						notifySuccess={this.notifySuccess}
						notifyFailure={this.notifyFailure}
					/>
				) : null}
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(withRouter, translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
    'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin'], 'internalPage'))(ViewFirmware);
