/*
*  Component responsible for Editing a Deice Operator
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import '../css/EditADeviceOperator.css';
import SimpleLoader from './SimpleLoader';
import {Button, Header, Modal, Dropdown, TransitionablePortal} from 'semantic-ui-react'
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";
import DeviceOperatorDescText from "./customer_portal/DeviceOperatorDescText";
import {AuthReq} from "./library/helpers";

const Auth = new AuthService();

class EditADeviceOperator extends React.Component {
  state = {
    pinValidationError: false,
    usedPinValidationError: false,
    usedPin: '',
    notificationLevel: 0,
    fullName: '',
    userPIN: '',
    emailAddr: '',
    phoneNumber: '',
    employeeID: null,
    deviceOperatorID: null,
    allowModalToBeClosed: true,
    facilityDropdown: []
  };

  componentDidMount() {

    const customerFacilityObj = this.props.simpleCustomerFacilityObj;
    const customerID = this.props.operatorBeingEdited['employee']['customer']['id'];

    const facilityDropdown = this.props.customerDropdown.filter( item => {return item.key === customerID})[0].facilities
    const FacilityID = this.props.operatorBeingEdited.employee.facilities
    // need to get a shallow, fresh copy of pins -> and then cast as a set
    const pinArr = new Set([...customerFacilityObj[customerID].pins]);

    pinArr.delete(this.props.operatorBeingEdited['pin']); // delete pin from shallow copy of pinArr

    //figure out appropriate notifcationLevel for dropdown default value
    const notify_complete = this.props.operatorBeingEdited['notify_complete'];
    const notify_error = this.props.operatorBeingEdited['notify_error'];
    let notificationLevel = 0;
    if (notify_complete && notify_error) {
      notificationLevel = 3;
    } else if (notify_complete) {
      notificationLevel = 1;
    } else if (notify_error) {
      notificationLevel = 2;
    } else {
      notificationLevel = 0;
    }


    this.setState({
      fullName: this.props.operatorBeingEdited['employee']['full_name'],
      userPIN: this.props.operatorBeingEdited['pin'],
      emailAddr: this.props.operatorBeingEdited['employee']['email_address'],
      phoneNumber: this.props.operatorBeingEdited['employee']['phone'],
      employeeID: this.props.operatorBeingEdited['employee']['id'],
      deviceOperatorID: this.props.operatorBeingEdited['id'],
      notificationLevel: notificationLevel,
      customerID: customerID,
      pinArr: pinArr,
      facilityDropdown,
      FacilityID
    })
  }

  handleFacilityChange = (none, data) => {
    const FacilityID = data.value;

    this.setState({
      FacilityID
    })

  };


  handleNotificationChange = (event, data) => {
    this.setState({
      notificationLevel: data.value
    });
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    const pinCharacterLimit = this.state.customerID === 3 ? 8 : 7;
    if (name === 'userPIN') {
      const customerID = this.state.customerID;
      if (customerID) {
        const pinArr = this.state.pinArr; //get a shallow copy of the pinArr, and then make it a set again

        // pinArr is a JavaScript Set, which does not have the Array find function
        //we need to [...spread] pinArr into an array, so we can use the find function
        const usedPin = [...pinArr].find((pins) => {
          return pins === value;
        });

        if (usedPin) {
          this.setState({
            usedPin: usedPin,
            usedPinValidationError: true
          })
        } else {
          this.setState({
            usedPin: null,
            usedPinValidationError: false
          })
        }

      }

      if (value.length > pinCharacterLimit || value.length < 4) {
        this.setState({
          pinValidationError: true
        })
      } else {
        //We only want to un-validate the pinvalidation state if we know there is no usedPin validation error going on
        if (!this.state.usedPin) {
          this.setState({
            pinValidationError: false
          })
        }
      }

      // if the value is not not a number, it is a number
      // goofy, but it's the only 100% way to check if something is a number in js
      // pins must only be numbers
      // need to use this over type="number" because of floating labels & controlled inputs
      if (!isNaN(value)) {
        this.setState({[name]: value});
      }

    } else {
      this.setState({[name]: value});
    }
  };


  editDeviceOperator = (e) => {
    e.preventDefault();
    let putObjEmployee
    const {
      notificationLevel,
      fullName,
      userPIN,
      emailAddr,
      phoneNumber,
      employeeID,
      deviceOperatorID,
      FacilityID
    } = this.state;

    const {
      group
    } = this.props

    if(group === "FacilityAdmin"){
      putObjEmployee = {
        'full_name': fullName,
        'email_address': emailAddr,
        'phone': phoneNumber
      };

    }
    else{
      putObjEmployee = {
        'full_name': fullName,
        'email_address': emailAddr,
        'phone': phoneNumber,
        'facility_ids': FacilityID
      };
    }

    let notifyComplete = false;
    let notifyError = false;
    switch (notificationLevel) {
      case 0:
        notifyComplete = false;
        notifyError = false;
        break;
      case 1:
        notifyComplete = true;
        notifyError = false;
        break;
      case 2:
        notifyComplete = false;
        notifyError = true;
        break;
      case 3:
        notifyComplete = true;
        notifyError = true;
        break;
      default:
        notifyComplete = false;
        notifyError = false;
        break;
    }
    const putObjDeviceOperator = {
      'notify_complete': notifyComplete,
      'notify_error': notifyError,
      'pin': userPIN,
      'employee_id': employeeID
    };

    this.setState({
      appLoading: true,
      allowModalToBeClosed: false
    });
    Auth.fetch(`/api/employee/${employeeID}/`, {
      method: 'PATCH',
      body: JSON.stringify(putObjEmployee)
    }).then(data => {
      const modifiedEmployeeName = data.full_name;

      Auth.fetch(`/api/device-operator/${deviceOperatorID}/`, {
        method: 'PATCH',
        body: JSON.stringify(putObjDeviceOperator)
      }).then(() => {

        this.props.fetchCustomerMachines().then(() => {
          this.setState({
            appLoading: false,
            allowModalToBeClosed: true
          });
          this.props.editOperator(null); // close modal, set operator being edited to null
          this.props.fetchDeviceOperators(); //fetch new data for table
          this.props.fetchSiteAdmins();
          this.props.notifyEditSuccess(modifiedEmployeeName); // notify user of success w/ toast
        });

      }).catch(e => {
        Sentry.captureException(e);
        this.props.notifyEditFailure();
        this.setState({
          appLoading: false,
          allowModalToBeClosed: true
        });
      });
    }).catch(e => {
      Sentry.captureException(e);
      this.props.notifyEditFailure();
      this.setState({
        appLoading: false,
        allowModalToBeClosed: true
      });
    });

  };

  render() {
    const {
      pinValidationError,
      usedPinValidationError,
      usedPin,
      notificationLevel,
      appLoading,
      facilityDropdown,
      FacilityID
    } = this.state;

    const {
      t,
      group
    } = this.props;

    // const topMargin = this.state.height < 1000 ? '3vh' : '10vh';
    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const notificationDropdown = [
      {
        key: 0,
        value: 0,
        id: '0',
        text: t('Do not notify')
      },
      {
        key: 1,
        value: 1,
        id: '1',
        text: t('Notify on Completion')
      },
      {
        key: 2,
        value: 2,
        id: '2',
        text: t('Notify on Error')
      },
      {
        key: 3,
        value: 3,
        id: '3',
        text: t('Notify on Completion and Error')
      },

    ];

    return (
      <TransitionablePortal
        open={this.props.editOperatorModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.editOperator(null) //pass null to both edit ids when closing modal
          }}
        >
          {appLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2>{t('Edit Device Operator')}</h2>
          </Header>
          <Modal.Content className='edit-a-device-operator'>
            <form id='editADeviceOperatorForm'
                  onSubmit={this.editDeviceOperator}
                  className="create-a-device-operator-form">
              <div className='split-container'>
                <DeviceOperatorDescText
                  pinValidationError={pinValidationError}
                  usedPinValidationError={usedPinValidationError}
                  usedPin={usedPin}
                />
                <div className='items'>
                <AuthReq
                    userGroup={group}
                    requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs',
                      'FactoryWorkers', 'DistributorAdmins','SterilisWasteTypeAdmin', 'CustomerPortalUsers']}
                  >
                    <div className="form-group">
                      <label className="fake-float">{t('Facility')}</label>
                      <Dropdown
                        className='full-width'
                        search
                        fluid 
                        multiple selection
                        id='editDeviceOpSelectFacility'
                        options={facilityDropdown}
                        value={FacilityID}
                        onChange={this.handleFacilityChange}

                      />
                    </div>
                  </AuthReq>
                  <div className="form-group">
                    <input value={this.state.fullName}
                           name='fullName'
                           onChange={this.handleChange}
                           type="text"
                           id="fullNameInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="fullNameInput">{t('Full Name')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.userPIN}
                           name='userPIN'
                           onChange={this.handleChange}
                           type="text"
                           id="userPINInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="userPINInput">{t('User PIN')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.emailAddr}
                           name='emailAddr'
                           onChange={this.handleChange}
                           type="text"
                           id="emailAddressInput"
                           className="form-control"
                           required/>
                    <label className="form-control-placeholder"
                           htmlFor="emailAddressInput">{t('Email Address')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.phoneNumber}
                           name='phoneNumber'
                           onChange={this.handleChange}
                           type="text"
                           id="phoneNumberInput"
                           className="form-control" required/>
                    <label className="form-control-placeholder"
                           htmlFor="phoneNumberInput">{t('Phone Number')}</label>
                  </div>

                  <div className="form-group">
                    <label className="fake-float">{t('Notification Settings')}</label>
                    <Dropdown
                      className='full-width'
                      search
                      selection
                      id='notificationSettingsDropdown'
                      options={notificationDropdown}
                      value={notificationLevel}
                      onChange={this.handleNotificationChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='editADeviceOperatorForm'
                    id='editADeviceOperatorBtn'
              // disabled={pinValidationError || usedPinValidationError || !customerID}
                    disabled={pinValidationError || usedPinValidationError}
            >{t('Submit changes')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(EditADeviceOperator);
