// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import SubCard from "src/components/material/Cards/SubCard";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { isNotEmptyObject, dateTimeFilterRegex, LOCAL_DATETIME_FORMAT } from "src/components/library/helpers";
import { DateTime } from "luxon";

const DeviceErrorLog = (props) => {
    const { t } = useTranslation("translations");
    const { hardwareErrorLogs, selectedDeviceConfig, deviceTimezone } = useSelector((store) => store.deviceInfo);
    const loading = useSelector((store) => store.deviceInfo.loading.logs);
    const theme = useTheme();

    const Toolbar = (props) => {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    const columns = [
        {
            headerName: t("Error Time"),
            type: "dateTime",
            field: "error_time",
            headerAlign: "center",
            headerClassName: "device-errors-table--header",
            align: "center",
            flex: 1,
            valueGetter: ({ value }) => {
                return value && DateTime.fromISO(value, { zone: deviceTimezone }).toJSDate();
            },
            valueFormatter: ({ value }) =>
                DateTime.fromJSDate(value, { zone: deviceTimezone }).toLocaleString(LOCAL_DATETIME_FORMAT),
            getApplyQuickFilterFn: (value) => {
                if (!value || !dateTimeFilterRegex.test(value)) {
                    return null;
                }
                return (params) => {
                    if (params.value instanceof Date) {
                        return params.value.toLocaleString().includes(value);
                    }
                };
            },
        },
        {
            headerName: t("Software Version"),
            field: "firmware",
            headerAlign: "center",
            align: "center",
            headerClassName: "device-errors-table--header",
            flex: 1,
        },
        {
            headerName: t("Machine State"),
            field: "machine_state",
            headerAlign: "center",
            align: "center",
            headerClassName: "device-errors-table--header",
            flex: 1,
        },
        {
            headerName: t("Error Code"),
            field: "error_code",
            headerAlign: "center",
            align: "center",
            headerClassName: "device-errors-table--header",
            flex: 1,
        },
        {
            headerName: t("Description"),
            field: "description",
            headerAlign: "center",
            align: "center",
            headerClassName: "device-errors-table--header",
            flex: 1,
        },
    ];
    return (
        isNotEmptyObject(selectedDeviceConfig) && (
            <SubCard
                sx={{
                    width: "100%",
                    "& .device-errors-table--header": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                    },
                }}>
                <DataGrid
                    disableSelectionOnClick
                    autoHeight
                    columns={columns}
                    rows={hardwareErrorLogs}
                    loading={loading}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "error_time", sort: "desc" }],
                        },
                    }}
                    sx={{
                        fontSize: 14,
                        padding: 2,
                        borderRadius: 4,
                    }}
                    components={{ Toolbar: Toolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </SubCard>
        )
    );
};

DeviceErrorLog.propTypes = {};

export default DeviceErrorLog;
