import PropTypes from "prop-types";
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React from "react";
import { AuthReq } from "../../library/helpers";
import { useTranslation } from "react-i18next";

const SubItem = (props) => {
	const { t } = useTranslation("translations");
	if (props.option.requiredRoles) {
		return (
			<AuthReq userGroup={props.userGroup} requiredRoles={props.option.requiredRoles}>
				<Tooltip title={props.option.tooltip} placement="right" arrow>
					{props.option.clickHandler ? (
						<ListItemButton sx={{ pl: 4 }} key={props.option.id} onClick={props.option.clickHandler}>
							<ListItemIcon>{props.option.icon}</ListItemIcon>
							<ListItemText primary={t(props.option.text)} />
						</ListItemButton>
					) : props.option.path ? (
						<ListItemButton sx={{ pl: 4 }} key={props.option.id} component={Link} to={props.option.path}>
							<ListItemIcon>{props.option.icon}</ListItemIcon>
							<ListItemText primary={t(props.option.text)} />
						</ListItemButton>
					) : (
						<ListItemButton sx={{ pl: 4 }} key={props.option.id}>
							<ListItemIcon>{props.option.icon}</ListItemIcon>
							<ListItemText primary={t(props.option.text)} />
						</ListItemButton>
					)}
				</Tooltip>
			</AuthReq>
		);
	} else {
		return props.option.path ? (
			<Tooltip title={props.option.tooltip} placement="right" arrow>
				<ListItemButton sx={{ pl: 4 }} key={props.option.id} component={Link} to={props.option.path}>
					<ListItemIcon>{props.option.icon}</ListItemIcon>
					<ListItemText primary={t(props.option.text)} />
				</ListItemButton>
			</Tooltip>
		) : (
			<Tooltip title={props.option.tooltip} placement="right" arrow>
				<ListItemButton sx={{ pl: 4 }} key={props.option.id}>
					<ListItemIcon>{props.option.icon}</ListItemIcon>
					<ListItemText primary={t(props.option.text)} />
				</ListItemButton>
			</Tooltip>
		);
	}
};

const MenuItem = (props) => {
	const { t } = useTranslation("translations");
	if (props.item.requiredRoles) {
		return (
			<AuthReq
				userGroup={props.userGroup}
				requiredRoles={props.item.requiredRoles}
				component={Link}
				to={props.item.path}
			>
				{props.item.path ? (
					<Tooltip title={t(props.item.text)} placement="right" arrow>
						<ListItemButton key={props.item.id} alignItems="center" component={Link} to={props.item.path}>
							<ListItemIcon>{props.item.icon}</ListItemIcon>
							<ListItemText primary={t(props.item.text)} />
							{props.item.options ? props.item.openState ? <ExpandLess /> : <ExpandMore /> : null}
						</ListItemButton>
					</Tooltip>
				) : (
					<Tooltip title={t(props.item.text)} placement="right" arrow>
						<ListItemButton key={props.item.id} alignItems="center" onClick={props.item.clickHandler}>
							<ListItemIcon>{props.item.icon}</ListItemIcon>
							<ListItemText primary={t(props.item.text)} />
							{props.item.options ? props.item.openState ? <ExpandLess /> : <ExpandMore /> : null}
						</ListItemButton>
					</Tooltip>
				)}
				{props.item.options ? (
					<Collapse in={props.item.openState} timeout="auto" unmountOnExit>
						<List>
							{props.item.options.map((option) => {
								return (
                                    <SubItem option={option} key={`sub-${option.id}`} userGroup={props.userGroup} />
								);
							})}
						</List>
						<Divider variant="inset" />
					</Collapse>
				) : null}
			</AuthReq>
		);
	} else {
		return (
			<React.Fragment>
				{props.item.path ? (
					<Tooltip title={t(props.item.text)} placement="right" arrow>
						<ListItemButton
							onClick={props.item.clickHandler}
							key={props.item.id}
							alignItems="center"
							component={Link}
							to={props.item.path}
						>
							<ListItemIcon>{props.item.icon}</ListItemIcon>
							<ListItemText primary={t(props.item.text)} />
							{props.item.options ? props.item.openState ? <ExpandLess /> : <ExpandMore /> : null}
						</ListItemButton>
					</Tooltip>
				) : (
					<Tooltip title={props.subOption ? t(props.item.tooltip) : t(props.item.text)} placement="right" arrow>
						<ListItemButton onClick={props.item.clickHandler} key={props.item.id} alignItems="center" sx={props.subOption ? {pl : 4} : null}>
							<ListItemIcon>{props.item.icon}</ListItemIcon>
							<ListItemText primary={t(props.item.text)} />
							{props.item.options ? props.item.openState ? <ExpandLess /> : <ExpandMore /> : null}
						</ListItemButton>
					</Tooltip>
				)}
				{props.item.options ? (
					// subOption is added to handle tri-level options e.g. Users > Create > Sterilis User
					<Collapse in={props.item.openState} timeout="auto" unmountOnExit>
						<List>
							{props.item.options.map((option) => {
								return (
									option.options ?
									<MenuItem item={option} key={`menu-${option.id}`} userGroup={props.userGroup} subOption={true}/> :
									<SubItem option={option} key={`sub-${option.id}`} userGroup={props.userGroup}/>
								);
							})}
						</List>
						{
							!props.subOption &&
								<Divider variant="inset" />
						}
					</Collapse>
				) : null}
			</React.Fragment>
		);
	}
};

MenuItem.propTypes = {
	item: PropTypes.object,
	userGroup: PropTypes.string,
	requiredRoles: PropTypes.arrayOf(PropTypes.string),
	subOption: PropTypes.bool
};

export default MenuItem;
