/*
*  Component which holds the Device & date range pickers
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {Button, Dropdown} from 'semantic-ui-react';
import { withTranslation as translate} from "react-i18next";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import RefreshCountdownTimer from "./RefreshCountdownTimer";

import 'react-day-picker/lib/style.css';
import '../../css/dashboard/DashboardNav.css';


const DashboardNav = props => (
  <span>
    <RefreshCountdownTimer
      secondsUntilRefresh={props.secondsUntilRefresh}
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
    />
    <div className='dashboard-nav'>
     <div className='nav-group'>
       <div className='flex-dir-row even-split'>
         <label className='date-label'>{props.t('Device')}</label>
         {props.filterCustomerDevices}
       </div>
       {/*only render dropdown if props.customerMachines exists*/}
       {<Dropdown
         onChange={props.selectDevice}
         className='device-dropdown'
         placeholder='Select Device'
         search
         selection
         searchInput={{autoComplete: 'noautocomplete', type: 'text'}}
         value={props.deviceConfigID}
         options={props.customerMachines}
       />}
     </div>
      <div className='dashboard-nav-right'>
        {props.toDateClick && props.fromDateClick ?
          <div className='date-group'>
            <div className='nav-group from-date-div dashboard-datepicker'>
              <label className='date-label'>{props.t('From')}</label>
              <DayPickerInput
                onDayChange={day => props.fromDateClick(day)}
                value={props.fromDate}
              />
            </div>
            <div className='nav-group dashboard-datepicker'>
              <label className='date-label'>{props.t('To')}</label>
              <DayPickerInput
                onDayChange={day => props.toDateClick(day)}
                value={props.toDate}
              />
            </div>
          </div> : null}
        {props.prepareDashboard ?
          <Button
            className='ster-btn show-data-btn'
            onClick={() => props.prepareDashboard(false)}
          >{props.t('Show Data')}</Button> : null
        }
      </div>
   </div>
  </span>
);

export default translate('translations')(DashboardNav);
