/*
* Component which catches all errors and sends them to Sentry
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorPage from "./ErrorPage";

//Sentry.init({ dsn: 'https://8b2ebec173824278ae29f179eb5dcd5a@sentry.io/1250648' });

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err, info) {
    this.setState({ hasError: true });
    Sentry.captureException(err);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage/>
    }
    return this.props.children;
  }
}
