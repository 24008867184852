/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Dropdown, Popup, Button} from "semantic-ui-react";
import {commStatusSortHook, isGroupSterilis, serialSortHook, alphabeticalJsxSort} from "../../library/helpers";
import DistributorDropdownFilter from "../distributors/DistributorDropdownFilter";
import HookTable from "../../library/HookTable";
import SimpleLoader from "../../SimpleLoader";

export const customRowClass = (rowInfo) => {
  if (rowInfo && rowInfo.original) {
    if (rowInfo.original.banned_status) {
        return "light-red-tr";
    } else if (rowInfo.original.deleted_status) {
        return "light-grey-tr";
    } else if (rowInfo.original.activated_status === false) {
        return "light-yellow-tr";
    }
  }
};

class DeviceTable extends React.Component {
  state = {
    isLoading: false,
    allDevices: false,
    customerDevices: true,
    selected: [1],
  };

  handleChange = (e, {value}) => {
    let difference = [];
    if (this.state.selected.length > value.length) { // an item has been removed
      difference = this.state.selected.filter(
        x => !value.includes(x),
      );
    } else if (this.state.selected.length < value.length) {
      difference = this.state.selected.filter(
        x => value.includes(x),
      );
    }
    // If user chooses all Devices, remove all other selections and do base query on /customer-machines
    if (value.includes(0) && !difference.includes(0)) {
      this.setState({
        selected: [0]
      }, () => {
        this.props.preparePage('')
      });
    } else {
      // In this else, since the user has chosen something that is not 'ALl Devices',
      // remove the 'All devices' index from the value array
      const indexOfZero = value.indexOf(0);
      if (indexOfZero > -1) {
        value.splice(indexOfZero, 1);
      }
      this.setState({selected: value}, () => {
        const {
          selected
        } = this.state;
        const queryString = selected.reduce((str, cur) => {
          switch (cur) {
            case 1:
              return str += 'filter=customer&';
            case 2:
              return str += 'filter=factory&';
            case 3:
              return str += 'filter=unassigned&';
            case 4:
              return str += 'filter=unactivated&';
            case 5:
              return str += 'filter=retired&';
            case 6:
              return str += 'filter=banned&';
            default:
              return str += '';
          }
        }, '?');
        this.setState({
          queryString
        }, () => this.props.preparePage(queryString))
      });
    }
  };

  handleDistributorFilterChange = (event, data) => {
    this.setState({
      distributorFilter: data.value
    });
  };

  render() {
    const {
      distributorFilter
    } = this.state;
    const {
      t,
      deviceArray,
      group,
      distributorDropdownArray,
    } = this.props;

    const columns = [
      {
        Header: <Trans ns="translations" i18nKey="communication_status_br">
          Communication<br/>Status
        </Trans>,
        accessor: 'comm_status',
        manualWidth: 145,
        sortType: commStatusSortHook,
      },
      {
        Header: t('Device Serial'),
        accessor: 'serial',
        manualWidth: 120,
        sortType: serialSortHook,
      },
      {
        Header: t('Software Version'),
        accessor: 'software_version',
        minWidth: 150,
      },
      {
        Header: t('Linux Version'),
        accessor: 'linux_version',
        minWidth: 150,
      },
      ...isGroupSterilis(group) ? [{
        Header: t('Distributor'),
        accessor: 'distributor',
        sortType: alphabeticalJsxSort,
      }] : [],
      {
        Header: t('Customer'),
        accessor: 'customer',
        sortType: alphabeticalJsxSort,
      },
      {
        Header: t('Facility'),
        accessor: 'facility',
        sortType: alphabeticalJsxSort,
      },
      {
        Header: t('Actions'),
        accessor: 'actions',
        manualWidth: 60
      },
    ];
    const deviceFilterDropdown = [
      {
        key: 0,
        value: 0,
        id: '0',
        text: t('All Devices')
      },
      {
        key: 1,
        value: 1,
        id: '1',
        text: t('Customer Devices')
      },
      {
        key: 4,
        value: 4,
        id: '4',
        text: t('Unactivated Devices')
      },
      {
        key: 5,
        value: 5,
        id: '5',
        text: t('Archived Devices')
      },
      {
        key: 6,
        value: 6,
        id: '6',
        text: t('Suspended Devices')
      },
    ];

    if (isGroupSterilis(group)) {
      deviceFilterDropdown.push(
        {
          key: 2,
          value: 2,
          id: '2',
          text: t('Factory Devices')
        },
        {
          key: 3,
          value: 3,
          id: '3',
          text: t('Unassigned Devices')
        }
      )
    }
    const leftHandJSX =
      <div className="flex-dir-row even-split view-customer-filter_container">
        <div className="form-group">
          <label><h4 className='orange-text device-filter-header'>{t('Device Filter')}</h4></label>
          <Dropdown
            className='device-filter-dropdown'
            search
            selection
            onChange={this.handleChange}
            fluid
            multiple
            value={this.state.selected}
            id='filterSelectionDropdown'
            options={deviceFilterDropdown}
          />
        </div>
        <DistributorDropdownFilter
          group={group}
          onChange={this.handleDistributorFilterChange}
          selectedDistributor={distributorFilter}
          distributorDropdownArray={distributorDropdownArray}
        />
      </div>;

    let deviceArrayData = deviceArray;


    if (distributorFilter) {
      deviceArrayData = deviceArrayData.filter(row => {
        return row['distributor_id'] === distributorFilter;
      });
    }
    return (
      <div className='table-container'>
        {this.props.isLoading ? (
          <SimpleLoader/>
        ) : null}
        <div>
          <div className='table-legend'>
            <Popup
              content={
                <div>
                  <div className='small-width minor-padding  light-grey-tr'>
                    {t('Device has been archived')}
                  </div>
                  <div className='small-width minor-padding light-red-tr'>
                    {t('Device has been suspended')}
                  </div>
                  <div className='small-width minor-padding light-yellow-tr'>
                    {t('Device has not been activated with the four digit initialization key')}
                  </div>
                </div>
              }
              on='click'
              position='bottom right'
              trigger={<Button className='grey-btn' content={t('Show Table Legend')}/>}
            />
          </div>
        </div>
        <HookTable
          floatLeftHeaderJSX={leftHandJSX}
          id='deviceTable'
          data={deviceArrayData}
          columns={columns}
          tableName='deviceTable'
          defaultPageSize={20}
          defaultSortBy={{id: 'comm_status', desc: true}}
          customRowClass={true}
          customRowClassFunc={customRowClass}
        />
      </div>
    )
  }
}

export default translate('translations')(DeviceTable);
