/*
*  View SD Image page
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import {composeHoc, getFileNameFromUrl, AuthReq} from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import EditSDImageModal from "./EditSDImageModal";
import DeleteSDImageModal from "./DeleteSDImageModal";
import {Button, Card, Dropdown, Popup} from "semantic-ui-react";
import moment from "moment";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";
import SDImageTable from "./SDImageTable";

const AbortController = window.AbortController;

const Auth = new AuthService();

class ViewSDImage extends React.Component {
  state = {
    isLoading: false,
    SDImage: null,
    SDImageTableArray: [],
    showLegend: false,
    fileName: '',
    showEditSDImageModal: false,
  };

  notifySuccess = (action, filename) =>
    toast(`Successfully ${action} SD Image ${filename}`, {type: toast.TYPE.DEFAULT, autoClose: 5000});


  notifyFailure = () =>
    toast(`Failed to complete the operation. Please refresh the page and try again.`, {
      type: toast.TYPE.ERROR,
      autoClose: 5000
    });

  notifyDownloadFailure = () => toast(`Failed to download file. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  componentDidMount() {
    document.title = 'View SD Images';
    this.preparePage();
  }

  preparePage = () => {
    this.setState({
      isLoading: true
    });
    Auth.fetch(`/api/sd-image?show_deleted=true`)
      .then(data => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const SDImageTableArray = data.map(SDImage => {
          return {
            'file_name': getFileNameFromUrl(SDImage['upload']),
            'description': SDImage['description'],
            'uploaded_timestamp': moment.utc(SDImage['uploaded_timestamp']).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z"),
            'upload_user': SDImage['upload_user']['employee']['full_name'],
            'deleted_status': SDImage['deleted'],
            'preferred': SDImage['preferred'],
            'actions': this.SDImageActions(SDImage)
          }
        });

        this.setState({
          SDImageTableArray,
          isLoading: false,
        })
      }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        isLoading: false,
      })
    });
  };


  SDImageActions = (SDImage) => {
    const {
      t,
      group
    } = this.props;
    return (
      <Dropdown
        id={SDImage['id']}
        pointing='top right'
        icon='ellipsis horizontal'>
        <Dropdown.Menu>
          <Dropdown.Header content={<h4>{t('SD Image Actions')}</h4>}/>
          <Dropdown.Divider/>
          <AuthReq
            userGroup={group}
            requiredRoles={['SterilisSuperUsers','SterilisWasteTypeAdmin', 'SterilisPortalUsers']}
          >
            {
              SDImage['deleted'] === null ?
                <Dropdown.Item
                  id={'prefer-' + SDImage['id']}
                  onClick={() => this.flagAsPreferredSDImage(SDImage)}
                  text={
                    <h5>{SDImage['preferred'] ? t('Remove preferred SD Image status') : t('Set as preferred SD Image')}</h5>}
                />
                : null
            }
          </AuthReq>
          {
            SDImage['deleted'] === null ?
              <Dropdown.Item
                id={'assign-' + SDImage['id']}
                onClick={() => this.downloadFile(SDImage)}
                text={<h5>{t('Download SD Image')}</h5>}
              /> : null
          }
          <AuthReq
            userGroup={group}
            requiredRoles={['SterilisSuperUsers','SterilisWasteTypeAdmin', 'SterilisPortalUsers']}
          >
            {
              SDImage['deleted'] === null ?
                <Dropdown.Item
                  id={'edit-' + SDImage['id']}
                  onClick={() => this.triggerEditSDImageModal(SDImage)}
                  text={<h5>{t('Edit SD Image')}</h5>}
                />
                : null
            }
          </AuthReq>
          <AuthReq
            userGroup={group}
            requiredRoles={['SterilisSuperUsers','SterilisWasteTypeAdmin', 'SterilisPortalUsers']}
          >
            <Dropdown.Item
              id={'delete-' + SDImage['id']}
              onClick={() => this.triggerDeleteSDImageModal(SDImage)}
              text={
                <h5>
                  {`${SDImage['deleted'] ? t('Reactivate') : t('Archive')} ${t('SD Image')}`}
                </h5>
              }
            />
          </AuthReq>
        </Dropdown.Menu>
      </Dropdown>
    )
  };

  getFileNameFromUrl = (url) => {
    const fileName = /([^/]+)(?=[?])/.exec(url)[0];
    // Remove the %20s from the file name, s3 puts them there
    return fileName.replace(/%20/g, " ");
  };

  downloadFile = (SDImage) => {
    const controller = new AbortController();
    const fileID = SDImage['id'];
    const fileName = getFileNameFromUrl(SDImage['upload']);
    this.setState({
      isDownloading: true,
      fileName: fileName,
      controller
    });
    const signal = controller.signal;
    Auth.fetch(`/api/download-file/`, {
      method: 'POST',
      signal,
      body: JSON.stringify({
        'file_id': fileID,
        'file_type': 'sd'
      })
    }).then(response => {
      this.setState({
        isDownloading: false,
      });
      const link = document.createElement('a');
      link.href = response['url'];
      link.setAttribute('download', `${response['url']}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  triggerEditSDImageModal = (SDImage) => {
    this.setState((prevState) => {
      return {
        showEditSDImageModal: !prevState.showEditSDImageModal,
        SDImage
      };
    });
  };

  triggerDeleteSDImageModal = (SDImage) => {
    this.setState((prevState) => {
      return {
        showDeleteSDImageModal: !prevState.showDeleteSDImageModal,
        SDImage
      };
    });
  };

  flagAsPreferredSDImage = SDImage => {
    const newPreferredValue = SDImage['preferred'] ? 0 : 1;

    this.setState({
      isLoading: true
    });


    Auth.fetch(`/api/sd-image/${SDImage['id']}`, {
      method: 'PATCH',
      body: JSON.stringify({
        'preferred': newPreferredValue
      })
    }).then(data => {
      newPreferredValue === 1 ?
        toast(`Set ${getFileNameFromUrl(data['upload'])} as preferred SD Image.`, {
          type: toast.TYPE.DEFAULT,
          autoClose: 5000
        })
        : toast(`Removed preferred SD Image status from ${getFileNameFromUrl(data['upload'])}.`, {
          type: toast.TYPE.DEFAULT,
          autoClose: 5000
        });
      this.preparePage();
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        isLoading: false
      }, () => {
        this.notifyFailure();
      });
    });


  };

  cancelDownload = () => {
    this.state.controller.abort();
  };

  render() {
    const {
      isLoading,
      SDImageTableArray,
      isDownloading,
      fileName,
      showEditSDImageModal,
      SDImage,
      showDeleteSDImageModal
    } = this.state;
    const {t, group} = this.props;
    const cancelButton = <Button
      className='grey-btn high-z-index important-medium-margin-top medium-max-width'
      value="Submit"
      type="submit"
      onClick={this.cancelDownload}
    >Cancel Download</Button>;
    return (
		<Card fluid>
			{isLoading ? <SimpleLoader /> : null}

			{isDownloading && (
				<SimpleLoader
					customClass="flex-dir-column center-items"
					cancelButton={cancelButton}
					loaderText={`Downloading ${fileName}. This is a large file and it may take several minutes to download.`}
				/>
			)}
			<Card.Header style={{ margin: "10px" }}>
				<h2>{t("View SD Images")}</h2>
			</Card.Header>
			<Card.Content className="card-body">
				<div className="view-firmware-header">
					<AuthReq userGroup={group} requiredRoles={["SterilisSuperUsers", "SterilisWasteTypeAdmin"]}>
						<Link to="/upload/sd-image">{t("Upload new SD Image")}</Link>
					</AuthReq>
					<Popup
						content={
							<div>
								<div className="small-width minor-padding light-green-tr">
									{t("The SD Image has been designated as a preferred version")}
								</div>
								<div className="small-width minor-padding light-red-tr">
									{t("The SD Image has been archived")}
								</div>
							</div>
						}
						on="click"
						position="bottom right"
						trigger={<Button className="grey-btn table-legend" content={t("Show Table Legend")} />}
					/>
				</div>
				<SDImageTable group={group} className="slight-margin-top" SDImageTableArray={SDImageTableArray} />

				{showEditSDImageModal ? (
					<EditSDImageModal
						SDImage={SDImage}
						preparePage={this.preparePage}
						triggerEditSDImageModal={this.triggerEditSDImageModal}
						showEditSDImageModal={showEditSDImageModal}
						notifySuccess={this.notifySuccess}
						notifyFailure={this.notifyFailure}
					/>
				) : null}

				{showDeleteSDImageModal ? (
					<DeleteSDImageModal
						SDImage={SDImage}
						preparePage={this.preparePage}
						triggerDeleteSDImageModal={this.triggerDeleteSDImageModal}
						showDeleteSDImageModal={showDeleteSDImageModal}
						notifySuccess={this.notifySuccess}
						notifyFailure={this.notifyFailure}
					/>
				) : null}
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
    'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin'], 'internalPage'))(ViewSDImage);
