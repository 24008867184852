import { Box, Button, Card, CardHeader, CardContent, CircularProgress, useTheme } from "@mui/material";
import Highcharts from "highcharts/highstock";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { calculateCycleTime, cleanCycleType, convertWeight, prettyWastetypes } from "src/components/library/helpers";
import { fetchCycleEvents, setCurrentPressureUnit } from "../deviceDashboardSlice";
import SterilisChart from "src/components/material/Highcharts/SterilisChart";
import SubCard from "src/components/material/Cards/SubCard";

const SterilizationInfoPanel = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {
        cycleEvents,
        cycleMetadata,
        selectedCycle,
        currentPressureUnit,
        deviceSerial,
        userTimezone,
        deviceTimezone,
        weightUnit,
    } = useSelector(
        // @ts-ignore
        (store) => store.devicedashboard
    );

    // @ts-ignore
    const loading = useSelector((store) => store.devicedashboard.loading.sterilizationInfo);

    const [options, setOptions] = React.useState(null);
    const [pressureArrayA, setPressureArrayA] = React.useState([]);
    const [pressureArrayG, setPressureArrayG] = React.useState([]);
    const [tempArray, setTempArray] = React.useState([]);
    const [dashboardResized, setDashboardResized] = React.useState(false);

    const pressureUnitSelectionText = currentPressureUnit === "PSI(g)" ? "PSI(g)" : "PSI(a)";

    const { t } = useTranslation("translations");

    const populateGraph = () => {
        const pressureArrayG = [];
        const pressureArrayA = [];
        const tempArray = [];
        const timeArray = [];
        const ambientPressure = cycleMetadata["ambient_pressure"];
        cycleEvents.forEach((event) => {
            const pressureG = parseFloat((event.pv_value - ambientPressure).toFixed(2));
            timeArray.push(
                moment(event.timestamp)
                    .tz(deviceTimezone ? deviceTimezone : userTimezone)
                    .format("HH:mm:ss")
            );
            tempArray.push(event.tv_value);
            pressureArrayA.push(event.pv_value);
            pressureArrayG.push(pressureG);
        });

        const pressureArray = currentPressureUnit === "PSI(g)" ? pressureArrayG : pressureArrayA;
        const pressureUnitSelectionText =
            currentPressureUnit === "PSI(g)" ? "Switch to<br>PSI(a)" : "Switch to<br>PSI(g)";

        const maxPressure = calculateMaxPressure();

        const pressureSeries = {
            name: t("Pressure"),
            type: "spline",
            yAxis: 1,
            data: pressureArray,
            color: theme.palette.secondary.main,
            tooltip: {
                valueSuffix: currentPressureUnit,
            },
        };
        const tempSeries = {
            name: t("Temp"),
            type: "spline",
            data: tempArray,
            color: theme.palette.primary.main,
            tooltip: {
                valueSuffix: "°C",
            },
        };
        const cleanTime = moment(cycleMetadata["time_started"])
            .tz(deviceTimezone ? deviceTimezone : userTimezone)
            .format("YYYY-MM-DD HH:mm:ss z");
        const steamGraphTitle = `${t("Started")}: ${cleanTime}`;
        const steamGraphSubtitle = generateSteamGraphSubtitle(
            cycleMetadata["max_tv"],
            maxPressure,
            currentPressureUnit
        );

        const options = {
            chart: {
                zoomType: "x",
                resetZoomButton: {
                    position: {
                        x: 0,
                        y: 170,
                    },
                },
            },
            title: {
                text: steamGraphTitle,
            },
            caption: {
                text: getCaptionGraph(cleanTime, cycleMetadata, t, deviceSerial),
                style: {
                    color: theme.palette.text.secondary,
                    fontFamily: "Poppins",
                },
            },
            subtitle: {
                text: steamGraphSubtitle,
            },
            xAxis: [
                {
                    categories: timeArray,
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    labels: {
                        format: "{value}°C",
                    },
                    title: {
                        text: t("Temperature"),
                        style: {
                            color: theme.palette.primary.main,
                        },
                    },
                    startOnTick: false,
                    tickInterval: 10,
                },
                {
                    // Secondary yAxis
                    title: {
                        text: t("Pressure"),
                    },
                    labels: {
                        format: `{value} ${currentPressureUnit}`,
                    },
                    opposite: true,
                    startOnTick: false,
                    gridLineColor: "transparent",
                    tickInterval: 5,
                },
            ],
            tooltip: {
                shared: true,
            },
            credits: {
                enabled: false,
            },
            legend: {
                verticalAlign: "top",
            },
            series: [pressureSeries, tempSeries],
        };
        setOptions(options);
        setPressureArrayA(pressureArrayA);
        setPressureArrayG(pressureArrayG);
        setTempArray(tempArray);
        setDashboardResized(false);
    };

    const calculateMaxPressure = () => {
        const ambientPressure = cycleMetadata["ambient_pressure"];

        return currentPressureUnit === "PSI(g)"
            ? (cycleMetadata["max_pv"] - ambientPressure).toFixed(2)
            : cycleMetadata["max_pv"];
    };

    /**
     * The function `getCaptionGraph` generates a formatted caption with various details extracted from
     * `cycleMetadata` object for a specific device cycle.
     * @param cleanTime - The `cleanTime` parameter is a timestamp representing the date and time of a
     * cleaning cycle.
     * @param cycleMetadata - Cycle metadata contains information about a specific cycle, such as cook
     * time, steam time, weight, waste type, cycle type, cycle status, cycle time, steam time, cook
     * time, user, etc.
     * @param t - The parameter `t` is a function used for internationalization and localization in the
     * code. It is typically used to translate text into different languages based on the user's locale
     * or language preference.
     * @param deviceSerial - The `deviceSerial` parameter is a unique identifier for a specific device.
     * It is used to associate the device with its corresponding data and information in the system.
     * @returns The function `getCaptionGraph` returns a formatted string containing various details
     * related to a cycle metadata object. The details included in the returned string are:
     * - Device Serial
     * - Date & time
     * - Weight
     * - Load Type
     * - Cycle Type
     * - Cycle Status
     * - Cycle Time
     * - Steam Time
     * - Cook Time
     * - User
     */
    const getCaptionGraph = (cleanTime, cycleMetadata, t, deviceSerial) => {
        if (Object.keys(cycleMetadata).length > 0) {
            const cookTime = cycleMetadata.cook_time ? Math.round(cycleMetadata.cook_time) : 0;
            const steamTime = cycleMetadata.steam_time ? Math.round(cycleMetadata.steam_time) : 0;
            return `<b>${t("Device Serial")}: </b>${deviceSerial}<br>
                    <b>${t("Date & time")}: </b>${cleanTime}<br>
                    <b>${t("Weight")}: </b>${convertWeight(cycleMetadata["weight"], {
                weight: weightUnit,
                roundPrecision: 0.1,
                showUnit: true,
            })}<br>
                    <b>${t("Load Type")}: </b>${prettyWastetypes(cycleMetadata.waste_type)}<br>
                    <b>${t("Cycle Type")}: </b>${cleanCycleType(cycleMetadata.cycle_type)}<br>
                    <b>${t("Cycle Status")}: </b>${cycleMetadata.last_cycle_status.message}<br>
                    <b>${t("Cycle Time")}: </b>${calculateCycleTime(
                cycleMetadata["time_started"],
                cycleMetadata["time_ended"],
                cycleMetadata["latest_cycle_event_timestamp"],
                cycleMetadata["latest_cycle_status_timestamp"]
            )}<br>
                    <b>${t("Steam Time")}: </b>${steamTime} ${steamTime > 1 ? t("mins") : t("min")}<br>
                    <b>${t("Cook Time")}: </b>${cookTime} ${cookTime > 1 ? t("mins") : t("min")}<br>
                    <b>${t("User")}: </b>${cycleMetadata.user}<br>
                `;
        }
    };

    const generateSteamGraphSubtitle = (maxTemp, maxPressure, currentPressureUnit) => {
        if (maxTemp === null && maxPressure === null) {
            // Good example of t function interpolation
            return t("no-pres-temp-for-steam-graph", { break: "<br/>", interpolation: { escapeValue: false } });
        } else {
            return `${t("Max temperature")}: ${maxTemp} °C<br>${t("Max pressure")}: ${maxPressure ? maxPressure : ""} ${
                maxPressure ? currentPressureUnit : ""
            }`;
        }
    };

    const switchPressureUnit = () => {
        if (currentPressureUnit === "PSI(a)") {
            dispatch(setCurrentPressureUnit("PSI(g)"));
        } else {
            dispatch(setCurrentPressureUnit("PSI(a)"));
        }
    };

    useEffect(() => {
        if (cycleMetadata !== null) {
            populateGraph();
        }
    }, [cycleEvents, currentPressureUnit, cycleMetadata]);

    useEffect(() => {
        if (selectedCycle) {
            // @ts-ignore
            dispatch(fetchCycleEvents(selectedCycle.cycle_id));
        }
    }, [selectedCycle]);

    return (
        <SubCard
            sx={{ width: "100%" }}
            title={cycleMetadata && t(cycleMetadata["cycle_type"])}
            titleProps={{ textAlign: "center", color: "primary", fontWeight: "bold" }}
            titleVariant={"h5"}
            secondary={
                <Button variant="outlined" onClick={switchPressureUnit}>
                    {pressureUnitSelectionText}
                </Button>
            }>
            <CardContent>
                <Box mt={2}>
                    <SterilisChart highcharts={Highcharts} options={options} updateArgs={[true, true, true]} />
                </Box>
            </CardContent>
        </SubCard>
    );
};

export default SterilizationInfoPanel;
