/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

const SteamCycleTrendsChart = (props) => {
    const {
        scoreTrend,
        tvAvgHoldTrend,
        tpDiffTrend,
        durationTrend,
        flowSecondsTimeTrend,
        steamPumpdownTimeTrend,
        drainTimeTrend,
        heatUpTimeTrend,
        pumpdownTimeoutTrend,
        tvStartTrend,
        tvMaxTrend,
        trAvgTrend,
        pvStartTrend,
        pvMinTrend,
        pvMaxTrend,
        pvAvgDuringHoldTrend,
        heatUpRateDifferenceTrend,
        // @ts-ignore
    } = useSelector((store) => store.metric.steamCycleTrends);
    const { t } = useTranslation("translations");
    const theme = useTheme();

    const combinedSteamCycleTrendOptions = {
        chart: {
            zoomType: "x",
            resetZoomButton: {
                position: {
                    x: 0,
                    y: 0,
                },
            },
        },
        tooltip: {
            shared: true,
        },
        title: {
            text: "Steam Cycle Trends",
        },
        credits: false,
        time: {
            useUTC: false,
        },
        xAxis: [
            {
                type: "datetime",
                crosshair: true,
                alignTicks: true,
                minTickInterval: 24 * 3600 * 1000,
                tickmarkPlacement: "on",
            },
        ],
        yAxis: [
            ...(props.trendCycleScore
                ? [
                      {
                          id: "scoreYAxisLabel",
                          labels: {
                              format: "Score: {value}",
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "dodgerblue",
                              },
                          },
                          title: {
                              text: t("Score"),
                              style: {
                                  color: "dodgerblue",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendCycleDuration
                ? [
                      {
                          id: "cycleDurationYAxisLabel",
                          title: {
                              text: t("Cycle Duration"),
                              style: {
                                  color: "lightseagreen",
                              },
                          },
                          labels: {
                              format: `{value} mins`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "lightseagreen",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendTvAvgHold
                ? [
                      {
                          id: "tvHoldYAxisLabel",
                          title: {
                              text: t("TV Avg Hold"),
                              style: {
                                  color: "fuchsia",
                              },
                          },
                          labels: {
                              format: `{value}°C`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "fuchsia",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendTPDiff
                ? [
                      {
                          id: "tpDiffYAxisLabel",
                          title: {
                              text: t("T-P diff"),
                              style: {
                                  color: "Orange",
                              },
                          },
                          labels: {
                              format: `{value}°C`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "Orange",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendFlowSeconds
                ? [
                      {
                          id: "flowSecondsYAxisLabel",
                          title: {
                              text: t("Flow Seconds"),
                              style: {
                                  color: "HotPink",
                              },
                          },
                          labels: {
                              format: `{value} secs`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "HotPink",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendPumpdownDuration
                ? [
                      {
                          id: "pumpdownDurationYAxisLabel",
                          title: {
                              text: t("Pumpdown duration"),
                              style: {
                                  color: "PeachPuff",
                              },
                          },
                          labels: {
                              format: `{value} mins`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "PeachPuff",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendDrainingTime
                ? [
                      {
                          id: "drainTimeYAxisLabel",
                          title: {
                              text: t("Drain time"),
                              style: {
                                  color: "SpringGreen",
                              },
                          },
                          labels: {
                              format: `{value} mins`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "SpringGreen",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendHeatUpTime
                ? [
                      {
                          id: "heatUpTimeYAxis",
                          title: {
                              text: t("Heat up time"),
                              style: {
                                  color: "MediumTurquoise",
                              },
                          },
                          labels: {
                              format: `{value} mins`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "MediumTurquoise",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendPumpdownTimeouts
                ? [
                      {
                          id: "pumpdownTimeoutsYAxis",
                          title: {
                              text: t("Pumpdown Timeouts"),
                              style: {
                                  color: "LightSalmon",
                              },
                          },
                          labels: {
                              format: `{value} timeouts`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "LightSalmon",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendTVStart
                ? [
                      {
                          id: "tvStartYAxisLabel",
                          title: {
                              text: t("TV Start"),
                              style: {
                                  color: "BlueViolet",
                              },
                          },
                          labels: {
                              format: `{value}°C`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "BlueViolet",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendTVMax
                ? [
                      {
                          id: "tvMaxYAxisLabel",
                          title: {
                              text: t("TV Max"),
                              style: {
                                  color: "DarkSeaGreen",
                              },
                          },
                          labels: {
                              format: `{value}°C`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "DarkSeaGreen",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendTRAverage
                ? [
                      {
                          id: "trAvgYAxisLabel",
                          title: {
                              text: t("TR Average"),
                              style: {
                                  color: "DarkSlateGrey",
                              },
                          },
                          labels: {
                              format: `{value}°C`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "DarkSlateGrey",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendPVStart
                ? [
                      {
                          id: "pvStartYAxisLabel",
                          title: {
                              text: t("Pv Start"),
                              style: {
                                  color: "MediumTurquoise",
                              },
                          },
                          labels: {
                              format: `{value} PSI(g)`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "MediumTurquoise",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendPVMin
                ? [
                      {
                          id: "pvMinYAxisLabel",
                          title: {
                              text: t("PV Min"),
                              style: {
                                  color: "DarkOliveGreen",
                              },
                          },
                          labels: {
                              format: `{value} PSI(g)`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "DarkOliveGreen",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendPVMax
                ? [
                      {
                          id: "pvMaxYAxisLabel",
                          title: {
                              text: t("PV Max"),
                              style: {
                                  color: "LemonChiffon",
                              },
                          },
                          labels: {
                              format: `{value} PSI(g)`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "LemonChiffon",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendPVAverageDuringHold
                ? [
                      {
                          id: "pvAvgYAxisLabel",
                          title: {
                              text: t("PV Avg"),
                              style: {
                                  color: "CornflowerBlue",
                              },
                          },
                          labels: {
                              format: `{value} PSI(g)`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "CornflowerBlue",
                              },
                          },
                      },
                  ]
                : []),
            ...(props.trendHeatUpRateDifference
                ? [
                      {
                          id: "heatUpRateDifferenceYAxisLabel",
                          title: {
                              text: t("Heat Up Rate Difference"),
                              style: {
                                  color: "Crimson",
                              },
                          },
                          labels: {
                              format: `{value}`,
                              enabled: props.hideAxisLabels,
                              style: {
                                  color: "Crimson",
                              },
                          },
                      },
                  ]
                : []),
        ],
        series: [
            ...(props.trendCycleScore
                ? [
                      {
                          name: "Score",
                          data: scoreTrend,
                          yAxis: "scoreYAxisLabel",
                          color: "dodgerblue",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalScoreValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalScoreValues["maximum_value"],
                                            color: "dodgerblue",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendTvAvgHold
                ? [
                      {
                          name: "Tv Avg during Hold",
                          data: tvAvgHoldTrend,
                          yAxis: "tvHoldYAxisLabel",
                          color: "fuchsia",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalTvAvgDuringHoldValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalTvAvgDuringHoldValues["maximum_value"],
                                            color: "fuchsia",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendTPDiff
                ? [
                      {
                          name: "T-P Diff",
                          data: tpDiffTrend,
                          yAxis: "tpDiffYAxisLabel",
                          color: "Orange",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalTPDiffValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalTPDiffValues["maximum_value"],
                                            color: "Orange",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendCycleDuration
                ? [
                      {
                          name: "Cycle Duration",
                          data: durationTrend,
                          yAxis: "cycleDurationYAxisLabel",
                          color: "lightseagreen",
                          zones: [],
                      },
                  ]
                : []),
            ...(props.trendFlowSeconds
                ? [
                      {
                          name: "Flow Seconds",
                          data: flowSecondsTimeTrend,
                          yAxis: "flowSecondsYAxisLabel",
                          color: "HotPink",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalFlowSecondsValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalFlowSecondsValues["maximum_value"],
                                            color: "HotPink",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendPumpdownDuration
                ? [
                      {
                          name: "Pumpdown Duration",
                          data: steamPumpdownTimeTrend,
                          yAxis: "pumpdownDurationYAxisLabel",
                          color: "PeachPuff",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalSteamPumpdownValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalSteamPumpdownValues["maximum_value"],
                                            color: "PeachPuff",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendDrainingTime
                ? [
                      {
                          name: "Drain Time",
                          data: drainTimeTrend,
                          yAxis: "drainTimeYAxisLabel",
                          color: "SpringGreen",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalDrainTimeValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalDrainTimeValues["maximum_value"],
                                            color: "SpringGreen",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendHeatUpTime
                ? [
                      {
                          name: "Heat up time",
                          data: heatUpTimeTrend,
                          yAxis: "heatUpTimeYAxis",
                          color: "MediumTurquoise",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalHeatUpTimeValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalHeatUpTimeValues["maximum_value"],
                                            color: "MediumTurquoise",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendPumpdownTimeouts
                ? [
                      {
                          name: "Pumpdown Timeouts",
                          data: pumpdownTimeoutTrend,
                          yAxis: "pumpdownTimeoutsYAxis",
                          color: "LightSalmon",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalPumpdownTimeoutsValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalPumpdownTimeoutsValues["maximum_value"],
                                            color: "LightSalmon",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendTVStart
                ? [
                      {
                          name: "TV Start",
                          data: tvStartTrend,
                          yAxis: "tvStartYAxisLabel",
                          color: "BlueViolet",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalTvStartValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalTvStartValues["maximum_value"],
                                            color: "BlueViolet",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendTVMax
                ? [
                      {
                          name: "TV Max",
                          data: tvMaxTrend,
                          yAxis: "tvMaxYAxisLabel",
                          color: "DarkSeaGreen",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalTvMaxValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalTvMaxValues["maximum_value"],
                                            color: "DarkSeaGreen",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),

            ...(props.trendTRAverage
                ? [
                      {
                          name: "TR Average",
                          data: trAvgTrend,
                          yAxis: "trAvgYAxisLabel",
                          color: "DarkSlateGrey",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalTrAvgValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalTrAvgValues["maximum_value"],
                                            color: "DarkSlateGrey",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),

            ...(props.trendPVStart
                ? [
                      {
                          name: "PV Start",
                          data: pvStartTrend,
                          yAxis: "pvStartYAxisLabel",
                          color: "MediumTurquoise",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalPvStartValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalPvStartValues["maximum_value"],
                                            color: "MediumTurquoise",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
            ...(props.trendPVMin
                ? [
                      {
                          name: "PV Min",
                          data: pvMinTrend,
                          yAxis: "pvMinYAxisLabel",
                          color: "DarkOliveGreen",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalPvMinValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalPvMinValues["maximum_value"],
                                            color: "DarkOliveGreen",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),

            ...(props.trendPVMax
                ? [
                      {
                          name: "PV Max",
                          data: pvMaxTrend,
                          yAxis: "pvMaxYAxisLabel",
                          color: "LemonChiffon",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalPvMaxValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalPvMaxValues["maximum_value"],
                                            color: "LemonChiffon",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),

            ...(props.trendPVAverageDuringHold
                ? [
                      {
                          name: "PV Average during Hold",
                          data: pvAvgDuringHoldTrend,
                          yAxis: "pvAvgYAxisLabel",
                          color: "CornflowerBlue",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalPvAvgDuringHoldValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalPvAvgDuringHoldValues["maximum_value"],
                                            color: "CornflowerBlue",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),

            ...(props.trendHeatUpRateDifference
                ? [
                      {
                          name: "Heat Up Rate Difference",
                          data: heatUpRateDifferenceTrend,
                          yAxis: "heatUpRateDifferenceYAxisLabel",
                          color: "Crimson",
                          zones: [
                              ...(props.showAbnormalTrendValues
                                  ? [
                                        {
                                            value: props.abnormalHeatUpRateDifferenceValues["minimum_value"],
                                            color: theme.palette.error.light,
                                        },
                                        {
                                            value: props.abnormalHeatUpRateDifferenceValues["maximum_value"],
                                            color: "Crimson",
                                        },
                                        {
                                            color: theme.palette.error.light,
                                        },
                                    ]
                                  : []),
                          ],
                      },
                  ]
                : []),
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={combinedSteamCycleTrendOptions} />;
};

SteamCycleTrendsChart.defaultProps = {
    combineSteamCycleTrends: true,
    trendCycleOutcome: false,
    trendCycleScore: true,
    trendCycleDuration: true,
    trendTvAvgHold: true,
    trendTPDiff: true,
    trendHeatUpRateDifference: true,
    showAbnormalTrendValues: false,
    cycleStartTimes: [],

    abnormalScoreValues: {},
    abnormalFlowSecondsValues: {},
    abnormalSteamPumpdownValues: {},
    abnormalDrainTimeValues: {},
    abnormalHeatUpTimeValues: {},
    abnormalPumpdownTimeoutsValues: {},
    abnormalTPDiffValues: {},
    abnormalTvStartValues: {},
    abnormalTvMaxValues: {},
    abnormalTrAvgValues: {},
    abnormalTvAvgDuringHoldValues: {},
    abnormalPvStartValues: {},
    abnormalPvMinValues: {},
    abnormalPvMaxValues: {},
    abnormalPvAvgDuringHoldValues: {},
    abnormalHeatUpRateDifferenceValues: {},
};

SteamCycleTrendsChart.propTypes = {
    combineSteamCycleTrends: PropTypes.bool,
    trendCycleOutcome: PropTypes.bool,
    trendCycleScore: PropTypes.bool,
    trendCycleDuration: PropTypes.bool,
    trendTvAvgHold: PropTypes.bool,
    trendTPDiff: PropTypes.bool,
    trendHeatUpRateDifference: PropTypes.bool,
    showAbnormalTrendValues: PropTypes.bool,

    abnormalScoreValues: PropTypes.object,
    abnormalFlowSecondsValues: PropTypes.object,
    abnormalSteamPumpdownValues: PropTypes.object,
    abnormalDrainTimeValues: PropTypes.object,
    abnormalHeatUpTimeValues: PropTypes.object,
    abnormalPumpdownTimeoutsValues: PropTypes.object,
    abnormalTPDiffValues: PropTypes.object,
    abnormalTvStartValues: PropTypes.object,
    abnormalTvMaxValues: PropTypes.object,
    abnormalTrAvgValues: PropTypes.object,
    abnormalTvAvgDuringHoldValues: PropTypes.object,
    abnormalPvStartValues: PropTypes.object,
    abnormalPvMinValues: PropTypes.object,
    abnormalPvMaxValues: PropTypes.object,
    abnormalPvAvgDuringHoldValues: PropTypes.object,
    abnormalHeatUpRateDifferenceValues: PropTypes.object,
};

export default SteamCycleTrendsChart;
