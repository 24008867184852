/*
 *  This is the component for the large (width >1200px) dashboard
 *
 *   It will have three graphs at the top.
 *   The cycle table & pressure/temp graph will be parallel to one another at the bottom of the page
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { withTranslation as translate } from "react-i18next";
import { Grid, Segment } from "semantic-ui-react";
import CycleSummary from "../dashboard/CycleSummary";
import UserPieChart from "../dashboard/UserPieChart";
import SummaryDashboardNav from "./SummaryDashboardNav";
import SummaryDashboardCycleTable from "./SummaryDashboardCycleTable";
import SummaryDashboardBiochallenge from "./SummaryDashboardBiochallenge";
import WasteProcessedSummary from "../dashboard/WasteProcessedSummary";

const SummaryDashboard = (props) => (
  <div className="customer-container">
    <SummaryDashboardNav
      toDateClick={props.toDateClick}
      fromDateClick={props.fromDateClick}
      fromDate={props.fromDate}
      toDate={props.toDate}
      prepareDashboard={props.prepareDashboard}
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
      customerName={props.customerName}
      isFacility={props.isFacility}
      facilityName={props.facilityName}
      customerId={props.customerId}
      facilityID={props.facilityID}
      facilities={props.facilities}
      selectedFacility={props.selectedFacility}
    />
    <div
      className={`flex-row top-row`}
    >
      <Grid  container stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Segment raised>
              <CycleSummary
                graphContainerClass=""
                className="flex-item"
                dashboardResized={true}
                deviceCycles={props.summaryCycles}
                userTimezone={props.userTimezone}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment raised>
              <UserPieChart
                graphContainerClass=""
                className="flex-item"
                dashboardResized={true}
                deviceCycles={props.summaryCycles}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment raised>
              <WasteProcessedSummary
                graphContainerClass=""
                className="flex-item"
                dashboardResized={true}
                deviceCycles={props.summaryCycles}
                userTimezone={props.userTimezone}
                weight_unit={props.weight_unit}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment raised>
              <SummaryDashboardCycleTable
                deviceCycles={props.summaryCycles}
                userTimezone={props.userTimezone}
                deviceTimezone={props.deviceTimezone}
                className='flex-item'
                complianceReport={props.complianceReport}
                comprehensiveReport={props.comprehensiveReport}
                setComprehensiveReport={props.setComprehensiveReport}
                setComplianceReport={props.setComplianceReport}
                cycleTableIsExpanded={true}
                deviceFacilityFullAddress={props.deviceFacilityFullAddress}
                isAirport={props.isAirport}
                deviceSerial={props.isFacility?props.facilityName:props.customerName}
                currentPressureUnit={props.currentPressureUnit}
                customerReport={!props.isFacility}
              />

            </Segment>
          </Grid.Column>
        </Grid.Row>
        {props.isAirport ?
          null
          :
          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment raised>
                <SummaryDashboardBiochallenge
                  biochallengeInfo={props.biochallengeInfo}
                  userTimezone={props.userTimezone}
                  deviceTimezone={props.deviceTimezone}
                />
          

              </Segment>
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    </div>
  </div>
);
export default translate("translations")(SummaryDashboard);
