/*
*  Description side component for Creating a Distributor, Customer, and/or Facility
*
* Copyright (C) 2018, 2019, 2020 Sterilis Medical Inc., all rights reserved.
*/

import React from "react";
import {useTranslation} from "react-i18next";

export const CreateDCFDesc = props => {
  const {t} = useTranslation('translations');
  return <div className='desc'>
    {props['create'] === 'distributor' &&
    <React.Fragment>
      <h3>{t('create-a-distributor.header', 'New Distributor and Device Warehouse')}</h3>
      <h5>{t('create-a-distributor.1', 'This form is to create a new distributor and it\'s Warehouse facility')}</h5>
      <h5>{t('create-a-distributor.2', 'The warehouse facility is the location where the distributor will keep their device inventory.')}</h5>
    </React.Fragment>}
    {props['create'] === 'customer' &&
    <React.Fragment>
      <h3>{t('create-a-customer.header', 'New Customer & Facility')}</h3>
      <h5>{t('create-a-customer.1', 'This form is to create a new customer and subsequently, it\'s first facility.')}</h5>
    </React.Fragment>}
    {props['create'] === 'facility' &&
    <React.Fragment>
      <h3>{t('create-a-facility.header', 'Existing Customer & New Facility')}</h3>
      <h5>{t('create-a-facility.1', 'This form is to create a new facility with an existing customer.')}</h5>
      <h5>{t('create-a-facility.2', 'Choose the customer that the new facility will belong to from the dropdown.')}</h5>
    </React.Fragment>}
    {(props['create'] === 'facility' || props['create'] === 'customer') &&
    <React.Fragment>
      <h5>{t('create-a-customer.3', 'A customer is the business we\'re working with')}'</h5>
      <h5
        className='h5-indented'>{t('create-a-customer.3_example', '(eg \'Sterilis LLC\', \'Advanced Dermatology\')')}</h5>
      <h5>{t('create-a-customer.4', 'A facility is the building where a device is located.')}</h5>
      <h5
        className='h5-indented'>{t('create-a-customer.4_example', '(eg \'Sterilis Lab\', \'Lincolnshire\')')}</h5>
      <h5>{t('create-a-customer.5', 'A customer can have any number of facilities, but a facility can only have one customer.')}</h5>
      <h5>{t('create-a-customer.6', 'Devices belong to facilities, and facilities belong to customers.')}</h5>
    </React.Fragment>
    }
  </div>
};