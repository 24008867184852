/*
* A generic dropdown filter for Distributors
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {Dropdown} from "semantic-ui-react";
import {AuthReq} from "../../library/helpers";
import {useTranslation} from "react-i18next";

const DistributorDropdownFilter = props => {
  const { t } = useTranslation('translations');
  return <AuthReq
    userGroup={props.group}
    requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers','SterilisWasteTypeAdmin']}
  >
    <div className={props.containerClass}>
      <label><h4 className={`${props['labelTextColor']} device-filter-header`}>
        {t(props['filterLabel'])}
      </h4></label>
      <Dropdown
        className={props.dropdownClass}
        search
        selection
        onChange={props.onChange}
        fluid
        value={props.selectedDistributor}
        options={props.distributorDropdownArray}
      />
    </div>
  </AuthReq>
};

DistributorDropdownFilter.defaultProps = {
  selectedDistributor : 0,
  dropdownClass: 'device-filter-dropdown',
  containerClass: 'view-customer-filter',
  filterLabel: 'Distributor Filter',
  labelTextColor: 'orange-text'
};

export default DistributorDropdownFilter;
