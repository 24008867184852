import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import AuthService from "src/components/AuthService";
import { processBurninData } from "src/components/library/helpers";

const auth = new AuthService();

const initialState = {
    distributors: [],
    loading: false,
    selectedDistributor: null,
    distributorDetails: {
        distributor_name: null,
        main_customer: {},
        id: null,
        active: false,
    },
    updatedDistributor: {
        id: null,
        distributor_name: null,
        active: null,
    },
    editModalOpen: false,
    loadingDetails: false,
    distributorCustomers: [],
};

const distributorSlice = createSlice({
    name: "distributor",
    initialState,
    reducers: {
        setDistributors(state, action) {
            state.distributors = action.payload;
        },
        setSelectedDistributor(state, action) {
            state.selectedDistributor = action.payload;
        },
        setEditModalOpen(state, action) {
            state.editModalOpen = action.payload;
        },
        setDistributorDetails(state, action) {
            state.distributorDetails = action.payload;
        },
        setDistributorName(state, action) {
            state.updatedDistributor.distributor_name = action.payload;
        },
        setDistributorActive(state, action) {
            state.updatedDistributor.active = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDistributors.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchDistributors.fulfilled, (state, action) => {
                state.distributors = action.payload;
                state.loading = false;
            })
            .addCase(fetchDistributors.rejected, (state, action) => {
                state.loading = false;
                state.distributors = [];
            })
            .addCase(fetchDistributorCustomers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchDistributorCustomers.fulfilled, (state, action) => {
                let filteredDists = action.payload.filter((dist) => dist["main_customer"] && dist.id !== 1);
                const options = filteredDists.map((dist) => {
                    return {
                        key: dist?.main_customer.id,
                        name: dist?.main_customer.customer_name,
                        dist_id: dist?.id,
                        dist_name: dist?.distributor_name,
                    };
                });
                state.distributorCustomers = options;
                state.loading = false;
            })
            .addCase(fetchDistributorCustomers.rejected, (state, action) => {
                state.loading = false;
                state.distributors = [];
            })
            .addCase(fetchDistributorDetails.pending, (state) => {
                state.loadingDetails = true;
            })
            .addCase(fetchDistributorDetails.fulfilled, (state, action) => {
                state.distributorDetails = action.payload;
                state.updatedDistributor.id = action.payload.id;
                state.distributorDetails.active = action.payload.deleted === null;
                state.updatedDistributor.active = action.payload.deleted === null;
                state.loadingDetails = false;
            })
            .addCase(fetchDistributorDetails.rejected, (state, action) => {
                state.loadingDetails = false;
                state.distributorDetails = initialState.distributorDetails;
            })
            .addCase(updateDistributorDetails.pending, (state) => {
                //state.distributorDetails = initialState.distributorDetails;
            })
            .addCase(updateDistributorDetails.fulfilled, (state, action) => {
                state.distributorDetails = action.payload;
                const dist = state.distributors.find((distributor) => distributor.id === action.payload.id);
                if (dist) {
                    dist.distributor_name = action.payload.distributor_name;
                    dist.main_customer = action.payload.main_customer;
                    dist.deleted = action.payload.deleted;
                }
                state.distributorDetails.active = action.payload.deleted === null;
            })
            .addCase(updateDistributorDetails.rejected, (state, action) => {
                state.distributorDetails = initialState.distributorDetails;
            });
    },
});

export const fetchDistributorDetails = createAsyncThunk(
    "distributor/fetchDistributorDetails",
    async (distributorId) => {
        const response = await auth.fetch(`/api/distributor/${distributorId}`);
        return response;
    }
);

export const fetchDistributors = createAsyncThunk("distributor/fetchDistributors", async () => {
    const response = await auth.fetch(`/api/distributor?show_deleted=True`);
    return response;
});

export const fetchDistributorCustomers = createAsyncThunk("distributor/fetchDistributorCustomers", async () => {
    const response = await auth.fetch(`/api/distributor/`);
    return response;
});

export const updateDistributorDetails = createAsyncThunk(
    "distributor/updateDistributorDetails",
    /** @param params {{ id: string, distributor_name: string, deleted: string, main_customer: object }} */
    async (params) => {
        console.log(JSON.stringify(params));
        const response = await auth.fetch(`/api/distributor/${params.id}`, {
            method: "PATCH",
            body: JSON.stringify(params),
        });
        return response;
    }
);

export const {
    setDistributors,
    setSelectedDistributor,
    setEditModalOpen,
    setDistributorDetails,
    setDistributorName,
    setDistributorActive,
} = distributorSlice.actions;

export default distributorSlice.reducer;
