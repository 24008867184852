import deviceDashboard from "./images/customer/dashboard.png";
import refreshInterval from "./images/customer/refresh_interval.png";
import exportMenu from "./images/customer/export_menu.png";
import biochallengPanel from "./images/customer/biochallenge_panel.png";
import sterilizationGraph from "./images/customer/sterilization_graph.png";

export const changelog = [
    {
        type: "chg",
        audience: "customer",
        desc: "Updated Device Dashboard UI.",
        details:
            "Updated device dashboard user interface to be more responsive and cleaner. Including the following updates.",
        images: [
            { id: "device-dashboard", src: deviceDashboard, title: "Device Dashboard" },
            { id: "export-menu", src: exportMenu, title: "New Export Menu For Running Reports" },
            { id: "bio-panel", src: biochallengPanel, title: "Updated Biochallenge Panel" },
            { id: "sterilization-graph", src: sterilizationGraph, title: "Updated Sterilization Graph" },
        ],
    },
    {
        type: "new",
        audience: "customer",
        desc: "Added Refresh Interval Selector to Device Dashboard",
        details: "Added a drop down that allows users to select the time between automatic dashboard refreshes.",
        images: [{ id: "refresh-interval", src: refreshInterval, title: "Refresh Interval Selector" }],
    },
];
