/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import BurninScore from "./BurninScore";
import AbnormalValuesModal from "../AbnormalValues/AbnormalValuesModal";
import BurninStats from "./BurninStats";
import ExportBurninResults from "./ExportBurninResults";
import moment from "moment";
import CycleMetricChartOptions from "../CycleMetricChartOptions";
import { Box, Tabs, Tab, Paper, Typography, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

const BurninStatChartContainer = (props) => {
    const [chartOptions, _setChartOptions] = useState({
        combineTPGraphs: true,
        showCurrentGraph: true,
        showTempConstants: true,
        showPresConstants: true,
        overlayStates: false,
        overlayInfo: false,
        plotDutyCycle: false,
        showAbnormalSeriesValues: false,
    });

    const setChartOptions = (newState) => _setChartOptions({ ...chartOptions, ...newState });

    const [digitalOutputOverlays, _setDigitalOutputOverlays] = useState({
        overlaySol1: false,
        overlaySol2: false,
        overlaySol3: false,
        overlaySol4: false,
        overlaySol5: false,
        overlaySol6: false,
        overlaySol7: false,
        overlaySol8: false,
        overlaySol9: false,
        overlaySol10: false,
        overlaySol11: false,
        overlaySol12: false,
        overlaySol13: false,
        overlaySol14: false,
        overlaySol15: false,
        overlaySol16: false,
        overlaySol17: false,
        overlaySol18: false,
        overlayVacPump: false,
        overlayAirPump: false,
        overlayWaterPump: false,
        overlayHeaters: false,
        overlayDeodorizerLED: false,
        overlayGrinderOn: false,
        overlayGrinderForward: false,
        overlayOil: false,
        overlayOilLevels: false,
    });

    const setDigitalOutputOverlays = (newState) => _setDigitalOutputOverlays({ ...digitalOutputOverlays, ...newState });

    const [showFloatButtons, setShowFloatButtons] = useState(true);

    const burninTest1 = useSelector((store) => store.bit.bitTests[1].latest);
    const burninTest2 = useSelector((store) => store.bit.bitTests[2].latest);
    const burninTest3 = useSelector((store) => store.bit.bitTests[3].latest);
    const burninTest4 = useSelector((store) => store.bit.bitTests[4].latest);
    const burninTest5 = useSelector((store) => store.bit.bitTests[5].latest);
    const burninTest6 = useSelector((store) => store.bit.bitTests[6].latest);
    const burninTest7 = useSelector((store) => store.bit.bitTests[7].latest);

    const test1Header = "Test 1: Extended Grind Run";
    const test2Header = "Test 2: Automated Kinematics";
    const test3Header = "Test 3: Long Steam Cycle";
    const test4Header = "Test 4: # Of Burps";
    const test5Header = "Test 5: Lid Motion after Heat";
    const test6Header = "Test 6: Towel Roll Test";
    const test7Header = "Test 7: Summary of 4 UI Runs";

    const burninScore = useSelector((state) => state.bit.score);
    const [scoreCalculated, setScoreCalculated] = useState(false);

    // These were originally abstracted into their own arrays instead of defined inside the <BurninScore/> props
    // in order to dynamically build the Export ability, but it's not worth it at this time IMO
    const burninTest1Metrics = ["m0_current", "m30_current", "growth_factor", "max_duty_cycle", "tooth_temperature"];

    const burninTest3Metrics = [
        "pv_min",
        "tr_max",
        "tr_average_during_hold",
        "pv_high_hold_start",
        "pv_high_hold_end",
        "pv_drift",
        "pv_average_during_hold",
        "tp_diff",
        "heat_up_rate_delta",
        "score",
    ];

    const burninTest4Metrics = ["stag_recovery_count", "prv_drain_temp"];

    const burninTest5Metrics = [
        "pv_min",
        "tr_max",
        "tr_average_during_hold",
        "pv_average_during_hold",
        "open_bottomlid_duration",
        "open_toplid_duration",
        "tp_diff",
        "heat_up_rate_delta",
        "score",
    ];

    const burninTest6Metrics = [
        "grinder_current_hi",
        "grinder_current_low",
        "grinder_current_noise",
        "ia_at_end",
        "grind_duration",
        "final_duty_cycle",
        "number_stalls",
    ];

    const burninTest7Metrics = [
        "pv_min",
        "pumpdown_duration_minutes",
        "tr_average_during_ramp",
        "flow_seconds",
        "heatup_time",
        "pv_average_during_hold",
        "tv_average_during_hold",
        "tr_average_during_hold",
        "draining_duration_minutes",
        "duty_cycle_end",
        "duration_minutes",
        "score",
    ];

    const heatUpRateDefinitions = {
        heat_up_rate_delta: [
            "th_initial",
            {
                name: "heat_up_time_at_pres",
                format: (value) => moment.utc(value).format("HH:mm:ss"),
            },
            "heat_up_tv_at_pres",
            {
                name: "heat_up_time_at_heat_up",
                format: (value) => moment.utc(value).format("HH:mm:ss"),
            },
            {
                name: "heat_up_time_delta",
                format: (value) => {
                    return moment.duration(Number(value), "seconds").humanize();
                },
            },
            "typical_rate_at_th_initial",
            "tv_rate_of_rise",
        ],
    };

    return (
        <Box mx={2} mb={4}>
            {showFloatButtons && (
                <ExportBurninResults
                    burninTest1={burninTest1}
                    burninTest2={burninTest2}
                    burninTest3={burninTest3}
                    burninTest4={burninTest4}
                    burninTest5={burninTest5}
                    burninTest6={burninTest6}
                    burninTest7={burninTest7}
                    deviceSerial={props.deviceSerial}
                    burninScore={burninScore}
                />
            )}
            <CycleMetricChartOptions
                useDrawer={true}
                containsBurninTest={true}
                cycleType={props.cycleType}
                infoCount={props.infoCount}
                chartOptions={chartOptions}
                setChartOptions={setChartOptions}
                digitalOutputOverlays={digitalOutputOverlays}
                setDigitalOutputOverlays={setDigitalOutputOverlays}
                scrollOnCycleClick={props.scrollOnCycleClick}
                setScrollOnCycleClick={props.setScrollOnCycleClick}
                showFloatButtons={showFloatButtons}
                setShowFloatButtons={setShowFloatButtons}
            />
            <BurninScore
                burninTest1={burninTest1}
                burninTest2={burninTest2}
                burninTest3={burninTest3}
                burninTest4={burninTest4}
                burninTest5={burninTest5}
                burninTest6={burninTest6}
                burninTest7={burninTest7}
                abnormalBurninValues={props.abnormalBurninValues}
                burninScore={burninScore}
                setScoreCalculated={setScoreCalculated}
                handleTabChange={props.handleTabChange}
                loadingBurnIn1={props.loadingBurnIn1}
                loadingBurnIn2={props.loadingBurnIn2}
                loadingBurnIn3={props.loadingBurnIn3}
                loadingBurnIn4={props.loadingBurnIn4}
                loadingBurnIn5={props.loadingBurnIn5}
                loadingBurnIn6={props.loadingBurnIn6}
                loadingBurnIn7={props.loadingBurnIn7}
            />
            {showFloatButtons && (
                <AbnormalValuesModal
                    abnormalValues={props.abnormalValues}
                    containsBurninTest={true}
                    setShowFloatButtons={setShowFloatButtons}
                />
            )}
            <Paper sx={{ width: "100%", p: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        variant="fullWidth"
                        value={props.burnInTab}
                        onChange={(event, data) => props.handleTabChange(data)}
                        aria-label="Burn in test tabs">
                        <Tooltip title="Burn-in Test 1" placement="top-end">
                            <Tab label={<Typography variant="body1">Extended Grind Run</Typography>} />
                        </Tooltip>
                        <Tooltip title="Burn-in Test 2" placement="top-end">
                            <Tab label={<Typography variant="body1">Automated Kinematics</Typography>} />
                        </Tooltip>
                        <Tooltip title="Burn-in Test 3" placement="top-end">
                            <Tab label={<Typography variant="body1">Long Steam Cycle</Typography>} />
                        </Tooltip>
                        <Tooltip title="Burn-in Test 4" placement="top-end">
                            <Tab label={<Typography variant="body1"># Of Burps</Typography>} />
                        </Tooltip>
                        <Tooltip title="Burn-in Test 5" placement="top-end">
                            <Tab label={<Typography variant="body1">Lid Motion after Heat</Typography>} />
                        </Tooltip>
                        <Tooltip title="Burn-in Test 6" placement="top-end">
                            <Tab label={<Typography variant="body1">Towel Roll Test</Typography>} />
                        </Tooltip>
                        <Tooltip title="Burn-in Test 7" placement="top-end">
                            <Tab label={<Typography variant="body1">Summary of 4 UI Runs</Typography>} />
                        </Tooltip>
                    </Tabs>
                </Box>
                <BurninStats
                    testNumber={1}
                    burninTestList={props.burnIn1Data}
                    testTitle={test1Header}
                    abnormalValuesForBurninTest={props.abnormalBurninValues["1"]}
                    burninStatInternalNames={burninTest1Metrics}
                    digitalOutputOverlays={digitalOutputOverlays}
                    chartOptions={chartOptions}
                    abnormalValuesObj={props.abnormalValuesObj}
                    burnInTab={props.burnInTab}
                    index={0}
                    loadingBurninData={props.loadingBurnIn1}
                    digitalOutput={props.digitalOutput}
                />
                <BurninStats
                    testNumber={2}
                    burninTestList={props.burnIn2Data}
                    testTitle={test2Header}
                    abnormalValuesForBurninTest={props.abnormalBurninValues["2"]}
                    usePercent={true}
                    burninStatInternalNames={["scj_percent", "shroud_open_percent", "shroud_close_percent"]}
                    subBurninStatInternalNames={{
                        scj_percent: [
                            "scissor_jack_count",
                            "scj_extension_succeeds",
                            "scj_extension_fails",
                            "scj_extension_duration_avg",
                            "scj_retraction_succeeds",
                            "scj_retraction_fails",
                            "scj_retraction_duration_avg",
                        ],
                        shroud_open_percent: ["shroud_open_succeeds", "shroud_open_fails", "shroud_open_duration_avg"],
                        shroud_close_percent: [
                            "shroud_close_succeeds",
                            "shroud_close_fails",
                            "shroud_close_duration_avg",
                        ],
                    }}
                    burnInTab={props.burnInTab}
                    index={1}
                    loadingBurninData={props.loadingBurnIn2}
                    digitalOutput={props.digitalOutput}
                />
                <BurninStats
                    testNumber={3}
                    burninTestList={props.burnIn3Data}
                    testTitle={test3Header}
                    abnormalValuesForBurninTest={props.abnormalBurninValues["3"]}
                    burninStatInternalNames={burninTest3Metrics}
                    subBurninStatInternalNames={heatUpRateDefinitions}
                    digitalOutputOverlays={digitalOutputOverlays}
                    chartOptions={chartOptions}
                    abnormalValuesObj={props.abnormalValuesObj}
                    burnInTab={props.burnInTab}
                    index={2}
                    loadingBurninData={props.loadingBurnIn3}
                    digitalOutput={props.digitalOutput}
                />
                <BurninStats
                    testNumber={4}
                    burninTestList={props.burnIn4Data}
                    testTitle={test4Header}
                    abnormalValuesForBurninTest={props.abnormalBurninValues["4"]}
                    burninStatInternalNames={burninTest4Metrics}
                    digitalOutputOverlays={digitalOutputOverlays}
                    chartOptions={chartOptions}
                    abnormalValuesObj={props.abnormalValuesObj}
                    burnInTab={props.burnInTab}
                    index={3}
                    loadingBurninData={props.loadingBurnIn4}
                    digitalOutput={props.digitalOutput}
                />
                <BurninStats
                    testNumber={5}
                    burninTestList={props.burnIn5Data}
                    testTitle={test5Header}
                    abnormalValuesForBurninTest={props.abnormalBurninValues["5"]}
                    burninStatInternalNames={burninTest5Metrics}
                    digitalOutputOverlays={digitalOutputOverlays}
                    subBurninStatInternalNames={heatUpRateDefinitions}
                    chartOptions={chartOptions}
                    abnormalValuesObj={props.abnormalValuesObj}
                    burnInTab={props.burnInTab}
                    index={4}
                    loadingBurninData={props.loadingBurnIn5}
                    digitalOutput={props.digitalOutput}
                />
                <BurninStats
                    testNumber={6}
                    burninTestList={props.burnIn6Data}
                    testTitle={test6Header}
                    abnormalValuesForBurninTest={props.abnormalBurninValues["6"]}
                    burninStatInternalNames={burninTest6Metrics}
                    digitalOutputOverlays={digitalOutputOverlays}
                    chartOptions={chartOptions}
                    abnormalValuesObj={props.abnormalValuesObj}
                    burnInTab={props.burnInTab}
                    index={5}
                    loadingBurninData={props.loadingBurnIn6}
                    digitalOutput={props.digitalOutput}
                />
                <BurninStats
                    testNumber={7}
                    burninTestList={props.burnIn7Data}
                    testTitle={test7Header}
                    abnormalValuesForBurninTest={props.abnormalBurninValues["7"]}
                    burninStatInternalNames={burninTest7Metrics}
                    digitalOutputOverlays={digitalOutputOverlays}
                    chartOptions={chartOptions}
                    abnormalValuesObj={props.abnormalValuesObj}
                    burnInTab={props.burnInTab}
                    index={6}
                    loadingBurninData={props.loadingBurnIn7}
                    digitalOutput={props.digitalOutput}
                />
            </Paper>
        </Box>
    );
};

BurninStatChartContainer.defaultProps = {
    abnormalValues: [],
    abnormalValuesObj: {},
    abnormalBurninValues: {},
    showBurninTest3Chart: true,
    showBurninTest4Chart: false,
    showBurninTest5Chart: false,
    showBurninTest6Chart: false,
    deviceSerial: "",
    burninTest7Cycles: [],
};

BurninStatChartContainer.propTypes = {
    abnormalValues: PropTypes.array,
    abnormalValuesObj: PropTypes.object,
    abnormalBurninValues: PropTypes.object,
    showBurninTest3Chart: PropTypes.bool,
    showBurninTest4Chart: PropTypes.bool,
    showBurninTest5Chart: PropTypes.bool,
    showBurninTest6Chart: PropTypes.bool,
    deviceSerial: PropTypes.string,
    scrollOnCycleClick: PropTypes.bool.isRequired,
    setScrollOnCycleClick: PropTypes.func.isRequired,
    burninTest7Cycles: PropTypes.array,
};

export default BurninStatChartContainer;
