import {
    CheckCircleTwoTone,
    ErrorOutlineTwoTone,
    ErrorTwoTone,
    InfoTwoTone,
    WarningTwoTone,
} from "@mui/icons-material";

export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                },
                rounded: {
                    borderRadius: 2,
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    padding: "24px",
                },
                title: {
                    fontSize: "1.125rem",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "24px",
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: "24px",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 16,
                    padding: 4,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    //color: theme.darkTextPrimary,
                    //paddingTop: "10px",
                    //paddingBottom: "10px",
                    "&.Mui-selected": {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        "&:hover": {
                            backgroundColor: theme.menuSelectedBack,
                        },
                        "& .MuiListItemIcon-root": {
                            color: theme.menuSelected,
                        },
                    },
                    "&:hover": {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        "& .MuiListItemIcon-root": {
                            color: theme.menuSelected,
                        },
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingLeft: 6,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
                input: {
                    color: theme.textDark,
                    borderRadius: 8,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.colors?.grey100,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.colors?.primaryMain,
                    },
                    "&.MuiInputBase-multiline": {
                        padding: 1,
                    },
                },
                input: {
                    fontWeight: 500,
                    padding: "15.5px 14px",
                    borderRadius: 8,
                    "&.MuiInputBase-inputSizeSmall": {
                        padding: "10px 14px",
                        "&.MuiInputBase-inputAdornedStart": {
                            paddingLeft: 0,
                        },
                    },
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
                notchedOutline: {
                    borderRadius: 8,
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: theme.colors?.grey300,
                    },
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: "4px",
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    "&.MuiChip-deletable .MuiChip-deleteIcon": {
                        color: "inherit",
                    },
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: "secondary",
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "secondary",
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700,
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
                row: {
                    "&.Mui-selected": {
                        backgroundColor: "rgba(76, 187, 212, 0.9)",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "rgba(51, 178, 206, 0.9)",
                        },
                    },
                },
                checkboxInput: {
                    color: "rgba(255, 167, 38, 1)",
                    "&.Mui-checked": {
                        color: "rgba(255, 167, 38, 1)",
                    },
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    warning: <WarningTwoTone fontSize="inherit" sx={{ color: theme.colors.warningDark }} />,
                    info: <InfoTwoTone fontSize="inherit" sx={{ color: theme.colors.infoDark }} />,
                    success: <CheckCircleTwoTone fontSize="inherit" sx={{ color: theme.colors.successDark }} />,
                    error: <ErrorTwoTone fontSize="inherit" sx={{ color: theme.colors.errorDark }} />,
                },
            },
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                containerAnchorOriginTopRight: {
                    marginTop: 60,
                },
            },
        },
    };
}
