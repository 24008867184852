import React from "react";
import PropTypes from "prop-types";
import SubCard from "src/components/material/Cards/SubCard";
import { Avatar, Button, ButtonBase, CardContent, Stack, useTheme } from "@mui/material";
import { BuildTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    setResendActivationCodeModal,
    setResetBiochallengeDialogOpen,
    setShowGetDeviceFilesModal,
} from "../deviceInfoSlice";
import { AuthReq, sterilisCustomers } from "src/components/library/helpers";

const DeviceActions = (props) => {
    const { t } = useTranslation("translations");
    const { serialNumber } = props;
    const dispatch = useDispatch();
    const { selectedDeviceConfig, showGetDeviceFilesModal, resendActivationCodeModal } = useSelector(
        (store) => store.deviceInfo
    );
    const { user, group } = useSelector((store) => store.user);
    const theme = useTheme();
    return (
        <SubCard
            title={t("Device Actions")}
            avatar={
                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                    <BuildTwoTone />
                </Avatar>
            }>
            <CardContent>
                <Stack sx={{ paddingLeft: 4 }} alignItems={"flex-start"} justifyContent={"center"}>
                    <Button onClick={() => dispatch(setShowGetDeviceFilesModal(!showGetDeviceFilesModal))}>
                        {t("Get Files")}
                    </Button>
                    <Button component={RouterLink} to={`/sa/dashboard/${selectedDeviceConfig?.id}`}>{`${t("View")} ${
                        serialNumber ? serialNumber : ""
                    } ${t("Dashboard")}`}</Button>

                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FSEs",
                            "ExternalFSEs",
                            "FactoryWorkers",
                            "DistributorAdmins",
                            "DistributorFSEs",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {group === "FactoryWorkers" ? (
                            sterilisCustomers.includes(selectedDeviceConfig?.facility?.customer?.id) && (
                                <Button
                                    onClick={() => dispatch(setResendActivationCodeModal(!resendActivationCodeModal))}>
                                    {t("Reset Initialization Key")}
                                </Button>
                            )
                        ) : (
                            <Button onClick={() => dispatch(setResendActivationCodeModal(!resendActivationCodeModal))}>
                                {t("Reset Initialization Key")}
                            </Button>
                        )}
                    </AuthReq>
                    <AuthReq userGroup={group} requiredRoles={["SterilisSuperUsers", "SterilisWasteTypeAdmin"]}>
                        <Button
                            onClick={() => {
                                dispatch(setResetBiochallengeDialogOpen(true));
                            }}>
                            {t("Reset Bio-Challenge Interval")}
                        </Button>
                    </AuthReq>
                </Stack>
            </CardContent>
        </SubCard>
    );
};

DeviceActions.propTypes = {
    serialNumber: PropTypes.string,
};

export default DeviceActions;
