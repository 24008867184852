/*
*  Main component for SD image upload
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { Trans, withTranslation as translate } from "react-i18next";
import SimpleLoader from "../SimpleLoader";
import {composeHoc} from "../library/helpers";
import withAuth from "../withAuth";
import AuthService from "../AuthService";
import {Button, Card, Checkbox} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
const Auth = new AuthService();

class UploadSDImage extends React.Component{
  state = {
    isLoading : false,
    preferred : false,
    description : '',
  };

  notifySuccess = (title) => toast(`Successfully uploaded SD Image ${title}`, { type: toast.TYPE.DEFAULT, autoClose: 15000 });
  notifyUploadFailure = () => toast(`Failed to upload file. Please refresh the page and try again.`, { type: toast.TYPE.ERROR, autoClose: 5000 });
  // notifyUploadFailureWithReason = (reason) => toast(`Failed to upload file. Server response: ${reason}`, { type: toast.TYPE.ERROR, autoClose: 5000 });
  componentDidMount(){
    document.title = 'Upload SD Image';
  }

  uploadSDImage = e => {
    e.preventDefault();

    const {
      sdImage,
      description,
      preferred,
      documentTitle
    } = this.state;


    const formData = new FormData();
    formData.append('upload', sdImage);
    formData.append('description', description);
    formData.append('preferred', preferred);

    this.setState({
      isLoading : true
    });
    Auth.file_fetch(`/api/sd-image`, {
      method : 'POST',
      body: formData
    }).then(data => {
      this.notifySuccess(documentTitle);
      this.setState({
        isLoading : false
      }, () => this.props.history.replace('/view/sd-image'));

      //() => this.props.history.replace('/view/firmware')
    }).catch(err => {
      this.notifyUploadFailure();
      Sentry.captureException(err);
      this.setState({
        isLoading: false
      });
    })
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    this.setState({ [name] : value});
  };

  handleFileChange = (selectorFiles) => {
    if(selectorFiles.length > 0) {
      this.setState({
        documentTitle: selectorFiles[0].name,
        sdImage: selectorFiles[0],
      })
    }
  };

  togglePreferred = () => {
    this.setState((prevState) => {
      return {preferred: !prevState.preferred};
    });
  };


  render() {
    const {
      isLoading
    } = this.state;
    const {t} = this.props;
    return (
		<Card fluid>
			{isLoading ? <SimpleLoader /> : null}
			<Card.Header style={{ margin: "10px" }}>
				<h2>{t("Upload SD Image")}</h2>
			</Card.Header>
			<Card.Content className="card-body">
				<div className="split-container even-split">
					<div className="wide-desc">
						<h3 className="orange-text">
							<Trans ns="translations" i18nKey="upload-sd-image.subheader">
								Upload a new SD image here
							</Trans>
						</h3>
						<p>
							<Trans ns="translations" i18nKey="upload-sd-image.1">
								<strong>Description</strong> is an optional field used to help describe the SD image to
								other members of Sterilis.
							</Trans>
						</p>
						<p className="important-left-margin">
							<Trans ns="translations" i18nKey="upload-sd-image.2">
								e.g <strong>'Grind improvements, cook time changes, bug fixes'</strong>
							</Trans>
						</p>
						<p>
							<Trans ns="translations" i18nKey="upload-sd-image.3">
								<strong>The file</strong> must be a <strong>.zip</strong>
							</Trans>
						</p>
						<p className="important-left-margin">
							e.g <strong>SterilisDevice01_SW_v2.4.5.zip</strong>
						</p>
					</div>
					<div className="wide-no-flex-items">
						<form id="uploadSoftwareForm" className="slight-padding-top" onSubmit={this.uploadSDImage}>
							<div className="form-group reduced-width">
								<label className={`fake-float`} htmlFor="uploadDocumentInput">
									{t("File")}
								</label>
								<input
									type="file"
									id="uploadFileInput"
									className="form-control"
									accept=".zip"
									onChange={(e) => this.handleFileChange(e.target.files)}
									required
								/>
							</div>
							<div className="form-group">
								<label className="fake-float">{t("Description")}</label>
								<textarea
									value={this.state.description}
									name="description"
									onChange={this.handleChange}
									id="descriptionTextArea"
									rows="5"
									className="form-control"
								/>
							</div>
							<div className="form-group">
								<Checkbox
									label={t("Preferred SD Image")}
									name="preferred"
									id="preferred"
									checked={this.state.preferred}
									onChange={this.togglePreferred}
								/>
							</div>
							<Button
								className="ster-btn float-right"
								value="Submit"
								type="submit"
								form="uploadSoftwareForm"
								id="uploadSoftwareBtn"
								// disabled={checksumValidationError || fileNameValidationError}
							>
								{t("Upload SD Image")}
							</Button>
						</form>
					</div>
				</div>
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(translate('translations'), withAuth(['SterilisSuperUsers','SterilisWasteTypeAdmin']))(UploadSDImage);
