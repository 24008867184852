/*
*  Main component for the View Devices page
*  TODO: remove this TODO after lunch and add linux version to this table
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {composeHoc, AuthReq} from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import { withRouter } from "react-router-dom";
import DeviceTable from "./DeviceTable";
import ResendActivationCodeModal from "./ResendActivationCodeModal";
import RetireDeviceModal from "./RetireDeviceModal";
import UnretireDeviceModal from "./UnretireDeviceModal";
import BanDeviceModal from "./BanDeviceModal";
import UnbanDeviceModal from "./UnbanDeviceModal";
import get from 'lodash/get';
import {Card, Dropdown, Icon, Popup} from "semantic-ui-react";
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";
import { Duration } from "luxon";

const Auth = new AuthService();

class ViewDevices extends React.Component {
  state = {
    isLoading: false,
    distributorDropdownArray: [{
      key: 0,
      value: 0,
      text: this.props.t('All Distributors'),
    }],
  };


  notifySuccess = (operation, subject, name) =>
    toast(`Successfully ${operation} ${subject} ${name}`, {type: toast.TYPE.DEFAULT, autoClose: 5000});

  notifyFailure = () => toast(`Operation failed. Please refresh this page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  componentDidMount() {
    document.title = 'View Devices';

    this.preparePage('?filter=customer');
  };

  preparePage = (queryString) => {
    this.setState({
      isLoading: true
    });
    return Auth.fetch(`/api/customer-machines${queryString}`, {
      method: 'GET',
    }).then(data => {
      const deviceArray = [];
      // take shallow copy of array in state to begin building distributor dropdown array
      const distributorDropdownArray = [...this.state.distributorDropdownArray];
      data.forEach(customer => {
        // Create an array of unique distributors
        if (distributorDropdownArray.some(elem => {
          return customer.distributor['id'] === elem['value']
        }) === false) {
          distributorDropdownArray.push(
            {
              key: customer.distributor['id'],
              value: customer.distributor['id'],
              text: customer.distributor['distributor_name'],
            }
          )
        }

        customer['facilities'].forEach(facility => {
          facility['device_configs'].forEach(device_config => {
            deviceArray.push({
              'comm_status': this.calculateTimeLastCommunicated(device_config.device.time_of_last_communication),
              'serial': <Link
                data-search={device_config.device.serial_number}
                target="_blank" id='linkToFindAMachine'
                to={`/find-a-machine?serial=${device_config.device.serial_number}`}>
                {device_config.device.serial_number}
              </Link>,
              // 'software_version' : get(device_config, 'current_firmware.firmware_version', null),
              'software_version': this.deviceConfigFirmware(device_config),
              'linux_version': get(device_config, 'device.linux_version', ''),
              'distributor_id': customer.distributor.id,
              'distributor': <Link
                target="_blank"
                data-search={customer.distributor.distributor_name}
                to={`/view/customers?name=${customer.distributor.distributor_name}`}>
                {customer.distributor.distributor_name}
              </Link>,
              'customer': <Link
                target="_blank"
                data-search={customer.customer_name}
                to={`/view/customers?name=${customer.customer_name}`}>
                {customer.customer_name}
              </Link>,
              'facility': <Link
                target="_blank"
                data-search={facility.facility_name}
                to={`/view/customers?name=${facility.facility_name}`}>
                {facility.facility_name}
              </Link>,
              'deleted_status': device_config.device.deleted,
              'activated_status': device_config.device.activated,
              'banned_status': device_config.device.banned,
              'actions': this.deviceActions(device_config, customer, facility),
            })
          });
        })
      });

      this.setState({
        isLoading: false,
        deviceArray,
        distributorDropdownArray,
        queryString
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.notifyFailure();
      this.setState({
        isLoading: false
      });
    });
  };

  deviceConfigFirmware = (deviceConfig) => {
    const currentFirmware = get(deviceConfig, 'current_firmware.firmware_version', 'No software assigned.');
    const targetFirmware = get(deviceConfig, 'target_firmware.firmware_version', 'No software assigned.');

    const currentPreferredIcon = this.generatePreferredFirmwareIcon(get(deviceConfig, 'current_firmware.preferred', null));
    const targetPreferredIcon = this.generatePreferredFirmwareIcon(get(deviceConfig, 'target_firmware.preferred', null));

    if (currentFirmware !== targetFirmware) {
      return <div data-search={`Target: ${targetFirmware} Current: ${currentFirmware}`}>
        {targetPreferredIcon}Target: {targetFirmware}
        <br/>
        {currentPreferredIcon}Current: {currentFirmware}
      </div>
    } else {
      return <div data-search={currentFirmware}>
        {currentPreferredIcon} {currentFirmware}
      </div>;
    }
  };

  generatePreferredFirmwareIcon = (preferred) => {
    if (preferred) {
      return <Popup
        trigger={<Icon className='green-icon' name='check circle'/>}
        content={<div>
          <p>This is preferred software.</p>
        </div>}
      />
    } else if (preferred === false) {
      return <Popup
        trigger={<Icon className='orange-icon' name='exclamation circle'/>}
        content={<div>
          <p>This is not preferred software.</p>
        </div>}
      />
    }
  };


  calculateTimeLastCommunicated = (timeLastCommunicated) => {
    if (timeLastCommunicated) {
        //Get rid of milliseconds
        const timeDelta = Date.now() / 1000 - Date.parse(timeLastCommunicated) / 1000;

        const iconStyle = { color: "rgb(41, 170, 23)", fontSize: "1.33333333em", paddingLeft: "8px" };
        const duration = Duration.fromObject({ seconds: timeDelta }).shiftTo("days", "hours", "minutes", "seconds");
        const formattedDelta = `${duration.days ? duration.days + " days" : ""} ${
            duration.hours ? duration.hours + " hours" : ""
        } ${duration.minutes ? duration.minutes + " minutes" : ""} ${
            duration.seconds ? Math.round(duration.seconds) + " seconds" : ""
        }.`;
        const iconTitle = `The machine has last communicated with the portal ${formattedDelta} ago.`;

        if (timeDelta < 300) {
            iconStyle.color = "rgb(41, 170, 23)";
            return (
                <details data-search={timeLastCommunicated} data-value={timeLastCommunicated}>
                    <summary>
                        <Icon title={iconTitle} style={iconStyle} name="check circle" />
                    </summary>
                    <p>
                        <strong>{formattedDelta}</strong>
                        <br />
                        since last
                        <br />
                        communication with
                        <br />
                        the portal.
                    </p>
                </details>
            );
        } else if (timeDelta > 300 && timeDelta < 86400) {
            iconStyle.color = "#ff8600";
            return (
                <details data-search={timeLastCommunicated} data-value={timeLastCommunicated}>
                    <summary>
                        <Icon title={iconTitle} style={iconStyle} name="minus circle" />
                    </summary>
                    <p>
                        <strong>{formattedDelta}</strong>
                        <br />
                        since last
                        <br />
                        communication with
                        <br />
                        the portal.
                    </p>
                </details>
            );
        } else {
            iconStyle.color = "rgb(214, 10, 10)";
            return (
                <details data-search={timeLastCommunicated} data-value={timeLastCommunicated}>
                    <summary>
                        <Icon title={iconTitle} style={iconStyle} name="remove circle" />
                    </summary>
                    <p>
                        <strong className="red">{formattedDelta}</strong>
                        <br />
                        since last
                        <br />
                        communication with
                        <br />
                        the portal.
                    </p>
                </details>
            );
        }
    }
  };


  resendActivationCodeItem = (device_config) => {
    const {t} = this.props;
    const timeDiffSecs = (Date.now() - new Date(device_config.device.reset_password_request_time)) / 1000;
    if (timeDiffSecs < 3000 && device_config.device.reset_password_key) {
      return <Dropdown.Item
        id={'resetPasswordKey-' + device_config['id']}
        onClick={() => this.triggerResendActivationCodeModal(device_config)}
        text={
          <div>
            <h5 className='green'>{t('Active initialization key')}: {device_config.device.reset_password_key}</h5>
            <h5>{t('Reset initialization key')}</h5>
          </div>
        }
      />
    } else {
      return <Dropdown.Item
        id={'resetPasswordKey-' + device_config['id']}
        onClick={() => this.triggerResendActivationCodeModal(device_config)}
        text={<h5>{t('Reset initialization key')}</h5>}
      />
    }
  };

  retireDeviceItem = (device_config) => {
    const {
      t,
    } = this.props;
    if (device_config.device.deleted) {
      return <Dropdown.Item
        id={'unretire-' + device_config['id']}
        onClick={() => this.triggerUnretireDeviceModal(device_config)}
        text={<h5>{t('Reactivate Device')}</h5>}
      />
    } else {
      return <Dropdown.Item
        id={'retire-' + device_config['id']}
        onClick={() => this.triggerRetireDeviceModal(device_config)}
        text={<h5>{t('Archive Device')}</h5>}
      />
    }
  };

  banDeviceItem = (device_config) => {
    const {
      t,
    } = this.props;
    if (device_config.device.banned) {
      return <Dropdown.Item
        id={'unbandevice-' + device_config['id']}
        onClick={() => this.triggerUnbanDeviceModal(device_config)}
        text={<h5>{t('Reactivate Device')}</h5>}
      />
    } else {
      return <Dropdown.Item
        id={'bandevice-' + device_config['id']}
        onClick={() => this.triggerBanDeviceModal(device_config)}
        text={<h5>{t('Suspend Device')}</h5>}
      />
    }
  };


  deviceActions = (device_config) => {
    const {
      t,
      group,
    } = this.props;
    return (
        <div className="overflow-visible-td">
            <Dropdown id={device_config["id"]} pointing="top right" icon="ellipsis horizontal">
                <Dropdown.Menu>
                    <Dropdown.Header content={<h4>{t("Device Actions")}</h4>} />
                    <Dropdown.Divider />
                    {/*{*/}
                    {/*device_config.device.activated === false && this.resendActivationCodeItem(device_config)*/}
                    {/*}*/}
                    <Dropdown.Item
                        id={"view-dashboard-" + device_config["id"]}
                        onClick={() => this.props.history.push(`/sa/dashboard/${device_config.id}`)}
                        text={<h5>{t("View Dashboard")}</h5>}
                    />

                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FactoryWorkers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        <Dropdown.Item
                            id={"assign-" + device_config["id"]}
                            onClick={() =>
                                this.props.history.push(
                                    `/assign/device-to-facility?device_config=${device_config["id"]}`
                                )
                            }
                            text={
                                <h5>
                                    <Trans ns="translations" i18nKey="dropdown_assign_device_to_facility">
                                        Assign device <br /> to a different facility
                                    </Trans>
                                </h5>
                            }
                        />
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FSEs",
                            "ExternalFSEs",
                            "FactoryWorkers",
                            "DistributorAdmins",
                            "DistributorFSEs",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        <Dropdown.Item
                            id={"assign-" + device_config["id"]}
                            onClick={() =>
                                this.props.history.push(
                                    `/assign/software-to-devices?device_config=${device_config["id"]}`
                                )
                            }
                            text={<h5>{t("Assign software to device")}</h5>}
                        />
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FactoryWorkers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {this.retireDeviceItem(device_config)}
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FSEs",
                            "ExternalFSEs",
                            "FactoryWorkers",
                            "DistributorAdmins",
                            "DistributorFSEs",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {device_config.device.deleted === null && this.resendActivationCodeItem(device_config)}
                    </AuthReq>
                    <AuthReq
                        userGroup={group}
                        requiredRoles={[
                            "SterilisSuperUsers",
                            "SterilisPortalUsers",
                            "FactoryWorkers",
                            "DistributorAdmins",
                            "SterilisWasteTypeAdmin",
                        ]}>
                        {device_config.device.deleted === null && this.banDeviceItem(device_config)}
                    </AuthReq>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
  };

  triggerRetireDeviceModal = (deviceConfig) => {
    this.setState((prevState) => {
      return {
        retireDeviceModal: !prevState.retireDeviceModal,
        modalSerial: get(deviceConfig, 'device.serial_number', null),
        modalDeviceID: get(deviceConfig, 'device.id', null),
        modalDeviceConfigID: get(deviceConfig, 'id', null),
      };
    });
  };


  triggerUnretireDeviceModal = (deviceConfig) => {
    this.setState((prevState) => {
      return {
        unretireDeviceModal: !prevState.unretireDeviceModal,
        modalSerial: get(deviceConfig, 'device.serial_number', null),
        modalDeviceID: get(deviceConfig, 'device.id', null),
      };
    });
  };

  triggerResendActivationCodeModal = (deviceConfig) => {
    this.setState((prevState) => {
      return {
        resendActivationCodeModal: !prevState.resendActivationCodeModal,
        modalSerial: get(deviceConfig, 'device.serial_number', null),
        modalDeviceID: get(deviceConfig, 'device.id', null),
        modalCustomerName: get(deviceConfig, 'facility.customer.customer_name', null),
        modalFacilityName: get(deviceConfig, 'facility.facility_name', null),
      };
    });
  };

  triggerBanDeviceModal = (deviceConfig) => {
    this.setState((prevState) => {
      return {
        showBanDeviceModal: !prevState.showBanDeviceModal,
        modalSerial: get(deviceConfig, 'device.serial_number', null),
        modalDeviceID: get(deviceConfig, 'device.id', null),
      };
    });
  };

  triggerUnbanDeviceModal = (deviceConfig) => {
    this.setState((prevState) => {
      return {
        showUnbanDeviceModal: !prevState.showUnbanDeviceModal,
        modalSerial: get(deviceConfig, 'device.serial_number', null),
        modalDeviceID: get(deviceConfig, 'device.id', null),
      };
    });
  };


  render() {
    const {
      isLoading,
      deviceArray,
      modalSerial,
      modalDeviceID,
      modalCustomerName,
      modalFacilityName,
      resendActivationCodeModal,
      retireDeviceModal,
      queryString,
      distributorDropdownArray,
      unretireDeviceModal,
      showBanDeviceModal,
      showUnbanDeviceModal,
    } = this.state;
    const {
      t,
      group,
    } = this.props;
    return (
		<Card fluid>
			<Card.Header style={{ margin: "10px" }}>
				<h2>{t("View Devices")}</h2>
			</Card.Header>
			{/*<ToastContainer />*/}
			<Card.Content className="card-body">
				<AuthReq
					userGroup={group}
					requiredRoles={[
						"SterilisSuperUsers",
						"SterilisPortalUsers",
						"FSEs",
						"ExternalFSEs",
						"FactoryWorkers",
						"DistributorAdmins",
						"DistributorFSEs",
						"SterilisWasteTypeAdmin",
					]}
				>
					{resendActivationCodeModal && (
						<ResendActivationCodeModal
							modalDeviceID={modalDeviceID}
							modalSerial={modalSerial}
							modalCustomerName={modalCustomerName}
							modalFacilityName={modalFacilityName}
							resendActivationCodeModal={resendActivationCodeModal}
							triggerResendActivationCodeModal={this.triggerResendActivationCodeModal}
							preparePage={this.preparePage}
							prepareParameter={queryString}
							fromTable={true}
							notifyFailure={this.notifyFailure}
						/>
					)}
				</AuthReq>
				<AuthReq
					userGroup={group}
					requiredRoles={[
						"SterilisSuperUsers",
						"SterilisPortalUsers",
						"DistributorAdmins",
						"SterilisWasteTypeAdmin",
					]}
				>
					{retireDeviceModal && (
						<RetireDeviceModal
							modalDeviceID={modalDeviceID}
							modalSerial={modalSerial}
							retireDeviceModal={retireDeviceModal}
							triggerRetireDeviceModal={this.triggerRetireDeviceModal}
							preparePage={this.preparePage}
							prepareParameter={queryString}
							notifySuccess={this.notifySuccess}
							notifyFailure={this.notifyFailure}
							modalDeviceConfigID={this.state.modalDeviceConfigID}
						/>
					)}
				</AuthReq>
				<AuthReq
					userGroup={group}
					requiredRoles={[
						"SterilisSuperUsers",
						"SterilisPortalUsers",
						"DistributorAdmins",
						"SterilisWasteTypeAdmin",
					]}
				>
					{unretireDeviceModal && (
						<UnretireDeviceModal
							modalDeviceID={modalDeviceID}
							modalSerial={modalSerial}
							unretireDeviceModal={unretireDeviceModal}
							triggerUnretireDeviceModal={this.triggerUnretireDeviceModal}
							preparePage={this.preparePage}
							prepareParameter={queryString}
							notifySuccess={this.notifySuccess}
							notifyFailure={this.notifyFailure}
						/>
					)}
				</AuthReq>
				<AuthReq
					userGroup={group}
					requiredRoles={[
						"SterilisSuperUsers",
						"SterilisPortalUsers",
						"DistributorAdmins",
						"SterilisWasteTypeAdmin",
					]}
				>
					{showBanDeviceModal && (
						<BanDeviceModal
							modalDeviceID={modalDeviceID}
							modalSerial={modalSerial}
							showBanDeviceModal={showBanDeviceModal}
							triggerBanDeviceModal={this.triggerBanDeviceModal}
							preparePage={this.preparePage}
							prepareParameter={queryString}
							notifySuccess={this.notifySuccess}
							notifyFailure={this.notifyFailure}
						/>
					)}
				</AuthReq>
				<AuthReq
					userGroup={group}
					requiredRoles={[
						"SterilisSuperUsers",
						"SterilisPortalUsers",
						"DistributorAdmins",
						"SterilisWasteTypeAdmin",
					]}
				>
					{showUnbanDeviceModal && (
						<UnbanDeviceModal
							modalDeviceID={modalDeviceID}
							modalSerial={modalSerial}
							showUnbanDeviceModal={showUnbanDeviceModal}
							triggerUnbanDeviceModal={this.triggerUnbanDeviceModal}
							preparePage={this.preparePage}
							prepareParameter={queryString}
							notifySuccess={this.notifySuccess}
							notifyFailure={this.notifyFailure}
						/>
					)}
				</AuthReq>
				<AuthReq
					userGroup={group}
					requiredRoles={[
						"SterilisSuperUsers",
						"SterilisPortalUsers",
						"FactoryWorkers",
						"SterilisWasteTypeAdmin",
					]}
				>
					<Link id="createDeviceLink" to="/create/device">
						{t("Create a new device")}
					</Link>
				</AuthReq>
				<DeviceTable
					preparePage={this.preparePage}
					deviceArray={deviceArray}
					isLoading={isLoading}
					group={group}
					distributorDropdownArray={distributorDropdownArray}
				/>
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(withRouter, translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
    'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin'], 'internalPage'))(ViewDevices);
