import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { setCustomer, setPermissionGroup, setUserTypeDisplay } from "src/features/User/Create/createUserSlice";
import { useTranslation } from "react-i18next";

const FormAutocomplete = ({ control, options, label, name, errors, rules, ...rest }) => {
    const dispatch = useDispatch();
    const { setValue, ...other } = rest;
    const { t } = useTranslation("translations");
    return (
        <Controller
            rules={rules}
            render={({ name, value, onChange, ref }) => (
                <Autocomplete
                    fullWidth
                    id={name}
                    ref={ref}
                    options={options}
                    defaultValue={null}
                    value={value}
                    {...other}
                    onChange={(e, v) => {
                        if (name === "user.permission") {
                            dispatch(setPermissionGroup(v));
                            dispatch(setUserTypeDisplay(t(v.label)));
                            if (v.value === "FactoryWorkers") {
                                dispatch(
                                    setCustomer({ id: 1, name: "Sterilis Factory", dist_name: "Sterilis", dist_id: 1 })
                                );
                            }
                        }
                        if (name === "employee.customer") {
                            setValue("employee.facility_ids", []);
                            dispatch(setCustomer(v));
                        }
                        return onChange(v);
                    }}
                    getOptionLabel={(option) => option.label || option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            name={name}
                            error={!!errors}
                            helperText={errors ? errors.message : null}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                        />
                    )}
                />
            )}
            name={name}
            control={control}
        />
    );
};

FormAutocomplete.propTypes = {};

export default FormAutocomplete;
