/*
*  Region setting table
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {isGroupSuperUser} from "../../library/helpers";
import HookTable from "../../library/HookTable";

const RegionSettingsTable = props => {
  const {
    t
  } = props;
  const columns = [
    {
      Header: t('Region'),
      accessor: 'region_name',
    },
    {
      Header: t('Country'),
      accessor: 'country',
      manualWidth: 70,
    },
    {
      Header: t('Biochallenge Type'),
      accessor: 'biochallenge_type',
    },
    {
      Header: t('Interval Duration'),
      accessor: 'bio_interval_duration',
    },
    {
      Header: t('Regulated cook time (mins)'),
      accessor: 'min_cook_time',
    },
    {
      Header: `${t('Regulated cook temp')}. (C°)`,
      accessor: 'min_cook_temp',
    },
    {
      Header: t('Timezone'),
      accessor: 'timezone',
    },
  ];

  // if they're a super user, include the actions column
  isGroupSuperUser(props.group) && columns.push({
    Header: t('Actions'),
    accessor: 'actions',
    manualWidth: 60,
  });

  return (
    <HookTable
      id='regionSettingsTable'
      data={props.regionSettingTableArray}
      columns={columns}
      tableName='regionSettingsTable'
      defaultSortBy={{id: 'region_name'}}
    />
  )
};
export default translate('translations')(RegionSettingsTable);
