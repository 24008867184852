/*
*  Component which allows Device Operators to be upgraded to Site Admins
*   Requires an XHR call on click to see if  a device operator is a Site Admin already or not.
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "../AuthService";
import SimpleLoader from "../SimpleLoader";
import debounce from "lodash/debounce";
import { prettyGroupName } from '../library/helpers';
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class UpgradeOperatorModal extends React.Component {
  state = {
    allowModalToBeClosed: true,
    portalUserAlreadyCreated: false,
    portalUserUsername: null,
    username: '',
    operatorBeingUpdatedEmail: '',
    FacilityAdminRole: false,
    portalUserID: null,
    userRole: ''
  };

  componentDidMount() {
    this.setState({
      operatorBeingUpdated: this.props.operatorBeingUpdated,
      operatorBeingUpdatedEmail: this.props.operatorBeingUpdated['employee']['email_address'],
      modalLoading: true
    }, () => {
      const {
        operatorBeingUpdated
      } = this.state;
      Auth.fetch(`/api/portal-user/?employee_id=${operatorBeingUpdated['employee']['id']}`)
        .then(data => {
          if (data.length > 0) {
            this.setState({
              portalUserAlreadyCreated: true,
              portalUserUsername: data[0]['username'],
              portalUserID: data[0]['id'],
              FacilityAdminRole: data[0].groups[0].name === "FacilityAdmin" ? true : false,
              userRole: prettyGroupName(data[0].groups[0].name),
              modalLoading: false
            })
          } else {
            this.setState({
              modalLoading: false
            })
          }
        }).catch(err => {
        Sentry.captureException(err);
      });
    })
  }

  upgradeOperator = (e) => {
    e.preventDefault();
    const {
      operatorBeingUpdated,
      username
    } = this.state;

    const putObjEmployee = {
      'upgradeOperator': true,
    };

    const employeeID = operatorBeingUpdated['employee']['id']

    const portalUser = {
      employee_id: operatorBeingUpdated['employee']['id'],
      username: username,
      is_active: false,
      group: 'CustomerPortalUsers'
    };
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false
    });
    Auth.fetch(`/api/employee/${employeeID}/`, {
      method: 'PATCH',
      body: JSON.stringify(putObjEmployee)
    }).then(data => {
      Auth.fetch(`/api/portal-user/`, {
        method: 'POST',
        body: JSON.stringify(portalUser)
      }).then(data => {
        this.props.fetchSiteAdmins().then(() => {
          this.props.fetchDeviceOperators().then(() => {
            this.setState({
              modalLoading: false,
              allowModalToBeClosed: true
            }, () => {
              this.props.notifyUpgradeSuccess(data['employee']['full_name']);
              this.props.toggleUpgradeOperatorModal(null, null);
            });

          });
        });
      }).catch(err => {
        Sentry.captureException(err);
        this.setState({
          modalLoading: false,
          allowModalToBeClosed: true
        }, () => {
          this.props.notifyUpgradeFailure(operatorBeingUpdated['employee']['full_name']);
          this.props.toggleUpgradeOperatorModal(null, null);
        });

      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
          modalLoading: false,
          allowModalToBeClosed: true
      }, () => {
          this.props.notifyUpgradeFailure(operatorBeingUpdated['employee']['full_name']);
          this.props.toggleUpgradeOperatorModal(null, null);
      });

    });
  };

  debouncedCheckIfUsernameTaken = debounce((username) => this.checkIfUsernameTaken(username), 350);

  checkIfUsernameTaken = (username) => {
    // If the user is submitting a duplicate username really quickly and they try to submit the
    // form before the check username call finishes, it'll crash because the username will not be unique
    // We'll just disable the form submit through checkingUsername to prevent this bad UX
    this.setState({
      checkingUsername: true
    });
    Auth.fetch(`/api/username-available/`, {
      method: 'POST',
      body: JSON.stringify({
        'username': username
      })
    }).then(data => {
      if (data['available'] === 1) {
        this.setState({
          usernameValidationError: false,
          checkingUsername: false,
        })
      } else {
        this.shakeUsernameSpan();
        this.setState({
          usernameValidationError: true,
          checkingUsername: false,
        })
      }
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        checkingUsername: false,
      })
    });
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (name === 'username') {
      if (/^[a-zA-Z0-9_-]*$/i.test(value)) {
        this.debouncedCheckIfUsernameTaken(value);
      } else {
        this.shakeUsernameSpan();
        this.setState({
          usernameValidationError: true
        })
      }
    }

    this.setState({[name]: value});
  };

  shakeUsernameSpan = () => {
    this.setState({
      setUsernameShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setUsernameShake: false,
        })
      }, 1000)
    })
  };

  upgradeFacilityAdmin = () => {
    const {
        portalUserID
      } = this.state;
  
      const employeeID = this.props.operatorBeingUpdated['employee']['id']
  
      const portaluserID = portalUserID
  
      const groups = 'CustomerPortalUsers'
      const groupname = 'Site Admin'
  
      const putObjEmployee = {
          'facility_ids': [],
          'upgradeOperator': true
        };
  
      const portalUser = {
        'group': groups,
        'upgradeUser': true
      };
  
      this.setState({
        modalLoading: true,
        allowModalToBeClosed: false
      });
      Auth.fetch(`/api/employee/${employeeID}/`, {
          method: 'PATCH',
          body: JSON.stringify(putObjEmployee)
        }).then(data => {
          Auth.fetch(`/api/portal-user/${portaluserID}`, {
          method: 'PATCH',
          body: JSON.stringify(portalUser)
          }).then(data => {
                this.props.fetchDeviceOperators().then(() => {
                  this.props.fetchSiteAdmins();
                  this.props.refreshSiteAdmins();
                  this.setState({
                      modalLoading: false,
                      allowModalToBeClosed: true
                  }, () => {
                      this.props.notifyGenericSuccess("changed", "to", groupname);
                      this.props.toggleUpgradeOperatorModal(null, null);
                  });
      
                  });
          }).catch(err => {
          Sentry.captureException(err);
          this.setState({
            modalLoading: false,
              allowModalToBeClosed: true
          }, () => {
              this.props.notifyGenericFailure("change", "to", groupname);
              this.props.toggleUpgradeOperatorModal(null, null);
          });
  
          });
      }).catch(err => {
          Sentry.captureException(err);
          this.setState({
            modalLoading: false,
              allowModalToBeClosed: true
          }, () => {
              this.props.notifyGenericFailure("change", "to", groupname);
              this.props.toggleUpgradeOperatorModal(null, null);
          });
  
          });
    }

  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading,
      usernameValidationError,
      setUsernameShake,
      checkingUsername,
      operatorBeingUpdatedEmail,
      portalUserUsername,
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.showUpgradeOperatorModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='small'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.toggleUpgradeOperatorModal(null, null)
          }}
        >
          <Header>
            <h2>{t('Upgrade Operator to Site Admin')}</h2>
          </Header>
          <Modal.Content>
            {modalLoading ? (
              <SimpleLoader/>
            ) : null}
            <form id='upgradeOperatorForm'
                  onSubmit={this.upgradeOperator}
            >
              <div>
                {this.state.portalUserAlreadyCreated ?
                  <div className='desc customer'>
                    <div className="desc-text">
                      {!this.state.FacilityAdminRole ?
                      t(`A ${this.state.userRole} account already exists for this device operator`)
                      :
                      <div className='label-validation-error desc-text shake'>
                        {t('A Facility Admin account already exists for this device operator. Do you wish to upgrade Facility Admin to Site Admin?')}
                      </div>
                      }.
                    </div>
                    <div className="desc-text">
                      <Trans ns="translations" i18nKey="upgrade_operator_site_admin_exists">
                        The username is <strong>{{portalUserUsername}}</strong>
                      </Trans>
                    </div>
                  </div> :
                  <React.Fragment>
                    <div className='split-container'>
                      <div className='desc customer'>
                        <div
                          className={(usernameValidationError ? 'label-validation-error desc-text' : 'desc-text') + (setUsernameShake ? ' shake' : '')}>
                          {t('create-site-admin.7', 'Usernames must be unique. They also must be alphanumeric. - and _ are allowed')}
                        </div>
                        <div className="desc-text">
                          <Trans ns="translations" i18nKey="upgrade_operator_activation_email">
                            An activation email will be sent to: <strong>{{operatorBeingUpdatedEmail}}</strong>
                          </Trans>
                        </div>
                      </div>
                      <div className='items'>
                        <div className="form-group">
                          <input value={this.state.username}
                                 name='username'
                                 onChange={this.handleChange}
                                 type="text"
                                 id="usernameInput"
                                 className="form-control"
                                 pattern="^[a-zA-Z0-9_-]*$"
                                 title="Usernames must be alphanumeric. - and _ are allowed"
                                 required/>¬
                          <label
                            className={`${usernameValidationError ? 'float-label-invalid' : ''} form-control-placeholder`}
                            htmlFor="usernameInput">{t('Username')}</label>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions style={{'min-height': '65px'}}>
            {this.state.portalUserAlreadyCreated === false &&
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='upgradeOperatorForm'
                    disabled={usernameValidationError || checkingUsername}
            >{t('Create Site Admin')}</Button>
            }
            {this.state.portalUserAlreadyCreated === true && this.state.FacilityAdminRole === true &&
            <Button className='ster-btn'
                    type="submit"
                    onClick={() => this.upgradeFacilityAdmin()} 
            >{t('Upgrade to Site Admin')}</Button>
            }
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(UpgradeOperatorModal);
