/*
*  Main component for the View Region Settings table page
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import {composeHoc, capitalizeFirstLetter, capitalizeFullString, isGroupSuperUser} from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import {Card, Dropdown} from "semantic-ui-react";
import RegionSettingsTable from "./RegionSettingsTable";
import EditRegionSettingsModal from "./EditRegionSettingsModal";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import { toast } from "react-toastify";
const Auth = new AuthService();

class ViewRegionSettings extends React.Component{
  state = {
    isLoading : false,
    editRegionModal : false,
  };
  notifySuccess = (regionName) => toast(`Successfully changed ${regionName}'s details.`,
    { type: toast.TYPE.DEFAULT, autoClose: 5000 });

  notifyFailure = () => toast(`Failed to save region setting changes. Please refresh this page and try again later.`,
    { type: toast.TYPE.ERROR, autoClose: 5000 });

  componentDidMount(){
    document.title = 'View Region Settings';
    this.fetchRegionSettings()
  }

  fetchRegionSettings = () => {
    this.setState({isLoading: true});
    Auth.fetch(`/api/region-setting/`, {
      method : 'GET',
    }).then(data => {
      const regionSettingTableArray = data.map(region=> {
        return {
          'region_name': region['region_name'],
          'country': capitalizeFullString(region['country']),
          'biochallenge_type': capitalizeFirstLetter(region['bio_interval_type']),
          'bio_interval_duration' : this.biochallengeDuration(region['bio_interval_duration']),
          'min_cook_time' : region['min_cook_time'],
          'min_cook_temp' : region['min_cook_temp'],
          'timezone' : region['timezone']['timezone'],
          'actions' : isGroupSuperUser(this.props.group) ? this.regionSettingsActions(region) : null,
        }
      });
      this.setState({
        isLoading: false,
        regionSettingTableArray
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        isLoading : false,
      })
    });
  };

  biochallengeDuration = (duration) => {
    // The choice fields send back a string, we need to cast it to a number so that moment.js can use it
    const numericDuration = Number(duration);
    const momentDuration = moment.duration(numericDuration, 'seconds');
    // 500k is random, I just wanted usage/low duration regions to have hours instead of months
    if(numericDuration < 500000){
      return `${momentDuration.asHours()} hours`;
    }else{
      const humanizeDuration = moment.duration(numericDuration, 'seconds').humanize();
      // Probably not the best way to do this but I really don't like the "a month" the humanize generates
      // 1 month >>> a month
      if(humanizeDuration === 'a month'){
        return '1 month'
      }else{
        return humanizeDuration;
      }
    }
  };

  regionSettingsActions = (region) => {
    const {
      t
    } = this.props;
    return (
      <Dropdown pointing='top right' icon='ellipsis horizontal'>
        <Dropdown.Menu>
          <Dropdown.Header content={<h4>{t('Region Actions')}</h4>}/>
          <Dropdown.Divider />
          <Dropdown.Item id={'edit-'+ region['id']}
                         onClick={() => this.triggerEditRegionModal(region)}
                         text={<h5>{t('Edit region')}</h5>}
          />
        </Dropdown.Menu>
      </Dropdown>
    )
  };

  triggerEditRegionModal = (region) => {
    this.setState((prevState) => {
      return {
        editRegionModal: !prevState.editRegionModal,
        regionBeingEdited: region,
      };
    });
  };

  render() {
    const {
      isLoading,
      regionSettingTableArray,
      editRegionModal,
      regionBeingEdited
    } = this.state;
    const {t} = this.props;
    return (
		<Card fluid>
			{isLoading ? <SimpleLoader /> : null}
			<Card.Header style={{ margin: "10px" }}>
				<h2>{t("View Region Settings")}</h2>
			</Card.Header>
			<Card.Content className="card-body">
				<RegionSettingsTable
					className="slight-margin-top"
					regionSettingTableArray={regionSettingTableArray}
					group={this.props.group}
				/>

				{isGroupSuperUser(this.props.group) && editRegionModal ? (
					<EditRegionSettingsModal
						editRegionModal={editRegionModal}
						regionBeingEdited={regionBeingEdited}
						triggerEditRegionModal={this.triggerEditRegionModal}
						fetchRegionSettings={this.fetchRegionSettings}
						notifySuccess={this.notifySuccess}
						notifyFailure={this.notifyFailure}
					/>
				) : null}
			</Card.Content>
		</Card>
	);
  }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs',
      'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin'], 'internalPage'))(ViewRegionSettings);
