/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import {Link} from "react-router-dom";
import AuthService from "../../../AuthService";
import SimpleLoader from "../../../SimpleLoader";
import * as Sentry from "@sentry/browser";
import CycleReportWarning from "./CycleReportWarning";
const Auth = new AuthService();

class RetireFacilityModal extends React.Component{
  state = {
    facilityAbleToBeRetired : true,
    allowModalToBeClosed: true,
  };
  componentDidMount(){
    const {
      facility
    } = this.props;

    // Facilities can only be retired if there are no active device_configs underneath it
    const facilityAbleToBeRetired = facility['device_configs'].length <= 0;

    this.setState({
      facilityAbleToBeRetired,
      facilityDevices : facility['device_configs'],
    })

  }

  retireFacility = () => {
    const {
      facility
    } = this.props;
    this.setState({
      modalLoading : true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/facility/${facility['id']}/`, {
      method : 'DELETE',
    }).then(() => {

     this.setState({
       modalLoading : false,
       allowModalToBeClosed: true,
     }, () => {
       this.props.preparePage();
       this.props.triggerRetireFacilityModal(null);
       this.props.notifySuccess('archived', 'facility', facility['facility_name'])
     });
    }).catch(err=> {
      Sentry.captureException(err);
      this.setState({
         modalLoading : false,
        allowModalToBeClosed: true,
       }, () => {
        this.props.triggerRetireFacilityModal();
        this.props.notifyFailure()
       });
    });
  };

  render() {
    const {
      t,
      facility,
      customer,
    } = this.props;

    const {
      facilityAbleToBeRetired,
      facilityDevices,
      modalLoading,
    } = this.state;

    const facilityName = facility['facility_name'];

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
          open={this.props.retireFacilityModal}
          onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
          transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerRetireFacilityModal(null)
          }}
        >
          <Header>
            <h2 className='grey-text'>{t('Archive Facility')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            {
              facilityAbleToBeRetired ?
                <div className='short-modal-text center-text'>
                  <p>
                    <Trans ns="translations" i18nKey="archive-facility.1">
                      Are you sure you want to archive <strong>{{facilityName}}</strong>?
                    </Trans>
                  </p>
                  <p>
                    {t('archive-facility.2', 'Devices will no longer be able to be assigned to this facility.')}
                  </p>
                  <CycleReportWarning
                    action={t('archive')}
                    actionPastTense={t('archived')}
                    noun={t('facility')}
                    name={customer['customer_name']}
                    customerID={customer['id']}
                  />
                </div> :
                <div className='short-modal-text center-text'>
                  <p>{t('archive-facility.cannot_archive',
                    'This facility cannot be archived yet. It has active devices assigned to it. Please move the following devices to a different facility')}:</p>
                  <ul className="facility-serials">
                    {facilityDevices.map((deviceConfig) => {
                      return (
                        <li key={deviceConfig['id']}>
                          <Link target="_blank" to={`/find-a-machine/${deviceConfig['id']}`}>
                            {deviceConfig['device']['serial_number']}
                          </Link>
                        </li>
                      )})
                    }
                  </ul>
                </div>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn grey-btn'
                    value="Submit"
                    type="submit"
                    id='retireFacility'
                    disabled={!facilityAbleToBeRetired}
                    onClick={this.retireFacility}
                    >{t('Archive Facility')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(RetireFacilityModal);
