import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import React from "react";
import AuthService from "src/components/AuthService";

const auth = new AuthService();

const initialState = {
    operators: [],
    operatorCount: 0,
    loading: false,
};

const operatorSlice = createSlice({
    name: "operator",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeviceOperators.pending, (state, action) => {
                state.loading = true;
                state.operators = initialState.operators;
            })
            .addCase(fetchDeviceOperators.fulfilled, (state, action) => {
                state.loading = false;
                state.operators = action.payload;
                state.operatorCount = action.payload.lenth;
            })
            .addCase(fetchDeviceOperators.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const fetchDeviceOperators = createAsyncThunk("operator/fetchDeviceOperators", async () => {
    const response = await auth.fetch(`/api/device-operator/`, { method: "GET" });
    return response;
});

const getNotificationLabel = (operator) => {
    const { notify_complete, notify_error } = operator;
    if (notify_complete && notify_error) {
        return "Completion & Error";
    } else if (notify_complete) {
        return "Completion";
    } else if (notify_error) {
        return "Error";
    } else {
        return "None";
    }
};

export const {} = operatorSlice.actions;

export default operatorSlice.reducer;
