/*
*  This is the component for the medium sized dashboard (width < 1200 && width > 980)
*
*  It will have 2x2 charts, with the cycle table at the bottom of the 2x2
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import '../../css/dashboard/DashboardMain.css';
import PressureTemperatureGraph from "./PressureTemperatureGraph";
import CycleSummary from "./CycleSummary";
import UserPieChart from "./UserPieChart";
import DashboardNav from "./DashboardNav";
import BiochallengeChart from "./BiochallengeChart";
import DashboardCycleTable from "./DashboardCycleTable";
import WasteProcessedSummary from "./WasteProcessedSummary";

const DashboardMedium = props => (
  <div className='customer-container'>
    <DashboardNav
      toDateClick={props.toDateClick}
      fromDateClick={props.fromDateClick}
      fromDate={props.fromDate}
      toDate={props.toDate}
      prepareDashboard={props.prepareDashboard}
      customerMachines={props.customerMachines}
      selectDevice={props.selectDevice}
      deviceConfigID={props.deviceConfigID}
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
      filterCustomerDevices={props.filterCustomerDevices}
    />
    <div className="flex-row">
      <CycleSummary
        graphContainerClass='graph-container--medium'
        className='flex-item'
        dashboardResized={true}
        deviceCycles={props.deviceCycles}
        userTimezone={props.userTimezone}
        deviceFacility={props.deviceFacility}
      />
      <UserPieChart
        graphContainerClass='graph-container--medium'
        className='flex-item'
        dashboardResized={true}
        userTimezone={props.userTimezone}
        deviceCycles={props.deviceCycles}

      />
    </div>
    <div className="flex-row">
      <WasteProcessedSummary
        graphContainerClass='graph-container--medium'
        className='flex-item'
        dashboardResized={true}
        deviceCycles={props.deviceCycles}
        userTimezone={props.userTimezone}
        deviceFacility={props.deviceFacility}
        weight_unit={props.weight_unit}
      />
      <PressureTemperatureGraph
        graphContainerClass='graph-container--medium'
        className='flex-item'
        dashboardResized={true}
        cycleEvents={props.cycleEvents}
        cycleMetadata={props.cycleMetadata}
        userTimezone={props.userTimezone}
        deviceTimezone={props.deviceTimezone}
        setCurrentPressureUnit={props.setCurrentPressureUnit}
        currentPressureUnit={props.currentPressureUnit}
        deviceSerial={props.deviceSerial}

      />
    </div>
    <div className="flex-row">
    {props.biochallengeInfo['is_airport'] ?
        null :
        <BiochallengeChart
          graphContainerClass='graph-container--medium dashboard-container'
          className='flex-item'
          dashboardResized={true}
          biochallengeInfo={props.biochallengeInfo}
          userTimezone={props.userTimezone}
          deviceTimezone={props.deviceTimezone}

        />
      }
    </div>
    <div className="flex-row">
      <DashboardCycleTable
        dashboardSize='medium'
        deviceConfigID={props.deviceConfigID}
        deviceCycles={props.deviceCycles}
        className='flex-item'
        userTimezone={props.userTimezone}
        deviceTimezone={props.deviceTimezone}
        fetchCycleEvents={props.fetchCycleEvents}
        complianceReport={props.complianceReport}
        comprehensiveReport={props.comprehensiveReport}
        setComprehensiveReport={props.setComprehensiveReport}
        setComplianceReport={props.setComplianceReport}
        expandCycleTable={props.expandCycleTable}
        fromDate={props.fromDate}
        toDate={props.toDate}
        deviceFacilityFullAddress={props.deviceFacilityFullAddress}
        isAirport={props.biochallengeInfo['is_airport']}
        deviceSerial={props.deviceSerial}
        currentPressureUnit={props.currentPressureUnit}
      />
    </div>
  </div>
);

export default translate('translations')(DashboardMedium);
