import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { setCustomer } from "src/features/User/Create/createUserSlice";

const FormCheckbox = ({ control, name, rules, ...rest }) => {
    const dispatch = useDispatch();
    return (
        <Controller
            rules={rules}
            render={({ name, value, onChange, ref }) => (
                <Checkbox
                    name={name}
                    ref={ref}
                    value={value}
                    onChange={(e) => {
                        return onChange(e.target.checked);
                    }}
                    {...rest}
                />
            )}
            name={name}
            control={control}
        />
    );
};

FormCheckbox.propTypes = {};

export default FormCheckbox;
