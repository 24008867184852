/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import SimpleLoader from "../../../SimpleLoader";
import AuthService from "../../../AuthService";
import CycleReportWarning from "./CycleReportWarning";

const Auth = new AuthService();

class BanCustomerModal extends React.Component {
  state = {
    modalLoading: false,
    allowModalToBeClosed: true,
  };
  banCustomer = () => {
    const {
      customer
    } = this.props;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    const customerID = customer['id'];

    Auth.fetch(`/api/customer/${customerID}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        banned: true
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.preparePage();
        this.props.triggerBanCustomerModal(null);
        this.props.notifySuccess('suspended', 'customer', data['customer_name'])
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerBanCustomerModal(null);
        this.props.notifyFailure()
      });
    });
  };

  render() {
    const {
      t,
      customer
    } = this.props;
    const {
      modalLoading
    } = this.state;
    const customerName = customer['customer_name'];

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.banCustomerModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerBanCustomerModal(null)
          }}
        >
          <Header>
            <h2 className='red-text'>{t('Suspend Customer')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            <div className='short-modal-text center-text'>
              <p>
                <Trans ns="translations" i18nKey="suspend-customer.1">
                  Are you sure you want to suspend <strong>{{customerName}}</strong>?
                </Trans>
              </p>
              <p>
                {t('suspend-customer.2',
                  'Their devices will no longer be able to communicate with the portal, and their users will no longer be able to log onto the portal.')}
              </p>
              <CycleReportWarning
                action={t('suspend')}
                actionPastTense={t('suspended')}
                noun={t('customer')}
                name={customerName}
                customerID={customer['id']}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='red-btn'
                    value="Submit"
                    type="submit"
                    form='editCustomerForm'
                    id='editCustomerBtn'
                    onClick={this.banCustomer}
            >{t('Suspend Customer')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(BanCustomerModal);
