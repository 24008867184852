import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { changelog } from "./changelog";
import { AuthReq } from "src/components/library/helpers";
import { UserContext } from "src/components/library/UserContext";

const sterilisUserRoles = ["SterilisSuperUsers", "SterilisPortalUsers", "SterilisWasteTypeAdmin"];

const V223ReleaseNotes = (props) => {
    localStorage.setItem("seen_release_note", "v2.2.3");
    const userContext = useContext(UserContext);
    // @ts-ignore
    const { group } = userContext;
    const currentUserRoles = [];
    currentUserRoles.push(group);

    return (
        <Accordion>
            <AccordionSummary id="2.2.3" expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h4" fontWeight="bold" color="secondary">
                    v2.2.3
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {changelog.some((change) => change.type === "new") ? (
                    <>
                        <Typography variant="h5" p={2} fontWeight="bold" color="primary">
                            {props.t("New Features")}
                        </Typography>
                        <Divider />
                    </>
                ) : null}
                <List>
                    {changelog
                        .filter((change) => {
                            return change.type === "new";
                        })
                        .map((change) => {
                            return (
                                <AuthReq
                                    userGroup={group}
                                    requiredRoles={
                                        change.audience === "sterilis" ? sterilisUserRoles : currentUserRoles
                                    }>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ChevronRightTwoToneIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{ fontWeight: "bold" }}
                                            primary={change.desc}
                                            secondary={change.details ? change.details : null}
                                        />
                                    </ListItem>
                                    {change.images.length > 0 ? (
                                        <ImageList sx={{ pl: 10, mb: 2 }}>
                                            {change.images.map((image) => {
                                                return (
                                                    <ImageListItem key={image.id}>
                                                        <img src={image.src} alt={image.title} loading="lazy" />
                                                        <ImageListItemBar title={image.title} />
                                                    </ImageListItem>
                                                );
                                            })}
                                        </ImageList>
                                    ) : null}
                                    <Divider variant="inset" />
                                </AuthReq>
                            );
                        })}
                </List>
                {changelog.some((change) => change.type === "chg") ? (
                    <>
                        <Typography variant="h5" p={2} fontWeight="bold" color="primary">
                            {props.t("Updates")}
                        </Typography>
                        <Divider />
                    </>
                ) : null}
                <List>
                    {changelog
                        .filter((change) => {
                            return change.type === "chg";
                        })
                        .map((change) => {
                            return (
                                <AuthReq
                                    userGroup={group}
                                    requiredRoles={
                                        change.audience === "sterilis" ? sterilisUserRoles : currentUserRoles
                                    }>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ChevronRightTwoToneIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{ fontWeight: "bold" }}
                                            primary={change.desc}
                                            secondary={change.details ? change.details : null}
                                        />
                                    </ListItem>
                                    {change.images.length > 0 ? (
                                        <ImageList sx={{ pl: 10, mb: 2 }}>
                                            {change.images.map((image) => {
                                                return (
                                                    <ImageListItem key={image.id}>
                                                        <img src={image.src} alt={image.title} loading="lazy" />
                                                        <ImageListItemBar title={image.title} />
                                                    </ImageListItem>
                                                );
                                            })}
                                        </ImageList>
                                    ) : null}
                                    <Divider variant="inset" />
                                </AuthReq>
                            );
                        })}
                </List>
                {changelog.some((change) => change.type === "fix") ? (
                    <>
                        <Typography variant="h5" p={2} fontWeight="bold" color="primary">
                            {props.t("Bug Fixes")}
                        </Typography>
                        <Divider />
                    </>
                ) : null}
                <List>
                    {changelog
                        .filter((change) => {
                            return change.type === "fix";
                        })
                        .map((change) => {
                            return (
                                <AuthReq
                                    userGroup={group}
                                    requiredRoles={
                                        change.audience === "sterilis" ? sterilisUserRoles : currentUserRoles
                                    }>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ChevronRightTwoToneIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{ fontWeight: "bold" }}
                                            primary={change.desc}
                                            secondary={change.details ? change.details : null}
                                        />
                                    </ListItem>
                                    {change.images.length > 0 ? (
                                        <ImageList sx={{ pl: 10, mb: 2 }}>
                                            {change.images.map((image) => {
                                                return (
                                                    <ImageListItem key={image.id}>
                                                        <img src={image.src} alt={image.title} loading="lazy" />
                                                        <ImageListItemBar title={image.title} />
                                                    </ImageListItem>
                                                );
                                            })}
                                        </ImageList>
                                    ) : null}
                                    <Divider variant="inset" />
                                </AuthReq>
                            );
                        })}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

V223ReleaseNotes.propTypes = {
    t: PropTypes.func,
};

export default V223ReleaseNotes;
