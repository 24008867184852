/*
*  The Active User table, present in the Portal Status & Sterilis Homepage
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import moment from "moment";
import {timestampSortHook} from "../library/helpers";
import SimpleLoader from "../SimpleLoader";
import HookTable from "../library/HookTable";

const ActiveUsersTable = props => {
  
  const activeUserColumns = [
    {
      Header: props.t('Username'),
      accessor: 'username',
    },
    {
      Header: props.t('Name'),
      accessor: 'full_name',
    },
    {
      Header: props.t('Last visit'),
      accessor: 'last_login',
      id: 'last_login',
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss a z"),
    },
  ];

  const activeUsers = props['lastActiveUsers'].map(user => {
    return {
      'username': user['username'],
      'full_name': user['employee__full_name'],
      'last_login': user['last_login'] ? moment.utc(user['last_login']).tz(props.userTimezone).format("YYYY-MM-DD HH:mm:ss z") : 'User has never logged into the portal.',
    }
  });

  return <React.Fragment>
    {props.isLoading ? (
      <SimpleLoader/>
    ) : null}
    <HookTable
      columns={activeUserColumns}
      data={activeUsers}
      defaultPageSize={10}
      showPagination={false}
      defaultSortBy={{
        id: `last_login`,
        desc: true
      }}
    /></React.Fragment>;
};

export default translate('translations')(ActiveUsersTable);
