import React, { useState } from "react";
import PropTypes from "prop-types";
import SubCard from "src/components/material/Cards/SubCard";
import {
    Avatar,
    Box,
    Button,
    CardContent,
    FormControlLabel,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
    useTheme,
} from "@mui/material";
import { PeopleTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AuthReq } from "src/components/library/helpers";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: "bold",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.primary.light,
    },
}));

const StyledUniversalTableRow = styled(TableRow)(({ theme }) => ({
    "*": {
        backgroundColor: theme.palette.secondary.light,
    },
}));

const StyledDistributorTableRow = styled(TableRow)(({ theme }) => ({
    "*": {
        backgroundColor: theme.palette.warning.light,
    },
}));

const Operators = (props) => {
    const theme = useTheme();
    const { t } = useTranslation("translations");
    const { selectedDeviceConfig, extraDeviceInfo } = useSelector((store) => store.deviceInfo);
    const { group } = useSelector((store) => store.user);
    const [showUniversalOperators, setShowUniversalOperators] = useState(false);
    const [showDistributorOperators, setShowDistributorOperators] = useState(false);

    return (
        <SubCard
            title={t("Operators")}
            avatar={
                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                    <PeopleTwoTone />
                </Avatar>
            }>
            <CardContent>
                <AuthReq
                    userGroup={group}
                    requiredRoles={["SterilisSuperUsers", "SterilisPortalUsers", "FSEs", "SterilisWasteTypeAdmin"]}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Switch
                                    value={showUniversalOperators}
                                    onChange={() => setShowUniversalOperators(!showUniversalOperators)}
                                />
                            }
                            label="Show Universal Operators"
                        />
                        {showUniversalOperators && (
                            <Box>
                                <Box sx={{ p: 1, bgcolor: theme.palette.secondary.light }}>
                                    <Typography variant="body2">{t("Indicates Universal Operators")}</Typography>
                                </Box>
                            </Box>
                        )}
                    </Stack>
                </AuthReq>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <FormControlLabel
                        labelPlacement="start"
                        control={
                            <Switch
                                value={showDistributorOperators}
                                onChange={() => setShowDistributorOperators(!showDistributorOperators)}
                            />
                        }
                        label="Show Distributor Operators"
                    />
                    {showDistributorOperators && (
                        <Box>
                            <Box sx={{ p: 1, bgcolor: theme.palette.warning.light }}>
                                <Typography variant="body2">{t("Indicates Distributor Operators")}</Typography>
                            </Box>
                        </Box>
                    )}
                </Stack>
                {selectedDeviceConfig && (
                    <TableContainer sx={{ maxHeight: 500 }}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">{t("Name")}</StyledTableCell>
                                    <StyledTableCell align="center">{t("PIN")}</StyledTableCell>
                                    <StyledTableCell align="center">{t("Role")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedDeviceConfig.operators.map((operator) => (
                                    <StyledTableRow key={operator?.id} hover>
                                        <StyledTableCell sortDirection="asc" component="th" scope="row" align="center">
                                            {operator.employee.full_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{operator.pin}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {operator.is_admin ? "Admin" : "Operator"}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                {showUniversalOperators &&
                                    extraDeviceInfo &&
                                    extraDeviceInfo?.universal_operator_pins.map((operator) => (
                                        <StyledUniversalTableRow key={operator?.id} hover>
                                            <StyledTableCell
                                                sortDirection="asc"
                                                component="th"
                                                scope="row"
                                                align="center">
                                                {operator?.employee__full_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{operator.pin}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {operator.is_admin ? "Admin" : "Operator"}
                                            </StyledTableCell>
                                        </StyledUniversalTableRow>
                                    ))}
                                {showDistributorOperators &&
                                    extraDeviceInfo &&
                                    extraDeviceInfo?.distributor_operator_pins.map((operator) => (
                                        <StyledDistributorTableRow key={operator?.id} hover>
                                            <StyledTableCell
                                                sortDirection="asc"
                                                component="th"
                                                scope="row"
                                                align="center">
                                                {operator?.employee__full_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{operator.pin}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {operator.is_admin ? "Admin" : "Operator"}
                                            </StyledTableCell>
                                        </StyledDistributorTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </CardContent>
        </SubCard>
    );
};

Operators.propTypes = {};

export default Operators;
