/*
*  Modal responsible for Re-activating an inactive Device Operator
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Trans} from "react-i18next";
import SimpleLoader from "./SimpleLoader";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "./AuthService";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class ReactivateOperatorModal extends React.Component {
  state = {
    allowModalToBeClosed: true,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({
      operatorBeingReactivated: this.props.operatorBeingReactivated
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  reactivateOperator = () => {
    const {operatorBeingReactivated} = this.state;
    const operatorBeingReactivatedEmployeeID = operatorBeingReactivated.employee.id;
    const operatorBeingReactivatedName = operatorBeingReactivated.employee.full_name;

    this.setState({appLoading: true, allowModalToBeClosed: false});

    Auth.fetch(`/api/employee/`+operatorBeingReactivatedEmployeeID+'/', {
      method: 'DELETE',
    }).then(data => {
      this.setState({appLoading: false, allowModalToBeClosed: true,});

      this.props.handleReactivateOperatorModal();
      this.props.fetchDeviceOperators();
      this.props.fetchDeviceOperators();
      this.props.notifyOperatorReactivationSuccess(operatorBeingReactivatedName);
    }).catch(e => {
      Sentry.captureException(e);
      this.props.notifyOperatorReactivationFailure(operatorBeingReactivatedName);
      this.setState({appLoading: false, allowModalToBeClosed: true,});
    });
  };

  render() {
    // const topMargin = this.state.height < 1000 ? '3vh' : '10vh';
    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '600px',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    const {
      appLoading
    } = this.state;

    const {
      t,
      operatorBeingReactivated
    } = this.props;

    const operatorName = operatorBeingReactivated.employee.full_name;

    return (
      <TransitionablePortal
        open={this.props.showReactivateOperatorModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.handleReactivateOperatorModal(operatorBeingReactivated)
          }}
        >
          {appLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2 className='light-green'>{t('Reactivate Device Operator')}</h2>
          </Header>
          <Modal.Content className='create-device-operator-cust'>
            <div className='short-modal-text center-text'>
              {t('Are you sure you want to activate')} {operatorName}'s {t('device operator account')}?
            </div>
            <div className='short-modal-text center-text'>
              <Trans i18nKey="reactivate-operator-dialogue">
                They will able to log into a device with their PIN and start cycles once their account is active.
              </Trans>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='green-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    id='reactivateOperator'
                    onClick={this.reactivateOperator}
            >{t('Reactivate Device Operator')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(ReactivateOperatorModal);
