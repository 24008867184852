/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "../../AuthService";
import SimpleLoader from "../../SimpleLoader";
const Auth = new AuthService();

class DeleteFirmwareModal extends React.Component{
  state = {
    modalLoading : false,
    allowModalToBeClosed: true,
  };
  deleteFirmware = () => {
    const {
      firmware
    } = this.props;
    this.setState({
      modalLoading : true,
      allowModalToBeClosed: false,
    });

    Auth.fetch(`/api/firmware/${firmware['id']}/`, {
      method : 'DELETE',
    }).then(data => {
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.notifySuccess(firmware['deleted'] ? 'reactivated' : 'archived', firmware['firmware_version']);
        this.props.triggerDeleteFirmwareModal(null);
        this.props.preparePage();
      });
    }).catch(err => {
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.notifyFailure();
        this.props.triggerDeleteFirmwareModal(null);
      });
    });
  };
  render() {
    const {
      t,
      firmware
    } = this.props;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
          open={this.props.deleteFirmwareModal}
          onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
          transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerDeleteFirmwareModal()
          }}
        >
          <Header>
            <h2>{`${firmware['deleted'] ? t('Reactivate') : t('Archive')} ${t('firmware')}`}</h2>
          </Header>
          {this.state.modalLoading ? (
              <SimpleLoader/>
          ) : null}
          <Modal.Content>
            <div className="flex-dir-column short-modal-text center-text">
              {
                firmware['deleted']  ?
                  <React.Fragment>
                    <p>Are you sure you want to reactivate <strong>{firmware['firmware_version']}</strong>?</p>
                    <p>This action will make <strong>{firmware['firmware_version']}</strong> available for device assignment.</p>
                  </React.Fragment> :
                  <React.Fragment>
                    <p>Are you sure you want to archive <strong>{firmware['firmware_version']}</strong>?</p>
                    <p>This action will make it so that <strong>{firmware['firmware_version']}</strong> cannot be assigned to devices.</p>
                  </React.Fragment>
              }
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className={`ster-btn ${firmware['deleted'] ? 'green-btn' : 'red-btn'}`}
                    value="Submit"
                    type="submit"
                    id='cycleReportBtn'
                    onClick={this.deleteFirmware}
                    >{`${firmware['deleted'] ? t('Reactivate') : t('Archive')} ${firmware['firmware_version']}`}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(DeleteFirmwareModal);
