/*
* v1.0.0 Release Notes
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { withTranslation as translate } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import img002 from './images/image002.png'
import img003 from './images/image003.png'
import img004 from './images/image004.png'
import img005 from './images/image005.png'
import img007 from './images/image007.png'
import img008 from './images/image008.png'
import img009 from './images/image009.png'
import img010 from './images/image010.png'
import img011 from './images/image011.png'
import img012 from './images/image012.png'
import img013 from './images/image013.png'
import img014 from './images/image014.png'
import img015 from './images/image015.png'
import img016 from './images/image016.png'

const V100ReleaseNotes = () => {
    return (
        <Accordion>
            <AccordionSummary id="1.0.0" expandIcon={<ExpandMoreIcon />} >
                <Typography variant="h4" fontWeight="bold" color="secondary">
                    v1.0.0
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <h3 className='orange-text'>Version 1.0.0 of the Sterilis Portal</h3>

                <h4>Terminology changes</h4>
                <ul>
                    <li>
                        <p>Machine users are now called Device Operators.</p>
                        <p>A device operator can run cycles on your device.</p>
                    </li>
                    <br />
                    <li>
                        <p>A device operator with an Admin role can also run Biochallenge Validations and Pressure tests.</p>
                        <p>They can also restart cycles that may have an error.</p>
                    </li>
                </ul>

                <h4>UI improvements</h4>
                <p>The Devices page has changed so the devices are shown by Facility, see the Lab label below.</p>
                <img src={img002} alt="Customer Devices" />
                <br />
                <p>You can click on the menu in the upper right to run reports or see the device dashboard.</p>
                <img src={img003} alt="Device dropdown" />
                <br />
                <p>The Site Administrators tab shows all administrator for your company, you’ll at least see your name on the list. When you click on the New Site Admin button you’ll see the following form. We automatically make a device operator account for all site administrators.</p>
                <img src={img004} alt="Create a new Site Admin modal" />
                <br />
                <p>On the Operators tab you can see all device operators for you company. When you create a new operator their details are automatically sent to every device.</p>
                <img src={img005} alt="Operators tab" />
                <br />
                <p>When you click on the Actions button at the end of a row you can change settings for an operator</p>
                <img src={img007} alt="Operators actions" />
                <br />
                <p>When you select Edit Operator, you change the operator's personal information, PIN value, and notification settings.</p>
                <img src={img008} alt="Edit operators" />
                <br />
                <p>The Documents tab lets you download Sterilis Solutions documents. You’ll get an email whenever we add or change a document.</p>
                <img src={img009} alt="Documents tab" />
                <br />
                <p>We’ve improved the dashboard that’s displayed for a device. We now have a section that displays the number of cycles run in the time period broken down by device operator.</p>
                <img src={img010} alt="Sterilis Dashboard" />
                <br />
                <p>You can see what types of cycles were run on a given day by moving your mouse over the Cycle Summary graph in the upper left</p>
                <img src={img011} alt="Cycle breakdown" />
                <br />
                <p>You can see the pressure and temperature values for any cycle by moving your mouse over the graph in the lower right corner.</p>
                <img src={img012} alt="Pressure and Temperature chart" />
                <br />
                <p>If you press on your mouse and drag, you can select a section of this graph and zoom in on it.</p>
                <img src={img013} alt="Graph zoom" />
                <br />
                <p>And then just press on the Reset zoom button to go back to the full graph</p>
                <img src={img014} alt="Reset zoom" />
                <br />
                <p>We show the most recent five cycles, but you can display more using the controls at the top of the Cycle Report section.</p>
                <img src={img015} alt="More cycles on Cycle Table" />
                <br />
                <p>And you can search for specific cycles using the search box above the table. It will look for a match in any column.</p>
                <img src={img016} alt="Search cycle table" />
                <br />
                <br />
                <br />
                <p>We hope you find these changes make it even easier to work with our devices and we look forward to adding new features regularly. As always, we appreciate your feedback.</p>
            </AccordionDetails>
        </Accordion>
    )
};

export default translate('translations')(V100ReleaseNotes);
