import React from "react";
import {Icon} from "semantic-ui-react";

const expandColumn = (columnName, showLength = true) => {
  return {
    Header: columnName,
    className: 'expandable-column',
    manualWidth: 100,
    id: 'expander',
    Cell: ({row}) => {
      if (row['original']['subRows'].length > 0) {
        return <p {...row.getToggleRowExpandedProps()} className='fake-hyperlink'>
          {row.isExpanded ? <Icon name='chevron down'/> : <Icon name='chevron right'/>}
          {showLength && row['original']['subRows'].length}
        </p>
      } else {
        return <p>
          {showLength && row['original']['subRows'].length}
        </p>
      }
    }
  }
};

export default expandColumn;