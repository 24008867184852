/*
*  Modal which houses the Export All Errors from All Devices functionality
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Checkbox, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../../SimpleLoader";
import {parse as jsonToCSV} from 'json2csv';
import * as Sentry from "@sentry/browser";
import AuthService from "../../AuthService";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {toast} from "react-toastify";

const Auth = new AuthService();

class ExportAllErrorsModal extends React.Component {
  state = {
    modalLoading: false,
    filterByDate: false,
    fromDate: moment().subtract(1, 'week').format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    allowModalToBeClosed: true,
    errorDropdownValue: 'all_errors',
  };

  formatWasteType = (waste_type) => {
    switch (waste_type) {
        case "bio":
            return "Biochallenge test";
        case "cannabis":
            return "Cannabis Microbial Waste";
        case "cannabis_product":
            return "Cannabis Product Waste";
        case "dialysis":
            return "Dialysis";
        case "inspect":
            return "Inspect";
        case "mixed":
            return "Mixed";
        case "prv":
            return "Pressure release valve test";
        case "red_bags":
            return "Red Bags";
        case "sharps":
            return "Sharps";
        case "None":
            return "None";
        case "yellow_bags":
            return "Yellow Bags";
        case "liquids":
            return "Liquids";
        case "soft_lab_waste":
            return "Soft Lab Waste";
        case "yellow_bags_nogrind":
            return "Yellow Bags NoGrind";
        case "glass_slides":
            return "Glass Slides";
        case "ungrindable_waste":
            return "Ungrindable Waste";
        case "unknown":
            return "Unknown";
        default:
            return "";
    }
  };

  exportAllErrors = () => {
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    const {
      filterByDate,
      fromDate,
      toDate,
      errorDropdownValue
    } = this.state;
    Auth.fetch(`/api/export/all-errors?error_bucket=${errorDropdownValue}${filterByDate ? `&from_date=${fromDate}&to_date=${toDate}` : ''}`, {
      method: 'GET',
    }).then(data => {

      if(data['all_errors'].length === 0){
        toast(`No errors were found under the provided parameters.`, { type: toast.TYPE.DEFAULT, autoClose: 5000 });
        return
      }
      const csvArray = data['all_errors'].map((error) => {
        const waste_type = this.formatWasteType(error.cycle__waste_type)

        return {
          'Error time (UTC)': moment.utc(error.error_time).format("YYYY-MM-DD HH:mm:ss"),
          'Cycle ID': error.cycle_id,
          'Cycle start time (UTC)': error.cycle__time_started ? moment.utc(error.cycle__time_started).format("YYYY-MM-DD HH:mm:ss") : '',
          'HW Error code': error.hw_error_code__error_code,
          'SW Error Msg': error.sw_error_msg,
          'Device Serial': error.device_config__device__serial_number,
          'Software version': error.firmware__firmware_version,
          'Customer name': error.device_config__facility__customer__customer_name,
          'Facility name': error.device_config__facility__facility_name,
          'Waste Type': waste_type
        }
      });

      const csv = jsonToCSV(csvArray);
      const blob = new Blob([csv], {type: 'text/csv'});

      const link = document.createElement('a');

      const filePath = window.URL.createObjectURL(blob);

      link.href = filePath;

      if(filterByDate){
        link.download = `all_errors_from_all_devices-${fromDate}-${toDate}.csv`;
      }else{
        link.download = `all_errors_from_all_devices-${moment().format("YYYY-MM-DD")}.csv`;
      }
      document.getElementById('downloadDiv').appendChild(link);
      link.click();
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => this.props.triggerExportAllErrorsModal());
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => this.props.triggerExportAllErrorsModal());
    });
  };

  fromDateClick = timestamp => {
    this.setState({
      fromDate: moment(timestamp).format("YYYY-MM-DD")
    });
  };

  toDateClick = timestamp => {
    this.setState({
      toDate: moment(timestamp).format("YYYY-MM-DD")
    });
  };

  toggleFilterByDate = () => {
    this.setState((prevState) => {
      return {filterByDate: !prevState.filterByDate};
    });
  };

  errorDropdownChange = (event, data) => {
    this.setState({
      errorDropdownValue: data.value
    });
  };

  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading,
      filterByDate,
      fromDate,
      toDate
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const errorDropdownOptions = [
      {
        'key': 'all_errors',
        'value': 'all_errors',
        'text': t('All errors'),
      },
      {
        'key': 'cycle_errors',
        'value': 'cycle_errors',
        'text': t('Only errors that happened during a cycle'),
      },
      {
        'key': 'non_cycle_errors',
        'value': 'non_cycle_errors',
        'text': t('Only errors that happened outside of a cycle'),
      },

    ];
    return (
      <TransitionablePortal
        open={this.props.exportAllErrorsModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size={filterByDate ? 'small' : 'mini'}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerExportAllErrorsModal()
          }}
        >
          <Header>
            <h2>{t('Export All Errors from All Devices')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            <div className="short-modal-text center-text">
              <p>{t('csv-download-on-submit', 'Upon clicking submit, a CSV will be downloaded to your browser.')}</p>
              <div className="form-group medium-right-margin">
                <Checkbox label={t('Filter by date')}
                          name='byDate'
                          id='byDate'
                          checked={filterByDate}
                          onChange={this.toggleFilterByDate}/>
              </div>
              {filterByDate ?
                <div className='modal-date-select-container medium-margin-bottom'>
                  <div className='overflow-visible-td flex-dir-column left-text'>
                    <label className='date-label medium-right-margin'>{t('From')}</label>
                    <DayPickerInput
                      onDayChange={day => this.fromDateClick(day)}
                      value={fromDate}
                    />
                  </div>
                  <div className='overflow-visible-td  flex-dir-column left-text'>
                    <label className='date-label medium-right-margin'>{t('To')}</label>
                    <DayPickerInput
                      onDayChange={day => this.toDateClick(day)}
                      value={toDate}
                    />
                  </div>
                </div> : null
              }

              <div className="form-group slight-margin-top">
                <label className="fake-float" htmlFor="errorTypeDropdown">{t('Error type')}</label>
                <Dropdown
                  name='errorTypeDropdown'
                  className='medium-max-width'
                  selection
                  id='errorTypeDropdown'
                  options={errorDropdownOptions}
                  value={this.state.errorDropdownValue}
                  onChange={this.errorDropdownChange}
                  required
                />
              </div>
            </div>
          </Modal.Content>
          <div id='downloadDiv' className="download-div"/>
          <Modal.Actions>
            <Button className='ster-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    id='cycleReportBtn'
                    onClick={this.exportAllErrors}
            >{t('Submit')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(ExportAllErrorsModal);
