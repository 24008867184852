/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BurninStatChartContainer from "./BurninStatChartContainer";
import { useDispatch } from "react-redux";
import { addAbnormalValue } from "./bitSlice";

const BurninContainer = (props) => {
    const dispatch = useDispatch();

    const [abnormalBurninValues, setAbnormalBurninValues] = useState({});
    const [burninTest1AbnormalValues, setBurninTest1AbnormalValues] = useState([]);
    const [burninTest2AbnormalValues, setBurninTest2AbnormalValues] = useState([]);
    const [burninTest3AbnormalValues, setBurninTest3AbnormalValues] = useState([]);
    const [burninTest4AbnormalValues, setBurninTest4AbnormalValues] = useState([]);
    const [burninTest5AbnormalValues, setBurninTest5AbnormalValues] = useState([]);
    const [burninTest6AbnormalValues, setBurninTest6AbnormalValues] = useState([]);
    const [burninTest7AbnormalValues, setBurninTest7AbnormalValues] = useState([]);

    // get burnin abnormal values
    useEffect(() => {
        const burninTest1AbnormalValues = [];
        const burninTest2AbnormalValues = [];
        const burninTest3AbnormalValues = [];
        const burninTest4AbnormalValues = [];
        const burninTest5AbnormalValues = [];
        const burninTest6AbnormalValues = [];
        const burninTest7AbnormalValues = [];

        const abnormalValuesObj = {};

        props.abnormalValues
            .filter((val) => val["burnin_test"] !== 0)
            .forEach((value) => {
                if (value["burnin_test"] in abnormalValuesObj) {
                    if (value["value_name"] in abnormalValuesObj[value["burnin_test"]]) {
                    } else {
                        abnormalValuesObj[value["burnin_test"]][value["value_name"]] = {
                            ...value,
                        };
                    }
                } else {
                    abnormalValuesObj[value["burnin_test"]] = {};
                    abnormalValuesObj[value["burnin_test"]][value["value_name"]] = {
                        ...value,
                    };
                }

                if (value["burnin_test"] === 1) {
                    burninTest1AbnormalValues.push(value);
                } else if (value["burnin_test"] === 2) {
                    burninTest2AbnormalValues.push(value);
                } else if (value["burnin_test"] === 3) {
                    burninTest3AbnormalValues.push(value);
                } else if (value["burnin_test"] === 4) {
                    burninTest4AbnormalValues.push(value);
                } else if (value["burnin_test"] === 5) {
                    burninTest5AbnormalValues.push(value);
                } else if (value["burnin_test"] === 6) {
                    burninTest6AbnormalValues.push(value);
                } else if (value["burnin_test"] === 7) {
                    burninTest7AbnormalValues.push(value);
                }
            });

        setBurninTest1AbnormalValues(burninTest1AbnormalValues);
        dispatch(addAbnormalValue(burninTest1AbnormalValues));

        setBurninTest2AbnormalValues(burninTest2AbnormalValues);
        dispatch(addAbnormalValue(burninTest2AbnormalValues));

        setBurninTest3AbnormalValues(burninTest3AbnormalValues);
        dispatch(addAbnormalValue(burninTest3AbnormalValues));

        setBurninTest4AbnormalValues(burninTest4AbnormalValues);
        dispatch(addAbnormalValue(burninTest4AbnormalValues));

        setBurninTest5AbnormalValues(burninTest5AbnormalValues);
        dispatch(addAbnormalValue(burninTest5AbnormalValues));

        setBurninTest6AbnormalValues(burninTest6AbnormalValues);
        dispatch(addAbnormalValue(burninTest6AbnormalValues));

        setBurninTest7AbnormalValues(burninTest7AbnormalValues);
        dispatch(addAbnormalValue(burninTest7AbnormalValues));

        setAbnormalBurninValues(abnormalValuesObj);
    }, [props.abnormalValues.length]);

    return (
        <React.Fragment>
            <BurninStatChartContainer
                abnormalValuesObj={props.abnormalValuesObj}
                containsBurninTest={props.containsBurninTest}
                abnormalBurninValues={abnormalBurninValues}
                burninTest1AbnormalValues={burninTest1AbnormalValues}
                burninTest2AbnormalValues={burninTest2AbnormalValues}
                burninTest3AbnormalValues={burninTest3AbnormalValues}
                burninTest4AbnormalValues={burninTest4AbnormalValues}
                burninTest5AbnormalValues={burninTest5AbnormalValues}
                burninTest6AbnormalValues={burninTest6AbnormalValues}
                burninTest7AbnormalValues={burninTest7AbnormalValues}
                abnormalValues={props.abnormalValues}
                deviceSerial={props.deviceSerial}
                scrollOnCycleClick={props.scrollOnCycleClick}
                setScrollOnCycleClick={props.setScrollOnCycleClick}
                zoomParams={props.zoomParams}
                setZoomParams={props.setZoomParams}
                burnInTab={props.burnInTab}
                handleTabChange={props.handleTabChange}
                burnIn1Data={props.burnIn1Data}
                loadingBurnIn1={props.loadingBurnIn1}
                burnIn2Data={props.burnIn2Data}
                loadingBurnIn2={props.loadingBurnIn2}
                burnIn3Data={props.burnIn3Data}
                loadingBurnIn3={props.loadingBurnIn3}
                burnIn4Data={props.burnIn4Data}
                loadingBurnIn4={props.loadingBurnIn4}
                burnIn5Data={props.burnIn5Data}
                loadingBurnIn5={props.loadingBurnIn5}
                burnIn6Data={props.burnIn6Data}
                loadingBurnIn6={props.loadingBurnIn6}
                burnIn7Data={props.burnIn7Data}
                loadingBurnIn7={props.loadingBurnIn7}
                digitalOutput={props.digitalOutput}
            />
        </React.Fragment>
    );
};

BurninContainer.defaultProps = {
    abnormalValuesObj: {},
    showMessages: false,
    showBurninContainer: false,
    deviceSerial: "",
};

BurninContainer.propTypes = {
    abnormalValuesObj: PropTypes.object,
    showMessages: PropTypes.bool,
    showBurninContainer: PropTypes.bool,
    setShowMessages: PropTypes.func,
    setShowBurninContainer: PropTypes.func.isRequired,
    deviceSerial: PropTypes.string,
    scrollOnCycleClick: PropTypes.bool.isRequired,
    setScrollOnCycleClick: PropTypes.func.isRequired,
};

export default BurninContainer;
