import { Box, Chip, MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerMachines, selectFacilitiesDropdownByCustomer } from "src/features/Customer/customerSlice";
import FormSelect from "src/components/material/Forms/FormSelect";

const FacilitySelection = (props) => {
    const dispatch = useDispatch();
    const { isEdit, control, errors, rules } = props;
    // @ts-ignore
    const { customer } = useSelector((store) => store.createUser);
    // @ts-ignore
    const { facilities } = useSelector((store) => store.customer);
    // @ts-ignore
    const { userCustomer, editUser } = useSelector((store) => store.editUser);
    // @ts-ignore
    const { operatorCustomer } = useSelector((store) => store.editOperator);

    const facilityDropdownOptions = useSelector((state) =>
        // @ts-ignore
        selectFacilitiesDropdownByCustomer(state.customer, customer?.id || userCustomer?.id || operatorCustomer?.id)
    );

    useEffect(() => {
        if (facilities.length === 0) {
            // @ts-ignore
            dispatch(fetchCustomerMachines());
        }
    }, []);

    return (
        <FormSelect
            multiple
            label="Facilities"
            name="employee.facility_ids"
            options={facilityDropdownOptions}
            control={control}
            errors={errors}
            rules={{}}
            renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {facilityDropdownOptions &&
                        selected.map((value) => {
                            if (value) {
                                if (value?.hasOwnProperty("id")) {
                                    return (
                                        <Chip
                                            key={value.id}
                                            label={
                                                facilityDropdownOptions.find((facility) => facility.id === value.id)
                                                    ?.label
                                            }
                                        />
                                    );
                                } else {
                                    return (
                                        <Chip
                                            key={value}
                                            label={
                                                facilityDropdownOptions.find((facility) => facility.id === value)?.label
                                            }
                                        />
                                    );
                                }
                            } else {
                                return null;
                            }
                        })}
                </Box>
            )}
        />
    );
};

export default FacilitySelection;
