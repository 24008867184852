/*
* Modal which houses the delete document button
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate } from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../SimpleLoader";
import AuthService from "../AuthService";
import * as Sentry from "@sentry/browser";
const Auth = new AuthService();

class GetDeviceFilesModal extends React.Component{
  state = {
    ethelLogs : false,
    honeybeeLogs : false,
    piDatabase : false,
    installLog : false,
    sysLog : false,
    commdaemonLog : false,
    ethelLogsNewest : true,
    ethelLogsAll : false,
    honeybeeLogsNewest : true,
    honeybeeLogsAll : false,
    modalLoading : false,
    allowModalToBeClosed: true
  };
  componentDidMount(){

  }
  getFiles = (e) => {
    e.preventDefault();
    const {
      ethelLogs,
      honeybeeLogs,
      piDatabase,
      sysLog,
      commdaemonLog,
      ethelLogsNewest,
      ethelLogsAll,
      honeybeeLogsNewest,
      honeybeeLogsAll,
      installLog
    } = this.state;

    const {
      deviceConfigID
    } = this.props;

    let ethelFileExpression = '';

    // All pf the spacing here is intentional. In order to space the filepaths correctly, a space must be
    // appended at the expression variable level & not at the string concat level
    if(ethelLogs){
      if(ethelLogsAll){
        ethelFileExpression = '/var/log/ethel.log* ';
      }else if(ethelLogsNewest){
        ethelFileExpression = '/var/log/ethel.log /var/log/ethel.log.1 ';
      }
    }

    let honeybeeFileExpression = '';
    if(honeybeeLogs){
      if(honeybeeLogsAll){
        honeybeeFileExpression = '/var/log/honeybee.log* ';
      }else if(honeybeeLogsNewest){
        honeybeeFileExpression = '/var/log/honeybee.log /var/log/honeybee.log.1 ';
      }
    }

    const finalFileExpression =
      `${ethelFileExpression}${honeybeeFileExpression}${piDatabase ? '/home/pi/132C/sterilis.0.2.2.db ' : ''}${installLog ? '/home/pi/install_log* ' : ''}${sysLog ? '/var/log/syslog ' : ''}${commdaemonLog ? 'commdaemon.log ' : ''}`;

    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/file-request/`, {
      method : 'POST',
      body: JSON.stringify({
        'pi_file_name_expression' : finalFileExpression,
        'attach_ethel_logs' : 0,
        'request_part' : 0,
        'device_config_id' : deviceConfigID
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true
      });
      this.props.notifyFileRequestSuccess();
      this.props.triggerGetDeviceFilesModal(null);
    }).catch(err => {
      Sentry.captureException(err);
      this.props.notifyGenericFailure();
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true
      })
    });
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    const type = event.currentTarget.type;
    if(type === 'radio' || type === 'checkbox'){
      if(name === 'ethelLogsNewest' ||
          name === 'ethelLogsAll' ||
          name === 'honeybeeLogsNewest' ||
          name === 'honeybeeLogsAll'){
        this.switchOppositeLogPref(name)
      }
      this.setState(state =>  ({
        [name] : !state[name],
      }))
    }else{
      this.setState({ [name] : value});
    }

  };

  switchOppositeLogPref = (name) => {
    if(name === 'ethelLogsNewest'){
      this.setState({
        ethelLogsAll : !this.state.ethelLogsAll
      })
    }else if(name === 'ethelLogsAll'){
      this.setState({
        ethelLogsNewest : !this.state.ethelLogsNewest
      })
    }else if(name === 'honeybeeLogsAll'){
      this.setState({
        honeybeeLogsNewest : !this.state.honeybeeLogsNewest
      })
    }else if(name === 'honeybeeLogsNewest'){
      this.setState({
        honeybeeLogsAll : !this.state.honeybeeLogsAll
      })
    }
  };

  serialHeader = () => {
    const {
      selectedDeviceSerial
    } = this.props;
    return selectedDeviceSerial ? <span>from {selectedDeviceSerial}</span>: null;
  };

  render() {
    const {
      ethelLogs,
      honeybeeLogs,
      piDatabase,
      sysLog,
      commdaemonLog,
      installLog,
      modalLoading
    } = this.state;

    const {
      t
    } = this.props;

    const userEmail = this.props.userEmail;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
          open={this.props.showGetDeviceFilesModal}
          onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
          transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='tiny'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerGetDeviceFilesModal(null)
          }}
        >
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2>{`${t('Retrieve device files')}`} {this.serialHeader()}</h2>
          </Header>
          <Modal.Content>
            <form id='getDeviceFilesForm'
                  onSubmit={this.getFiles}>
              <div>
                <div className="form-group">
                  <label className='left-label' htmlFor="ethelLogsInput">{t('Ethel Log')}</label>
                  <input checked={!!this.state.ethelLogs}
                         name='ethelLogs'
                         onChange={this.handleChange}
                         type="checkbox"
                         id="ethelLogsInput"
                         className="get-files-form-checkbox"
                  />
                  <span className="left-margin">
                    <input checked={!!this.state.ethelLogsNewest}
                           name='ethelLogsNewest'
                           onChange={this.handleChange}
                           type="radio"
                           id="ethelLogsNewestInput"
                           disabled={!this.state.ethelLogs}
                           className="left-margin"/>
                    <label className="slight-left-margin" htmlFor="ethelLogsNewestInput">{t('Newest')}</label>
                    <input checked={!!this.state.ethelLogsAll}
                           name='ethelLogsAll'
                           onChange={this.handleChange}
                           type="radio"
                           id="ethelLogsAllInput"
                           disabled={!this.state.ethelLogs}
                           className="left-margin"/>
                    <label className="slight-left-margin" htmlFor="ethelLogsAllInput">{t('All')}</label>
                  </span>
                </div>
                <div className="form-group">
                  <label className='left-label' htmlFor="honeybeeLogsInput">{t('Honeybee Log')}</label>
                  <input checked={!!this.state.honeybeeLogs}
                         name='honeybeeLogs'
                         onChange={this.handleChange}
                         type="checkbox"
                         id="honeybeeLogsInput"
                         className="get-files-form-checkbox"/>
                  <span className="left-margin">
                    <input checked={!!this.state.honeybeeLogsNewest}
                           name='honeybeeLogsNewest'
                           onChange={this.handleChange}
                           type="radio"
                           id="honeybeeLogsNewestInput"
                           disabled={!this.state.honeybeeLogs}
                           className="left-margin"/>
                    <label className="slight-left-margin" htmlFor="honeybeeLogsNewestInput">{t('Newest')}</label>
                    <input checked={!!this.state.honeybeeLogsAll}
                           name='honeybeeLogsAll'
                           onChange={this.handleChange}
                           type="radio"
                           id="honeybeeLogsAllInput"
                           disabled={!this.state.honeybeeLogs}
                           className="left-margin"/>
                    <label className="slight-left-margin"  htmlFor="honeybeeLogsAllInput">{t('All')}</label>
                  </span>
                </div>
                <div className="form-group">
                  <label className='left-label' htmlFor="piDatabaseInput">{t('Pi Database')}</label>
                  <input checked={!!this.state.piDatabase}
                         name='piDatabase'
                         onChange={this.handleChange}
                         type="checkbox"
                         id="piDatabaseInput"
                         className="get-files-form-checkbox"/>
                </div>
                <div className="form-group">
                  <label className='left-label' htmlFor="installLogInput">{t('Installation Log')}</label>
                  <input checked={!!this.state.installLog}
                         name='installLog'
                         onChange={this.handleChange}
                         type="checkbox"
                         id="installLogInput"
                         className="get-files-form-checkbox"/>
                </div>
                <div className="form-group">
                  <label className='left-label' htmlFor="sysLogInput">{t('System Log')}</label>
                  <input checked={!!this.state.sysLog}
                         name='sysLog'
                         onChange={this.handleChange}
                         type="checkbox"
                         id="sysLogInput"
                         className="get-files-form-checkbox"/>
                </div>
                <div className="form-group">
                  <label className='left-label' htmlFor="commdaemonLogInput">{t('Commdaemon log')}</label>
                  <input checked={!!this.state.commdaemonLog}
                         name='commdaemonLog'
                         onChange={this.handleChange}
                         type="checkbox"
                         id="commdaemonLogInput"
                         className="get-files-form-checkbox"/>
                </div>
                <h5 className='center-text'>
                  {t('device-files.3',
                    "Please check the Device Files tab before requesting files! The files you're interested in may have already been uploaded.")}
                </h5>
                <h5 className='center-text'>
                  {this.props.userEmail ?
                    <Trans ns='translations' i18nKey='device-files.1_with_email'>
                      After the file(s) have been uploaded, an email with a link for downloading the file(s) will be sent to {{userEmail}}
                    </Trans>:
                    <Trans ns='translations' i18nKey='device-files.1'>
                      After the file(s) have been uploaded you will receive an email with a link for downloading the file(s).
                    </Trans>
                  }
                </h5>
                <h5 className='center-text'>
                  {t('device-files.2', 'Please note that devices cannot send files while a cycle is active.')}
                </h5>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn-wide grey-btn'
                    value="Submit"
                    type="submit"
                    form='getDeviceFilesForm'
                    id='getDeviceFilesBtn'
                    disabled={!ethelLogs && !honeybeeLogs && !piDatabase && !sysLog && !commdaemonLog && !installLog}
            >{t('Get files')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(GetDeviceFilesModal);
