/*
* Customer-wide Comprehensive and Compliance reports
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React, {useState, useEffect, useRef} from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button} from "semantic-ui-react";
import CustomerCycleReportModal from './CustomerCycleReportModal'

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// Good example of using hooks in a situation where initial state is set by a prop
const CustomerCycleReport = props => {
  const [showCustomerCycleReportModal, triggerCustomerCycleReportModal] = useState(props.showModal);
  const prevShow = usePrevious(props.showModal);
  useEffect(() => {
    if(prevShow !== props.showModal){
      triggerCustomerCycleReportModal(props.showModal)
    }
  });

  return <React.Fragment>
    <Button className='ster-btn customer-cycle-report-btn'
            id='triggerCustomerReportModalBtn'
            onClick={() => triggerCustomerCycleReportModal(!showCustomerCycleReportModal)}
    >
      {props.isCurrentUserSterilisOrDist ? props.t('Generate Customer Cycle Report') : props.t('Generate Cycle Report')}</Button>
    {
      showCustomerCycleReportModal &&
        <CustomerCycleReportModal
          triggerCustomerCycleReportModal={triggerCustomerCycleReportModal}
          showCustomerCycleReportModal={showCustomerCycleReportModal}
          isCurrentUserSterilisOrDist={props.isCurrentUserSterilisOrDist}
          customerCycleReportCustomerID={props.customerCycleReportCustomerID}
        />
    }
  </React.Fragment>;
};

export default translate('translations')(CustomerCycleReport);
