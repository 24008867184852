import { Button } from "@mui/material";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { get, orderBy } from "lodash";
import { DateTime } from "luxon";
import AuthService from "src/components/AuthService";
import { alphabeticalSort, sterilisCustomers } from "src/components/library/helpers";

const auth = new AuthService();

const initialState = {
    loading: {
        dropdown: false,
        deviceConfig: false,
        configUpdate: false,
        wasteUpdate: false,
        extraInfo: false,
        logs: false,
        timeline: false,
        files: false,
        wasteTypeConfig: false,
        bioReset: false,
        keyReset: false,
    },
    selectedDeviceConfig: null,
    selectedDevice: null,
    selectedDistributor: null,
    deviceDropdownOptions: [],
    distributorDropdownOptions: [],
    configAuditLogs: [],
    softwareErrorLogs: [],
    hardwareErrorLogs: [],
    deviceConfigOptions: {},
    deviceTimelineList: [],
    deviceFileRequests: [],
    deviceFiles: [],
    customerDevicesOnly: false,
    deviceTimezone: null,
    extraDeviceInfo: null,
    showGetDeviceFilesModal: false,
    resendActivationCodeModal: false,
    resetBiochallengeDialogOpen: false,
    wasteTypeConfigOptions: {},
    updatedConfig: {},
    selectedWasteType: null,
    modifiedWasteConfig: {},
    defaultWasteConfig: {},
    regulatoryCompliance: [],
    regulatoryComplianceDropdown: [],
    inactiveWasteTypes: [],
    newWasteType: false,
    activeWasteCount: 0,
    notifySuccess: false,
    notifyFailure: false,
    activationKey: null,
    archiveDialogOpen: false,
    pvSensors: [],
    pvSensorDropdown: [],
};

const deviceInfoSlice = createSlice({
    name: "deviceInfo",
    initialState,
    reducers: {
        setSelectedDeviceConfig(state, action) {
            state.selectedDeviceConfig = action.payload;
        },
        setSelectedDevice(state, action) {
            state.selectedDeviceConfig = null;
            state.selectedDevice = action.payload;
        },
        setSelectedDistributor(state, action) {
            state.selectedDistributor = action.payload;
        },
        setDeviceTimezone(state, action) {
            state.deviceTimezone = action.payload;
        },
        setExtraDeviceInfo(state, action) {
            state.extraDeviceInfo = action.payload;
        },
        setShowGetDeviceFilesModal(state, action) {
            state.showGetDeviceFilesModal = action.payload;
        },
        setResendActivationCodeModal(state, action) {
            state.resendActivationCodeModal = action.payload;
        },
        setResetBiochallengeDialogOpen(state, action) {
            state.resetBiochallengeDialogOpen = action.payload;
        },
        setDeviceDropdownOptions(state, action) {
            state.deviceDropdownOptions = action.payload;
        },
        setCustomerDevicesOnly(state, action) {
            state.customerDevicesOnly = action.payload;
        },
        setUpdatedConfig(state, action) {
            const field = action.payload.field;

            if (field === "vessel_pressure_sensor") {
                state.updatedConfig["vessel_pressure_sensor_id"] = action.payload.value;
            }
            state.updatedConfig[field] = action.payload.value;
        },
        setSelectedWasteType(state, action) {
            state.selectedWasteType = action.payload;
        },
        setUpdatedWasteConfig(state, action) {
            state.modifiedWasteConfig[action.payload.field] = action.payload.value;
        },
        setInactiveWasteTypes(state, action) {
            state.inactiveWasteTypes = action.payload;
        },
        setNewWasteType(state, action) {
            state.newWasteType = action.payload;
        },
        setArchiveDialogOpen(state, action) {
            state.archiveDialogOpen = action.payload;
        },
        resetWasteTypeConfig(state, action) {
            state.modifiedWasteConfig = initialState.modifiedWasteConfig;
            state.defaultWasteConfig = initialState.defaultWasteConfig;
        },
        resetUpdatedConfig(state, action) {
            state.updatedConfig = initialState.updatedConfig;
            state.modifiedWasteConfig = initialState.modifiedWasteConfig;
            state.selectedWasteType = initialState.selectedWasteType;
            state.newWasteType = initialState.newWasteType;
        },

        resetDeviceInfoState(state, action) {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeviceDropdownOptions.pending, (state, action) => {
                state.loading.dropdown = true;
                state.deviceDropdownOptions = [];
                state.distributorDropdownOptions = [];
            })
            .addCase(fetchDeviceDropdownOptions.fulfilled, (state, action) => {
                state.loading.dropdown = false;
                state.deviceDropdownOptions = action.payload
                    .map((item) => {
                        if (
                            state.distributorDropdownOptions.some((elem) => {
                                return item?.distributor_id === elem?.value;
                            }) === false
                        ) {
                            state.distributorDropdownOptions.push({
                                key: item?.distributor_id,
                                value: item?.distributor_id,
                                text: item?.distributor_name,
                            });
                        }

                        return {
                            key: item?.id,
                            value: item?.id,
                            serial: item?.serial_number,
                            text: `${item?.customer} : ${item?.facility} : ${item?.serial_number}`,
                            customer_id: item?.customer_id,
                            distributor_id: item?.distributor_id,
                        };
                    })
                    .sort((a, b) => alphabeticalSort(a, b, "text"));
            })
            .addCase(fetchDeviceDropdownOptions.rejected, (state, action) => {
                state.loading.dropdown = false;
                state.deviceDropdownOptions = [];
                state.distributorDropdownOptions = [];
            })
            .addCase(fetchExtraDeviceInfo.pending, (state, action) => {
                state.loading.extraInfo = true;
                state.extraDeviceInfo = initialState.extraDeviceInfo;
            })
            .addCase(fetchExtraDeviceInfo.fulfilled, (state, action) => {
                state.loading.extraInfo = false;
                state.extraDeviceInfo = action.payload;
            })
            .addCase(fetchExtraDeviceInfo.rejected, (state, action) => {
                state.loading.extraInfo = false;
                state.extraDeviceInfo = initialState.extraDeviceInfo;
            })
            .addCase(fetchDeviceConfig.pending, (state, action) => {
                state.loading.deviceConfig = true;
                state.selectedDeviceConfig = initialState.selectedDeviceConfig;
                state.updatedConfig = initialState.updatedConfig;
                state.inactiveWasteTypes = initialState.inactiveWasteTypes;
            })
            .addCase(fetchDeviceConfig.fulfilled, (state, action) => {
                state.loading.deviceConfig = false;
                state.selectedDeviceConfig = action.payload;
                state.deviceTimezone = action.payload.facility.region_setting.timezone.timezone;

                state.inactiveWasteTypes = Object.keys(state.deviceConfigOptions)
                    .filter((option) => {
                        return (
                            state.deviceConfigOptions[option].category === "waste_type" &&
                            state.deviceConfigOptions[option].requires_facility_type ===
                                action.payload.facility.facility_type &&
                            !action.payload[option]
                        );
                    })
                    .map((item) => {
                        return {
                            key: item,
                            value: item,
                            text: state.deviceConfigOptions[item].label,
                        };
                    });

                state.activeWasteCount = Object.entries(action.payload).reduce((acc, [key, value]) => {
                    if (state.wasteTypeConfigOptions.includes(key)) {
                        if (value) {
                            acc = acc + 1;
                        }
                    }
                    return acc;
                }, 0);
            })
            .addCase(fetchDeviceConfig.rejected, (state, action) => {
                state.loading.deviceConfig = false;
                state.selectedDeviceConfig = initialState.selectedDeviceConfig;
            })
            .addCase(fetchDeviceLogs.pending, (state, action) => {
                state.loading.logs = true;
                state.configAuditLogs = initialState.configAuditLogs;
                state.hardwareErrorLogs = initialState.hardwareErrorLogs;
                state.softwareErrorLogs = initialState.softwareErrorLogs;
            })
            .addCase(fetchDeviceLogs.fulfilled, (state, action) => {
                state.loading.logs = false;
                action.payload.forEach((config) => {
                    state.configAuditLogs.push(...config?.audit_logs);
                    config?.device_errors.forEach((error) => {
                        error.error_time = DateTime.fromISO(error.error_time, {
                            zone: state.deviceTimezone,
                        }).toISO();

                        if (error?.sw_error_msg) {
                            state.softwareErrorLogs.push({
                                id: error?.id,
                                error_time: error?.error_time,
                                firmware: error?.firmware?.firmware_version || "No Firmware Recorded.",
                                machine_state: error?.machine_state,
                                sw_error_msg: error?.sw_error_msg,
                            });
                        } else {
                            const flattened = {
                                id: error?.id,
                                description: error?.hw_error_code?.description,
                                error_code: error?.hw_error_code?.error_code,
                                firmware: error?.firmware?.firmware_version || "No Firmware Recorded.",
                                machine_state: error?.machine_state,
                                error_time: error?.error_time,
                            };
                            state.hardwareErrorLogs.push(flattened);
                        }
                    });
                });
                state.configAuditLogs.map((log, idx) => {
                    log.id = idx;
                    log.timestamp = DateTime.fromISO(log.timestamp, { zone: state.deviceTimezone }).toISO();
                });
            })
            .addCase(fetchDeviceLogs.rejected, (state, action) => {
                state.loading.logs = false;
                state.configAuditLogs = initialState.configAuditLogs;
                state.hardwareErrorLogs = initialState.hardwareErrorLogs;
                state.softwareErrorLogs = initialState.softwareErrorLogs;
            })
            .addCase(fetchDeviceTimeline.pending, (state, action) => {
                state.loading.timeline = true;
                state.deviceTimelineList = initialState.deviceTimelineList;
            })
            .addCase(fetchDeviceTimeline.fulfilled, (state, action) => {
                state.loading.timeline = false;
                state.deviceTimelineList = action.payload.map((config) => {
                    return {
                        id: config?.id,
                        customer: config?.customer,
                        facility: config?.facility,
                        created_date: DateTime.fromISO(config?.created).toISO(),
                        deleted_date: DateTime.fromISO(config?.deleted).toISO(),
                        cycle_count: config?.cycle_count,
                        error_count: config?.error_count,
                        device_config: config,
                    };
                });
            })
            .addCase(fetchDeviceTimeline.rejected, (state, action) => {
                state.loading.timeline = false;
                state.deviceTimelineList = initialState.deviceTimelineList;
            })
            .addCase(fetchDeviceFiles.pending, (state, action) => {
                state.loading.files = true;
                state.deviceFileRequests = initialState.deviceFileRequests;
            })
            .addCase(fetchDeviceFiles.fulfilled, (state, action) => {
                state.loading.files = false;
                state.deviceFileRequests = action.payload;
                let filesList = action.payload.reduce((acc, fileRequest) => {
                    return acc.concat(
                        fileRequest?.request_files.filter(
                            (requestedFile) =>
                                requestedFile?.status === "OnPortal" && requestedFile?.file_request_s3_file
                        )
                    );
                }, []);
                filesList = orderBy(filesList, [(items) => items?.files_request_s3_file?.uploaded_at], ["desc"]);
                const uniqueFiles = filesList.reduce((acc, fileOnPortal) => {
                    if (
                        !acc.find(
                            (file) => file?.file_request_s3_file?.upload === fileOnPortal?.file_request_s3_file?.upload
                        )
                    ) {
                        acc.push(fileOnPortal);
                    }
                    return acc;
                }, []);

                const files = uniqueFiles.map((files) => {
                    return {
                        id: files?.id,
                        fileName: files?.s3_file_name,
                        fileType: getFileType(files),
                        fileSize: files?.file_size,
                        s3FileId: files?.file_request_s3_file.id,
                        contentStartDate: files?.content_start_date,
                        actions: "download",
                    };
                });

                state.deviceFiles = files;
            })
            .addCase(fetchDeviceFiles.rejected, (state, action) => {
                state.loading.files = false;
                state.deviceFileRequests = initialState.deviceFileRequests;
            })
            .addCase(fetchDeviceConfigOptions.pending, (state, action) => {
                state.loading.deviceConfig = true;
                state.deviceConfigOptions = initialState.deviceConfigOptions;
                state.wasteTypeConfigOptions = initialState.wasteTypeConfigOptions;
            })
            .addCase(fetchDeviceConfigOptions.fulfilled, (state, action) => {
                state.loading.deviceConfig = false;

                const configOptions = action.payload.config_item_metadata;
                configOptions.vessel_pressure_sensor.category = "sensors";
                configOptions.vessel_pressure_sensor.affects_software = true;

                state.deviceConfigOptions = configOptions;
                const wasteTypes = Object.entries(action.payload.config_item_metadata).reduce((acc, [key, value]) => {
                    if (value?.category === "waste_type") {
                        acc.push(key);
                    }
                    return acc;
                }, []);
                state.wasteTypeConfigOptions = wasteTypes;
            })
            .addCase(fetchDeviceConfigOptions.rejected, (state, action) => {
                state.loading.deviceConfig = false;
                state.deviceConfigOptions = initialState.deviceConfigOptions;
            })
            .addCase(fetchDefaultWasteConfig.pending, (state, action) => {
                state.loading.wasteTypeConfig = true;
                if (!action.meta.arg.getSystemDefaults) {
                    state.defaultWasteConfig = initialState.defaultWasteConfig;
                }
                state.modifiedWasteConfig = initialState.modifiedWasteConfig;
            })
            .addCase(fetchDefaultWasteConfig.fulfilled, (state, action) => {
                state.loading.wasteTypeConfig = false;
                if (action.meta.arg.getSystemDefaults) {
                    state.modifiedWasteConfig = action.payload;
                } else {
                    state.defaultWasteConfig = action.payload;
                    state.modifiedWasteConfig = action.payload;
                }
            })
            .addCase(fetchDefaultWasteConfig.rejected, (state, action) => {
                state.loading.wasteTypeConfig = false;
                state.defaultWasteConfig = initialState.defaultWasteConfig;
                state.modifiedWasteConfig = initialState.modifiedWasteConfig;
            })
            .addCase(fetchRegulatoryCompliance.pending, (state, action) => {
                state.regulatoryCompliance = initialState.regulatoryCompliance;
                state.regulatoryComplianceDropdown = initialState.regulatoryComplianceDropdown;
            })
            .addCase(fetchRegulatoryCompliance.fulfilled, (state, action) => {
                state.regulatoryCompliance = action.payload;
                state.regulatoryComplianceDropdown = action.payload.map((item) => {
                    return {
                        key: item.id,
                        value: item.abbreviation + item.timezone_id,
                        text: item.region_name + " - " + item.tz_display_label,
                    };
                });
            })
            .addCase(fetchRegulatoryCompliance.rejected, (state, action) => {
                state.regulatoryCompliance = initialState.regulatoryCompliance;
                state.regulatoryComplianceDropdown = initialState.regulatoryComplianceDropdown;
            })
            .addCase(patchDeviceConfig.pending, (state, action) => {
                state.loading.configUpdate = true;
            })
            .addCase(patchDeviceConfig.fulfilled, (state, action) => {
                state.loading.configUpdate = false;
                state.selectedDeviceConfig = action.payload;
                state.updatedConfig = initialState.updatedConfig;
                state.inactiveWasteTypes = Object.keys(state.deviceConfigOptions)
                    .filter((option) => {
                        return (
                            state.deviceConfigOptions[option].category === "waste_type" &&
                            state.deviceConfigOptions[option].requires_facility_type ===
                                action.payload.facility.facility_type &&
                            !action.payload[option]
                        );
                    })
                    .map((item) => {
                        return {
                            key: item,
                            value: item,
                            text: state.deviceConfigOptions[item].label,
                        };
                    });

                state.activeWasteCount = Object.entries(action.payload).reduce((acc, [key, value]) => {
                    if (state.wasteTypeConfigOptions.includes(key)) {
                        if (value) {
                            acc = acc + 1;
                        }
                    }
                    return acc;
                }, 0);
            })
            .addCase(patchDeviceConfig.rejected, (state, action) => {
                state.loading.configUpdate = false;
            })
            .addCase(saveWasteTypeConfig.pending, (state, action) => {
                state.loading.wasteUpdate = true;
            })
            .addCase(saveWasteTypeConfig.fulfilled, (state, action) => {
                state.loading.wasteUpdate = false;
                state.selectedWasteType = initialState.selectedWasteType;
                state.modifiedWasteConfig = initialState.modifiedWasteConfig;
                state.defaultWasteConfig = initialState.defaultWasteConfig;
                state.inactiveWasteTypes = initialState.inactiveWasteTypes;
            })
            .addCase(saveWasteTypeConfig.rejected, (state, action) => {
                state.loading.wasteUpdate = false;
            })
            .addCase(resetBioInterval.pending, (state, action) => {
                state.loading.bioReset = true;
            })
            .addCase(resetBioInterval.fulfilled, (state, action) => {
                state.loading.bioReset = false;
                state.selectedDeviceConfig.device = action.payload;
                state.resetBiochallengeDialogOpen = false;
            })
            .addCase(resetBioInterval.rejected, (state, action) => {
                state.loading.bioReset = false;
                state.resetBiochallengeDialogOpen = false;
            })
            .addCase(resetInitKey.pending, (state, action) => {
                state.loading.keyReset = true;
            })
            .addCase(resetInitKey.fulfilled, (state, action) => {
                state.loading.keyReset = false;
                state.selectedDeviceConfig.device = action.payload;
            })
            .addCase(resetInitKey.rejected, (state, action) => {
                state.loading.keyReset = false;
            })
            .addCase(fetchPvSensors.pending, (state, action) => {
                state.pvSensors = initialState.pvSensors;
                state.pvSensorDropdown = initialState.pvSensorDropdown;
            })
            .addCase(fetchPvSensors.fulfilled, (state, action) => {
                state.pvSensors = action.payload;
                state.pvSensorDropdown = action.payload.map((sensor) => {
                    return {
                        key: sensor?.id,
                        value: sensor?.id,
                        text: `${sensor?.manufacturer} - ${sensor?.model}`,
                    };
                });
            })
            .addCase(fetchPvSensors.rejected, (state, action) => {
                state.pvSensors = initialState.pvSensors;
                state.pvSensorDropdown = initialState.pvSensorDropdown;
            });
    },
});

export const fetchDeviceDropdownOptions = createAsyncThunk("deviceInfo/fetchDeviceDropdownOptions", async () => {
    const response = await auth.fetch(`/api/get-serials`);
    return response;
});

export const fetchDeviceConfig = createAsyncThunk("deviceInfo/fetchDeviceConfig", async (configId) => {
    const response = await auth.fetch(`/api/device-config/${configId}`);
    return response;
});

export const fetchDeviceConfigOptions = createAsyncThunk("deviceInfo/fetchDeviceConfigOptions", async () => {
    const response = await auth.fetch(`/api/device-config/`, {
        method: "OPTIONS",
    });
    return response;
});

export const fetchDeviceLogs = createAsyncThunk("deviceInfo/fetchDeviceLogs", async (configId) => {
    const response = await auth.fetch(`/api/device-config-logs/${configId}`);
    return response;
});

export const fetchDeviceTimeline = createAsyncThunk("deviceInfo/fetchDeviceTimeline", async (deviceId) => {
    const response = await auth.fetch(`/api/export/device-configs?device_id=${deviceId}&cycle_count=True`);
    return response;
});

export const fetchDeviceFiles = createAsyncThunk("deviceInfo/fetchDeviceFiles", async (deviceId) => {
    const response = await auth.fetch(`/api/file-request?device_id=${deviceId}`);
    return response;
});

export const fetchDefaultWasteConfig = createAsyncThunk(
    "deviceInfo/fetchDefaultWasteConfig",
    /** @param params {{ wasteType: string, facilityId: number, getSystemDefaults: boolean }} */
    async (params) => {
        const response = await auth.fetch(
            `/api/get-waste-type-config?` +
                new URLSearchParams({
                    facility_id: params.facilityId.toString(),
                    waste_type: params.wasteType,
                    get_system_default: params.getSystemDefaults ? "True" : "False",
                }),
            {
                method: "GET",
            }
        );
        return response;
    }
);

export const fetchExtraDeviceInfo = createAsyncThunk("deviceInfo/fetchExtraDeviceInfo", async (configId) => {
    const response = await auth.fetch(`/api/get_extra_find_a_machine_info/`, {
        method: "POST",
        body: JSON.stringify({ device_config_id: configId }),
    });
    return response;
});

export const fetchPvSensors = createAsyncThunk("deviceInfo/fetchPvSensors", async (configId) => {
    const response = await auth.fetch(`/api/pressure-sensor/`, {
        method: "GET",
    });
    return response;
});

export const fetchRegulatoryCompliance = createAsyncThunk("deviceInfo/fetchRegulatoryCompliance", async (deviceId) => {
    const response = await auth.fetch(`/api/regulatory-compliance/`);
    return response;
});

export const patchDeviceConfig = createAsyncThunk(
    "deviceInfo/patchDeviceConfig",
    /** @param params {{ configId: number, body: object }} */
    async (params) => {
        const response = await auth.fetch(`/api/device-config/${params.configId}`, {
            method: "PATCH",
            body: JSON.stringify(params.body),
        });
        return response;
    }
);

export const resetBioInterval = createAsyncThunk(
    "deviceInfo/resetBioInterval",
    /** @param params {{ deviceId: number, body: object }} */
    async (params) => {
        const response = await auth.fetch(`/api/device/${params.deviceId}`, {
            method: "PATCH",
            body: JSON.stringify(params.body),
        });
        return response;
    }
);

export const resetInitKey = createAsyncThunk(
    "deviceInfo/resetInitKey",
    /** @param params {{ deviceId: number, body: object }} */
    async (params) => {
        const response = await auth.fetch(`/api/device/${params.deviceId}`, {
            method: "PATCH",
            body: JSON.stringify(params.body),
        });
        return response;
    }
);

export const saveWasteTypeConfig = createAsyncThunk("deviceInfo/saveWasteTypeConfig", async (body) => {
    const response = await auth.fetch(`/api/save-waste-type-config/`, {
        method: "POST",
        body: JSON.stringify(body),
    });
    return response;
});

const customerDevicesOnlySelector = (state) => state.deviceInfo.customerDevicesOnly;
const distributorSelector = (state) => state.deviceInfo.selectedDistributor;
const deviceDropdownSelector = (state) => state.deviceInfo.deviceDropdownOptions;

export const selectFilteredDropdownOptions = createSelector(
    [customerDevicesOnlySelector, distributorSelector, deviceDropdownSelector],
    (customerDevicesOnly, selectedDistributor, deviceDropdownOptions) => {
        if (customerDevicesOnly) {
            if (selectedDistributor) {
                return deviceDropdownOptions.filter(
                    (device) =>
                        device?.distributor_id === selectedDistributor?.value &&
                        !sterilisCustomers.includes(device?.customer_id)
                );
            }
            return deviceDropdownOptions.filter((device) => !sterilisCustomers.includes(device?.customer_id));
        }

        if (selectedDistributor) {
            return deviceDropdownOptions.filter((device) => device?.distributor_id === selectedDistributor?.value);
        }
        return deviceDropdownOptions;
    }
);

export const selectDistributor = createSelector([(state) => state.deviceInfo.selectedDeviceConfig], (config) =>
    config ? config.facility.customer.distributor : null
);

export const selectCustomer = createSelector([(state) => state.deviceInfo.selectedDeviceConfig], (config) =>
    config ? config.facility.customer : null
);

export const selectFacility = createSelector([(state) => state.deviceInfo.selectedDeviceConfig], (config) =>
    config ? config.facility : null
);

export const selectSerial = createSelector([(state) => state.deviceInfo.selectedDeviceConfig], (config) =>
    config ? config.device.serial_number : null
);

const getFileType = (file) => {
    const fileName = file?.device_file_name;
    if (fileName.includes("ethel")) {
        return "ethel";
    } else if (fileName.includes("honeybee")) {
        return "honeybee";
    } else if (fileName.includes("sterilis.0.2.2.db")) {
        return "database";
    } else if (fileName.includes("syslog")) {
        return "syslog";
    }
};

export const {
    setSelectedDeviceConfig,
    setSelectedDevice,
    setSelectedDistributor,
    setDeviceTimezone,
    setExtraDeviceInfo,
    setShowGetDeviceFilesModal,
    setResendActivationCodeModal,
    setResetBiochallengeDialogOpen,
    setDeviceDropdownOptions,
    setCustomerDevicesOnly,
    setUpdatedConfig,
    setSelectedWasteType,
    resetUpdatedConfig,
    resetDeviceInfoState,
    setUpdatedWasteConfig,
    resetWasteTypeConfig,
    setInactiveWasteTypes,
    setNewWasteType,
    setArchiveDialogOpen,
} = deviceInfoSlice.actions;

export default deviceInfoSlice.reducer;
