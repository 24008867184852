/*
*  Container which contains Semantic UI card css for the Upload Documents page on the internal portal
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Trans } from "react-i18next";
import {composeHoc} from "../library/helpers";
import withAuth from "../withAuth";

import UploadDocument from "./UploadDocument";
import { Card } from "semantic-ui-react";
const AbortController = window.AbortController;
const customerAppAbortController = new AbortController();

const UploadDocumentContainer = (props) => (
	<Card fluid>
		<Card.Header style={{ margin: "10px" }}>
			<h2>
				<Trans ns="translations" i18nKey="User-Documents">
					User Documents
				</Trans>
			</h2>
		</Card.Header>
		<Card.Content className="card-body">
			<UploadDocument group={props.group} customerAppAbortController={customerAppAbortController} />
		</Card.Content>
	</Card>
);

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers','SterilisPortalUsers', 'FSEs','SterilisWasteTypeAdmin'], 'internalPage'))(UploadDocumentContainer);
