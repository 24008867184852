/*
* Component for login panel
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

import React, {Component} from 'react';
import '../css/Login.css';
import AuthService from './AuthService';
import {Card} from 'semantic-ui-react'
import {withTranslation as translate} from "react-i18next";
import {Link} from 'react-router-dom';
import {Trans} from "react-i18next";
import sterSolutionsLogo from '../images/sterilis-solutions-svg-logo.svg';
import SimpleLoader from './SimpleLoader';
import {isGroupCustomer, isGroupNotCustomer, isGroupSterilis} from "./library/helpers";
import {composeHoc} from "./library/helpers";
import * as Sentry from "@sentry/browser";
import { withRouter } from "react-router-dom";
import { setGroup, setUser } from "src/features/User/userSlice";
import { connect } from "react-redux";

class Login extends Component {
    constructor() {
        super();
        this.Auth = new AuthService();
    }

    state = {
        username: "",
        password: "",
        isError: false,
        isLoading: false,
        failedAttempts: 0,
        validationError: false,
        ipLocked: false,
    };

    componentDidMount() {
        if (this.Auth.loggedIn()) {
            const group = this.Auth.getGroup();
            if (isGroupNotCustomer(group)) {
                this.props.history.replace("/");
            } else if (isGroupCustomer(group)) {
                this.props.history.replace("/sa");
            }
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault();

        this.setState({ isLoading: true });

        this.Auth.login(this.state.username, this.state.password)
            .then((res) => {
                const authLevel = res.user.group_name;
                this.props.setUser(res.user);
                this.props.setGroup(res.user.group_name);

                // Need to check if location.state exists before checking if location.state.attemptedUrl
                // attemptedUrl will be populated if the user attempted to visit a auth req'd resource without auth and
                // was redirected to the login page
                // The check for !== "/" is to prevent the case where the user visited hostname/ and was redirected to /login, which is
                // a false positive for what we're looking for
                if (
                    this.props.location.state &&
                    this.props.location.state.attemptedUrl &&
                    this.props.location.state.attemptedUrl !== "/"
                ) {
                    const { attemptedQueryParam, attemptedUrl } = this.props.location.state;
                    this.props.history.replace(
                        attemptedQueryParam ? `${attemptedUrl}${attemptedQueryParam}` : attemptedUrl
                    );
                } else {
                    // Else, if the user did not attempt to visit a specific URL before logging in
                    this.setState({ isLoading: false }, () => {
                        // Sterilis users do not need to accept TaC, but customers and dist users do
                        if (isGroupSterilis(authLevel)) {
                            this.props.history.replace("/");
                        } else if (res["user"]["accepted_toc"] === false) {
                            this.props.history.replace("/terms");
                        } else {
                            if (isGroupCustomer(authLevel)) {
                                this.props.history.replace("/sa");
                            } else {
                                this.props.history.replace("/");
                            }
                        }
                    });
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
                // Good example of how to get information from the server during an error
                console.log("error here Login.js 89");
                err.response
                    .json()
                    .then((resp) => {
                        if (resp["ip_locked"]) {
                            this.setState({
                                ipLocked: true,
                                isLoading: false,
                                isError: false,
                            });
                        } else if (resp["banned_or_decommissioned"]) {
                            this.setState({
                                customerBanned: true,
                                isLoading: false,
                                isError: false,
                            });
                        } else {
                            this.handleFailedLogin();
                        }
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        this.handleFailedLogin();
                    });
            });
    };

    handleFailedLogin = () => {
        this.setState((prevState) => {
            return {
                isError: true,
                failedAttempts: prevState.failedAttempts + 1,
                isLoading: false,
                validationError: true,
            };
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        const { failedAttempts, isError, isLoading, isLocked, validationError, customerBanned, ipLocked } = this.state;
        const { t } = this.props;
        return (
            <div
                className={
                    `login-form ` +
                    (failedAttempts >= 1 ? ` short-failed-login-form ` : "") +
                    (ipLocked || customerBanned || failedAttempts >= 3 ? ` failed-login-form ` : "") +
                    ` top-border-radius`
                }>
                {isLoading ? <SimpleLoader /> : null}
                <div>
                    <Card.Content className="login-header top-border-radius">
                        <img src={sterSolutionsLogo} alt="Sterilis Medical" className="ster-solutions-logo" />
                    </Card.Content>
                    <Card.Content className="login-body">
                        <p className="login-intro">{t("login.intro", "Please enter your credentials to login")}</p>
                        <form onSubmit={this.handleFormSubmit}>
                            <div className="form-group">
                                <input
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    name="username"
                                    id="usernameInput"
                                    type="text"
                                    className={`${validationError ? "float-input-invalid" : ""} form-control`}
                                    required
                                />
                                <label
                                    className={` ${
                                        validationError ? "float-label-invalid" : ""
                                    } form-control-placeholder`}
                                    htmlFor="usernameInput">
                                    {t("Username")}
                                </label>
                            </div>

                            <div className="form-group">
                                <input
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    name="password"
                                    type="password"
                                    id="passwordInput"
                                    className={`${validationError ? "float-input-invalid" : ""} form-control`}
                                    required
                                />
                                <label
                                    className={` ${
                                        validationError ? "float-label-invalid" : ""
                                    } form-control-placeholder`}
                                    htmlFor="passwordInput">
                                    {t("Password")}
                                </label>
                            </div>
                            <input
                                className="form-submit"
                                id="loginButton"
                                value={t("SUBMIT")}
                                type="submit"
                                disabled={isLocked || ipLocked}
                            />
                        </form>
                        <Link to="/forgot-password">
                            <div className="light-hyperlink">{t("Forgot password?")}</div>
                        </Link>
                        {isError && (
                            <div className="login-error">
                                <div>
                                    {t("login.incorrect-creds", "The entered username or password is incorrect.")}
                                </div>
                                {failedAttempts <= 5 && (
                                    <div>
                                        {t(
                                            "login.incorrect-creds-try-again",
                                            "Try again or click forgot password to reset your password."
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {customerBanned && (
                            <div className="login-error">
                                <p>{t("banned.1", "This account has been disabled.")}</p>
                                <p>
                                    {t(
                                        "banned.2",
                                        "Please contact Sterilis support for assistance regarding this matter."
                                    )}
                                </p>
                                <p>{t("banned.3", "We apologize for any inconveniences this may of caused.")}</p>
                            </div>
                        )}

                        {ipLocked && (
                            <div className="login-error slight-padding-bottom">
                                <p>
                                    {t(
                                        "locked.1",
                                        "Your account has been temporarily locked due to the amount of failed login attempts."
                                    )}
                                </p>
                                <p className="light-hyperlink">
                                    <Trans ns="translations" i18nKey="reset-password">
                                        Click <Link to="/forgot-password">here</Link> to reset your password
                                    </Trans>
                                </p>
                            </div>
                        )}
                        <div>
                            <div className="login-error">
                                {ipLocked === false && failedAttempts >= 3 && (
                                    <div className="slight-padding">
                                        {t(
                                            "locked.2",
                                            "Too many unsuccessful attempts in succession will cause your account to be temporarily locked out."
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card.Content>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setGroup: (group) => dispatch(setGroup(group)),
    };
};

export default composeHoc(withRouter, translate("translations"), connect(null, mapDispatchToProps))(Login);
