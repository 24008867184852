/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ParsedCycleTable from "./ParsedCycleTable";
import BurninStatChartContainer from "./BurninStatChartContainer";
import {Checkbox} from "semantic-ui-react";

const BurninContainer = props => {

  const [abnormalBurninValues, setAbnormalBurninValues] = useState({});
  const [burninTest1AbnormalValues, setBurninTest1AbnormalValues] = useState([]);
  const [burninTest2AbnormalValues, setBurninTest2AbnormalValues] = useState([]);
  const [burninTest3AbnormalValues, setBurninTest3AbnormalValues] = useState([]);
  const [burninTest4AbnormalValues, setBurninTest4AbnormalValues] = useState([]);
  const [burninTest5AbnormalValues, setBurninTest5AbnormalValues] = useState([]);
  const [burninTest6AbnormalValues, setBurninTest6AbnormalValues] = useState([]);


  // get burnin abnormal values
  useEffect(() => {
    const burninTest1AbnormalValues = [];
    const burninTest2AbnormalValues = [];
    const burninTest3AbnormalValues = [];
    const burninTest4AbnormalValues = [];
    const burninTest5AbnormalValues = [];
    const burninTest6AbnormalValues = [];

    const abnormalValuesObj = {};

    props.abnormalValues.filter(val => val['burnin_test'] !== 0).forEach(value => {
      if (value['burnin_test'] in abnormalValuesObj) {
        if (value['value_name'] in abnormalValuesObj[value['burnin_test']]) {
        } else {
          abnormalValuesObj[value['burnin_test']][value['value_name']] = {
            ...value
          }
        }
      } else {
        abnormalValuesObj[value['burnin_test']] = {};
        abnormalValuesObj[value['burnin_test']][value['value_name']] = {
          ...value
        }
      }


      if (value['burnin_test'] === 1) {
        burninTest1AbnormalValues.push(value)
      } else if (value['burnin_test'] === 2) {
        burninTest2AbnormalValues.push(value)
      } else if (value['burnin_test'] === 3) {
        burninTest3AbnormalValues.push(value)
      } else if (value['burnin_test'] === 4) {
        burninTest4AbnormalValues.push(value)
      } else if (value['burnin_test'] === 5) {
        burninTest5AbnormalValues.push(value)
      } else if (value['burnin_test'] === 6) {
        burninTest6AbnormalValues.push(value)
      }

    });

    setBurninTest1AbnormalValues(burninTest1AbnormalValues);
    setBurninTest2AbnormalValues(burninTest2AbnormalValues);
    setBurninTest3AbnormalValues(burninTest3AbnormalValues);
    setBurninTest4AbnormalValues(burninTest4AbnormalValues);
    setBurninTest5AbnormalValues(burninTest5AbnormalValues);
    setBurninTest6AbnormalValues(burninTest6AbnormalValues);

    setAbnormalBurninValues(abnormalValuesObj)
  }, [props.abnormalValues.length]);

  const [showBurninTest7CycleSelect, setShowBurninTest7CycleSelect] = useState(false);
  const selectCyclesForBurninTest7Toggle = <Checkbox
    className='digital-output-toggle'
    toggle
    label='Enable Steam Cycles selection for Burnin Test 7'
    onChange={() => setShowBurninTest7CycleSelect(!showBurninTest7CycleSelect)}
    checked={showBurninTest7CycleSelect}
  />;

  const [burninTest7Cycles, _setBurninTest7Cycles] = useState([]);
  const setBurninTest7Cycles = tableRow => _setBurninTest7Cycles(() => {
    if (tableRow.length > 0) {
      return tableRow.map(row => {
        let deviceConfigId = null;
        let cycleID = null;
        if (row['original']['portal_cycle']) {
          if (row['original']['portal_cycle']['device_config']) {
            deviceConfigId = row['original']['portal_cycle']['device_config']['id'];
            cycleID = row['original']['cycle_id'];
          }
        }
        return {
          file_id: row['original']['file_id'],
          cycle_start_line_number: row['original']['cycle_start_line_number'],
          cycle_end_line_number: row['original']['cycle_end_line_number'],
          start_time: row['original']['start_time'],
          cycle_id: cycleID,
          device_config_id: deviceConfigId
        }
      });
    } else {
      return []
    }
  });

  return <React.Fragment>
    <BurninStatChartContainer
      abnormalValuesObj={props.abnormalValuesObj}
      parsedLog={props.parsedLog}
      containsBurninTest={props.containsBurninTest}
      abnormalBurninValues={abnormalBurninValues}
      burninTest1AbnormalValues={burninTest1AbnormalValues}
      burninTest2AbnormalValues={burninTest2AbnormalValues}
      burninTest3AbnormalValues={burninTest3AbnormalValues}
      burninTest4AbnormalValues={burninTest4AbnormalValues}
      burninTest5AbnormalValues={burninTest5AbnormalValues}
      burninTest6AbnormalValues={burninTest6AbnormalValues}
      abnormalValues={props.abnormalValues}
      deviceSerial={props.deviceSerial}
      parserLeftHeader={props.parserLeftHeader}
      scrollOnCycleClick={props.scrollOnCycleClick}
      setScrollOnCycleClick={props.setScrollOnCycleClick}
      zoomParams={props.zoomParams}
      setZoomParams={props.setZoomParams}
      selectCyclesForBurninTest7Toggle={selectCyclesForBurninTest7Toggle}
      setShowBurninTest7CycleSelect={setShowBurninTest7CycleSelect}
      burninTest7Cycles={burninTest7Cycles}
      setIsLoading={props.setIsLoading}
      setLoadingText={props.setLoadingText}
      test7CycleIDs={props.test7CycleIDs}
    />
    <ParsedCycleTable
      data={props.parsedLog}
      setSelectedCycle={props.setSelectedCycle}
      setSelectedIndex={props.setSelectedIndex}
      selectedIndex={props.selectedIndex}
      batchProcessing={props.batchProcessing}
      fetchCycleFromFileID={props.fetchCycleFromFileID}
      setBatchProcessing={props.setBatchProcessing}
      initialLoadFromBatch={props.initialLoadFromBatch}
      showMessages={props.showMessages}
      setShowMessages={props.setShowMessages}
      showBurninContainer={props.showBurninContainer}
      setShowBurninContainer={props.setShowBurninContainer}
      setZoomParams={props.setZoomParams}
      showBurninTest7CycleSelect={showBurninTest7CycleSelect}
      onRowSelectCallback={setBurninTest7Cycles}
      showOnRowSelectCheckboxes={showBurninTest7CycleSelect}
    />
  </React.Fragment>
};

BurninContainer.defaultProps = {
  parsedLog: [],
  abnormalValuesObj: {},
  showMessages: false,
  showBurninContainer: false,
  deviceSerial: '',
};

BurninContainer.propTypes = {
  parsedLog: PropTypes.array,
  abnormalValuesObj: PropTypes.object,
  showMessages: PropTypes.bool,
  showBurninContainer: PropTypes.bool,
  setSelectedCycle: PropTypes.func,
  setSelectedIndex: PropTypes.func,
  setBatchProcessing: PropTypes.func,
  setShowMessages: PropTypes.func,
  fetchCycleFromFileID: PropTypes.func,
  setShowBurninContainer: PropTypes.func.isRequired,
  deviceSerial: PropTypes.string,
  parserLeftHeader: PropTypes.element.isRequired,
  scrollOnCycleClick: PropTypes.bool.isRequired,
  setScrollOnCycleClick: PropTypes.func.isRequired,
};

export default BurninContainer;
