/*
* Simple loading spinner component
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

import React from 'react';
import {Dimmer, Loader} from 'semantic-ui-react'

class SimpleLoader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Dimmer id={this.props.id} active inverted>
          <Loader>
            <div className={this.props.customClass}>
              {this.props.loaderText}
              {this.props.cancelButton && this.props.cancelButton}
            </div>
          </Loader>
        </Dimmer>

      </React.Fragment>
    )
  }
}

export default SimpleLoader;
