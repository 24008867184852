/*
*  Component for the Customer Site Admin tab
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate } from "react-i18next";

import { Trans } from "react-i18next";
import {
    composeHoc,
    AuthReq,
    prettyGroupName,
    isGroupSterilisOrDist,
    isGroupInGroups,
    isGroupDist,
} from "./library/helpers";
import CustomerSiteAdminsTable from "./CustomerSiteAdminsTable";
import SimpleLoader from "./SimpleLoader";
import DeleteSiteAdminModal from "./DeleteSiteAdminModal";
import ReactivateSiteAdminModal from "./ReactivateSiteAdminModal";
import { Icon, Dropdown, Button, Popup } from "semantic-ui-react";
import "../css/CustomerUsers.css";
import AuthService from "./AuthService";
import CustomerCreateASiteAdmin from "./CustomerCreateASiteAdmin";
import EditASiteAdminModal from "./EditASiteAdminModal";
import ChangeUserRoleModal from "./ChangeUserRoleModal";
import ResetSiteAdminPasswordModal from "./ResetSiteAdminPasswordModal";
import * as Sentry from "@sentry/browser";
import { Box, IconButton, Button as MuiButton, Tooltip } from "@mui/material";
import get from "lodash/get";
import { setCreateModalOpen, setUserType, setUserTypeDisplay } from "src/features/User/Create/createUserSlice";
import { connect } from "react-redux";
import {
    AddCircleTwoTone,
    CheckCircleTwoTone,
    EmailTwoTone,
    PersonOffTwoTone,
    PhoneTwoTone,
} from "@mui/icons-material";
import { setEditUser, setEditUserModalOpen } from "src/features/User/Edit/editUserSlice";
import { fetchSiteAdmins, selectSiteAdminsForTable } from "src/features/Customer/SiteAdmins/siteAdminSlice";
import {
    fetchFacilityAdmins,
    selectFacilityAdminsForTable,
} from "src/features/Customer/FacitlityAdmins/facilityAdminSlice";

const Auth = new AuthService();

class SiteAdmins extends React.Component {
    state = {
        appLoading: false,
        addSiteAdminModal: false,
        showDeleteSiteAdminModal: false,
        showReactivateSiteAdminModal: false,
        resetSiteAdminPasswordModal: false,
        queryString: "",
        showLegend: false,
        distributorDropdownArray: [
            {
                key: 0,
                value: 0,
                text: this.props.t("All Distributors"),
            },
        ],
    };

    componentDidMount() {
        this.setState({
            appLoading: true,
            loggedInUserID: this.props.user.user_id,
        });
        if (this.props.hoistFunction) {
            this.props.hoistFunction("fetchSiteAdmins", this.props.fetchSiteAdmins);
            if (this.props.isFacilityTab) {
                this.props.hoistFunction("fetchFacilityAdmins", this.props.fetchFacilityAdmins);
            } else {
                this.props.hoistFunction("fetchCustomerAdmins", this.props.fetchSiteAdmins);
            }
        }
        this.preparePage().then(() => {
            this.setState({ appLoading: false });
        });
    }

    // This handles updates to the siteAdmins and facilityAdmins redux objects
    componentDidUpdate(prevProps) {
        if (prevProps.siteAdmins !== this.props.siteAdmins) {
            const siteAdmins = this.buildTableData(this.props.siteAdmins, this.props.siteAdminsTable);
            this.setState({
                siteAdmins,
            });
        }
        if (prevProps.facilityAdmins !== this.props.facilityAdmins) {
            const facilityAdmins = this.buildTableData(this.props.facilityAdmins, this.props.facilityAdminsTable);
            this.setState({
                facilityAdmins,
            });
        }
    }

    preparePage = async () => {
        if (this.props.isFacilityTab) {
            const facilityAdmins = await this.props.fetchFacilityAdmins().then(() => {
                return this.buildTableData(this.props.facilityAdmins, this.props.facilityAdminsTable);
            });

            this.setState({
                facilityAdmins,
            });
        } else if (this.props.allUsers) {
            const siteAdminsData = await this.props.fetchSiteAdmins("").then((data) => {
                return this.buildTableData(this.props.siteAdmins, this.props.siteAdminsTable);
            });

            this.setState({
                siteAdminsData,
            });
        } else {
            const siteAdminsData = await this.props.fetchSiteAdmins("?group=CustomerPortalUsers").then((data) => {
                return this.buildTableData(this.props.siteAdmins, this.props.siteAdminsTable);
            });

            this.setState({
                siteAdminsData,
            });
        }
    };

    handleSiteAdminModal = () => {
        this.setState((prevState) => {
            return { addSiteAdminModal: !prevState.addSiteAdminModal };
        });
    };

    triggerChangeUserRoleModal = (siteAdmin) => {
        this.setState((prevState) => {
            return {
                changeUserRoleModal: !prevState.changeUserRoleModal,
                CurrentUser: siteAdmin,
            };
        });
    };

    triggerEditSiteAdminModal = (siteAdmin) => {
        this.props.setEditUser(siteAdmin);
        this.props.setEditUserModalOpen(true);
    };

    triggerResetSiteAdminPasswordModal = (siteAdmin) => {
        this.setState((prevState) => {
            return {
                resetSiteAdminPasswordModal: !prevState.resetSiteAdminPasswordModal,
                siteAdminBeingEdited: siteAdmin,
            };
        });
    };

    handleDeleteSiteAdminModal = (siteAdmin) => {
        this.setState((prevState) => {
            return {
                showDeleteSiteAdminModal: !prevState.showDeleteSiteAdminModal,
                siteAdminBeingDeleted: siteAdmin,
            };
        });
    };

    handleReactivateSiteAdminModal = (siteAdmin) => {
        this.setState((prevState) => {
            return {
                showReactivateSiteAdminModal: !prevState.showReactivateSiteAdminModal,
                siteAdminBeingReactivated: siteAdmin,
            };
        });
    };

    resendActivationEmail = (siteAdmin) => {
        this.setState({ appLoading: true });
        Auth.fetch(`/api/resend-activation-email/`, {
            method: "POST",
            body: JSON.stringify({
                portal_user_id: siteAdmin.id,
            }),
        })
            .then((data) => {
                this.setState({ appLoading: false });
                this.props.notifyResendActivationEmailSuccess(siteAdmin.employee.email_address);
            })
            .catch((e) => {
                Sentry.captureException(e);
                this.setState({ appLoading: false });
                this.props.notifyResendActivationEmailFailure(siteAdmin.employee.email_address);
            });
    };

    setQueryString = (queryString) => {
        return this.setState(
            {
                queryString,
            },
            () => {
                this.props.fetchSiteAdmins(queryString);
            }
        );
    };

    siteAdminStatus = (portalUser) => {
        const { t } = this.props;
        if (portalUser.employee.deleted) {
            const employeeName = portalUser.employee.full_name;
            return (
                <div data-search="deleted" className="admin-check-div">
                    <Icon
                        title={t("account_archived", { employeeName: employeeName })}
                        name="user delete"
                        className="red"
                        size="large"
                    />
                </div>
            );
        } else if (portalUser.is_active === false) {
            const employeeName = portalUser.employee.full_name;
            return (
                <div data-search="inactive" className="admin-check-div">
                    <Icon
                        title={t("account_inactive", { employeeName: employeeName })}
                        size="large"
                        className="yellow"
                        onClick={() => this.resendActivationEmail(portalUser)}
                        name="mail square"
                    />
                </div>
            );
        } else {
            return (
                <div data-search="active" className="admin-check-div">
                    <Icon size="large" name="check circle outline" color="green" />
                </div>
            );
        }
    };

    portalUserStatus = (groupName) => {
        if (groupName) {
            return groupName;
        } else {
            return "";
        }
        // Uncomment out the following lines to support multiple groups
        // The table search for this kind of layered JSX doesn't work atm, so I'm removing it for now
        // return <div>
        //   {portalUser.groups.map((group, idx) => {
        //     return <p key={idx}>{prettyGroupName(group.name)}</p>
        //   })}
        // </div>
    };

    siteAdminStatusAction = (portalUser, groupName, allUsers, tabHeader) => {
        const { t } = this.props;
        if (portalUser.employee.deleted) {
            return (
                <Dropdown.Item
                    onClick={() => this.handleReactivateSiteAdminModal(portalUser)}
                    text={
                        <h5>
                            {allUsers ? (
                                <Trans ns="translations" i18nKey="reactivate_user_with_group_name">
                                    Reactivate {{ groupName }}
                                </Trans>
                            ) : (
                                t(`Reactivate ${tabHeader}`)
                            )}
                        </h5>
                    }
                />
            );
        } else if (portalUser.is_active === false) {
            return (
                <React.Fragment>
                    <Dropdown.Item
                        onClick={() => this.resendActivationEmail(portalUser)}
                        text={<h5>{t("Resend activation email")}</h5>}
                    />
                    <Dropdown.Item
                        onClick={() => this.handleDeleteSiteAdminModal(portalUser)}
                        text={
                            <h5>
                                {allUsers ? (
                                    <Trans ns="translations" i18nKey="archive_user_with_group_name">
                                        Archive {{ groupName }}
                                    </Trans>
                                ) : (
                                    t(`Archive ${tabHeader}`)
                                )}
                            </h5>
                        }
                    />
                </React.Fragment>
            );
        } else {
            //cannot allow Site Admins to delete themselves
            if (portalUser.id !== this.state.loggedInUserID) {
                return (
                    <Dropdown.Item
                        onClick={() => this.handleDeleteSiteAdminModal(portalUser)}
                        text={
                            <h5>
                                {allUsers ? (
                                    <Trans ns="translations" i18nKey="archive_user_with_group_name">
                                        Archive {{ groupName }}
                                    </Trans>
                                ) : (
                                    t(`Archive ${tabHeader}`)
                                )}
                            </h5>
                        }
                    />
                );
            }
        }
    };

    portalUserContactInfo = (portalUser) => {
        return (
            <div
                data-search={`${portalUser.employee.phone} ${portalUser.employee.email_address}`}
                className="contact-info-container">
                <div className="contact-info-container-inner">
                    <MuiButton startIcon={<PhoneTwoTone color="info" fontSize="small" />}>
                        <Box component={"a"} sx={{ textAlign: "center" }} href={`tel:${portalUser.employee.phone}`}>
                            {portalUser.employee.phone}
                        </Box>
                    </MuiButton>
                </div>
                <div className="contact-info-container-inner">
                    <MuiButton color="success" startIcon={<EmailTwoTone color="success" fontSize="small" />}>
                        <Box
                            sx={{ textTransform: "none" }}
                            component={"a"}
                            href={`mailto:${portalUser.employee.email_address}`}>
                            {portalUser.employee.email_address}
                        </Box>
                    </MuiButton>
                </div>
            </div>
        );
    };

    buildTableData = (siteAdmins, siteAdminTableData) => {
        const { t, group, allUsers, activeTab, isFacilityTab } = this.props;
        let tabHeader, tabpermissiongroup, changeRoleTo;

        switch (activeTab) {
            case "FacilityAdmins":
                tabHeader = "Facility Admin";
                changeRoleTo = "Site Admin";
                tabpermissiongroup = [
                    "SterilisSuperUsers",
                    "SterilisPortalUsers",
                    "DistributorAdmins",
                    "SterilisWasteTypeAdmin",
                    "CustomerPortalUsers",
                    "FacilityAdmin",
                ];
                break;
            case "SiteAdmins":
                tabHeader = "Site Admin";
                changeRoleTo = "Facility Admin";
                tabpermissiongroup = [
                    "SterilisSuperUsers",
                    "SterilisPortalUsers",
                    "DistributorAdmins",
                    "SterilisWasteTypeAdmin",
                    "CustomerPortalUsers",
                ];
                break;
            default:
                if (isFacilityTab) {
                    tabHeader = "Facility Admin";
                    changeRoleTo = "Site Admin";
                    tabpermissiongroup = [
                        "SterilisSuperUsers",
                        "SterilisPortalUsers",
                        "DistributorAdmins",
                        "SterilisWasteTypeAdmin",
                        "CustomerPortalUsers",
                        "FacilityAdmin",
                    ];
                } else {
                    tabHeader = "Site Admin";
                    changeRoleTo = "Facility Admin";
                    tabpermissiongroup = [
                        "SterilisSuperUsers",
                        "SterilisPortalUsers",
                        "DistributorAdmins",
                        "SterilisWasteTypeAdmin",
                        "CustomerPortalUsers",
                    ];
                }
                break;
        }

        return siteAdminTableData.map((admin) => {
            const siteAdmin = siteAdmins.find((adm) => adm.id === admin.id);
            const groupName = admin.group_name;
            return {
                ...admin,
                status: allUsers ? (
                    admin.group_name
                ) : siteAdmin.is_active ? (
                    <div data-search="active" className="admin-check-div">
                        <Tooltip title={`${siteAdmin.username} is Active`}>
                            <CheckCircleTwoTone color="success" />
                        </Tooltip>
                    </div>
                ) : siteAdmin.employee.deleted ? (
                    <div data-search="deleted" className="admin-check-div">
                        <Tooltip title={t("account_archived", { employeeName: siteAdmin.employee.full_name })}>
                            <PersonOffTwoTone color="error" />
                        </Tooltip>
                    </div>
                ) : (
                    <div data-search="inactive" className="admin-check-div">
                        <Tooltip title={t("account_inactive", { employeeName: siteAdmin.employee.full_name })}>
                            <IconButton onClick={() => this.resendActivationEmail(siteAdmin)}>
                                <EmailTwoTone color="warning" />
                            </IconButton>
                        </Tooltip>
                    </div>
                ),
                contact_info: this.portalUserContactInfo(siteAdmin),
                actions: (
                    <div className="overflow-visible-td">
                        <Dropdown id={siteAdmin.id} pointing="top right" icon="ellipsis horizontal">
                            <Dropdown.Menu>
                                <Dropdown.Header
                                    content={<h4>{t(`${allUsers ? groupName : tabHeader} Actions`)}</h4>}
                                />
                                <Dropdown.Divider />
                                <AuthReq userGroup={group} requiredRoles={tabpermissiongroup}>
                                    {siteAdmin.employee.deleted === null && (
                                        <Dropdown.Item
                                            id={"edit-" + siteAdmin.id}
                                            onClick={() => this.triggerEditSiteAdminModal(siteAdmin)}
                                            text={
                                                <h5>
                                                    {allUsers ? (
                                                        <Trans ns="translations" i18nKey="edit_user_with_group_name">
                                                            Edit {{ groupName }}
                                                        </Trans>
                                                    ) : (
                                                        t(`Edit ${tabHeader}`)
                                                    )}
                                                </h5>
                                            }
                                        />
                                    )}
                                    {this.siteAdminStatusAction(siteAdmin, groupName, allUsers, tabHeader)}
                                </AuthReq>
                                <AuthReq
                                    userGroup={group}
                                    requiredRoles={[
                                        "SterilisSuperUsers",
                                        "SterilisPortalUsers",
                                        "DistributorAdmins",
                                        "SterilisWasteTypeAdmin",
                                        "CustomerPortalUsers",
                                    ]}>
                                    {!allUsers
                                        ? siteAdmin.employee.deleted === null && (
                                              <Dropdown.Item
                                                  id={"change-" + siteAdmin["id"]}
                                                  onClick={() => this.triggerChangeUserRoleModal(siteAdmin)}
                                                  text={<h5>{t(`Change to ${changeRoleTo}`)}</h5>}
                                              />
                                          )
                                        : null}
                                </AuthReq>
                                <Dropdown.Item
                                    onClick={() => this.triggerResetSiteAdminPasswordModal(siteAdmin)}
                                    text={<h5>{t("Reset password")}</h5>}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ),
            };
        });
    };

    setSiteAdminCount = (count) => {
        this.setState({
            siteAdminCount: count,
        });
    };

    handleNewSiteAdminClick = (type) => {
        if (type === "FACILITY ADMIN") {
            this.props.setUserType("facilityadmin");
            this.props.setUserTypeDisplay("Facility Administrator");
        }
        if (type === "SITE ADMIN") {
            this.props.setUserType("customer");
            this.props.setUserTypeDisplay("Site Administrator");
        }
        if (type === "OPERATOR") {
        }

        this.props.setCreateModalOpen(true);
    };

    render() {
        const { t, allUsers, createUserThroughButton, isCurrentUserSterilisOrDist, group, isFacilityTab } = this.props;
        let tabHeader, i18key, tabpermissiongroup, changeRoleTo;
        const {
            appLoading,
            siteAdminCount,
            addSiteAdminModal,
            showDeleteSiteAdminModal,
            siteAdminBeingDeleted,
            showReactivateSiteAdminModal,
            siteAdminBeingReactivated,
            siteAdminBeingEdited,
            editSiteAdminModal,
            changeUserRoleModal,
            CurrentUser,
            resetSiteAdminPasswordModal,
            facilityAdmins,
            siteAdmins,
        } = this.state;

        if (isFacilityTab) {
            tabHeader = "FACILITY ADMIN";
            i18key = "total_facility_admins";
            changeRoleTo = "Site Admin";
            tabpermissiongroup = [
                "SterilisSuperUsers",
                "SterilisPortalUsers",
                "DistributorAdmins",
                "SterilisWasteTypeAdmin",
                "CustomerPortalUsers",
                "FacilityAdmin",
            ];
        } else {
            tabHeader = "SITE ADMIN";
            i18key = "total_site_admins";
            changeRoleTo = "Facility Admin";
            tabpermissiongroup = [
                "SterilisSuperUsers",
                "SterilisPortalUsers",
                "DistributorAdmins",
                "SterilisWasteTypeAdmin",
                "CustomerPortalUsers",
            ];
        }

        return (
            <div>
                {allUsers ? (
                    <Popup
                        content={
                            <div>
                                <div className="small-width minor-padding light-red-tr">
                                    {t("User has been archived")}
                                </div>
                                <div className="small-width minor-padding light-yellow-tr">
                                    {t("User has not activated account via email")}
                                </div>
                            </div>
                        }
                        on="click"
                        position="bottom right"
                        trigger={<Button className="grey-btn table-legend" content={t("Show Table Legend")} />}
                    />
                ) : null}
                <div className="customer-header">
                    <div className="stat-info">
                        <p className="large-num">{siteAdminCount}</p>
                        <div className="stat-name">
                            {allUsers ? (
                                <Trans ns="translations" i18nKey="total_portal_users">
                                    TOTAL <br /> PORTAL USERS
                                </Trans>
                            ) : (
                                <Trans ns="translations" i18nKey={i18key}>
                                    TOTAL <br /> {tabHeader}
                                </Trans>
                            )}
                        </div>
                    </div>
                    {isCurrentUserSterilisOrDist ? this.props.filterByDiv : null}
                    {createUserThroughButton ? (
                        <div className="right-actions negative-bottom-margin">
                            <AuthReq userGroup={group} requiredRoles={tabpermissiongroup}>
                                <MuiButton
                                    id="createSiteAdminBtn"
                                    size="large"
                                    disabled={!this.props.finishedLoading}
                                    endIcon={<AddCircleTwoTone />}
                                    variant="contained"
                                    onClick={() => this.handleNewSiteAdminClick(tabHeader)}>
                                    {t(`NEW ${tabHeader}`)}
                                </MuiButton>
                            </AuthReq>
                        </div>
                    ) : null}
                </div>
                {isFacilityTab ? (
                    this.props.facilityAdminsLoading ? (
                        <SimpleLoader />
                    ) : null
                ) : this.props.siteAdminsLoading ? (
                    <SimpleLoader />
                ) : null}
                <div
                    // className='customer-content'
                    className={allUsers ? "ster-content" : "customer-content"}>
                    <CustomerSiteAdminsTable
                        siteAdmins={isFacilityTab ? facilityAdmins : siteAdmins}
                        selectedCustomer={this.props.selectedCustomer}
                        group={this.props.group}
                        allUsers={allUsers}
                        preparePage={isFacilityTab ? this.props.fetchFacilityAdmins : this.props.fetchSiteAdmins}
                        setQueryString={this.setQueryString}
                        setSiteAdminCount={this.setSiteAdminCount}
                        distributorDropdownArray={this.props.distributorFilterOptions}
                        tabpermissiongroup={tabpermissiongroup}
                        isFacilityTab={this.props.isFacilityTab}
                    />
                    <AuthReq userGroup={group} requiredRoles={tabpermissiongroup}>
                        {!allUsers && addSiteAdminModal && (
                            <CustomerCreateASiteAdmin
                                group={this.props.group}
                                notifySiteAdminSuccess={this.props.notifySiteAdminSuccess}
                                handleSiteAdminModal={this.handleSiteAdminModal}
                                addSiteAdminModal={addSiteAdminModal}
                                simpleCustomerObj={this.props.simpleCustomerObj}
                                simpleCustomerNameObj={this.props.simpleCustomerNameObj}
                                simpleCustomerFacilityObj={this.props.simpleCustomerFacilityObj}
                                customerDropdown={this.props.customerDropdown}
                                notifySiteAdminFailure={this.props.notifySiteAdminFailure}
                                //fetchSiteAdmins={this.fetchSiteAdmins}
                                fetchDeviceOperators={this.props.fetchDeviceOperators}
                                fetchCustomerMachines={this.props.fetchCustomerMachines}
                                isFacilityTab={this.props.isFacilityTab}
                            />
                        )}
                        {showDeleteSiteAdminModal && (
                            <DeleteSiteAdminModal
                                group={this.props.group}
                                showDeleteSiteAdminModal={showDeleteSiteAdminModal}
                                handleDeleteSiteAdminModal={this.handleDeleteSiteAdminModal}
                                fetchSiteAdmins={
                                    isFacilityTab ? this.props.fetchFacilityAdmins : this.props.fetchSiteAdmins
                                }
                                fetchDeviceOperators={this.props.fetchDeviceOperators}
                                siteAdminBeingDeleted={siteAdminBeingDeleted}
                                notifyGenericSuccess={this.props.notifyGenericSuccess}
                                notifyGenericFailure={this.props.notifyGenericFailure}
                                allUsers={allUsers}
                            />
                        )}

                        {showReactivateSiteAdminModal && (
                            <ReactivateSiteAdminModal
                                group={this.props.group}
                                showReactivateSiteAdminModal={showReactivateSiteAdminModal}
                                handleReactivateSiteAdminModal={this.handleReactivateSiteAdminModal}
                                fetchSiteAdmins={
                                    isFacilityTab ? this.props.fetchFacilityAdmins : this.props.fetchSiteAdmins
                                }
                                fetchDeviceOperators={this.props.fetchDeviceOperators}
                                siteAdminBeingReactivated={siteAdminBeingReactivated}
                                notifyGenericSuccess={this.props.notifyGenericSuccess}
                                notifyGenericFailure={this.props.notifyGenericFailure}
                                allUsers={allUsers}
                            />
                        )}
                        {editSiteAdminModal && (
                            <EditASiteAdminModal
                                siteAdminBeingEdited={siteAdminBeingEdited}
                                editSiteAdminModal={editSiteAdminModal}
                                triggerEditSiteAdminModal={this.triggerEditSiteAdminModal}
                                //fetchSiteAdmins={this.fetchSiteAdmins}
                                fetchDeviceOperators={this.props.fetchDeviceOperators}
                                notifyGenericSuccess={this.props.notifyGenericSuccess}
                                notifyGenericFailure={this.props.notifyGenericFailure}
                                allUsers={allUsers}
                                isFacilityTab={this.props.isFacilityTab}
                                customerDropdown={this.props.customerDropdown}
                                group={this.props.group}
                            />
                        )}
                        {changeUserRoleModal && (
                            <ChangeUserRoleModal
                                CurrentUser={CurrentUser}
                                changeUserRoleModal={changeUserRoleModal}
                                triggerChangeUserRoleModal={this.triggerChangeUserRoleModal}
                                fetchSiteAdmins={
                                    isFacilityTab ? this.props.fetchFacilityAdmins : this.props.fetchSiteAdmins
                                }
                                refreshSiteAdmins={this.props.refreshSiteAdmins}
                                fetchDeviceOperators={this.props.fetchDeviceOperators}
                                notifyGenericSuccess={this.props.notifyGenericSuccess}
                                notifyGenericFailure={this.props.notifyGenericFailure}
                                allUsers={allUsers}
                                isFacilityTab={this.props.isFacilityTab}
                                group={this.props.group}
                                customerFacilityDropdown={this.props.customerFacilityDropdown}
                                fetchCustomerMachines={this.props.fetchCustomerMachines}
                                changeRoleTo={changeRoleTo}
                            />
                        )}
                        {resetSiteAdminPasswordModal && (
                            <ResetSiteAdminPasswordModal
                                siteAdminBeingEdited={siteAdminBeingEdited}
                                resetSiteAdminPasswordModal={resetSiteAdminPasswordModal}
                                triggerResetSiteAdminPasswordModal={this.triggerResetSiteAdminPasswordModal}
                                fetchSiteAdmins={
                                    isFacilityTab ? this.props.fetchFacilityAdmins : this.props.fetchSiteAdmins
                                }
                                fetchDeviceOperators={this.props.fetchDeviceOperators}
                                notifyGenericSuccess={this.props.notifyGenericSuccess}
                                notifyGenericFailure={this.props.notifyGenericFailure}
                                allUsers={allUsers}
                            />
                        )}
                    </AuthReq>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(setUserType(userType)),
        setUserTypeDisplay: (typeDisplay) => dispatch(setUserTypeDisplay(typeDisplay)),
        setCreateModalOpen: (open) => dispatch(setCreateModalOpen(open)),
        setEditUser: (user) => dispatch(setEditUser(user)),
        setEditUserModalOpen: (open) => dispatch(setEditUserModalOpen(open)),
        fetchSiteAdmins: (queryString) => dispatch(fetchSiteAdmins(queryString)),
        fetchFacilityAdmins: () => dispatch(fetchFacilityAdmins()),
    };
};

const mapStateToProps = (state) => ({
    fromFacilityTab: state.createUser.fromFacilityTab,
    activeTab: state.customer.activeTab,
    siteAdmins: state.siteAdmin.siteAdmins,
    siteAdminsLoading: state.siteAdmin.loading,
    distributorFilterOptions: state.siteAdmin.distributorFilterOptions,
    siteAdminsTable: selectSiteAdminsForTable(state),
    facilityAdmins: state.facilityAdmin.facilityAdmins,
    facilityAdminsTable: selectFacilityAdminsForTable(state),
    facilityAdminsLoading: state.facilityAdmin.loading,
});

export default composeHoc(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(SiteAdmins);
