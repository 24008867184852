/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "../../AuthService";
import SimpleLoader from "../../SimpleLoader";
import debounce from "lodash/debounce";

const Auth = new AuthService();

class EditFirmwareModal extends React.Component {
  state = {
    firmwareVersion: '',
    description: '',
    checksum: '',
    linuxVersion: '',
    allowModalToBeClosed: true,
  };

  componentDidMount() {
    this.setState({
      firmwareVersion: this.props.firmware['firmware_version'],
      description: this.props.firmware['description'],
      checksum: this.props.firmware['checksum'],
      linuxVersion: this.props.firmware['linux_version_internal'],
    })
  }

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    name === 'checksum' && this.debouncedCheckChecksum(value);
    this.setState({[name]: value});
  };

  debouncedCheckChecksum = debounce((checksum) => this.checkChecksum(checksum), 500);

  checkChecksum = (checksum) => {
    this.setState({
      checksumValidationError: checksum.length !== 40
    }, () => checksum.length !== 40 && this.shakeChecksum());
  };

  shakeChecksum = () => {
    this.setState({
      setChecksumShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setChecksumShake: false,
        })
      }, 1000)
    })
  };

  handleLinuxChange = (event, data) => {
    this.setState({
      linuxVersion: data.value,
    });
  };

  editFirmware = (e) => {
    e.preventDefault();
    const {
      firmware
    } = this.props;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });

    Auth.fetch(`/api/firmware/${firmware['id']}`, {
      method: 'PATCH',
      body: JSON.stringify({
        'firmware_version': this.state.firmwareVersion,
        'description': this.state.description,
        'checksum': this.state.checksum,
        'linux_version': this.state.linuxVersion,
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true
      }, () => {
        this.props.notifySuccess('edited', data['firmware_version']);
        this.props.triggerEditFirmwareModal(null);
        this.props.preparePage();
      });
    }).catch(err => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true
      }, () => {
        this.props.notifyFailure();
        this.props.triggerEditFirmwareModal(null);
      });
    });
  };

  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading,
      checksumValidationError,
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

    const linuxDropdownOptions = [
      {
        key: '4.19.58',
        value: '4.19.58',
        text: 'Buster/DHCP 4.19+',
      },
      {
        key: '4.9.35',
        value: '4.9.35',
        text: 'Jessie/NM 4.9.35-v7',
      },
    ];
    return (
      <TransitionablePortal
        open={this.props.editFirmwareModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerEditFirmwareModal(null)
          }}
        >
          <Header>
            <h2>{t('Edit software information')}</h2>
          </Header>
          <Modal.Content>
            {modalLoading ? (
              <SimpleLoader/>
            ) : null}
            <form id='editFirmwareForm'
                  onSubmit={this.editFirmware}
            >
              <div className="form-group">
                <input value={this.state.firmwareVersion}
                       name='firmwareVersion'
                       onChange={this.handleChange}
                       type="text"
                       id="firmwareVersionInput"
                       className="form-control"
                       required/>
                <label className="form-control-placeholder"
                       htmlFor="firmwareVersionInput">{t('Software Version')}</label>
              </div>
              <div className="form-group">
                <label className='fake-float'>{t('Linux version')}</label>
                <Dropdown
                  className='wide-dropdown'
                  search
                  selection
                  onChange={this.handleLinuxChange}
                  fluid
                  value={this.state.linuxVersion}
                  id='linuxDropdown'
                  options={linuxDropdownOptions}
                />
              </div>
              <div className="form-group">
                <input value={this.state.checksum}
                       name='checksum'
                       onChange={this.handleChange}
                       type="text"
                       id="checksumInput"
                       className="form-control"
                       autoComplete='nope'
                       required/>
                <label
                  className={`form-control-placeholder ${checksumValidationError && 'label-validation-error'}`}
                  htmlFor="checksumInput">{t('Checksum')}</label>
              </div>
              <div className="form-group">
                <label className="fake-float">{t('Description')}</label>
                <textarea value={this.state.description}
                          name='description'
                          onChange={this.handleChange}
                          rows={5}
                          id="descriptionInput"
                          className="form-control"
                          required/>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    form='editFirmwareForm'
                    disabled={checksumValidationError}
            >{t('Submit changes')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(EditFirmwareModal);
