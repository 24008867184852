/*
*  Component responsible for displaying the Pressure and Temperature cycle graph
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import {prettyWastetypes, cleanCycleType, calculateCycleTime} from "../library/helpers";

require("highcharts/modules/exporting")(Highcharts);

class PressureTemperatureGraph extends React.Component {
  state = {
    pressureSeries: [],
    tempSeries: [],
    // currentPressureUnit : 'PSI(g)',
    pressureUnitSelectionText: 'Switch to<br>PSI(a)',
    options: {},
  };
  pressureTempChart = React.createRef();

  componentDidMount() {
    this.setState({
      width: this.props.width,
      userTimezone: this.props.userTimezone,
      deviceTimezone: this.props.deviceTimezone,
      dashboardResized: this.props.dashboardResized,
      cycleTableIsExpanded: this.props.cycleTableIsExpanded
    })
  }

  componentDidUpdate(prevProps) {
    // If this prop exists, the initial xhr calls have finished
    if (prevProps.cycleEvents !== this.props.cycleEvents) {
      this.updateChart()
    } else {
      if (this.state.dashboardResized && this.props.cycleEvents) {
        this.populateGraph()
      }
    }

    if (prevProps.cycleTableIsExpanded !== this.props.cycleTableIsExpanded) {
      //  This is how you can invoke the highchart functions
      //  Use the ref and traverse to the chart. from there, you have access to funcs like reflow
      this.pressureTempChart.current.chart.reflow();
      this.pressureTempChart.current.chart.redraw();

    }
  }

  getCaptionGraph = (cleanTime, cycleMetadata, t, deviceSerial) => {
    if (Object.keys(cycleMetadata).length > 0){
        const cookTime = cycleMetadata.cook_time ? Math.round(cycleMetadata.cook_time) : 0
        const steamTime = cycleMetadata.steam_time ? Math.round(cycleMetadata.steam_time) : 0
        return (
            "<b>" + t('Device Serial') + ": </b><em>" + deviceSerial + "</em><br>" +
            "<b>" + t('Date & time') + ": </b><em>" + cleanTime + "</em><br>" +
            "<b>" + t('Weight') + ": </b><em>" + cycleMetadata['weight'] + "</em><br>" +
            "<b>" + t('Load Type') + ": </b><em>" + prettyWastetypes(cycleMetadata.waste_type) + "</em><br>" +
            "<b>" + t('Cycle Type') + ": </b><em>" + cleanCycleType(cycleMetadata.cycle_type) + "</em><br>" +
            "<b>" + t('Cycle Status') + ": </b><em>" + cycleMetadata.last_cycle_status.message + "</em><br>" +
            "<b>" + t('Cycle Time') + ": </b><em>" + calculateCycleTime(cycleMetadata['time_started'], cycleMetadata['time_ended'], cycleMetadata['latest_cycle_event_timestamp'], cycleMetadata['latest_cycle_status_timestamp']) + "</em><br>" +
            "<b>" + t('Steam Time') + ": </b><em>" +  `${steamTime} ${steamTime > 1 ? t('mins') : t('min')}` + "</em><br>" +
            "<b>" + t('Cook Time') + ": </b><em>" + `${cookTime} ${cookTime > 1 ? t('mins') : t('min')}`  + "</em><br>" +
            "<b>" + t('User') + ": </b><em>" + cycleMetadata.user + "</em><br>"
        )
    }
  }

  populateGraph = () => {
    const {
      cycleEvents,
      cycleMetadata,
      t,
      currentPressureUnit,
      deviceSerial
    } = this.props;
    const {
      userTimezone,
      deviceTimezone,
      // currentPressureUnit
    } = this.state;
    const pressureArrayG = [];
    const pressureArrayA = [];
    const tempArray = [];
    const timeArray = [];
    const ambientPressure = cycleMetadata['ambient_pressure'];
    cycleEvents.forEach((event) => {
      const pressureG = parseFloat((event.pv_value - ambientPressure).toFixed(2));
      timeArray.push(
        moment(event.timestamp).tz(deviceTimezone ? deviceTimezone : userTimezone).format("HH:mm:ss")
      );
      tempArray.push(
        event.tv_value
      );
      pressureArrayA.push(
        event.pv_value
      );
      pressureArrayG.push(
        pressureG
      )
    });

    const pressureArray = currentPressureUnit === 'PSI(g)' ? pressureArrayG : pressureArrayA;
    const pressureUnitSelectionText = currentPressureUnit === 'PSI(g)' ? 'Switch to<br>PSI(a)' : 'Switch to<br>PSI(g)';


    const maxPressure = this.calculateMaxPressure();

    const pressureSeries = {
      name: t('Pressure'),
      type: 'spline',
      yAxis: 1,
      data: pressureArray,
      color: 'rgba(190, 107, 68, 0.8)',
      tooltip: {
        valueSuffix: currentPressureUnit
      }
    };
    const tempSeries = {
      name: t('Temp'),
      type: 'spline',
      data: tempArray,
      color: 'rgba(5, 159, 194, 0.8)',
      tooltip: {
        valueSuffix: '°C'
      }
    };
    const cleanTime = moment(cycleMetadata['time_started']).tz(deviceTimezone ? deviceTimezone : userTimezone).format("YYYY-MM-DD HH:mm:ss z");
    const steamGraphTitle = `${t(cycleMetadata['cycle_type'])}<br>${t('Started')}: ${cleanTime}`;
    const steamGraphSubtitle = this.generateSteamGraphSubtitle(cycleMetadata['max_tv'], maxPressure, currentPressureUnit);


    const options = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          position: {
            x: 0,
            y: 170,
          }
        }
      },
      title: {
        text: steamGraphTitle,
        style: {
          color: '#009FC2',
          fontSize: '20px',
          fontFamily: 'Arial'
        }
      },
      caption: {
        text: this.getCaptionGraph(cleanTime, cycleMetadata, t, deviceSerial)
      },
      subtitle: {
        text: steamGraphSubtitle,
      },
      xAxis: [{
        categories: timeArray,
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}°C',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        title: {
          text: t('Temperature'),
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        // min: 0,
        // max: 150,
        startOnTick: false,
        tickInterval: 10
      }, { // Secondary yAxis
        title: {
          text: t('Pressure'),
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: `{value} ${currentPressureUnit}`,
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true,
        // min: currentPressureUnit === 'PSI(g)' ? -20 : 0,
        // max: currentPressureUnit === 'PSI(g)' ? 40 : 55,
        startOnTick: false,
        gridLineColor: 'transparent',
        tickInterval: 5
      }],
      tooltip: {
        shared: true
      },
      credits: {
        enabled: false
      },
      legend: {
        layout: 'vertical',
        itemDistance: 50,
        align: 'left',
        x: -10,
        verticalAlign: 'top',
        y: -10,
        floating: true,
        backgroundColor: 'rgba(0,0,0,0)'
        //backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
      },
      series: [
        pressureSeries,
        tempSeries
      ],
      exporting: {
        buttons: {
          customButton: {
            text: pressureUnitSelectionText,
            x: -10,
            y: 25,
            onclick: () => {
              this.props.setCurrentPressureUnit(this.populateGraph)
              // if(currentPressureUnit === 'PSI(g)'){
              //   this.setState({
              //     currentPressureUnit : 'PSI(a)'
              //   }, () => this.populateGraph())
              // }else{
              //   this.setState({
              //     currentPressureUnit : 'PSI(g)'
              //   }, () => this.populateGraph())
              // }
            }
          }
        }
      },
    };
    this.setState({
      dashboardResized: false,
      options: options,
      pressureArrayG: pressureArrayG,
      pressureArrayA: pressureArrayA,
      tempArray: tempArray,
    });
  };

  setChartToState = (chart) => {
    this.setState({
      chart: chart
    })
  };

  calculateMaxPressure = () => {
    const {
      currentPressureUnit,
      cycleMetadata,
    } = this.props;
    const ambientPressure = cycleMetadata['ambient_pressure'];

    return currentPressureUnit === 'PSI(g)' ? parseFloat(cycleMetadata['max_pv'] - ambientPressure).toFixed(2) : cycleMetadata['max_pv']
  };

  generateSteamGraphSubtitle = (maxTemp, maxPressure, currentPressureUnit) => {
    const {t} = this.props;
    if (maxTemp === null && maxPressure === null) {
      // Good example of t function interpolation
      return t('no-pres-temp-for-steam-graph', {break: '<br/>', interpolation: { escapeValue: false }})
    } else {
      return `${t('Max temperature')}: ${maxTemp} °C<br>${t('Max pressure')}: ${maxPressure ? maxPressure : ''} ${maxPressure ? currentPressureUnit : ''}`;
    }
  };

  updateChart = () => {
    const {
      cycleEvents,
      cycleMetadata,
      t,
      currentPressureUnit,
      deviceSerial
    } = this.props;
    const {
      userTimezone,
      deviceTimezone,
      // currentPressureUnit
    } = this.state;
    const pressureArrayG = [];
    const pressureArrayA = [];
    const tempArray = [];
    const timeArray = [];
    const ambientPressure = cycleMetadata['ambient_pressure'];
    cycleEvents.forEach((event) => {
      const pressureG = parseFloat((event.pv_value - ambientPressure).toFixed(2));
      timeArray.push(
        moment(event.timestamp).tz(deviceTimezone ? deviceTimezone : userTimezone).format("HH:mm:ss")
      );
      tempArray.push(
        event.tv_value
      );
      pressureArrayA.push(
        event.pv_value
      );
      pressureArrayG.push(
        pressureG
      )
    });

    const pressureArray = currentPressureUnit === 'PSI(g)' ? pressureArrayG : pressureArrayA;
    const pressureUnitSelectionText = currentPressureUnit === 'PSI(g)' ? `${'Switch to'}<br>PSI(a)` : `${'Switch to'}<br>PSI(g)`;

    const maxPressure = this.calculateMaxPressure();

    const cleanTime = moment(cycleMetadata['time_started']).tz(deviceTimezone ? deviceTimezone : userTimezone).format("YYYY-MM-DD HH:mm:ss z");
    const steamGraphTitle = `${t(cycleMetadata['cycle_type'])}<br>${t('Started')}: ${cleanTime}`;
    const steamGraphSubtitle = this.generateSteamGraphSubtitle(cycleMetadata['max_tv'], maxPressure, currentPressureUnit);
    this.setState({
      options: {
        series: [
          {data: pressureArray},
          {data: tempArray},
        ],
        xAxis: [{
          categories: timeArray,
          crosshair: true
        }],
        exporting: {
          buttons: {
            customButton: {
              text: pressureUnitSelectionText,
            }
          }
        },
        title: {
          text: steamGraphTitle,
        },
        caption: {
          text: this.getCaptionGraph(cleanTime, cycleMetadata, t, deviceSerial)
        },
        subtitle: {
          text: steamGraphSubtitle,
        }
      },
      pressureArrayG: pressureArrayG,
      pressureArrayA: pressureArrayA,
      tempArray: tempArray,
    });
  };

  render() {
    return (
      <div className={this.props.graphContainerClass}>
        <div id='pressureTempChart' className={this.props.className}>
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            ref={this.pressureTempChart}
            callback={this.setChartToState}
            updateArgs={[true, true, true]}
          />
        </div>
      </div>
    )
  }
}

export default translate('translations')(PressureTemperatureGraph);
