import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import SubCard from "src/components/material/Cards/SubCard";
import { DateTime } from "luxon";
import { dateTimeFilterRegex, isNotEmptyObject } from "src/components/library/helpers";

const DeviceConfigLog = (props) => {
    const { t } = useTranslation("translations");
    const { configAuditLogs, deviceTimezone, selectedDeviceConfig } = useSelector((store) => store.deviceInfo);
    const loading = useSelector((store) => store.deviceInfo.loading.logs);
    const theme = useTheme();
    const [sortModel, setSortModel] = useState([]);

    const Toolbar = (props) => {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    const columns = [
        {
            headerName: t("Time Modified"),
            type: "dateTime",
            field: "timestamp",
            valueGetter: ({ value }) => value && new Date(value),
            headerAlign: "center",
            headerClassName: "config-logs-table--header",
            align: "center",
            flex: 1,
            getApplyQuickFilterFn: (value) => {
                if (!value || !dateTimeFilterRegex.test(value)) {
                    return null;
                }
                return (params) => {
                    if (params.value instanceof Date) {
                        return params.value.toLocaleString().includes(value);
                    }
                };
            },
        },
        {
            headerName: t("Modified By"),
            field: "user",
            valueGetter: ({ value }) => {
                return value?.employee?.full_name || "";
            },
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("Configuration Item"),
            field: "column_changed",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("Before Value"),
            field: "before_value",
            headerAlign: "center",
            valueGetter: ({ row, value }) => {
                if (row.column_changed === "time_last_biochallenge") {
                    const isoString = value.replace(" ", "T");
                    return DateTime.fromISO(isoString)
                        .setZone(deviceTimezone)
                        .toLocaleString({
                            ...DateTime.DATETIME_SHORT_WITH_SECONDS,
                            timeZoneName: "short",
                        });
                } else {
                    return value;
                }
            },
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
        {
            headerName: t("After Value"),
            field: "after_value",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            valueGetter: ({ row, value }) => {
                if (row.column_changed === "time_last_biochallenge") {
                    const isoString = value.replace(" ", "T");
                    return DateTime.fromISO(isoString)
                        .setZone(deviceTimezone)
                        .toLocaleString({
                            ...DateTime.DATETIME_SHORT_WITH_SECONDS,
                            timeZoneName: "short",
                        });
                } else {
                    return value;
                }
            },
            flex: 1,
        },
        {
            headerName: t("Description"),
            field: "change_description",
            headerAlign: "center",
            align: "center",
            headerClassName: "config-logs-table--header",
            flex: 1,
        },
    ];

    useEffect(() => {
        if (configAuditLogs.length > 0) {
            setSortModel([{ field: "timestamp", sort: "desc" }]);
        }
    }, [configAuditLogs]);

    return (
        isNotEmptyObject(selectedDeviceConfig) && (
            <SubCard
                sx={{
                    width: "100%",
                    "& .config-logs-table--header": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                    },
                }}>
                <DataGrid
                    disableSelectionOnClick
                    autoHeight
                    columns={columns}
                    rows={configAuditLogs}
                    loading={loading}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    sx={{
                        fontSize: 14,
                        padding: 2,
                        borderRadius: 4,
                    }}
                    components={{ Toolbar: Toolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </SubCard>
        )
    );
};

DeviceConfigLog.propTypes = {};

export default DeviceConfigLog;
