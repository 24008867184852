/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Button, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";

import AuthService from "../../../AuthService";
import SimpleLoader from "../../../SimpleLoader";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class EditCustomerModal extends React.Component {
  state = {
    customerName: '',
    modalLoading: false,
    allowModalToBeClosed: true,
    isLoading: false,
    customerWeightUnitOptions: []
  };

  componentDidMount() {
    const {
      customer
    } = this.props;
    this.setState({modalLoading: true});

    Auth.fetch(`/api/customer/`, {
      method: 'OPTIONS',
    }).then(data => {
      const customerWeightUnitOptions = data['customer_metadata']['weight_unit_preference']['choices'].map(choice => {
        return {
          key: choice['value'],
          value: choice['value'],
          text: choice['display_name']
        }
      });
      this.setState({
        customerName: customer['customer_name'],
        customerID: customer['id'],
        weightUnitPref: customer['weight_unit_preference'],
        customerWeightUnitOptions,
        modalLoading: false,
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.props.notifyFailure();
      this.setState({
        customerName: customer['customer_name'],
        customerID: customer['id'],
        weightUnitPref: customer['weight_unit_preference'],
        customerWeightUnitOptions: [],
        modalLoading: false,
      });
    });
  }

  submitCustomerChanges = (e) => {
    e.preventDefault();
    const {
      customerName,
      customerID,
      weightUnitPref,
    } = this.state;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });

    Auth.fetch(`/api/customer/${customerID}`, {
      method: 'PATCH',
      body: JSON.stringify({
        customer_name: customerName,
        weight_unit_preference: weightUnitPref
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.preparePage();
        this.props.triggerEditCustomerModal(null);
        this.props.notifySuccess('edited', 'customer', data['customer_name'])
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerEditCustomerModal();
        this.props.notifyFailure()
      });
    });
  };
  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    this.setState({[name]: value});
  };

  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.editCustomerModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='mini'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerEditCustomerModal(null)
          }}
        >
          <Header>
            <h2>{t('Edit Customer')}</h2>
          </Header>
          <Modal.Content>
            {modalLoading ? (
              <SimpleLoader/>
            ) : null}
            <form
              id='editCustomerForm'
              className='slight-padding-top'
              onSubmit={this.submitCustomerChanges}
            >
              <div className="form-group">
                <input value={this.state.customerName}
                       name='customerName'
                       onChange={this.handleChange}
                       type="text"
                       id="customerNameInput"
                       className="form-control"
                       required/>
                <label className="form-control-placeholder" htmlFor="customerNameInput">
                  {t('Customer Name')}
                </label>
              </div>
              <div className="form-group">
                <label className="fake-float" htmlFor="facilityType">{t('Weight Unit Preference')}</label>
                <Dropdown
                  className='full-width forty-five-height'
                  selection
                  id='customerWeightOption'
                  options={this.state.customerWeightUnitOptions}
                  value={this.state.weightUnitPref}
                  onChange={(event, data) => {
                    this.setState({
                      weightUnitPref: data.value
                    });
                  }}
                />
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='editCustomerForm'
                    id='editCustomerBtn'
            >{t('Submit changes')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(EditCustomerModal);
