/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import {composeHoc, serialSortHook, timestampSortHook} from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import {Button, Card, Checkbox, Dropdown, Tab} from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import {parse as jsonToCSV} from "json2csv";
import {Link} from "react-router-dom";
import HookTable from "../../library/HookTable";

const Auth = new AuthService();

class ExportDevicesBySpecificErrors extends React.Component {
  state = {
    isLoading: false,
    selected: [],
    errorCodeDropdownOptions: [],
    deviceErrorArray: [],
    errorColumns: [],
    pageQueried: false,
    fromDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    filterByDate: false,
  };

  componentDidMount() {
    document.title = 'Find devices by Specific Error Codes';
    this.setState({isLoading: true});
    Auth.fetch(`/api/error-code/`, {
      method: 'GET',
    }).then(data => {
      const errorCodeDropdownOptions = data.map((errorCode, idx) => {
        return {
          'key': idx,
          'value': errorCode.error_code,
          'text': `${errorCode.error_code} - ${errorCode.description}`,
        }
      });

      this.setState({
        isLoading: false,
        errorCodeDropdownOptions
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({isLoading: false});
    });
  }

  handleMultiSelectDropdownChange = (e, data) => {
    const {value} = data;
    this.setState({selected: value})
  };
  fetchDevicesByErrors = () => {
    const {
      selected,
      filterByDate
    } = this.state;
    this.setState({
      isLoading: true
    });
    const requestedErrors = {
      'error_codes': selected
    };

    if (filterByDate) {
      requestedErrors['from_date'] = this.state.fromDate;
      requestedErrors['to_date'] = this.state.toDate;
    }
    Auth.fetch(`/api/export/devices-by-error-code/`, {
      method: 'POST',
      body: JSON.stringify(requestedErrors)
    }).then(data => {

      const chronologicalDeviceErrorArray = data['device_errors']
      // some really old errors have undefined device configs - filter them out
        .filter(deviceError => deviceError['device_config__device__serial_number'])
        .map(deviceError => {
          return {
            'error_time': moment.utc(deviceError['error_time']).format("YYYY-MM-DD HH:mm:ss"),
            'serial_number': <Link target="_blank"
                                   to={`/find-a-machine?serial=${deviceError['device_config__device__serial_number']}`}>
              {deviceError['device_config__device__serial_number']}
            </Link>,
            'error_code': deviceError['hw_error_code__error_code'],
            'firmware_version': deviceError['firmware__firmware_version'],
            'facility_name': deviceError['device_config__facility__facility_name'],
            'customer_name': deviceError['device_config__facility__customer__customer_name'],
          }
        });


      // The following lines of code are all pieces of making the high level view table possible
      // We need to get the data in a format that react-table can accept.
      // The backend returns an array of objects which were created by a GROUP_BY on error_code
      // This makes it so we have an array of objects, where each object is an error code w/ some other info
      // We need each object to be a row in the high level table. A row in the high level table is defined by
      // a serial number and the number of occurrences that a specific error happened on a given device

      ///Create a dynamic column set for the errors, based on what was selected
      const errorColumns = selected.map(errorCode => {
        return {
          Header: errorCode,
          accessor: errorCode
        }
      });
      //Append serial_number to the front of the errorColumns array so it's the first column in the table
      const {t} = this.props;
      errorColumns.unshift({
        Header: t('Device Serial'),
        accessor: 'serial_number',
        sortType: serialSortHook,
      });
      const deviceErrorDict = data['device_error_count']
      // some really old errors have undefined device configs - filter them out
        .filter(deviceError => deviceError['device_config__device__serial_number'])
        .reduce((acc, current) => {
          if (current['device_config__id'] in acc) {
            acc[current['device_config__id']][current['hw_error_code__error_code']] = current['hw_error_code__error_code__count'];
            return acc;
          } else {
            acc[current['device_config__id']] = {
              [current['hw_error_code__error_code']]: current['hw_error_code__error_code__count'],
              'serial_number': current['device_config__device__serial_number']
            };

            if (!errorColumns[errorColumns.findIndex(x => x.accessor === current['hw_error_code__error_code'])]) {
              errorColumns.push({
                Header: current['hw_error_code__error_code'],
                accessor: current['hw_error_code__error_code'],
              })
            }
            return acc;
          }
        }, {});

      const flatAccessors = errorColumns.map(error => {
        return error['accessor'];
      });
      const deviceErrorArray = Object.keys(deviceErrorDict).map((deviceError) => {
        const arrayObj = {};
        flatAccessors.forEach((accessor) => {
          if (deviceErrorDict[deviceError][accessor]) {
            if (accessor === 'serial_number') {
              arrayObj[accessor] =
                <Link target="_blank" to={`/find-a-machine?serial=${deviceErrorDict[deviceError][accessor]}`}>
                  {deviceErrorDict[deviceError][accessor]}
                </Link>
            } else {
              arrayObj[accessor] = deviceErrorDict[deviceError][accessor]
            }
          } else {
            arrayObj[accessor] = 0
          }
        });
        return arrayObj;
      });

      this.setState({
        deviceErrorArray,
        errorColumns,
        chronologicalDeviceErrorArray,
        pageQueried: true,
        isLoading: false,
      })
    }).catch(err => {
      this.setState({
        isLoading: false,
      });
      Sentry.captureException(err);
    });
  };

  exportHighLevelToCSV = () => {
    const {
      deviceErrorArray,
      selected,
      filterByDate
    } = this.state;


    // the 'serial_number' key has a bunch of <Link/> JSX in it; strip it out before we CSV the array
    const noJsxDeviceErrorArray = deviceErrorArray.map(device => {
      return {
        ...device,
        'serial_number': device['serial_number']['props']['children']
      };
    });

    const csv = jsonToCSV(noJsxDeviceErrorArray);
    const blob = new Blob([csv], {type: 'text/csv'});

    const link = document.createElement('a');

    const filePath = window.URL.createObjectURL(blob);

    link.href = filePath;
    if (filterByDate) {
      link.download = `devices_with_${selected}_${this.state.fromDate}-${this.state.toDate}.csv`;
    } else {
      link.download = `devices_with_${selected}_${moment().format("YYYY-MM-DD")}.csv`;
    }
    document.getElementById('downloadDiv').appendChild(link);
    link.click();
  };

  exportChronologicalToCSV = () => {
    const {
      chronologicalDeviceErrorArray,
      selected,
      filterByDate
    } = this.state;

    // the 'serial_number' key has a bunch of <Link/> JSX in it; strip it out before we CSV the array
    const noJsxChronologicalDeviceErrorArray = chronologicalDeviceErrorArray.map(device_error => {
      return {
        ...device_error,
        'serial_number': device_error['serial_number']['props']['children']
      };
    });

    const csv = jsonToCSV(noJsxChronologicalDeviceErrorArray);
    const blob = new Blob([csv], {type: 'text/csv'});

    const link = document.createElement('a');

    const filePath = window.URL.createObjectURL(blob);

    link.href = filePath;
    if (filterByDate) {
      link.download = `errors_${selected}_${this.state.fromDate}-${this.state.toDate}.csv`;
    } else {
      link.download = `errors_${selected}_${moment().format("YYYY-MM-DD")}.csv`;
    }
    document.getElementById('downloadDiv').appendChild(link);
    link.click();
  };

  fromDateClick = timestamp => {
    this.setState({
      fromDate: moment(timestamp).format("YYYY-MM-DD")
    });
  };
  toDateClick = timestamp => {
    this.setState({
      toDate: moment(timestamp).format("YYYY-MM-DD")
    });
  };

  toggleFilterByDate = () => {
    this.setState((prevState) => {
      return {filterByDate: !prevState.filterByDate};
    });
  };

  render() {
    const {
      isLoading,
      errorCodeDropdownOptions,
      deviceErrorArray,
      errorColumns,
      pageQueried,
      chronologicalDeviceErrorArray,
      fromDate,
      toDate,
      filterByDate,
    } = this.state;
    const {
      t
    } = this.props;

    const exportHighLevelCSVButton = <Button
      className='grey-btn'
      onClick={this.exportHighLevelToCSV}
    >{t('CSV')}</Button>;

    const exportChronologicalCSVButton = <Button
      className='grey-btn'
      onClick={this.exportChronologicalToCSV}
    >{t('CSV')}</Button>;

    const chronologicalColumns = [
      {
        Header: t('Time of error (UTC)'),
        accessor: 'error_time',
        id: 'error_time',
        sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss a z"),
      },
      {
        Header: t('Device Serial'),
        accessor: 'serial_number',
        sortType: serialSortHook,
      },
      {
        Header: t('Error code'),
        accessor: 'error_code'
      },
      {
        Header: t('Software Version'),
        accessor: 'firmware_version'
      },
      {
        Header: t('Facility name'),
        accessor: 'facility_name'
      },
      {
        Header: t('Customer name'),
        accessor: 'customer_name'
      },
    ];


    return (
		<React.Fragment>
			<Card fluid>
				<Card.Header style={{ margin: "10px" }}>
					<h2>{t("search-by-error.header")}</h2>
				</Card.Header>
				{isLoading ? <SimpleLoader /> : null}
				<Card.Content className="card-body">
					<div className="split-container even-split">
						<div className="wide-desc">
							<h3 className="orange-text">
								{t(
									"search-by-error.1",
									"Use the dropdown to select as many error codes you wish to search for"
								)}
							</h3>
							<p>{t("search-by-error.2", "Two tables will be created")}</p>
							<p>
								<Trans ns="translations" i18nKey="search-by-error.3">
									<strong>A high level table</strong> which displays the amount of times each device
									has encountered the requested errors.
								</Trans>
							</p>
							<p>
								<Trans ns="translations" i18nKey="search-by-error.4">
									<strong>A chronological table</strong> which displays the errors as rows in
									chronological order.
								</Trans>
							</p>
						</div>
						<div className="wide-no-flex-items">
							<div className="form-group">
								<label>
									<h4 className="orange-text device-filter-header">
										{t("Error codes to search by")}
									</h4>
								</label>
								<Dropdown
									className="wide-dropdown"
									search
									selection
									onChange={this.handleMultiSelectDropdownChange}
									fluid
									multiple
									value={this.state.selected}
									id="filterSelectionDropdown"
									options={errorCodeDropdownOptions}
								/>
							</div>
							<div className="date-group error-code-dates">
								<div className="form-group medium-right-margin">
									<Checkbox
										label={t("Filter by date")}
										name="isAdmin"
										id="isAdmin"
										checked={filterByDate}
										onChange={this.toggleFilterByDate}
									/>
								</div>
								{filterByDate ? (
									<React.Fragment>
										<div className="nav-group from-date-div">
											<label className="date-label">{t("From")}</label>
											<DayPickerInput
												onDayChange={(day) => this.fromDateClick(day)}
												value={fromDate}
											/>
										</div>
										<div className="nav-group">
											<label className="date-label">{t("To")}</label>
											<DayPickerInput
												onDayChange={(day) => this.toDateClick(day)}
												value={toDate}
											/>
										</div>
									</React.Fragment>
								) : null}
							</div>
							<Button
								className="ster-btn float-right"
								value="Submit"
								type="submit"
								onClick={this.fetchDevicesByErrors}
							>
								{t("Search devices")}
							</Button>
						</div>
					</div>
					{pageQueried && (
						<Tab
							className="slight-margin-top"
							panes={[
								{
									menuItem: t("search-by-error.high_level_view", "High level view"),
									pane: {
										key: 0,
										className: "important-no-margin-bottom",
										content: (
											<HookTable
												id="highLevelDeviceErrorTable"
												data={deviceErrorArray}
												columns={errorColumns}
												tableName="highLevelDeviceErrorTable"
												defaultSortBy={{ id: "serial_number" }}
												floatLeftHeaderJSX={exportHighLevelCSVButton}
											/>
										),
									},
								},
								{
									menuItem: t("search-by-error.chronological_view", "Chronological view"),
									pane: {
										key: 1,
										content: (
											<HookTable
												id="chronologicalDeviceErrorTable"
												data={chronologicalDeviceErrorArray}
												columns={chronologicalColumns}
												tableName="chronologicalDeviceErrorTable"
												defaultSortBy={{ id: "error_time", desc: true }}
												floatLeftHeaderJSX={exportChronologicalCSVButton}
											/>
										),
									},
								},
							]}
							renderActiveOnly={false}
						/>
					)}
				</Card.Content>
			</Card>
			<div id="downloadDiv" className="download-div" />
		</React.Fragment>
	);
  }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs',
    'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin'], 'internalPage'))(ExportDevicesBySpecificErrors);
