import React from "react";
import PropTypes, { bool } from "prop-types";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DrawerItems from "./DrawerItems";

const drawerWidth = 300;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
	overflowY: "invisible",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	overflowY: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
    // @ts-ignore
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const AppDrawer = (props) => {
    const theme = useTheme();
    const {
        group,
        open,
        handleDrawerClose,
        handleAllErrorsModal,
        handleCustomerDeviceModal,
        handleDeviceConfigsModal,
        handleDeviceSpreadsheetModal,
    } = props;
    return (
        <Drawer variant="permanent" open={open} PaperProps={{ sx: { border: 0 } }}>
            <DrawerHeader sx={{ bgcolor: theme.palette.background.paper, border: "0px solid" }}>
                <Box justifyContent="flex-end" width="100%">
                    <Typography variant="h6" color="secondary">
                        STERILIS SOLUTIONS
                    </Typography>
                </Box>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <DrawerItems
                handleCustomerDeviceModal={handleCustomerDeviceModal}
                handleDeviceConfigsModal={handleDeviceConfigsModal}
                handleDeviceSpreadsheetModal={handleDeviceSpreadsheetModal}
                handleAllErrorsModal={handleAllErrorsModal}
                group={group}
            />
        </Drawer>
    );
};

AppDrawer.propTypes = {
	handleDrawerClose: PropTypes.func,
	open: PropTypes.bool,
	group: PropTypes.string,
	handleAllErrorsModal: PropTypes.func,
	handleCustomerDeviceModal: PropTypes.func,
	handleDeviceConfigsModal: PropTypes.func,
	handleDeviceSpreadsheetModal: PropTypes.func,
};

export default AppDrawer;
