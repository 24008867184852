import { Box, Button, Divider, Typography } from "@mui/material";
import {
    DataGrid,
    GridFooterContainer,
    GridPagination,
    GridRowCount,
    GridSelectedRowCount,
    GridToolbar,
    selectedGridRowsCountSelector,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFormatter, datetimeColumn, idColumn } from "./GridOptions";
import AuthService from "src/components/AuthService";
import { UserContext } from "src/components/library/UserContext";
import { convertWeight } from "src/components/library/helpers";
import { useDispatch } from "react-redux";
import SubCard from "src/components/material/Cards/SubCard";

const Auth = new AuthService();

const CycleSelectionTable = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translations");
    const [selectedCycles, setSelectedCycles] = useState([]);
    const [selectedMetaIds, setSelectedMetaIds] = useState([]);
    const userContext = useContext(UserContext);
    const { user } = userContext;
    const [pageSize, setPageSize] = useState(props.rows.length > 25 ? 50 : 25);

    const columns = [
        {
            field: "id",
            headerName: t("Cycle Id"),
            flex: 0.5,
            ...idColumn,
        },
        {
            field: "time_started",
            headerName: t("Start Time"),
            flex: 0.75,
            ...datetimeColumn,
        },
        {
            field: "time_ended",
            headerName: t("End Time"),
            flex: 0.75,
            ...datetimeColumn,
        },
        {
            field: "cycle_type",
            headerName: t("Cycle Type"),
            headerAlign: "center",
            align: "center",
            flex: 0.5,
            ...capitalizeFormatter,
        },
        {
            field: "waste_type",
            headerName: t("Waste Type"),
            headerAlign: "center",
            align: "center",
            flex: 0.5,
            ...capitalizeFormatter,
        },
        {
            field: "weight",
            headerName: t("Weight"),
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 0.5,
            valueFormatter: (params) => {
                const label = user["customer_unit_preference"]["weight"];
                const value = convertWeight(params.value, {
                    ...user["customer_unit_preference"],
                    roundPrecision: 1,
                    showUnit: false,
                });

                return `${value} ${label}s`;
            },
        },
        {
            field: "device_config",
            headerName: t("Device Config"),
            flex: 0.75,
            ...idColumn,
        },
        {
            field: "operator",
            headerName: t("Operator"),
            flex: 0.5,
            ...idColumn,
        },
    ];

    const handleSelectionChange = (updatedSelections) => {
        const metadataIds = updatedSelections.map((id) => {
            return props.rows.find((row) => row.id === id).metadata;
        });
        setSelectedMetaIds(metadataIds);
        setSelectedCycles(updatedSelections);
    };

    const ActionFooter = () => {
        const apiRef = useGridApiContext();
        const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector);

        return (
            <GridFooterContainer>
                <GridSelectedRowCount selectedRowCount={selectedRowCount} />
                <Box display={"flex"} sx={{ flex: 1 }}>
                    <Button
                        variant="outlined"
                        sx={{
                            display: "flex",
                        }}
                        disabled={selectedCycles.length <= 0}
                        onClick={(event) => props.viewMetricsOnClick(event, selectedMetaIds)}>
                        View Metrics
                    </Button>
                </Box>
                <GridRowCount rowCount={0} visibleRowCount={0} />
                <GridPagination />
            </GridFooterContainer>
        );
    };

    return (
        <SubCard title={t("Cycle Selection")}>
            <Box sx={{ height: 600 }}>
                <DataGrid
                    columns={columns}
                    rows={props.rows}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}
                    checkboxSelection
                    components={{
                        Toolbar: GridToolbar,
                        Footer: ActionFooter,
                    }}
                    componentsProps={{
                        toolbar: { background: "primary" },
                    }}
                    onSelectionModelChange={(updatedSelections) => handleSelectionChange(updatedSelections)}
                    selectionModel={selectedCycles}
                />
            </Box>
        </SubCard>
    );
};

export default CycleSelectionTable;
