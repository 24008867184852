import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "src/components/AuthService";

const auth = new AuthService();

const initialState = {
    loading: false,
    editOperator: null,
    updatedOperator: {
        id: null,
    },
    operatorCustomer: null,
    pinMin: 4,
    pinMax: 7,
    editOperatorModalOpen: false,
    failed: false,
    successText: "",
};

const editOperatorSlice = createSlice({
    name: "editOperator",
    initialState,
    reducers: {
        setEditOperator(state, action) {
            state.editOperator = action.payload;
            state.updatedOperator.id = action.payload?.id;
        },
        setUpdatedOperator(state, action) {
            state.updatedOperator = action.payload;
        },
        updateOperatorField(state, action) {
            state.updatedOperator[action.payload?.field] = action.payload?.value;
        },
        setEditOperatorModalOpen(state, action) {
            state.editOperatorModalOpen = action.payload;
        },
        setOperatorCustomer(state, action) {
            state.operatorCustomer = action.payload;
        },
        setPinMin(state, action) {
            state.pinMin = action.payload;
        },
        setPinMax(state, action) {
            state.pinMax = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setFailed(state, action) {
            state.failed = action.payload;
        },
        setSuccessText(state, action) {
            state.successText = action.payload;
        },
        resetEditState(state, action) {
            return initialState;
        },
    },
});

export const fetchSiteAdmins = createAsyncThunk("customer/fetchSiteAdmins", async (queryString) => {
    const response = await auth.fetch(`/api/portal-user${queryString}`);
    return response;
});

export const {
    setEditOperator,
    setEditOperatorModalOpen,
    setOperatorCustomer,
    setUpdatedOperator,
    updateOperatorField,
    setPinMin,
    setPinMax,
    setLoading,
    resetEditState,
    setFailed,
    setSuccessText,
} = editOperatorSlice.actions;

export default editOperatorSlice.reducer;
