/*
 *  -insert desc here-
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
 */
import React from "react";
import { Trans, withTranslation as translate } from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import ExportDeviceConfigTable from "./ExportDeviceConfigTable";
import { composeHoc } from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import { parse as jsonToCSV } from "json2csv";
import moment from "moment";
import { Button, Card, Dropdown } from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Auth = new AuthService();

class ExportDevicesByConfig extends React.Component {
    state = {
        isLoading: false,
        selected: [],
        configDropdown: [],
        deviceConfigIDArray: [],
        pageQueried: false,
    };

    notifyGenericFailure = (err) =>
        toast(`Operation failed, reason: ${err}`, { type: toast.TYPE.ERROR, autoClose: 5000 });

    componentDidMount() {
        document.title = "Export Devices by configuration";
        this.setState({
            isLoading: true,
        });
        Auth.fetch(`/api/device-config/`, {
            method: "OPTIONS",
        })
            .then((data) => {
                const configOptions = data["config_item_metadata"];
                const configDropdown = Object.keys(configOptions).reduce((acc, configOption) => {
                    if (configOption !== "sw_affecting" && configOption !== "defaults") {
                        if (configOptions[configOption]["type"] === "choice") {
                            configOptions[configOption]["choices"].forEach((choice) => {
                                acc.push({
                                    key: `${configOption}~~${choice.value}`,
                                    value: `${configOption}~~${choice.value}`,
                                    description: {
                                        config_item: configOption,
                                        config_value: choice.value,
                                    },
                                    text: `${configOptions[configOption].label} - ${choice.display_name}`,
                                });
                            });
                        } else if (configOptions[configOption]["type"] === "boolean") {
                            acc.push(
                                {
                                    key: `${configOption}~~true`,
                                    value: `${configOption}~~${true}`,
                                    description: {
                                        config_item: configOption,
                                        config_value: "True",
                                    },
                                    text: `${configOptions[configOption].label} - True`,
                                },
                                {
                                    key: `${configOption}~~false`,
                                    value: `${configOption}~~${false}`,
                                    description: {
                                        config_item: configOption,
                                        config_value: "False",
                                    },
                                    text: `${configOptions[configOption].label} - False`,
                                }
                            );
                        }
                    }
                    return acc;
                }, []);

				//Add pi_rev device items
                configDropdown.push(
                    {
                        key: `device__pi_rev~~B/B+`,
                        value: `device__pi_rev~~B/B+`,
                        description: {
                            config_item: "device__pi_rev",
                            config_value: "B/B+",
                        },
                        text: `Pi Revision - B/B+`,
                    },
                    {
                        key: `device__pi_rev~~Model B+`,
                        value: `device__pi_rev~~Model B+`,
                        description: {
                            config_item: "device__pi_rev",
                            config_value: "Model B+",
                        },
                        text: `Pi Revision - Model B+`,
                    },
                    {
                        key: `device__pi_rev~~Pi 2 Model B`,
                        value: `device__pi_rev~~Pi 2 Model B`,
                        description: {
                            config_item: "device__pi_rev",
                            config_value: "Pi 2 Model B",
                        },
                        text: `Pi Revision - Pi 2 Model B`,
                    },
                    {
                        key: `device__pi_rev~~Pi 3 Model B`,
                        value: `device__pi_rev~~Pi 3 Model B`,
                        description: {
                            config_item: "device__pi_rev",
                            config_value: "Pi 3 Model B",
                        },
                        text: `Pi Revision - Pi 3 Model B`,
                    },
                    {
                        key: `device__pi_rev~~Pi 3 Model B (Stadium. 1GB)`,
                        value: `device__pi_rev~~Pi 3 Model B (Stadium. 1GB)`,
                        description: {
                            config_item: "device__pi_rev",
                            config_value: "Pi 3 Model B (Stadium. 1GB)",
                        },
                        text: `Pi Revision - Pi 3 Model B (Stadium. 1GB)`,
                    },
                    {
                        key: `device__pi_rev~~Pi 4`,
                        value: `device__pi_rev~~Pi 4`,
                        description: {
                            config_item: "device__pi_rev",
                            config_value: "Pi 4",
                        },
                        text: `Pi Revision - Pi 4`,
                    }
                );

				//Add network_type device items
                configDropdown.push(
                    {
                        key: `device__network_type~~wired`,
                        value: `device__network_type~~wired`,
                        description: {
                            config_item: "device__network_type",
                            config_value: "Wired",
                        },
                        text: `Network Type - Wired`,
                    },
                    {
                        key: `device__network_type~~wifi`,
                        value: `device__network_type~~wifi`,
                        description: {
                            config_item: "device__network_type",
                            config_value: "wifi",
                        },
                        text: `Network Type - Wifi`,
                    },
                    {
                        key: `device__network_type~~unknown`,
                        value: `device__network_type~~unknown`,
                        description: {
                            config_item: "device__network_type",
                            config_value: "Unknown",
                        },
                        text: `Network Type - Unknown`,
                    }
                );

                this.setState({
                    isLoading: false,
                    configDropdown,
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                this.notifyGenericFailure(err);
                this.setState({
                    isLoading: false,
                });
            });
    }

    handleMultiSelectDropdownChange = (e, data) => {
        const { value } = data;
        this.setState({ selected: value });
    };

    fetchDevicesByConfig = () => {
        this.setState({
            isLoading: true,
            pageQueried: false,
        });
        Auth.fetch(`/api/export/devices-by-config/`, {
            method: "POST",
            body: JSON.stringify({
                device_config_values: this.state.selected,
            }),
        })
            .then((data) => {
                const deviceConfigIDArray = [];
                const deviceConfigs = data.map((deviceConfig) => {
                    deviceConfigIDArray.push(deviceConfig["id"]);
                    return {
                        serial_number: (
                            <Link target="_blank" to={`/find-a-machine/${deviceConfig["id"]}`}>
                                {deviceConfig["device__serial_number"]}
                            </Link>
                        ),
                        facility_name: deviceConfig["facility__facility_name"],
                        customer_name: deviceConfig["facility__customer__customer_name"],
                        firmware_version: deviceConfig["current_firmware__firmware_version"],
                        links: (
                            <div className="flex-dir-column">
                                <Link target="_blank" to={`/sa/dashboard/${deviceConfig["id"]}`}>
                                    Dashboard
                                </Link>
                                <Link target="_blank" to={`/find-a-machine/${deviceConfig["id"]}`}>
                                    Find a Machine
                                </Link>
                            </div>
                        ),
                    };
                });
                this.setState({
                    deviceConfigIDArray,
                    deviceConfigs,
                    rawData: data,
                    pageQueried: true,
                    isLoading: false,
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                this.setState({
                    isLoading: false,
                });
            });
    };

    fetchDeviceConfigsForCSV = () => {
        this.setState({
            isLoading: true,
        });
        Auth.fetch(`/api/export/device-configs?id_list=${this.state.deviceConfigIDArray}`, {
            method: "GET",
        })
            .then((data) => {
                // We need to remove the irrelevant values on DeviceConfig
                // The delete operation is mutable, so we are mutating the data object.
                const csvArray = data.map((deviceConfig) => {
                    delete deviceConfig["id"];
                    delete deviceConfig["deleted"];
                    delete deviceConfig["sw_affecting"];
                    delete deviceConfig["operators"];
                    delete deviceConfig["defaults"];
                    delete deviceConfig["device"];
                    delete deviceConfig["created"];
                    delete deviceConfig["config_rev"];
                    return deviceConfig;
                });

                const csv = jsonToCSV(csvArray);
                const blob = new Blob([csv], { type: "text/csv" });

                const link = document.createElement("a");

                const filePath = window.URL.createObjectURL(blob);

                link.href = filePath;
                const { selected } = this.state;

                link.download = `devices_with_${selected}-${moment().format("YYYY-MM-DD")}.csv`;
				// Appending to the actual dom is necessary to get the files to download on Firefox
                document.getElementById("downloadDiv").appendChild(link);
                link.click();
                this.setState({
                    isLoading: false,
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                this.setState({
                    isLoading: false,
                });
            });
    };

    render() {
        const { isLoading, configDropdown, deviceConfigs, pageQueried } = this.state;
        const { t } = this.props;
        const exportCSVButton = (
            <Button className="grey-btn" onClick={this.fetchDeviceConfigsForCSV}>
                {t("CSV")}
            </Button>
        );
        return (
            <Card fluid>
                <Card.Header style={{ margin: "10px" }}>
                    <h2>{t("search-by-config.header", "Find devices by Configuration item(s)")}</h2>
                </Card.Header>
                {/*<ToastContainer />*/}
                {isLoading ? <SimpleLoader /> : null}
                <Card.Content className="card-body">
                    <div className="split-container even-split">
                        <div className="wide-desc">
                            <h3 className="orange-text">
                                {t(
                                    "search-by-config.1",
                                    "Use the dropdown to select as many configuration item/value pairs you wish to search for"
                                )}
                            </h3>
                            <p>
                                <Trans ns="translations" i18nKey="search-by-config.2">
                                    The search is 'and' based, for example if you select{" "}
                                    <strong>Voltage 220V/60Hz</strong> and <strong>Grinder Voltage 220V</strong>,
                                    devices with both <strong>Voltage 220V/60Hz</strong> and{" "}
                                    <strong>Grinder Voltage 220V</strong> will be returned.
                                </Trans>
                            </p>
                            <p>
                                <Trans ns="translations" i18nKey="search-by-config.3">
                                    If you select two different configuration values for the same configuration item,
                                    for example a <strong>voltage of 220V/60Hz</strong> and a{" "}
                                    <strong>voltage of110V/60Hz</strong>, devices with <strong>either</strong>{" "}
                                    <strong>220V/60Hz</strong> OR <strong>110V/60Hz voltage</strong> will be returned.
                                </Trans>
                            </p>
                        </div>
                        <div className="wide-no-flex-items">
                            <div className="form-group">
                                <label>
                                    <h4 className="orange-text device-filter-header">
                                        {t("Configuration items to search by")}
                                    </h4>
                                </label>
                                <Dropdown
                                    className="wide-dropdown"
                                    search
                                    selection
                                    onChange={this.handleMultiSelectDropdownChange}
                                    fluid
                                    multiple
                                    value={this.state.selected}
                                    id="filterSelectionDropdown"
                                    options={configDropdown}
                                />
                            </div>
                            <Button
                                className="ster-btn float-right"
                                value="Submit"
                                type="submit"
                                onClick={this.fetchDevicesByConfig}
                            >
                                {t("Search devices")}
                            </Button>
                        </div>
                    </div>
                    {pageQueried && (
                        <ExportDeviceConfigTable deviceConfigs={deviceConfigs} exportCSVButton={exportCSVButton} />
                    )}
                    <div id="downloadDiv" className="download-div" />
                </Card.Content>
            </Card>
        );
    }
}

export default composeHoc(
    translate("translations"),
    withAuth(
        [
            "SterilisSuperUsers",
            "SterilisPortalUsers",
            "FSEs",
            "ExternalFSEs",
            "FactoryWorkers",
            "DistributorAdmins",
            "DistributorReadOnly",
            "DistributorFSEs",
            "SterilisWasteTypeAdmin",
        ],
        "internalPage"
    )
)(ExportDevicesByConfig);
