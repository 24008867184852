/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {composeHoc, isGroupNotCustomer} from "../library/helpers";
import { withTranslation as translate} from "react-i18next";
import withAuth from "../withAuth";
import UserSettings from "./UserSettings";

const UserSettingsWrapper = (props) => (
	<React.Fragment>
		{isGroupNotCustomer(props.group) ? (
			<UserSettings user={props.user} group={props.group} />
		) : (
			<UserSettings user={props.user} group={props.group} />
		)}
	</React.Fragment>
);

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
    'CustomerPortalUsers', 'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin', 'FacilityAdmin']))(UserSettingsWrapper);
