import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import AuthService from "src/components/AuthService";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthReq } from "src/components/library/helpers";
import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";

const Auth = new AuthService();

const UserMenu = (props) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const { username, group } = props;
    const history = useHistory();
    const { t } = useTranslation();

    const sterilisUserRoles = ["SterilisSuperUsers", "SterilisPortalUsers", "SterilisWasteTypeAdmin"];

    const handleLogout = () => {
        Auth.logout();
        history.push("/login");
    };
    const settings = [
        { text: "Settings", id: "user-settings", path: "/user-settings" },
        { text: "Release Notes", id: "release-notes", path: "/release-notes" },
        { text: "Portal Status", id: "portal-status", path: "/portal-status", requiredRoles: sterilisUserRoles },
        { text: "Logout", id: "logout", clickHandler: handleLogout },
    ];

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t("Settings Menu")}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="User">{username.charAt(0).toUpperCase()}</Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                <MenuItem disabled key={"username"}>
                    <ListItemIcon>
                        <PersonTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText>{username}</ListItemText>
                </MenuItem>
                <Divider />
                {settings.map((option) => {
                    return option.requiredRoles ? (
                        <AuthReq key={option.id} userGroup={group} requiredRoles={option.requiredRoles}>
                            <MenuItem
                                key={option.id}
                                component={option.path ? Link : undefined}
                                to={option.path ? option.path : undefined}
                                onClick={option.clickHandler ? option.clickHandler : undefined}>
                                <Typography textAlign="center">{t(option.text)}</Typography>
                            </MenuItem>
                        </AuthReq>
                    ) : (
                        <MenuItem
                            key={option.id}
                            component={option.path ? Link : undefined}
                            to={option.path ? option.path : undefined}
                            onClick={option.clickHandler ? option.clickHandler : undefined}>
                            <Typography textAlign="center">{t(option.text)}</Typography>
                        </MenuItem>
                    );
                })}
                <Divider />
                <MenuItem disabled key={"release-version"}>
                    <ListItemIcon>
                        <InfoTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText>{process.env.REACT_APP_VERSION}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

UserMenu.propTypes = {
    username: PropTypes.string,
    group: PropTypes.string,
};

export default UserMenu;
