import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "src/components/AuthService";
import { isGroupDist, prettyGroupName } from "src/components/library/helpers";

const auth = new AuthService();

const initialState = {
    facilityAdmins: [],
    facilityAdminTableData: [],
    loading: false,
};

const facilityAdminSlice = createSlice({
    name: "facilityAdmin",
    initialState,
    reducers: {
        setFacilityAdminTableData(state, action) {
            state.facilityAdminTableData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacilityAdmins.pending, (state, action) => {
                state.loading = true;
                state.facilityAdmins = initialState.facilityAdmins;
            })
            .addCase(fetchFacilityAdmins.fulfilled, (state, action) => {
                state.loading = false;
                state.facilityAdmins = action.payload;
            })
            .addCase(fetchFacilityAdmins.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const fetchFacilityAdmins = createAsyncThunk("facilityAdmin/fetchFacilityAdmins", async () => {
    const response = await auth.fetch(`/api/portal-user?group=FacilityAdmin`);
    return response;
});

export const selectFacilityAdminsForTable = (state) => {
    return state.facilityAdmin.facilityAdmins.map((user) => ({
        id: user.id,
        username: user.username,
        full_name: user.employee.full_name,
        customer_id: user.employee.customer.id,
        distributor_id: user.employee.customer.distributor.id,
        active_status: user.is_active,
        deleted_status: user.employee.deleted,
        group_name: prettyGroupName(user.groups[0].name, isGroupDist(state.user.group)),
        pin: user.employee?.device_user.length > 0 ? user.employee?.device_user[0].pin : "",
        facility_name: user.employee.facilities.map((facility) => facility.facility_name).join(", \n"),
        customer_name: user.employee.customer.customer_name,
    }));
};

export const { setFacilityAdminTableData } = facilityAdminSlice.actions;

export default facilityAdminSlice.reducer;
