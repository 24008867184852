/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import { Button, Table, Header, Modal, TransitionablePortal, Checkbox } from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import SimpleLoader from "../../../SimpleLoader";
import AuthService from "../../../AuthService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Auth = new AuthService();


class FacilityScheduleModal extends React.Component {
  state = {
    facilitySchedule: {}, //holds the state of actual schedules
    facilityScheduleDefault: {}, //holds the states of all day available schedule
    weekDays:[], //Static array to hold the days of a week as string
    createResponse: [],
    facility_id: null, //stores the facility id, as per request from create page(null) or customor view page(valid value) 
    apiFetchedData: [],//get the existing schedules and store here
    allowModalToBeClosed: true, 
    modalLoading: false,
    loadingText: null,
    modalErrorMessage: '', //when time is left blank then throw error
    isFacilityAvailableWholeDay:false,//current state of all day facility checkbox
    isAllDayFacilitySelected: false, //state of all day facility after submitting the modal,It is used to recover the values after closing the modal.
  };
  
  componentDidMount() {
    const {
      facility
    } = this.props;

    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    //get the facility id from the facilty prop passes in parent component(null in case of create facilty page)
    let facility_id = this.props.facility === null ? null : this.props.facility['id'];

    //initialize the schedules
    const facilitySchedule = {
        0: {id:null, start_time: null, end_time: null,facility:facility_id,day:0, isSelected: false, isEdited: false },
        1: {id:null, start_time: null, end_time: null,facility:facility_id,day:1, isSelected: false, isEdited: false },
        2: {id:null, start_time: null, end_time: null,facility:facility_id,day:2, isSelected: false, isEdited: false },
        3: {id:null, start_time: null, end_time: null,facility:facility_id,day:3, isSelected: false, isEdited: false },
        4: {id:null, start_time: null, end_time: null,facility:facility_id,day:4, isSelected: false, isEdited: false },
        5: {id:null, start_time: null, end_time: null,facility:facility_id,day:5, isSelected: false, isEdited: false },
        6: {id:null, start_time: null, end_time: null,facility:facility_id,day:6, isSelected: false, isEdited: false },
    }
      
    //call get api endpoint and populate the values to respective schedule objects of facilitySchedule
    if (facility !== null) {
      this.setState({
        modalLoading: true,
        allowModalToBeClosed: false,
        loadingText:this.props.t('fetching schedules')
        });
      Auth.fetch(`/api/facility-schedule/?facility_id=${facility['id']}`, {
        method: 'GET',
      }).then(data => {
        this.setState({
          modalLoading: false,
          allowModalToBeClosed: true,
          loadingText:null,
          apiFetchedData:data,
        }, () => {
        //populate the get response data to scheduleFacility variable
        this.state.apiFetchedData.forEach(row => {
        const idx = parseInt(row.day, 10);
        facilitySchedule[idx].id = row.id;
        let timeStringArr = row.start_time.split(":");
         facilitySchedule[idx].start_time = new Date(new Date().setHours(timeStringArr[0], timeStringArr[1], 0));
        timeStringArr = row.end_time.split(":");
        facilitySchedule[idx].end_time = new Date(new Date().setHours(timeStringArr[0], timeStringArr[1], 0));
        facilitySchedule[idx].isSelected = true;
        })
          this.setState({
            facilitySchedule,
          })
          });
      });
    }

  this.setState({
    weekDays,
    facilitySchedule,
    facility_id,
  });
  }

//Making the time in proper format by adding a '0' as prefix.
  addZeroToTime=(t)=>{
      if (t < 10) {t = "0" + t}
      return t;
    }
  
  setTimeInStringFormat = (schedule) => {
    if(schedule.start_time!==null)
      schedule.start_time = this.addZeroToTime(new Date(schedule.start_time).getHours()) + ":" + this.addZeroToTime(new Date(schedule.start_time).getMinutes()+":00");
    if(schedule.end_time!==null)
      schedule.end_time = this.addZeroToTime(new Date(schedule.end_time).getHours()) + ":" + this.addZeroToTime(new Date(schedule.end_time).getMinutes() + ":00");
    return schedule;
  }

  setStringInTimeFormat = (timeString) => {
    let timeArr = timeString.split(':')
    return new Date().setHours(timeArr[0],timeArr[1]);
  }

  //common api call method for POST, PUT and DELTE
  commonApiCallMethod = (payloadData, method) => {
    return Auth.fetch(`/api/facility-schedule/`, {
      method: method,
      body: JSON.stringify(payloadData)
    }).then(data => {
      return data['id'];
    }).catch(err => {
      this.props.notifyFailure();
      Sentry.captureException(err);
    });
  }

  //handling the start time and endtime datepicker events
  handleTimeChange = (idx, time, callFrom) => {
    time === null ? "" : time.getHours() + ":" + time.getMinutes();
    let facilitySchedule = { ...this.state.facilitySchedule };
	if(facilitySchedule[idx].id !== null)
		facilitySchedule[idx].isEdited=true;
    if(callFrom === "start_time")
      facilitySchedule[idx].start_time = time;
    else if(callFrom === "end_time")
      facilitySchedule[idx].end_time = time;
    this.setState({
         facilitySchedule,
    });
  };
  
  handleCheckBoxOfDays = (idx) => {
    let facilitySchedule = { ...this.state.facilitySchedule };
    this.setState((prevState) => {
      facilitySchedule[idx].isSelected= !prevState.facilitySchedule[idx].isSelected
        }, () => {
    this.setState({
        facilitySchedule,
    });
    });
  };
  
  //handle the all day available check box event
  handleAllDayAvailableFacility = () => {
    const facilitySchedule = { ...this.state.facilitySchedule };
    const defaultOpenTime = new Date(new Date().setHours(0, 0, 0));
    const defaultCloseTime = new Date(new Date().setHours(23, 59, 59));
    const facilityScheduleDefault= {
        0: {id:null, start_time: defaultOpenTime, end_time: defaultCloseTime,day:0,facility:null, isSelected:true, isEdited: false },
        1: {id:null, start_time: defaultOpenTime, end_time: defaultCloseTime,day:1,facility:null, isSelected:true, isEdited: false },
        2: {id:null, start_time: defaultOpenTime, end_time: defaultCloseTime,day:2,facility:null, isSelected:true, isEdited: false },
        3: {id:null, start_time: defaultOpenTime, end_time: defaultCloseTime,day:3,facility:null, isSelected:true, isEdited: false },
        4: {id:null, start_time: defaultOpenTime, end_time: defaultCloseTime,day:4,facility:null, isSelected:true, isEdited: false },
        5: {id:null, start_time: defaultOpenTime, end_time: defaultCloseTime,day:5,facility:null, isSelected:true, isEdited: false },
        6: {id:null, start_time: defaultOpenTime, end_time: defaultCloseTime,day:6,facility:null, isSelected:true, isEdited: false },
    }

    this.setState((prevState) => {
        return { isFacilityAvailableWholeDay: !prevState.isFacilityAvailableWholeDay }
    }, () => {

      Object.keys(facilityScheduleDefault).forEach((idx) => {
        facilityScheduleDefault[idx].facility = facilitySchedule[idx].facility;
        if (facilitySchedule[idx].id !== null)
          facilityScheduleDefault[idx].isEdited = true;
      });
    });
    this.setState({
      facilityScheduleDefault,
    });
  };

  submitFacilityScheduleChanges = (e) => {
    e.preventDefault();

    let schedule = {};
    if (this.state.isFacilityAvailableWholeDay) {
      schedule = cloneDeep(this.state.facilityScheduleDefault);
      this.setState({
        isAllDayFacilitySelected: true
      })
    }
    else {
      schedule = cloneDeep(this.state.facilitySchedule);
      this.setState({
        isAllDayFacilitySelected:false,
      })
    }
    
    let facilitySchedule = { ...this.state.facilitySchedule };
    let createResponse = [];
    let editResponse = [];
    let deleteResponse = [];
    let isTimeLeftEmpty = false;

    Object.keys(schedule).forEach(idx => {

      if (facilitySchedule[idx].isSelected === true &&
        (facilitySchedule[idx].start_time === null ||
          facilitySchedule[idx].end_time === null)) {
        isTimeLeftEmpty = !this.state.isFacilityAvailableWholeDay;
      }

      if (schedule[idx].id === null) {
        if (schedule[idx].isSelected === true && schedule[idx].start_time !== null && schedule[idx].end_time !== null) {
          schedule[idx] = this.setTimeInStringFormat(schedule[idx]);
          createResponse.push(schedule[idx]);
        }
      }
      else {
        if (schedule[idx].isSelected === true && schedule[idx].start_time !== null
          && schedule[idx].end_time !== null && schedule[idx].isEdited === true) {
          schedule[idx] = this.setTimeInStringFormat(schedule[idx]);
          editResponse.push(schedule[idx]);
        }
        if (schedule[idx].isSelected === false || schedule[idx].start_time === null
          || schedule[idx].end_time === null) {
          schedule[idx] = this.setTimeInStringFormat(schedule[idx]);
          deleteResponse.push(schedule[idx]);
        }
      }
    });

    if (isTimeLeftEmpty) {
      this.setState({
        modalErrorMessage: 'Opening/Closing time cannot be empty for seleted days !',
      }, () => {
        setTimeout(() => {
          this.setState({
            modalErrorMessage: '',
          })
        }, 5000)
      });
    }

    this.setState({
      facilitySchedule,
      createResponse,
    }, () => {
      //if request coming from create page then facility prop will be null so transfer the data to parent comp
      //and call POST api inside parent where the Facility Form is getting submitted.
      if (this.props.facility === null) {
        if (createResponse.length > 0) {
          this.props.setAllowSubmitFacility(true);
          this.props.setScheduleIconName("calendar check");
          this.props.setSchedule(createResponse);
        }
        else {
          this.props.setScheduleIconName("calendar alternate outline");
          this.props.setAllowSubmitFacility(false);
        }
        this.props.setFacilityScheduleModal(isTimeLeftEmpty);
      }

      //if request comes from customer view page then, facility prop will not be null,
      else {
        if (!isTimeLeftEmpty) {
          this.setState({
            modalLoading: true,
            allowModalToBeClosed: false,
            loadingText: this.props.t('updating schedules')
          });
          let promiseArrToExecuteAPIs = [];

          if (createResponse.length > 0)
          promiseArrToExecuteAPIs.push(this.commonApiCallMethod(createResponse, 'POST'));
      
        if (editResponse.length > 0)
          promiseArrToExecuteAPIs.push(this.commonApiCallMethod(editResponse, 'PUT'));

        if (deleteResponse.length > 0) {
          deleteResponse = deleteResponse.map((row) => {
            if (row.start_time === null)
              row.start_time = "00:00:00";
            if (row.end_time === null)
              row.end_time = "00:00:00";
            return row;
          });
          promiseArrToExecuteAPIs.push(this.commonApiCallMethod(deleteResponse, 'DELETE'));
        }
          
        Promise.all(promiseArrToExecuteAPIs)
          .then(() => {
            this.setState({
              modalLoading: false,
              allowModalToBeClosed: true,
              loadingText: null
            }, () => {
              if (promiseArrToExecuteAPIs.length > 0)
                this.props.notifySuccess('edited', 'facility schedule', "!");
              this.props.triggerFacilityScheduleModal(null);
            });
          });
        }
      }
    });
  }
  
  render() {
    const {
      t,
      facility
    } = this.props;
  
    const {
        modalLoading,
        facilityScheduleDefault,
        facilitySchedule,
        isFacilityAvailableWholeDay,
        allowModalToBeClosed,
        weekDays,
        loadingText,
        modalErrorMessage,
    } = this.state;
    const inlineStyle = {
      modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };

  return (
    <TransitionablePortal
      open={this.props.facilityScheduleModal}
      onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
      transition={{animation: 'scale', duration: 500}}
    >
      <Modal
        style={inlineStyle.modal}
        open={true}
        closeOnDimmerClick={allowModalToBeClosed}
        closeIcon={<Button className='close-modal-btn grey-btn'>{t('Cancel')}</Button>}
        onClose={(event) => {
          document.body.classList.remove('modal-fade-in');
          this.props.triggerFacilityScheduleModal(null);
          this.setState({
            isFacilityAvailableWholeDay: this.state.isAllDayFacilitySelected,
          });

          if (!this.state.isAllDayFacilitySelected) {
            let facilitySchedule = { ...this.state.facilitySchedule };

            Object.keys(facilitySchedule).forEach((idx) => {
              facilitySchedule[idx].isSelected = false;
              facilitySchedule[idx].start_time = null;
              facilitySchedule[idx].end_time = null;
            });

            if (this.state.createResponse.length > 0) {
              Object.keys(this.state.createResponse).forEach((idx) => {
                let id = this.state.createResponse[idx].day;
                facilitySchedule[id].isSelected = this.state.createResponse[idx].isSelected;
                facilitySchedule[id].start_time = this.setStringInTimeFormat(this.state.createResponse[idx].start_time);
                facilitySchedule[id].end_time = this.setStringInTimeFormat(this.state.createResponse[idx].end_time);
              });
              this.setState({
                facilitySchedule,
              })
            }

          }

        }}
      >
        <Header>
          {(this.props.facility === null) ?
            (<h2>{t('Schedule Facility')}</h2>) :
            (<h2>{t('Schedule '+facility['facility_name'])}</h2>)}
          <Checkbox
              label="Available Whole Day"        
              className='cust-fact-checkbox'
              onChange={this.handleAllDayAvailableFacility}
              checked={isFacilityAvailableWholeDay}
          />
        </Header>
          <Modal.Content>
            {modalLoading &&
            <SimpleLoader
              loaderText={loadingText}
            />}
          {modalErrorMessage && (
              <center>
                <h4 className="error" style={{ backgroundColor: 'yellow', alignSelf: 'flex-start' }}> {modalErrorMessage}
                </h4>
              </center>
            )}
        <form
            id='scheduleFacility'
            className='slight-padding-top'
            >
              <Table>
              <Table.Header>
              </Table.Header>
              <Table.Body>
                      {
                        Object.keys(weekDays).map((item, idx) => {
                          return (
                            <Table.Row key={idx}>
                              <Table.Cell>
                                <input type="checkbox" id={idx}
                                  onChange={() => this.handleCheckBoxOfDays(idx)}
                                  checked={isFacilityAvailableWholeDay ? facilityScheduleDefault[idx].isSelected : facilitySchedule[idx].isSelected}
                                  disabled={isFacilityAvailableWholeDay}
                                />
                              </Table.Cell>
                              <Table.Cell>{weekDays[idx]}</Table.Cell>
                              <Table.Cell>
                                <DatePicker
                                  selected={isFacilityAvailableWholeDay ? facilityScheduleDefault[idx].start_time : facilitySchedule[idx].start_time}
                                  onChange={(date) => this.handleTimeChange(idx, date,"start_time")}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="start_time"
                                  dateFormat="h:mm aa"
                                  disabled={isFacilityAvailableWholeDay || !this.state.facilitySchedule[idx].isSelected}
                                  placeholderText="Facility Opening Time"
                                  isClearable={!isFacilityAvailableWholeDay && this.state.facilitySchedule[idx].isSelected}
                                  minDate={new Date()}
                                  minTime={new Date().setHours(0, 0, 0)}
                                  maxTime={isFacilityAvailableWholeDay ? (facilityScheduleDefault[idx].end_time == null ?
                                    new Date().setHours(23, 59, 59) : facilityScheduleDefault[idx].end_time) :
                                    (facilitySchedule[idx].end_time == null ?
                                      new Date().setHours(23, 59, 59) : facilitySchedule[idx].end_time)}
                                  maxDate={new Date()}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <DatePicker
                                  selected={isFacilityAvailableWholeDay ? facilityScheduleDefault[idx].end_time : facilitySchedule[idx].end_time}
                                  onChange={(date) => this.handleTimeChange(idx, date,"end_time")}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="end_time"
                                  dateFormat="h:mm aa"
                                  disabled={isFacilityAvailableWholeDay || !this.state.facilitySchedule[idx].isSelected}
                                  placeholderText="Facility Closing Time"
                                  isClearable={!isFacilityAvailableWholeDay && this.state.facilitySchedule[idx].isSelected}
                                  minDate={new Date()}
                                  minTime={isFacilityAvailableWholeDay ? (facilityScheduleDefault[idx].start_time == null ?
                                    new Date().setHours(23, 59, 59) : facilityScheduleDefault[idx].start_time) :
                                    (facilitySchedule[idx].start_time == null ?
                                      new Date().setHours(23, 59, 59) : facilitySchedule[idx].start_time)}
                                  maxTime={new Date().setHours(23, 59, 59)}
                                  maxDate={new Date()}
                                />
                              </Table.Cell>
                            </Table.Row>
                                )
                        })
                }
              </Table.Body>
            </Table>
        </form>
    </Modal.Content>
        <Modal.Actions>
                <Button className='ster-btn'
                type={"button"}
                onClick={this.submitFacilityScheduleChanges}
                >{t('Done')}
                </Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
  }
export default translate('translations')(FacilityScheduleModal);
