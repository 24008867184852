import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import SterRouter from "./components/Router";
import registerServiceWorker from "./registerServiceWorker";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "./components/i18n";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "@mui/material";
import theme from "./themes";

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme()}>
            <Suspense fallback="loading">
                <SterRouter />
            </Suspense>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);
//registerServiceWorker();
