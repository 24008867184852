import {
    Avatar,
    Box,
    Card,
    CardContent,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import EventTwoToneIcon from "@mui/icons-material/EventTwoTone";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import TimerTwoToneIcon from "@mui/icons-material/TimerTwoTone";
import Highcharts from "highcharts/highstock";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SterilisChart from "src/components/material/Highcharts/SterilisChart";
import SubCard from "src/components/material/Cards/SubCard";

const BioChallengePanel = () => {
    // @ts-ignore
    const { biochallengeInfo, deviceTimezone, userTimezone } = useSelector((store) => store.devicedashboard);
    const { t } = useTranslation("translations");
    // @ts-ignore
    const loading = useSelector((store) => store.devicedashboard.loading.biochallengeInfo);
    const theme = useTheme();
    const [options, setOptions] = React.useState({});

    const calcBarColor = (xSeriesValue, maxXAxisValue) => {
        if (xSeriesValue / maxXAxisValue > 0.75) {
            return theme.palette.success.main;
        } else if (xSeriesValue / maxXAxisValue > 0.5) {
            return theme.palette.warning.light;
        } else if (xSeriesValue / maxXAxisValue > 0.25) {
            return theme.palette.warning.main;
        } else {
            return theme.palette.error.main;
        }
    };

    const populateChart = () => {
        const xSeriesValue = Number(biochallengeInfo["x_series_value"]);
        const maxXAxisValue = Number(biochallengeInfo["x_axis"]);
        const barColor = calcBarColor(xSeriesValue, maxXAxisValue);
        const options = {
            chart: {
                type: "bar",
                height: 250,
            },
            title: {
                text: t("Biological Challenge"),
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                labels: {
                    enabled: false,
                },
                tickLength: 0,
            },
            yAxis: {
                min: 0,
                reversed: true,
                max: maxXAxisValue,
                title: {
                    text: `${biochallengeInfo["type"] === "calendar" ? t("Days Left") : t("Cycles Left")}`,
                    align: "high",
                },
                labels: {
                    overflow: "justify",
                },
            },
            tooltip: {
                enabled: false,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: `{y} ${biochallengeInfo["type"] === "calendar" ? t("days") : t("cycles")} ${t(
                            "remain"
                        )}`,
                    },
                },
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    name: "",
                    data: [xSeriesValue],
                    color: barColor,
                },
            ],
        };
        setOptions(options);
    };

    const cleanLastBiochallenge = (lastBiochallengeEnd, biochallengeResult, biochallengeStatus) => {
        let cleanStatus = "";
        if (biochallengeResult === 1) {
            cleanStatus = t("Validated");
        } else {
            cleanStatus = biochallengeStatus;
        }
        return `${moment(lastBiochallengeEnd)
            .tz(deviceTimezone ? deviceTimezone : userTimezone)
            .format("YYYY-MM-DD HH:mm:ss z")} (${cleanStatus})`;
    };

    const biochallengePanel = () => {
        const lastBiochallengeOperator = biochallengeInfo?.last_biochallenge_info?.last_biochallenge_operator;
        const lastbiochallengedate =
            biochallengeInfo?.last_biochallenge_info?.last_biochallenge_result === 1 ||
            !biochallengeInfo?.last_biochallenge_info?.last_biochallenge_start
                ? biochallengeInfo?.last_biochallenge_info?.last_biochallenge_validation_date
                : biochallengeInfo?.last_biochallenge_info?.last_biochallenge_start;

        const lastBiochallenge = cleanLastBiochallenge(
            lastbiochallengedate,
            biochallengeInfo?.last_biochallenge_info?.last_biochallenge_result,
            biochallengeInfo?.last_biochallenge_info?.last_biochallenge_status_msg
        );

        if (biochallengeInfo["type"] === "calendar") {
            const nextBiochallenge = moment(biochallengeInfo?.next_biochallenge_date)
                .tz(deviceTimezone ? deviceTimezone : userTimezone)
                .format("YYYY-MM-DD HH:mm:ss z");
            return (
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <EventTwoToneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t("Next Biological Challenge Start Date")}
                            secondary={nextBiochallenge}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <TodayTwoToneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t("Last Biological Challenge Date")} secondary={lastBiochallenge} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonOutlineTwoToneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t("Run By")} secondary={lastBiochallengeOperator} />
                    </ListItem>
                </List>
            );
        } else if (biochallengeInfo["type"] === "usage") {
            const cyclesLeft = biochallengeInfo["x_series_value"];
            return (
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <TimerTwoToneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t("Estimated Cycles Left Until Next Biochallenge")}
                            secondary={cyclesLeft}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <TodayTwoToneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t("Last Biological Challenge Date")} secondary={lastBiochallenge} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonOutlineTwoToneIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t("Run By")} secondary={lastBiochallengeOperator} />
                    </ListItem>
                </List>
            );
        }
    };
    const xSeriesValue = biochallengeInfo["x_series_value"];

    useEffect(() => {
        if (biochallengeInfo !== null) {
            populateChart();
        }
    }, [biochallengeInfo]);

    return (
        <SubCard>
            <CardContent>
                {loading ? (
                    <Box
                        sx={{
                            p: 20,
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                        }}>
                        <CircularProgress color="secondary" size={60} />
                    </Box>
                ) : (
                    <Box>
                        {xSeriesValue > 0 ? (
                            <SterilisChart highcharts={Highcharts} options={options} updateArgs={[true, true, true]} />
                        ) : (
                            <Stack alignItems={"center"} mt={4}>
                                <WarningTwoToneIcon sx={{ fontSize: 80 }} color="error" />
                                <Typography variant="h5" textAlign={"center"} color={"error"}>
                                    {t(
                                        "A Biological Challenge Must Be Run Before This Device Can Run a Sterilization Cycle"
                                    )}
                                </Typography>
                            </Stack>
                        )}
                        {biochallengePanel()}
                    </Box>
                )}
            </CardContent>
        </SubCard>
    );
};

export default BioChallengePanel;
