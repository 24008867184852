/*
*  CycleReport.js contains functions which use jsPDF to generate PDF/CSV reports
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

import { jsPDF } from "jspdf";
// this import is used, eslint & pycharm are wrong
import autoTable from "jspdf-autotable"; // eslint-disable-line
import moment from "moment";
import {parse as jsonToCSV} from 'json2csv';

export const cycleCSV = (cycleArray, pressureUnit, comprehensiveReport, deviceSerial, customerReport = false) => {
    const pressureKey = pressureUnit === "PSI(g)" ? "Max Pres PSI(g)" : "Max Pres PSI(a)";

    const processedRows = prepareReport(cycleArray, comprehensiveReport);
    const csvArray = processedRows.map((cycle) => {
        const arrVal = {
            "Device Serial": cycle.serial_number,
            Date: cycle.simple_time_started,
            Weight: cycle.weight,
            "Waste type": cycle.waste_type,
            "Cycle time": cycle.cycle_time,
            "Steam time": cycle.steam_time,
            "Cook time": cycle.cook_time,
            [pressureKey]: pressureUnit === "PSI(g)" ? cycle.max_pres_G : cycle.max_pres_A,
            "Max Temp (C)": cycle.max_temp,
            "Run by": cycle.user,
            Status: cycle.cycle_state,
            Comments: cycle.cycle_comments,
        };

        if (customerReport) {
            arrVal["Facility"] = cycle.facility_name;
        }

        return arrVal;
    });
    const csv = jsonToCSV(csvArray);
    const blob = new Blob([csv], { type: "text/csv" });

    const link = document.createElement("a");

    const filePath = window.URL.createObjectURL(blob);

    link.href = filePath;

    link.download = `${
        comprehensiveReport ? "comprehensive_report" : "compliance_report"
    }_${deviceSerial}_${moment().format("YYYY-MM-DD")}.csv`;
    // Appending to the actual dom is necessary to get the files to download on Firefox
    document.getElementById("downloadDiv").appendChild(link);
    link.click();
};

const prepareReport = (reportRows, comprehensiveReport) => {
    return reportRows
        .filter((cycle) => {
            //filter out the cycles where
            // the user requests a compliance report (comprehensiveReport === false)
            // the cycle is not completed yet (cycle['time_ended'] === null)
            if (comprehensiveReport === false && cycle["time_ended"] === null) {
                return false;
            }
            return cycle;
        })
        .map((cycle) => {
            // this string is just long, trim it down for the report
            if (cycle["cycle_state"] === "Completed successfully, remove waste bag") {
                cycle["cycle_state"] = "Completed successfully";
            }
            return cycle;
        });
};

/**
 * Create a PDF file for the rows in the input array and save it to the given
 * file name.
 *
 * @param facilityInfo String with facility information
 * @param isAirport true if the devices are in an airport
 * @param comprehensiveReport Boolean report type, true if it's a Comprehensive Report
 * @param deviceSerial Serial of device whose report is being generated
 * @param pressureUnit pressureUnit desired for the report - either PSI(a) or PSI(g)
 * @param pdfRows Array of row information, from CycleTable. The same array used to populate the Cycle Table
 * @param customerReport if it's a customer instead of device based report, we change a few fields
 */
export const cycleReport = (
    facilityInfo,
    isAirport,
    comprehensiveReport,
    deviceSerial,
    pressureUnit,
    pdfRows,
    customerReport = false
) => {
    const processedRows = prepareReport(pdfRows, comprehensiveReport);

    //Iterate through the rows one final time to prepare the data for

    const pdfColumnHeadings = [
        {
            dataKey: "serial_number",
            title: "Device Serial",
        },
        {
            dataKey: "time_started",
            title: "Date",
        },
        {
            dataKey: "weight",
            title: "Weight",
        },
        {
            dataKey: "waste_type",
            title: "Waste type",
        },
        {
            dataKey: "cycle_time",
            title: "Cycle time",
        },
        {
            dataKey: "steam_time",
            title: "Steam time",
        },
        {
            dataKey: "cook_time",
            title: "Cook time",
        },
        {
            dataKey: pressureUnit === "PSI(g)" ? "max_pres_G" : "max_pres_A",
            title: pressureUnit === "PSI(g)" ? "Max Pres PSI(g)" : "Max Pres PSI(a)",
        },
        {
            dataKey: "max_temp",
            title: "Max Temp (°C)",
        },
        {
            dataKey: "user",
            title: "Run by",
        },
        {
            dataKey: "cycle_state",
            title: "Status",
        },
        {
            dataKey: "cycle_comments",
            title: "Comments",
        },
    ];

    if (customerReport) {
        pdfColumnHeadings.push({
            dataKey: "facility_name",
            title: "Facility",
        });
    }

    const doc = new jsPDF("l", "pt");

    const timeNow = new Date();
    const prettyTime = moment.utc(timeNow).format("YYYY-MM-DD");
    const fileName = `${comprehensiveReport ? "comprehensive_report" : "compliance_report"}_${
        customerReport ? facilityInfo : deviceSerial
    }_${prettyTime}`;
    const header = () => {
        doc.setFontSize(16);
        if (isAirport) {
            doc.text("Regulated Garbage Record-Keeping Log", 270, 30);
        } else {
            doc.text("Medical or Biological Waste Record-Keeping Log", 230, 30);
        }
        doc.setFontSize(10);
        if (comprehensiveReport) {
            doc.setFontSize(12);
            doc.text("ON SITE TREATMENT - Comprehensive Report " + timeNow.toLocaleDateString(), 250, 50);
        } else {
            doc.setFontSize(12);
            doc.text(`ON SITE TREATMENT - Compliance Report ${timeNow.toLocaleDateString()}`, 260, 50);
        }
        doc.setFontSize(12);
        doc.setFont(undefined, "bold");
        doc.text(`${customerReport ? "Customer name " : "Facility name & address: "}`, 40, 70);
        doc.setFont(undefined, "normal");
        doc.text(facilityInfo, 186, 70);

        doc.setFont(undefined, "bold");
        doc.text("Treatment method: ", 40, 88);
        doc.setFont(undefined, "normal");
        doc.text("Steam sterilization", 186, 88);
    };
    const totalPagesExp = "{###jsPDFVarTotalPages###}";
    const footer = (data) => {
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            var footerStr = ". Page " + data.pageCount;
            if (typeof doc.putTotalPages === "function") {
                footerStr = footerStr + " of " + totalPagesExp;
            }
            if (comprehensiveReport) {
                if (isAirport) {
                    doc.text(
                        "Regulated Garbage On-site Comprehensive Report" + footerStr,
                        data.settings.margin.left,
                        doc.internal.pageSize.height - 20
                    );
                } else {
                    doc.text(
                        "Medical/Biological Waste On-site Comprehensive Report" + footerStr,
                        data.settings.margin.left,
                        doc.internal.pageSize.height - 20
                    );
                }
            } else {
                if (isAirport) {
                    doc.text(
                        "Regulated Garbage On-site Compliance Report" + footerStr,
                        data.settings.margin.left,
                        doc.internal.pageSize.height - 20
                    );
                } else {
                    doc.text(
                        "Medical/Biological Waste On-site Compliance Report" + footerStr,
                        data.settings.margin.left,
                        doc.internal.pageSize.height - 20
                    );
                }
            }
        },
        pdfOptions = {
            columns: pdfColumnHeadings,
            body: processedRows,
            margin: { top: 96, left: 36, bottom: 36, right: 36 },
            startY: 136,
            theme: "striped",
            columnStyles: {
                serial_number: { cellWidth: 75 },
                simple_time_started: { cellWidth: 130 },
                waste_type: { cellWidth: 75 },
                weight: { cellWidth: 45 },
                user: { minCellWidth: 50 },
                cycle_comments: { cellWidth: 75 },
                cycle_time: { cellWidth: 50 },
                steam_time: { cellWidth: 50 },
                cook_time: { cellWidth: 50 },
                max_temp: { cellWidth: 45 },
                max_pres_A: { cellWidth: 45 },
                max_pres_G: { cellWidth: 45 },
            },
            headStyles: {
                fillColor: [0, 159, 194],
                overflow: "linebreak",
            },
            bodyStyles: {
                overflow: "linebreak",
                // rowHeight: 16
            },
            didDrawPage: function (data) {
                header();
                footer(data);
            },
        };

    if (customerReport) {
        pdfOptions["columnStyles"]["simple_time_started"] = { cellWidth: 65 };
        pdfOptions["columnStyles"]["facility_name"] = { cellWidth: 65 };
    }



    doc.setFontSize(8);
    if (isAirport) {
        doc.text(
            "Minimum requirements for the management of regulated garbage. Generators of regulated garbage which is treated on-site shall maintain a current record-keeping log with the following information:",
            40,
            106
        );
        doc.text(
            "the exact date of treatment; the quantity of garbage treated, the type of garbage; the on-site treatment method with documentation of applicable process parameters, including but not limited to:",
            40,
            116
        );
        doc.text(
            "time, pressure, and temperature; the printed name of the person responsible for treatment; and the quality control (QC) / challenge testing results (growth / no growth), when applicable.",
            40,
            126
        );
    } else {
        doc.text(
            "Minimum requirements for the management of medical or biological waste. Generators of medical or biological waste which is treated on-site shall maintain a current record-keeping log with the following",
            40,
            106
        );
        doc.text(
            "information: the exact date of treatment; the quantity of waste treated, the type of waste; the on-site treatment method with documentation of applicable process parameters, including but not limited to: time, ",
            40,
            116
        );
        doc.text(
            "pressure, and temperature; the printed name of the person responsible for treatment; and the quality control (QC) / challenge testing results (growth / no growth), when applicable.",
            40,
            126
        );
    }

    autoTable(doc, pdfOptions);
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
    }

    // Definitions page
    doc.addPage();
    doc.setFontSize(16);
    doc.text(`Sterilis ${comprehensiveReport ? "Comprehensive" : "Compliance"} Report Column Definitions`, 270, 30);

    doc.setFontSize(12);

    doc.setFont(undefined, "bold");
    doc.text("Device Serial", 40, 70);
    doc.setFont(undefined, "normal");
    doc.text("Serial number assigned at the factory.", 186, 70);

    doc.setFont(undefined, "bold");
    doc.text("Date", 40, 90);
    doc.setFont(undefined, "normal");
    doc.text("Day and time when the cycle was started.", 186, 90);

    doc.setFont(undefined, "bold");
    doc.text("Weight", 40, 110);
    doc.setFont(undefined, "normal");
    doc.text("Estimated weight of the load in pounds. Entered at the start of the cycle.", 186, 110);

    doc.setFont(undefined, "bold");
    doc.text("Waste Type", 40, 130);
    doc.setFont(undefined, "normal");
    doc.text("Type of waste selected by the operator at the start of the cycle.", 186, 130);

    doc.setFont(undefined, "bold");
    doc.text("Cycle time", 40, 150);
    doc.setFont(undefined, "normal");
    doc.text("Number of minutes to process the load, including sterilization and grinding.", 186, 150);

    doc.setFont(undefined, "bold");
    doc.text("Steam time", 40, 170);
    doc.setFont(undefined, "normal");
    doc.text("Number of minutes of operation for the steam sterilizer.", 186, 170);

    doc.setFont(undefined, "bold");
    doc.text("Cook time", 40, 190);
    doc.setFont(undefined, "normal");
    doc.text("Number of minutes the vessel was at or above sterilization temperature.", 186, 190);

    doc.setFont(undefined, "bold");
    doc.text("Max pressure", 40, 210);
    doc.setFont(undefined, "normal");
    doc.text("Highest pressure reading throughout the cycle.", 186, 210);

    doc.setFont(undefined, "bold");
    doc.text("Max temperature", 40, 230);
    doc.setFont(undefined, "normal");
    doc.text("Highest temperature reading throughout the cycle.", 186, 230);

    doc.setFont(undefined, "bold");
    doc.text("Run by", 40, 250);
    doc.setFont(undefined, "normal");
    doc.text("Name of the operator who began the cycle.", 186, 250);

    doc.setFont(undefined, "bold");
    doc.text("Status", 40, 270);
    doc.setFont(undefined, "normal");
    doc.text("Final status of the cycle.", 186, 270);

    doc.setFont(undefined, "bold");
    doc.text("Comments", 40, 290);
    doc.setFont(undefined, "normal");
    doc.text("Optional comments entered by the operator at the start of the cycle.", 186, 290);

    if (customerReport) {
        doc.setFont(undefined, "bold");
        doc.text("Facility", 40, 310);
        doc.setFont(undefined, "normal");
        doc.text("Name of the facility", 186, 310);
    }

    doc.save(fileName + ".pdf");
};
