import React from "react";
import PropTypes from "prop-types";
import SterilisChart from "src/components/material/Highcharts/SterilisChart";

const CustomerCycleGraph = (props) => {
    return <SterilisChart options={props.options} />;
};

CustomerCycleGraph.propTypes = {
    options: PropTypes.object,
};

export default CustomerCycleGraph;
