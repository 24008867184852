/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {inRangeInclusive} from "./ChartHelpers";

const isValueAbnormal = (value, min, max) => {
  if (min !== null && max !== null && typeof min !== 'undefined' && typeof max !== 'undefined') {
    return !inRangeInclusive(value, min, max);
  } else {
    return false
  }
};

const CycleStat = props => {
  const [valueAbnormal, setValueAbnormal] = useState(false);
  const [hasMounted, setHasMounted] = useState(true);

  useEffect(() => {
    const numericValue = Number(props.value);
    const valueAbnormal = isValueAbnormal(numericValue, props.abnormalValues['minimum_value'], props.abnormalValues['maximum_value']);
    setValueAbnormal(valueAbnormal);
    setHasMounted(true)
  }, [props.value, props.abnormalValues]);

  const containerClass = `surround-padding ${valueAbnormal ? 'light-red-tr' : ''} ${props.dynamicValue ? 'forty-height' : ''}`;

  const findValidRange = () => {
    if (props.usePercent) {
      if (props.abnormalValues['maximum_value'] - props.abnormalValues['minimum_value'] === 0) {
        return `Must be ${props.abnormalValues['maximum_value']}%`
      } else {
        return `Valid Range: ${props.abnormalValues['minimum_value']}% to ${props.abnormalValues['maximum_value']}%`;
      }
    }
    return `Valid Range: ${props.abnormalValues['minimum_value']} to ${props.abnormalValues['maximum_value']}`;
  };

  const findValue = () => {
    if (props.value !== null) {
      if (props.usePercent) {
        return `${props.value}%`
      } else {
        return props.value;
      }
    } else {
      return 'Not Found';
    }
  };

  const findAbnormalValue = () => {
    if (props.showAbnormalRanges) {
      if (valueAbnormal) {
        const abnormalRanges = findValidRange();
        return <p className='strong text-align-end'>{abnormalRanges}</p>
      }
    }
  };

  const value = findValue();
  const abnormalValue = findAbnormalValue();

  return <React.Fragment>
    {hasMounted &&
    <div className={`${containerClass} flex-block-item ${props.useInlineBlock ? 'inline-flex' : ''}`}>
      <div className='flex-dir-row even-split'>
        {
          // valid dom nesting warning; sometimes props.label will be a Checkbox
          // and <p> tags cannot have anything besides text as their children
          typeof(props.label) === "string" ?
            <p>
              {props.label}
            </p> :
            props.label
        }
        <p>
          {value}
        </p>
      </div>
      {abnormalValue}
    </div>}
  </React.Fragment>;
};

CycleStat.defaultProps = {
  abnormalValues: {},
  dynamicValue: false,
  useInlineBlock: false,
  usePercent: false,
  showAbnormalRanges: true,
  label: '',
  value: '',
  statKey: '',
};

CycleStat.propTypes = {
  abnormalValues: PropTypes.object,
  dynamicValue: PropTypes.bool,
  useInlineBlock: PropTypes.bool,
  showAbnormalRanges: PropTypes.bool,
  usePercent: PropTypes.bool,
  key: PropTypes.string,
};

export default CycleStat;
