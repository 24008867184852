import distributorToggle from "./images/sterilis/distributor_user_toggle.png";

export const changelog = [
    {
        type: "new",
        audience: "sterilis",
        desc: "Added new Distributor users toggle.",
        details:
            "Added a toggle to the operators panel on the Device Info page that allows you to view Distributor operators.",
        images: [{ id: "distributor-toggle", src: distributorToggle, title: "Distributor Toggle" }],
    },
];
