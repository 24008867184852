/*
*  Reset PW modal
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "./AuthService";
import SimpleLoader from "./SimpleLoader";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class ResetSiteAdminPasswordModal extends React.Component {
  state = {
    modalLoading: false,
    allowModalToBeClosed: true,
  };

  resetPassword = () => {
    const {
      siteAdminBeingEdited
    } = this.props;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/forgot-password/`, {
      method: 'POST',
      body: JSON.stringify({
        'portal_user_email': siteAdminBeingEdited['employee']['email_address']
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      });
      this.props.notifyGenericSuccess('sent reset password email', 'to', siteAdminBeingEdited['employee']['email_address']);
      this.props.triggerResetSiteAdminPasswordModal(null)
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      });
      this.props.notifyGenericFailure('send reset password email', 'to', siteAdminBeingEdited['employee']['email_address']);
      this.props.triggerResetSiteAdminPasswordModal(null)
    });
  };

  render() {
    const {
      t,
      siteAdminBeingEdited
    } = this.props;
    const siteAdminUsername = siteAdminBeingEdited['username'];
    const siteAdminEmail = siteAdminBeingEdited['employee']['email_address'];

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.resetSiteAdminPasswordModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          size='small'
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerResetSiteAdminPasswordModal(null)
          }}
        >
          {this.state.modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2>{t('Reset password')}</h2>
          </Header>
          <Modal.Content>
            <div className='short-modal-text center-text'>
              <Trans ns='translations' i18nKey='reset_password_prompt'>
                Are you sure you want to send a password reset email to <strong>{{siteAdminUsername}}</strong> ({{siteAdminEmail}})?
              </Trans>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn grey-btn'
                    value="Submit"
                    type="submit"
                    onClick={this.resetPassword}
            >{t('Reset password')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(ResetSiteAdminPasswordModal);
