/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Trans } from "react-i18next";
import '../css/Login.css';
import SimpleLoader from "./SimpleLoader";
import {composeHoc} from "./library/helpers";
import sterLogo from "../images/logo-sterilis.png";
import { Card, Form } from 'semantic-ui-react'
import {Link} from "react-router-dom";
import AuthService from "./AuthService";
import {toast} from "react-toastify";
const Auth = new AuthService();

class ForgotPassword extends React.Component{
  state = {
    isLoading : false,
    noMatch : false,
    noPortalUser : false,
  };

  notifyEmailSent = (employeeEmail) => toast(`Successfully sent email to ${employeeEmail}`, { type: toast.TYPE.DEFAULT, autoClose: 5000 });
  submitForgotPassword = (e) => {
    e.preventDefault();
    const portalUserEmail = this.state.email;
    this.setState({isLoading: true});
    Auth.fetch(`/api/forgot-password/`, {
        method : 'POST',
        body: JSON.stringify({
          'portal_user_email' : portalUserEmail
        })
      }).then(data => {
        this.setState({
          isLoading: false,
          noMatch : false,
          attemptedEmail : null,
        });
        this.notifyEmailSent(portalUserEmail)
      }).catch(err => {
        err.response.json().then((jsonErr) => {
          if(jsonErr.status === 'no_employee_found' || jsonErr.status === 'no_portal_user_found'){
            this.shakeSorry();
            this.setState({
              isLoading: false,
              noMatch : true,
              // we want a second variable so we can use 'email' in the DOM w/out it being updated as the user attempts again
              attemptedEmail : portalUserEmail
            });
          }
          else{
            // this else clause should rarely/never be hit
            this.setState({
              isLoading: false,
              noMatch: false,
              attemptedEmail : null,
            });
          }
        })
    });
  };
  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  };

  shakeSorry = () => {
    this.setState({
      submitShake : true,
    }, () => {
      setTimeout(() => {
        this.setState({
          submitShake : false,
        })
      }, 1000)
    })
  };
  render() {
    const {
      isLoading,
      noMatch,
      attemptedEmail,
      submitShake
    } = this.state;
    const {t} = this.props;
    return (
      <div className={`login-form `}>
        {isLoading ? (
          <SimpleLoader/>
        ) : null}
        <div>
          {/*<ToastContainer id='toast' />*/}
          <Card.Content className="login-header top-border-radius">
            <img src={sterLogo} alt="Sterilis Medical" className="ster-img"/>
          </Card.Content>
          <Card.Content className="login-body">
            <div className='slight-margin-bottom'>
              <p className="login-intro">
                <Trans i18nKey="forgot-pw-intro">Forgot your password? Don't worry, we're here to help.</Trans>
              </p>
            </div>
            <div className='slight-margin-bottom'>
              <p className="login-intro">
                <Trans i18nKey="forgot-pw-intro-2">Enter the email address associated with your Sterilis account to begin.</Trans>
              </p>
            </div>
            {
              noMatch ?
                <div className='slight-margin-bottom'>
                  <p className={`light-validation-error login-intro ` + (submitShake ? ' shake ' : '')}>
                    {t('Sorry, but we couldn\'t find a Sterilis account associated with')} {attemptedEmail}
                  </p>
                </div> : null
            }
            <Form onSubmit={this.submitForgotPassword}>
              <Form.Field>
                <input
                  className="form-item"
                  placeholder="Email address"
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  required
                />
              </Form.Field>
              <input
                className="form-submit"
                id="resetButton"
                value="SUBMIT"
                type="submit"
              />
            </Form>
            <Link to="/login">
              <div className='light-hyperlink'>
                {t('Return to login')}
              </div>
            </Link>
          </Card.Content>
        </div>
      </div>
    )
  }
}

export default composeHoc(translate('translations'))(ForgotPassword);
