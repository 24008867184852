/*
* The device table inside the facility table, which is inside the Customer table on the View Customers page
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import HookTable from "../library/HookTable";
import {timestampSortHook} from "../library/helpers";
import PropTypes from 'prop-types';
import {Checkbox} from "semantic-ui-react";

const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, canBeChecked, ...rest}, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    if (canBeChecked === false) {
      rest['checked'] = false;
    }

    return (
      <React.Fragment>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </React.Fragment>
    )
  }
);

const ParsedCycleTable = props => {
    const [groupCyclesByID, setGroupCyclesByID] = useState(true);
    const {t} = useTranslation('translations');
    const customRowClass = (rowInfo) => {
      if (rowInfo && rowInfo.original) {
        return `${rowInfo.original.is_successful === false ? 'light-red-tr' : ''} ${rowInfo.original.child_row ? 'child-row-indent' : ''}`;
      }
    };

    const cycleClick = row => {
      if (props.showOnRowSelectCheckboxes) {
      } else if (row.original.cycle_type !== 'Notification') {
        if (row.original.cycle_type === 'BurninTest') {
          props.setShowBurninContainer(true);
        } else {
          props.setShowBurninContainer(false);
        }
        props.setZoomParams({});
        props.setSelectedIndex(row.index);
        props.setSelectedCycle(row.original);
      }
    };


    const parserColumns = React.useMemo(() => [
      ...props.showOnRowSelectCheckboxes ? [{
        id: props.showBurninContainer ? 'select_burnin_test_7' : 'overlay_cycle_selection',
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => {
          const checkbox = <IndeterminateCheckbox
            {...{...row.getToggleRowSelectedProps(), ...{canBeChecked: props.showOnRowSelectCheckboxes}}}
          />;
          return <div>
            {props.showBurninContainer ?
              (
                row.original.cycle_type === 'SteamCycle' //only render BiT-7 checkboxes for Steam Cycles
              ) && checkbox :
              (
                row.index !== props.selectedIndex &&  // don't render the checkbox for the currently selected cycle
                row.original.cycle_type === props.cycleType //only render checkboxes for cycles of the same type
              ) && checkbox
            }
          </div>
        },
      }] : [],
      // expandColumn(t('Cycles')),
      {
        Header: t('Event time'),
        accessor: 'start_time',
        manualWidth: 125,
        id: 'start_time',
        sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss z")
      },
      {
        Header: t('Event Type'),
        accessor: 'pretty_cycle_type',
      },
      {
        Header: t('Waste Type'),
        accessor: 'pretty_waste_type',
      },

      {
        Header: t('Score/Message'),
        accessor: 'score',
      },
      {
        Header: t('Cycle Duration (mins)'),
        accessor: 'duration_minutes',
      },
      {
        Header: t('Message count'),
        accessor: 'info_count',
      },
      {
        Header: t('Cycle id'),
        accessor: 'cycle_id',
      },


    ], [props.showOverlayCycleSelect, props.showOnRowSelectCheckboxes, props.showBurninContainer, groupCyclesByID]);

    const leftHandJSX = React.useMemo(() => <div className='flex-dir-column'>
      <Checkbox
        className='digital-output-toggle'
        toggle
        label='Show Messages in table'
        onChange={() => props.setShowMessages(!props.showMessages)}
        checked={props.showMessages}
      />
      {props.showBurninContainer === false &&
      <Checkbox
        className='digital-output-toggle'
        toggle
        label='Show Overlay Cycle checkboxes in table'
        onChange={() => props.setShowOverlayCycleSelect(!props.showOverlayCycleSelect)}
        checked={props.showOverlayCycleSelect}
      />
      }
      <Checkbox
        className='digital-output-toggle'
        toggle
        label='Group Cycles by cycle id'
        onChange={() => setGroupCyclesByID(!groupCyclesByID)}
        checked={groupCyclesByID}
      />
    </div>, [props.showMessages, props.showOverlayCycleSelect, props.batchProcessing, props.showBurninContainer, groupCyclesByID]);

    const tableData = React.useMemo(() => {
      let {
        data
      } = props;
      let tableData = data;
      if (props.showMessages === false) {
        tableData = tableData.filter(item => item['cycle_type'] !== 'Notification');
      }

      if (props.showBurninTest7CycleSelect) {
        tableData = tableData.filter(item => item['cycle_type'] === 'SteamCycle')
      }

      if (groupCyclesByID) {
        const mainCycleIDs = new Set();
        tableData = tableData.map((item) => {
          if (item.cycle_id) {
            if (mainCycleIDs.has(item.cycle_id)) {
              return {
                child_row: true,
                ...item,
              }
            } else {
              mainCycleIDs.add(item.cycle_id)
            }
          }
          return item;
        });
      }

      return tableData;
    }, [props.showMessages, props.data, props.showBurninTest7CycleSelect, groupCyclesByID]);


    return <HookTable
      tableName='parsedCycleTable'
      floatLeftHeaderJSX={leftHandJSX}
      columns={parserColumns}
      data={tableData}
      showPagination={true}
      defaultPageSize={tableData.length > 10 ? 20 : 10}
      customRowClass={true}
      renderEmptyRow={false}
      enableOnRowClickCallback={true}
      onClickCallback={cycleClick}
      customRowClassFunc={customRowClass}
      selectedIndex={props.selectedIndex}
      defaultSortBy={{'id': 'start_time'}}
      onRowSelectCallback={props.onRowSelectCallback}
      showOnRowSelectCheckboxes={props.showOnRowSelectCheckboxes}
    />
  }
;

ParsedCycleTable.defaultProps = {
  data: [],
  selectedIndex: 0,
  batchProcessing: false,
  showMessages: false,
  showBurninContainer: false,
  showOverlayCycleSelect: false,
  showBurninTest7CycleSelect: false,
};

ParsedCycleTable.propTypes = {
  data: PropTypes.array,
  selectedIndex: PropTypes.number,
  batchProcessing: PropTypes.bool,
  showMessages: PropTypes.bool,
  showBurninContainer: PropTypes.bool,
  showOverlayCycleSelect: PropTypes.bool,
  showBurninTest7CycleSelect: PropTypes.bool,
  setShowBurninContainer: PropTypes.func.isRequired,
  onRowSelectCallback: PropTypes.func,
};


export default ParsedCycleTable;
