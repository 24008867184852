/*
* Main housing component for the Create Facility, Customer, and Distributor forms
*
* Copyright (C) 2018 Sterilis Solutions LLC all rights reserved.
*/
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Button, Card, Icon } from 'semantic-ui-react';
import {useForm, FormProvider} from 'react-hook-form';
import CreateDCFHeader from './CreateDCFHeader'
import AuthService from "../../AuthService";
import FacilityForm from './FacilityForm';
import CustomerForm from './CustomerForm';
import {CreateDCFDesc} from "./CreateDCFDesc";
import DistributorForm from "./DistributorForm";
import {AuthReq} from "../../library/helpers";
import * as Sentry from "@sentry/browser";
import SimpleLoader from "../../SimpleLoader";
import {toast} from "react-toastify";
import get from "lodash/get";
import {useHistory} from 'react-router-dom';

const Auth = new AuthService();


const notifyFailure = (message) => toast(`${message} Please refresh the page and try again.`, {
  type: toast.TYPE.ERROR,
  autoClose: 15000
});

const notifySuccess = (createdItems) => toast(<div>Successfully created {createdItems}</div>, {
  type: toast.TYPE.DEFAULT,
  autoClose: 5000
});

const fillScheduleAlert = () => toast("Setting Facility Schedule is mandatory along with other facility details!", {
  type: toast.TYPE.WARNING,
  autoClose: 10000
});

const CreateDistributorCustomerFacility = props => {
  const {t} = useTranslation('translations');
  const methods = useForm();
  const [timezones, setTimezones] = useState({});
  const [stateDropdownOptions, setStateDropdownOptions] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [allowSubmitFacility, setAllowSubmitFacility] = useState(false);
  const [scheduleIconName, setScheduleIconName] = useState("calendar alternate outline");

  // used so we don't display success message if any submit calls failed
  const [callFailed, setCallFailed] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [facilityScheduleModal, setFacilityScheduleModal] = useState(false);
  const getRegionSettings = () => {
    setLoading(true);
    return Auth.fetch(`/api/region-timezone/`)
      .then(data => {
        const stateDropdownOptions = data.reduce((acc, region) => {
          if (region['country'] === 'us' || region['country'] === 'ca') {
            if (acc[region['country']].length === 0) {
              acc[region['country']].push(
                {
                  key: region['abbreviation'],
                  value: region['abbreviation'],
                  text: region['region_name'],
                }
              )
            } else {
              if (acc[region['country']].some(option => option['key'] === region['abbreviation']) === false) {
                acc[region['country']].push(
                  {
                    key: region['abbreviation'],
                    value: region['abbreviation'],
                    text: region['region_name'],
                  }
                )
              }
            }
          }
          return acc;
        }, {
          'us': [], // US and canada are the only two countries we support the concept of "states" for
          'ca': [],
        });


        const regionTimezones = data.reduce((prev, curr) => {
          // check if the country has been added yet
          if (prev[curr.timezone.country]) {
            // if it exists in country obj already
            if (prev[curr.timezone.country][curr.abbreviation]) {
              // push to the array
              prev[curr.timezone.country][curr.abbreviation].push({
                'timezone': curr.timezone.timezone,
                'display_label': curr.timezone.display_label,
                'regionID': curr.id,
                'state': curr.abbreviation
              })
            } else {
              // create an array of timezones
              prev[curr.timezone.country][curr.abbreviation] = [{
                'timezone': curr.timezone.timezone,
                'display_label': curr.timezone.display_label,
                'regionID': curr.id,
                'state': curr.abbreviation
              }];
            }
          } else {
            // Create the object
            prev[curr.timezone.country] = {
              // Key: timezoneArray
              [curr.abbreviation]: [{
                'timezone': curr.timezone.timezone,
                'display_label': curr.timezone.display_label,
                'regionID': curr.id,
                'state': curr.abbreviation
              }]
            };
          }
          return prev;
        }, {});

        //Creates an object of arrays where each array index is a unique timezone for a given region
        // { ar : ["America/Argentina/Buenos_Aires"], us: {"America/Chicago", "America/Anchorage", ... etc] }​
        const uniqueTimezones = {};
        Object.keys(regionTimezones).forEach((country) => {
          const individualCountry = regionTimezones[country];
          uniqueTimezones[country] = [];
          Object.keys(individualCountry).forEach((states) => {
            individualCountry[states].forEach((state) => {
              if (!uniqueTimezones[country].includes(state)) {
                uniqueTimezones[country].push(state);
              }
            });
          });
        });
        setTimezones(uniqueTimezones);
        setStateDropdownOptions(stateDropdownOptions);
        setLoading(false);
      }).catch(err => {
        notifyFailure('Failed to fetch timezone information.');
        setLoading(false);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    getRegionSettings()
  }, []);
  const history = useHistory();
  useEffect(() => {
    if (callFailed === false && successText.length > 0) {
      notifySuccess(successText);
      history.push('/view/customers');
    }
  }, [successText, callFailed]);

  const onSubmit = async values => {
    let successText = [];
    setLoading(true);
    setSuccessText('');
    setCallFailed(false);

    if (props['create'] === 'distributor') {
      setLoadingText('Creating Distributor and Device Warehouse...');
      await createDistributor(values);
      successText.push(<div key={values['distributorName']}>Distributor - {values['distributorName']}</div>);
      successText.push(<div key={values['distributorName']}>Customer - {values['distributorName']}</div>);
      successText.push(<div key={values['facilityName']}>Facility - {values['facilityName']}</div>);
    } else if (props['create'] === 'customer') {
      setLoadingText('Creating customer and facility...');
      await createCustomer(values);
      successText.push(<div key={values['customerName']}>Customer - {values['customerName']}</div>);
      successText.push(<div key={values['facilityName']}>Facility - {values['facilityName']}</div>);
    } else if (props['create'] === 'facility') {
      setLoadingText('Creating facility...');
      await createFacility(values);
      createSchedule(schedule);
      successText.push(<div key={values['facilityName']}>Facility - {values['facilityName']}</div>);
    }

    setLoading(false);
    setSuccessText(successText);

  };

  const createDistributor = values => {
    // Set the Distributor's first customer's name to the Distributors name; we hide the Customer name
    // input from the user to keep things simpler
    const customerName = values['distributorName'];
    const distributor = {
      'distributor_name': values['distributorName'],
      'customer': {
        'customer_name': customerName,
        'facility': {
          'region_setting_id': values['facilityTimezone'], // this is the regionID
          'facility_name': values['facilityName'],
          'facility_type': values['facilityType'],
          'elevation': values['facilityElevation'],
          'incubation_period': values['incubationPeriod'],
          'thoroughfare': values['facilityThoroughfare'],
          'sub_thoroughfare': values['facilitySubThoroughfare'],
          'locality': values['facilityCity'],
          'postal_code': values['facilityZip'],
          'waste_per_month': values['facilityWastePerMonth'],
          'enable_facility_specific_cooktime': values['enableFacilityCooktime'],
          'facility_specific_cooktime': values['enableFacilityCooktime'] ? values['facilitySpecificCooktime'] : null,
          'bio_validation_waste_type': values['bioValidationWasteType'],
        }
      }
    };
    return Auth.fetch(`/api/distributor/`, {
      method: 'POST',
      body: JSON.stringify(distributor)
    }).then(data => {
      return data['id'];
    }).catch(err => {
      setCallFailed(true);
      notifyFailure('Failed to create distributor.');
      Sentry.captureException(err);
    });
  };

  const createFacility = values => {
    const facility = {
      'customer_id': values['facilityCustomer'],
      'region_setting_id': values['facilityTimezone'], // this is the regionID
      'facility_name': values['facilityName'],
      'facility_type': values['facilityType'],
      'elevation': values['facilityElevation'],
      'incubation_period': values['incubationPeriod'],
      'thoroughfare': values['facilityThoroughfare'],
      'sub_thoroughfare': values['facilitySubThoroughfare'],
      'locality': values['facilityCity'],
      'postal_code': values['facilityZip'],
      'waste_per_month': values['facilityWastePerMonth'],
      'bio_validation_waste_type': values['bioValidationWasteType'],
      'days_open': values['daysOpen'],
      'hours_open': values['hoursOpen'],
    };
    return Auth.fetch(`/api/facility/`, {
      method: 'POST',
      body: JSON.stringify(facility)
    }).then(data => {
      if (data['id']) {
        schedule.map(row => {
          row.facility = data['id'];
          return row;
        });
      }
      return data['id'];
    }).catch(err => {
      setCallFailed(true);
      notifyFailure('Failed to create facility.');
      Sentry.captureException(err);
    });

  };

  const createSchedule = schedule => {
        return Auth.fetch(`/api/facility-schedule/`, {
      method: 'POST',
      body: JSON.stringify(schedule)
        }).then(data => {
          return data['id'];
    }).catch(err => {
      setCallFailed(true);
      notifyFailure('Failed to create schedule.');
      Sentry.captureException(err);
    });
  };
  const createCustomer = values => {
    const customer = {
      'customer_name': values['customerName'],
      'distributor_id': values['customerDistributor'],
      'facility': {
        'region_setting_id': values['facilityTimezone'], // this is the regionID
        'facility_name': values['facilityName'],
        'facility_type': values['facilityType'],
        'elevation': values['facilityElevation'],
        'incubation_period': values['incubationPeriod'],
        'thoroughfare': values['facilityThoroughfare'],
        'sub_thoroughfare': values['facilitySubThoroughfare'],
        'locality': values['facilityCity'],
        'postal_code': values['facilityZip'],
        'waste_per_month': values['facilityWastePerMonth'],
        'enable_facility_specific_cooktime': values['enableFacilityCooktime'],
        'facility_specific_cooktime': values['enableFacilityCooktime'] ? values['facilitySpecificCooktime'] : null,
        'bio_validation_waste_type': values['bioValidationWasteType'],
      }
    };
    return Auth.fetch(`/api/customer/`, {
      method: 'POST',
      body: JSON.stringify(customer)
    }).then(data => {
      return data['id'];
    }).catch(err => {
      Sentry.captureException(err);
      setCallFailed(true);
      err.response.json().then(resp => {
        const customerNameErrorReason = get(resp, 'customer_name', false);
        customerNameErrorReason ? notifyFailure(`Failed to create customer. Reason: ${customerNameErrorReason[0]}`) :
          notifyFailure('Failed to create customer.');

      });
    });
  };

  const triggerFacilityScheduleModal = () => {
    return setFacilityScheduleModal(!facilityScheduleModal);
  };

  return (
		<Card fluid>
			<CreateDCFHeader create={props["create"]} />
			<Card.Content className="card-body">
				{isLoading && <SimpleLoader loaderText={loadingText} />}
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<div className="split-container slight-margin-top">
							<CreateDCFDesc create={props["create"]} />
							<div className="items">
								{/* We don't need to show the Distributor dropdown when the user is creating a Facility, since the */}
								{/* distributor will be chosen by the customer they choose */}
								{(props["create"] === "distributor" || props["create"] === "customer") && (
									<AuthReq
										userGroup={props.group}
										requiredRoles={[
											"SterilisSuperUsers",
											"SterilisPortalUsers",
											"SterilisWasteTypeAdmin",
										]}
									>
										<DistributorForm create={props["create"]} />
									</AuthReq>
								)}
								{(props["create"] === "facility" || props["create"] === "customer") && (
									<CustomerForm group={props.group} create={props["create"]} />
								)}
								<FacilityForm
									timezones={timezones}
									stateDropdownOptions={stateDropdownOptions}
									create={props["create"]}
									setSchedule={setSchedule}
									setAllowSubmitFacility={setAllowSubmitFacility}
									allowSubmitFacility={allowSubmitFacility}
									setFacilityScheduleModal={setFacilityScheduleModal}
									facilityScheduleModal={facilityScheduleModal}
									setScheduleIconName={setScheduleIconName}
									triggerFacilityScheduleModal={triggerFacilityScheduleModal}
								/>
								{props["create"] === "facility" && (
									<Button
										icon
										labelPosition="left"
										onClick={() => triggerFacilityScheduleModal()}
										type={"button"}
									>
										<Icon name={scheduleIconName} />
										{t("Schedule Facility")}
									</Button>
								)}
								{props["create"] === "facility" ? (
									<Button
										className="submit-machine"
										value="Submit"
										type={!allowSubmitFacility ? "button" : "submit"}
										onClick={() => {
											return !allowSubmitFacility ? fillScheduleAlert() : null;
										}}
										primary
									>
										{t("Submit")}
									</Button>
								) : (
									<Button className="submit-machine" value="Submit" type="submit" primary>
										{t("Submit")}
									</Button>
								)}
							</div>
						</div>
					</form>
				</FormProvider>
			</Card.Content>
		</Card>
  );
};

export default CreateDistributorCustomerFacility;
