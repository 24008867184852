/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import GrindCycle from "./GrindCycle/GrindCycle";
import SteamCycleTrendsChart from "./TrendsChart/SteamCycleTrendsChart";
import GrindCycleTrendsChart from "./TrendsChart/GrindCycleTrendsChart";
import SharedCycleTrendsChart from "./TrendsChart/SharedCycleTrendsChart";
import CycleMetricChartOptions from "./CycleMetricChartOptions";
import CurrentChart from "./CurrentChart/CurrentChart";
import SteamChart from "./SteamChart/SteamChart";
import { Box, Card } from "@mui/material";
import AuthService from "src/components/AuthService";
import SimpleBackdrop from "src/components/material/SimpleBackdrop/SimpleBackdrop";
import { getStatePlotBands, getNotificationPlotLines, dioPlotBands } from "./CycleStats/ChartHelpers";

const Auth = new AuthService();

export const processPlotBand = (plotBand, digitalOutputOverlays, overlayStates) => {
    const digitalOutputsOn = Object.values(digitalOutputOverlays).includes(true);
    if (digitalOutputsOn && overlayStates) {
        // if its the state plot band and digital outputs are overlayed while states are overlayed
        // bump the state's plot band up to -90
        return plotBand.map((band) => {
            return {
                ...band,
                label: {
                    ...band.label,
                    y: -90,
                },
            };
        });
    }
    return plotBand;
};

const CycleMetricChartContainer = (props) => {
    const [chartOptions, _setChartOptions] = useState({
        combineTPGraphs: true,
        showCurrentGraph: true,
        showTempConstants: true,
        showPresConstants: true,
        overlayStates: false,
        overlayInfo: false,
        plotDutyCycle: false,
        showAbnormalSeriesValues: false,
    });

    const [chartLoading, setChartLoading] = useState(false);
    const [timeList, setTimeList] = useState([]);
    const [currentList, setCurrentList] = useState([]);
    const [tvList, setTvList] = useState([]);
    const [thList, setThList] = useState([]);
    const [rtList, setRtList] = useState([]);
    const [paList, setPaList] = useState([]);
    const [pvList, setPvList] = useState([]);
    const [psList, setPsList] = useState([]);
    const [dutyCycleList, setDutyCycleList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [dioList, setDioList] = useState([]);
    const [infoConstants, setInfoConstants] = useState([]);

    const setChartOptions = (newState) => _setChartOptions({ ...chartOptions, ...newState });

    const [digitalOutputOverlays, _setDigitalOutputOverlays] = useState({
        overlaySol1: false,
        overlaySol2: false,
        overlaySol3: false,
        overlaySol4: false,
        overlaySol5: false,
        overlaySol6: false,
        overlaySol7: false,
        overlaySol8: false,
        overlaySol9: false,
        overlaySol10: false,
        overlaySol11: false,
        overlaySol12: false,
        overlaySol13: false,
        overlaySol14: false,
        overlaySol15: false,
        overlaySol16: false,
        overlaySol17: false,
        overlaySol18: false,
        overlayVacPump: false,
        overlayAirPump: false,
        overlayWaterPump: false,
        overlayHeaters: false,
        overlayDeodorizerLED: false,
        overlayGrinderOn: false,
        overlayGrinderForward: false,
        overlayOil: false,
        overlayOilLevels: false,
    });

    const setDigitalOutputOverlays = (newState) => _setDigitalOutputOverlays({ ...digitalOutputOverlays, ...newState });

    const {
        combineTPGraphs,
        showCurrentGraph,
        showTempConstants,
        showPresConstants,
        overlayStates,
        overlayInfo,
        plotDutyCycle,
        showAbnormalSeriesValues,
    } = chartOptions;

    const {
        overlaySol1,
        overlaySol2,
        overlaySol3,
        overlaySol4,
        overlaySol5,
        overlaySol6,
        overlaySol7,
        overlaySol8,
        overlaySol9,
        overlaySol10,
        overlaySol11,
        overlaySol12,
        overlaySol13,
        overlaySol14,
        overlaySol15,
        overlaySol16,
        overlaySol17,
        overlaySol18,
        overlayVacPump,
        overlayAirPump,
        overlayWaterPump,
        overlayHeaters,
        overlayDeodorizerLED,
        overlayGrinderOn,
        overlayGrinderForward,
        overlayOil,
        overlayOilLevels,
    } = digitalOutputOverlays;

    useEffect(() => {
        setChartLoading(true);

        if (props.cycleType === "SteamCycle") {
            Auth.fetch(`/metrics/steam-metric?metadata_id=${props.selectedCycle.metadata}`, {
                method: "GET",
            })
                .then((data) => {
                    const times = data.map((point) => Date.parse(point.time));
                    setTimeList(times);

                    const ampSeries = data.map((point) => [Date.parse(point.time), point.amps]);
                    setCurrentList(ampSeries);

                    const tvSeries = data.map((point) => [Date.parse(point.time), point.vessel_temp]);
                    setTvList(tvSeries);

                    const thSeries = data.map((point) => [Date.parse(point.time), point.heater_temp]);
                    setThList(thSeries);

                    const runawayTempSeries = data.map((point) => [Date.parse(point.time), point.runaway_temp]);
                    setRtList(runawayTempSeries);

                    const paSeries = data.map((point) => [Date.parse(point.time), point.accumulator_pressure]);
                    setPaList(paSeries);

                    const pvSeries = data.map((point) => [Date.parse(point.time), point.vessel_pressure]);
                    setPvList(pvSeries);

                    const psSeries = data.map((point) => [Date.parse(point.time), point.seal_pressure]);
                    setPsList(psSeries);

                    const dutyCycleSeries = data.map((point) => [Date.parse(point.time), point.duty_cycle]);
                    setDutyCycleList(dutyCycleSeries);

                    const stateSeries = data.map((point) => [Date.parse(point.time), point.state]);
                    setStateList(getStatePlotBands(stateSeries));

                    const dioSeries = data.map((point) => [Date.parse(point.time), point.digital_output]);
                    setDioList(dioPlotBands(dioSeries, props.digitalOutput));

                    setInfoConstants(getNotificationPlotLines(props.selectedCycle.notifications));

                    setChartLoading(false);
                })
                .catch((err) => {
                    console.log("error caught");
                    setChartLoading(false);
                });
        } else if (props.cycleType === "GrindCycle") {
            Auth.fetch(`/metrics/grind-metric?metadata_id=${props.selectedCycle.metadata}`, {
                method: "GET",
            })
                .then((data) => {
                    const times = data.map((point) => Date.parse(point.time));
                    setTimeList(times);

                    const ampSeries = data.map((point) => [Date.parse(point.time), point.amps]);
                    setCurrentList(ampSeries);

                    const dutyCycleSeries = data.map((point) => [Date.parse(point.time), point.duty_cycle]);
                    setDutyCycleList(dutyCycleSeries);

                    const paSeries = data.map((point) => [Date.parse(point.time), point.accumulator_pressure]);
                    setPaList(paSeries);

                    const stateSeries = data.map((point) => [Date.parse(point.time), point.state]);
                    setStateList(getStatePlotBands(stateSeries));

                    const dioSeries = data.map((point) => [Date.parse(point.time), point.digital_output]);
                    setDioList(dioPlotBands(dioSeries, props.digitalOutput));

                    setInfoConstants(getNotificationPlotLines(props.selectedCycle.notifications));

                    setChartLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setChartLoading(false);
                });
        }
    }, [props.selectedCycle]);

    const plotBands = useMemo(() => {
        return [
            ...(overlaySol1 && dioList?.vacuum_valve ? dioList.vacuum_valve : []),
            ...(overlaySol2 && dioList.drain_valve ? dioList.drain_valve : []),
            ...(overlaySol3 && dioList.water_valve ? dioList.water_valve : []),
            ...(overlaySol4 && dioList.top_lid_valve ? dioList.top_lid_valve : []),
            ...(overlaySol5 && dioList.bottom_lid_valve ? dioList.bottom_lid_valve : []),
            ...(overlaySol6 && dioList.shroud_valve ? dioList.shroud_valve : []),
            ...(overlaySol7 && dioList.accumulator_valve ? dioList.accumulator_valve : []),
            ...(overlaySol8 && dioList.seal_valve ? dioList.seal_valve : []),
            ...(overlaySol9 && dioList.jack_valve ? dioList.jack_valve : []),
            ...(overlaySol10 && dioList.air_inlet_valve ? dioList.air_inlet_valve : []),
            ...(overlaySol11 && dioList.oil_valve ? dioList.oil_valve : []),
            ...(overlaySol12 && dioList.seal_vacuum_valve ? dioList.seal_vacuum_valve : []),
            ...(overlaySol13 && dioList.vessel_valve ? dioList.vessel_valve : []),
            ...(overlaySol14 && dioList.odor_valve ? dioList.odor_valve : []),
            ...(overlaySol15 && dioList.shroud_2 ? dioList.shroud_2 : []),
            ...(overlaySol16 && dioList.deodorizer_spray ? dioList.deodorizer_spray : []),
            ...(overlaySol17 && dioList.drain_valve_alt ? dioList.drain_valve_alt : []),
            ...(overlaySol18 && dioList.signal_light ? dioList.signal_light : []),
            ...(overlayVacPump && dioList.vac_pump ? dioList.vac_pump : []),
            ...(overlayAirPump && dioList.pressure_pump ? dioList.pressure_pump : []),
            ...(overlayWaterPump && dioList.water_pump ? dioList.water_pump : []),
            ...(overlayHeaters && dioList.heater ? dioList.heater : []),
            ...(overlayDeodorizerLED && dioList.deodorizer_led ? dioList.deodorizer_led : []),
            ...(overlayGrinderOn && dioList.grinder_on ? dioList.grinder_on : []),
            ...(overlayGrinderForward && dioList.grinder_fwd ? dioList.grinder_fwd : []),
            ...(overlayOil && dioList.oil ? dioList.oil : []),
            ...(overlayOilLevels && dioList.oil_level ? dioList.oil_level : []),

            ...(overlayStates ? processPlotBand(stateList, digitalOutputOverlays, overlayStates) : []),
        ];
    }, [digitalOutputOverlays, overlayStates, dioList]);

    // use memo here since the .some calculation is kind of heavy and we shouldn't just re-calc
    // every time state changes in this component
    const dutyCycleValuesOverThreshHold = useMemo(() => {
        return plotDutyCycle && dutyCycleList.some((dutyCycleVal) => dutyCycleVal >= 690);
    }, [dutyCycleList, plotDutyCycle, timeList]);

    return (
        <Box pb={2}>
            <CycleMetricChartOptions
                cycleType={props.cycleType}
                infoCount={props.infoCount}
                chartOptions={chartOptions}
                setChartOptions={setChartOptions}
                setDigitalOutputOverlays={setDigitalOutputOverlays}
                digitalOutputOverlays={digitalOutputOverlays}
                scrollOnCycleClick={props.scrollOnCycleClick}
                setScrollOnCycleClick={props.setScrollOnCycleClick}
                showFloatButtons={props.showFloatButtons}
                setShowFloatButtons={props.setShowFloatButtons}
            />
            <Card variant="outlined" sx={{ padding: 2, my: 2 }}>
                {props.cycleType === "SteamCycle" && (
                    <Box position="relative">
                        {chartLoading && <SimpleBackdrop loaderText="Preparing Chart..." />}
                        <SteamChart
                            thList={thList}
                            tvList={tvList}
                            trList={rtList}
                            paList={paList}
                            psList={psList}
                            pvList={pvList}
                            iList={currentList}
                            timeList={timeList}
                            plotBands={plotBands}
                            chartOptions={chartOptions}
                            combineTPGraphs={combineTPGraphs}
                            showCurrentGraph={showCurrentGraph}
                            showTempConstants={showTempConstants}
                            showPresConstants={showPresConstants}
                            infoConstants={infoConstants}
                            overlayInfo={overlayInfo}
                            overlayStates={overlayStates}
                            plotDutyCycle={plotDutyCycle}
                            dutyCycleList={dutyCycleList}
                            dutyCycleValuesOverThreshHold={dutyCycleValuesOverThreshHold}
                            showAbnormalSeriesValues={showAbnormalSeriesValues}
                            abnormalThValues={props.abnormalValuesObj["th_list"]}
                            abnormalTvValues={props.abnormalValuesObj["tv_list"]}
                            abnormalTrValues={props.abnormalValuesObj["tr_list"]}
                            abnormalPaValues={props.abnormalValuesObj["pa_list"]}
                            abnormalPsValues={props.abnormalValuesObj["ps_list"]}
                            abnormalPvValues={props.abnormalValuesObj["pv_list"]}
                            abnormalIValues={props.abnormalValuesObj["i_list"]}
                            abnormalDutyCycleValues={props.abnormalValuesObj["duty_cycle_list"]}
                            zoomParams={props.zoomParams}
                            setZoomParams={props.setZoomParams}
                            overlayedCycles={props.overlayedCycles}
                            cycleStartTime={props.cycleStartTime}
                        />
                    </Box>
                )}

                {props.cycleType === "SteamCycle" && showCurrentGraph && (
                    <Box position="relative">
                        {chartLoading && <SimpleBackdrop loaderText="Preparing Chart..." />}
                        <CurrentChart
                            timeList={timeList}
                            iList={currentList}
                            plotBands={plotBands}
                            overlayInfo={overlayInfo}
                            infoConstants={infoConstants}
                            combineTPGraphs={props.cycleType === "SteamCycle" ? false : combineTPGraphs}
                            showAbnormalSeriesValues={showAbnormalSeriesValues}
                            abnormalIValues={
                                props.cycleType === "SteamCycle"
                                    ? props.abnormalValuesObj["i_list"]
                                    : props.abnormalValuesObj["ig_list"]
                            }
                            customTitlePrefix={props.customTitlePrefix}
                            zoomParams={props.zoomParams}
                            setZoomParams={props.setZoomParams}
                            overlayedCycles={props.overlayedCycles}
                            cycleStartTime={props.cycleStartTime}
                            isTPACurrentGraph={props.cycleType === "GrindCycle"}
                            cycleType={props.cycleType}
                        />
                    </Box>
                )}
                {props.cycleType === "GrindCycle" && (
                    <Box position="relative">
                        {chartLoading && <SimpleBackdrop loaderText="Preparing Chart..." />}
                        <GrindCycle
                            plotBands={plotBands}
                            timeList={timeList}
                            igList={currentList}
                            paList={paList}
                            infoConstants={infoConstants}
                            overlayInfo={overlayInfo}
                            overlayStates={overlayStates}
                            plotDutyCycle={plotDutyCycle}
                            dutyCycleList={dutyCycleList}
                            dutyCycleValuesOverThreshHold={dutyCycleValuesOverThreshHold}
                            showAbnormalSeriesValues={showAbnormalSeriesValues}
                            abnormalPaValues={props.abnormalValuesObj["pa_list"]}
                            abnormalIgValues={props.abnormalValuesObj["ig_list"]}
                            abnormalDutyCycleValues={props.abnormalValuesObj["duty_cycle_list"]}
                            zoomParams={props.zoomParams}
                            setZoomParams={props.setZoomParams}
                            overlayedCycles={props.overlayedCycles}
                            cycleStartTime={props.cycleStartTime}
                        />
                    </Box>
                )}
                {props.cycleType === "GrindCycle" && showCurrentGraph && (
                    <Box position="relative">
                        {chartLoading && <SimpleBackdrop loaderText="Preparing Chart..." />}
                        <CurrentChart
                            timeList={timeList}
                            iList={currentList}
                            plotBands={plotBands}
                            overlayInfo={overlayInfo}
                            infoConstants={infoConstants}
                            combineTPGraphs={props.cycleType === "GrindCycle" ? false : combineTPGraphs}
                            showAbnormalSeriesValues={showAbnormalSeriesValues}
                            abnormalIValues={
                                props.cycleType === "GrindCycle"
                                    ? props.abnormalValuesObj["ig_list"]
                                    : props.abnormalValuesObj["i_list"]
                            }
                            customTitlePrefix={props.customTitlePrefix}
                            zoomParams={props.zoomParams}
                            setZoomParams={props.setZoomParams}
                            overlayedCycles={props.overlayedCycles}
                            cycleStartTime={props.cycleStartTime}
                            isTPACurrentGraph={props.cycleType === "SteamCycle"}
                            cycleType={props.cycleType}
                        />
                    </Box>
                )}
                {props.cycleType === "SteamCycle" && props.showSteamCycleTrends && (
                    <SteamCycleTrendsChart
                        cycleStartTimes={props.steamCycleStartTimes}
                        trendCycleScore={props.trendCycleScore}
                        trendCycleDuration={props.trendCycleDuration}
                        trendTvAvgHold={props.trendTvAvgHold}
                        trendTPDiff={props.trendTPDiff}
                        trendFlowSeconds={props.trendFlowSeconds}
                        trendPumpdownDuration={props.trendPumpdownDuration}
                        trendDrainingTime={props.trendDrainingTime}
                        trendHeatUpTime={props.trendHeatUpTime}
                        trendPumpdownTimeouts={props.trendPumpdownTimeouts}
                        trendTVStart={props.trendTVStart}
                        trendTVMax={props.trendTVMax}
                        trendTRAverage={props.trendTRAverage}
                        trendPVStart={props.trendPVStart}
                        trendPVMin={props.trendPVMin}
                        trendPVMax={props.trendPVMax}
                        trendPVAverageDuringHold={props.trendPVAverageDuringHold}
                        trendHeatUpRateDifference={props.trendHeatUpRateDifference}
                        hideAxisLabels={props.hideAxisLabels}
                        showAbnormalTrendValues={props.showAbnormalTrendValues}
                        abnormalScoreValues={props.abnormalValuesObj["score"]}
                        abnormalFlowSecondsValues={props.abnormalValuesObj["flow_seconds"]}
                        abnormalSteamPumpdownValues={props.abnormalValuesObj["pumpdown_duration_minutes"]}
                        abnormalDrainTimeValues={props.abnormalValuesObj["draining_duration_minutes"]}
                        abnormalHeatUpTimeValues={props.abnormalValuesObj["ramp_duration_minutes"]}
                        abnormalPumpdownTimeoutsValues={props.abnormalValuesObj["pumpdown_timeout_count"]}
                        abnormalTPDiffValues={props.abnormalValuesObj["tp_diff"]}
                        abnormalTvStartValues={props.abnormalValuesObj["tv_start"]}
                        abnormalTvMaxValues={props.abnormalValuesObj["tv_max"]}
                        abnormalTrAvgValues={props.abnormalValuesObj["tr_average"]}
                        abnormalTvAvgDuringHoldValues={props.abnormalValuesObj["tv_average_during_hold"]}
                        abnormalPvStartValues={props.abnormalValuesObj["pv_start"]}
                        abnormalPvMinValues={props.abnormalValuesObj["pv_min"]}
                        abnormalPvMaxValues={props.abnormalValuesObj["pv_max"]}
                        abnormalPvAvgDuringHoldValues={props.abnormalValuesObj["pv_average_during_hold"]}
                        abnormalHeatUpRateDifferenceValues={props.abnormalValuesObj["tv_rate_of_rise"]}
                    />
                )}

                {props.cycleType === "GrindCycle" && props.showGrindCycleTrends && (
                    <GrindCycleTrendsChart
                        cycleStartTimes={props.grindCycleStartTimes}
                        trendStalls={props.trendStalls}
                        trendGrindDuration={props.trendGrindDuration}
                        trendJack={props.trendJack}
                        trendNumberStalls={props.trendNumberStalls}
                        trendTries={props.trendTries}
                        trendForwardDuration={props.trendForwardDuration}
                        trendReverseDuration={props.trendReverseDuration}
                        hideAxisLabels={props.hideAxisLabels}
                        showAbnormalTrendValues={props.showAbnormalTrendValues}
                        abnormalGrindDurationValues={props.abnormalValuesObj["grind_duration_minutes"]}
                        abnormalStallsValues={props.abnormalValuesObj["stalls"]}
                        abnormalJackValues={props.abnormalValuesObj["jack"]}
                        abnormalNumberStallsValues={props.abnormalValuesObj["number_stalls"]}
                        abnormalTriesValues={props.abnormalValuesObj["tries"]}
                        abnormalForwardDurationValues={props.abnormalValuesObj["forward_duration"]}
                        abnormalReverseDurationValues={props.abnormalValuesObj["reverse_duration"]}
                    />
                )}
                {props.showSharedCycleTrends && (
                    <SharedCycleTrendsChart
                        cycleStartTimes={props.cycleStartTimes}
                        trendDutyCycleAvg={props.trendDutyCycleAvg}
                        trendDutyCycleStart={props.trendDutyCycleStart}
                        trendDutyCycleEnd={props.trendDutyCycleEnd}
                        hideAxisLabels={props.hideAxisLabels}
                        // temporarily hard coded false until we want to trend abnormal duty cycle values
                        showAbnormalTrendValues={false}
                        abnormalDutyCycleAvgValues={props.abnormalValuesObj["duty_cycle_average"]}
                        abnormalDutyCycleStartValues={props.abnormalValuesObj["duty_cycle_start"]}
                        abnormalDutyCycleEndValues={props.abnormalValuesObj["duty_cycle_end"]}
                    />
                )}
            </Card>
        </Box>
    );
};

CycleMetricChartContainer.defaultProps = {
    cycleStartTimes: [],
    cycleType: "",
    facilityType: "RMW",
    infoCount: 0,
    trendCycleOutcome: false,
    trendCycleScore: false,
    trendCycleDuration: false,
    trendTvAvgHold: false,
    trendTPDiff: false,
    trendFlowSeconds: false,
    trendPumpdownDuration: false,
    trendDrainingTime: false,
    trendHeatUpTime: false,
    trendPumpdownTimeouts: false,
    trendTVStart: false,
    trendTVMax: false,
    trendTRAverage: false,
    trendPVStart: false,
    trendPVMin: false,
    trendPVMax: false,
    trendPVAverageDuringHold: false,
    showGrindCycleTrends: false,
    combineGrindCycleTrends: false,
    showSteamCycleTrends: false,
    combineSteamCycleTrends: false,
    showSharedCycleTrends: false,
    hideAxisLabels: false,
    trendGrindDuration: false,
    trendStalls: true,
    trendJack: true,
    trendNumberStalls: true,
    trendTries: true,
    trendForwardDuration: false,
    trendReverseDuration: false,
    showAbnormalTrendValues: false,
    trendDutyCycleAvg: false,
    trendDutyCycleStart: false,
    trendDutyCycleEnd: false,
    abnormalValuesObj: {},
    overlayedCycles: [],
};

CycleMetricChartContainer.propTypes = {
    selectedCycle: PropTypes.object.isRequired,
    cycleType: PropTypes.string,
    facilityType: PropTypes.string,
    infoCount: PropTypes.number,
    trendCycleOutcome: PropTypes.bool,
    trendCycleScore: PropTypes.bool,
    trendCycleDuration: PropTypes.bool,
    trendTvAvgHold: PropTypes.bool,
    trendTPDiff: PropTypes.bool,
    trendFlowSeconds: PropTypes.bool,
    trendPumpdownDuration: PropTypes.bool,
    trendDrainingTime: PropTypes.bool,
    trendHeatUpTime: PropTypes.bool,
    trendPumpdownTimeouts: PropTypes.bool,
    trendTVStart: PropTypes.bool,
    trendTVMax: PropTypes.bool,
    trendTRAverage: PropTypes.bool,
    trendPVStart: PropTypes.bool,
    trendPVMin: PropTypes.bool,
    trendPVMax: PropTypes.bool,
    trendPVAverageDuringHold: PropTypes.bool,
    showGrindCycleTrends: PropTypes.bool,
    combineGrindCycleTrends: PropTypes.bool,
    showSteamCycleTrends: PropTypes.bool,
    combineSteamCycleTrends: PropTypes.bool,
    showSharedCycleTrends: PropTypes.bool,
    hideAxisLabels: PropTypes.bool,
    trendGrindDuration: PropTypes.bool,
    trendStalls: PropTypes.bool,
    trendJack: PropTypes.bool,
    trendNumberStalls: PropTypes.bool,
    trendTries: PropTypes.bool,
    trendForwardDuration: PropTypes.bool,
    trendReverseDuration: PropTypes.bool,
    showAbnormalTrendValues: PropTypes.bool,
    trendDutyCycleAvg: PropTypes.bool,
    trendDutyCycleStart: PropTypes.bool,
    trendDutyCycleEnd: PropTypes.bool,
    abnormalValuesObj: PropTypes.object,
    scrollOnCycleClick: PropTypes.bool.isRequired,
    setScrollOnCycleClick: PropTypes.func.isRequired,
    overlayedCycles: PropTypes.array,
};

export default CycleMetricChartContainer;
