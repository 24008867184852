import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import SubCard from "src/components/material/Cards/SubCard";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CardContent,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
    useTheme,
} from "@mui/material";
import {
    AddCircleTwoTone,
    AirTwoTone,
    CancelTwoTone,
    CheckCircleTwoTone,
    CheckTwoTone,
    CropSquareTwoTone,
    DeleteTwoTone,
    DeveloperBoardTwoTone,
    EditTwoTone,
    LocalDrinkTwoTone,
    MiscellaneousServicesTwoTone,
    SaveTwoTone,
    SensorsTwoTone,
    SettingsTwoTone,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDeviceConfigOptions,
    patchDeviceConfig,
    resetUpdatedConfig,
    resetWasteTypeConfig,
    saveWasteTypeConfig,
    setInactiveWasteTypes,
    setNewWasteType,
    setSelectedWasteType,
    setUpdatedConfig,
} from "../deviceInfoSlice";
import { AuthReq, capitalizeFirstLetter } from "src/components/library/helpers";
import { useTranslation } from "react-i18next";
import EditWasteTypeModal from "./EditWasteTypeModal";
import { toast } from "react-toastify";
import { useSnackbar } from "notistack";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: "bold",
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.primary.light,
    },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const MAX_WASTE_TYPES = 6;

const DeviceConfiguration = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation("translations");
    const { enqueueSnackbar } = useSnackbar();
    const loading = useSelector((store) => store.deviceInfo.loading.deviceConfig);
    const pendingConfigUpdate = useSelector((store) => store.deviceInfo.loading.configUpdate);

    const {
        selectedDeviceConfig,
        deviceConfigOptions,
        updatedConfig,
        selectedWasteType,
        activeWasteCount,
        pvSensorDropdown,
    } = useSelector((store) => store.deviceInfo);
    const { user, group } = useSelector((store) => store.user);
    const [editMode, setEditMode] = useState(false);
    const [editConfigDialogOpen, setEditConfigDialogOpen] = useState(false);
    const [editWasteDialogOpen, setEditWasteDialogOpen] = useState(false);
    const [saveStatus, setSaveStatus] = useState(null);
    const [wasteSaveStatus, setWasteSaveStatus] = useState(null);
    const [comments, setComments] = useState();

    const notifyMaxWasteType = () =>
        enqueueSnackbar(
            `A maximum of ${MAX_WASTE_TYPES} waste types can be enabled. Please remove one to add a new waste type.`,
            { variant: "error" }
        );

    const doesWasteTypeBelongToFacilityType = (item, facilityType) => {
        if (item in deviceConfigOptions) {
            return deviceConfigOptions[item]["requires_facility_type"] === facilityType;
        } else {
            return false;
        }
    };

    const getConfigDisplay = (type) => {
        const facilityType = selectedDeviceConfig?.facility?.facility_type;
        const inactiveWasteTypes = [];

        const configList = (
            <List>
                {loading
                    ? deviceConfigOptions &&
                      Object.keys(deviceConfigOptions)
                          .filter((item) => deviceConfigOptions[item].category === type && item !== "defaults")
                          .map((item) => {
                              return (
                                  <ListItem key={item}>
                                      <ListItemText>
                                          <Skeleton />
                                      </ListItemText>
                                  </ListItem>
                              );
                          })
                    : selectedDeviceConfig &&
                      Object.keys(selectedDeviceConfig)
                          .filter((item) => {
                              return (
                                  item in deviceConfigOptions &&
                                  deviceConfigOptions[item].category === type &&
                                  item !== "defaults"
                              );
                          })
                          .map((item) => {
                              const affectsSoftware = deviceConfigOptions[item].affects_software;
                              const configLabel = deviceConfigOptions[item].label;

                              const displayValue = () => {
                                  if (deviceConfigOptions[item].type === "choice") {
                                      const value = deviceConfigOptions[item].choices.find(
                                          (choice) => choice.value === selectedDeviceConfig[item]
                                      );

                                      return value
                                          ? value.display_name
                                          : "Something went wrong displaying this configuration item's value. Please contact software.";
                                  } else {
                                      if (deviceConfigOptions[item].type === "boolean") {
                                          if (type === "waste_type" && !selectedDeviceConfig[item]) {
                                              inactiveWasteTypes.push({
                                                  key: item,
                                                  value: item,
                                                  text: deviceConfigOptions[item].label,
                                              });
                                          }
                                          return selectedDeviceConfig[item]
                                              ? capitalizeFirstLetter(selectedDeviceConfig[item].toString())
                                              : "False";
                                      } else {
                                          if (item in selectedDeviceConfig) {
                                              switch (typeof selectedDeviceConfig[item]) {
                                                  case "number":
                                                      return selectedDeviceConfig[item];
                                                      break;
                                                  case "object" || type === "sensors":
                                                      return `${selectedDeviceConfig[item].manufacturer} - ${selectedDeviceConfig[item].model}`;
                                                  default:
                                                      capitalizeFirstLetter(selectedDeviceConfig[item]);
                                                      break;
                                              }
                                          } else {
                                              return "";
                                          }
                                      }
                                  }
                              };

                              const isWasteTypeAllowedAtFacility =
                                  type === "waste_type" ? doesWasteTypeBelongToFacilityType(item, facilityType) : true;
                              if (isWasteTypeAllowedAtFacility) {
                                  return (
                                      <ListItem key={`${type}-${item}`} secondaryAction={displayValue()}>
                                          <ListItemIcon>
                                              {affectsSoftware && (
                                                  <Tooltip title={`${configLabel} ${t("is software affecting")}`}>
                                                      <SaveTwoTone sx={{ color: theme.palette.warning.main }} />
                                                  </Tooltip>
                                              )}
                                          </ListItemIcon>
                                          <ListItemText primary={configLabel} />
                                      </ListItem>
                                  );
                              } else {
                                  return "";
                              }
                          })}
            </List>
        );
        //dispatch(setInactiveWasteTypes(inactiveWasteTypes));
        return configList;
    };

    const getEditConfigDisplay = (type) => {
        const facilityType = selectedDeviceConfig?.facility.facility_type;

        return (
            <Stack spacing={2}>
                {selectedDeviceConfig &&
                    Object.keys(selectedDeviceConfig)
                        .filter(
                            (item) =>
                                deviceConfigOptions[item]?.category === type &&
                                deviceConfigOptions[item] &&
                                item !== "defaults" &&
                                item !== "config_rev" &&
                                item !== "id" &&
                                item !== "old_id" &&
                                item !== "reset_password_key"
                        )
                        .map((item, outerIdx) => {
                            const affectsSoftware = deviceConfigOptions[item]?.affects_software;
                            if (deviceConfigOptions[item].type === "boolean") {
                                const checked = selectedDeviceConfig[item] ? "checked" : null;
                                const wasteAllowedAtFacility =
                                    type === "waste_type"
                                        ? doesWasteTypeBelongToFacilityType(item, facilityType)
                                        : true;
                                if (wasteAllowedAtFacility) {
                                    return group === "SterilisWasteTypeAdmin" && type === "waste_type" ? (
                                        checked && (
                                            <ListItem
                                                key={`${item}-${outerIdx}`}
                                                secondaryAction={
                                                    <>
                                                        <IconButton
                                                            color="info"
                                                            onClick={() => {
                                                                dispatch(setSelectedWasteType(item));
                                                                setEditWasteDialogOpen(!editWasteDialogOpen);
                                                            }}>
                                                            <EditTwoTone />
                                                        </IconButton>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => {
                                                                handleRemoveWasteType(item);
                                                            }}>
                                                            <DeleteTwoTone />
                                                        </IconButton>
                                                    </>
                                                }>
                                                <ListItemIcon>
                                                    {affectsSoftware && (
                                                        <Tooltip
                                                            title={`${deviceConfigOptions[item].label} ${t(
                                                                "is software affecting"
                                                            )}`}>
                                                            <SaveTwoTone sx={{ color: theme.palette.warning.main }} />
                                                        </Tooltip>
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText primary={deviceConfigOptions[item].label} />
                                            </ListItem>
                                        )
                                    ) : (
                                        <ListItem
                                            key={`${item}-${outerIdx}`}
                                            secondaryAction={
                                                <Checkbox
                                                    defaultChecked={checked}
                                                    onChange={(event) => handleConfigChange(item, event.target.checked)}
                                                />
                                            }>
                                            <ListItemIcon>
                                                {affectsSoftware && (
                                                    <Tooltip
                                                        title={`${deviceConfigOptions[item].label} ${t(
                                                            "is software affecting"
                                                        )}`}>
                                                        <SaveTwoTone sx={{ color: theme.palette.warning.main }} />
                                                    </Tooltip>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={deviceConfigOptions[item].label} />
                                        </ListItem>
                                    );
                                } else {
                                    return "";
                                }
                            } else if (["integer", "float", "string"].includes(deviceConfigOptions[item].type)) {
                                if (deviceConfigOptions[item].type === "string") {
                                    return (
                                        <ListItem
                                            secondaryAction={
                                                <FormControl variant="outlined">
                                                    <TextField
                                                        size="small"
                                                        onChange={(event) =>
                                                            handleConfigChange(item, event.target.value)
                                                        }
                                                        placeholder={selectedDeviceConfig[item]}
                                                    />
                                                </FormControl>
                                            }>
                                            <ListItemIcon>
                                                {affectsSoftware && (
                                                    <Tooltip
                                                        title={`${deviceConfigOptions[item].label} ${t(
                                                            "is software affecting"
                                                        )}`}>
                                                        <SaveTwoTone sx={{ color: theme.palette.warning.main }} />
                                                    </Tooltip>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={deviceConfigOptions[item].label} />
                                        </ListItem>
                                    );
                                } else {
                                    const disabled = [
                                        "pres_lim_max_count",
                                        "short_drain_secs",
                                        "stag_recovery_secs",
                                    ].includes(item);
                                    return (
                                        <ListItem
                                            secondaryAction={
                                                <FormControl variant="outlined">
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        onChange={(event) =>
                                                            handleConfigChange(item, event.target.value)
                                                        }
                                                        placeholder={selectedDeviceConfig[item]}
                                                    />
                                                </FormControl>
                                            }>
                                            <ListItemIcon>
                                                {affectsSoftware && (
                                                    <Tooltip
                                                        title={`${deviceConfigOptions[item].label} ${t(
                                                            "is software affecting"
                                                        )}`}>
                                                        <SaveTwoTone sx={{ color: theme.palette.warning.main }} />
                                                    </Tooltip>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={deviceConfigOptions[item].label} />
                                        </ListItem>
                                    );
                                }
                            } else if (deviceConfigOptions[item].type === "choice") {
                                return (
                                    <ListItem
                                        secondaryAction={
                                            <Select
                                                size="small"
                                                defaultValue={selectedDeviceConfig[item]}
                                                sx={{ minWidth: 200 }}
                                                onChange={(event) => handleConfigChange(item, event.target.value)}>
                                                {deviceConfigOptions[item].choices.map((choice, innerIdx) => {
                                                    const optKey = `${outerIdx}-${innerIdx}`;
                                                    return (
                                                        <MenuItem key={optKey} value={choice.value}>
                                                            {choice.display_name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        }>
                                        <ListItemIcon>
                                            {affectsSoftware && (
                                                <Tooltip
                                                    title={`${deviceConfigOptions[item].label} ${t(
                                                        "is software affecting"
                                                    )}`}>
                                                    <SaveTwoTone sx={{ color: theme.palette.warning.main }} />
                                                </Tooltip>
                                            )}
                                        </ListItemIcon>
                                        <ListItemText primary={deviceConfigOptions[item].label} />
                                    </ListItem>
                                );
                            } else if (
                                deviceConfigOptions[item].type === "nested object" &&
                                deviceConfigOptions[item]?.category === "sensors"
                            ) {
                                return (
                                    <ListItem
                                        secondaryAction={
                                            <Select
                                                size="small"
                                                defaultValue={selectedDeviceConfig[item]?.id}
                                                sx={{ minWidth: 200 }}
                                                onChange={(event) => handleConfigChange(item, event.target.value)}>
                                                {pvSensorDropdown.map((sensor) => {
                                                    return (
                                                        <MenuItem key={sensor.key} value={sensor.value}>
                                                            {sensor.text}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        }>
                                        <ListItemIcon>
                                            {affectsSoftware && (
                                                <Tooltip
                                                    title={`${deviceConfigOptions[item].label} ${t(
                                                        "is software affecting"
                                                    )}`}>
                                                    <SaveTwoTone sx={{ color: theme.palette.warning.main }} />
                                                </Tooltip>
                                            )}
                                        </ListItemIcon>
                                        <ListItemText primary={deviceConfigOptions[item].label} />
                                    </ListItem>
                                );
                            }
                        })}
            </Stack>
        );
    };

    const handleConfigChange = (field, value) => {
        dispatch(setUpdatedConfig({ field: field, value: value }));
        if (field === "drain_path") {
            switch (value) {
                case "big_lower_only":
                    dispatch(setUpdatedConfig({ field: "short_drain_secs", value: 0 }));
                    dispatch(setUpdatedConfig({ field: "stag_recovery_secs", value: 20 }));
                    dispatch(setUpdatedConfig({ field: "pres_lim_max_count", value: 5 }));
                    break;
                case "small":
                    dispatch(setUpdatedConfig({ field: "short_drain_secs", value: 40 }));
                    dispatch(setUpdatedConfig({ field: "stag_recovery_secs", value: 20 }));
                    dispatch(setUpdatedConfig({ field: "pres_lim_max_count", value: 5 }));
                    break;
                case "big_drain":
                    dispatch(setUpdatedConfig({ field: "short_drain_secs", value: 0 }));
                    dispatch(setUpdatedConfig({ field: "stag_recovery_secs", value: 7 }));
                    dispatch(setUpdatedConfig({ field: "pres_lim_max_count", value: 10 }));
                    break;
                default:
                    break;
            }
        }
    };

    const handleRemoveWasteType = (wasteType) => {
        dispatch(setSelectedWasteType(wasteType));
        if (wasteType && selectedDeviceConfig) {
            dispatch(setUpdatedConfig({ field: wasteType, value: false }));
            setEditConfigDialogOpen(true);
        }
    };

    const submitConfigChanges = () => {
        if (comments) {
            dispatch(
                // @ts-ignore
                patchDeviceConfig({
                    configId: selectedDeviceConfig?.id,
                    body: { ...updatedConfig, change_desc: comments },
                })
            ).then((resp) => {
                setSaveStatus(resp.meta.requestStatus);
                setEditConfigDialogOpen(false);
                setEditWasteDialogOpen(false);
                setEditMode(false);
                setComments(null);
                dispatch(resetUpdatedConfig());
                dispatch(resetWasteTypeConfig());
            });
        } else {
            dispatch(patchDeviceConfig({ configId: selectedDeviceConfig?.id, body: updatedConfig })).then((resp) => {
                setSaveStatus(resp.meta.requestStatus);
                setEditConfigDialogOpen(false);
                setEditWasteDialogOpen(false);
                setEditMode(false);
                setComments(null);
                dispatch(resetUpdatedConfig());
                dispatch(resetWasteTypeConfig());
            });
        }
    };

    const successNotification = () =>
        enqueueSnackbar(t("Configuration Changes Saved Successfully."), { variant: "success" });

    const failureNotification = () =>
        enqueueSnackbar(t("Failed To Save Configuration Changes. Please Refresh And Try Again."), { variant: "error" });

    const successWasteNotification = () =>
        enqueueSnackbar(t("Waste Configuration Changes Saved Successfully."), { variant: "success" });

    const failureWasteNotification = () =>
        enqueueSnackbar(t(`Failed To Save Waste Configuration Changes. Please Refresh And Try Again.`), {
            variant: "error",
        });

    const cancelConfigChanges = () => {
        setEditConfigDialogOpen(false);
        setEditMode(false);
        dispatch(resetUpdatedConfig());
    };

    const handleAddWasteType = () => {
        if (activeWasteCount < MAX_WASTE_TYPES) {
            dispatch(setNewWasteType(true));
            setEditWasteDialogOpen(!editWasteDialogOpen);
        } else {
            notifyMaxWasteType();
        }
    };

    useEffect(() => {
        if (saveStatus === "fulfilled") {
            successNotification();
            setSaveStatus(null);
        } else if (saveStatus === "rejected") {
            failureNotification();
            setSaveStatus(null);
        }

        if (wasteSaveStatus === "fulfilled") {
            successWasteNotification();
            setWasteSaveStatus(null);
        } else if (wasteSaveStatus === "rejected") {
            failureWasteNotification();
            setWasteSaveStatus(null);
        }
    }, [saveStatus, wasteSaveStatus]);

    return editMode ? (
        <>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Stack spacing={2} direction={"row"}>
                        <Tooltip title={t("Accept Changes")}>
                            <IconButton color="success" size="large" onClick={() => setEditConfigDialogOpen(true)}>
                                <CheckCircleTwoTone fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("Cancel Changes")}>
                            <IconButton color="error" size="large" onClick={() => cancelConfigChanges()}>
                                <CancelTwoTone fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Stack spacing={2}>
                        <SubCard
                            title={"Air"}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <AirTwoTone />
                                </Avatar>
                            }>
                            <CardContent>{getEditConfigDisplay("air")}</CardContent>
                        </SubCard>
                        <SubCard
                            title={"Fluids"}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <LocalDrinkTwoTone />
                                </Avatar>
                            }>
                            <CardContent>{getEditConfigDisplay("fluids")}</CardContent>
                        </SubCard>
                        <SubCard
                            title={"Grinder"}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <SettingsTwoTone />
                                </Avatar>
                            }>
                            <CardContent>{getEditConfigDisplay("grinder")}</CardContent>
                        </SubCard>
                        <SubCard
                            title={"Miscellaneous"}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <MiscellaneousServicesTwoTone />
                                </Avatar>
                            }>
                            <CardContent>{getEditConfigDisplay("misc")}</CardContent>
                        </SubCard>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Stack spacing={2}>
                        <SubCard
                            title={`Waste Types - ${activeWasteCount}/6`}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <DeleteTwoTone />
                                </Avatar>
                            }>
                            <CardContent>
                                {group === "SterilisWasteTypeAdmin" && (
                                    <Button
                                        startIcon={<AddCircleTwoTone />}
                                        variant="outlined"
                                        sx={{ m: 2 }}
                                        onClick={() => handleAddWasteType()}>
                                        Add Waste Type
                                    </Button>
                                )}
                                {getEditConfigDisplay("waste_type")}
                            </CardContent>
                        </SubCard>
                        <SubCard
                            title={"Machine"}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <DeveloperBoardTwoTone />
                                </Avatar>
                            }>
                            <CardContent>{getEditConfigDisplay("machine")}</CardContent>
                        </SubCard>
                        <SubCard
                            title={"Seals"}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <CropSquareTwoTone />
                                </Avatar>
                            }>
                            <CardContent>{getEditConfigDisplay("seals")}</CardContent>
                        </SubCard>
                        <SubCard
                            title={"Sensors"}
                            avatar={
                                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                    <SensorsTwoTone />
                                </Avatar>
                            }>
                            <CardContent>{getEditConfigDisplay("sensors")}</CardContent>
                        </SubCard>
                    </Stack>
                </Grid>
            </Grid>
            <StyledDialog open={editConfigDialogOpen} maxWidth={"lg"} fullWidth>
                <DialogTitle>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <SettingsTwoTone sx={{ marginRight: 1 }} />

                        {t("Confirm Configuration Changes")}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {pendingConfigUpdate ? (
                        <Stack justifyContent={"center"} alignItems={"center"} spacing={1}>
                            <CircularProgress color="primary" />
                            <Typography color={"primary"} variant="caption">
                                {t("Saving Changes")}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack spacing={2}>
                            <TableContainer sx={{ minWidth: 600, borderRadius: 4 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">{t("Configuration Item")}</StyledTableCell>
                                            <StyledTableCell align="center">{t("Old Value")}</StyledTableCell>
                                            <StyledTableCell align="center">{t("New Value")}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(updatedConfig).map((item, idx) => {
                                            if (item === "vessel_pressure_sensor_id") {
                                                return null;
                                            }
                                            return (
                                                <StyledTableRow key={idx} hover>
                                                    <StyledTableCell
                                                        sortDirection="asc"
                                                        component="th"
                                                        scope="row"
                                                        align="center">
                                                        {deviceConfigOptions[item].label}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {deviceConfigOptions[item].type === "choice" &&
                                                            deviceConfigOptions[item].choices
                                                                .filter(
                                                                    (choice) =>
                                                                        choice.value === selectedDeviceConfig[item]
                                                                )
                                                                .map((choice, innerIdx) => {
                                                                    return choice.display_name;
                                                                })}
                                                        {deviceConfigOptions[item].type === "nested object" &&
                                                        item === "vessel_pressure_sensor"
                                                            ? pvSensorDropdown.find((sensor) => {
                                                                  return sensor?.key === selectedDeviceConfig[item]?.id;
                                                              })?.text
                                                            : null}
                                                        {["integer", "boolean", "float"].includes(
                                                            deviceConfigOptions[item].type
                                                        )
                                                            ? selectedDeviceConfig[item].toString()
                                                            : null}
                                                        {deviceConfigOptions[item].type === "string"
                                                            ? selectedDeviceConfig[item]
                                                            : null}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {deviceConfigOptions[item].type === "choice" &&
                                                            deviceConfigOptions[item].choices
                                                                .filter(
                                                                    (choice) => choice.value === updatedConfig[item]
                                                                )
                                                                .map((choice, idx) => choice.display_name)}
                                                        {deviceConfigOptions[item].type === "nested object" &&
                                                        item === "vessel_pressure_sensor"
                                                            ? pvSensorDropdown.find(
                                                                  (sensor) => sensor.key === updatedConfig[item]
                                                              )?.text
                                                            : null}
                                                        {["integer", "boolean", "float"].includes(
                                                            deviceConfigOptions[item].type
                                                        )
                                                            ? updatedConfig[item].toString()
                                                            : null}
                                                        {deviceConfigOptions[item].type === "string"
                                                            ? updatedConfig[item]
                                                            : null}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TextField
                                multiline
                                label="Comments"
                                value={comments}
                                onChange={(event) => setComments(event.target.value)}
                                sx={{ width: "50%" }}
                            />
                        </Stack>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        //variant="outlined"
                        color="error"
                        startIcon={<CancelTwoTone />}
                        onClick={() => setEditConfigDialogOpen(false)}>
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="outlined"
                        color="success"
                        startIcon={<CheckTwoTone />}
                        onClick={() => submitConfigChanges()}>
                        {t("Submit")}
                    </Button>
                </DialogActions>
            </StyledDialog>

            <EditWasteTypeModal
                open={editWasteDialogOpen}
                setEditWasteDialogOpen={setEditWasteDialogOpen}
                setEditMode={setEditMode}
                setSaveStatus={setSaveStatus}
                setWasteSaveStatus={setWasteSaveStatus}
            />
        </>
    ) : (
        <Grid container spacing={2}>
            <AuthReq
                userGroup={group}
                requiredRoles={[
                    "SterilisSuperUsers",
                    "SterilisPortalUsers",
                    "FSEs",
                    "ExternalFSEs",
                    "FactoryWorkers",
                    "DistributorAdmins",
                    "DistributorFSEs",
                    "SterilisWasteTypeAdmin",
                ]}>
                <Grid xs={12}>
                    <Button color="secondary" startIcon={<EditTwoTone />} onClick={() => setEditMode(true)}>
                        {t("Edit")}
                    </Button>
                </Grid>
            </AuthReq>
            <Grid xs={12} sm={6}>
                <Stack spacing={2}>
                    <SubCard
                        title={"Air"}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <AirTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("air")}</CardContent>
                    </SubCard>
                    <SubCard
                        title={"Fluids"}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <LocalDrinkTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("fluids")}</CardContent>
                    </SubCard>
                    <SubCard
                        title={"Grinder"}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <SettingsTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("grinder")}</CardContent>
                    </SubCard>
                    <SubCard
                        title={"Miscellaneous"}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <MiscellaneousServicesTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("misc")}</CardContent>
                    </SubCard>
                </Stack>
            </Grid>

            <Grid xs={12} sm={6}>
                <Stack spacing={2}>
                    <SubCard
                        title={`Waste Types - ${activeWasteCount}/6`}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <DeleteTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("waste_type")}</CardContent>
                    </SubCard>
                    <SubCard
                        title={"Machine"}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <DeveloperBoardTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("machine")}</CardContent>
                    </SubCard>
                    <SubCard
                        title={"Seals"}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <CropSquareTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("seals")}</CardContent>
                    </SubCard>
                    <SubCard
                        title={"Sensors"}
                        avatar={
                            <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                                <SensorsTwoTone />
                            </Avatar>
                        }>
                        <CardContent>{getConfigDisplay("sensors")}</CardContent>
                    </SubCard>
                </Stack>
            </Grid>
        </Grid>
    );
};

DeviceConfiguration.propTypes = {};

export default DeviceConfiguration;
