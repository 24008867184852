/*
 *  -insert desc here-
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
 */
import React from "react";
import { Trans, withTranslation as translate } from "react-i18next";
import SimpleLoader from "../../SimpleLoader";
import { AuthReq, composeHoc } from "../../library/helpers";
import withAuth from "../../withAuth";
import AuthService from "../../AuthService";
import ServiceSummaryTable from "./ServiceSummaryTable";
import { Button, Card, Checkbox, Dropdown, Popup } from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import { Link } from "react-router-dom";
import { parse as jsonToCSV } from "json2csv";
import * as Sentry from "@sentry/browser";
import "details-polyfill";
import DistributorDropdownFilter from "../../view/distributors/DistributorDropdownFilter";

const Auth = new AuthService();
const AbortController = window.AbortController;

class ServiceSummary extends React.Component {
    state = {
        isLoading: false,
        startDate: moment().utc().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: moment().utc().format("YYYY-MM-DD"),
        // startDate: moment('2019-01-06').format("YYYY-MM-DD"),
        // endDate: moment('2019-01-12').format("YYYY-MM-DD"), // dev dates
        serviceSummaryTableArray: [],
        abortController: new AbortController(),
        distributorDropdownArray: [
            {
                key: 0,
                value: 0,
                text: this.props.t("All Distributors"),
            },
        ],
        distributorFilter: 0,
        customerDevicesOnly: false,
        filterSelected: [1],
        queryString: "",
    };

    handleChange = (e, { value }) => {
        let difference = [];
        if (this.state.filterSelected.length > value.length) {
            // an item has been removed
            difference = this.state.filterSelected.filter((x) => !value.includes(x));
        } else if (this.state.filterSelected.length < value.length) {
            difference = this.state.filterSelected.filter((x) => value.includes(x));
        }

        if (value.includes(0) && !difference.includes(0)) {
            this.setState(
                {
                    filterSelected: [0],
                },
                () => {
                    this.submitServiceSummary("filter=all&");
                }
            );
        } else {
            // In this else, since the user has chosen something that is not 'ALl Devices',
            // remove the 'All devices' index from the value array
            const indexOfZero = value.indexOf(0);
            if (indexOfZero > -1) {
                value.splice(indexOfZero, 1);
            }
            this.setState({ filterSelected: value }, () => {
                const { filterSelected } = this.state;
                const queryString = filterSelected.reduce((str, cur) => {
                    switch (cur) {
                        case 0:
                            return (str += "filter=all&");
                        case 1:
                            return (str += "filter=customer&");
                        case 2:
                            return (str += "filter=factory&");
                        case 3:
                            return (str += "filter=unassigned&");
                        case 4:
                            return (str += "filter=unactivated&");
                        case 5:
                            return (str += "filter=retired&");
                        case 6:
                            return (str += "filter=banned&");
                        default:
                            return (str += "");
                    }
                }, "");
                this.setState(
                    {
                        queryString,
                    },
                    () => this.submitServiceSummary(queryString)
                );
            });
        }
    };

    componentDidMount() {
        document.title = `Service Summary | ${this.state.startDate} - ${this.state.endDate}`;
        this.submitServiceSummary(this.state.queryString);
    }

    componentWillUnmount() {
        this.state.abortController.abort();
    }

    fromDateClick = (timestamp) => {
        this.setState({
            startDate: moment.utc(timestamp).format("YYYY-MM-DD"),
        });
    };

    toDateClick = (timestamp) => {
        this.setState({
            endDate: moment.utc(timestamp).format("YYYY-MM-DD"),
        });
    };

    submitServiceSummary = (queryString) => {
        this.setState({ isLoading: true });
        const { startDate, endDate } = this.state;
        Auth.fetch(
            `/api/export/service-summary?` +
                queryString +
                new URLSearchParams({
                    start_date: moment.utc(startDate).clone().format("YYYY-MM-DD"),
                    end_date: moment.utc(endDate).clone().add(1, "days").format("YYYY-MM-DD"),
                }),
            {
                method: "GET",
                signal: this.state.abortController.signal,
            }
        )
            .then((data) => {
                // take shallow copy of array in state to begin building distributor dropdown array
                const distributorDropdownArray = [...this.state.distributorDropdownArray];

                const daysElapsed = this.enumerateDaysBetweenDates();

                const dayColumns = daysElapsed.map((day) => {
                    return {
                        Header: moment.utc(day).format("YYYY-MM-DD"),
                        accessor: String(moment.utc(day).valueOf()),
                    };
                });

                const prettyStart = moment(startDate).format("MMM Do YYYY");
                const prettyEnd = moment(endDate).format("MMM Do YYYY");
                const cyclesByDayColumns = [
                    {
                        Header: `Cycles by day ${prettyStart} - ${prettyEnd}`,
                        headerClassName: `cycles-by-day-header ${daysElapsed.length < 14 ? "center-text" : ""}`,
                        columns: dayColumns,
                    },
                ];

                const serviceSummaryTableArray = data.map((device) => {
                    // When a device hasn't had an error yet, we need to safely get the last_error attribute
                    let lastErrorTime = "";
                    let lastErrorJsx = "";
                    let lastErrorTxt = "";
                    if (device["last_error"]) {
                        lastErrorTime = moment.utc(device["last_error"]["error_time"]).format("YYYY-MM-DD HH:mm:ss z");
                        lastErrorJsx = this.lastError(device["last_error"]);
                        lastErrorTxt = this.lastErrorTxt(device["last_error"]);
                    }

                    // const cyclesPerDayTableArray = daysElapsed.map(day => {

                    // end goal is an array of days dicts where it looks like
                    // [ { epoch1: 0, epoch2: 5, epoch3: 7.. }]
                    // each key is an epoch date in the date range, each value is a cycle_count
                    const oneRowOnlyDayDict = {};
                    daysElapsed.forEach((day) => {
                        // Set the value to 0. If it's present, it'll get overwritten to the real cycle_count value
                        oneRowOnlyDayDict[moment.utc(day).valueOf()] = 0;
                        device["runs_per_day"].forEach((run) => {
                            if (moment.utc(run["day"]).isSame(moment.utc(day))) {
                                oneRowOnlyDayDict[moment.utc(run["day"]).valueOf()] = run["cycle_count"];
                            }
                        });
                    });

                    const oneRowDayTableArray = [];
                    oneRowDayTableArray.push(oneRowOnlyDayDict);

                    // Create an array of unique distributors
                    if (
                        distributorDropdownArray.some((elem) => {
                            return device.distributor_id === elem["value"];
                        }) === false
                    ) {
                        distributorDropdownArray.push({
                            key: device.distributor_id,
                            value: device.distributor_id,
                            text: device.distributor_name,
                        });
                    }

                    return {
                        serial_number: (
                            <Link target="_blank" to={`/find-a-machine/${device["device_config_id"]}`}>
                                {device["serial_number"]}
                            </Link>
                        ),
                        serial_number_txt: device["serial_number"],
                        customer: this.deviceCustomerFacility(device),
                        customer_name_txt: device["customer_name"],
                        facility_name_txt: device["facility_name"],
                        firmware_version: device["firmware_version"],
                        error_count: device["error_count"],
                        distributor_id: device["distributor_id"],
                        customer_id: device["customer_id"],
                        last_error_time: lastErrorTime,
                        last_error: lastErrorJsx,
                        last_error_txt: lastErrorTxt,
                        last_communication: moment.utc(device["last_communication"]).format("YYYY-MM-DD HH:mm:ss z"),
                        successful_cycle_count: (
                            <Link target="_blank" to={`/sa/dashboard/${device["device_config_id"]}`}>
                                {device["successful_cycle_count"]}
                            </Link>
                        ),
                        successful_cycle_count_txt: device["successful_cycle_count"],
                        subRows: oneRowDayTableArray,
                        is_device_in_error: device["is_device_in_error"] ? "Yes" : "",
                    };
                });

                this.setState({
                    isLoading: false,
                    serviceSummaryTableArray,
                    cyclesByDayColumns,
                    distributorDropdownArray,
                });
            })
            .catch((err) => {
                if (err.code !== 20) {
                    Sentry.captureException(err);
                    this.setState({
                        isLoading: false,
                    });
                }
            });
    };

    deviceCustomerFacility = (device) => {
        // If they're the same, like Hilo, just return the facility name to make things cleaner
        if (device["facility_name"] === device["customer_name"]) {
            return device["facility_name"];
        } else {
            return (
                <div>
                    {device["customer_name"]}
                    <br />
                    {device["facility_name"]}
                </div>
            );
        }
    };

    lastError = (error) => {
        if (error["sw_error_msg"]) {
            return (
                <React.Fragment>
                    {error["sw_error_msg"]}
                    {error["firmware_version"]}
                </React.Fragment>
            );
        } else {
            return (
                <details>
                    <summary>{error["error_code"]}</summary>
                    {error["error_description"]}
                    <br />
                    {error["firmware_version"]}
                </details>
            );
        }
    };

    lastErrorTxt = (error) => {
        if (error["sw_error_msg"]) {
            return `${error["sw_error_msg"]} | ${error["firmware_version"]}`;
        } else {
            return `${error["error_code"]} - ${error["error_description"]} | ${error["firmware_version"]}`;
        }
    };

    enumerateDaysBetweenDates = () => {
        const dates = [];

        const { startDate, endDate } = this.state;

        //Algorithm isn't inclusive. I'll just modify the inputs instead of modifying the algorithm
        // https://stackoverflow.com/questions/23795522/how-to-enumerate-dates-between-two-dates-in-moment
        const currDate = moment.utc(startDate).subtract(1, "day");
        const lastDate = moment.utc(endDate).add(1, "day");

        while (currDate.add(1, "days").diff(lastDate) < 0) {
            dates.push(currDate.clone().toDate());
        }
        return dates;
    };

    generateCSV = () => {
        const { serviceSummaryTableArray, startDate, endDate } = this.state;
        this.setState(
            {
                isLoading: true,
            },
            () => {
                const csvArray = serviceSummaryTableArray.map((serviceSummaryItem) => {
                    const returnObj = {
                        serial_number: serviceSummaryItem["serial_number_txt"],
                        customer_name: serviceSummaryItem["customer_name_txt"],
                        facility_name: serviceSummaryItem["facility_name_txt"],
                        firmware_version: serviceSummaryItem["firmware_version"],
                        error_count: serviceSummaryItem["error_count"],
                        last_error_time: serviceSummaryItem["last_error_time"],
                        last_error: serviceSummaryItem["last_error_txt"],
                        last_communication: serviceSummaryItem["last_communication"],
                        successful_cycle_count: serviceSummaryItem["successful_cycle_count_txt"],
                        is_device_in_error: serviceSummaryItem["is_device_in_error"],
                    };
                    const cycleByDayDict = serviceSummaryItem["subRows"][0];
                    Object.keys(cycleByDayDict).forEach((cycleCountByDay) => {
                        const prettyDate = moment.unix(cycleCountByDay / 1000).format("YYYY-MM-DD");
                        returnObj[prettyDate] = cycleByDayDict[cycleCountByDay];
                    });
                    return returnObj;
                });

                const csv = jsonToCSV(csvArray);
                const blob = new Blob([csv], { type: "text/csv" });

                const link = document.createElement("a");

                const filePath = window.URL.createObjectURL(blob);
                link.href = filePath;
                link.download = `service_summary-${moment(startDate).format("YYYY-MM-DD")}-${moment(endDate).format(
                    "YYYY-MM-DD"
                )}.csv`;
                // Appending to the actual dom is necessary to get the files to download on Firefox
                document.getElementById("downloadDiv").appendChild(link);
                link.click();
                this.setState({
                    isLoading: false,
                });
            }
        );
    };

    handleDistributorFilterChange = (event, data) => {
        this.setState({
            distributorFilter: data.value,
        });
    };

    filterCustomerDevices = () => {
        this.setState((prevState) => {
            return { customerDevicesOnly: !prevState.customerDevicesOnly };
        });
    };

    render() {
        const { isLoading, serviceSummaryTableArray } = this.state;
        const { t, group } = this.props;
        const deviceFilterDropdown = [
            {
                key: 0,
                value: 0,
                id: "0",
                text: t("All Devices"),
            },
            {
                key: 1,
                value: 1,
                id: "1",
                text: t("Customer Devices"),
            },
            {
                key: 2,
                value: 2,
                id: "2",
                text: t("Factory Devices"),
            },
            {
                key: 3,
                value: 3,
                id: "3",
                text: t("Unassigned Devices"),
            },
            {
                key: 4,
                value: 4,
                id: "4",
                text: t("Unactivated Devices"),
            },
            {
                key: 5,
                value: 5,
                id: "5",
                text: t("Archived Devices"),
            },
            {
                key: 6,
                value: 6,
                id: "6",
                text: t("Suspended Devices"),
            },
        ];

        const prettyStartDate = moment(this.state.startDate).format("MMM Do YYYY");
        const prettyEndDate = moment(this.state.endDate).format("MMM Do YYYY");
        return (
            <Card fluid>
                {isLoading ? <SimpleLoader /> : null}
                <Card.Header style={{ margin: "10px" }}>
                    <h2>
                        <Trans ns="translations" i18nKey="service-summary.header">
                            Service Summary | {{ prettyStartDate }} - {{ prettyEndDate }}
                        </Trans>
                    </h2>
                </Card.Header>
                <Card.Content className="card-body">
                    <div className="split-container even-split slight-margin-bottom">
                        <div className="wide-desc">
                            <Popup
                                content={
                                    <div style={{ width: "350px" }}>
                                        <div className="small-width minor-padding" style={{ background: "#0dbc0d94" }}>
                                            {t(
                                                "service-summary.legend.1",
                                                "Device has communicated with the portal within the last 5 minutes."
                                            )}
                                        </div>
                                        <div className="small-width minor-padding" style={{ background: "#f7f5c1" }}>
                                            {t(
                                                "service-summary.legend.2",
                                                "Device has communicated with the portal within the last 24 hours."
                                            )}
                                        </div>
                                        <div
                                            className="small-width minor-padding"
                                            style={{ background: "rgba(255, 33, 60, 0.53)" }}>
                                            {t(
                                                "service-summary.legend.3",
                                                "Device hasn't communicated with the portal for over 24 hours."
                                            )}
                                        </div>
                                    </div>
                                }
                                on="click"
                                position="bottom left"
                                trigger={
                                    <Button
                                        className="grey-btn important-medium-margin-bottom"
                                        content={t("Show Color Legend")}
                                    />
                                }
                            />
                            <p>
                                <Trans ns="translations" i18nKey="service-summary.1">
                                    All times on this page are <strong>UTC (GMT+0)</strong>
                                </Trans>
                            </p>
                            <p>
                                <Trans ns="translations" i18nKey="service-summary.2">
                                    The <strong>Date of last error</strong> & <strong>Information on last error</strong>{" "}
                                    columns are agnostic of the Start/End date range.
                                </Trans>
                            </p>
                            <p>
                                {t(
                                    "service-summary.3",
                                    "Click on any device's row to see the amount of cycles it ran per day during the time period."
                                )}
                            </p>
                            {serviceSummaryTableArray.length > 0 ? (
                                <Button className="grey-btn" onClick={this.generateCSV}>
                                    {t("CSV")}
                                </Button>
                            ) : null}
                        </div>
                        <div className="wide-no-flex-items">
                            <AuthReq
                                userGroup={group}
                                requiredRoles={[
                                    "SterilisSuperUsers",
                                    "SterilisPortalUsers",
                                    "FSEs",
                                    "ExternalFSEs",
                                    "FactoryWorkers",
                                    "SterilisWasteTypeAdmin",
                                ]}>
                                <div className="float-right slight-margin-bottom">
                                    <div className="form-group">
                                        <label>
                                            <h4 className="orange-text device-filter-header">{t("Device Filter")}</h4>
                                        </label>
                                        <Dropdown
                                            className="device-filter-dropdown"
                                            search
                                            selection
                                            onChange={this.handleChange}
                                            fluid
                                            multiple
                                            value={this.state.filterSelected}
                                            id="filterSelectionDropdown"
                                            options={deviceFilterDropdown}
                                        />
                                    </div>
                                    <DistributorDropdownFilter
                                        group={group}
                                        onChange={this.handleDistributorFilterChange}
                                        selectedDistributor={this.state.distributorFilter}
                                        distributorDropdownArray={this.state.distributorDropdownArray}
                                    />
                                </div>
                            </AuthReq>
                            <div className="date-group error-code-dates float-right">
                                <div className="nav-group from-date-div">
                                    <label className="date-label">{t("Start date")}</label>
                                    <DayPickerInput
                                        onDayChange={(day) => this.fromDateClick(day)}
                                        value={this.state.startDate}
                                    />
                                </div>
                                <div className="nav-group">
                                    <label className="date-label">{t("End date")}</label>
                                    <DayPickerInput
                                        onDayChange={(day) => this.toDateClick(day)}
                                        value={this.state.endDate}
                                    />
                                </div>
                            </div>
                            <Button
                                className="ster-btn float-right"
                                value="Submit"
                                type="submit"
                                onClick={() => this.submitServiceSummary(this.state.queryString)}>
                                {t("Generate Service Summary")}
                            </Button>
                        </div>
                    </div>
                    {this.state.serviceSummaryTableArray.length > 0 ? (
                        <ServiceSummaryTable
                            cyclesByDayColumns={this.state.cyclesByDayColumns}
                            serviceSummaryTableArray={this.state.serviceSummaryTableArray}
                            distributorFilter={this.state.distributorFilter}
                            customerDevicesOnly={this.state.customerDevicesOnly}
                        />
                    ) : null}
                </Card.Content>
                <div id="downloadDiv" className="download-div" />
            </Card>
        );
    }
}

export default composeHoc(
    translate("translations"),
    withAuth([
        "SterilisSuperUsers",
        "SterilisPortalUsers",
        "FSEs",
        "ExternalFSEs",
        "FactoryWorkers",
        "DistributorAdmins",
        "DistributorReadOnly",
        "DistributorFSEs",
        "SterilisWasteTypeAdmin",
    ])
)(ServiceSummary);
