/*
*  Header for the Create Dist/Customer/Facility component
*
* Copyright (C) 2018, 2019, 2020 Sterilis Medical Inc., all rights reserved.
*/

import {Card} from "semantic-ui-react";
import React from "react";
import {useTranslation, Trans} from "react-i18next";

const CreateDCFHeader = props => {
  const header = () => {
    const {t} = useTranslation('translations');
    if (props['create'] === 'distributor') {
      return t('Distributor and Device Warehouse')
    } else if (props['create'] === 'customer') {
      return t('Customer and Facility')
    } else if (props['create'] === 'facility') {
      return t('Facility')
    } else {
      return props['create']
    }
  };

  const headerText = header();

  return <Card.Header style={{'margin': '10px'}}>
    <h2>
      <Trans ns="translations" i18nKey="create_a">
        Create a {{headerText}}
      </Trans>
    </h2>
  </Card.Header>
};

export default CreateDCFHeader;