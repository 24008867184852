import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "src/components/AuthService";

const auth = new AuthService();

const initialState = {
    loading: false,
    createModalOpen: false,
    userType: null,
    permissionGroup: null,
    userTypeDisplay: null,
    customer: null,
    distributor: null,
    pinMin: 4,
    pinMax: 7,
    failed: false,
    successText: "",
    fromFacilityTab: false,
    createAnotherUser: false,
};

const createUserSlice = createSlice({
    name: "createUser",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setUserType(state, action) {
            state.userType = action.payload;
        },
        setUserTypeDisplay(state, action) {
            state.userTypeDisplay = action.payload;
        },
        setPermissionGroup(state, action) {
            state.permissionGroup = action.payload;
        },
        setCustomer(state, action) {
            state.customer = action.payload;
        },
        setDistributor(state, action) {
            state.distributor = action.payload;
        },
        setFailed(state, action) {
            state.failed = action.payload;
        },
        setPinMin(state, action) {
            state.pinMin = action.payload;
        },
        setPinMax(state, action) {
            state.pinMax = action.payload;
        },
        setSuccessText(state, action) {
            state.successText = action.payload;
        },
        setCreateModalOpen(state, action) {
            state.createModalOpen = action.payload;
        },
        formSubmitted(state, action) {
            state.loading = true;
            state.successText = "";
            state.failed = false;
        },
        formSuccessful(state, action) {
            state.loading = false;
            state.createAnotherUser = false;
            state.successText = action.payload;
        },
        setCreateAnotherUser(state, action) {
            state.createAnotherUser = action.payload;
        },
        resetState(state, action) {
            return initialState;
        },
    },
});

export const createUser = createAsyncThunk("createUser/createUser", async (user) => {
    const response = await auth.fetch(`/api/customer/`);
    return response;
});

export const selectRequiredFieldsComplete = (state) => {
    return [state.username, state.pin, state.email, state.phoneNumber, state.firstName, state.lastName].every(
        (field) => field !== null
    );
};

export const {
    setLoading,
    setUserType,
    setUserTypeDisplay,
    setPermissionGroup,
    setCustomer,
    setDistributor,
    setFailed,
    setPinMin,
    setPinMax,
    setSuccessText,
    setCreateModalOpen,
    formSubmitted,
    formSuccessful,
    setCreateAnotherUser,
    resetState,
} = createUserSlice.actions;

export default createUserSlice.reducer;
