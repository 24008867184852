/*
*  Component for the Customer Device Operator
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Checkbox} from "semantic-ui-react";
import {isGroupInGroups, isGroupSterilis} from "./library/helpers";
import HookTable from "./library/HookTable";

class CustomerDeviceOperatorsTable extends React.Component {
  state = {
    showDeleted: false,
    tableOperators: []
  };
  handleHideDeleted = () => {
    this.setState((prevState) => {
      return {showDeleted: !prevState.showDeleted};
    });
  };

  componentDidMount() {
    this.setState({
      tableOperators: this.props.deviceOperators
    })
  }

  customRowClass = (rowInfo) => {
    if (rowInfo && rowInfo.original) {
      if (rowInfo.original.deleted_status) {
        return 'light-red-tr'
      }
    }
  };

  componentDidUpdate(prevProps) {
    // Update the shown device operators when a user clicks the Filter By Customer dropdown
    if (this.props.selectedCustomer !== prevProps.selectedCustomer) {
      const {selectedCustomer, deviceOperators} = this.props;
      // -1 is 'All Customers'
      if (selectedCustomer !== -1) {
        const filteredOperators = deviceOperators.filter(operator => {
          return operator['customer_id'] === selectedCustomer
        });
        this.setState({
          tableOperators: filteredOperators
        }, () => this.props.setDeviceOperatorCount(this.state.tableOperators.length))
      } else {
        this.setState({
          tableOperators: deviceOperators
        }, () => this.props.setDeviceOperatorCount(this.state.tableOperators.length))
      }
    }


    if (this.props.deviceOperators !== prevProps.deviceOperators) {
      const {deviceOperators, selectedCustomer} = this.props;

      // Sometimes there will be a selectedCustomer and we'll be fetching fresh operators, like after
      // CRUDing an operator
      if (selectedCustomer !== -1) {
        const filteredOperators = deviceOperators.filter(operator => {
          return operator['customer_id'] === selectedCustomer
        });
        this.setState({
          tableOperators: filteredOperators
        }, () => this.props.setDeviceOperatorCount(this.state.tableOperators.length))
      } else {
        this.setState({
          tableOperators: deviceOperators
        }, () => this.props.setDeviceOperatorCount(this.state.tableOperators.length))
      }

    }
  }

  render() {
    const {
      t,
      group
    } = this.props;
    const {
      showDeleted
    } = this.state;

    const columns = [
      // {
      //   Header: t('Status'),
      //   accessor: 'status',
      //   manualWidth: 60,
      // },
      {
        Header: t('Portal User Status'),
        accessor: 'portal_user_status',
        manualWidth: 100,
      },
      {
        Header: t('Full name'),
        accessor: 'full_name',
      },
      {
        Header: t('PIN'),
        accessor: 'pin',
        manualWidth: 100,
      },
      {
        Header: t('Email address'),
        accessor: 'email_address'
      },
      {
        Header: t('Phone number'),
        accessor: 'phone_number'
      },
      {
        Header: t('Notification settings'),
        accessor: 'notification_settings'
      },
      {
        Header: t('Device Access'),
        accessor: 'operator_role',
        manualWidth: 125,
      },
      ...isGroupInGroups(group, ['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs',
        'ExternalFSEs', 'CustomerPortalUsers', 'DistributorAdmins','SterilisWasteTypeAdmin', 'FacilityAdmin']) ? [{
        Header: t('Actions'),
        accessor: 'actions',
        manualWidth: 60,
      }] : [],
    ];

    if (isGroupSterilis(group)) {
      columns.unshift({
        Header: t('Customer'),
        accessor: 'customer_name',
      })
    }
    const floatLeftHeaderJSX = <Checkbox label={t('Show archived operators')}
                                         name='showDeletedOperators'
                                         id='showDeletedOperators'
                                         checked={showDeleted}
                                         onChange={this.handleHideDeleted}/>;
    return (
      <div>
        <HookTable
          id='customerDeviceOperatorTable'
          data={this.state.tableOperators}
          floatLeftHeaderJSX={floatLeftHeaderJSX}
          columns={columns}
          defaultSortBy={{id: 'full_name'}}
          showDeleted={showDeleted}
          customRowClass={true}
          customRowClassFunc={this.customRowClass}
        />
      </div>
    )
  }
}

export default translate('translations')(CustomerDeviceOperatorsTable);
