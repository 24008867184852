/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import GrindCycle from "./GrindCycle";
import {processPlotBand} from "./ChartContainer"
import SteamChart from "./SteamChart";
import CurrentChart from "./CurrentChart";

const BurninTestChart = props => {
  const {
    overlaySol1,
    overlaySol2,
    overlaySol3,
    overlaySol4,
    overlaySol5,
    overlaySol6,
    overlaySol7,
    overlaySol8,
    overlaySol9,
    overlaySol10,
    overlaySol11,
    overlaySol12,
    overlaySol13,
    overlaySol14,
    overlaySol15,
    overlaySol16,
    overlaySol17,
    overlaySol18,
    overlayVacPump,
    overlayAirPump,
    overlayWaterPump,
    overlayHeaters,
    overlayDeodorizerLED,
    overlayGrinderOn,
    overlayGrinderForward,
    overlayOil,
    overlayOilLevels,
  } = props.digitalOutputOverlays;
  const {
    combineTPGraphs,
    showCurrentGraph,
    showTempConstants,
    showPresConstants,
    overlayStates,
    overlayInfo,
    plotDutyCycle,
    showAbnormalSeriesValues,
  } = props.chartOptions;
  const cycleType = props.burninTestCycle.cycle_type;
  const plotBands = [
    ...(overlaySol1 ? props.burninTestCycle['digital_output_plot_bands']['sol_1_plot_band'] : []),
    ...(overlaySol2 ? props.burninTestCycle['digital_output_plot_bands']['sol_2_plot_band'] : []),
    ...(overlaySol3 ? props.burninTestCycle['digital_output_plot_bands']['sol_3_plot_band'] : []),
    ...(overlaySol4 ? props.burninTestCycle['digital_output_plot_bands']['sol_4_plot_band'] : []),
    ...(overlaySol5 ? props.burninTestCycle['digital_output_plot_bands']['sol_5_plot_band'] : []),
    ...(overlaySol6 ? props.burninTestCycle['digital_output_plot_bands']['sol_6_plot_band'] : []),
    ...(overlaySol7 ? props.burninTestCycle['digital_output_plot_bands']['sol_7_plot_band'] : []),
    ...(overlaySol8 ? props.burninTestCycle['digital_output_plot_bands']['sol_8_plot_band'] : []),
    ...(overlaySol9 ? props.burninTestCycle['digital_output_plot_bands']['sol_9_plot_band'] : []),
    ...(overlaySol10 ? props.burninTestCycle['digital_output_plot_bands']['sol_10_plot_band'] : []),
    ...(overlaySol11 ? props.burninTestCycle['digital_output_plot_bands']['sol_11_plot_band'] : []),
    ...(overlaySol12 ? props.burninTestCycle['digital_output_plot_bands']['sol_12_plot_band'] : []),
    ...(overlaySol13 ? props.burninTestCycle['digital_output_plot_bands']['sol_13_plot_band'] : []),
    ...(overlaySol14 ? props.burninTestCycle['digital_output_plot_bands']['sol_14_plot_band'] : []),
    ...(overlaySol15 ? props.burninTestCycle['digital_output_plot_bands']['sol_15_plot_band'] : []),
    ...(overlaySol16 ? props.burninTestCycle['digital_output_plot_bands']['sol_16_plot_band'] : []),
    ...(overlaySol17 ? props.burninTestCycle['digital_output_plot_bands']['sol_17_plot_band'] : []),
    ...(overlaySol18 ? props.burninTestCycle['digital_output_plot_bands']['sol_18_plot_band'] : []),
    ...(overlayVacPump ? props.burninTestCycle['digital_output_plot_bands']['vac_pump_plot_band'] : []),
    ...(overlayAirPump ? props.burninTestCycle['digital_output_plot_bands']['air_pump_plot_band'] : []),
    ...(overlayWaterPump ? props.burninTestCycle['digital_output_plot_bands']['water_pump_plot_band'] : []),
    ...(overlayHeaters ? props.burninTestCycle['digital_output_plot_bands']['heaters_plot_band'] : []),
    ...(overlayDeodorizerLED ? props.burninTestCycle['digital_output_plot_bands']['deodorizer_led_plot_band'] : []),
    ...(overlayGrinderOn ? props.burninTestCycle['digital_output_plot_bands']['grinder_on_plot_band'] : []),
    ...(overlayGrinderForward ? props.burninTestCycle['digital_output_plot_bands']['grinder_forward_plot_band'] : []),
    ...(overlayOil ? props.burninTestCycle['digital_output_plot_bands']['oil_plot_band'] : []),
    ...(overlayOilLevels ? props.burninTestCycle['digital_output_plot_bands']['oil_level_plot_band'] : []),


    ...(overlayStates ? processPlotBand(props.burninTestCycle['state_plot_bands'], props.digitalOutputOverlays, overlayStates) : []),
  ];



  const dutyCycleValuesOverThreshHold = useMemo(() => {
    return plotDutyCycle && props.burninTestCycle.duty_cycle_list.some(dutyCycleVal => dutyCycleVal >= 690);
  }, [props.burninTestCycle.duty_cycle_list, plotDutyCycle]);

  const [zoomParams, _setZoomParams] = useState(null);

  const setZoomParams = React.useCallback(newState => {
    _setZoomParams({...zoomParams, ...newState});
  }, []);

  return <div className={`flex-dir-column`}>
    {cycleType === 'SteamCycle' &&
    <SteamChart
      customTitlePrefix={props.customTitlePrefix}
      thList={props.burninTestCycle.th_list}
      tvList={props.burninTestCycle.tv_list}
      trList={props.burninTestCycle.tr_list}
      paList={props.burninTestCycle.pa_list}
      psList={props.burninTestCycle.ps_list}
      pvList={props.burninTestCycle.pv_list}
      iList={props.burninTestCycle.i_list}
      timeList={props.burninTestCycle.time_list}
      plotBands={plotBands}
      combineTPGraphs={combineTPGraphs}
      showCurrentGraph={showCurrentGraph}
      showTempConstants={showTempConstants}
      showPresConstants={showPresConstants}
      infoConstants={props.burninTestCycle.info_constants}
      overlayInfo={overlayInfo}
      overlayStates={overlayStates}
      plotDutyCycle={plotDutyCycle}
      dutyCycleList={props.burninTestCycle.duty_cycle_list}
      abnormalThValues={props.abnormalValuesObj['th_list']}
      abnormalTvValues={props.abnormalValuesObj['tv_list']}
      abnormalTrValues={props.abnormalValuesObj['tr_list']}
      abnormalPaValues={props.abnormalValuesObj['pa_list']}
      abnormalPsValues={props.abnormalValuesObj['ps_list']}
      abnormalPvValues={props.abnormalValuesObj['pv_list']}
      abnormalIValues={props.abnormalValuesObj['i_list']}
      abnormalDutyCycleValues={props.abnormalValuesObj['duty_cycle_list']}
      burninChart={true}

      zoomParams={zoomParams}
      setZoomParams={setZoomParams}

    />
    }
    {cycleType === 'GrindCycle' &&
    <GrindCycle
      plotBands={plotBands}
      customTitlePrefix={props.customTitlePrefix}
      timeList={props.burninTestCycle.time_list}
      igList={props.burninTestCycle.ig_list}
      paList={props.burninTestCycle.pa_list}
      infoConstants={props.burninTestCycle.info_constants}
      overlayInfo={overlayInfo}
      overlayStates={overlayStates}
      plotDutyCycle={plotDutyCycle}
      dutyCycleList={props.burninTestCycle.duty_cycle_list}
      dutyCycleValuesOverThreshHold={dutyCycleValuesOverThreshHold}
      showAbnormalSeriesValues={showAbnormalSeriesValues}
      abnormalPaValues={props.abnormalValuesObj['pa_list']}
      abnormalIgValues={props.abnormalValuesObj['ig_list']}
      abnormalDutyCycleValues={props.abnormalValuesObj['duty_cycle_list']}
      zoomParams={zoomParams}
      setZoomParams={setZoomParams}
    />
    }
    { showCurrentGraph &&
      <CurrentChart
        timeList={props.burninTestCycle.time_list}
        iList={cycleType === 'GrindCycle' ? props.burninTestCycle.ig_list : props.burninTestCycle.i_list}
        plotBands={plotBands}
        overlayInfo={overlayInfo}
        infoConstants={props.burninTestCycle.info_constants}
        combineTPGraphs={cycleType === 'GrindCycle' ? false : combineTPGraphs}
        showAbnormalSeriesValues={showAbnormalSeriesValues}
        abnormalIValues={cycleType === 'GrindCycle' ? props.abnormalValuesObj['ig_list'] : props.abnormalValuesObj['i_list']}
        customTitlePrefix={props.customTitlePrefix}
        zoomParams={zoomParams}
        setZoomParams={setZoomParams}
        cycleType={props.cycleType}

      />
    }
  </div>
};

BurninTestChart.defaultProps = {
  burninTestCycle: {},
  abnormalValuesObj: {},
  customTitlePrefix: '',
  digitalOutputOverlays: {},
  chartOptions: {},
  olderTestGraph: false,

};

BurninTestChart.propTypes = {
  burninTestCycle: PropTypes.object,
  abnormalValuesObj: PropTypes.object,
  customTitlePrefix: PropTypes.string,
  digitalOutputOverlays: PropTypes.object,
  chartOptions: PropTypes.object,
  olderTestGraph: PropTypes.bool,

};

export default BurninTestChart;
