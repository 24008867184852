/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import PropTypes from 'prop-types';
import CycleStat from "./CycleStat";
import {getRoundedNumber} from "../library/helpers";

const BurninItem = props => {
  const {
    test,
    name,
    idx,
    subBurninStatInternalNames
  } = props;

  const getCycleStatLabel = (name) => {
    const {
      abnormalValuesForBurninTest,
      labelPrefix
    } = props;
    if (name in abnormalValuesForBurninTest) {
      return `${labelPrefix} ${abnormalValuesForBurninTest[name]['display_name']}`
    } else {
      return name;
    }
  };

  const getAbnormalValueDefinition = (name) => {
    const {
      abnormalValuesForBurninTest
    } = props;
    if (name in abnormalValuesForBurninTest) {
      return abnormalValuesForBurninTest[name]
    }
  };


  return <React.Fragment>
    {subBurninStatInternalNames && (subBurninStatInternalNames[name] && subBurninStatInternalNames[name].length > 0) ?
      <details className='detailed-cycle-stat-main-stat'>
        <summary>
          <CycleStat
            label={getCycleStatLabel(name)}
            value={getRoundedNumber(test, name,
              props.usePercent ? 0 : 2 // for percent based metrics, we don't want to have 2 decimal points
            )}
            abnormalValues={getAbnormalValueDefinition(name)}
            useInlineBlock={true}
            usePercent={props.usePercent}
          />
        </summary>
        <div className='detailed-cycle-stats'>
          {
            subBurninStatInternalNames[name].map((subMetric, internalIdx) => {
              // some subMetrics can be defined as objects for additional functionality
              if(typeof subMetric === "object"){
                return <React.Fragment key={`${subMetric['name']}-${internalIdx}-${idx}`}>
                  <CycleStat
                    label={getCycleStatLabel(subMetric['name'])}
                    value={subMetric.format(test[subMetric['name']])}
                    abnormalValues={getAbnormalValueDefinition(subMetric['name'])}
                  />
                </React.Fragment>
              }else {
                return <React.Fragment key={`${subMetric}-${internalIdx}-${idx}`}>
                  <CycleStat
                    label={getCycleStatLabel(subMetric)}
                    value={getRoundedNumber(test, subMetric, 2)}
                    abnormalValues={getAbnormalValueDefinition(subMetric)}
                  />
                </React.Fragment>
              }
            })
          }
        </div>
      </details> :
      <CycleStat
        label={getCycleStatLabel(name)}
        value={getRoundedNumber(test, name, 2)}
        abnormalValues={getAbnormalValueDefinition(name)}
        useInlineBlock={props.useInlineBlock}
      />
    }
  </React.Fragment>
};

BurninItem.defaultProps = {
  labelPrefix: '',
  useInlineBlock: false,
  usePercent: false,
};

BurninItem.propTypes = {
  labelPrefix: PropTypes.string,
  useInlineBlock: PropTypes.bool,
  usePercent: PropTypes.bool,
};

export default BurninItem;
