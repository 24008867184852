// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import AuthService from "src/components/AuthService";
import { sterilisCustomers } from "src/components/library/helpers";
import { alphabeticalSort } from "src/components/library/helpers";
import { isGroupSterilisOrDist } from "src/components/library/helpers";

const auth = new AuthService();

const initialState = {
    loading: {
        devices: false,
        deviceCycles: true,
        cycleSummary: true,
        cyclesByOperator: true,
        wasteProcessed: true,
        cycleReport: false,
        sterilizationInfo: true,
        biochallengeInfo: true,
        cycleEvents: true,
    },
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    toDate: DateTime.now().toISODate(),
    fromDate: DateTime.now().minus({ month: 1 }).toISODate(),
    selectedCycle: null,
    selectedDevice: null,
    cycleMetadata: null,
    cycleEvents: [],
    deviceTimezone: null,
    deviceCycles: [],
    deviceConfigId: null,
    deviceSerial: "",
    complianceReport: false,
    comprehensiveReport: false,
    isError: false,
    currentPressureUnit: "PSI(a)",
    deviceFacility: "",
    deviceFacilityAddress: "",
    facilityType: null,
    customerDevices: [],
    filteredCustomerDevices: [],
    rawCustomerDevices: [],
    biochallengeInfo: [],
    customerDevicesOnly: true,
    weightUnit: "",
    refreshInterval: 30,
    noCyclesFound: false,
};

const deviceDashboardSlice = createSlice({
    name: "devicedashboard",
    initialState,
    reducers: {
        setUserTimezone(state, action) {
            state.userTimezone = action.payload;
        },
        setToDate(state, action) {
            state.toDate = action.payload;
        },
        setFromDate(state, action) {
            state.fromDate = action.payload;
        },
        setSelectedCycle(state, action) {
            state.selectedCycle = action.payload;
        },
        setSelectedDevice(state, action) {
            state.selectedDevice = action.payload;
        },
        setCycleMetadata(state, action) {
            state.cycleMetadata = action.payload;
        },
        setCycleEvents(state, action) {
            state.cycleEvents = action.payload;
        },
        setDeviceCycles(state, action) {
            state.deviceCycles = action.payload;
        },
        setDeviceConfigId(state, action) {
            state.deviceConfigId = action.payload;
        },
        setDeviceSerial(state, action) {
            state.deviceSerial = action.payload;
        },
        setDeviceTimezone(state, action) {
            state.deviceTimezone = action.payload;
        },
        setComplianceReport(state, action) {
            state.complianceReport = action.payload;
        },
        setComprehensiveReport(state, action) {
            state.comprehensiveReport = action.payload;
        },
        setIsError(state, action) {
            state.isError = action.payload;
        },
        setCurrentPressureUnit(state, action) {
            state.currentPressureUnit = action.payload;
        },
        setDeviceFacility(state, action) {
            state.deviceFacility = action.payload;
        },
        setDeviceFacilityAddress(state, action) {
            state.deviceFacilityAddress = action.payload;
        },
        setCustomerDevices(state, action) {
            state.customerDevices = action.payload;
        },
        setFilteredCustomerDevices(state, action) {
            state.filteredCustomerDevices = action.payload;
        },
        setRawCustomerDevices(state, action) {
            state.rawCustomerDevices = action.payload;
        },
        setBiochallengeInfo(state, action) {
            state.biochallengeInfo = action.payload;
        },
        setCustomerDevicesOnly(state, action) {
            state.customerDevicesOnly = action.payload;
        },
        setWeightUnit(state, action) {
            state.weightUnit = action.payload;
        },
        setRefreshInterval(state, action) {
            state.refreshInterval = action.payload;
        },
        setNoCyclesFound(state, action) {
            state.noCyclesFound = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeviceCycles.pending, (state) => {
                state.loading.deviceCycles = true;
                state.loading.cycleEvents = true;
                state.deviceCycles = initialState.deviceCycles;
                //state.cycleEvents = initialState.cycleEvents;
                state.loading.cycleSummary = true;
                state.loading.cyclesByOperator = true;
                state.loading.wasteProcessed = true;
            })
            .addCase(fetchDeviceCycles.fulfilled, (state, action) => {
                state.deviceCycles = sortCyclesByDate(action.payload["device_cycles"]);
                state.noCyclesFound = action.payload["device_cycles"].length > 0 ? false : true;
                state.deviceFacility = action.payload["device_metadata"]["device_facility_name"];
                state.facilityType = action.payload["device_metadata"]["facility_type"];
                state.deviceFacilityAddress = action.payload["device_metadata"]["device_facility_full_address"];
                state.deviceSerial = action.payload["device_metadata"]["device_serial"];
                state.weightUnit = action.payload["device_metadata"]["weight_unit"];
                if (action.payload["cycle_metadata"]["new_from_date"]) {
                    state.fromDate = DateTime.fromISO(action.payload["cycle_metadata"]["new_from_date"]).toISODate();
                }
                state.selectedCycle = state.selectedCycle || sortCyclesByDate(action.payload["device_cycles"])[0];

                state.loading.deviceCycles = false;
                state.loading.cycleSummary = false;
                state.loading.cyclesByOperator = false;
                state.loading.wasteProcessed = false;
            })
            .addCase(fetchDeviceCycles.rejected, (state) => {
                state.isError = true;
                state.loading.deviceCycles = false;
                state.loading.cycleSummary = false;
                state.loading.cyclesByOperator = false;
                state.loading.wasteProcessed = false;
            })
            .addCase(fetchCycleEvents.pending, (state, action) => {
                state.cycleEvents = initialState.cycleEvents;
                state.loading.cycleEvents = true;
                state.loading.sterilizationInfo = true;
            })
            .addCase(fetchCycleEvents.fulfilled, (state, action) => {
                state.cycleEvents = action.payload["cycle_events"];
                state.cycleMetadata = action.payload["cycle_metadata"];
                state.loading.cycleEvents = false;
                state.loading.sterilizationInfo = false;
            })
            .addCase(fetchCycleEvents.rejected, (state, action) => {
                state.loading.cycleEvents = false;
                state.loading.sterilizationInfo = false;
                state.isError = true;
            })
            .addCase(fetchCustomerDevices.pending, (state, action) => {
                state.loading.devices = true;
            })
            .addCase(fetchCustomerDevices.fulfilled, (state, action) => {
                state.customerDevices = initialState.customerDevices;
                state.filteredCustomerDevices = initialState.filteredCustomerDevices;
                state.rawCustomerDevices = action.payload;

                action.payload.forEach((customer) => {
                    customer["facilities"].forEach((facility) => {
                        facility["device_configs"].forEach((deviceConfig) => {
                            if (deviceConfig["id"] === state.deviceConfigId) {
                                state.deviceTimezone = facility["region_setting"]["timezone"]["timezone"];
                                state.deviceSerial = deviceConfig["device"]["serial_number"];
                            }

                            state.customerDevices.push({
                                id: deviceConfig.id,
                                label: `${customer["customer_name"]} @ ${facility["facility_name"]} : ${deviceConfig["device"]["serial_number"]}`,
                                customer_id: customer.id,
                            });
                        });
                    });
                });
                state.customerDevices = state.customerDevices.sort((a, b) => alphabeticalSort(a, b, "label"));

                state.filteredCustomerDevices = state.customerDevices.filter((device) => {
                    return state.deviceConfigId === device["value"]
                        ? true
                        : !sterilisCustomers.includes(device["customer_id"]);
                });

                state.loading.devices = false;
            })
            .addCase(fetchCustomerDevices.rejected, (state, action) => {
                state.loading.devices = false;
                state.isError = true;
            })
            .addCase(fetchBiochallengeInfo.pending, (state, action) => {
                state.loading.biochallengeInfo = true;
            })
            .addCase(fetchBiochallengeInfo.fulfilled, (state, action) => {
                state.biochallengeInfo = action.payload;
                state.loading.biochallengeInfo = false;
            })
            .addCase(fetchBiochallengeInfo.rejected, (state) => {
                state.loading.biochallengeInfo = false;
                state.isError = true;
            });
    },
});

const sortCyclesByDate = (cycles) => {
    return cycles.sort((a, b) => {
        const aVal = new Date(a.time_started);
        const bVal = new Date(b.time_started);

        return aVal < bVal ? 1 : -1;
    });
};

export const selectDeviceById = (state, id) => state.customerDevices.find((device) => device.id === id);

export const isFacilityAirport = (state) => {
    return !state?.facilityType || state?.facilityType === "airport";
};

export const fetchCustomerDevices = createAsyncThunk("devicedashboard/fetchCustomerDevices", async (userGroup) => {
    const response = await auth.fetch(`/api/customer-machines`);
    return response;
});

export const fetchDeviceCycles = createAsyncThunk("devicedashboard/fetchDeviceCycles", async (arg, { getState }) => {
    const state = getState();
    const deviceCycleParams = new URLSearchParams({
        device_config_id: state.devicedashboard.deviceConfigId,
        // Add/Subtract 1 day so that the date range is inclusive
        from_date: DateTime.fromISO(state.devicedashboard.fromDate).toString(),
        to_date: DateTime.fromISO(state.devicedashboard.toDate).plus({ days: 1 }).toString(),
        compliance_report: state.devicedashboard.complianceReport,
        enable_date_adjustment: "true",
    });
    const response = await auth.fetch(`/api/get-device-cycles?${deviceCycleParams}`);
    return response;
});

export const fetchCycleEvents = createAsyncThunk(
    "devicedashboard/fetchCycleEvents",
    /** @param {string} cycleId  */
    async (cycleId, { getState }) => {
        const state = getState();
        const params = new URLSearchParams({
            device_config_id: state.devicedashboard.deviceConfigId,
            from_date: DateTime.fromISO(state.devicedashboard.fromDate).minus({ days: 1 }).toString(),
            to_date: DateTime.fromISO(state.devicedashboard.toDate).plus({ days: 1 }).toString(),
            compliance_report: state.devicedashboard.complianceReport,
        });

        if (state.devicedashboard.selectedCycle) {
            params.append("cycle_id", state.devicedashboard.selectedCycle.cycle_id);
        } else if (cycleId) {
            params.append("cycle_id", cycleId);
        }

        const response = await auth.fetch(`/api/get-cycle-events?${params}`);
        return response;
    }
);

export const fetchBiochallengeInfo = createAsyncThunk(
    "devicedashboard/fetchBiochallengeInfo",
    async (arg, { getState }) => {
        const state = getState();
        const params = new URLSearchParams({
            device_config_id: state.devicedashboard.deviceConfigId,
        });

        const response = await auth.fetch(`/api/get-biochallenge-info?${params}`);

        return response;
    }
);

export const {
    setUserTimezone,
    setToDate,
    setFromDate,
    setSelectedCycle,
    setCycleMetadata,
    setCycleEvents,
    setDeviceCycles,
    setDeviceConfigId,
    setDeviceSerial,
    setComplianceReport,
    setSelectedDevice,
    setComprehensiveReport,
    setIsError,
    setCurrentPressureUnit,
    setDeviceFacility,
    setDeviceFacilityAddress,
    setDeviceTimezone,
    setRawCustomerDevices,
    setCustomerDevices,
    setFilteredCustomerDevices,
    setBiochallengeInfo,
    setCustomerDevicesOnly,
    setWeightUnit,
    setRefreshInterval,
    setNoCyclesFound,
} = deviceDashboardSlice.actions;

export default deviceDashboardSlice.reducer;
