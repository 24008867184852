/*
*  table for the Service Summary
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {serialSortHook, sterilisCustomers, timestampSortHook} from "../../library/helpers";
import moment from "moment"
import HookTable from "../../library/HookTable";
import expandColumn from "../../library/ExpandColumn";

const ServiceSummaryTable = props => {

  const deviceCommunicationColor = (timeLastCommunicated) => {
    const now = (moment.utc().valueOf() / 1000);
    const formattedLostComm = moment.utc(timeLastCommunicated, "YYYY-MM-DD HH:mm:ss z").valueOf() / 1000;
    const timeDelta = now - formattedLostComm;


    if (timeDelta < 300) {
      return 'service-summary-green-cell'
    } else if (timeDelta > 300 && timeDelta < 86400) {
      return 'service-summary-yellow-cell'
    } else {
      return 'service-summary-red-cell'
    }
  };
  const columns = [
    expandColumn('Cycles per day', false),
    {
      Header: 'Device Serial',
      accessor: 'serial_number',
      manualWidth: 110,
      sortType: serialSortHook,
    },
    {
      Header: <div>Customer<br/>Facility</div>,
      accessor: 'customer',
      style: {'whiteSpace': 'unset'}
    },
    {
      Header: 'Firmware',
      accessor: 'firmware_version',
      manualWidth: 155,
    },
    {
      Header: <div>Date of last error<br/></div>,
      accessor: 'last_error_time',
      manualWidth: 135,
    },
    {
      Header: <div>Information on<br/>last error</div>,
      accessor: 'last_error',
      id: 'last_error',
    },
    {
      Header: <div>Date of device's<br/>last communication</div>,
      accessor: 'last_communication',
      id: 'last_communication',
      manualWidth: 135,
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss a z"),
      customCellClass: deviceCommunicationColor,
    },
    {
      Header: <div>Number of<br/>errors</div>,
      accessor: 'error_count',
      manualWidth: 100,
    },
    {
      Header: <div>Number of<br/>successful cycles</div>,
      accessor: 'successful_cycle_count',
    },
    {
      Header: <div>Currently<br/>in error?</div>,
      accessor: 'is_device_in_error',
      manualWidth: 80,
      customCellClass: (cellValue) => cellValue === 'Yes' ? 'service-summary-red-cell' : '',
    },
  ];

  const {
    serviceSummaryTableArray,
    distributorFilter,
    customerDevicesOnly
  } = props;
  let serviceSummaryData = serviceSummaryTableArray;

  if (distributorFilter) {
    serviceSummaryData = serviceSummaryData.filter(deviceSummaryItem => {
      return deviceSummaryItem['distributor_id'] === distributorFilter;
    });
  }

  if (customerDevicesOnly) {
    serviceSummaryData = serviceSummaryData.filter(deviceDropdownItem => {
      return !sterilisCustomers.includes(deviceDropdownItem['customer_id']);
    });
  }

  return (
    <HookTable
      innerTable={false}
      isLoading={props.isLoading}
      columns={columns}
      data={serviceSummaryData}
      subComponentJSX={'cyclesByDayTable'}
      expandableTableColumns={props.cyclesByDayColumns}
      expandableTable={true}
      defaultSortBy={{id: 'last_error_time', desc: true}}
    />
  )
};

export default translate('translations')(ServiceSummaryTable);
