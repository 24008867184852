/*
*  Main component for downloading requested device files
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import SimpleLoader from "./SimpleLoader";
import {composeHoc, getFileNameFromUrl, bytesToAppropriateUnit} from "./library/helpers";
import withAuth from "./withAuth";
import AuthService from "./AuthService";
import {Card} from "semantic-ui-react";
import DownloadDeviceFileTable from "./DownloadDeviceFileTable";
import SimpleRefreshTimer from "./SimpleRefreshTimer";
import saveAs from "file-saver";
import * as Sentry from "@sentry/browser";
import {Link} from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const Auth = new AuthService();

class DownloadFileRequest extends React.Component {
	state = {
		isLoading: false,
		cycleError: false,
	};
	notifyGenericFailure = (customMsg = false) =>
		toast(
			customMsg ||
				`Server error. Please refresh this page and try again. If the problem persists, please contact support.`,
			{ type: toast.TYPE.ERROR, autoClose: 5000 }
		);

	componentDidMount() {
		document.title = "Download Device Files";

		// isLoading is set to true in componentDidMount and no where else on purpose
		// This allows the loader to only appear on initial load, and not when SimpleRefreshTimer invokes preparePage
		this.setState({
			isLoading: true,
		});
		// The promise is ignored here, but we do use it in SimpleRefreshTimer
		this.preparePage();
	}

	preparePage = async () => {
		await this.fetchDeviceFiles();
	};

	fetchDeviceFiles = () => {
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const fileRequestID = this.props.match.params["file_request_id"];
		//8517 is an error
		//8531 no error
		return Auth.fetch(`/api/file-request/${fileRequestID}/`, {
			method: "GET",
		})
			.then((data) => {
				document.title = `${data.device} | ${data.device_error ? "Error" : "Requested"} Files`;

				const deviceFileTableArray = data.request_files.map((file) => {
					return {
						file_name: file.device_file_name,
						content_start_date: moment.utc(file.content_start_date).format("YYYY-MM-DD HH:mm:ss"),
						file_size: bytesToAppropriateUnit(file.file_size),
						status: this.cleanFileStatus(file.status),
						actions: this.fileActions(file),
					};
				});

				// We need to get the file_request's current part so if the user initiates another part request,
				// we'll be able to populate file_part_request w/ the appropriate +1 part
				const currentDeviceFilePart = data.request_part;

				// If it's an error, we need to gather a lot more
				if (data.device_error) {
					const deviceError = data.device_error;
					const deviceFacility = data.device_error.device_config.facility;

					// !! converts the presence of an object's key into a boolean
					const cycleError = !!deviceError["cycle"];

					const cycleOperator = cycleError ? deviceError.cycle.operator.employee.full_name : "";
					const cycleStart = cycleError ? deviceError.cycle.time_started : "";

					const cycleFirmwareVersion = cycleError
						? data.device_error.cycle.firmware
							? data.device_error.cycle.firmware.firmware_version
							: "Unknown software - software record missing for cycle."
						: "Out of Cycle error";

					// If it's a hardware error, give it the hardware error code. Else (software error), give it an empty string
					const errorCode = deviceError.hw_error_code ? deviceError.hw_error_code.error_code : "";

					// If it's a hardware error, give it the HW error description. Else (sw error), give it the sw_error_msg
					const errorDesc = deviceError.hw_error_code
						? deviceError.hw_error_code.description
						: deviceError.sw_error_msg;

					const errorTime = deviceError.error_time;
					const errorState = deviceError.machine_state;

					const deviceCustomerFacility = `${deviceFacility.customer.customer_name} - ${deviceFacility.facility_name}`;

					//Used to link to dashboard
					const deviceConfigID = data.device_error.device_config.id;

					Auth.fetch(`/api/timezone/${deviceFacility.region_setting.timezone}/`, {
						method: "GET",
					})
						.then((timezoneData) => {
							const deviceTimezone = timezoneData.timezone;
							const errorTimeTimezoned = moment
								.utc(errorTime)
								.tz(deviceTimezone)
								.format("YYYY-MM-DD HH:mm:ss z");
							const cycleStartTimezoned = moment
								.utc(cycleStart)
								.tz(deviceTimezone)
								.format("YYYY-MM-DD HH:mm:ss z");

							this.setState({
								cycleFirmwareVersion,
								cycleOperator,
								errorCode,
								errorDesc,
								errorState,
								deviceCustomerFacility,
								deviceConfigID,
								deviceTimezone,
								errorTimeTimezoned,
								cycleStartTimezoned,
								isLoading: false,
								deviceFileTableArray,
								deviceError: data.device_error,
								deviceSerial: data.device,
								userDisplayName: data.display_name,
								userEmail: data.request_email,
								requestDate: moment
									.utc(data.request_date)
									.tz(userTimezone)
									.format("YYYY-MM-DD HH:mm:ss z"),
								currentDeviceFilePart,
								cycleError,
							});
						})
						.catch((err) => {
							Sentry.captureException(err);
							this.notifyGenericFailure();
							this.setState({
								isLoading: false,
							});
						});
				} else {
					this.setState({
						isLoading: false,
						deviceFileTableArray,
						deviceError: data.device_error,
						deviceSerial: data.device,
						userDisplayName: data.display_name,
						userEmail: data.request_email,
						requestDate: moment.utc(data.request_date).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z"),
						currentDeviceFilePart,
					});
				}
			})
			.catch((err) => {
				Sentry.captureException(err);
				this.notifyGenericFailure();
				this.setState({
					isLoading: false,
				});
			});
	};

	cleanFileStatus = (fileStatus) => {
		if (fileStatus === "OnPortal") {
			return "On Portal";
		} else if (fileStatus === "OnPi") {
			return "On Device";
		} else if (fileStatus === "Retrieving") {
			return "Retrieving from device";
		} else {
			return fileStatus;
		}
	};

	fileActions = (file) => {
		// file['file_request_s3_file'] is the actual file; the file_request_s3_file record
		if (file.status === "OnPortal" && file["file_request_s3_file"]) {
			return (
				<React.Fragment>
					<div
						className="clickable-text slight-margin-bottom"
						onClick={() => this.downloadFile(file["file_request_s3_file"])}
					>
						Download file to PC
					</div>
					{file["device_file_name"].includes("ethel") && (
						<Link target="_blank" to={`/log-parser?file_id=${file["file_request_s3_file"]["id"]}`}>
							View Log in Parser
						</Link>
					)}
				</React.Fragment>
			);
		} else if (file.status === "OnPi") {
			return (
				<div className="clickable-text" onClick={() => this.uploadFile(file)}>
					Upload file to portal
				</div>
			);
		} else if (file.status === "Retrieving") {
			return "";
		} else {
			return "";
		}
	};

	downloadFile = (upload) => {
		const fileID = upload["id"];
		const fileName = getFileNameFromUrl(upload["upload"]);

		const { deviceSerial } = this.state;
		// Some string manipulation logic to turn commdaemon-2019-09-27-09-17-01.log into commdaemon-SERIAL_NUMBER-2019-09-27-09-17-01.log
		const fileNameWithSerial = `${fileName.slice(0, fileName.indexOf("-"))}-${deviceSerial}${fileName.slice(
			fileName.indexOf("-")
		)}`;

		this.setState({
			isDownloading: true,
			fileName: fileNameWithSerial,
		});
		Auth.fetch(`/api/download-file/`, {
			method: "POST",
			body: JSON.stringify({
				file_id: fileID,
				file_type: "log",
			}),
		})
			.then((response) => response.blob())
			.catch((err) => {
				Sentry.captureException(err);
				err.response.json().then((errInfo) => {
					if (errInfo["message"]) {
						this.notifyGenericFailure(errInfo["message"]);
					}
				});
				this.setState({
					isLoading: false,
				});
			})
			.then((blob) => {
				this.setState({ isDownloading: false });
				saveAs(blob, fileNameWithSerial);
			})
			.catch((err) => Sentry.captureException(err));
	};

	uploadFile = (fileRequestFile) => {
		this.setState({
			isLoading: true,
		});
		this.uploadFileParts(fileRequestFile).then(() => {
			this.preparePage();
		});
	};

	uploadFileParts = async (fileRequestFile) => {
		try {
			let { currentDeviceFilePart } = this.state;

			await Auth.fetch(`/api/file-part-request/`, {
				method: "POST",
				body: JSON.stringify({
					part_id: currentDeviceFilePart + 1,
					file_request: fileRequestFile.file_request,
					file_request_file: fileRequestFile.id,
				}),
			});
		} catch (err) {
			Sentry.captureException(err);
			this.notifyGenericFailure();
			this.setState({
				isLoading: false,
			});
		}
	};

	downloadFileHeader = () => {
		const { deviceError, cycleError } = this.state;
		const { t } = this.props;
		if (deviceError) {
			if (cycleError) {
				return t("Download device files generated by a cycle error");
			} else {
				return t("Download device files generated by an out-of-cycle error");
			}
		} else {
			return t("Download requested device files");
		}
	};

	render() {
		const {
			isLoading,
			deviceError,
			deviceFileTableArray,
			isDownloading,
			fileName,
			deviceSerial,
			userDisplayName,
			requestDate,
			cycleFirmwareVersion,
			cycleOperator,
			errorCode,
			errorDesc,
			errorState,
			deviceCustomerFacility,
			deviceConfigID,
			deviceTimezone,
			errorTimeTimezoned,
			cycleStartTimezoned,
			cycleError,
		} = this.state;

		const { t } = this.props;
		return (
            <Card fluid>
                {isLoading && <SimpleLoader />}
                {isDownloading && <SimpleLoader loaderText={`Downloading ${fileName}`} />}
                <Card.Header style={{ margin: "10px" }}>
                    <h2 id="getDeviceFileHeader">{this.downloadFileHeader()}</h2>
                </Card.Header>
                <Card.Content className="card-body">
                    <SimpleRefreshTimer refreshData={this.preparePage} countdownText="Fetching fresh log files in" />
                    {deviceError ? (
                        <div>
                            <div className="file-request-metainfo">
                                <div>
                                    <h5>{t("Customer")}:</h5>{" "}
                                    <p id="deviceCustomerFacility">{deviceCustomerFacility}</p>
                                </div>
                                {cycleError && (
                                    <div>
                                        <h5>
                                            {t("Cycle start time")} ({deviceTimezone}):
                                        </h5>{" "}
                                        <p id="cycleStartTimezoned">{cycleStartTimezoned}</p>
                                    </div>
                                )}
                                <div>
                                    <h5>
                                        {t("Error detection time")} ({deviceTimezone}):
                                    </h5>{" "}
                                    <p id="errorTimeTimezoned">{errorTimeTimezoned}</p>
                                </div>
                                {cycleError && (
                                    <div>
                                        <h5>{t("Device Operator")}:</h5> <p id="cycleOperator">{cycleOperator}</p>
                                    </div>
                                )}
                                <div>
                                    <h5>{t("Device")}:</h5>
                                    <Link
                                        target="_blank"
                                        id="linkToFindAMachine"
                                        to={`/find-a-machine?serial=${deviceSerial}`}>
                                        {deviceSerial}
                                    </Link>
                                </div>
                                {cycleError && (
                                    <div>
                                        <h5>{t("Software")}:</h5>{" "}
                                        <p id="currentFirmwareVersion">{cycleFirmwareVersion}</p>
                                    </div>
                                )}
                                {/*Only show errorCode for hardware errors*/}
                                {errorCode.length > 0 && (
                                    <div>
                                        <h5>{t("Error code")}:</h5> <p id="errorCode">{errorCode}</p>
                                    </div>
                                )}
                                <div>
                                    <h5>{t("Error description")}:</h5> <p id="errorDesc">{errorDesc}</p>
                                </div>
                                <div>
                                    <h5>{t("Device state")}:</h5> <p id="errorState">{errorState}</p>
                                </div>
                            </div>
                            <div>
                                <Link target="_blank" to={`/sa/dashboard/${deviceConfigID}`}>
                                    {t("View dashboard for")} {deviceSerial}
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className="file-request-metainfo">
                            <div>
                                <h5>{t("Requested by")}</h5> <p>{userDisplayName}</p>
                            </div>
                            <div>
                                <h5>{t("Requested at")}</h5> <p>{requestDate}</p>
                            </div>
                            <div>
                                <h5>{t("Device")}</h5>
                                <Link
                                    target="_blank"
                                    id="linkToFindAMachine"
                                    to={`/find-a-machine?serial=${deviceSerial}`}>
                                    {deviceSerial}
                                </Link>
                            </div>
                        </div>
                    )}

                    <DownloadDeviceFileTable deviceFileTableArray={deviceFileTableArray} showStatus={true} />
                </Card.Content>
            </Card>
        );
	}
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers',
    'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin']))(DownloadFileRequest);
