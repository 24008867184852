/*
*  Terms and Conditions page
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import SimpleLoader from "../SimpleLoader";
import {Button, Card, Checkbox} from "semantic-ui-react";
import {composeHoc, isGroupCustomer} from "../library/helpers";
import withAuth from "../withAuth";
import AuthService from "../AuthService";
import moment from "moment";
import * as Sentry from "@sentry/browser";
const Auth = new AuthService();

class TermsAndConditions extends React.Component {
  state = {
    isLoading: false,
    termsAccepted: false,
    tacURL: '',
  };

  componentDidMount() {
    document.title = 'Sterilis Terms and Conditions';
    this.setState({isLoading: true});
    // TODO: this whole logic really relies on a single TaC being preferred
    Auth.fetch(`/api/terms-and-conditions-documents/?preferred=True`, {
      method: 'GET',
    }).then(data => {
      this.setState({
        isLoading: false,
        tacURL: data[0]['upload'],
        tacID: data[0]['id']
      });
    }).catch(err => {
      Sentry.captureException(err);
    });
  }

  handleTermsAccept = () => {
    this.setState((prevState) => {
      return {termsAccepted: !prevState.termsAccepted};
    });
  };

  submit = () => {
    const {
      termsAccepted,
      tacID,
    } = this.state;
    const {
      user,
      group,
    } = this.props;
    const portalUserID = user['user_id'];
    this.setState({isLoading: true});

    Auth.fetch(`/api/portal-user/${portalUserID}`, {
      method : 'PATCH',
      body: JSON.stringify({
        'accepted_terms_and_conditions': termsAccepted,
        'last_accepted_terms_and_conditions_date': moment.utc(),
        'terms_accepted_id': tacID,
      })
    }).then(data => {
      this.setState({
        isLoading: false
      }, () => {
        if (isGroupCustomer(group)) {
          this.props.history.replace('/sa')
        }else{
          this.props.history.replace('/')
        }
      });
    }).catch(err => {
      Sentry.captureException(err);
    });
  };


  render() {
    const {
      isLoading,
      tacURL
    } = this.state;
    const {
      t
    } = this.props;
    return (
      <Card className='customer-container' fluid>
        {isLoading && (
          <SimpleLoader/>
        )}
        <Card.Header style={{'margin': '10px'}}>
          <h2 className='center-text'>{t('Terms and Conditions')}</h2>
        </Card.Header>
        <Card.Content className="card-body">
          <p>{('Before you continue, please review our Terms and Conditions agreement.')}</p>
          <p className='slight-margin-bottom'>{t('Users are required to comply before they can access the Sterilis Solutions Portal.')}</p>
          <iframe title='Sterilis TaC' src={tacURL} width="100%" height="500px">
          </iframe>
          <p>{t('PDF not loading? Click')} <a target="_blank" href={tacURL}>here</a> {t('to open the PDF in a new tab')}</p>
          <div className='flex-col-end'>
            <Checkbox
              label={t('I have read and I accept Sterilis Solution\'s terms and conditions.')}
              name='termsAccepted'
              id='termsAccepted'
              className='medium-margin-bottom'
              required
              checked={this.state.termsAccepted}
              onChange={this.handleTermsAccept}/>

            <Button
              className='grey-btn'
              value="Submit"
              onClick={this.submit}
              type="submit"
              disabled={!this.state.termsAccepted}
            >{t('Submit')}</Button>
          </div>
        </Card.Content>
      </Card>
    )
  }
}

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers', 'CustomerPortalUsers',
    'DistributorAdmins', 'DistributorReadOnly', 'DistributorFSEs','SterilisWasteTypeAdmin', 'FacilityAdmin'], 'termsPage'))(TermsAndConditions);
