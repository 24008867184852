/*
*  Table for viewing, downloading, and editing firmware records
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {Checkbox} from "semantic-ui-react";
import {timestampSortHook, isGroupSterilisDistAdminOrFSE, useShowDeleted, epochSort} from "../../library/helpers";
import HookTable from "../../library/HookTable";


const FirmwareTable = props => {
  const [showDeleted, handleHideDeleted] = useShowDeleted(false);

  const customRowClass = (rowInfo) => {
    if (rowInfo && rowInfo.original) {
      if (rowInfo.original.deleted_status) {
        return 'light-red-tr'
      } else if (rowInfo.original.preferred === true) {
        return 'light-green-tr'
      }
    }
  };

  const {
    t,
    group
  } = props;
  const columns = [
    {
      Header: t('Software version'),
      accessor: 'firmware_version',
      id: 'firmware_version',
    },
    {
      Header: t('Linux version'),
      accessor: 'linux_version',
      id: 'linux_version',
    },
    {
      Header: t('Description'),
      accessor: 'description',
      id: 'description',
    },
    {
      Header: t('Created'),
      accessor: 'creation_date',
      id: 'creation_date',
      manualWidth: 185,
      sortType: (a, b, columnID) => epochSort(a, b, columnID)
    },
    {
      Header: t('Uploaded'),
      accessor: 'uploaded_timestamp',
      id: 'uploaded_timestamp',
      manualWidth: 185,
      sortType: (a, b, columnID) => timestampSortHook(a, b, columnID, "YYYY-MM-DD HH:mm:ss z")
    },
    {
      Header: t('Uploaded by'),
      accessor: 'upload_user',
      id: 'upload_user',
      manualWidth: 150,
    },
    {
      Header: t('Checksum'),
      accessor: 'checksum',
      id: 'checksum',
    },
    ...isGroupSterilisDistAdminOrFSE(group) ? [{
      Header: t('Actions'),
      accessor: 'actions',
      id: 'actions',
      manualWidth: 60,
    }] : [],
  ];

  const floatLeftHeaderJSX = <div className="table-sub-header">
    {group === 'SterilisSuperUsers' || group === 'SterilisWasteTypeAdmin' ?
      <Checkbox label={t('Show archived software')}
                className={'slight-margin-bottom'}
                checked={showDeleted}
                onChange={handleHideDeleted}/> : null}
  </div>;

  return (
    <HookTable
      id='firmwareTable'
      data={props.firmwareTableArray}
      columns={columns}
      tableName='firmwareTable'
      showPagination={props.firmwareTableArray.length > 10}
      showDeleted={showDeleted}
      floatLeftHeaderJSX={floatLeftHeaderJSX}
      defaultSortBy={{id: 'uploaded_timestamp', desc: true}}
      customRowClass={true}
      customRowClassFunc={customRowClass}
    />
  )
};

export default translate('translations')(FirmwareTable);
