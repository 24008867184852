/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import SimpleLoader from "../../SimpleLoader";
import {getFileNameFromUrl} from "../../library/helpers";
import AuthService from "../../AuthService";

const Auth = new AuthService();

class EditSDImageModal extends React.Component {
  state = {
    allowModalToBeClosed: true,
    description: '',
    modalLoading: false,
  };

  componentDidMount() {
    this.setState({
      description: this.props.SDImage['description']
    })
  }

  editSDImage = (e) => {
    e.preventDefault();
    const {
      SDImage
    } = this.props;
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });

    Auth.fetch(`/api/sd-image/${SDImage['id']}`, {
      method: 'PATCH',
      body: JSON.stringify({
        'description': this.state.description,
      })
    }).then(data => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true
      }, () => {
        this.props.notifySuccess('edited', getFileNameFromUrl(data['upload']));
        this.props.triggerEditSDImageModal(null);
        this.props.preparePage();
      });
    }).catch(err => {
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true
      }, () => {
        this.props.notifyFailure();
        this.props.triggerEditSDImageModal(null);
      });
    });
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    this.setState({[name]: value});
  };

  render() {
    const {
      t
    } = this.props;

    const {
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
        open={this.props.showEditSDImageModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='small'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerEditSDImageModal(null)
          }}
        >
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2>{t('Edit SD Image')}</h2>
          </Header>
          <Modal.Content>
            <form id='formEditSDImage'
                  onSubmit={this.editSDImage}
            >
              <div className='short-modal-text'>
                <div className="form-group">
                  <label className="fake-float">{t('Description')}</label>
                  <textarea value={this.state.description}
                            name='description'
                            onChange={this.handleChange}
                            rows={5}
                            id="descriptionInput"
                            className="form-control"
                            required/>
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn'
                    value="Submit"
                    type="submit"
                    form='formEditSDImage'
            >{t('Edit SD Image')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(EditSDImageModal);
