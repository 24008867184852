/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import GrindCycle from "../GrindCycle/GrindCycle";
import { processPlotBand } from "../CycleMetricChartContainer";
import SteamChart from "../SteamChart/SteamChart";
import CurrentChart from "../CurrentChart/CurrentChart";
import { Box } from "@mui/material";

const BurninTestChart = (props) => {
    const {
        overlaySol1,
        overlaySol2,
        overlaySol3,
        overlaySol4,
        overlaySol5,
        overlaySol6,
        overlaySol7,
        overlaySol8,
        overlaySol9,
        overlaySol10,
        overlaySol11,
        overlaySol12,
        overlaySol13,
        overlaySol14,
        overlaySol15,
        overlaySol16,
        overlaySol17,
        overlaySol18,
        overlayVacPump,
        overlayAirPump,
        overlayWaterPump,
        overlayHeaters,
        overlayDeodorizerLED,
        overlayGrinderOn,
        overlayGrinderForward,
        overlayOil,
        overlayOilLevels,
    } = props.digitalOutputOverlays;
    const {
        combineTPGraphs,
        showCurrentGraph,
        showTempConstants,
        showPresConstants,
        overlayStates,
        overlayInfo,
        plotDutyCycle,
        showAbnormalSeriesValues,
    } = props.chartOptions;

    const plotBands = [
        ...(overlaySol1 && props.dioList.vacuum_valve ? props.dioList.vacuum_valve : []),
        ...(overlaySol2 && props.dioList.drain_valve ? props.dioList.drain_valve : []),
        ...(overlaySol3 && props.dioList.water_valve ? props.dioList.water_valve : []),
        ...(overlaySol4 && props.dioList.top_lid_valve ? props.dioList.top_lid_valve : []),
        ...(overlaySol5 && props.dioList.bottom_lid_valve ? props.dioList.bottom_lid_valve : []),
        ...(overlaySol6 && props.dioList.shroud_valve ? props.dioList.shroud_valve : []),
        ...(overlaySol7 && props.dioList.accumulator_valve ? props.dioList.accumulator_valve : []),
        ...(overlaySol8 && props.dioList.seal_valve ? props.dioList.seal_valve : []),
        ...(overlaySol9 && props.dioList.jack_valve ? props.dioList.jack_valve : []),
        ...(overlaySol10 && props.dioList.air_inlet_valve ? props.dioList.air_inlet_valve : []),
        ...(overlaySol11 && props.dioList.oil_valve ? props.dioList.oil_valve : []),
        ...(overlaySol12 && props.dioList.seal_vacuum_valve ? props.dioList.seal_vacuum_valve : []),
        ...(overlaySol13 && props.dioList.vessel_valve ? props.dioList.vessel_valve : []),
        ...(overlaySol14 && props.dioList.odor_valve ? props.dioList.odor_valve : []),
        ...(overlaySol15 && props.dioList.shroud_2 ? props.dioList.shroud_2 : []),
        ...(overlaySol16 && props.dioList.deodorizer_spray ? props.dioList.deodorizer_spray : []),
        ...(overlaySol17 && props.dioList.drain_valve_alt ? props.dioList.drain_valve_alt : []),
        ...(overlaySol18 && props.dioList.signal_light ? props.dioList.signal_light : []),
        ...(overlayVacPump && props.dioList.vac_pump ? props.dioList.vac_pump : []),
        ...(overlayAirPump && props.dioList.pressure_pump ? props.dioList.pressure_pump : []),
        ...(overlayWaterPump && props.dioList.water_pump ? props.dioList.water_pump : []),
        ...(overlayHeaters && props.dioList.heater ? props.dioList.heater : []),
        ...(overlayDeodorizerLED && props.dioList.deodorizer_led ? props.dioList.deodorizer_led : []),
        ...(overlayGrinderOn && props.dioList.grinder_on ? props.dioList.grinder_on : []),
        ...(overlayGrinderForward && props.dioList.grinder_fwd ? props.dioList.grinder_fwd : []),
        ...(overlayOil && props.dioList.oil ? props.dioList.oil : []),
        ...(overlayOilLevels && props.dioList.oil_level ? props.dioList.oil_level : []),

        ...(overlayStates ? processPlotBand(props.stateList, props.digitalOutputOverlays, overlayStates) : []),
    ];

    const dutyCycleValuesOverThreshHold = useMemo(() => {
        return plotDutyCycle && props.dutyCycleList.some((dutyCycleVal) => dutyCycleVal >= 690);
    }, [props.dutyCycleList, plotDutyCycle]);

    const [zoomParams, _setZoomParams] = useState(null);

    const setZoomParams = React.useCallback((newState) => {
        _setZoomParams({ ...zoomParams, ...newState });
    }, []);

    return (
        <Box sx={{ padding: 2, margin: 1 }}>
            {props.cycleType === "SteamCycle" && (
                <SteamChart
                    customTitlePrefix={props.customTitlePrefix}
                    thList={props.thList}
                    tvList={props.tvList}
                    trList={props.trList}
                    paList={props.paList}
                    psList={props.psList}
                    pvList={props.pvList}
                    iList={props.ampsList}
                    timeList={props.timeList}
                    plotBands={plotBands}
                    combineTPGraphs={combineTPGraphs}
                    showCurrentGraph={showCurrentGraph}
                    showTempConstants={showTempConstants}
                    showPresConstants={showPresConstants}
                    infoConstants={props.infoConstants}
                    overlayInfo={overlayInfo}
                    overlayStates={overlayStates}
                    plotDutyCycle={plotDutyCycle}
                    dutyCycleList={props.dutyCycleList}
                    abnormalThValues={props.abnormalValuesObj["th_list"]}
                    abnormalTvValues={props.abnormalValuesObj["tv_list"]}
                    abnormalTrValues={props.abnormalValuesObj["tr_list"]}
                    abnormalPaValues={props.abnormalValuesObj["pa_list"]}
                    abnormalPsValues={props.abnormalValuesObj["ps_list"]}
                    abnormalPvValues={props.abnormalValuesObj["pv_list"]}
                    abnormalIValues={props.abnormalValuesObj["i_list"]}
                    abnormalDutyCycleValues={props.abnormalValuesObj["duty_cycle_list"]}
                    burninChart={true}
                    zoomParams={zoomParams}
                    setZoomParams={setZoomParams}
                />
            )}
            {props.cycleType === "GrindCycle" && (
                <GrindCycle
                    plotBands={plotBands}
                    customTitlePrefix={props.customTitlePrefix}
                    timeList={props.timeList}
                    igList={props.ampsList}
                    paList={props.paList}
                    infoConstants={props.infoConstants}
                    overlayInfo={overlayInfo}
                    overlayStates={overlayStates}
                    plotDutyCycle={plotDutyCycle}
                    dutyCycleList={props.dutyCycleList}
                    dutyCycleValuesOverThreshHold={dutyCycleValuesOverThreshHold}
                    showAbnormalSeriesValues={showAbnormalSeriesValues}
                    abnormalPaValues={props.abnormalValuesObj["pa_list"]}
                    abnormalIgValues={props.abnormalValuesObj["ig_list"]}
                    abnormalDutyCycleValues={props.abnormalValuesObj["duty_cycle_list"]}
                    zoomParams={zoomParams}
                    setZoomParams={setZoomParams}
                />
            )}
            {showCurrentGraph && (
                <CurrentChart
                    timeList={props.timeList}
                    iList={props.ampsList}
                    plotBands={plotBands}
                    overlayInfo={overlayInfo}
                    infoConstants={props.infoConstants}
                    combineTPGraphs={props.cycleType === "GrindCycle" ? false : combineTPGraphs}
                    showAbnormalSeriesValues={showAbnormalSeriesValues}
                    abnormalIValues={
                        props.cycleType === "GrindCycle"
                            ? props.abnormalValuesObj["ig_list"]
                            : props.abnormalValuesObj["i_list"]
                    }
                    customTitlePrefix={props.customTitlePrefix}
                    zoomParams={zoomParams}
                    setZoomParams={setZoomParams}
                    cycleType={props.cycleType}
                />
            )}
        </Box>
    );
};

BurninTestChart.defaultProps = {
  abnormalValuesObj: {},
  customTitlePrefix: "",
  digitalOutputOverlays: {},
  chartOptions: {},
  olderTestGraph: false,
};

BurninTestChart.propTypes = {
  abnormalValuesObj: PropTypes.object,
  customTitlePrefix: PropTypes.string,
  digitalOutputOverlays: PropTypes.object,
  chartOptions: PropTypes.object,
  olderTestGraph: PropTypes.bool,
};

export default BurninTestChart;
