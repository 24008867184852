/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import PropTypes from "prop-types";
import {
    dutyCycleAvgSeries,
    dutyCycleAvgYAxis,
    dutyCycleEndSeries,
    dutyCycleEndYAxis,
    dutyCycleStartSeries,
    dutyCycleStartYAxis,
} from "../CycleStats/ChartHelpers";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useSelector } from "react-redux";

const SharedCycleTrendsChart = (props) => {
    const { dutyCycleAvgTrend, dutyCycleEndTrend, dutyCycleStartTrend } = useSelector(
        // @ts-ignore
        (store) => store.metric.sharedCycleTrends
    );
    const sharedCycleTrendsOptions = {
        chart: {
            zoomType: "x",
            resetZoomButton: {
                position: {
                    x: 0,
                    y: 0,
                },
            },
        },
        tooltip: {
            shared: true,
        },
        title: {
            text: "Shared Cycle Trends",
        },
        credits: false,
        time: {
            useUTC: false,
        },
        xAxis: [
            {
                type: "datetime",
                crosshair: true,
            },
        ],
        yAxis: [
            ...(props.trendDutyCycleAvg && dutyCycleAvgYAxis(props)),
            ...(props.trendDutyCycleStart && dutyCycleStartYAxis(props)),
            ...(props.trendDutyCycleEnd && dutyCycleEndYAxis(props)),
        ],
        series: [
            ...(props.trendDutyCycleAvg && dutyCycleAvgSeries(dutyCycleAvgTrend, props)),
            ...(props.trendDutyCycleStart && dutyCycleStartSeries(dutyCycleStartTrend, props)),
            ...(props.trendDutyCycleEnd && dutyCycleEndSeries(dutyCycleEndTrend, props)),
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={sharedCycleTrendsOptions} />;
};

SharedCycleTrendsChart.defaultProps = {
    trendDutyCycleAvg: true,
    trendDutyCycleStart: true,
    trendDutyCycleEnd: true,

    abnormalDutyCycleAvgValues: {},
    abnormalDutyCycleStartValues: {},
    abnormalDutyCycleEndValues: {},
};

SharedCycleTrendsChart.propTypes = {
    trendDutyCycleAvg: PropTypes.bool,
    trendDutyCycleStart: PropTypes.bool,
    trendDutyCycleEnd: PropTypes.bool,
    abnormalDutyCycleAvgValues: PropTypes.object,
    abnormalDutyCycleStartValues: PropTypes.object,
    abnormalDutyCycleEndValues: PropTypes.object,
};

export default SharedCycleTrendsChart;
