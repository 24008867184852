/*
*  The mini table within the Service Summary that has the breakdown of cycles which happened in a day
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import HookTable from "../../library/HookTable";

const CyclesByDayTable = props => (
  <HookTable
    columns={props.cyclesByDayColumns}
    innerTable={true}
    data={props.data}
    showPagination={false}
    renderEmptyRow={false}
    defaultPageSize={1}
  />
);

export default translate('translations')(CyclesByDayTable);
