import React from "react";
import PropTypes from "prop-types";
import {
    Alert,
    Avatar,
    CardContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import SubCard from "src/components/material/Cards/SubCard";
import { CoronavirusTwoTone, ErrorTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { capitalizeFirstLetter } from "src/components/library/helpers";

const BiochallengeInfo = (props) => {
    const theme = useTheme();
    const { t } = useTranslation("translations");
    const { selectedDevice, selectedDeviceConfig, deviceTimezone } = useSelector((store) => store.deviceInfo);
    const loading = useSelector((store) => store.deviceInfo.loading.deviceConfig);
    const isAirport = selectedDeviceConfig?.facility.facility_type === "airport";

    const getBioInfo = () => {
        const lastBioString = selectedDeviceConfig?.device?.time_last_biochallenge;
        const lastBio = lastBioString
            ? DateTime.fromISO(lastBioString, { zone: deviceTimezone }).toLocaleString({
                  ...DateTime.DATETIME_SHORT_WITH_SECONDS,
                  timeZoneName: "short",
              })
            : "";

        if (isAirport) {
            return (
                <ListItem>
                    <ListItemText
                        primary={t(
                            "device-info.biochallenge_info.airport_notice",
                            "This device belongs to an airport. Airport devices do not require Bio-Challenges"
                        )}
                    />
                </ListItem>
            );
        } else {
            let formattedBioInterval = formatBioInterval(
                selectedDeviceConfig?.facility?.region_setting?.bio_interval_duration
            );
            let intervalType = capitalizeFirstLetter(selectedDeviceConfig?.facility?.region_setting?.bio_interval_type);

            const texasFacility = selectedDeviceConfig?.facility?.waste_per_month;
            if (texasFacility) {
                // if it's a Texas facility, we need to use some different values
                formattedBioInterval = formatBioInterval(selectedDeviceConfig?.facility?.bio_interval_duration);
                intervalType = capitalizeFirstLetter(selectedDeviceConfig?.facility?.bio_interval_type);
            }

            return loading ? (
                <>
                    <Skeleton width={"100%"}>
                        <ListItem secondaryAction={lastBio}>
                            <ListItemText primary={t("Last Bio-Challenge") + ":"} />
                        </ListItem>
                    </Skeleton>
                    <Skeleton width={"100%"}>
                        <ListItem secondaryAction={intervalType}>
                            <ListItemText primary={t("Bio-Challenge Interval Type") + ":"} />
                        </ListItem>
                    </Skeleton>
                    <Skeleton width={"100%"}>
                        <ListItem secondaryAction={formattedBioInterval}>
                            <ListItemText primary={t("Bio-Challenge Interval") + ":"} />
                        </ListItem>
                    </Skeleton>
                </>
            ) : (
                <>
                    {texasFacility && (
                        <Alert severity="warning">
                            {t(
                                "device-info.biochallenge_info.texas_notice",
                                "This device belongs to a facility in Texas. They have specific Bio-Challenge regulations"
                            )}
                        </Alert>
                    )}
                    <ListItem secondaryAction={lastBio}>
                        <ListItemText primary={t("Last Bio-Challenge") + ":"} />
                    </ListItem>
                    <ListItem secondaryAction={intervalType}>
                        <ListItemText primary={t("Bio-Challenge Interval Type") + ":"} />
                    </ListItem>
                    <ListItem secondaryAction={formattedBioInterval}>
                        <ListItemText primary={t("Bio-Challenge Interval") + ":"} />
                    </ListItem>
                </>
            );
        }
    };

    const formatBioInterval = (bioInterval) => {
        switch (bioInterval) {
            case "2419200":
                return "1 month";
            case "7257600":
                return "3 months";
            case "144000":
                return "40 hours";
            case "360000":
                return "100 hours";
            default:
                return bioInterval;
        }
    };

    return (
        <SubCard
            title={t("Bio-Challenge Information")}
            sx={{ width: "100%" }}
            avatar={
                <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                    <CoronavirusTwoTone />
                </Avatar>
            }>
            {selectedDevice && (
                <CardContent>
                    <List>{getBioInfo()}</List>
                </CardContent>
            )}
        </SubCard>
    );
};

BiochallengeInfo.propTypes = {};

export default BiochallengeInfo;
