/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import CombinedSteamChart from "./CombinedSteamChart";
import PropTypes from "prop-types";
import SeparateSteamChart from "./SeparateSteamChart";

const SteamChart = (props) => {
  return (
    <React.Fragment>
      {props.combineTPGraphs ? (
        <CombinedSteamChart
          thList={props.thList}
          tvList={props.tvList}
          trList={props.trList}
          paList={props.paList}
          psList={props.psList}
          pvList={props.pvList}
          iList={props.iList}
          timeList={props.timeList}
          plotBands={props.plotBands}
          combineTPGraphs={props.combineTPGraphs}
          showCurrentGraph={props.showCurrentGraph}
          showTempConstants={props.showTempConstants}
          showPresConstants={props.showPresConstants}
          infoConstants={props.infoConstants}
          overlayInfo={props.overlayInfo}
          overlayStates={props.overlayStates}
          plotDutyCycle={props.plotDutyCycle}
          dutyCycleList={props.dutyCycleList}
          dutyCycleValuesOverThreshHold={props.dutyCycleValuesOverThreshHold}
          showAbnormalSeriesValues={props.showAbnormalSeriesValues}
          abnormalThValues={props.abnormalThValues}
          abnormalTvValues={props.abnormalTvValues}
          abnormalTrValues={props.abnormalTrValues}
          abnormalPaValues={props.abnormalPaValues}
          abnormalPsValues={props.abnormalPsValues}
          abnormalPvValues={props.abnormalPvValues}
          abnormalIValues={props.abnormalIValues}
          abnormalDutyCycleValues={props.abnormalDutyCycleValues}
          zoomParams={props.zoomParams}
          setZoomParams={props.setZoomParams}
          overlayedCycles={props.overlayedCycles}
          cycleStartTime={props.cycleStartTime}
          customTitlePrefix={props.customTitlePrefix}
        />
      ) : (
        <SeparateSteamChart
          thList={props.thList}
          tvList={props.tvList}
          trList={props.trList}
          paList={props.paList}
          psList={props.psList}
          pvList={props.pvList}
          iList={props.iList}
          timeList={props.timeList}
          plotBands={props.plotBands}
          combineTPGraphs={props.combineTPGraphs}
          showCurrentGraph={props.showCurrentGraph}
          showTempConstants={props.showTempConstants}
          showPresConstants={props.showPresConstants}
          infoConstants={props.infoConstants}
          overlayInfo={props.overlayInfo}
          overlayStates={props.overlayStates}
          plotDutyCycle={props.plotDutyCycle}
          dutyCycleList={props.dutyCycleList}
          dutyCycleValuesOverThreshHold={props.dutyCycleValuesOverThreshHold}
          showAbnormalSeriesValues={props.showAbnormalSeriesValues}
          abnormalThValues={props.abnormalThValues}
          abnormalTvValues={props.abnormalTvValues}
          abnormalTrValues={props.abnormalTrValues}
          abnormalPaValues={props.abnormalPaValues}
          abnormalPsValues={props.abnormalPsValues}
          abnormalPvValues={props.abnormalPvValues}
          abnormalIValues={props.abnormalIValues}
          abnormalDutyCycleValues={props.abnormalDutyCycleValues}
          zoomParams={props.zoomParams}
          setZoomParams={props.setZoomParams}
          overlayedCycles={props.overlayedCycles}
          cycleStartTime={props.cycleStartTime}
          customTitlePrefix={props.customTitlePrefix}
        />
      )}
    </React.Fragment>
  );
};

SteamChart.defaultProps = {
  overlayedCycles: [],
};

SteamChart.propTypes = {
  overlayedCycles: PropTypes.array,
};

export default SteamChart;
