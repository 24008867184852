import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import AuthService from "src/components/AuthService";
import { processBurninData } from "src/components/library/helpers";
import { config } from "../../../portal-environment";

const auth = new AuthService();

const initialState = {
    abnormalValues: [],
    bitTests: {
        1: {
            testList: [],
            isLoading: false,
            result: {
                test: 1,
                testname: "Extended Grind Run",
                missing: true,
                score: 0,
                success: false,
            },
            latest: {},
        },
        2: {
            testList: [],
            isLoading: true,
            result: {
                test: 2,
                testname: "Automated Kinematics",
                missing: true,
                score: 0,
                success: false,
            },
            latest: {},
        },
        3: {
            testList: [],
            isLoading: false,
            result: {
                test: 3,
                testname: "Long Steam Cycle",
                missing: true,
                score: 0,
                success: false,
            },
            latest: {},
        },
        4: {
            testList: [],
            isLoading: false,
            result: {
                test: 4,
                testname: "# Of Burps",
                missing: true,
                score: 0,
                success: false,
            },
            latest: {},
        },
        5: {
            testList: [],
            isLoading: false,
            result: {
                test: 5,
                testname: "Lid Motion after Heat",
                missing: true,
                score: 0,
                success: false,
            },
            latest: {},
        },
        6: {
            testList: [],
            isLoading: false,
            result: {
                test: 6,
                testname: "Towel Roll Test",
                missing: true,
                score: 0,
                success: false,
            },
            latest: {},
        },
        7: {
            testList: [],
            isLoading: false,
            result: {
                test: 7,
                testname: "Summary of 4 UI Runs",
                missing: true,
                score: 0,
                success: false,
            },
            latest: {},
        },
    },
    deviceId: null,
    test7Eligible: false,
    score: 0,
};

const bitSlice = createSlice({
    name: "bit",
    initialState,
    reducers: {
        addAbnormalValue: (state, action) => {
            state.abnormalValues.push(action.payload);
        },
        setDeviceId: (state, action) => {
            state.deviceId = action.payload;
        },
        setLatestTestData: (state, action) => {
            state.bitTests[action.payload.test].latest = action.payload.result;
        },
        setTestList: (state, action) => {
            state.bitTests[action.payload.test].testList = action.payload.data;
        },
        setTestResult: (state, action) => {
            state.bitTests[action.payload.test].result = action.payload;
        },
        resetTests: (state, action) => {
            state.bitTests = initialState.bitTests;
            state.score = 0;
            state.test7Eligible = false;
        },
        resetTestResult: (state, action) => {
            state.bitTests[action.payload].result.success = false;
            state.bitTests[action.payload].result.score = 0;
        },
        setResults: (state, action) => {
            action.payload.forEach((test) => (state.bitTests[test.test].result = test));
        },
        setTestSuccess: (state, action) => {
            state.bitTests[action.payload.test].result.success = action.payload.value;
        },
        setTestScore: (state, action) => {
            state.bitTests[action.payload.test].result.score = action.payload.score;
        },
        addTestScore: (state, action) => {
            state.bitTests[action.payload.test].result.score += action.payload.score;
        },
        setTotalScore: (state, action) => {
            state.score = action.payload;
        },
        setTestLoading: (state, action) => {
            state.bitTests[action.payload.test].isLoading = action.payload.value;
        },
        setSteamCycleProperty: (state, action) => {
            state.bitTests[action.payload.test].data[action.payload.key] = action.payload.value;
        },
        setGrindCycleProperty: (state, action) => {
            state.bitTests[action.payload.test].data[action.payload.key] = action.payload.value;
        },
        setTest7Eligibility: (state, action) => {
            state.test7Eligible = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Test #1
            .addCase(fetchTest1Results.pending, (state) => {
                state.bitTests[1].isLoading = true;
                state.bitTests[1].result.success = false;
            })
            .addCase(fetchTest1Results.fulfilled, (state, action) => {
                state.bitTests[1].isLoading = false;
                state.bitTests[1].result.missing = false;
                state.bitTests[1].testList = action.payload;
            })
            .addCase(fetchTest1Results.rejected, (state, action) => {
                console.log(action);
                state.bitTests[1].isLoading = false;
                state.bitTests[1].result.missing = true;
                state.bitTests[1].result.success = false;
            })
            // Test #2
            .addCase(fetchTest2Results.pending, (state) => {
                state.bitTests[2].isLoading = true;
                state.bitTests[2].result.success = false;
            })
            .addCase(fetchTest2Results.fulfilled, (state, action) => {
                state.bitTests[2].isLoading = false;
                state.bitTests[2].result.missing = false;
                state.bitTests[2].testList = action.payload;
            })
            .addCase(fetchTest2Results.rejected, (state, action) => {
                console.log(action);
                state.bitTests[2].isLoading = false;
                state.bitTests[2].result.missing = true;
                state.bitTests[2].result.success = false;
            })
            // Test #3
            .addCase(fetchTest3Results.pending, (state) => {
                state.bitTests[3].isLoading = true;
                state.bitTests[3].result.success = false;
            })
            .addCase(fetchTest3Results.fulfilled, (state, action) => {
                state.bitTests[3].isLoading = false;
                state.bitTests[3].result.missing = false;
                state.bitTests[3].testList = action.payload;
            })
            .addCase(fetchTest3Results.rejected, (state, action) => {
                console.log(action);
                state.bitTests[3].isLoading = false;
                state.bitTests[3].result.missing = true;
                state.bitTests[3].result.success = false;
            })
            // Test #4
            .addCase(fetchTest4Results.pending, (state) => {
                state.bitTests[4].isLoading = true;
                state.bitTests[4].result.success = false;
            })
            .addCase(fetchTest4Results.fulfilled, (state, action) => {
                state.bitTests[4].isLoading = false;
                state.bitTests[4].result.missing = false;
                state.bitTests[4].testList = action.payload;
            })
            .addCase(fetchTest4Results.rejected, (state, action) => {
                console.log(action);
                state.bitTests[4].isLoading = false;
                state.bitTests[4].result.missing = true;
                state.bitTests[4].result.success = false;
            })
            // Test #5
            .addCase(fetchTest5Results.pending, (state) => {
                state.bitTests[5].isLoading = true;
                state.bitTests[5].result.success = false;
            })
            .addCase(fetchTest5Results.fulfilled, (state, action) => {
                state.bitTests[5].isLoading = false;
                state.bitTests[5].result.missing = false;
                state.bitTests[5].testList = action.payload;
            })
            .addCase(fetchTest5Results.rejected, (state, action) => {
                console.log(action);
                state.bitTests[5].isLoading = false;
                state.bitTests[5].result.missing = true;
                state.bitTests[5].result.success = false;
            })
            // Test #6
            .addCase(fetchTest6Results.pending, (state) => {
                state.bitTests[6].isLoading = true;
                state.bitTests[6].result.success = false;
            })
            .addCase(fetchTest6Results.fulfilled, (state, action) => {
                state.bitTests[6].isLoading = false;
                state.bitTests[6].result.missing = false;
                state.bitTests[6].testList = action.payload;
            })
            .addCase(fetchTest6Results.rejected, (state, action) => {
                console.log(action);
                state.bitTests[6].isLoading = false;
                state.bitTests[6].result.missing = true;
                state.bitTests[6].result.success = false;
            })
            // Test #7
            .addCase(fetchTest7Results.pending, (state) => {
                state.bitTests[7].isLoading = true;
                state.bitTests[7].result.success = false;
            })
            .addCase(fetchTest7Results.fulfilled, (state, action) => {
                state.bitTests[7].isLoading = false;
                state.bitTests[7].result.missing = false;
                state.bitTests[7].latest = action.payload;
            })
            .addCase(fetchTest7Results.rejected, (state, action) => {
                console.log(action);
                state.bitTests[7].isLoading = false;
                state.bitTests[7].result.missing = true;
                state.bitTests[7].result.success = false;
            });
    },
});

export const fetchTest1Results = createAsyncThunk("bit/fetchTest1Results", async (deviceId) => {
    const response = await auth.fetch(`/metrics/bit/extended-grind?device_id=${deviceId}`);
    const test = processBurninData(response);
    return test;
});

export const fetchTest2Results = createAsyncThunk("bit/fetchTest2Results", async (deviceId) => {
    const response = await auth.fetch(`/metrics/bit/automated-kinematics?device_id=${deviceId}`);
    const test = processBurninData(response);
    return test;
});

export const fetchTest3Results = createAsyncThunk("bit/fetchTest3Results", async (deviceId) => {
    const response = await auth.fetch(`/metrics/bit/long-steam?device_id=${deviceId}`);
    const test = processBurninData(response);
    return test;
});

export const fetchTest4Results = createAsyncThunk("bit/fetchTest4Results", async (deviceId) => {
    const response = await auth.fetch(`/metrics/bit/burp-count?device_id=${deviceId}`);
    const test = processBurninData(response);
    return test;
});

export const fetchTest5Results = createAsyncThunk("bit/fetchTest5Results", async (deviceId) => {
    const response = await auth.fetch(`/metrics/bit/lid-motion?device_id=${deviceId}`);
    const test = processBurninData(response);
    return test;
});

export const fetchTest6Results = createAsyncThunk("bit/fetchTest6Results", async (deviceId) => {
    const response = await auth.fetch(`/metrics/bit/towel-roll?device_id=${deviceId}`);
    const test = processBurninData(response);
    return test;
});

export const fetchTest7Results = createAsyncThunk(
    "bit/fetchTest7Results",
    /** @param params {{ deviceId: string, lastTest: string }} */
    async (params) => {
        const response = await auth.fetch(
            `/metrics/bit/cycle-summary?device_id=${params.deviceId}&last_test=${params.lastTest}`
        );
        const test = processBurninData(response);
        return test;
    }
);

export const selectTest1To6Passing = (state) =>
    Object.values(state.bit.bitTests).every((test) => test.result.success || test.result.test === 7);

export const selectTestResults = createSelector([(state) => state.bit.bitTests], (tests) =>
    Object.values(tests).map((test) => test.result)
);

export const showTest7Button = createSelector([selectTestResults], (results) =>
    results.every((result) => {
        return result.success || result.test === 7;
    })
);

export const selectResultsByTest = (state, test) => state.bitTests[test].result;

export const selectLatestTestDate = (state) =>
    Object.keys(state.bitTests)
        .map((key) => state.bitTests[key].latest)
        .reduce((a, b) => {
            const aTime = DateTime.fromISO(a.end_time);
            const bTime = DateTime.fromISO(b.end_time);
            return aTime >= bTime ? a : b;
        });

export const {
    addAbnormalValue,
    setTestResult,
    setTestLoading,
    setDeviceId,
    setLatestTestData,
    setTestList,
    setTest7Eligibility,
    addTestScore,
    setTestScore,
    setTestSuccess,
    resetTestResult,
    setTotalScore,
    setResults,
    resetTests,
} = bitSlice.actions;

export default bitSlice.reducer;
