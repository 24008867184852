import { CheckCircleOutlineTwoTone, EmailTwoTone, PersonRemoveTwoTone, PhoneTwoTone } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import React from "react";
import AuthService from "src/components/AuthService";
import { isGroupDist, prettyGroupName } from "src/components/library/helpers";

const auth = new AuthService();

const initialState = {
    loading: false,
    siteAdmins: [],
    siteAdminTableData: [],
    emailResult: null,
    distributorFilterOptions: [],
};

const siteAdminSlice = createSlice({
    name: "siteAdmin",
    initialState,
    reducers: {
        setSiteAdminTableData(state, action) {
            state.siteAdminTableData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSiteAdmins.pending, (state, action) => {
                state.loading = true;
                state.siteAdmins = initialState.siteAdmins;
            })
            .addCase(fetchSiteAdmins.fulfilled, (state, action) => {
                state.loading = false;
                state.siteAdmins = action.payload.filter((admin) => admin.groups.length > 0);
                let dists = action.payload.map((admin) => {
                    return {
                        key: admin.employee.customer.distributor.id,
                        value: admin.employee.customer.distributor.id,
                        text: admin.employee.customer.distributor.distributor_name,
                    };
                });
                dists = dists.filter(
                    (val, idx, self) => idx === self.findIndex((t) => t.id === val.id && t.value === val.value)
                );
                dists.push({ key: 0, value: 0, text: "All Distributors" });
                state.distributorFilterOptions = Array.from(dists);
            })
            .addCase(fetchSiteAdmins.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const fetchSiteAdmins = createAsyncThunk(
    "siteAdmin/fetchSiteAdmins",
    // @ts-ignore
    async (queryString = "") => {
        const response = await auth.fetch(`/api/portal-user${queryString}`);
        return response;
    }
);

export const selectSiteAdminsForTable = (state) => {
    return state.siteAdmin.siteAdmins.map((user) => ({
        id: user.id,
        username: user.username,
        full_name: user.employee.full_name,
        customer_id: user.employee.customer.id,
        group_name: prettyGroupName(user.groups[0]?.name, isGroupDist(state.user.group)),
        distributor_id: user.employee.customer.distributor.id,
        active_status: user.is_active,
        deleted_status: user.employee.deleted,
        pin: user.employee?.device_user.length > 0 ? user.employee?.device_user[0].pin : "",
        customer_name: user.employee.customer.customer_name,
        contact_info: (
            <div
                data-search={`${user.employee?.phone} ${user.employee?.email_address}`}
                className="contact-info-container">
                <div className="contact-info-container-inner">
                    <PhoneTwoTone color="info" fontSize="small" />
                    <a href={`tel:${user.employee?.phone}`}> {user.employee?.phone}</a>
                </div>
                <div className="contact-info-container-inner">
                    <EmailTwoTone color="success" fontSize="small" />
                    <a href={`mailto:${user.employee?.email_address}`}> {user.employee?.email_address}</a>
                </div>
            </div>
        ),
    }));
};

export const { setSiteAdminTableData } = siteAdminSlice.actions;

export default siteAdminSlice.reducer;
