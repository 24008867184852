/*
 * Release notes main component
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { withTranslation as translate } from "react-i18next";
import { isGroupNotCustomer } from "../library/helpers";
import { Card, CardHeader, CardContent, Typography, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import V100ReleaseNotes from "./v1.0.0/V100ReleaseNotes";
import V222ReleaseNotes from "./v2.2.2/v222ReleaseNotes";
import V223ReleaseNotes from "./v2.2.3/v223ReleaseNotes";
import V227ReleaseNotes from "./v2.2.7/v227ReleaseNotes";
import V2210ReleaseNotes from "./v2.2.10/v2210ReleaseNotes";

const ReleaseNotes = (props) => {
    document.title = "Sterilis Release Notes";
    return (
        <div className={isGroupNotCustomer(props.group) ? "" : "customer-container"}>
            <Card>
                <CardHeader
                    title={
                        <Typography textAlign="center" variant="h3" fontWeight="bold">
                            {props.t("Release Notes")}
                        </Typography>
                    }
                />
                <CardContent>
                    <V2210ReleaseNotes t={props.t} />
                    <V227ReleaseNotes t={props.t} />
                    <V223ReleaseNotes t={props.t} />
                    <V222ReleaseNotes t={props.t} />
                    <V100ReleaseNotes />
                </CardContent>
            </Card>
        </div>
    );
};

export default translate("translations")(ReleaseNotes);
