/*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import SummaryDashboardNav from "./SummaryDashboardNav";
import WaveLoader from "../WaveLoader";
import '../../css/dashboard/DashboardMain.css';

const LoadingSummaryDashboard = props => (
  <div className='customer-container'>
    <SummaryDashboardNav
      toDateClick={props.toDateClick}
      fromDateClick={props.fromDateClick}
      fromDate={props.fromDate}
      toDate={props.toDate}
      prepareDashboard={props.prepareDashboard}
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
      customerName={props.customerName}
      isFacility={props.isFacility}
      facilityName={props.facilityName}
      customerId={props.customerId}
      facilityID={props.facilityID}
      facilities={props.facilities}
      selectedFacility={props.selectedFacility}
    />
    <WaveLoader/>
  </div>
);

export default translate('translations')(LoadingSummaryDashboard);
