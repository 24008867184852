import { Autocomplete, CircularProgress, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDistributorCustomers } from "src/features/Distributor/distributorSlice";
import FormAutocomplete from "src/components/material/Forms/FormAutocomplete";

const DistributorSelection = (props) => {
    const { control, errors, rules } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation("translations");

    // @ts-ignore
    const { distributorCustomers } = useSelector((store) => store.distributor);
    // @ts-ignore
    const { group } = useSelector((store) => store.user);

    const visibleDistributorGroups = ["SterilisSuperUsers", "SterilisPortalUsers", "FSEs", "SterilisWasteTypeAdmin"];

    React.useEffect(() => {
        if (distributorCustomers.length === 0) {
            // @ts-ignore
            dispatch(fetchDistributorCustomers());
        }
    }, []);

    return (
        <FormAutocomplete
            control={control}
            options={distributorCustomers}
            errors={errors}
            rules={rules}
            name={"employee.distributor"}
            label={t("Distributor")}
            renderOption={(props, option) => (
                <ListItem
                    component="li"
                    {...props}
                    secondaryAction={
                        visibleDistributorGroups.includes(group) ? (
                            <Typography variant="subtitle2">{option.dist_name}</Typography>
                        ) : null
                    }>
                    <ListItemText primary={option.name} />
                </ListItem>
            )}
        />
    );
};

export default DistributorSelection;
