import * as React from "react";
import { styled } from "@mui/material/styles";
import DeveloperBoardOffTwoToneIcon from "@mui/icons-material/DeveloperBoardOffTwoTone";
import { Typography } from "@mui/material";

const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
}));

export default function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <DeveloperBoardOffTwoToneIcon fontSize={"large"} />
            <Typography variant="h5" sx={{ mt: 1 }}>
                No Metrics Found
            </Typography>
        </StyledGridOverlay>
    );
}
