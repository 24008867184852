import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CycleSummaryPanel from "./CycleSummary/CycleSummaryPanel";
import CyclesByOperatorPanel from "./CyclesByOperator/CyclesByOperatorPanel";
import WasteProcessedPanel from "./WasteProcessed/WasteProcessedPanel";
import CycleReportContainer from "./CycleReport/CycleReportContainer";
import SterilizationInfoPanel from "./SterilizationInfo/SterilizationInfoPanel";
import BioChallengePanel from "./BioChallenge/BioChallengePanel";
import Grid from "@mui/material/Unstable_Grid2";
import DeviceDropdown from "../DeviceDropdown/DeviceDropdown";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "src/components/AuthService";
import {
    fetchBiochallengeInfo,
    fetchCustomerDevices,
    fetchDeviceCycles,
    isFacilityAirport,
    setDeviceConfigId,
    setSelectedDevice,
} from "./deviceDashboardSlice";

import Toolbar from "./Toolbar/Toolbar";
import NoCycles from "./NoCycles/NoCycles";
import MainCard from "src/components/material/Cards/MainCard";

const Auth = new AuthService();

const DeviceDashboard = (props) => {
    const {
        deviceConfigId,
        deviceSerial,
        noCyclesFound,
        complianceReport,
        customerDevices,
        // @ts-ignore
    } = useSelector((store) => store.devicedashboard);

    //TODO: Update summary dashboards

    document.title = `${deviceSerial} Dashboard`;
    const dispatch = useDispatch();
    // @ts-ignore
    const cyclesLoading = useSelector((store) => store.devicedashboard.loading.deviceCycles);

    const isAirport = useSelector((store) => isFacilityAirport(store.devicedashboard));

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCustomerDevices());
    }, []);

    useEffect(() => {
        dispatch(setDeviceConfigId(Number(props.match.params["device_id"]) || deviceConfigId));
        dispatch(setSelectedDevice(customerDevices.find((device) => device.id === deviceConfigId)));
    }, [customerDevices]);

    useEffect(() => {
        if (deviceConfigId) {
            // @ts-ignore
            dispatch(fetchDeviceCycles());
            // @ts-ignore
            dispatch(fetchBiochallengeInfo());
            dispatch(setSelectedDevice(customerDevices.find((device) => device.id === deviceConfigId)));
        }
    }, [deviceConfigId]);

    return (
        <MainCard>
            <Toolbar />
            {noCyclesFound ? (
                <NoCycles />
            ) : (
                <Grid container spacing={2}>
                    <Grid xs={12} lg={5} sx={{ display: "flex" }}>
                        <CycleSummaryPanel />
                    </Grid>
                    <Grid xs={12} md={6} lg={3} sx={{ display: "flex" }}>
                        <CyclesByOperatorPanel />
                    </Grid>
                    <Grid xs={12} md={6} lg={4} sx={{ display: "flex" }}>
                        <WasteProcessedPanel />
                    </Grid>
                    <Grid xs={12} lg={8} sx={{ display: "flex" }}>
                        <CycleReportContainer />
                    </Grid>
                    <Grid xs={12} lg={4} sx={{ display: "flex" }}>
                        <Stack direction={"column"} spacing={2} width={"100%"}>
                            <SterilizationInfoPanel />
                            {isAirport ? null : <BioChallengePanel />}
                        </Stack>
                    </Grid>
                    <Grid xs={4} sx={{ display: "flex" }}></Grid>
                </Grid>
            )}
        </MainCard>
    );
};

export default DeviceDashboard;
