import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography, useTheme } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCustomer,
    selectDistributor,
    selectFacility,
    selectSerial,
    setResendActivationCodeModal,
    setShowGetDeviceFilesModal,
} from "../deviceInfoSlice";
import DeviceActions from "./DeviceActions";
import Operators from "./Operators";
import GeneralInformation from "./GeneralInformation";
import BiochallengeInfo from "./BiochallengeInfo";
import PrvInformation from "./PrvInformation";
import GetDeviceFilesModal from "src/components/find_a_machine/GetDeviceFilesModal";
import { AuthReq } from "src/components/library/helpers";
import ResendActivationCodeModal from "src/components/view/devices/ResendActivationCodeModal";
import ResetInitializationKey from "./ResetInitializationKey";
import ResetBiochallengeIntervalDialog from "./ResetBiochallengeIntervalDialog";

const LocationConfiguration = (props) => {
    const { t } = useTranslation("translations");
    const dispatch = useDispatch();
    const {
        selectedDevice,
        selectedDeviceConfig,
        extraDeviceInfo,
        showGetDeviceFilesModal,
        resendActivationCodeModal,
    } = useSelector((store) => store.deviceInfo);
    const { user, group } = useSelector((store) => store.user);
    const serialNumber = useSelector(selectSerial);
    const distributor = useSelector(selectDistributor);
    const customer = useSelector(selectCustomer);
    const facility = useSelector(selectFacility);

    const theme = useTheme();

    return selectedDevice ? (
        <>
            <Grid container spacing={2}>
                <Grid id="column1" xs={12} md={6}>
                    <Stack spacing={2}>
                        <DeviceActions serialNumber={serialNumber} />
                        <Operators />
                    </Stack>
                </Grid>
                <Grid xs={12} md={6}>
                    <Stack spacing={2}>
                        <GeneralInformation
                            distributor={distributor}
                            customer={customer}
                            facility={facility}
                            extraDeviceInfo={extraDeviceInfo}
                            selectedDeviceConfig={selectedDeviceConfig}
                        />
                        <Stack spacing={2} justifyContent={"space-between"}>
                            <BiochallengeInfo />
                            <PrvInformation />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            {showGetDeviceFilesModal ? (
                <GetDeviceFilesModal
                    triggerGetDeviceFilesModal={(val) => dispatch(setShowGetDeviceFilesModal(val))}
                    showGetDeviceFilesModal={showGetDeviceFilesModal}
                    deviceConfigID={selectedDeviceConfig["id"]}
                    notifyFileRequestSuccess={() => {
                        alert("success!");
                    }}
                    notifyGenericFailure={() => {
                        alert("generic failure");
                    }}
                    userEmail={user.email}
                    selectedDeviceSerial={serialNumber}
                />
            ) : null}
            <AuthReq
                userGroup={group}
                requiredRoles={[
                    "SterilisSuperUsers",
                    "SterilisPortalUsers",
                    "FSEs",
                    "ExternalFSEs",
                    "FactoryWorkers",
                    "DistributorAdmins",
                    "DistributorFSEs",
                    "SterilisWasteTypeAdmin",
                ]}>
                {resendActivationCodeModal ? (
                    <ResetInitializationKey
                        modalDeviceID={selectedDeviceConfig?.device.id}
                        modalSerial={serialNumber}
                        modalCustomerName={customer?.customer_name}
                        modalFacilityName={facility?.facility_name}
                        resendActivationCodeModal={resendActivationCodeModal}
                        triggerResendActivationCodeModal={(val) => dispatch(setResendActivationCodeModal(val))}
                        fromTable={false}
                        notifyFailure={() => {
                            alert("generic failure");
                        }}
                    />
                ) : null}
            </AuthReq>
            <AuthReq userGroup={group} requiredRoles={["SterilisSuperUsers", "SterilisWasteTypeAdmin"]}>
                <ResetBiochallengeIntervalDialog modalDeviceID={selectedDeviceConfig?.device.id} />
            </AuthReq>
        </>
    ) : (
        <Stack spacing={2} padding={2}>
            <Typography variant="h6" color={"inherit"}>
                {t("device-info.helper_1", "To view a device's configuration, use the search bar above.")}
            </Typography>
            <Typography variant="h6" color={"inherit"}>
                {t(
                    "device-info.helper_2",
                    "You can find a device by searching for the device's serial number, the customer the device belongs to,  or the facility the device is located at."
                )}
            </Typography>
        </Stack>
    );
};

LocationConfiguration.propTypes = {};

export default LocationConfiguration;
