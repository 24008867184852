/*
*  Component which houses the Waste Processed chart, detailing the waste processed by a device/facility/customer
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";


class WasteProcessedSummary extends React.Component {
  state = {
    options: {}
  };

  componentDidMount() {
    this.setState({
      dashboardResized: this.props.dashboardResized
    });
    if (this.props.deviceCycles) {
      this.populateChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceCycles !== this.props.deviceCycles) {
      this.populateChart();
    }
  }

  checkForNonZeroArray = (array) => {
    const maxNumber = Math.max.apply(null, array);
    return (maxNumber !== 0);
  };

  /*
  *   All of this logic was copy & pasted from the v1 implementation.
  * */
  calculateWasteProcessedSummary = (deviceCycles) => {
    const {
      userTimezone,
      t
    } = this.props;
    const totalArray = []; // blank
    const unknownArray = []; // blank
    const dialysisArray = []; // Dialysis
    const mixedArray = [];  // Mixed
    const redBagArray = []; // Red Bags
    const sharpsArray = []; // Sharps
    const yellowBagArray = []; // Yellow bags
    const cannabisArray = []; // Cannabis Microbial Waste
    const cannabisProductArray = []; // Cannabis Product Waste
    const liquidsProductArray = []; // Liquids
    const softwasteProductArray = []; // Soft Lab Waste
    const yellowBagNoGrindArray = []; // Yellow bags no grind
    const xAxisDateArray = []; // Date array for x axis

    for (const cycle in deviceCycles) {
      if(deviceCycles[cycle]['cycle_state'] !== "Completed successfully"){
        continue;
      }
      const date = moment.utc(deviceCycles[cycle].time_started).tz(userTimezone).format("YYYY-MM-DD");

      if (!xAxisDateArray.includes(date)) {
        xAxisDateArray.push(date);
      }
     
      const indexOfRecentPush = xAxisDateArray.indexOf(date);

      let totalCycleCount = totalArray[indexOfRecentPush];
      totalCycleCount = totalCycleCount?totalCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
      totalArray[indexOfRecentPush] = totalCycleCount;

      if (deviceCycles[cycle]['waste_type'] === 'dialysis') {
        let currentCycleCount = dialysisArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        dialysisArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'mixed') {
        let currentCycleCount = mixedArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        mixedArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'red_bags') {
        let currentCycleCount = redBagArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        redBagArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'sharps') {
        let currentCycleCount = sharpsArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        sharpsArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'unknown') {
        let currentCycleCount = unknownArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        unknownArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'yellow_bags') {
        let currentCycleCount = yellowBagArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        yellowBagArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'yellow_bags_nogrind') {
        let currentCycleCount = yellowBagNoGrindArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        yellowBagNoGrindArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'cannabis') {
        let currentCycleCount = cannabisArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        cannabisArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'cannabis_product') {
        let currentCycleCount = cannabisProductArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        cannabisProductArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'liquids') {
        let currentCycleCount = liquidsProductArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        liquidsProductArray[indexOfRecentPush] = currentCycleCount;
      } else if (deviceCycles[cycle]['waste_type'] === 'soft_lab_waste'){
        let currentCycleCount = softwasteProductArray[indexOfRecentPush];
        currentCycleCount = currentCycleCount?currentCycleCount + deviceCycles[cycle].weight:deviceCycles[cycle].weight;
        softwasteProductArray[indexOfRecentPush] = currentCycleCount;
      }

    }

    //Since Highcharts requires array primitives and we need our X axis array and our Y axis arrays to line up index wise
    //We need to fill every Y axis array index with 0s where they do not currently have a value
    //The or syntax is just shorthand for if(!sharpsArray[day])
    for (let day in xAxisDateArray) {
      totalArray[day] = totalArray[day] || 0;
      sharpsArray[day] = sharpsArray[day] || 0;
      redBagArray[day] = redBagArray[day] || 0;
      mixedArray[day] = mixedArray[day] || 0;
      dialysisArray[day] = dialysisArray[day] || 0;
      unknownArray[day] = unknownArray[day] || 0;
      yellowBagArray[day] = yellowBagArray[day] || 0;
      yellowBagNoGrindArray[day] = yellowBagNoGrindArray[day] || 0;
      cannabisArray[day] = cannabisArray[day] || 0;
      cannabisProductArray[day] = cannabisProductArray[day] || 0;
      liquidsProductArray[day] = liquidsProductArray[day] || 0;
      softwasteProductArray[day] = softwasteProductArray[day] || 0;
    }

    const wasteSummarySeries = [];
    if (this.checkForNonZeroArray(totalArray)) {
      wasteSummarySeries.push({
        name: t('Total waste processed'),
        type: 'column',
        color: '#ff9492',
        data: totalArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        },
        visible: false
      })
    }

    if (this.checkForNonZeroArray(sharpsArray)) {
      wasteSummarySeries.push({
        name: t('Sharps'),
        type: 'column',
        color: '#ff9492',
        data: sharpsArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }
    if (this.checkForNonZeroArray(redBagArray)) {
      wasteSummarySeries.push({
        name: t('Red bags'),
        type: 'column',
        color: '#cb0000',
        data: redBagArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }
    if (this.checkForNonZeroArray(dialysisArray)) {
      wasteSummarySeries.push({
        name: t('Dialysis'),
        type: 'column',
        color: '#3a97ff',
        data: dialysisArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }
    if (this.checkForNonZeroArray(mixedArray)) {
      wasteSummarySeries.push({
        name: t('Mixed'),
        type: 'column',
        color: '#ffa955',
        data: mixedArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }
    if (this.checkForNonZeroArray(unknownArray)) {
      wasteSummarySeries.push({
        name: t('Waste Processed'),
        type: 'column',
        data: unknownArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }
    if (this.checkForNonZeroArray(yellowBagArray)) {
      wasteSummarySeries.push({
        name: t('Yellow bags'),
        type: 'column',
        color: '#ffd429',
        data: yellowBagArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }
    if (this.checkForNonZeroArray(yellowBagNoGrindArray)) {
      wasteSummarySeries.push({
        name: t('Yellow bags no grind'),
        type: 'column',
        color: '#64b5f6',
        data: yellowBagNoGrindArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }
    if (this.checkForNonZeroArray(cannabisArray)) {
      wasteSummarySeries.push({
        name: t('Cannabis Microbial Waste'),
        type: 'column',
        color: '#21cc1b',
        data: cannabisArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }

    if (this.checkForNonZeroArray(cannabisProductArray)) {
      wasteSummarySeries.push({
        name: t('Cannabis Product Waste'),
        type: 'column',
        color: '#156813',
        data: cannabisProductArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }

    if (this.checkForNonZeroArray(liquidsProductArray)) {
      wasteSummarySeries.push({
        name: t('Liquids'),
        type: 'column',
        color: '#1d2e68',
        data: liquidsProductArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }

    if (this.checkForNonZeroArray(softwasteProductArray)) {
      wasteSummarySeries.push({
        name: t('Soft Lab Waste'),
        type: 'column',
        color: '#8e24aa',
        data: softwasteProductArray.slice(0),
        tooltip: {
          valueSuffix: `${this.props.weight_unit}`
        }
      })
    }

    const comp = this;

    const options = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          position: {
              verticalAlign: 'bottom',
              x: 0,
              y: -140
          }
        },
      },
      credits: {
        enabled: false
      },
      title: {
        text: t('Waste Processed'),
        style: {
          color: '#009FC2',
          fontSize: '20px',
          fontFamily: 'Arial'
        }
      },
      xAxis: [{
        categories: xAxisDateArray,
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: `{value} ${this.props.weight_unit}`,
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: t('Weight'),
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      plotOptions: {
        series: {
            events: {
                legendItemClick: function() {
                  if (this.name === t("Total waste processed")){
                    return false;
                  }
                }
            }
        }
      },
      legend: {
        labelFormatter: function() {
          var total = 0;
          for(var i=this.yData.length; i--;) { total += this.yData[i]; };
          if (this.name === t("Total waste processed")){
            return '<b style="color:black;">' + this.name + " : " + Math.round(total) + comp.props.weight_unit + '</b>';
          }
          else if (this.name === t("Liquids")){
            return this.name + " : " + Math.round(total) + comp.props.weight_unit + " ( "  + Math.round(total * 453.59237) + " ml )"
          }
          else{
            return this.name + " : " + Math.round(total) + comp.props.weight_unit;
          }
       },
        layout: 'vertical',
        floating: true,
        align: 'left',
        verticalAlign: 'top',
        y: 9,
        backgroundColor: 'rgba(0,0,0,0)'
      },
      series: wasteSummarySeries
    };
    this.setState({
      options
    });
  };

  populateChart = () => {
    const {
      deviceCycles
    } = this.props;
    this.calculateWasteProcessedSummary(deviceCycles);
  };


  render() {
    return (
      <div className={this.props.graphContainerClass}>
        <div id='wasteProcessedSummaryChart' className={this.props.className}>
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            updateArgs={[true, true, true]}
          />
        </div>
      </div>
    )
  }
}

export default translate('translations')(WasteProcessedSummary);
