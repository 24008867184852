/*
*  Create a Machine component, used to instantiate a new machine into our system
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import {composeHoc} from "../library/helpers";
import withAuth from "../withAuth";
import SimpleLoader from '../SimpleLoader';
import {Card, Button, Icon} from 'semantic-ui-react'
import '../../css/CreateAMachine.css';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import AuthService from "../AuthService";
import * as Sentry from "@sentry/browser";
import moment from "moment";

const Auth = new AuthService();

class CreateAMachine extends React.Component {
  state = {
    appLoading: false,
    configOptions: {},
    createdMachinesConfig: {},
    initialLoad: false,
    dropdownOptions: [],
    selectedFactory: null,
    desiredSerial: null,
    validationError: true,
    activationCode: false,
    usedSerials: {},
    validationErrorDict: {},
  };

  notifyFailure = () => toast(`Failed to create this device. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  notifyLoadFailure = () => toast(`Failed to fetch required resources. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  componentDidMount() {
    //this.fetchDropdownData();
    document.title = 'Create a Device';
    this.fetchConfigOptions();
    this.createPvSensorConfigDropdown();
  };

  createPvSensorConfigDropdown = () => {
    Auth.fetch('/api/pressure-sensor/', {
      method: 'GET'
    }).then(data => {
      const pvSensorDropdown = data.map(sensor => {
        return {
          key: sensor['id'],
          value: sensor['id'],
          text: `${sensor['manufacturer']} - ${sensor['model']}`
        }
      });

      const pvSensorOptions = data.map(sensor => {
        return sensor
      })

      this.setState({
        pvSensorDropdownOptions: pvSensorDropdown,
        pvSensors: pvSensorOptions
      })
    })
  }

  fetchConfigOptions = () => {
    this.setState({
      appLoading: true,
      initialLoad: true,
    });
    Auth.fetch(`/api/device-config/`, {
      method: 'OPTIONS'
    }).then(data => {
      const configOptions = Object.entries(data['config_item_metadata']).reduce((acc, [key, value]) => {
        if ((value['config_item']) || (key.match(/^vessel_pressure_sensor.*\b/g))) {
          acc[key] = value;
        }
        return acc;
      }, {});
      Auth.fetch(`/api/get-create-a-device-info/`, {}).then(data => {
        const {
          facilities,
          taken_serials: takenSerials
        } = data;

        const factories = facilities.map(facility => {
          return {
            key: facility.id,
            value: facility.id,
            text: facility.facility_name
          }
        });

        const createdMachinesConfig = Object.entries(configOptions).reduce((acc, [key, value]) => {
          if (key === 'vessel_pressure_sensor'){
            acc[key] = "1";
          }
          else if(key === 'vessel_pressure_sensor_id'){
            acc[key] = 1;
          }
          else{
            acc[key] = value['default'];
          }
          return acc;
        }, {});

        this.setState({
          dropdownOptions: factories,
          selectedFactory: data['default_facility_id'],
          createdMachinesConfig,
          configOptions,
          usedSerials: takenSerials,
          appLoading: false,
          initialLoad: false
        });
      }).catch(err => {
        this.notifyLoadFailure();
        Sentry.captureException(err);
        this.setState({
          appLoading: false,
          initialLoad: false
        })

      });
    }).catch(err => {
      this.notifyLoadFailure();
      Sentry.captureException(err);
      this.setState({
        appLoading: false,
        initialLoad: false
      })

    });
  };


  handleConfigItemChange = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    const configOptions = this.state.configOptions;


    if (configOptions[name].type === 'integer' || configOptions[name].type === 'float') {
      if (value > configOptions[name].max_value || value < configOptions[name].min_value) {
        this.setState({validationError: true});
      } else {
        this.setState({validationError: false});
      }
    }
    this.editConfigItemsValue(event);
  };

  editConfigItemsValue = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (event.currentTarget.type === 'checkbox') {
      this.setState(state => ({
        createdMachinesConfig: {
          ...state.createdMachinesConfig,
          [name]: !state.createdMachinesConfig[name],
        }
      }))
    } else {
      if (!value) {
			//If the value is empty, it's because the user backspace'd out of an input field, so we need to reset state and give them them the placeholder vlue
			const configOptions = this.state.configOptions[name];
			const defaultValue = configOptions.default_value;

			this.setState({
				createdMachinesConfig: {
					...this.state.createdMachinesConfig,
					[name]: defaultValue,
				},
				validationError: false,
			});
		} else {
			if (name === "drain_path") {
				if (value === "big_lower_only") {
					//If you ever want to mutate the value inside of an object in state,
					// you'll need to take a copy of the current obj, mutate it, and set org obj to the mutated obj
					const updatedSelectedDeviceConfig = this.state.createdMachinesConfig;
					updatedSelectedDeviceConfig.short_drain_secs = 0;
					updatedSelectedDeviceConfig.stag_recovery_secs = 20;
					updatedSelectedDeviceConfig.pres_lim_max_count = 5;

					this.setState({
						createdMachinesConfig: {
							...updatedSelectedDeviceConfig,
						},
					});
				}
				if (value === "small") {
					const updatedSelectedDeviceConfig = this.state.createdMachinesConfig;
					updatedSelectedDeviceConfig.short_drain_secs = 40;
					updatedSelectedDeviceConfig.stag_recovery_secs = 20;
					updatedSelectedDeviceConfig.pres_lim_max_count = 5;

					this.setState({
						createdMachinesConfig: {
							...updatedSelectedDeviceConfig,
						},
					});
				}
				if (value === "big_drain") {
					const updatedSelectedDeviceConfig = this.state.createdMachinesConfig;
					updatedSelectedDeviceConfig.short_drain_secs = 0;
					updatedSelectedDeviceConfig.stag_recovery_secs = 7;
					updatedSelectedDeviceConfig.pres_lim_max_count = 10;

					this.setState({
						createdMachinesConfig: {
							...updatedSelectedDeviceConfig,
						},
					});
				}
			} //if drain path

			// If the changed value is to the vessel_pressure_sensor,
			// set the value of vessel_pressure_sensor_id.
			if (name === "vessel_pressure_sensor") {
				const updatedSelectedDeviceConfig = this.state.createdMachinesConfig;
				updatedSelectedDeviceConfig.vessel_pressure_sensor_id = parseInt(value, 10);

				this.setState({
					createdMachinesConfig: {
						...updatedSelectedDeviceConfig,
					},
				});
			}

			this.setState({
				createdMachinesConfig: {
					...this.state.createdMachinesConfig,
					[name]: value,
				},
			});
		}
    }
    // event.currentTarget.value.length === 0 ? this.setState({ [name] : null}) : this.setState({ [name] : value});
  };


  handleFacilityChange = (event) => {
    this.setState({
      selectedFactory: event.currentTarget.value
    })
  };
  handleSerialChange = (event) => {
    const value = event.currentTarget.value;

    if (this.state.usedSerials.includes(value)) { //first check if the serial is used
      this.setState({
        validationError: true,
        takenSerial: true
      });
    } else {
      if (value.match(/^\d{3}-\d{5}R\d$/)) { // if it's not used, check that it passes the regex requirement
        this.setState({
          serialTooLong: false,
          validationError: false,
          takenSerial: false
        })
      } else {
        if (!value.match(/^\d{3}-\d{5}$/)) {
          this.setState({
            serialTooLong: true,
            validationError: true,
            takenSerial: false
          })
        } else {
          this.setState({
            serialTooLong: false,
            validationError: false,
            takenSerial: false
          })
        }
      }
    }

    this.setState({
      desiredSerial: value
    })
  };


  submitMachine = (e) => {
    e.preventDefault();
    const createdMachinesConfig = {...this.state.createdMachinesConfig};
    const selectedFactory = this.state.selectedFactory;
    const desiredSerial = this.state.desiredSerial;
    const activationCode = Math.floor(1000 + Math.random() * 9000);


    const createdDevice = {
      'serial_number': desiredSerial,
      'reset_password_key': activationCode,
      'reset_password_request_time': moment().format(),
    };
    this.setState({appLoading: true});

    Auth.fetch(`/api/device/`, {
      method: 'POST',
      body: JSON.stringify(createdDevice)
    }).then(data => {
      // device ID is the id of the response from post on /api/device/
      createdMachinesConfig['device_id'] = data['id'];
      createdMachinesConfig['facility_id'] = selectedFactory;
      Auth.fetch(`/api/device-config/`, {
        method: 'POST',
        body: JSON.stringify(createdMachinesConfig)
      }).then(data => {
        this.setState({
          appLoading: false,
          activationCode: activationCode
        });
      }).catch(err => {
        Sentry.captureException(err);
        this.setState({appLoading: false});
        this.notifyFailure();
      });

    }).catch(err => {
      Sentry.captureException(err);
      this.setState({appLoading: false});
      this.notifyFailure();
    });
  };

  resetPage = () => {
    window.location.reload()
  };

  validateOnBlur = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    const configOptions = this.state.configOptions;

    if (configOptions[name].type === 'integer' || configOptions[name].type === 'float') {
      if (!value) {
        this.setState({validationError: false});
      } else {
        if (value > configOptions[name].max_value || value < configOptions[name].min_value) {
          const validationErrorDict = this.state.validationErrorDict;
          validationErrorDict[name] = {
            min_value: configOptions[name].min_value,
            max_value: configOptions[name].max_value,
          };

          this.setState({
            validationError: true,
            validationErrorDict: validationErrorDict
          });
        } else {
          const validationErrorDict = this.state.validationErrorDict;

          if (validationErrorDict[name]) {
            delete validationErrorDict[name];
          }

          this.setState({validationError: false});
        }
      }
    }
  };

  createConfigDisplay = (key, configItem) => {
    const {
      validationErrorDict,
      createdMachinesConfig,
      pvSensorDropdownOptions
    } = this.state;
    const configDefaultValue = configItem['default'];

    if (configItem.type === 'string') {
      return (
        //strings
        <div className='display-config-item flex-block-item' key={key}>
          <label htmlFor={key}>{configItem.label}</label>
          <span className='flex-block-item-right-justify'>
            <input
              placeholder={configDefaultValue}
              onChange={this.handleConfigItemChange}
              type="text"
              name={key}
              id={key}
            />
          </span>
        </div>
      )
    } else if (configItem.type === 'integer' || configItem.type === 'float') {
      return (
        //floats and ints
        <div className='display-config-item flex-block-item' key={key}>
          <label htmlFor={key}>{configItem.label}</label>
          <span className='flex-block-item-right-justify'>
            <input min={configItem.min_value}
                   max={configItem.max_value}
                   placeholder={configDefaultValue}
                   onChange={this.handleConfigItemChange}
                   type="number"
                   onBlur={this.validateOnBlur}
                   name={key}
                   id={key}
                   className='right-input'
            />
          </span>
          {
            validationErrorDict[key] ? (
              <div className='red-text'>
                {configItem.label} must be
                between {validationErrorDict[key].min_value} and {validationErrorDict[key].max_value}
              </div>
            ) : null
          }
        </div>
      )
    } else if (configItem.type === 'boolean') {
      const checked = createdMachinesConfig[key] ? 'checked' : null;
      return (
        <div className='display-config-item flex-block-item' key={key}>
          <label htmlFor={key}>{configItem.label}</label>
          <span className='flex-block-item-right-justify'>
            <input
              checked={!!checked}
              onChange={this.handleConfigItemChange}
              name={key}
              type="checkbox"
              value={createdMachinesConfig[key]}
              id={key}
            />
          </span>
        </div>
      )
    } else if (configItem.type === 'choice') {
      return <div className='display-config-item flex-block-item' key={key}>
        {configItem.label}
        <span className='flex-block-item-right-justify'>
          <select
            onChange={this.handleConfigItemChange}
            defaultValue={configDefaultValue}
            name={key}
            id={key}
          >
            {
              configItem.choices.map((choice, innerIndex) => {
                const optKey = `${key}-${innerIndex}`;
                return (
                  <option key={optKey} value={choice.value}>
                    {choice.display_name}
                  </option>
                )
              })
            }
        </select>
      </span>
      </div>

    } else if (configItem.type === 'nested object') {
      return <div className='display-config-item flex-block-item' key={key}>
        {configItem.label}
        <span className='flex-block-item-right-justify'>
            <select onChange={this.handleConfigItemChange}
                    defaultValue='1'
                    name={key}
                    id={key}>
              {
                pvSensorDropdownOptions.map((option) => {
                  return (
                      <option key={option.key} value={option.value}>
                        {option.text}
                      </option>
                  )
                })
              }
            </select>
        </span>
      </div>
    }
  };


  render() {
    const {
      appLoading,
      initialLoad,
      configOptions,
      dropdownOptions,
      activationCode,
      takenSerial,
      desiredSerial,
    } = this.state;
    const {t} = this.props;
    return (
		<Card className="create-a-machine-card">
			{appLoading ? <SimpleLoader /> : null}
			<Card.Header style={{ margin: "10px" }}>
				<h2>{t("create-a-device.header", "Create a Device")}</h2>
			</Card.Header>

			{activationCode ? (
				<Card.Content className="card-body">
					<div className="success-div">
						<div>
							<div>You successfully created a device with serial {desiredSerial}</div>
							To complete setup, please enter the following code on the device:{" "}
							<span id="activationCode" className="mint">
								{activationCode}
							</span>
						</div>
						<div className="hyperlink-div">
							<div className="top-success-div">
								<div>
									<Link to="/create/device" onClick={this.resetPage}>
										Click here to create another device
									</Link>
								</div>
							</div>
							<div>
								<Link to={`/find-a-machine/?serial=${desiredSerial}`}>
									Click here to visit the Device Information page for {desiredSerial}
								</Link>
							</div>
						</div>
					</div>
				</Card.Content>
			) : (
				<Card.Content className="card-body">
					{/*<ToastContainer />*/}
					{!initialLoad && ( //if app is not loading, display desc
						<form className="create-a-machine-form">
							<div className="software-affecting">
								<div className="desc">
									<h3>
										{t(
											"create-a-device.software-affecting.header",
											"Software Affecting Configuration Items"
										)}
									</h3>
									<h5>
										<Trans ns="translations" i18nKey="create-a-device.software-affecting.1">
											These values are <span className="red-text">sent to the device.</span>
										</Trans>
									</h5>
									<h5>
										{t(
											"create-a-device.software-affecting.2",
											"It is essential to the device's health and performance that the set values are correct."
										)}
									</h5>
									<h5>
										{t(
											"create-a-device.software-affecting.3",
											"A set of defaults have been provided, but please check that they are correct."
										)}
									</h5>
								</div>
								<div className="items">
									<div className="display-config-item flex-block-item">
										<label htmlFor="serial">{t("Serial number")}</label>
										<span className="flex-block-item-right-justify">
											<input
												placeholder="xxx-xxxxx"
												onChange={this.handleSerialChange}
												name="serial"
												id="serial"
												pattern="^\d{3}-\d{5}$"
												required
												className="right-input"
												title={t(
													"create-a-device.serial_label",
													"Serials must follow the format of three digits-five digits (ie 123-45678)"
												)}
												type="text"
											/>
										</span>
										{takenSerial && (
											<div className="red-text float-right slight-margin-right">
												<Trans ns="translations" i18nKey="create-a-device.used_serial">
													The serial {{ desiredSerial }} is already in use.
												</Trans>
											</div>
										)}
									</div>

									<div className="display-config-item flex-block-item">
										Factory
										<span className="flex-block-item-right-justify">
											<select
												onChange={this.handleFacilityChange}
												defaultValue={this.state.selectedFactory} //default is PPI right now
												name="create-a-machine-dropdown"
												id="create-a-machine-dropdown"
											>
												{dropdownOptions.map((choice, optKey) => {
													return (
														<option key={optKey} value={choice.value}>
															{choice.text}
														</option>
													);
												})}
											</select>
										</span>
									</div>

									{Object.keys(configOptions).map((item) => {
										if (configOptions[item]["affects_software"] === true) {
											return this.createConfigDisplay(item, configOptions[item]);
										}
										return false;
									})}
								</div>
							</div>

							<div className="non-software-affecting">
								<div className="desc">
									<h3>
										{t(
											"create-a-device.non-software-affecting.header",
											"Non-Software Affecting Configuration Items"
										)}
									</h3>
									<h5>
										<Trans ns="translations" i18nKey="create-a-device.non-software-affecting.1">
											These values are <span className="red-text">not</span> sent to the device.
										</Trans>
									</h5>
									<h5>
										{t(
											"create-a-device.non-software-affecting.2",
											"These configuration items are more of a means of record keeping than anything else."
										)}
									</h5>
									<h5>
										{t(
											"create-a-device.non-software-affecting.3",
											"Please ensure the values are as correct as possible, but it is not essential to the device's health."
										)}
									</h5>
								</div>
								<div className="items">
									{Object.keys(configOptions).map((item) => {
										if (
											configOptions[item]["affects_software"] === false ||
											item === "vessel_pressure_sensor"
										) {
											return this.createConfigDisplay(item, configOptions[item]);
										}
										return false;
									})}
								</div>
							</div>
							<Button
								id="createAMachineButton"
								className="submit-machine"
								disabled={
									this.state.validationError || this.state.takenSerial || this.state.serialTooLong
								}
								onClick={this.submitMachine}
								primary
							>
								Create device
							</Button>
						</form>
					)}
				</Card.Content>
			)}
		</Card>
	);
  }
}

export default composeHoc(translate('translations'), withAuth(
  ['SterilisSuperUsers', 'SterilisPortalUsers', 'FactoryWorkers','SterilisWasteTypeAdmin'], 'internalPage'
))(CreateAMachine);
