/*
* Wrapper component for Release notes
* Main purpose is to serve <ReleaseNotes/> with or without the side menu, depending on which group the user is
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {composeHoc, isGroupNotCustomer} from "../library/helpers";
import { withTranslation as translate} from "react-i18next";
import withAuth from "../withAuth";
import ReleaseNotes from "./ReleaseNotes";

const ReleaseNotesWrapper = (props) => (
	<React.Fragment>
		{isGroupNotCustomer(props.group) ? (
			<ReleaseNotes user={props.user} group={props.group} />
		) : (
			<ReleaseNotes user={props.user} group={props.group} />
		)}
	</React.Fragment>
);

export default composeHoc(translate('translations'),
  withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs',
    'FactoryWorkers', 'CustomerPortalUsers', 'DistributorAdmins', 'DistributorReadOnly',
    'DistributorFSEs','SterilisWasteTypeAdmin', 'FacilityAdmin'], 'releaseNotesPage'))(ReleaseNotesWrapper);
