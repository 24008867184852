/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { exists } from "src/components/library/helpers";
import { dioPlotBands, getNotificationPlotLines, inRangeInclusive } from "../CycleStats/ChartHelpers";
import BurninTestChart from "./BurninTestChart";
import SimpleBackdrop from "src/components/material/SimpleBackdrop/SimpleBackdrop";
import BurninTest7Results from "./BurninTest7Results";
import BurninItem from "./BurninItem";
import CycleStats from "../CycleStats/CycleStats";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Card,
    CardContent,
    CardHeader,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Box,
    Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AuthService from "src/components/AuthService";
import { DateTime } from "luxon";
import { getStatePlotBands, getDigitalOutputPlotBands } from "../CycleStats/ChartHelpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchTest7Results, setLatestTestData, setTestLoading, showTest7Button } from "./bitSlice";

const Auth = new AuthService();

const BurninStats = (props) => {
    // @ts-ignore
    const { bitTests, deviceId } = useSelector((store) => store.bit);
    const latestTest = useSelector((store) => store.bit.bitTests[props.testNumber].latest);
    const testSuccess = useSelector((store) => store.bit.bitTests[props.testNumber].result.success);
    const dispatch = useDispatch();

    const [finishedLoading, setFinishedLoading] = useState(false);
    const [remainingTests, setRemainingTests] = useState({});
    const [timeList, setTimeList] = useState([]);
    const [currentList, setCurrentList] = useState([]);
    const [tvList, setTvList] = useState([]);
    const [thList, setThList] = useState([]);
    const [rtList, setRtList] = useState([]);
    const [paList, setPaList] = useState([]);
    const [pvList, setPvList] = useState([]);
    const [psList, setPsList] = useState([]);
    const [test7CycleMetrics, setTest7CycleMetrics] = useState({});
    const [dutyCycleList, setDutyCycleList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [dioList, setDioList] = useState([]);
    const [infoConstants, setInfoConstants] = useState([]);
    const [chartLoading, setChartLoading] = useState(false);
    const [plotPointsList, setPlotPointsList] = useState([]);
    const [test7plotPointsList, setTest7PlotPointsList] = useState([]);
    const showTest7Flag = useSelector((state) => showTest7Button(state));
    const findLatestBurninTest = (burninTests) => {
        if (burninTests.length === 0) {
            return {};
        } else if (burninTests.length === 1) {
            return burninTests[0];
        } else if (burninTests.length > 1) {
            return burninTests.reduce((a, b) => {
                const aDate = DateTime.fromISO(a.start_time);
                const bDate = DateTime.fromISO(b.start_time);
                const isALogLatest = aDate > bDate;
                return isALogLatest ? a : b;
            });
        }
    };

    const handleBit7Click = (deviceId) => {
        dispatch(setTestLoading({ test: 7, value: true }));
        const lastTest = Object.keys(bitTests)
            .map((key) => bitTests[key].latest)
            .reduce((a, b) => {
                const aTime = DateTime.fromISO(a.end_time);
                const bTime = DateTime.fromISO(b.end_time);
                if (!b.end_time) {
                    return a;
                }
                return aTime >= bTime ? a : b;
            }).end_time;
        // @ts-ignore
        dispatch(fetchTest7Results({ deviceId: deviceId, lastTest: lastTest }))
            .unwrap()
            .then((data) => dispatch(setLatestTestData({ test: 7, result: data })));
    };

    const resetChartSeries = () => {
        setTimeList([]);
        setCurrentList([]);
        setTvList([]);
        setThList([]);
        setRtList([]);
        setPaList([]);
        setPvList([]);
        setPsList([]);
        setTest7CycleMetrics({});
    };

    const getMetrics = (expanded, latestTest) => {
        if (!expanded) {
            return;
        }
        setChartLoading(true);

        if (props.testNumber === 7) {
            const cachedData = test7CycleMetrics[latestTest.id];
            if (cachedData) {
                setChartLoading(false);
                return;
            }

            Auth.fetch(`/metrics/steam-metric?steam_cycle_id=${latestTest.id}`, {
                method: "GET",
            })
                .then((data) => {
                    const id = latestTest.id;
                    let metrics = test7CycleMetrics;
                    metrics[id] = {};

                    const times = data.map((point) => Date.parse(point.time));
                    setTimeList(times);
                    metrics[id].timeList = times;

                    const ampSeries = data.map((point) => [Date.parse(point.time), point.amps]);
                    setCurrentList(ampSeries);
                    metrics[id].currentList = ampSeries;

                    const tvSeries = data.map((point) => [Date.parse(point.time), point.vessel_temp]);
                    setTvList(tvSeries);
                    metrics[id].tvList = tvSeries;
                    const thSeries = data.map((point) => [Date.parse(point.time), point.heater_temp]);
                    setThList(thSeries);
                    metrics[id].thList = thSeries;

                    const runawayTempSeries = data.map((point) => [Date.parse(point.time), point.runaway_temp]);
                    setRtList(runawayTempSeries);
                    metrics[id].rtList = runawayTempSeries;

                    const paSeries = data.map((point) => [Date.parse(point.time), point.accumulator_pressure]);
                    setPaList(paSeries);
                    metrics[id].paList = paSeries;

                    const pvSeries = data.map((point) => [Date.parse(point.time), point.vessel_pressure]);
                    setPvList(pvSeries);
                    metrics[id].pvList = pvSeries;

                    const psSeries = data.map((point) => [Date.parse(point.time), point.seal_pressure]);
                    setPsList(psSeries);
                    metrics[id].psList = psSeries;

                    const dutyCycleSeries = data.map((point) => [Date.parse(point.time), point.duty_cycle]);
                    setDutyCycleList(dutyCycleSeries);
                    metrics[id].dutyCycleList = dutyCycleSeries;

                    const stateSeries = data.map((point) => [Date.parse(point.time), point.state]);
                    setStateList(getStatePlotBands(stateSeries));
                    metrics[id].stateList = stateSeries;

                    const dioSeries = data.map((point) => [Date.parse(point.time), point.digital_output]);
                    const dioPlotBands = dioPlotBands(dioSeries, props.digitalOutput);
                    setDioList(dioPlotBands);
                    metrics[id].dioList = dioPlotBands;

                    setChartLoading(false);

                    setTest7CycleMetrics(metrics);
                })
                .catch((err) => {
                    console.log("error caught");
                    setChartLoading(false);
                });
        } else {
            if (latestTest["steam_cycle"]) {
                const cycleid = latestTest["steam_cycle"].id;
                Auth.fetch(`/metrics/steam-metric?steam_cycle_id=${cycleid}`, {
                    method: "GET",
                })
                    .then((data) => {
                        // data.info_constants = getNotificationPlotLines(props.selectedCycle.notifications)
                        const times = data.map((point) => Date.parse(point.time));
                        setTimeList(times);

                        const ampSeries = data.map((point) => [Date.parse(point.time), point.amps]);
                        setCurrentList(ampSeries);

                        const tvSeries = data.map((point) => [Date.parse(point.time), point.vessel_temp]);
                        setTvList(tvSeries);

                        const thSeries = data.map((point) => [Date.parse(point.time), point.heater_temp]);
                        setThList(thSeries);

                        const runawayTempSeries = data.map((point) => [Date.parse(point.time), point.runaway_temp]);
                        setRtList(runawayTempSeries);

                        const paSeries = data.map((point) => [Date.parse(point.time), point.accumulator_pressure]);
                        setPaList(paSeries);

                        const pvSeries = data.map((point) => [Date.parse(point.time), point.vessel_pressure]);
                        setPvList(pvSeries);

                        const psSeries = data.map((point) => [Date.parse(point.time), point.seal_pressure]);
                        setPsList(psSeries);

                        const dutyCycleSeries = data.map((point) => [Date.parse(point.time), point.duty_cycle]);
                        setDutyCycleList(dutyCycleSeries);

                        const stateSeries = data.map((point) => [Date.parse(point.time), point.state]);
                        setStateList(getStatePlotBands(stateSeries));

                        const dioSeries = data.map((point) => [Date.parse(point.time), point.digital_output]);
                        setDioList(dioPlotBands(dioSeries, props.digitalOutput));

                        setInfoConstants(getNotificationPlotLines(props.selectedCycle.notifications));

                        setChartLoading(false);
                    })
                    .catch((err) => {
                        console.log("error caught");
                        setChartLoading(false);
                    });
            } else if (latestTest["grind_cycle"]) {
                const cycleid = latestTest["grind_cycle"].id;
                const dataAvailable = plotPointsList.find((cycleObj) => cycleObj.id === cycleid);
                if (dataAvailable) {
                    setChartLoading(false);
                    return;
                }
                Auth.fetch(`/metrics/grind-metric?grind_cycle_id=${cycleid}`, {
                    method: "GET",
                })
                    .then((data) => {
                        const times = data.map((point) => Date.parse(point.time));
                        setTimeList(times);

                        const ampSeries = data.map((point) => [Date.parse(point.time), point.amps]);
                        setCurrentList(ampSeries);

                        const dutyCycleSeries = data.map((point) => [Date.parse(point.time), point.duty_cycle]);
                        setDutyCycleList(dutyCycleSeries);

                        const paSeries = data.map((point) => [Date.parse(point.time), point.accumulator_pressure]);
                        setPaList(paSeries);

                        const stateSeries = data.map((point) => [Date.parse(point.time), point.state]);
                        setStateList(getStatePlotBands(stateSeries));

                        const dioSeries = data.map((point) => [Date.parse(point.time), point.digital_output]);
                        setDioList(dioPlotBands(dioSeries, props.digitalOutput));

                        setInfoConstants(getNotificationPlotLines(props.selectedCycle.notifications));

                        setChartLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setChartLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        const { burninTestList } = props;
        const latestTest = findLatestBurninTest(burninTestList);
        const remainingTests = burninTestList.filter((test) => {
            return test.start_time !== latestTest.start_time;
        });

        dispatch(setLatestTestData({ test: props.testNumber, result: latestTest }));

        setRemainingTests(remainingTests);

        setFinishedLoading(true);

        return () => {
            resetChartSeries();
        };
    }, [props.burninTestList]);

    const calculateBurninSuccess = (burninTestValues) => {
        const { abnormalValuesForBurninTest } = props;
        let isSuccessful = true;
        Object.keys(abnormalValuesForBurninTest).forEach((key) => {
            const abnormalDefinition = abnormalValuesForBurninTest[key];
            const burninValue = burninTestValues[abnormalDefinition["value_name"]];
            const conditions = [exists(burninValue), isSuccessful, exists(abnormalDefinition)];

            if (conditions.every((condition) => condition)) {
                if (abnormalDefinition["score_coefficient"] > 0) {
                    isSuccessful = inRangeInclusive(
                        burninValue,
                        abnormalDefinition["minimum_value"],
                        abnormalDefinition["maximum_value"]
                    );
                }
            } else {
                isSuccessful = false;
            }
        });
        return isSuccessful;
    };

    const noBurninTestFound = () => {
        return (
            <Typography variant="body1" color="error" sx={{ fontWeight: "bold", p: 4 }}>
                No data available for Burnin Test {props.testNumber}
            </Typography>
        );
    };

    const burninTestState = (isSuccessful) => {
        return (
            <span className={`${isSuccessful ? "green-text" : "red-text"}`}>{isSuccessful ? "Success" : "Fail"}</span>
        );
    };

    const datetimeFormat = { ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: "short" };

    const remainingTestHeader = () => {
        const { testNumber } = props;
        const conditionalS = remainingTests.length > 1 ? "s" : "";
        return `Older Test ${testNumber}${conditionalS} found in parsed log. Click to show ${remainingTests.length} earlier test${conditionalS}`;
    };

    return (
        <React.Fragment>
            {finishedLoading && props.burnInTab === props.index && (
                <Box
                    pb={0.1}
                    hidden={props.burnInTab !== props.index}
                    position="relative"
                    minHeight="40vh"
                    key={"test-contianer"}>
                    {props.loadingBurninData && <SimpleBackdrop loaderText="Preparing Burnin View..." />}
                    {Object.keys(latestTest).length > 0 ? (
                        <React.Fragment>
                            {props.testNumber === 7 ? (
                                <React.Fragment>
                                    <Box display="flex" justifyContent="space-around" mt={2} key="wrapper">
                                        <Box width="45%" key="test-summary-box">
                                            <Card sx={{ margin: 1 }} variant="outlined">
                                                <CardHeader
                                                    title={
                                                        <Typography variant="h5" color="primary">
                                                            {props.testTitle}
                                                        </Typography>
                                                    }
                                                    subheader={burninTestState(testSuccess)}
                                                />
                                                <CardContent sx={{ py: 0 }}>
                                                    <List>
                                                        <ListItem divider={true} key={`${props.testNumber}-summary`}>
                                                            <ListItemButton>
                                                                <ListItemText primary={"Test Date"} />
                                                                <ListItemText
                                                                    primary={DateTime.fromISO(
                                                                        latestTest.start_time
                                                                    ).toLocaleString(datetimeFormat)}
                                                                    sx={{ textAlign: "right" }}
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </List>
                                                    {props.burninStatInternalNames.map((name, idx) => {
                                                        return (
                                                            <BurninTest7Results
                                                                test={latestTest}
                                                                name={name}
                                                                idx={idx}
                                                                key={`${name}-${idx}`}
                                                                subBurninStatInternalNames={latestTest.steam_cycle}
                                                                abnormalValuesForBurninTest={
                                                                    props.abnormalValuesForBurninTest
                                                                }
                                                                usePercent={props.usePercent}
                                                            />
                                                        );
                                                    })}
                                                </CardContent>
                                            </Card>
                                        </Box>

                                        <Box width="45%" sx={{ m: 1 }} key="cycle-list">
                                            {latestTest.steam_cycle.map((cycle, idx) => {
                                                return (
                                                    <Accordion variant="outlined" sx={{ m: 1 }} key={cycle.id}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            key={`${cycle.id}-summary`}>
                                                            <Typography variant="h6" color="primary">
                                                                {`Cycle Metrics for Steam Cycle ${idx + 1}`}
                                                            </Typography>
                                                        </AccordionSummary>

                                                        <AccordionDetails key={`${cycle.id}-details`}>
                                                            <CycleStats
                                                                selectedCycle={cycle}
                                                                abnormalValuesObj={props.abnormalValuesObj}
                                                                fromBurninTest={true}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                    <Box key={"charts-wrapper"}>
                                        {latestTest.steam_cycle.map((cycle, idx) => {
                                            return (
                                                <Accordion
                                                    variant="outlined"
                                                    sx={{ m: 1 }}
                                                    onChange={(event, expanded) => getMetrics(expanded, cycle)}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header">
                                                        <Typography variant="h6" color="primary">
                                                            {`Steam Chart for Cycle ${idx + 1}`}
                                                        </Typography>
                                                    </AccordionSummary>

                                                    <AccordionDetails>
                                                        {/* <Box position="relative"> */}
                                                        {chartLoading && (
                                                            <SimpleBackdrop loaderText="Preparing Chart..." />
                                                        )}
                                                        <BurninTestChart
                                                            customTitlePrefix={props.testTitle}
                                                            cycleType="SteamCycle"
                                                            abnormalValuesObj={props.abnormalValuesObj}
                                                            digitalOutputOverlays={props.digitalOutputOverlays}
                                                            chartOptions={props.chartOptions}
                                                            timeList={test7CycleMetrics[cycle.id]?.timeList}
                                                            thList={test7CycleMetrics[cycle.id]?.thList}
                                                            tvList={test7CycleMetrics[cycle.id]?.tvList}
                                                            trList={test7CycleMetrics[cycle.id]?.rtList}
                                                            paList={test7CycleMetrics[cycle.id]?.paList}
                                                            psList={test7CycleMetrics[cycle.id]?.psList}
                                                            pvList={test7CycleMetrics[cycle.id]?.pvList}
                                                            ampsList={test7CycleMetrics[cycle.id]?.currentList}
                                                            dioList={test7CycleMetrics[cycle.id]?.dioList}
                                                            dutyCycleList={test7CycleMetrics[cycle.id]?.dutyCycleList}
                                                            stateList={test7CycleMetrics[cycle.id]?.stateList}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Box display="flex" justifyContent="space-around" mt={2}>
                                        <Box width="45%">
                                            <Card sx={{ margin: 1 }} variant="outlined">
                                                <CardHeader
                                                    title={
                                                        <Typography variant="h5" color="primary">
                                                            {props.testTitle}
                                                        </Typography>
                                                    }
                                                    subheader={burninTestState(testSuccess)}
                                                />
                                                <CardContent sx={{ py: 0 }}>
                                                    <List>
                                                        <ListItem divider={true}>
                                                            <ListItemButton sx={{ px: 0 }}>
                                                                <ListItemText primary={"Test Date"} />
                                                                <ListItemText
                                                                    primary={DateTime.fromISO(
                                                                        latestTest.start_time
                                                                    ).toLocaleString(datetimeFormat)}
                                                                    sx={{ textAlign: "right" }}
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </List>
                                                    {props.testNumber !== 7 &&
                                                        props.burninStatInternalNames.map((name, idx) => {
                                                            return (
                                                                <BurninItem
                                                                    test={latestTest}
                                                                    name={name}
                                                                    idx={idx}
                                                                    key={`${name}-${idx}`}
                                                                    subBurninStatInternalNames={
                                                                        props.subBurninStatInternalNames
                                                                    }
                                                                    abnormalValuesForBurninTest={
                                                                        props.abnormalValuesForBurninTest
                                                                    }
                                                                    usePercent={props.usePercent}
                                                                />
                                                            );
                                                        })}
                                                </CardContent>
                                            </Card>
                                        </Box>
                                        <Box width="45%" sx={{ m: 1 }}>
                                            <CycleStats
                                                selectedCycle={
                                                    latestTest.steam_cycle
                                                        ? latestTest.steam_cycle
                                                        : latestTest.grind_cycle
                                                }
                                                abnormalValuesObj={props.abnormalValuesObj}
                                                fromBurninTest={true}
                                            />
                                        </Box>
                                    </Box>
                                    {(latestTest.steam_cycle || latestTest.grind_cycle) && (
                                        <Accordion
                                            variant="outlined"
                                            onChange={(event, expanded) => getMetrics(expanded, latestTest)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                sx={{ m: 1 }}>
                                                <Typography variant="h6" color="primary">
                                                    View Charts for {props.testTitle}
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                {chartLoading && <SimpleBackdrop loaderText="Preparing Chart..." />}
                                                <BurninTestChart
                                                    customTitlePrefix={props.testTitle}
                                                    cycleType={latestTest.steam_cycle ? "SteamCycle" : "GrindCycle"}
                                                    abnormalValuesObj={props.abnormalValuesObj}
                                                    digitalOutputOverlays={props.digitalOutputOverlays}
                                                    chartOptions={props.chartOptions}
                                                    timeList={timeList}
                                                    thList={thList}
                                                    tvList={tvList}
                                                    trList={rtList}
                                                    paList={paList}
                                                    psList={psList}
                                                    pvList={pvList}
                                                    ampsList={currentList}
                                                    dioList={dioList}
                                                    dutyCycleList={dutyCycleList}
                                                    stateList={stateList}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                    {remainingTests.length >= 1 && (
                                        <Accordion variant="outlined" sx={{ m: 1 }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography variant="h6" color="primary">
                                                    {remainingTestHeader()}
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                {remainingTests.map((test, idx) => {
                                                    const dataAvailable = plotPointsList.find(
                                                        (cycleObj) =>
                                                            cycleObj.id ===
                                                            (test.steam_cycle
                                                                ? test.steam_cycle.id
                                                                : test.grind_cycle.id)
                                                    );
                                                    return (
                                                        <React.Fragment key={`fragment-${idx}`}>
                                                            <Box display="flex" justifyContent="space-around" mt={2}>
                                                                <Box width="45%">
                                                                    <Card
                                                                        key={`fieldset-${idx}`}
                                                                        sx={{ padding: 2, margin: 1 }}
                                                                        variant="outlined">
                                                                        <CardHeader
                                                                            title={
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    color="secondary">{`Previous Test ${props.testNumber}`}</Typography>
                                                                            }
                                                                            subheader={burninTestState(
                                                                                calculateBurninSuccess(test)
                                                                            )}
                                                                            sx={{ padding: 0 }}
                                                                        />
                                                                        <List>
                                                                            <ListItem divider={true}>
                                                                                <ListItemButton>
                                                                                    <ListItemText
                                                                                        primary={"Test Date"}
                                                                                    />
                                                                                    <ListItemText
                                                                                        primary={DateTime.fromISO(
                                                                                            test.start_time
                                                                                        ).toLocaleString(
                                                                                            datetimeFormat
                                                                                        )}
                                                                                        sx={{ textAlign: "right" }}
                                                                                    />
                                                                                </ListItemButton>
                                                                            </ListItem>
                                                                        </List>
                                                                        {props.burninStatInternalNames.map(
                                                                            (name, internalIdx) => {
                                                                                return (
                                                                                    <BurninItem
                                                                                        test={test}
                                                                                        name={name}
                                                                                        idx={internalIdx}
                                                                                        key={`${name}-${idx}`}
                                                                                        subBurninStatInternalNames={
                                                                                            props.subBurninStatInternalNames
                                                                                        }
                                                                                        abnormalValuesForBurninTest={
                                                                                            props.abnormalValuesForBurninTest
                                                                                        }
                                                                                        usePercent={props.usePercent}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Card>
                                                                </Box>
                                                                <Box width="45%" sx={{ m: 1 }}>
                                                                    <CycleStats
                                                                        selectedCycle={
                                                                            test.steam_cycle
                                                                                ? test.steam_cycle
                                                                                : test.grind_cycle
                                                                        }
                                                                        abnormalValuesObj={props.abnormalValuesObj}
                                                                        fromBurninTest={true}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                            {(test.steam_cycle || test.grind_cycle) && (
                                                                <Accordion
                                                                    variant="outlined"
                                                                    sx={{ m: 1 }}
                                                                    onChange={(event, expanded) =>
                                                                        getMetrics(expanded, test)
                                                                    }>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header">
                                                                        <Typography variant="h6" color="primary">
                                                                            View Charts
                                                                        </Typography>
                                                                    </AccordionSummary>

                                                                    <AccordionDetails>
                                                                        {/* <Box position="relative"> */}
                                                                        {chartLoading && (
                                                                            <SimpleBackdrop loaderText="Preparing Chart..." />
                                                                        )}

                                                                        <BurninTestChart
                                                                            customTitlePrefix={`Previous Test ${props.testNumber}`}
                                                                            cycleType={
                                                                                test.steam_cycle
                                                                                    ? "SteamCycle"
                                                                                    : "GrindCycle"
                                                                            }
                                                                            abnormalValuesObj={props.abnormalValuesObj}
                                                                            olderTestGraph={true}
                                                                            digitalOutputOverlays={
                                                                                props.digitalOutputOverlays
                                                                            }
                                                                            chartOptions={props.chartOptions}
                                                                            timeList={timeList}
                                                                            thList={thList}
                                                                            tvList={tvList}
                                                                            trList={rtList}
                                                                            paList={paList}
                                                                            psList={psList}
                                                                            pvList={pvList}
                                                                            ampsList={currentList}
                                                                            dioList={dioList}
                                                                            dutyCycleList={dutyCycleList}
                                                                            stateList={stateList}
                                                                        />
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : showTest7Flag ? (
                        <React.Fragment>
                            {props.loadingBurninData && <SimpleBackdrop loaderText="Preparing Burnin View..." />}
                            <Box
                                sx={{
                                    display: "flex",
                                    height: "40vh",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <Box>
                                    <Button variant="contained" size="large" onClick={() => handleBit7Click(deviceId)}>
                                        Generate BIT-7
                                    </Button>
                                </Box>
                            </Box>
                        </React.Fragment>
                    ) : (
                        noBurninTestFound()
                    )}
                </Box>
            )}
        </React.Fragment>
    );
};

BurninStats.defaultProps = {
    abnormalValuesForBurninTest: {},
    burninTestList: [],
    burninStatInternalNames: [],
    subBurninStatInternalNames: {},
    testTitle: "",
    showChart: false,
    testNumber: 0,
    burninTest7Cycles: [],
};

BurninStats.propTypes = {
    abnormalValuesForBurninTest: PropTypes.object.isRequired,
    burninTestList: PropTypes.array.isRequired,
    burninStatInternalNames: PropTypes.array.isRequired,
    subBurninStatInternalNames: PropTypes.object,
    testTitle: PropTypes.string,
    testNumber: PropTypes.number,
    burninTest7Cycles: PropTypes.array,
};

export default BurninStats;
