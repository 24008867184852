/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CycleStats from "./CycleStats/CycleStats";
import CycleMetricChartContainer from "./CycleMetricChartContainer";
import AuthService from "src/components/AuthService";
import get from "lodash/get";
import moment from "moment";
import BurninContainer from "./BurninContainer/BurninContainer";
import { useLocalStorage } from "src/components/library/hooks";
import AbnormalValuesModal from "./AbnormalValues/AbnormalValuesModal";
import { Box, Checkbox, Divider, FormControlLabel, Switch, Typography } from "@mui/material";
import CycleEventDataGrid from "./CycleEvent/CycleEventDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCycle, setSelectedIndex } from "./metricSlice";
import { DateTime } from "luxon";

const Auth = new AuthService();

const CycleMetricView = (props) => {
    const dispatch = useDispatch();
    const { cycleData, selectedCycle, selectedIndex, steamCycleTrends } = useSelector((store) => store.metric);
    const [showMessages, setShowMessages] = useState(false);
    const cycleType = get(selectedCycle, "cycle_type", "");
    const cycleStartTime = moment.utc(get(selectedCycle, "start_time")).format("MM-DD HH:mm:ss z");

    const [showSteamCycleTrends, setShowSteamCycleTrends] = useState(false);
    const [showGrindCycleTrends, setShowGrindCycleTrends] = useState(false);
    const [showSharedCycleTrends, setShowSharedCycleTrends] = useState(false);

    const [hideAxisLabels, setHideAxisLabels] = useState(true);
    const [showAbnormalTrendValues, setShowAbnormalTrendValues] = useState(false);
    const [zoomParams, setZoomParams] = useState({});
    const [showFloatButtons, setShowFloatButtons] = useState(true);
    const [burnInTab, setBurnInTab] = useState(0);

    const [cycleStartTimes, setCycleStartTimes] = useState([]);
    const [steamCycleStartTimes, setSteamCycleStartTimes] = useState([]);
    const [grindCycleStartTimes, setGrindCycleStartTimes] = useState([]);

    const handleTabChange = (newTabValue) => {
        setBurnInTab(newTabValue);
    };

    const calcEarliestStartDate = (cycle) => {
        let steamStart;
        let grindStart;

        if ("steam_cycle" in cycle && cycle.steam_cycle.start_time) {
            steamStart = DateTime.fromISO(cycle.steam_cycle.start_time);
        }
        if ("grind_cycle" in cycle && cycle.grind_cycle.start_time) {
            grindStart = DateTime.fromISO(cycle.grind_cycle.start_time);
        }
        if (steamStart && grindStart) {
            return steamStart < grindStart ? steamStart : grindStart;
        }
        return steamStart ? steamStart : grindStart;
    };

    const getEarlierCycle = (cycle) => {
        const steamTime = DateTime.fromISO(cycle?.steam_cycle?.start_time);
        const grindTime = DateTime.fromISO(cycle?.grind_cycle?.start_time);

        if (steamTime.isValid && grindTime.isValid) {
            return steamTime < grindTime ? cycle.steam_cycle : cycle.grind_cycle;
        } else {
            return steamTime.isValid ? cycle.steam_cycle : cycle?.grind_cycle;
        }
    };

    useEffect(() => {
        // we need to find the earliest cycle to select, which will not necessarily the first one in the parsed log array
        // - especially during batch processing
        if (!props.containsBurninTest) {
            let lowestIdx = cycleData.findIndex((cycle) => "steam_cycle" in cycle || "grind_cycle" in cycle);

            if (lowestIdx !== -1) {
                let earlierData = cycleData[lowestIdx];
                let tempLowestStartTime = calcEarliestStartDate(earlierData);
                for (const [idx, cycle] of cycleData.entries()) {
                    if (Object.keys(cycle).length > 0) {
                        const cycleStartTime = calcEarliestStartDate(cycle);
                        if (cycleStartTime < tempLowestStartTime) {
                            lowestIdx = idx;
                            tempLowestStartTime = cycleStartTime;
                        }
                    }
                }
                const earliestCycle = getEarlierCycle(cycleData[lowestIdx]);

                dispatch(setSelectedIndex(earliestCycle.id));
                dispatch(setSelectedCycle(earliestCycle));
            }
        }
    }, [cycleData]);

    useEffect(() => {
        const newcycleStartTimes = [];
        const newsteamCycleStartTimes = [];
        const newgrindCycleStartTimes = [];

        let cycleDate = [...cycleData];

        cycleDate = cycleDate.sort((a, b) => a.start_time - b.start_time);

        cycleDate.forEach((cycle) => {
            if (cycle.steam_cycle) {
                newsteamCycleStartTimes.push(moment.utc(cycle.steam_cycle.start_time).format("MM-DD HH:mm:ss"));

                setSteamCycleStartTimes(newsteamCycleStartTimes);
                newcycleStartTimes.push(moment.utc(cycle.steam_cycle.start_time).format("MM-DD HH:mm:ss"));
                setCycleStartTimes(newcycleStartTimes);
            }

            if (cycle.grind_cycle) {
                newgrindCycleStartTimes.push(moment.utc(cycle.grind_cycle.start_time).format("MM-DD HH:mm:ss"));

                setGrindCycleStartTimes(newgrindCycleStartTimes);
                newcycleStartTimes.push(moment.utc(cycle.grind_cycle.start_time).format("MM-DD HH:mm:ss"));
                setCycleStartTimes(newcycleStartTimes);
            }
        });
    }, [cycleData]);

    useEffect(() => {
        // if it's not the default index, we need to do some searching to find the new index
        if (showMessages) {
            const idx = cycleData.findIndex((cycle) => cycle.start_time === selectedCycle.start_time);
            dispatch(setSelectedIndex(idx));
        } else {
            if (selectedCycle && Object.keys(selectedCycle).length > 0) {
                const idx = cycleData
                    .filter((cycle) => cycle.cycle_type !== "Notification") // need to filter out notification messages to find true idx
                    .findIndex(
                        (cycle) =>
                            cycle?.steam_cycle?.start_time === selectedCycle?.start_time ||
                            cycle?.grind_cycle?.start_time === selectedCycle?.start_time
                    );
                if (selectedCycle.cycle_type === "SteamCycle") {
                    dispatch(setSelectedIndex(cycleData[idx]?.steam_cycle.id));
                } else if (selectedCycle.cycle_type === "GrindCycle") {
                    dispatch(setSelectedIndex(cycleData[idx]?.grind_cycle.id));
                }
            }
        }
    }, [showMessages, selectedCycle]);

    const prettyCycleType = (type) => {
        if (type === "SteamCycle") {
            return "Steam Cycle";
        } else if (type === "GrindCycle") {
            return "Grind Cycle";
        } else if (type === "BurninTest") {
            return "Burn in Test";
        } else {
            return type;
        }
    };

    const [trendCycleScore, setTrendCycleScore] = useState(true);
    const trendCycleScoreCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendCycleScore(!trendCycleScore)} checked={trendCycleScore} />}
            label="Score"
        />
    );

    const [trendCycleDuration, setTrendCycleDuration] = useState(true);
    const trendCycleDurationCheckbox = (
        <FormControlLabel
            control={
                <Checkbox onChange={() => setTrendCycleDuration(!trendCycleDuration)} checked={trendCycleDuration} />
            }
            label="Steam Cycle duration"
        />
    );

    const [trendTvAvgHold, setTrendTvAvgHold] = useState(false);
    const trendTvAvgHoldCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendTvAvgHold(!trendTvAvgHold)} checked={trendTvAvgHold} />}
            label="TV Avg During Hold"
        />
    );

    const [trendTPDiff, setTrendTPDiff] = useState(true);
    const trendTPDiffCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendTPDiff(!trendTPDiff)} checked={trendTPDiff} />}
            label="TP Diff"
        />
    );

    const [trendFlowSeconds, setTrendFlowSeconds] = useState(false);
    const trendFlowSecondsCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendFlowSeconds(!trendFlowSeconds)} checked={trendFlowSeconds} />}
            label="Flow Seconds"
        />
    );

    const [trendPumpdownDuration, setTrendPumpdownDuration] = useState(false);
    const trendPumpdownDurationCheckbox = (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={() => setTrendPumpdownDuration(!trendPumpdownDuration)}
                    checked={trendPumpdownDuration}
                />
            }
            label="Steam Pumpdown Time (mins)"
        />
    );

    const [trendDrainingTime, setTrendDrainingTime] = useState(false);
    const trendDrainingTimeCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendDrainingTime(!trendDrainingTime)} checked={trendDrainingTime} />}
            label="Draining Time (mins)"
        />
    );

    const [trendHeatUpTime, setTrendHeatUpTime] = useState(false);
    const trendHeatUpTimeCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendHeatUpTime(!trendHeatUpTime)} checked={trendHeatUpTime} />}
            label="Heat Up Time (mins)"
        />
    );

    const [trendHeatUpRateDifference, setHeatUpRateDifference] = useState(false);
    const trendHeatUpRateDifferenceCheckbox = (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={() => setHeatUpRateDifference(!trendHeatUpRateDifference)}
                    checked={trendHeatUpRateDifference}
                />
            }
            label="Heat Up Rate Difference"
        />
    );

    const [trendPumpdownTimeouts, setTrendPumpdownTimeouts] = useState(false);
    const trendPumpdownTimeoutsCheckbox = (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={() => setTrendPumpdownTimeouts(!trendPumpdownTimeouts)}
                    checked={trendPumpdownTimeouts}
                />
            }
            label="Pumpdown Timeouts"
        />
    );

    const [trendTVStart, setTrendTVStart] = useState(false);
    const trendTVStartCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendTVStart(!trendTVStart)} checked={trendTVStart} />}
            label="TV Start"
        />
    );

    const [trendTVMax, setTrendTVMax] = useState(false);
    const trendTVMaxCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendTVMax(!trendTVMax)} checked={trendTVMax} />}
            label="TV Max"
        />
    );

    const [trendTRAverage, setTrendTRAverage] = useState(false);
    const trendTRAverageCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendTRAverage(!trendTRAverage)} checked={trendTRAverage} />}
            label="TR Avg"
        />
    );

    const [trendPVStart, setTrendPVStart] = useState(false);
    const trendPVStartCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendPVStart(!trendPVStart)} checked={trendPVStart} />}
            label="PV Start"
        />
    );

    const [trendPVMin, setTrendPVMin] = useState(false);
    const trendPVMinCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendPVMin(!trendPVMin)} checked={trendPVMin} />}
            label="PV Min"
        />
    );

    const [trendPVMax, setTrendPVMax] = useState(false);
    const trendPVMaxCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendPVMax(!trendPVMax)} checked={trendPVMax} />}
            label="PV Max"
        />
    );

    const [trendPVAverageDuringHold, setTrendPVAverage] = useState(true);
    const trendPVAverageCheckbox = (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={() => setTrendPVAverage(!trendPVAverageDuringHold)}
                    checked={trendPVAverageDuringHold}
                />
            }
            label="PV Avg during hold"
        />
    );

    const [trendStalls, setTrendStalls] = useState(false);
    const trendStallsCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendStalls(!trendStalls)} checked={trendStalls} />}
            label="Stalls"
        />
    );

    const [trendGrindDuration, setTrendGrindDuration] = useState(true);
    const trendGrindDurationCheckbox = (
        <FormControlLabel
            control={
                <Checkbox onChange={() => setTrendGrindDuration(!trendGrindDuration)} checked={trendGrindDuration} />
            }
            label="Grind duration"
        />
    );

    const [trendJack, setTrendJack] = useState(false);
    const trendJackCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendJack(!trendJack)} checked={trendJack} />}
            label="Jack"
        />
    );

    const [trendNumberStalls, setTrendNumberStalls] = useState(true);
    const trendNumberStallsCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendNumberStalls(!trendNumberStalls)} checked={trendNumberStalls} />}
            label="Number of Stalls"
        />
    );

    const [trendTries, setTrendTries] = useState(false);
    const trendTriesCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendTries(!trendTries)} checked={trendTries} />}
            label="Tries"
        />
    );

    const [trendForwardDuration, setTrendForwardDuration] = useState(false);
    const trendForwardDurationCheckbox = (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={() => setTrendForwardDuration(!trendForwardDuration)}
                    checked={trendForwardDuration}
                />
            }
            label="Forward Duration"
        />
    );

    const [trendReverseDuration, setTrendReverseDuration] = useState(false);
    const trendReverseDurationCheckbox = (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={() => setTrendReverseDuration(!trendReverseDuration)}
                    checked={trendReverseDuration}
                />
            }
            label="Reverse Duration"
        />
    );

    const [trendDutyCycleAvg, setTrendDutyCycleAvg] = useState(true);
    const trendDutyCycleAvgCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendDutyCycleAvg(!trendDutyCycleAvg)} checked={trendDutyCycleAvg} />}
            label="Duty Cycle Avg"
        />
    );

    const [trendDutyCycleStart, setTrendDutyCycleStart] = useState(true);
    const trendDutyCycleStartCheckbox = (
        <FormControlLabel
            control={
                <Checkbox onChange={() => setTrendDutyCycleStart(!trendDutyCycleStart)} checked={trendDutyCycleStart} />
            }
            label="Duty Cycle Start"
        />
    );

    const [trendDutyCycleEnd, setTrendDutyCycleEnd] = useState(true);
    const trendDutyCycleEndCheckbox = (
        <FormControlLabel
            control={<Checkbox onChange={() => setTrendDutyCycleEnd(!trendDutyCycleEnd)} checked={trendDutyCycleEnd} />}
            label="Duty Cycle End"
        />
    );

    const cycleTrendOptions = (
        <React.Fragment>
            {cycleType === "SteamCycle" && (
                <FormControlLabel
                    className="digital-output-toggle"
                    control={
                        <Switch
                            onChange={() => setShowSteamCycleTrends(!showSteamCycleTrends)}
                            checked={showSteamCycleTrends}
                        />
                    }
                    label="Show Steam Cycle Trends"
                />
            )}
            {cycleType === "GrindCycle" && (
                <FormControlLabel
                    className="digital-output-toggle"
                    control={
                        <Switch
                            onChange={() => setShowGrindCycleTrends(!showGrindCycleTrends)}
                            checked={showGrindCycleTrends}
                        />
                    }
                    label="Show Grind Cycle Trends"
                />
            )}
            <FormControlLabel
                className="digital-output-toggle"
                control={
                    <Switch
                        onChange={() => setShowSharedCycleTrends(!showSharedCycleTrends)}
                        checked={showSharedCycleTrends}
                    />
                }
                label="Show Shared Cycle Trends"
            />

            {(showSteamCycleTrends || showGrindCycleTrends || showSharedCycleTrends) && (
                <React.Fragment>
                    <FormControlLabel
                        className="digital-output-toggle"
                        control={
                            <Switch onChange={() => setHideAxisLabels(!hideAxisLabels)} checked={hideAxisLabels} />
                        }
                        label="Show Axis Labels"
                    />
                    <FormControlLabel
                        className="digital-output-toggle"
                        control={
                            <Switch
                                onChange={() => setShowAbnormalTrendValues(!showAbnormalTrendValues)}
                                checked={showAbnormalTrendValues}
                            />
                        }
                        label="Color Abnormal Trend Values"
                    />
                </React.Fragment>
            )}

            {showFloatButtons && (
                <AbnormalValuesModal
                    abnormalValues={props.abnormalValues}
                    containsBurninTest={props.containsBurninTest}
                    setShowFloatButtons={setShowFloatButtons}
                />
            )}
        </React.Fragment>
    );

    const [showBurninContainer, setShowBurninContainer] = useState(false);
    useEffect(() => {
        setShowBurninContainer(props.containsBurninTest);
    }, [props.containsBurninTest]);

    const [scrollOnCycleClick, _setScrollOnCycleClick] = useLocalStorage("scrollOnCycleClick", false);
    const setScrollOnCycleClick = React.useCallback((value) => {
        _setScrollOnCycleClick(value);
    }, []);

    useEffect(() => {
        // when the cycle changes, scroll user to top of page
        if (scrollOnCycleClick) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [selectedCycle]);

    const [showOverlayCycleSelect, setShowOverlayCycleSelect] = useState(false);

    useEffect(() => {
        if (showOverlayCycleSelect === false) {
            _setOverlayedCycles([]);
        }
    }, [showOverlayCycleSelect]);

    const [overlayedCycles, _setOverlayedCycles] = useState([]);
    const setOverlayedCycles = (tableRow) =>
        _setOverlayedCycles(() => {
            return tableRow.length > 0 ? tableRow.map((row) => row["original"]) : [];
        });

    return (
        <Box
            sx={{
                width: "100%",
            }}>
            {showBurninContainer ? (
                <BurninContainer
                    abnormalValuesObj={props.abnormalValuesObj}
                    containsBurninTest={props.containsBurninTest}
                    showMessages={showMessages}
                    setShowMessages={setShowMessages}
                    abnormalValues={props.abnormalValues}
                    deviceSerial={get(props.deviceInfo, "serial_number", "")}
                    setShowBurninContainer={setShowBurninContainer}
                    showBurninContainer={showBurninContainer}
                    scrollOnCycleClick={scrollOnCycleClick}
                    setScrollOnCycleClick={setScrollOnCycleClick}
                    zoomParams={zoomParams}
                    setZoomParams={setZoomParams}
                    burnInTab={burnInTab}
                    handleTabChange={handleTabChange}
                    burnIn1Data={props.burnIn1Data}
                    loadingBurnIn1={props.loadingBurnIn1}
                    burnIn2Data={props.burnIn2Data}
                    loadingBurnIn2={props.loadingBurnIn2}
                    burnIn3Data={props.burnIn3Data}
                    loadingBurnIn3={props.loadingBurnIn3}
                    burnIn4Data={props.burnIn4Data}
                    loadingBurnIn4={props.loadingBurnIn4}
                    burnIn5Data={props.burnIn5Data}
                    loadingBurnIn5={props.loadingBurnIn5}
                    burnIn6Data={props.burnIn6Data}
                    loadingBurnIn6={props.loadingBurnIn6}
                    burnIn7Data={props.burnIn7Data}
                    loadingBurnIn7={props.loadingBurnIn7}
                    digitalOutput={props.digitalOutput}
                />
            ) : (
                <React.Fragment>
                    <Box m="auto" width={"95%"}>
                        <CycleEventDataGrid
                            showMessages={showMessages}
                            showBurninContainer={showBurninContainer}
                            setShowBurninContainer={setShowBurninContainer}
                            cycleType={cycleType}
                            overlayedCycles={overlayedCycles}
                            setOverlayedCycles={setOverlayedCycles}
                            setZoomParams={setZoomParams}
                        />
                    </Box>

                    <Divider variant="middle">
                        <Typography variant="h5" color="secondary" sx={{ fontWeight: "bold", p: 1 }}>
                            {prettyCycleType(cycleType)}
                        </Typography>
                    </Divider>
                    <Box m="auto" width={"95%"}>
                        {(cycleType === "SteamCycle" || cycleType === "GrindCycle") && (
                            <CycleStats
                                selectedCycle={selectedCycle}
                                cycleTrendOptions={cycleTrendOptions}
                                showSteamCycleTrends={showSteamCycleTrends}
                                showGrindCycleTrends={showGrindCycleTrends}
                                showSharedCycleTrends={showSharedCycleTrends}
                                trendCycleScoreCheckbox={trendCycleScoreCheckbox}
                                trendCycleDurationCheckbox={trendCycleDurationCheckbox}
                                trendTvAvgHoldCheckbox={trendTvAvgHoldCheckbox}
                                trendTPDiffCheckbox={trendTPDiffCheckbox}
                                trendFlowSecondsCheckbox={trendFlowSecondsCheckbox}
                                trendPumpdownDurationCheckbox={trendPumpdownDurationCheckbox}
                                trendDrainingTimeCheckbox={trendDrainingTimeCheckbox}
                                trendHeatUpTimeCheckbox={trendHeatUpTimeCheckbox}
                                trendPumpdownTimeoutsCheckbox={trendPumpdownTimeoutsCheckbox}
                                trendHeatUpRateDifferenceCheckbox={trendHeatUpRateDifferenceCheckbox}
                                trendTVStartCheckbox={trendTVStartCheckbox}
                                trendTVMaxCheckbox={trendTVMaxCheckbox}
                                trendTRAverageCheckbox={trendTRAverageCheckbox}
                                trendPVStartCheckbox={trendPVStartCheckbox}
                                trendPVMinCheckbox={trendPVMinCheckbox}
                                trendPVMaxCheckbox={trendPVMaxCheckbox}
                                trendPVAverageCheckbox={trendPVAverageCheckbox}
                                trendGrindDurationCheckbox={trendGrindDurationCheckbox}
                                trendStallsCheckbox={trendStallsCheckbox}
                                trendJackCheckbox={trendJackCheckbox}
                                trendNumberStallsCheckbox={trendNumberStallsCheckbox}
                                trendTriesCheckbox={trendTriesCheckbox}
                                trendForwardDurationCheckbox={trendForwardDurationCheckbox}
                                trendReverseDurationCheckbox={trendReverseDurationCheckbox}
                                trendDutyCycleAvgCheckbox={trendDutyCycleAvgCheckbox}
                                trendDutyCycleStartCheckbox={trendDutyCycleStartCheckbox}
                                trendDutyCycleEndCheckbox={trendDutyCycleEndCheckbox}
                                abnormalValuesObj={props.abnormalValuesObj}
                            />
                        )}

                        {selectedCycle && (
                            <CycleMetricChartContainer
                                setShowBurninContainer={setShowBurninContainer}
                                selectedCycle={selectedCycle}
                                cycleType={cycleType}
                                cycleStartTime={cycleStartTime}
                                cycleStartTimes={cycleStartTimes}
                                steamCycleStartTimes={steamCycleStartTimes}
                                grindCycleStartTimes={grindCycleStartTimes}
                                facilityType={props.deviceInfo["facility_type"]}
                                infoCount={selectedCycle["info_count"]}
                                showSteamCycleTrends={showSteamCycleTrends}
                                showGrindCycleTrends={showGrindCycleTrends}
                                showSharedCycleTrends={showSharedCycleTrends}
                                hideAxisLabels={hideAxisLabels}
                                trendCycleScore={trendCycleScore}
                                trendCycleDuration={trendCycleDuration}
                                trendTvAvgHold={trendTvAvgHold}
                                trendTPDiff={trendTPDiff}
                                trendFlowSeconds={trendFlowSeconds}
                                trendPumpdownDuration={trendPumpdownDuration}
                                trendDrainingTime={trendDrainingTime}
                                trendHeatUpTime={trendHeatUpTime}
                                trendPumpdownTimeouts={trendPumpdownTimeouts}
                                trendTVStart={trendTVStart}
                                trendTVMax={trendTVMax}
                                trendTRAverage={trendTRAverage}
                                trendPVStart={trendPVStart}
                                trendPVMin={trendPVMin}
                                trendPVMax={trendPVMax}
                                trendPVAverageDuringHold={trendPVAverageDuringHold}
                                trendHeatUpRateDifference={trendHeatUpRateDifference}
                                trendGrindDuration={trendGrindDuration}
                                trendStalls={trendStalls}
                                trendJack={trendJack}
                                trendNumberStalls={trendNumberStalls}
                                trendTries={trendTries}
                                trendForwardDuration={trendForwardDuration}
                                trendReverseDuration={trendReverseDuration}
                                abnormalValuesObj={props.abnormalValuesObj}
                                showAbnormalTrendValues={showAbnormalTrendValues}
                                trendDutyCycleAvg={trendDutyCycleAvg}
                                trendDutyCycleStart={trendDutyCycleStart}
                                trendDutyCycleEnd={trendDutyCycleEnd}
                                scrollOnCycleClick={scrollOnCycleClick}
                                setScrollOnCycleClick={setScrollOnCycleClick}
                                zoomParams={zoomParams}
                                setZoomParams={setZoomParams}
                                overlayedCycles={overlayedCycles}
                                showFloatButtons={showFloatButtons}
                                setShowFloatButtons={setShowFloatButtons}
                                digitalOutput={props.digitalOutput}
                            />
                        )}
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};

CycleMetricView.defaultProps = {
    abnormalValues: [],
    abnormalValuesObj: {},
    deviceInfo: {},
    cycleStartTimes: [],
    steamCycleStartTimes: [],
    grindCycleStartTimes: [],
    fileID: "",
    fileName: "",
    containsBurninTest: false,
};

CycleMetricView.propTypes = {
    abnormalValues: PropTypes.array,
    deviceInfo: PropTypes.object,
    abnormalValuesObj: PropTypes.object,
    cycleStartTimes: PropTypes.array,
    steamCycleStartTimes: PropTypes.array,
    grindCycleStartTimes: PropTypes.array,
    fileID: PropTypes.string,
    fileName: PropTypes.string,
    containsBurninTest: PropTypes.bool,
};

export default CycleMetricView;
