/*
*  A last step confirmation modal used to demonstrate the firmware changes
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withRouter } from "react-router-dom";
import {
  composeHoc,
} from "../../library/helpers";
import {Trans, withTranslation as translate} from "react-i18next";
import {Button, Header, Modal, Table, TransitionablePortal} from "semantic-ui-react";
import AuthService from "../../AuthService";
import SimpleLoader from "../../SimpleLoader";
import * as Sentry from "@sentry/browser";
const Auth = new AuthService();

class ConfirmFirmwareModal extends React.Component{
  state = {
    modalLoading : false,
    allowModalToBeClosed: true,
  };
  assignFirmwareToDevices = () => {
    this.setState({
      modalLoading : true,
      allowModalToBeClosed: false,
    });
    const {
      selectedDevices,
      selectedFirmware,
      selectedFirmwareString
    } = this.props;

    // https://sterilismedical.atlassian.net/browse/DP-713
    // Going to keep the obj in array format that supports multiple device firmware assignments
    const deviceFirmwareAssignments = []
    selectedDevices.map(device => {
      deviceFirmwareAssignments.push({
        'device_config_id': device,
        'firmware_id': selectedFirmware === "None" ? null : selectedFirmware
      })
    });
    Auth.fetch(`/api/assign-firmware/`, {
      method : 'POST',
      body: JSON.stringify({
        'device_firmware_assignments': deviceFirmwareAssignments
      })
    }).then(data => {
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerConfirmationModal();
        this.props.notifySuccess(selectedFirmwareString);
        this.props.history.push('/view/devices')
      })
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.triggerConfirmationModal();
        this.props.notifyFailure();
      })
    });
  };
  render() {
    const {
      t,
      prettyDeviceDisplay,
      selectedFirmwareString,
    } = this.props;
    const {
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    return (
      <TransitionablePortal
          open={this.props.confirmFirmwareModal}
          onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
          transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='small'
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerConfirmationModal()
          }}
        >
          <Header>
            <h2>{t('assign-firmware-to-devices.modal.header', 'Confirm software assignment')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content>
            <div className='short-modal-text center-text'>
              <p>
                <Trans ns="translations" i18nKey="assign-firmware-to-devices.modal.1">
                  Are you sure you want to assign <strong>{{selectedFirmwareString}}</strong> to these devices?
                </Trans>
              </p>
              <p>
                <Trans ns="translations" i18nKey="assign-firmware-to-devices.modal.2">
                  The devices will download the new software as soon as they communicate with our portal.
                </Trans>
              </p>
            </div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('Serial number')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Software version before')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Software version after')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  Object.keys(prettyDeviceDisplay).map((item, idx) => {
                    return (
                      <Table.Row key={idx}>
                        <Table.Cell>{prettyDeviceDisplay[item]['serial']}</Table.Cell>
                        <Table.Cell>{prettyDeviceDisplay[item]['current_fw']}</Table.Cell>
                        <Table.Cell>{prettyDeviceDisplay[item]['selected_fw']}</Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    id='cycleReportBtn'
                    onClick={this.assignFirmwareToDevices}
                    >{t('Submit')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default composeHoc(withRouter, translate('translations'))(ConfirmFirmwareModal);
