/*
*  Modal to edit region settings
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "../../AuthService";
import SimpleLoader from "../../SimpleLoader";
import * as Sentry from "@sentry/browser";
const Auth = new AuthService();

class EditRegionSettingsModal extends React.Component{
  state = {
    min_cook_time: '',
    min_cook_temp: '',
    bio_interval_type: '',
    modalLoading : false,
    allowModalToBeClosed: true,
  };
  componentDidMount(){
    const {
      regionBeingEdited
    } = this.props;
    this.setState({
      min_cook_time : regionBeingEdited['min_cook_time'],
      min_cook_temp : regionBeingEdited['min_cook_temp'],
      bio_interval_type : regionBeingEdited['bio_interval_type']
    })
  }
  submitChanges = (e) => {
    e.preventDefault();
    const {
      min_cook_time,
      min_cook_temp,
      bio_interval_type
    } = this.state;

    const {
      regionBeingEdited
    } = this.props;

    this.setState({
      modalLoading : true,
      allowModalToBeClosed: false,
    });

    Auth.fetch(`/api/region-setting/${regionBeingEdited['id']}`, {
      method : 'PATCH',
      body: JSON.stringify({
        min_cook_time: min_cook_time,
        min_cook_temp: min_cook_temp,
        bio_interval_type: bio_interval_type,
      })
    }).then(data => {
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: true,
      }, () => {
        this.props.fetchRegionSettings();
        this.props.triggerEditRegionModal(null);
        this.props.notifySuccess(data['region_name']);
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading : false,
        allowModalToBeClosed: true,
      });
      this.props.notifyFailure();
    });
  };

  handleBiochallengeTypeChange = (e, data) => {
    this.setState({
      bio_interval_type: data.value
    });
  };

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    this.setState({
      [name] : value
    });
  };

  render() {
    const {
      t
    } = this.props;
    const {
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal : {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea : {
        marginTop : '50px',
        width: '400px'
      }
    };
    const biochallengeDropdown = [
      {
        key: 0,
        text: `Usage`,
        value: 'usage'
      },
      {
        key: 1,
        text: `Calendar`,
        value: 'calendar'
      },
    ];
    return (
      <TransitionablePortal
          open={this.props.editRegionModal}
          onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
          transition={{ animation: 'scale', duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size='small'
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
              document.body.classList.remove('modal-fade-in');
              this.props.triggerEditRegionModal(null)
          }}
        >
          <Header>
            <h2>{t('Edit Region Setting')}</h2>
          </Header>
          <Modal.Content className='edit-a-device-operator'>
            {modalLoading ? (
              <SimpleLoader/>
            ) : null}
            <form id='editRegionForm'
                  onSubmit={this.submitChanges}
            >
              <div className='split-container'>
                <div className="desc customer">
                  <span className='slight-padding-bottom'>Biological challenges can fall under two different legal categorizations - <strong>Calendar</strong> and <strong>Usage</strong>.</span>
                  <span className='slight-padding-bottom'>The biochallenge <strong>Interval Duration</strong> is a value in <strong>seconds</strong> which denotes how long a device is in compliance with autoclave legislation after it's previous biochallenge.</span>
                </div>
                <div className="items">
                  <div className="form-group">
                    <label className="fake-float" htmlFor="countryDropdown">{t('Biochallenge Interval Type')}</label>
                    <Dropdown
                      className='full-width'
                      selection
                      id='countryDropdown'
                      options={biochallengeDropdown}
                      value={this.state.bio_interval_type}
                      onChange={this.handleBiochallengeTypeChange}
                    />
                  </div>
                  <div className="form-group">
                    <input value={this.state.min_cook_time}
                           name='min_cook_time'
                           onChange={this.handleChange}
                           type="number"
                           className="form-control"
                           id='minCookInput'
                           required/>
                    <label className="form-control-placeholder" htmlFor="minCookInput">{t('Minimum Cook Time (mins)')}</label>
                  </div>
                  <div className="form-group">
                    <input value={this.state.min_cook_temp}
                           name='min_cook_temp'
                           onChange={this.handleChange}
                           type="number"
                           className="form-control"
                           id='minCookTempInput'
                           required/>
                    <label className="form-control-placeholder" htmlFor="minCookTempInput">{t('Minimum Cook Temperature (C°)')}</label>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    form='editRegionForm'
                    >{t('Submit')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(EditRegionSettingsModal);
