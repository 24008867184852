/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import GrindCycle from "./GrindCycle";
import SteamCycleTrendsChart from "./SteamCycleTrendsChart";
import GrindCycleTrendsChart from "./GrindCycleTrendsChart";
import SharedCycleTrendsChart from "./SharedCycleTrendsChart";
import ChartOptions from "./ChartOptions";
import CurrentChart from "./CurrentChart";
import SteamChart from "./SteamChart";


export const processPlotBand = (plotBand, digitalOutputOverlays, overlayStates) => {
  const digitalOutputsOn = Object.values(digitalOutputOverlays).includes(true);
  if (digitalOutputsOn && overlayStates) {
    // if its the state plot band and digital outputs are overlayed while states are overlayed
    // bump the state's plot band up to -90
    return plotBand.map(band => {
      return {
        ...band,
        label: {
          ...band.label,
          y: -90,
        }
      }
    })
  }
  return plotBand;
};

const ChartContainer = props => {
  const [chartOptions, _setChartOptions] = useState({
    combineTPGraphs: true,
    showCurrentGraph: true,
    showTempConstants: true,
    showPresConstants: true,
    overlayStates: false,
    overlayInfo: false,
    plotDutyCycle: false,
    showAbnormalSeriesValues: false,
  });

  const setChartOptions = (newState) => _setChartOptions({...chartOptions, ...newState});

  const [digitalOutputOverlays, _setDigitalOutputOverlays] = useState({
    overlaySol1: false,
    overlaySol2: false,
    overlaySol3: false,
    overlaySol4: false,
    overlaySol5: false,
    overlaySol6: false,
    overlaySol7: false,
    overlaySol8: false,
    overlaySol9: false,
    overlaySol10: false,
    overlaySol11: false,
    overlaySol12: false,
    overlaySol13: false,
    overlaySol14: false,
    overlaySol15: false,
    overlaySol16: false,
    overlaySol17: false,
    overlaySol18: false,
    overlayVacPump: false,
    overlayAirPump: false,
    overlayWaterPump: false,
    overlayHeaters: false,
    overlayDeodorizerLED: false,
    overlayGrinderOn: false,
    overlayGrinderForward: false,
    overlayOil: false,
    overlayOilLevels: false,
  });

  const setDigitalOutputOverlays = (newState) => _setDigitalOutputOverlays({...digitalOutputOverlays, ...newState});

  const {
    combineTPGraphs,
    showCurrentGraph,
    showTempConstants,
    showPresConstants,
    overlayStates,
    overlayInfo,
    plotDutyCycle,
    showAbnormalSeriesValues,
  } = chartOptions;

  const {
    overlaySol1,
    overlaySol2,
    overlaySol3,
    overlaySol4,
    overlaySol5,
    overlaySol6,
    overlaySol7,
    overlaySol8,
    overlaySol9,
    overlaySol10,
    overlaySol11,
    overlaySol12,
    overlaySol13,
    overlaySol14,
    overlaySol15,
    overlaySol16,
    overlaySol17,
    overlaySol18,
    overlayVacPump,
    overlayAirPump,
    overlayWaterPump,
    overlayHeaters,
    overlayDeodorizerLED,
    overlayGrinderOn,
    overlayGrinderForward,
    overlayOil,
    overlayOilLevels,
  } = digitalOutputOverlays;


  const plotBands = useMemo(() => {
    return [
      ...(overlaySol1 ? props.selectedCycle['digital_output_plot_bands']['sol_1_plot_band'] : []),
      ...(overlaySol2 ? props.selectedCycle['digital_output_plot_bands']['sol_2_plot_band'] : []),
      ...(overlaySol3 ? props.selectedCycle['digital_output_plot_bands']['sol_3_plot_band'] : []),
      ...(overlaySol4 ? props.selectedCycle['digital_output_plot_bands']['sol_4_plot_band'] : []),
      ...(overlaySol5 ? props.selectedCycle['digital_output_plot_bands']['sol_5_plot_band'] : []),
      ...(overlaySol6 ? props.selectedCycle['digital_output_plot_bands']['sol_6_plot_band'] : []),
      ...(overlaySol7 ? props.selectedCycle['digital_output_plot_bands']['sol_7_plot_band'] : []),
      ...(overlaySol8 ? props.selectedCycle['digital_output_plot_bands']['sol_8_plot_band'] : []),
      ...(overlaySol9 ? props.selectedCycle['digital_output_plot_bands']['sol_9_plot_band'] : []),
      ...(overlaySol10 ? props.selectedCycle['digital_output_plot_bands']['sol_10_plot_band'] : []),
      ...(overlaySol11 ? props.selectedCycle['digital_output_plot_bands']['sol_11_plot_band'] : []),
      ...(overlaySol12 ? props.selectedCycle['digital_output_plot_bands']['sol_12_plot_band'] : []),
      ...(overlaySol13 ? props.selectedCycle['digital_output_plot_bands']['sol_13_plot_band'] : []),
      ...(overlaySol14 ? props.selectedCycle['digital_output_plot_bands']['sol_14_plot_band'] : []),
      ...(overlaySol15 ? props.selectedCycle['digital_output_plot_bands']['sol_15_plot_band'] : []),
      ...(overlaySol16 ? props.selectedCycle['digital_output_plot_bands']['sol_16_plot_band'] : []),
      ...(overlaySol17 ? props.selectedCycle['digital_output_plot_bands']['sol_17_plot_band'] : []),
      ...(overlaySol18 ? props.selectedCycle['digital_output_plot_bands']['sol_18_plot_band'] : []),
      ...(overlayVacPump ? props.selectedCycle['digital_output_plot_bands']['vac_pump_plot_band'] : []),
      ...(overlayAirPump ? props.selectedCycle['digital_output_plot_bands']['air_pump_plot_band'] : []),
      ...(overlayWaterPump ? props.selectedCycle['digital_output_plot_bands']['water_pump_plot_band'] : []),
      ...(overlayHeaters ? props.selectedCycle['digital_output_plot_bands']['heaters_plot_band'] : []),
      ...(overlayDeodorizerLED ? props.selectedCycle['digital_output_plot_bands']['deodorizer_led_plot_band'] : []),
      ...(overlayGrinderOn ? props.selectedCycle['digital_output_plot_bands']['grinder_on_plot_band'] : []),
      ...(overlayGrinderForward ? props.selectedCycle['digital_output_plot_bands']['grinder_forward_plot_band'] : []),
      ...(overlayOil ? props.selectedCycle['digital_output_plot_bands']['oil_plot_band'] : []),
      ...(overlayOilLevels ? props.selectedCycle['digital_output_plot_bands']['oil_level_plot_band'] : []),


      ...(overlayStates ? processPlotBand(props.selectedCycle['state_plot_bands'], digitalOutputOverlays, overlayStates) : []),
    ]
  }, [digitalOutputOverlays, overlayStates, props.selectedCycle]);


  // use memo here since the .some calculation is kind of heavy and we shouldn't just re-calc
  // every time state changes in this component
  const dutyCycleValuesOverThreshHold = useMemo(() => {
    return plotDutyCycle && props.selectedCycle.duty_cycle_list.some(dutyCycleVal => dutyCycleVal >= 690);
  }, [props.selectedCycle.duty_cycle_list, plotDutyCycle, props.timeList]);


  return <div
    className='parser-graph-container'
  >
    <ChartOptions
      cycleType={props.cycleType}
      infoCount={props.infoCount}
      chartOptions={chartOptions}
      setChartOptions={setChartOptions}
      setDigitalOutputOverlays={setDigitalOutputOverlays}
      digitalOutputOverlays={digitalOutputOverlays}
      scrollOnCycleClick={props.scrollOnCycleClick}
      setScrollOnCycleClick={props.setScrollOnCycleClick}

    />
    {props.cycleType === 'SteamCycle' &&
    <SteamChart

      thList={props.selectedCycle.th_list}
      tvList={props.selectedCycle.tv_list}
      trList={props.selectedCycle.tr_list}
      paList={props.selectedCycle.pa_list}
      psList={props.selectedCycle.ps_list}
      pvList={props.selectedCycle.pv_list}
      iList={props.selectedCycle.i_list}
      timeList={props.selectedCycle.time_list}


      plotBands={plotBands}
      chartOptions={chartOptions}
      combineTPGraphs={combineTPGraphs}
      showCurrentGraph={showCurrentGraph}
      showTempConstants={showTempConstants}
      showPresConstants={showPresConstants}
      infoConstants={props.selectedCycle.info_constants}
      overlayInfo={overlayInfo}
      overlayStates={overlayStates}
      plotDutyCycle={plotDutyCycle}
      dutyCycleList={props.selectedCycle.duty_cycle_list}
      dutyCycleValuesOverThreshHold={dutyCycleValuesOverThreshHold}
      showAbnormalSeriesValues={showAbnormalSeriesValues}

      abnormalThValues={props.abnormalValuesObj['th_list']}
      abnormalTvValues={props.abnormalValuesObj['tv_list']}
      abnormalTrValues={props.abnormalValuesObj['tr_list']}
      abnormalPaValues={props.abnormalValuesObj['pa_list']}
      abnormalPsValues={props.abnormalValuesObj['ps_list']}
      abnormalPvValues={props.abnormalValuesObj['pv_list']}
      abnormalIValues={props.abnormalValuesObj['i_list']}
      abnormalDutyCycleValues={props.abnormalValuesObj['duty_cycle_list']}
      zoomParams={props.zoomParams}
      setZoomParams={props.setZoomParams}
      overlayedCycles={props.overlayedCycles}
      cycleStartTime={props.cycleStartTime}

    />
    }

{
      (props.cycleType === 'SteamCycle' && showCurrentGraph) &&
      <CurrentChart
        timeList={props.selectedCycle.time_list}
        iList={props.cycleType === 'SteamCycle' ? props.selectedCycle.i_list : props.selectedCycle.ig_list}
        plotBands={plotBands}
        overlayInfo={overlayInfo}
        infoConstants={props.selectedCycle.info_constants}
        combineTPGraphs={props.cycleType === 'SteamCycle' ? false : combineTPGraphs}
        showAbnormalSeriesValues={showAbnormalSeriesValues}
        abnormalIValues={props.cycleType === 'SteamCycle' ? props.abnormalValuesObj['i_list'] : props.abnormalValuesObj['ig_list']}
        customTitlePrefix={props.customTitlePrefix}
        zoomParams={props.zoomParams}
        setZoomParams={props.setZoomParams}
        overlayedCycles={props.overlayedCycles}
        cycleStartTime={props.cycleStartTime}
        isTPACurrentGraph={props.cycleType === 'GrindCycle'}
        cycleType={props.cycleType}

      />
    }
    {props.cycleType === 'GrindCycle' &&
    <GrindCycle
      plotBands={plotBands}

      timeList={props.selectedCycle.time_list}
      igList={props.selectedCycle.ig_list}
      paList={props.selectedCycle.pa_list}


      infoConstants={props.selectedCycle.info_constants}
      overlayInfo={overlayInfo}
      overlayStates={overlayStates}
      plotDutyCycle={plotDutyCycle}
      dutyCycleList={props.selectedCycle.duty_cycle_list}
      dutyCycleValuesOverThreshHold={dutyCycleValuesOverThreshHold}
      showAbnormalSeriesValues={showAbnormalSeriesValues}
      abnormalPaValues={props.abnormalValuesObj['pa_list']}
      abnormalIgValues={props.abnormalValuesObj['ig_list']}
      abnormalDutyCycleValues={props.abnormalValuesObj['duty_cycle_list']}
      zoomParams={props.zoomParams}
      setZoomParams={props.setZoomParams}
      overlayedCycles={props.overlayedCycles}
      cycleStartTime={props.cycleStartTime}
    />
    }
    {
      (props.cycleType === 'GrindCycle' && showCurrentGraph) &&
      <CurrentChart
        timeList={props.selectedCycle.time_list}
        iList={props.cycleType === 'GrindCycle' ? props.selectedCycle.ig_list : props.selectedCycle.i_list}
        plotBands={plotBands}
        overlayInfo={overlayInfo}
        infoConstants={props.selectedCycle.info_constants}
        combineTPGraphs={props.cycleType === 'GrindCycle' ? false : combineTPGraphs}
        showAbnormalSeriesValues={showAbnormalSeriesValues}
        abnormalIValues={props.cycleType === 'GrindCycle' ? props.abnormalValuesObj['ig_list'] : props.abnormalValuesObj['i_list']}
        customTitlePrefix={props.customTitlePrefix}
        zoomParams={props.zoomParams}
        setZoomParams={props.setZoomParams}
        overlayedCycles={props.overlayedCycles}
        cycleStartTime={props.cycleStartTime}
        isTPACurrentGraph={props.cycleType === 'SteamCycle'}
        cycleType={props.cycleType}

      />
    }
    {
      props.showSteamCycleTrends &&
      <SteamCycleTrendsChart
        scoreTrend={props.scoreTrend}
        tvAvgHoldTrend={props.tvAvgHoldTrend}
        tpDiffTrend={props.tpDiffTrend}
        durationTrend={props.durationTrend}
        cycleStartTimes={props.steamCycleStartTimes}
        flowSecondsTimeTrend={props.flowSecondsTimeTrend}
        steamPumpdownTimeTrend={props.steamPumpdownTimeTrend}
        drainTimeTrend={props.drainTimeTrend}
        heatUpTimeTrend={props.heatUpTimeTrend}
        pumpdownTimeoutTrend={props.pumpdownTimeoutTrend}
        tvStartTrend={props.tvStartTrend}
        tvMaxTrend={props.tvMaxTrend}
        trAvgTrend={props.trAvgTrend}
        tvAvgDuringHoldTrend={props.tvAvgDuringHoldTrend}
        pvStartTrend={props.pvStartTrend}
        pvMinTrend={props.pvMinTrend}
        pvMaxTrend={props.pvMaxTrend}
        pvAvgDuringHoldTrend={props.pvAvgDuringHoldTrend}
        heatUpRateDifferenceTrend={props.heatUpRateDifferenceTrend}

        trendCycleScore={props.trendCycleScore}
        trendCycleDuration={props.trendCycleDuration}
        trendTvAvgHold={props.trendTvAvgHold}
        trendTPDiff={props.trendTPDiff}
        trendFlowSeconds={props.trendFlowSeconds}
        trendPumpdownDuration={props.trendPumpdownDuration}
        trendDrainingTime={props.trendDrainingTime}
        trendHeatUpTime={props.trendHeatUpTime}
        trendPumpdownTimeouts={props.trendPumpdownTimeouts}
        trendTVStart={props.trendTVStart}
        trendTVMax={props.trendTVMax}
        trendTRAverage={props.trendTRAverage}
        trendPVStart={props.trendPVStart}
        trendPVMin={props.trendPVMin}
        trendPVMax={props.trendPVMax}
        trendPVAverageDuringHold={props.trendPVAverageDuringHold}
        trendHeatUpRateDifference={props.trendHeatUpRateDifference}
        hideAxisLabels={props.hideAxisLabels}

        showAbnormalTrendValues={props.showAbnormalTrendValues}

        abnormalScoreValues={props.abnormalValuesObj['score']}
        abnormalFlowSecondsValues={props.abnormalValuesObj['flow_seconds']}
        abnormalSteamPumpdownValues={props.abnormalValuesObj['pumpdown_duration_minutes']}
        abnormalDrainTimeValues={props.abnormalValuesObj['draining_duration_minutes']}
        abnormalHeatUpTimeValues={props.abnormalValuesObj['ramp_duration_minutes']}
        abnormalPumpdownTimeoutsValues={props.abnormalValuesObj['pumpdown_timeout_count']}
        abnormalTPDiffValues={props.abnormalValuesObj['tp_diff']}
        abnormalTvStartValues={props.abnormalValuesObj['tv_start']}
        abnormalTvMaxValues={props.abnormalValuesObj['tv_max']}
        abnormalTrAvgValues={props.abnormalValuesObj['tr_average']}
        abnormalTvAvgDuringHoldValues={props.abnormalValuesObj['tv_average_during_hold']}
        abnormalPvStartValues={props.abnormalValuesObj['pv_start']}
        abnormalPvMinValues={props.abnormalValuesObj['pv_min']}
        abnormalPvMaxValues={props.abnormalValuesObj['pv_max']}
        abnormalPvAvgDuringHoldValues={props.abnormalValuesObj['pv_average_during_hold']}
        abnormalHeatUpRateDifferenceValues={props.abnormalValuesObj['tv_rate_of_rise']}

      />
    }
     
{
      props.showGrindCycleTrends &&
      <GrindCycleTrendsChart
        grindTriesTrend={props.grindTriesTrend}
        grindJackTrend={props.grindJackTrend}
        grindStallsTrend={props.grindStallsTrend}
        grindDurationTrend={props.grindDurationTrend}
        forwardDurationTrend={props.forwardDurationTrend}
        reverseDurationTrend={props.reverseDurationTrend}
        numberStallsTrend={props.numberStallsTrend}
        cycleStartTimes={props.grindCycleStartTimes}

        trendStalls={props.trendStalls}
        trendGrindDuration={props.trendGrindDuration}
        trendJack={props.trendJack}
        trendNumberStalls={props.trendNumberStalls}
        trendTries={props.trendTries}
        trendForwardDuration={props.trendForwardDuration}
        trendReverseDuration={props.trendReverseDuration}

        hideAxisLabels={props.hideAxisLabels}

        showAbnormalTrendValues={props.showAbnormalTrendValues}

        abnormalGrindDurationValues={props.abnormalValuesObj['grind_duration_minutes']}
        abnormalStallsValues={props.abnormalValuesObj['stalls']}
        abnormalJackValues={props.abnormalValuesObj['jack']}
        abnormalNumberStallsValues={props.abnormalValuesObj['number_stalls']}
        abnormalTriesValues={props.abnormalValuesObj['tries']}
        abnormalForwardDurationValues={props.abnormalValuesObj['forward_duration']}
        abnormalReverseDurationValues={props.abnormalValuesObj['reverse_duration']}

      />
    }
    {
      props.showSharedCycleTrends &&
      <SharedCycleTrendsChart
        cycleStartTimes={props.cycleStartTimes}

        dutyCycleAvgTrend={props.dutyCycleAvgTrend}
        dutyCycleStartTrend={props.dutyCycleStartTrend}
        dutyCycleEndTrend={props.dutyCycleEndTrend}

        trendDutyCycleAvg={props.trendDutyCycleAvg}
        trendDutyCycleStart={props.trendDutyCycleStart}
        trendDutyCycleEnd={props.trendDutyCycleEnd}
        hideAxisLabels={props.hideAxisLabels}

        // temporarily hard coded false until we want to trend abnormal duty cycle values
        showAbnormalTrendValues={false}

        abnormalDutyCycleAvgValues={props.abnormalValuesObj['duty_cycle_average']}
        abnormalDutyCycleStartValues={props.abnormalValuesObj['duty_cycle_start']}
        abnormalDutyCycleEndValues={props.abnormalValuesObj['duty_cycle_end']}
      />
    }
  </div>
};

ChartContainer.defaultProps = {
  scoreTrend: [],
  tvAvgHoldTrend: [],
  tpDiffTrend: [],
  durationTrend: [],
  grindTriesTrend: [],
  grindJackTrend: [],
  grindStallsTrend: [],
  grindDurationTrend: [],
  forwardDurationTrend: [],
  reverseDurationTrend: [],
  numberStallsTrend: [],
  dutyCycleAvgTrend: [],
  dutyCycleStartTrend: [],
  dutyCycleEndTrend: [],
  cycleStartTimes: [],
  cycleType: '',
  facilityType: 'RMW',
  infoCount: 0,
  trendCycleOutcome: false,
  trendCycleScore: false,
  trendCycleDuration: false,
  trendTvAvgHold: false,
  trendTPDiff: false,
  trendFlowSeconds: false,
  trendPumpdownDuration: false,
  trendDrainingTime: false,
  trendHeatUpTime: false,
  trendPumpdownTimeouts: false,
  trendTVStart: false,
  trendTVMax: false,
  trendTRAverage: false,
  trendPVStart: false,
  trendPVMin: false,
  trendPVMax: false,
  trendPVAverageDuringHold: false,
  showGrindCycleTrends: false,
  combineGrindCycleTrends: false,
  showSteamCycleTrends: false,
  combineSteamCycleTrends: false,
  showSharedCycleTrends: false,
  hideAxisLabels: false,
  trendGrindDuration: false,
  trendStalls: true,
  trendJack: true,
  trendNumberStalls: true,
  trendTries: true,
  trendForwardDuration: false,
  trendReverseDuration: false,
  initialLoadFromBatch: false,
  showAbnormalTrendValues: false,
  trendDutyCycleAvg: false,
  trendDutyCycleStart: false,
  trendDutyCycleEnd: false,
  abnormalValuesObj: {},
  overlayedCycles: [],

};

ChartContainer.propTypes = {
  selectedCycle: PropTypes.object.isRequired,
  scoreTrend: PropTypes.array,
  tvAvgHoldTrend: PropTypes.array,
  tpDiffTrend: PropTypes.array,
  durationTrend: PropTypes.array,
  grindTriesTrend: PropTypes.array,
  grindJackTrend: PropTypes.array,
  grindStallsTrend: PropTypes.array,
  grindDurationTrend: PropTypes.array,
  cycleStartTimes: PropTypes.array,
  forwardDurationTrend: PropTypes.array,
  reverseDurationTrend: PropTypes.array,
  numberStallsTrend: PropTypes.array,
  dutyCycleAvgTrend: PropTypes.array,
  dutyCycleStartTrend: PropTypes.array,
  dutyCycleEndTrend: PropTypes.array,
  cycleType: PropTypes.string,
  facilityType: PropTypes.string,
  infoCount: PropTypes.number,
  trendCycleOutcome: PropTypes.bool,
  trendCycleScore: PropTypes.bool,
  trendCycleDuration: PropTypes.bool,
  trendTvAvgHold: PropTypes.bool,
  trendTPDiff: PropTypes.bool,
  trendFlowSeconds: PropTypes.bool,
  trendPumpdownDuration: PropTypes.bool,
  trendDrainingTime: PropTypes.bool,
  trendHeatUpTime: PropTypes.bool,
  trendPumpdownTimeouts: PropTypes.bool,
  trendTVStart: PropTypes.bool,
  trendTVMax: PropTypes.bool,
  trendTRAverage: PropTypes.bool,
  trendPVStart: PropTypes.bool,
  trendPVMin: PropTypes.bool,
  trendPVMax: PropTypes.bool,
  trendPVAverageDuringHold: PropTypes.bool,
  showGrindCycleTrends: PropTypes.bool,
  combineGrindCycleTrends: PropTypes.bool,
  showSteamCycleTrends: PropTypes.bool,
  combineSteamCycleTrends: PropTypes.bool,
  showSharedCycleTrends: PropTypes.bool,
  hideAxisLabels: PropTypes.bool,
  trendGrindDuration: PropTypes.bool,
  trendStalls: PropTypes.bool,
  trendJack: PropTypes.bool,
  trendNumberStalls: PropTypes.bool,
  trendTries: PropTypes.bool,
  trendForwardDuration: PropTypes.bool,
  trendReverseDuration: PropTypes.bool,
  initialLoadFromBatch: PropTypes.bool,
  showAbnormalTrendValues: PropTypes.bool,
  trendDutyCycleAvg: PropTypes.bool,
  trendDutyCycleStart: PropTypes.bool,
  trendDutyCycleEnd: PropTypes.bool,
  abnormalValuesObj: PropTypes.object,
  scrollOnCycleClick: PropTypes.bool.isRequired,
  setScrollOnCycleClick: PropTypes.func.isRequired,
  overlayedCycles: PropTypes.array,
};


export default ChartContainer;
