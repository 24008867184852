/*
 *  Component responsible for the Biochallenge widget
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { withTranslation as translate } from "react-i18next";
import { Icon } from "semantic-ui-react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment-timezone";

class BiochallengeChart extends React.Component {
    state = {
        options: {},
    };

    componentDidMount() {
        //If this prop exists, the xhr calls have finished.
        if (this.props.biochallengeInfo) {
            this.populateChart();
        }
    }

    calcBarColor = (xSeriesValue, maxXAxisValue) => {
        if (xSeriesValue / maxXAxisValue > 0.75) {
            return "#01AF00";
        } else if (xSeriesValue / maxXAxisValue > 0.5) {
            return "#f8e24c";
        } else if (xSeriesValue / maxXAxisValue > 0.25) {
            return "#e18a19";
        } else {
            return "#d50100";
        }
    };

    populateChart = () => {
        const { biochallengeInfo, t } = this.props;
        const xSeriesValue = Number(biochallengeInfo["x_series_value"]);
        const maxXAxisValue = Number(biochallengeInfo["x_axis"]);
        const barColor = this.calcBarColor(xSeriesValue, maxXAxisValue);
        const options = {
            chart: {
                type: "bar",
                height: 250,
            },
            title: {
                text: t("Biological Challenge"),
                style: {
                    color: "#009FC2",
                    fontSize: "20px",
                    fontFamily: "Arial",
                },
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                labels: {
                    enabled: false,
                },
                tickLength: 0,
            },
            yAxis: {
                min: 0,
                reversed: true,
                max: maxXAxisValue,
                title: {
                    text: `${biochallengeInfo["type"] === "calendar" ? t("Days left") : t("Cycles left")}`,
                    align: "high",
                },
                labels: {
                    overflow: "justify",
                },
            },
            tooltip: {
                enabled: false,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: `{y} ${biochallengeInfo["type"] === "calendar" ? t("days") : t("cycles")} ${t(
                            "remain"
                        )}`,
                    },
                },
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    name: "",
                    data: [xSeriesValue],
                    color: barColor,
                },
            ],
        };
        this.setState({
            options,
        });
    };

    cleanLastBiochallenge = (lastBiochallengeEnd, biochallengeResult, biochallengeStatus) => {
        const { userTimezone, deviceTimezone, t } = this.props;
        let cleanStatus = "";
        if (biochallengeResult === 1) {
            cleanStatus = t("Validated");
        } else {
            cleanStatus = biochallengeStatus;
        }
        return `${moment(lastBiochallengeEnd)
            .tz(deviceTimezone ? deviceTimezone : userTimezone)
            .format("YYYY-MM-DD HH:mm:ss z")} (${cleanStatus})`;
    };

    biochallengePanel = (biochallengeInfo) => {
        const { t, userTimezone, deviceTimezone } = this.props;
        const lastBiochallengeOperator = biochallengeInfo["last_biochallenge_info"]["last_biochallenge_operator"];
        const lastbiochallengedate =
            biochallengeInfo["last_biochallenge_info"]["last_biochallenge_result"] === 1 ||
            !biochallengeInfo["last_biochallenge_info"]["last_biochallenge_start"]
                ? biochallengeInfo["last_biochallenge_info"]["last_biochallenge_validation_date"]
                : biochallengeInfo["last_biochallenge_info"]["last_biochallenge_start"];

        const lastBiochallenge = this.cleanLastBiochallenge(
            lastbiochallengedate,
            biochallengeInfo["last_biochallenge_info"]["last_biochallenge_result"],
            biochallengeInfo["last_biochallenge_info"]["last_biochallenge_status_msg"]
        );

        if (biochallengeInfo["type"] === "calendar") {
            const nextBiochallenge = moment(biochallengeInfo["next_biochallenge_date"])
                .tz(deviceTimezone ? deviceTimezone : userTimezone)
                .format("YYYY-MM-DD HH:mm:ss z");
            return (
                <div className="biochallenge-info">
                    <strong>{t("Next Biological Challenge start date")} </strong>
                    {nextBiochallenge}
                    <strong>{t("Last Biological Challenge date")}</strong>
                    {lastBiochallenge}
                    <strong>{t("Run by")}: </strong>
                    {lastBiochallengeOperator}
                </div>
            );
        } else if (biochallengeInfo["type"] === "usage") {
            const cyclesLeft = biochallengeInfo["x_series_value"];
            return (
                <div className="biochallenge-info">
                    <strong>{t("Estimated Cycles Left until next Biochallenge")} </strong>
                    {cyclesLeft}
                    <strong>{t("Last Biological Challenge date")}</strong>
                    {lastBiochallenge}
                    <strong>{t("Run by")}: </strong>
                    {lastBiochallengeOperator}
                </div>
            );
        }
    };

    render() {
        const { biochallengeInfo, t } = this.props;
        const biochallengePanel = this.biochallengePanel(biochallengeInfo);
        const xSeriesValue = biochallengeInfo["x_series_value"];
        return (
            <div className={this.props.graphContainerClass}>
                <div id="biochallengeChart" className={this.props.className}>
                    {/*Only render the chart if the days remaining are gte 0*/}
                    {xSeriesValue >= 0 ? (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.options}
                            updateArgs={[true, true, true]}
                        />
                    ) : (
                        <div className="biochallenge-required-now-dialogue">
                            <div className="biochallenge-required-now-icon">
                                <Icon size="large" name="exclamation triangle" />
                            </div>
                            <div>
                                {t(
                                    "A Biological Challenge must be run before this device can run a Sterilization cycle"
                                )}
                                .
                            </div>
                        </div>
                    )}
                </div>
                {biochallengePanel}
            </div>
        );
    }
}

export default translate("translations")(BiochallengeChart);
