/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ChartOptions from "./ChartOptions";
import BurninScore from "./BurninScore";
import AbnormalValuesPopup from "./AbnormalValuesPopup";
import BurninStats from "./BurninStats";
import ExportBurninResults from "./ExportBurninResults";
import AuthService from "../AuthService";
import SimpleLoader from "../SimpleLoader";
import moment from "moment";
import { useHistory } from "react-router-dom";


const Auth = new AuthService();
const BurninStatChartContainer = props => {
  let history = useHistory();
  const [chartOptions, _setChartOptions] = useState({
    combineTPGraphs: true,
    showCurrentGraph: true,
    showTempConstants: true,
    showPresConstants: true,
    overlayStates: false,
    overlayInfo: false,
    plotDutyCycle: false,
    showAbnormalSeriesValues: false,
  });

  const setChartOptions = (newState) => _setChartOptions({...chartOptions, ...newState});

  const [digitalOutputOverlays, _setDigitalOutputOverlays] = useState({
    overlaySol1: false,
    overlaySol2: false,
    overlaySol3: false,
    overlaySol4: false,
    overlaySol5: false,
    overlaySol6: false,
    overlaySol7: false,
    overlaySol8: false,
    overlaySol9: false,
    overlaySol10: false,
    overlaySol11: false,
    overlaySol12: false,
    overlaySol13: false,
    overlaySol14: false,
    overlaySol15: false,
    overlaySol16: false,
    overlaySol17: false,
    overlaySol18: false,
    overlayVacPump: false,
    overlayAirPump: false,
    overlayWaterPump: false,
    overlayHeaters: false,
    overlayDeodorizerLED: false,
    overlayGrinderOn: false,
    overlayGrinderForward: false,
    overlayOil: false,
    overlayOilLevels: false,
  });

  const setDigitalOutputOverlays = (newState) => _setDigitalOutputOverlays({...digitalOutputOverlays, ...newState});

  const [burninTestCycles, setBurninTestCycles] = useState({});
  const [hasMounted, setHasMounted] = useState(false);


  const findBurninTestCycle = ({cycles}) => {
    if (cycles && Array.isArray(cycles)) {
      if (cycles.length === 1) {
        return cycles[0];
      } else {
        console.log("%c!!!** more than 1 cycle in burnin test **!!!", "color: red; font-size: x-large")
      }
    }
  };

  useEffect(() => {
    const burninTestCycles = {};
    props.parsedLog.filter(log => log.cycle_type === 'BurninTest').forEach(log => {
      burninTestCycles[log['test_type']] = findBurninTestCycle(log);
    });
    setBurninTestCycles(burninTestCycles)
  }, [props.parsedLog.length]);


  const [burninTest1, setBurninTest1] = useState({});
  const [burninTest2, setBurninTest2] = useState({});
  const [burninTest3, setBurninTest3] = useState({});
  const [burninTest4, setBurninTest4] = useState({});
  const [burninTest5, setBurninTest5] = useState({});
  const [burninTest6, setBurninTest6] = useState({});
  const [burninTest7, setBurninTest7] = useState({});

  const [burninTest1List, setBurninTest1List] = useState([]);
  const [burninTest2List, setBurninTest2List] = useState([]);
  const [burninTest3List, setBurninTest3List] = useState([]);
  const [burninTest4List, setBurninTest4List] = useState([]);
  const [burninTest5List, setBurninTest5List] = useState([]);
  const [burninTest6List, setBurninTest6List] = useState([]);
  const [burninTest7List, setBurninTest7List] = useState([]);

  // get burnin cycle stats
  useEffect(() => {
    const burninTest1List = [];
    const burninTest2List = [];
    const burninTest3List = [];
    const burninTest4List = [];
    const burninTest5List = [];
    const burninTest6List = [];
    props.parsedLog.filter(log => log.cycle_type !== 'Notification').forEach(log => {
      if (log['test_type'] === 1) {
        burninTest1List.push(log);
      }
      if (log['test_type'] === 2) {
        burninTest2List.push(log);
      }
      if (log['test_type'] === 3) {
        burninTest3List.push(log);
      }
      if (log['test_type'] === 4) {
        burninTest4List.push(log);
      }
      if (log['test_type'] === 5) {
        burninTest5List.push(log);
      }
      if (log['test_type'] === 6) {
        burninTest6List.push(log);
      }


    }, []);

    setBurninTest1List(burninTest1List);
    setBurninTest2List(burninTest2List);
    setBurninTest3List(burninTest3List);
    setBurninTest4List(burninTest4List);
    setBurninTest5List(burninTest5List);
    setBurninTest6List(burninTest6List);
    setHasMounted(true)

  }, [props.parsedLog.length]);

  const [testLoading, setTestLoading] = useState(false);
  const fetchBurninTest7Cycles = (test7Cycles) => {
    const cycleData = test7Cycles || props.burninTest7Cycles;
    setTestLoading(true);
    Auth.fetch(`/log_parser/parse_cycle`, {
      method: 'POST',
      body: JSON.stringify({
        cycle_data: cycleData,
        file_type: 'cycles'
      })
    }).then(data => {
      const test7Cycles = cycleData.map(cycle => {
        return cycle['cycle_id'];
      });

      if(test7Cycles.length > 0){
        const currentUrl = `${history.location.pathname}${history.location.search}`;
        history.push(`${currentUrl}&test_7_cycle_ids=${test7Cycles.join(',')}`);
      }

      props.setShowBurninTest7CycleSelect(false);
      setBurninTest7List(data['cycle_list']);
      setTestLoading(false);
    }).catch(err => {
      setTestLoading(false);
      console.log(err);
    });
  };
  
  useEffect(() => {
    // we need to create an array of objects similar to the one generated by the setBurninTest7Cycles function, so
    // that the frontend can send a call to the server and the server can re-create the bit7 result 
    // for the cycle ids provided in the URL
    const test7RequestData = props.parsedLog.filter(cycle => {
      // care only about steam cycles here, grind cycles may have cycle ids attached to them to relate them back
      // to their steam cycle
      if (cycle['cycle_type'] === 'SteamCycle') {
        if (props.test7CycleIDs.includes(cycle['cycle_id'])) {
          let deviceConfigId = null;
          let cycleID = null;
          if (cycle['portal_cycle']) {
            if (cycle['portal_cycle']['device_config']) {
              deviceConfigId = cycle['portal_cycle']['device_config']['id'];
              cycleID = cycle['cycle_id'];
            }
          }
          return {
            file_id: cycle['file_id'],
            cycle_start_line_number: cycle['cycle_start_line_number'],
            cycle_end_line_number: cycle['cycle_end_line_number'],
            start_time: cycle['start_time'],
            cycle_id: cycleID,
            device_config_id: deviceConfigId
          }

        }
      }
    });

    test7RequestData.length > 0 && fetchBurninTest7Cycles(test7RequestData)

  }, [props.test7CycleIDs]);


  const test1Header = 'Test 1: Extended Grind Run -';
  const test2Header = 'Test 2: Automated Kinematics -';
  const test3Header = 'Test 3: Long Steam Cycle -';
  const test4Header = 'Test 4: # Of Burps -';
  const test5Header = 'Test 5: Lid Motion after Heat -';
  const test6Header = 'Test 6: Towel Roll Test -';
  const test7Header = 'Test 7: Summary of 4 UI Runs -';

  const [burninScore, _setBurninScore] = useState(null);
  const [scoreCalculated, setScoreCalculated] = useState(false);

  const setBurninScore = React.useCallback(score => {
    _setBurninScore(score);
  }, []);


  // These were originally abstracted into their own arrays instead of defined inside the <BurninScore/> props
  // in order to dynamically build the Export ability, but it's not worth it at this time IMO
  const burninTest1Metrics = [
    'm0_current',
    'm30_current',
    'growth_factor',
    'max_duty_cycle',
    'tooth_temperature'
  ];

  const burninTest3Metrics = [
    'pv_min',
    'tr_max',
    'tr_average_during_hold',
    'pv_high_hold_start',
    'pv_high_hold_end',
    'pv_drift',
    'pv_average_during_hold',
    'tp_diff',
    'heat_up_rate_delta',
    'score'
  ];

  const burninTest4Metrics = [
    'stag_recovery_count',
    'prv_drain_temp'
  ];

  const burninTest5Metrics = [
    'pv_min',
    'tr_max',
    'tr_average_during_hold',
    'pv_average_during_hold',
    'open_bottomlid_seconds',
    'open_toplid_seconds',
    'tp_diff',
    'heat_up_rate_delta',
    'score'
  ];

  const burninTest6Metrics = [
    'grinder_current_hi',
    'grinder_current_low',
    'grinder_current_noise',
    'ia_at_end',
    'grinder_time_seconds',
    'final_duty_cycle',
    'number_stalls',
  ];

  const burninTest7Metrics = [
    'pv_min',
    'pumpdown_duration_minutes',
    'tr_average_during_ramp',
    'flow_seconds',
    'heatup_time',
    'pv_average_during_hold',
    'tv_average_during_hold',
    'tr_average_during_hold',
    'draining_duration_minutes',
    'duty_cycle_end',
    'duration_minutes',
    'score',
  ];

  const heatUpRateDefinitions = {
    'heat_up_rate_delta': [
      'th_initial',
      {
        'name': 'heat_up_time_at_pres',
        'format': (value) => moment.utc(value).format('HH:mm:ss')
      },
      'heat_up_tv_at_pres',
      {
        'name': 'heat_up_time_at_heat_up',
        'format': (value) => moment.utc(value).format('HH:mm:ss')
      },
      {
        'name': 'heat_up_time_delta',
        'format': (value) => {
          return moment.duration(Number(value), 'seconds').humanize()
        }
      },
      'typical_rate_at_th_initial',
      'tv_rate_of_rise',
    ]
  };

  return <div>
    {scoreCalculated === false && <SimpleLoader loaderText='Preparing Burnin View...'/>}
    <div className="flex-dir-row even-split">
      {props.parserLeftHeader}
      <div className="flex-dir-column">
        <ExportBurninResults
          burninTest1={burninTest1}
          burninTest2={burninTest2}
          burninTest3={burninTest3}
          burninTest4={burninTest4}
          burninTest5={burninTest5}
          burninTest6={burninTest6}
          burninTest7={burninTest7}
          deviceSerial={props.deviceSerial}
          burninScore={burninScore}
        />
      </div>
    </div>
    <ChartOptions
      useDrawer={true}
      containsBurninTest={true}
      cycleType={props.cycleType}
      infoCount={props.infoCount}
      chartOptions={chartOptions}
      setChartOptions={setChartOptions}
      digitalOutputOverlays={digitalOutputOverlays}
      setDigitalOutputOverlays={setDigitalOutputOverlays}
      scrollOnCycleClick={props.scrollOnCycleClick}
      setScrollOnCycleClick={props.setScrollOnCycleClick}

    />
    {hasMounted &&
    <BurninScore
      burninTest1={burninTest1}
      burninTest2={burninTest2}
      burninTest3={burninTest3}
      burninTest4={burninTest4}
      burninTest5={burninTest5}
      burninTest6={burninTest6}
      burninTest7={burninTest7}
      abnormalBurninValues={props.abnormalBurninValues}
      burninScore={burninScore}
      setBurninScore={setBurninScore}
      setScoreCalculated={setScoreCalculated}
    />
    }
    <div className='burnin-abnormal-values-container'>
      <AbnormalValuesPopup
        abnormalValues={props.abnormalValues}
        containsBurninTest={true}
      />
    </div>
    {hasMounted &&
    <React.Fragment>
      <BurninStats
        testNumber={1}
        setBurninTest={setBurninTest1}
        burninTestList={burninTest1List}
        testTitle={test1Header}
        abnormalValuesForBurninTest={props.abnormalBurninValues['1']}
        burninStatInternalNames={burninTest1Metrics}
        digitalOutputOverlays={digitalOutputOverlays}
        chartOptions={chartOptions}
        burninTestCycle={burninTestCycles[1]}
        abnormalValuesObj={props.abnormalValuesObj}
      />
      <BurninStats
        testNumber={2}
        burninTestList={burninTest2List}
        testTitle={test2Header}
        abnormalValuesForBurninTest={props.abnormalBurninValues['2']}
        usePercent={true}
        burninStatInternalNames={['scj_percent', 'shroud_open_percent', 'shroud_close_percent']}
        subBurninStatInternalNames={{
          'scj_percent': ['scissor_jack_count', 'scj_extension_succeeds', 'scj_extension_fails', 'scj_extension_avg_time',
            'scj_retraction_succeeds', 'scj_retraction_fails', 'scj_retraction_avg_time',],
          'shroud_open_percent': ['shroud_open_succeeds', 'shroud_open_fails', 'shroud_open_avg_time'],
          'shroud_close_percent': ['shroud_close_succeeds', 'shroud_close_fails', 'shroud_close_avg_time']
        }}
        setBurninTest={setBurninTest2}
      />
      <BurninStats
        testNumber={3}
        burninTestList={burninTest3List}
        testTitle={test3Header}
        abnormalValuesForBurninTest={props.abnormalBurninValues['3']}
        burninStatInternalNames={burninTest3Metrics}
        subBurninStatInternalNames={heatUpRateDefinitions}
        setBurninTest={setBurninTest3}
        digitalOutputOverlays={digitalOutputOverlays}
        chartOptions={chartOptions}
        burninTestCycle={burninTestCycles[3]}
        abnormalValuesObj={props.abnormalValuesObj}
      />
      <BurninStats
        testNumber={4}
        burninTestList={burninTest4List}
        testTitle={test4Header}
        abnormalValuesForBurninTest={props.abnormalBurninValues['4']}
        burninStatInternalNames={burninTest4Metrics}
        setBurninTest={setBurninTest4}
        digitalOutputOverlays={digitalOutputOverlays}
        chartOptions={chartOptions}
        burninTestCycle={burninTestCycles[4]}
        abnormalValuesObj={props.abnormalValuesObj}
      />
      <BurninStats
        testNumber={5}
        burninTestList={burninTest5List}
        testTitle={test5Header}
        abnormalValuesForBurninTest={props.abnormalBurninValues['5']}
        burninStatInternalNames={burninTest5Metrics}
        setBurninTest={setBurninTest5}
        digitalOutputOverlays={digitalOutputOverlays}
        subBurninStatInternalNames={heatUpRateDefinitions}
        chartOptions={chartOptions}
        burninTestCycle={burninTestCycles[5]}
        abnormalValuesObj={props.abnormalValuesObj}
      />
      <BurninStats
        testNumber={6}
        burninTestList={burninTest6List}
        testTitle={test6Header}
        abnormalValuesForBurninTest={props.abnormalBurninValues['6']}
        burninStatInternalNames={burninTest6Metrics}
        setBurninTest={setBurninTest6}
        digitalOutputOverlays={digitalOutputOverlays}
        chartOptions={chartOptions}
        burninTestCycle={burninTestCycles[6]}
        abnormalValuesObj={props.abnormalValuesObj}
      />
      <BurninStats
        testNumber={7}
        burninTestList={burninTest7List}
        testTitle={test7Header}
        abnormalValuesForBurninTest={props.abnormalBurninValues['7']}
        burninStatInternalNames={burninTest7Metrics}
        setBurninTest={setBurninTest7}
        digitalOutputOverlays={digitalOutputOverlays}
        chartOptions={chartOptions}
        burninTestCycle={burninTestCycles[7]}
        abnormalValuesObj={props.abnormalValuesObj}
        selectCyclesForBurninTest7Toggle={props.selectCyclesForBurninTest7Toggle}
        burninTest7Cycles={props.burninTest7Cycles}
        fetchBurninTest7Cycles={fetchBurninTest7Cycles}
        testLoading={testLoading}

      />
    </React.Fragment>
    }

  </div>
};


BurninStatChartContainer.defaultProps = {
  parsedLog: [],
  abnormalValues: [],
  abnormalValuesObj: {},
  abnormalBurninValues: {},
  showBurninTest3Chart: true,
  showBurninTest4Chart: false,
  showBurninTest5Chart: false,
  showBurninTest6Chart: false,
  deviceSerial: '',
  burninTest7Cycles: [],
  test7CycleIDs: [],

};

BurninStatChartContainer.propTypes = {
  parsedLog: PropTypes.array,
  abnormalValues: PropTypes.array,
  abnormalValuesObj: PropTypes.object,
  abnormalBurninValues: PropTypes.object,
  showBurninTest3Chart: PropTypes.bool,
  showBurninTest4Chart: PropTypes.bool,
  showBurninTest5Chart: PropTypes.bool,
  showBurninTest6Chart: PropTypes.bool,
  deviceSerial: PropTypes.string,
  parserLeftHeader: PropTypes.element.isRequired,
  scrollOnCycleClick: PropTypes.bool.isRequired,
  setScrollOnCycleClick: PropTypes.func.isRequired,
  setShowBurninTest7CycleSelect: PropTypes.func.isRequired,
  selectCyclesForBurninTest7Toggle: PropTypes.element.isRequired,
  burninTest7Cycles: PropTypes.array,
  test7CycleIDs: PropTypes.array,
};

export default BurninStatChartContainer;
