import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setRefreshInterval,
    fetchDeviceCycles,
    fetchBiochallengeInfo,
    fetchCycleEvents,
} from "../deviceDashboardSlice";
import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";

const RefreshTimer = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    // @ts-ignore
    const { refreshInterval, noCyclesFound } = useSelector((store) => store.devicedashboard);

    const [timer, setTimer] = React.useState(refreshInterval);
    const [prevInterval, setPrevInterval] = React.useState(refreshInterval);

    const refresh = () => {
        // @ts-ignore
        dispatch(fetchDeviceCycles());
        // @ts-ignore
        dispatch(fetchBiochallengeInfo());
        // @ts-ignore
        dispatch(fetchCycleEvents());
    };

    const handleRefreshChange = (event) => {
        dispatch(setRefreshInterval(event.target.value));
    };

    React.useEffect(() => {
        const refreshTimer = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
        return () => clearInterval(refreshTimer);
    }, []);

    React.useEffect(() => {
        if (prevInterval !== refreshInterval) {
            setPrevInterval(refreshInterval);
            setTimer(refreshInterval);
        }
        if (timer === 0 && !noCyclesFound) {
            setTimer(refreshInterval);
            refresh();
        }
    }, [timer, refreshInterval]);

    return (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            {noCyclesFound ? null : (
                <Stack direction={"row"} spacing={4} mr={{ xs: 0, lg: 2, xl: 3 }}>
                    <FormControl>
                        <InputLabel id="refresh-interval-select-label">Refresh Interval</InputLabel>
                        <Select
                            id="refresh-interval-select"
                            sx={{ width: 150 }}
                            labelId="refresh-interval-select-label"
                            value={refreshInterval}
                            onChange={handleRefreshChange}
                            size="small"
                            label="Refresh Interval">
                            <MenuItem value={30}>30s</MenuItem>
                            <MenuItem value={60}>60s</MenuItem>
                            <MenuItem value={120}>2m</MenuItem>
                            <MenuItem value={300}>5m</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ position: "relative", display: "inline-flex", color: theme.palette.secondary.light }}>
                        <CircularProgress
                            color="inherit"
                            variant="determinate"
                            value={(timer / refreshInterval) * 100}
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <Typography variant="caption" component="div" color="text.secondary">
                                {`${Math.round(timer)}s`}
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
            )}
        </Box>
    );
};

export default RefreshTimer;
