import { Box, Card, CircularProgress, Typography, useTheme } from "@mui/material";
import Highcharts from "highcharts/highstock";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardContent } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import SterilisChart from "src/components/material/Highcharts/SterilisChart";
import SubCard from "src/components/material/Cards/SubCard";

const CyclesByOperatorPanel = (props) => {
    const dispatch = useDispatch();
    // @ts-ignore
    const { deviceCycles } = useSelector((store) => store.devicedashboard);
    const { t } = useTranslation("translations");
    const [options, setOptions] = useState(null);

    const theme = useTheme();

    // @ts-ignore
    const loading = useSelector((store) => store.devicedashboard.loading.cyclesByOperator);

    const searchObj = (nameKey, myArray) => {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].key === nameKey) {
                return true;
            }
        }
        return false;
    };

    const calculateTopUsers = (deviceCycles) => {
        const userCycles = {};
        deviceCycles.forEach((cycle) => {
            const currentUser = cycle["full_name"];
            userCycles.hasOwnProperty(currentUser) ? userCycles[currentUser]++ : (userCycles[currentUser] = 1);
        });

        const props = Object.keys(userCycles).map(function (key) {
            return { key: key, value: this[key] };
        }, userCycles);
        props.sort(function (p1, p2) {
            return p2.value - p1.value;
        });
        const topUsers = props.slice(0, 5);

        const finalUserObj = {};
        finalUserObj["Other"] = 0;
        const otherUserObj = {};
        for (let user in userCycles) {
            if (searchObj(user, topUsers)) {
                finalUserObj[user] = userCycles[user];
            } else {
                finalUserObj["Other"] += userCycles[user];
                otherUserObj[user] = userCycles[user];
            }
        }
        //If there are 0 'Other' users, just remove the 'Other' key
        finalUserObj["Other"] === 0 && delete finalUserObj["Other"];

        const seriesData = [];
        Object.keys(finalUserObj).forEach((user) => {
            seriesData.push({
                name: user,
                y: finalUserObj[user],
            });
        });
        return seriesData;
    };

    const colors = [
        theme.palette.secondary[200],
        theme.palette.success[200],
        theme.palette.info.light,
        theme.palette.primary[200],
        theme.palette.error[200],
    ];

    const populateChart = () => {
        const deviceUserSeries = calculateTopUsers(deviceCycles);
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: t("Cycles By Device Operator"),
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                pointFormat: "{series.name} <b>{point.percentage:.1f}% : {point.y} cycles</b>",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    size: "80%",
                    innerSize: "50%",
                    colors: colors,
                    dataLabels: {
                        enabled: true,
                        distance: "10%",
                        allowOverlap: false,
                        connectorWidth: 2,
                        format: `<b>{point.name}</b><br>{point.y} ${t("cycles")}<br>{point.percentage:.1f}%`,
                    },
                    showInLegend: false,
                },
            },
            series: [
                {
                    name: t("User"),
                    colorByPoint: true,
                    data: deviceUserSeries,
                },
            ],
        };
        setOptions(options);
    };

    useEffect(() => {
        populateChart();
    }, [deviceCycles]);

    return (
        <SubCard sx={{ width: "100%" }}>
            <CardContent>
                {loading ? (
                    <Box sx={{ p: 20, display: "flex", alignContent: "center", justifyContent: "center" }}>
                        <CircularProgress color="secondary" size={60} />
                    </Box>
                ) : (
                    <Box sx={{ marginTop: 2 }}>
                        <SterilisChart highcharts={Highcharts} options={options} updateArgs={[true, true, true]} />
                    </Box>
                )}
            </CardContent>
        </SubCard>
    );
};

export default CyclesByOperatorPanel;
