/*
*  @prop refreshData is an async function containing await(s) which fetch data
*  @prop countdownText is a text string to display next to the countdown
*  @prop countdownStart is a number which the counterdown will begin counting down from
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';

class SimpleRefreshTimer extends React.Component{
  state = {
    secondsUntilRefresh: 40,
    pageRefreshing : false
  };
  componentDidMount(){
    this.secondsUntilRefresh = setInterval(() => {
      if (this.state.secondsUntilRefresh > 0) {
        this.setState(prevState => {
          return {
            secondsUntilRefresh: prevState.secondsUntilRefresh - 1
          };
        });
      } else {
        const {pageRefreshing} = this.state;
        if (!pageRefreshing) {
          this.setState({
            pageRefreshing: true
          });
          this.props.refreshData().then(() => {
            this.setState({
              secondsUntilRefresh: 40,
              pageRefreshing: false
            });
          });
        }
      }
    }, 1000);
  }
  render() {
    const { secondsUntilRefresh, pageRefreshing } = this.state;
    const { countdownText } = this.props;
    return (
      <div className="refresh-container">
        {pageRefreshing ? (
          <span>
            Refreshing...
          </span>
        ) : (
          <span>
            {countdownText}... {secondsUntilRefresh}
          </span>
        )}
      </div>
    );
  }
}

export default SimpleRefreshTimer;
