/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from "semantic-ui-react";
import AbnormalValuesTable from "./AbnormalValuesTable";

const AbnormalValuesPopup = props => {
  return <React.Fragment> {
    props.abnormalValues.length > 0 &&
    <Modal
      on='click'
      offset='0, 50px'
      position='left center'
      closeIcon={<Button className='close-modal-btn grey-btn'>Close</Button>}
      trigger={<Button className={`grey-btn flex-self-center important-medium-margin-bottom`}
                       content={props.containsBurninTest ? 'Show Burnin Test Parameters' : `Show Abnormal Value Definitions`}/>}
    >
      <Modal.Header>
        <h2 className="red-text">
          {props.containsBurninTest ? 'Abnormal Burnin Parameters' : 'Abnormal Value Parameters'}</h2>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AbnormalValuesTable
            abnormalValues={props.abnormalValues}
            containsBurninTest={props.containsBurninTest}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className='modal-actions-container'/>

    </Modal>
  } </React.Fragment>
};

AbnormalValuesPopup.defaultProps = {
  abnormalValues: [],
  containsBurninTest: false,
};

AbnormalValuesPopup.propTypes = {
  abnormalValues: PropTypes.array,
  containsBurninTest: PropTypes.bool,
};

export default AbnormalValuesPopup;
