/*
* Error List file
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

export const critical = ['3-0004','4-0000', '6-0001', '6-0040'];

export const minor=['3-0000', '3-0001', '3-0002', '3-0020', '3-0080', '3-2000', '4-0004/5-0004', '4-0010/5-0010', '4-0020/5-0020', '4-0040/5-0040', 
                    '4-0080/5-0080', '4-2000/5-2000', '4-10000/5-10000', '6-0080', '6-0100', '6-0200', '4-4000/5-4000', '6-8000', '3-0100 / 3-00A0','3-0101',
                    '3-0120','3-0150','3-0180','3-01A0','3-0201','3-0202','3-0204','3-1001','3-1002','3-1080','3-1400','4-0004','4-0020', '4-0044', 
                    '4-10000', '4-10080', '4-4000', '4-4080', '5-0020', '5-0140', '5-0200', '5-0202', '5-10000', '5-4000', '6-0000', 'F-FFFF'];

export const major=['3-0008', '3-0010', '3-0040', '3-0100', '3-0400', '3-1000', '4-0001/5-0001', '4-0002/5-0002', '4-0008/5-0008', '5-0040', '5-0041', '5-0080', '5-0090', '5-0100',
                    '4-0100/5-0100', '4-0200/5-0200', '4-0400/5-0400', '4-1000/5-1000', '4-8000/5-8000', '4-20000/5-20000', '4-8000 / 0-0001', '5-0280', '5-0400', '5-0800', '5-0801', '5-1000', 
                    '4-8000 / 0-0002', '4-8000/ 0-0004', '4-8000 / 0-0008', '4-8000 / 0-0010', '4-8000 / 0-0020', '4-8000 / 0-0040', '4-8000 / 0-0080', '5-18000 / 0-0040', '5-2000', '5-20000', 
                    '4-8001 / 0-0100', '5-8000 / 0-0001', '5-8000 / 0-0002', '5-8000/ 0-0004', '5-8000 / 0-0008', '5-8000 / 0-0010', '5-8000 / 0-0020', '6-0048', '6-0084', '6-0090', '6-0108', '6-0240', 
                    '5-8000 / 0-0040', '5-8000 / 0-0080', '5-8001 / 0-0100', '6-0002', '6-0004', '6-0008', '6-0800', '6-1000', '6-2000', '6-4000', '4-0800/5-0800', '6-0280', '6-9002',
                    '0-0001','0-0000','0-0002','0-0004','0-0006','0-0008','0-0010','0-0020','0-0040','0-0041','0-0080','0-0082','0-0100','0-0104','0-0180','0-0186','0-01C0','0-6666','0-88888','0-99999',
                    '3-0005','3-0050','3-0081','3-0082','3-00A0','3-00A2', '3-2001','3-2008','3-2010','3-2040','3-2050','3-2100','3-2120','4-0001', '4-0002', '4-0003','4-0006','4-0008','4-0010','4-0011',
                    '4-0040', '4-0080','4-0084','4-0090','4-0100','4-0102','4-0120','4-0200','4-0201','4-0202','4-0400','4-0480','4-0800','4-0801','4-0802','4-0804','4-0810','4-0820','4-0A02','4-1000',
                    '4-1040','4-1800','4-18080 / 0-0000','4-2000','4-20000','4-20001','4-2800', '4-8000', '4-8000 / 0-0000', '4-8000 / 0-0003', '4-8000 / 0-0004', '4-8000 / 0-0006', '4-8000 / 0-0009', 
                    '4-8000 / 0-0041', '4-8000 / 0-0082', '4-8000 / 0-0100', '4-8000 / 0-0102', '4-8000 / 0-0104', '4-8000 / 0-0106', '4-8000 / 0-0180', '4-8000 / 0-01C0', '4-8001', '4-8001 / 0-0080', 
                    '4-8001 / 0-0180', '4-8002', '4-8002 / 0-0040 ', '4-8002 / 0-0180', '4-8004', '4-8004 / 0-0180', '4-8004 / 0-0186', '4-8010', '4-8010 / 0-0000', '4-8010 / 0-0100', '4-8010 / 0-0180', 
                    '4-8020 / 0-0040', '4-8080 / 0-0082', '4-8080 / 0-0100', '4-8080 / 0-0104', '4-8200 / 0-0040', '4-8200 / 0-0180', '4-8802', '4-8802 / 0-0180', '4-9000', '4-9000 / 0-0001', '4-C000', 
                    '4-C000 / 0-0100', '4-C000 / 0-0180', '5-0001', '5-0002', '5-0004', '5-0008', '5-0010', '5-4080', '5-8000', '5-8000 / 0-0000', '5-8000 / 0-0006', '5-8000 / 0-0041', '5-8000 / 0-01C0', 
                    '5-8080', '5-8080 / 0-0000', '5-8080 / 0-0004', '5-8080 / 0-0008', '5-8200 / 0-0040', '5-8280 / 0-0000', '5-8280 / 0-0002', '5-C000', '5-C000 / 0-0180', '6-0006', '6-0014', '6-0042', 
                    '7-0001', '7-0002', '7-0004', '7-0010'];