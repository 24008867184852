/*
*  This is the component for the large (width >1200px) dashboard
*
*   It will have three graphs at the top.
*   The cycle table & pressure/temp graph will be parallel to one another at the bottom of the page
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import PressureTemperatureGraph from "./PressureTemperatureGraph";
import UserPieChart from "./UserPieChart";
import CycleSummary from "./CycleSummary";
import BiochallengeChart from "./BiochallengeChart";
import DashboardNav from "./DashboardNav";
import '../../css/dashboard/DashboardMain.css';
import DashboardCycleTable from "./DashboardCycleTable";
import WasteProcessedSummary from "./WasteProcessedSummary";

const DashboardLarge = props => (
  <div className='customer-container'>
    <DashboardNav
      toDateClick={props.toDateClick}
      fromDateClick={props.fromDateClick}
      fromDate={props.fromDate}
      toDate={props.toDate}
      prepareDashboard={props.prepareDashboard}
      customerMachines={props.customerMachines}
      selectDevice={props.selectDevice}
      deviceConfigID={props.deviceConfigID}
      selectedCycle={props.selectedCycle}
      pageInactive={props.pageInactive}
      refreshData={props.refreshData}
      filterCustomerDevices={props.filterCustomerDevices}
    />
    <div className={`flex-row top-row ${props.cycleTableIsExpanded ? ' hidden ' : ''}`}>
      <CycleSummary
        graphContainerClass='graph-container--large'
        className='flex-item'
        dashboardResized={true}
        deviceCycles={props.deviceCycles}
        userTimezone={props.userTimezone}
        deviceFacility={props.deviceFacility}

      />
      <UserPieChart
        graphContainerClass='graph-container--large'
        className='flex-item'
        dashboardResized={true}
        deviceCycles={props.deviceCycles}

      />
      <WasteProcessedSummary
        graphContainerClass='graph-container--large'
        className='flex-item'
        dashboardResized={true}
        deviceCycles={props.deviceCycles}
        userTimezone={props.userTimezone}
        deviceFacility={props.deviceFacility}
        weight_unit={props.weight_unit}
      />
      
    </div>
    <div className={`${props.cycleTableIsExpanded ? 'flex-row-expanded' : 'flex-row'}`}>
      <DashboardCycleTable
        deviceConfigID={props.deviceConfigID}
        deviceCycles={props.deviceCycles}
        userTimezone={props.userTimezone}
        deviceTimezone={props.deviceTimezone}
        className='flex-item'
        fetchCycleEvents={props.fetchCycleEvents}
        complianceReport={props.complianceReport}
        comprehensiveReport={props.comprehensiveReport}
        setComprehensiveReport={props.setComprehensiveReport}
        setComplianceReport={props.setComplianceReport}
        expandCycleTable={props.expandCycleTable}
        cycleTableIsExpanded={props.cycleTableIsExpanded}
        fromDate={props.fromDate}
        toDate={props.toDate}
        deviceFacilityFullAddress={props.deviceFacilityFullAddress}
        isAirport={props.biochallengeInfo['is_airport']}
        deviceSerial={props.deviceSerial}
        currentPressureUnit={props.currentPressureUnit}
      />
      <div className={`${props.cycleTableIsExpanded ? 'flex-row-expanded' : 'flex-dir-column'}`}>
        <PressureTemperatureGraph
          graphContainerClass={`${props.cycleTableIsExpanded ? 'graph-container--expanded' : 'graph-container--large top-row'}`}
          // graphContainerClass='graph-container--large'
          className='flex-item'
          dashboardResized={true}
          cycleEvents={props.cycleEvents}
          cycleMetadata={props.cycleMetadata}
          userTimezone={props.userTimezone}
          deviceTimezone={props.deviceTimezone}
          cycleTableIsExpanded={props.cycleTableIsExpanded}
          setCurrentPressureUnit={props.setCurrentPressureUnit}
          currentPressureUnit={props.currentPressureUnit}
          deviceSerial={props.deviceSerial}
        />
        {/*If it's an airport facility, do not render the biochallenge chart*/}
      {props.biochallengeInfo['is_airport'] ?
        null
      : <BiochallengeChart
          graphContainerClass='graph-container--large dashboard-container'
          className='flex-item'
          dashboardResized={true}
          biochallengeInfo={props.biochallengeInfo}
          userTimezone={props.userTimezone}
          deviceTimezone={props.deviceTimezone}

        />}
      </div>
    </div>
  </div>
);

export default translate('translations')(DashboardLarge);
