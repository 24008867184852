/*
 * -placeholder text for new functional component -
 *
 * Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
 */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
    copyToClipboard,
    notifySuccessToast,
    notifyFailureToast,
    getPortalBaseURL,
} from "src/components/library/helpers";
import * as Sentry from "@sentry/browser";
import { UserContext } from "src/components/library/UserContext";
import { useLocation } from "react-router-dom";
import { Box, Fab, Tooltip } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";

const ExportBurninResults = (props) => {
    const user = useContext(UserContext);
    const location = useLocation();

    const createParserUrl = () => {
        let baseUrl = getPortalBaseURL(user["env"]);
        // the query string params in the URL have commas in them, and since we're pasting a CSV into Excel, the commas will
        // automatically force new columns. We must convert the commas to |s,
        // which are also supported by the portal parser as a query string array delimiter
        const queryParamsWithBar = location["search"].split(",").join("|");
        return `${baseUrl}${location["pathname"]}${queryParamsWithBar}`;
    };

    const copyValuesToClipboard = () => {
        const clipboardArray = [];
        const passOrFailStatus = props.burninScore >= 90 ? "Pass" : "Fail";
        clipboardArray.push(passOrFailStatus);
        clipboardArray.push(props.burninScore);
        clipboardArray.push(props.deviceSerial);

        clipboardArray.push(props.burninTest1["m0_current"]);
        clipboardArray.push(props.burninTest1["m30_current"]);
        clipboardArray.push(props.burninTest1["growth_factor"]);
        clipboardArray.push(props.burninTest1["max_duty_cycle"]);
        clipboardArray.push(props.burninTest1["tooth_temperature"]);

        clipboardArray.push(props.burninTest2["scj_percent"]);
        clipboardArray.push(props.burninTest2["scj_extension_avg_time"]);
        clipboardArray.push(props.burninTest2["shroud_open_percent"]);
        clipboardArray.push(props.burninTest2["shroud_open_avg_time"]);
        clipboardArray.push(props.burninTest2["shroud_close_percent"]);
        clipboardArray.push(props.burninTest2["shroud_close_avg_time"]);

        clipboardArray.push(props.burninTest3["pv_min"]);
        clipboardArray.push(props.burninTest3["heat_up_rate_delta"]);
        clipboardArray.push(props.burninTest3["tr_max"]);
        clipboardArray.push(props.burninTest3["tr_average_during_hold"]);
        clipboardArray.push(props.burninTest3["pv_high_hold_start"]);
        clipboardArray.push(props.burninTest3["pv_high_hold_end"]);
        clipboardArray.push(props.burninTest3["pv_drift"]);
        clipboardArray.push(props.burninTest3["pv_average_during_hold"]);
        clipboardArray.push(props.burninTest3["tp_diff"]);
        clipboardArray.push(props.burninTest3["score"]);
        clipboardArray.push(props.burninTest4["stag_recovery_count"]);
        clipboardArray.push(props.burninTest4["prv_drain_temp"]);

        clipboardArray.push(props.burninTest5["pv_min"]);
        clipboardArray.push(props.burninTest5["heat_up_rate_delta"]);
        clipboardArray.push(props.burninTest5["tr_max"]);
        clipboardArray.push(props.burninTest5["tr_average_during_hold"]);
        clipboardArray.push(props.burninTest5["pv_average_during_hold"]);
        clipboardArray.push(props.burninTest5["open_bottomlid_seconds"]);
        clipboardArray.push(props.burninTest5["open_toplid_seconds"]);
        clipboardArray.push(props.burninTest5["tp_diff"]);
        clipboardArray.push(props.burninTest5["score"]);

        clipboardArray.push(props.burninTest6["grinder_current_hi"]);
        clipboardArray.push(props.burninTest6["grinder_current_low"]);
        clipboardArray.push(props.burninTest6["grinder_current_noise"]);
        clipboardArray.push(props.burninTest6["ia_at_end"]);
        clipboardArray.push(props.burninTest6["grinder_time_seconds"]);
        clipboardArray.push(props.burninTest6["final_duty_cycle"]);
        clipboardArray.push(props.burninTest6["number_stalls"]);

        clipboardArray.push(props.burninTest7["pv_min"]);
        clipboardArray.push(props.burninTest7["pumpdown_duration_minutes"]);
        clipboardArray.push(props.burninTest7["tr_average_during_ramp"]);
        clipboardArray.push(props.burninTest7["flow_seconds"]);
        clipboardArray.push(props.burninTest7["heatup_time"]);
        clipboardArray.push(props.burninTest7["pv_average_during_hold"]);
        clipboardArray.push(props.burninTest7["tv_average_during_hold"]);
        clipboardArray.push(props.burninTest7["tr_average_during_hold"]);
        clipboardArray.push(props.burninTest7["draining_duration_minutes"]);
        clipboardArray.push(props.burninTest7["duty_cycle_end"]);
        clipboardArray.push(props.burninTest7["duration_minutes"]);
        clipboardArray.push(props.burninTest7["score"]);

        const url = createParserUrl();
        clipboardArray.push(url);

        // signature field
        clipboardArray.push("");
        // date field
        clipboardArray.push("");

        try {
            copyToClipboard(clipboardArray.join(","));
            notifySuccessToast("Successfully copied Burnin Data to Clipboard!");
        } catch (e) {
            Sentry.captureException(e);
            notifyFailureToast(
                "Failed to copy Burnin Data to Clipboard. Please refresh this page and try again."
            );
        }
    };
    return (
        <Box
            sx={{
                transform: "translateZ(0px)",
                flexGrow: 1,
                position: "fixed",
                bottom: 150,
                right: 30,
                zIndex: 2000,
            }}
        >
            <Tooltip title="Copy Burnin Results to clipboard" placement="left">
                <Fab
                    color="secondary"
                    aria-label="copy"
                    size="small"
                    onClick={() => copyValuesToClipboard()}
                >
                    <CopyAllIcon />
                </Fab>
            </Tooltip>
        </Box>
    );
};

ExportBurninResults.defaultProps = {
    burninTest1: {},
    burninTest2: {},
    burninTest3: {},
    burninTest4: {},
    burninTest5: {},
    burninTest6: {},
    burninTest7: {},
    deviceSerial: "",
    burninScore: 0,
};

ExportBurninResults.propTypes = {
    deviceSerial: PropTypes.string,
    burninTest1: PropTypes.object,
    burninTest2: PropTypes.object,
    burninTest3: PropTypes.object,
    burninTest4: PropTypes.object,
    burninTest5: PropTypes.object,
    burninTest6: PropTypes.object,
    burninTest7: PropTypes.object,
    burninScore: PropTypes.number,
};

export default ExportBurninResults;
