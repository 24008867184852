/*
*  Table which houses the documents which have been uploaded to the Portal.
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import HookTable from "../library/HookTable";

const UploadDocumentTable = props => {
  const columns = [
    {
      Header: props.t('File Name'),
      accessor: 'filename',
    },
    {
      Header: props.t('Description'),
      accessor: 'desc',
    },
    {
      Header: props.t('Upload date'),
      accessor: 'upload_date',
    },
    {
      Header: props.t('Actions'),
      accessor: 'actions',
      manualWidth: 120
    },
  ];
  return (
      <div
        className={props.fromCustomerApp ? 'customer-content' : ''}
      >
        <HookTable
          id='customerSiteAdminTable'
          data={props.documentTableArray}
          columns={columns}
          tableName='uploadDocumentTable'
          defaultSortBy={{id: 'error_time', desc: true}}
        />
      </div>
    )

};
export default translate('translations')(UploadDocumentTable);


