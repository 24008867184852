/*
 *  Component responsible for housing the countdown until the dashboard retrieves fresh data
 *  This component will call DashboardMain's refreshData, it is passed in via props
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
 */
import React from "react";
import { withTranslation as translate } from "react-i18next";
import { Loader } from "semantic-ui-react";

class RefreshCountdownTimer extends React.Component {
  state = {
    secondsUntilRefresh: 40,
    dashboardRefreshing : false
  };
  componentDidMount() {
    this.secondsUntilRefresh = setInterval(() => {
      if (this.state.secondsUntilRefresh > 0) {
        this.setState(prevState => {
          return {
            secondsUntilRefresh: prevState.secondsUntilRefresh - 1
          };
        });
      } else {
        const { selectedCycle, pageInactive } = this.props;
        const {dashboardRefreshing} = this.state;
        if (!pageInactive && !dashboardRefreshing) {
          this.setState({
            dashboardRefreshing: true
          });
          if (selectedCycle) {
            this.props.refreshData(selectedCycle).then(() => {
              this.setState({
                secondsUntilRefresh: 40,
                dashboardRefreshing: false
              });
            });
          } else {
            this.props.refreshData(false).then(() => {
              this.setState({
                secondsUntilRefresh: 40,
                dashboardRefreshing: false
              });
            });
          }
        } else {
          if(pageInactive){
            console.log("dashboard is hidden, do not refresh");
            this.setState({
              secondsUntilRefresh: 40,
              dashboardRefreshing: false
            });
          }
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.secondsUntilRefresh);
  }
  render() {
    const { secondsUntilRefresh, dashboardRefreshing } = this.state;
    const { t } = this.props;
    return (
      <div className="refresh-container">
        {dashboardRefreshing ? (
          <span>
            <Loader active inline size="tiny" />
          </span>
        ) : (
          <span>
            {t("Updating dashboard in")}... {secondsUntilRefresh}
          </span>
        )}
      </div>
    );
  }
}

export default translate("translations")(RefreshCountdownTimer);
