/*
*  Form for creating Customers
*
* Copyright (C) 2018, 2019, 2020 Sterilis Medical Inc., all rights reserved.
*/

import React, {useEffect, useState} from "react";
import {FloatLabelDropdown, FloatLabelInput} from "../../library/FloatLabelInputs";
import AuthService from "../../AuthService";
import get from 'lodash/get';
import {useTranslation} from "react-i18next";
import * as Sentry from "@sentry/browser";
import {alphabeticalSort} from "../../library/helpers";

const Auth = new AuthService();

const CustomerForm = props => {
  const {t} = useTranslation('translations');
  const [customerDropdownOptions, setCustomerDropdownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const createCustomer = props['create'] === 'customer';

  useEffect(() => {
    getCustomers()
  }, []);

  const customerDropdownText = customer => {
    const groupsThatShouldSeeDistributorNames = ['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs','SterilisWasteTypeAdmin'];

    if (groupsThatShouldSeeDistributorNames.includes(props.group)) {
      const distributorName = get(customer, 'distributor.distributor_name', '');

      // Use spans here to keep Semantic etc styling out of the dropdown; <p> will add margin
      return <span className='flex-dir-row even-split'>
        <span>{customer['customer_name']}</span>
        <span>{distributorName}</span>
      </span>
    } else {
      return customer['customer_name']
    }
  };

  const searchStringDropdownSearch = (options, query) => {
    return options.filter((option) => {
      return option['search_string'].toLowerCase().includes(query.toLowerCase())
    })
  };

  const getCustomers = () => {
    setIsLoading(true);
    return Auth.fetch(`/api/customer/`)
      .then(data => {
        const customerDropdown = data.map(customer => {
          return {
            key: customer['id'],
            value: customer['id'],
            text: customerDropdownText(customer),
            customer_name: customer['customer_name'],
            // May want to add Distributor name here; TBD. Leaving this for now
            search_string: customer['customer_name']
          }
        });
        setIsLoading(false);
        customerDropdown.sort((a, b) => alphabeticalSort(a, b, 'customer_name'));
        setCustomerDropdownOptions(customerDropdown)
      })
      .catch(err => {
        setIsLoading(false);
        Sentry.captureException(err);
      })
  };

  // If the user is trying to create a Customer or Distributor, we need to show them the Customer name input field
  return <React.Fragment>
    {createCustomer ?
      <FloatLabelInput
        registerParams={{
          required: 'Required', validate: (value) => {
            const input = value.toLowerCase();
            const customerNameTaken = customerDropdownOptions.find(customer => {
              return customer['customer_name'].toLowerCase() === input
            });
            if (customerNameTaken) {
              // Returning the string here will trigger validation w/ an error message of the Return value
              return "Customer names must be unique.";
            }
            return true;
          }
        }}
        required={true}
        name={'customerName'}
        labelText={t('Customer Name')}
        errors={props['errors']}
      /> :
      <FloatLabelDropdown
        registerParams={{required: 'Required'}}
        name={'facilityCustomer'}
        labelText={t('Customer')}
        isLoading={isLoading}
        isDisabled={isLoading}
        options={customerDropdownOptions}
        inputClass='customer-dropdown'
        enableCustomSearchFunction={true}
        customSearchFunction={searchStringDropdownSearch}
      />
    }
  </React.Fragment>
};

export default CustomerForm;