/*
*  Modal responsible for retrieving archived data from S3 Glacier
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Trans} from "react-i18next";
import SimpleLoader from "./SimpleLoader";
import {Button, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import AuthService from "./AuthService";
import {toast} from 'react-toastify';
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class RetrieveArchivedDataModal extends React.Component {
  state = {
    allowModalToBeClosed: true,
    appLoading: false
  };

  notifyFileRetrieveSuccess = () => toast("Your file request has been successfully submitted. The files should be available for view within 5-12 hours.", {
    type: toast.TYPE.DEFAULT,
    autoClose: 5000
  });

  notifyFileRetrieveFailure = (message) => toast(message
  , {type: toast.TYPE.ERROR, autoClose: 5000});

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  retrieveData = () => {
    this.setState({
      appLoading: true,
      allowModalToBeClosed: false,
    });
    return Auth.fetch(`/api/retrieve-s3-data/`, {
        method: "POST",
        body: JSON.stringify({
          deviceSerial: this.props.deviceSerial,
          userEmail: this.props.userEmail,
          user_id: this.props.portalUserId
        }),
      })
        .then((data) => {
          this.setState({
            appLoading: false,
            allowModalToBeClosed: true,
          });
          console.log(data)
          this.props.handleRetrieveArchivedDataModal()
          this.notifyFileRetrieveSuccess()
        }).catch(err => {
          Sentry.captureException(err);
          this.setState({
            appLoading: false,
            allowModalToBeClosed: true,
          });
          this.props.handleRetrieveArchivedDataModal()
          err.response.json().then(errInfo => {
            this.notifyFileRetrieveFailure(errInfo['message']);
          });
        });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  render() {
    // const topMargin = this.state.height < 1000 ? '3vh' : '10vh';
    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '600px',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    const {
      appLoading
    } = this.state;

    const {
      t,
    } = this.props;

    return (
      <TransitionablePortal
        open={this.props.retrieveArchivedDataModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.handleRetrieveArchivedDataModal()
          }}
        >
          {appLoading ? (
            <SimpleLoader/>
          ) : null}
          <Header>
            <h2 className='red'>{t('Retrive Archived Data')}</h2>
          </Header>
          <Modal.Content className='create-device-operator-cust'>
            <div className='short-modal-text center-text'>
              <Trans ns="translations" i18nKey="retrieve-archived-data-prompt">
                Are you sure you want to retreive the archived data older than 3 years?
              </Trans>
            </div>
            <div className='short-modal-text center-text'>
              <Trans ns="translations" i18nKey="data-retrieval-prompt">
                The request once submitted, will be processed within 12 hours.
              </Trans>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className='red-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    id='retrieveData'
                    onClick={this.retrieveData}
            >{t('Submit Request')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(RetrieveArchivedDataModal);
