import { DateTime } from "luxon";

export const idColumn = {
	type: "number",
	headerAlign: "center",
	align: "center",
	valueFormatter: (params) => {
		const formatted = parseInt(
			params.value.toLocaleString().replace(/\,+/g, "")
		);
		return `${formatted}`;
	},
};

export const datetimeColumn = {
	type: "dateTime",
	headerAlign: "center",
	align: "center",
	valueFormatter: (params) => {
		const formatted = DateTime.fromISO(params.value)
            .toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, timeZoneName: 'short' })
			.replace(",", "");
		return `${formatted}`;
	},
};

export const capitalizeFormatter = {
	valueFormatter: (params) => {
		return params.value.charAt(0).toUpperCase() + params.value.slice(1);
	},
};
