/*
*  Component responsible for the Device box on the Customer Machine page
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import '../css/CustomerDevice.css';
import {Dropdown, Button, Icon, Popup} from 'semantic-ui-react';
import machineIcon from '../images/thumb-machine.png'
import {Link} from "react-router-dom";
import {isGroupSterilis} from "./library/helpers";


const deviceStateColor = (deviceConfig, group) => {
  if (deviceConfig['device']['deleted']) {
    return 'light-grey-tr'
  } else if (deviceConfig['device']['banned']) {
    return 'light-red-tr'
  } else if (deviceConfig['device']['activated'] === false && isGroupSterilis(group)) {
    return 'light-yellow-tr'
  }
};

const deviceStateWord = (deviceConfig, group, t) => {
  if (deviceConfig['device']['deleted']) {
    return t('Archived Device')
  } else if (deviceConfig['device']['banned']) {
    return t('Suspended Device')
  } else if (deviceConfig['device']['activated'] === false && isGroupSterilis(group)) {
    return t('Un-Activated Device')
  }
};

const CustomerDevice = (props) => (
    <div className={"customer-machine-block"}>
        <div className={`customer-device-header ${deviceStateColor(props.deviceConfig, props.group)}`}>
            <p className="customer-device-device-state__p">
                {deviceStateWord(props.deviceConfig, props.group, props.t)}
            </p>
            <div className="dropdown">
                <span className="ellipsis-container">
                    <Dropdown pointing="top right" icon="ellipsis horizontal">
                        <Dropdown.Menu>
                            <Dropdown.Item
                                className="device-dropdown-text"
                                text={props.t("Run a compliance report")}
                                onClick={() => props.triggerCycleReportModal(true, props.deviceConfig)}
                            />
                            <Dropdown.Item
                                className="device-dropdown-text"
                                text={props.t("Run a comprehensive report")}
                                onClick={() => props.triggerCycleReportModal(false, props.deviceConfig)}
                            />
                            <Dropdown.Divider />
                            {/*Needed to emulate dropdown.item because it has some !important padding that creates really bad UX*/}
                            {/*for links nested inside of it*/}
                            <div className="fake-dropdown-item">
                                <Link className="full-nav" to={`/sa/dashboard/${props.deviceConfig["id"]}`}>
                                    {props.t("View Dashboard")}
                                </Link>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </span>
            </div>
        </div>
        <div className="machine-icon">
            <div>
                <img src={machineIcon} alt="Sterilis Device" />
                <Popup content={props.msg} trigger={<Icon className={props.status} name="circle" />} />
            </div>
            {props.showDailyUtilization ? (
                <div className="util-section">
                    <p>
                        {props.t("Daily Utilization")}
                        <Popup
                            trigger={<Icon name="info circle" />}
                            content={
                                <div>
                                    <p>
                                        {" "}
                                        {props.t(
                                            "Calculated based on the device run time throughout Facility's operation hours for the day"
                                        )}
                                    </p>
                                </div>
                            }
                        />
                        :
                    </p>
                    <p className="float-right">{props.daily_time_utilized}%</p>
                </div>
            ) : null}
            {props.showWeeklyUtilization ? (
                <div className="util-section">
                    <p>
                        {props.t("Weekly Utilization")}
                        <Popup
                            trigger={<Icon name="info circle" />}
                            content={
                                <div>
                                    <p>
                                        {" "}
                                        {props.t(
                                            "Calculated based on the device run time throughout Facility's operation hours for the week"
                                        )}
                                    </p>
                                </div>
                            }
                        />
                        :
                    </p>
                    <p className="float-right">{props.weekly_time_utilized}%</p>
                </div>
            ) : null}
        </div>
        <div className={`customer-device-title`}>
            <div className="top-div">
                <p className="name">{props.deviceConfig["device_name"]}</p>
            </div>
            <div className="sec">
                <p className="text-overflow-max-width">{props.individualFacilityName}</p>
                <p className="float-right">{props.deviceConfig["device"]["serial_number"]}</p>
            </div>
        </div>
        <div className="customer-device-view-dashboard-div">
            <Link to={`/sa/dashboard/${props.deviceConfig["id"]}`}>
                <Button className="customer-device-view-dashboard-btn">{props.t("View Dashboard")}</Button>
            </Link>
        </div>
    </div>
);
export default translate('translations')(CustomerDevice);
