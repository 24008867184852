import React, {useEffect} from 'react';
import {useTable, useSortBy, usePagination, useGlobalFilter, useExpanded, useRowSelect} from 'react-table';
import {Table as SemanticTable, Pagination, Dropdown, Input} from 'semantic-ui-react';
import '../../css/Table.css'
import PropTypes from 'prop-types';
import FacilityTable from "../view/customers/FacilityTable";
import SimpleDeviceTable from "../view/customers/SimpleDeviceTable";
import CyclesByDayTable from "../export/service_summary/CyclesByDayTable";

function GlobalFilter(
  {
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    tableName
  }
) {
  const count = preGlobalFilteredRows.length;

  return (
    <React.Fragment>
      <label
        className='table-header-label-padding'
        htmlFor={tableName + "-search"}
      >
        <strong>
          Search
        </strong>
      </label>
      <Input
        className='pagination-height'
        id={`${tableName}-search`}
        value={globalFilter || ''}
        onChange={(e, data) => {
          setGlobalFilter(data.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} records...`}
      />
    </React.Fragment>
  )
}

function getColumnClass({
                          className,
                          isSorted,
                          isSortedDesc,
                        }) {
  const classArray = [];
  if (isSorted) {
    if (isSortedDesc) {
      classArray.push('table-sort-desc');
    } else {
      classArray.push('table-sort-asc');
    }
  }
  if (className) {
    classArray.push(className);
  }
  return classArray.join(' ')
}

const Table = (
  {
    columns,
    data,
    showPagination,
    defaultPageSize,
    getRowProps,
    tableName,
    expandableTable,
    renderRowSubComponent,
    mainTableWidth,
    innerTable,
    subComponentJSX,
    showSearch,
    defaultSortBy,
    renderEmptyRow,
    searchParam,
    customHeader,
    showSearchInHeader,
    showPaginationInHeader,
    showSearchInFooter,
    showPaginationInFooter,
    showPaginationSelect,
    onRowSelectCallback,
    showOnRowSelectCheckboxes
  }
) => {
  const jsxFilter = React.useCallback((rows, columnNames, filterValue) => {
    return rows.filter(row => row.depth === 0).filter(row => {
      if (row['original']['search_string']) {
        return row['original']['search_string'].toLowerCase().includes(filterValue.toLowerCase());
      } else {
        // the filter will removed undefined variables, which can happen in the case of expandable tables
        const rawRowData = Object.values(row['values']).filter(e => e);
        const cleanRowData = rawRowData.map(rawRow => {
          if (typeof(rawRow) === "object") {
            return String(rawRow['props']['children']).toLowerCase()
          } else {
            return rawRow.toString().toLowerCase();
          }
        });
        return cleanRowData.find(rowString => rowString.includes(filterValue.toLowerCase()));
      }
    });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: {pageIndex, pageSize, globalFilter},
    preGlobalFilteredRows,
    setGlobalFilter,
    canNextPage,
    nextPage,
    previousPage,
    visibleColumns,
    toggleAllRowsSelected
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: defaultPageSize,
        sortBy: [defaultSortBy],
        globalFilter: searchParam,
      },
      manualGlobalFilter: false,
      globalFilter: jsxFilter,
      autoResetExpanded: expandableTable && false,
      autoResetPage: true,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
    expandableTable && useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.getCellProps.push((props, {instance, cell}) => {
        if (cell.column.customCellClass) {
          return [
            {className: cell.column.customCellClass(cell.value), ...props}
          ]
        }
        return [
          props
        ]
      })
    }
  );

  useEffect(() => {
    onRowSelectCallback && onRowSelectCallback(selectedFlatRows);
  }, [selectedFlatRows.length]);

  useEffect(() => {
    // when the widget to show checkboxes is false, set all of the checkboxes to false
    // basically just un-selects any combined charts on the portal parser
    if (showOnRowSelectCheckboxes === false){
      toggleAllRowsSelected(false);
    }
  }, [showOnRowSelectCheckboxes]);

  const paginationRow = (showSearchInPagination, showPagination) =>
    <SemanticTable.Row>
      <SemanticTable.HeaderCell colSpan={columns.length}>
        <div className="flex-dir-row even-split">
          <div className='flex-dir-column'>
            {showSearchInPagination && <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              tableName={tableName}
            />}
          </div>
          {pageOptions.length > 0 && showPagination ?
            <div className='flex-dir-row-reverse'>
              <div className='flex-dir-column'>
                <div>
              <span className='table-header-label-padding'>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
                </div>
                <Pagination
                  totalPages={pageOptions.length}
                  ellipsisItem={null}
                  boundaryRange={1}
                  siblingRange={1}
                  activePage={pageIndex + 1}
                  onPageChange={(e, data) => {
                    if (data.activePage) {
                      // -1 (or +1 in other places) because
                      // activePage is 0-based-index, while the pagination pages are 1-based
                      gotoPage(data.activePage - 1)
                    } else if (canNextPage) {
                      nextPage();
                    } else {
                      previousPage();
                    }
                  }}
                />
              </div>
              {showPaginationSelect &&
              <div className='flex-dir-column pagination-dropdown'>
                <div className='table-header-label-padding'>
                  <strong>
                    Show
                  </strong>
                </div>
                <Dropdown
                  value={pageSize}
                  selection
                  fluid
                  className='pagination-height'
                  options={[
                    {
                      key: 10,
                      value: 10,
                      text: '10 rows',
                    },
                    {
                      key: 20,
                      value: 20,
                      text: '20 rows',
                    },
                    {
                      key: 50,
                      value: 50,
                      text: '50 rows',
                    },
                    {
                      key: 100,
                      value: 100,
                      text: '100 rows',
                    },

                  ]}
                  onChange={(e, {value}) => setPageSize(value)}
                />
              </div>
              }
            </div>
            : null}
        </div>
      </SemanticTable.HeaderCell>
    </SemanticTable.Row>;

  const emptyRowsToRender = pageSize > data.length ? defaultPageSize - data.length > 0 ? defaultPageSize - data.length : false : false;
  return (
    <React.Fragment>
      <SemanticTable {...getTableProps()} className={innerTable ? 'inner-table' : ''}>
        {customHeader ? customHeader :
          showPagination &&
          <SemanticTable.Header fullWidth>
            {paginationRow(showSearchInHeader, showPaginationInHeader)}
          </SemanticTable.Header>
        }
        <SemanticTable.Header>
          {headerGroups.map(headerGroup => (
            <SemanticTable.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                const {style} = column.getHeaderProps(column.getSortByToggleProps());
                return <SemanticTable.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={getColumnClass(column)}
                  style={{
                    ...column['manualWidth'] ? {'width': column['manualWidth']} : {},
                    ...style
                  }}
                >
                  {column.render('Header')}
                </SemanticTable.HeaderCell>
              })}
            </SemanticTable.Row>
          ))}
        </SemanticTable.Header>
        <SemanticTable.Body {...getTableBodyProps()}>
          {page.filter(page => page.depth === 0).map(
            (row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <SemanticTable.Row {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map(cell => {
                      return (
                        <SemanticTable.Cell {...cell.getCellProps()}>{cell.render('Cell')}</SemanticTable.Cell>
                      )
                    })}
                  </SemanticTable.Row>
                  {row.isExpanded && (
                    <SemanticTable.Row>
                      <SemanticTable.Cell colSpan={row['cells'].length}>
                        {renderRowSubComponent({row}, subComponentJSX)}
                      </SemanticTable.Cell>
                    </SemanticTable.Row>
                  )}
                </React.Fragment>
              )
            }
          )}

          {/*When there isn't 10 (or whatever the defaultPagination value has been set to) rows of data to render,*/}
          {/*we render some empty rows so that the tables look full*/}
          {
            renderEmptyRow && emptyRowsToRender && [...Array(emptyRowsToRender)].map((e, i) => {
              return <SemanticTable.Row style={{'height': '43px'}} role='row' key={`row-${tableName}-${e}-${i}`}>
                {
                  visibleColumns.map((e, i) => <SemanticTable.Cell role='cell' key={`cell-${tableName}-${e}-${i}`}/>)
                }
              </SemanticTable.Row>
            })
          }
        </SemanticTable.Body>
        {
          showPagination &&
          <SemanticTable.Footer fullWidth>
            {paginationRow(showSearchInFooter, showPaginationInFooter)}
          </SemanticTable.Footer>
        }
      </SemanticTable>
    </React.Fragment>
  )
};

const removeDeleted = data => {
  return data.filter(row => {
    if (!row["deleted_status"]) {
      return row;
    }
    return false;
  });
};

const HookTable = props => {
  const renderRowSubComponent = React.useCallback(
    ({row}, subComponentJSX) => {
      if (subComponentJSX === 'facilityTable') {
        return <FacilityTable
          data={row['originalSubRows']}
          group={props.group}
        />
      } else if (subComponentJSX === 'simpleDeviceTable') {
        return <SimpleDeviceTable
          data={row['originalSubRows']}
          group={props.group}
        />
      } else if (subComponentJSX === 'cyclesByDayTable') {
        return <CyclesByDayTable
          cyclesByDayColumns={props.expandableTableColumns}
          data={row['originalSubRows']}
        />
      } else {
        return <div>placeholder subcomponent</div>
      }
    },
    []
  );


  let tableData = props.data;
  if (props.showDeleted === false) {
    tableData = removeDeleted(props.data)
  }

  const rowColor = row => {
    if (row.index === props.selectedIndex) {
      return "white";
    } else if (row.index === props.highlightedIndex) {
      return "darkorange";
    }
  };


  return <div
    key={`table-container-${props.tableName}`}
    className={props.innerTable ? 'inner-table-container' : props.tableContainerClass ? props.tableContainerClass : 'table-container'}
  >
    {props.floatLeftHeaderJSX ?
      <div className='slight-margin-top'>
        {props.floatLeftHeaderJSX}
      </div> : null
    }
    <Table
      columns={props.columns}
      data={tableData}
      showPagination={props.showPagination}
      defaultPageSize={props.defaultPageSize}
      tableName={props.tableName}
      innerTable={props.innerTable}
      expandableTable={props.expandableTable}
      renderRowSubComponent={renderRowSubComponent}
      subComponentJSX={props.subComponentJSX}
      defaultSortBy={props.defaultSortBy}
      searchParam={props.searchParam}
      getRowProps={row => ({
        onClick: () => props.enableOnRowClickCallback && props.onClickCallback(row),
        className: props.customRowClass ? props.customRowClassFunc(row) : "",
        style: {
          color: rowColor(row),
          backgroundColor: row.index === props.selectedIndex ? "#00afec" : '',
        }
      })}
      showSearch={props.showSearch}
      renderEmptyRow={props.renderEmptyRow}
      customHeader={props.customHeader}
      showSearchInHeader={props.showSearchInHeader}
      showPaginationInHeader={props.showPaginationInHeader}
      showSearchInFooter={props.showSearchInFooter}
      showPaginationInFooter={props.showPaginationInFooter}
      showPaginationSelect={props.showPaginationSelect}
      onRowSelectCallback={props.onRowSelectCallback}
      showOnRowSelectCheckboxes={props.showOnRowSelectCheckboxes}
    />
  </div>
};

HookTable.defaultProps = {
  enableOnRowClickCallback: false,
  showPagination: true,
  customRowClass: false,
  expandableTable: false,
  highlightedIndex: false,
  renderEmptyRow: true,
  selectedIndex: false,
  innerTable: false,
  defaultSortBy: {},
  subComponentJSX: '',
  tableName: '',
  searchParam: '',
  tableContainerClass: '',
  customRowClassFunc: () => {
  },
  defaultPageSize: 10,
  columns: [],
  expandableTableColumns: [],
  data: [],
  floatLeftHeaderJSX: null,
  showSearch: true,
  customHeader: false,
  showSearchInHeader: true,
  showPaginationInHeader: true,
  showSearchInFooter: false,
  showPaginationInFooter: true,
  showPaginationSelect: true,
  onRowSelectCallback: null,
  showOnRowSelectCheckboxes: false,
};

HookTable.propTypes = {
  showPagination: PropTypes.bool,
  enableOnRowClickCallback: PropTypes.bool,
  renderEmptyRow: PropTypes.bool,
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  selectedIndex: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  customRowClass: PropTypes.bool,
  innerTable: PropTypes.bool,
  expandableTable: PropTypes.bool,
  showSearch: PropTypes.bool,
  showDeleted: PropTypes.bool,
  customRowClassFunc: PropTypes.func,
  onClickCallback: PropTypes.func,
  defaultPageSize: PropTypes.number,
  columns: PropTypes.array,
  expandableTableColumns: PropTypes.array,
  data: PropTypes.array,
  tableName: PropTypes.string.isRequired,
  subComponentJSX: PropTypes.string,
  searchParam: PropTypes.string,
  tableContainerClass: PropTypes.string,
  customHeader: PropTypes.oneOfType([
    PropTypes.bool, PropTypes.element
  ]),
  showSearchInHeader: PropTypes.bool,
  showPaginationInHeader: PropTypes.bool,
  showSearchInFooter: PropTypes.bool,
  showPaginationInFooter: PropTypes.bool,
  showPaginationSelect: PropTypes.bool,
  onRowSelectCallback: PropTypes.func,
  showOnRowSelectCheckboxes: PropTypes.bool,
};


export default HookTable;
