/*
*  -insert desc here-
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import { withTranslation as translate} from "react-i18next";
import {Button, Dropdown, Header, Modal, TransitionablePortal} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import {AuthReq} from "../../library/helpers"
import {parse as jsonToCSV} from "json2csv";
import SimpleLoader from "../../SimpleLoader";
import AuthService from "../../AuthService";
import startCase from 'lodash/startCase';

const Auth = new AuthService();

class ExportDeviceConfigsModal extends React.Component {
  state = {
    deviceDropdownValue: 'all_devices',
    modalLoading: false,
    allowModalToBeClosed: true,
  };

  exportAllErrors = () => {
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(`/api/export/device-configs?device_bucket=${this.state.deviceDropdownValue}`, {
      method: 'GET',
    }).then(data => {
      Auth.fetch(`/api/device-config/`, {
        method: 'OPTIONS'
      }).then(configOptionsData => {
        const configOptions = configOptionsData['config_item_metadata'];


        // We need to remove the irrelevant values on DeviceConfig
        // The delete operation is mutable, so we are mutating the data object.
        const csvArray = data.map((deviceConfig) => {
          delete deviceConfig['id'];
          delete deviceConfig['customer_id'];
          delete deviceConfig['deleted'];
          delete deviceConfig['sw_affecting'];
          delete deviceConfig['operators'];
          delete deviceConfig['defaults'];
          delete deviceConfig['device'];
          delete deviceConfig['created'];
          delete deviceConfig['config_rev'];


          Object.keys(deviceConfig).forEach((item) => {
            if (item in configOptions) {
              if (configOptions[item]['type'] === 'choice') {
                // my local database has some bad configuration values from migration/model changes (typical for dev env)
                // instead of cleaning up the database, I think it's worthwhile to have a try/catch here
                // just in case something funky leaks onto prod
                try {
                  const prettyValue = configOptions[item]['choices'].find(choice => {
                    return choice.value === deviceConfig[item]
                  });
                  deviceConfig[item] = prettyValue['display_name'];
                }catch(error){
                  deviceConfig[item] = 'Invalid configuration value. Contact support ASAP'
                }
              }
            }
          });

          return deviceConfig;
        });

        csvArray.sort((a, b) => {
          const aSerial = a['serial_number'].split("-")[1];
          const bSerial = b['serial_number'].split("-")[1];
          return aSerial > bSerial ? 1 : -1;
        });

        const prettyHeaderCsvArray = csvArray.map(deviceConfig => {
          return Object.entries(deviceConfig).reduce((u, [n, v]) => {
            if(n in configOptions){
              u[`${configOptions[n]['label']}`] = v;
            }else{
              u[`${startCase(n)}`] = v;
            }
            return u;
          }, {});

        });
        const csv = jsonToCSV(prettyHeaderCsvArray);
        const blob = new Blob([csv], {type: 'text/csv'});

        const link = document.createElement('a');

        const filePath = window.URL.createObjectURL(blob);

        link.href = filePath;

        link.download = `device_data-${moment().format("YYYY-MM-DD")}.csv`;
        // Appending to the actual dom is necessary to get the files to download on Firefox
        document.getElementById('downloadDiv').appendChild(link);
        link.click();
        this.setState({
          modalLoading: false,
          allowModalToBeClosed: true,
        }, () => this.props.triggerExportDeviceConfigsModal());
      }).catch(err => {
        Sentry.captureException(err);
        this.setState({
          modalLoading: false,
          allowModalToBeClosed: true,
        }, () => this.props.triggerExportDeviceConfigsModal());
      });
    }).catch(err => {
      Sentry.captureException(err);
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      }, () => this.props.triggerExportDeviceConfigsModal());
    });
  };

  deviceDropdownChange = (event, data) => {
    this.setState({
      deviceDropdownValue: data.value
    });
  };

  render() {
    const {
      t,
      group
    } = this.props;
    const {
      deviceDropdownValue,
      modalLoading
    } = this.state;

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textArea: {
        marginTop: '50px',
        width: '400px'
      }
    };
    const deviceDropdown = [
      {
        key: 0,
        value: 'all_devices',
        text: t('All Devices')
      },
      {
        key: 1,
        value: 'customer_devices',
        text: t('Customer Devices')
      },
      {
        key: 2,
        value: 'factory_devices',
        text: t('Factory/Sterilis HQ Devices')
      },
    ];
    return (
      <TransitionablePortal
        open={this.props.exportDeviceConfigsModal}
        onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)}
        transition={{animation: 'scale', duration: 500}}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={<Button className='close-modal-btn grey-btn'>{t('Close')}</Button>}
          size='mini'
          onClose={(event) => {
            document.body.classList.remove('modal-fade-in');
            this.props.triggerExportDeviceConfigsModal()
          }}
        >
          <Header>
            <h2>{t('Export Device Configuration')}</h2>
          </Header>
          {modalLoading ? (
            <SimpleLoader/>
          ) : null}
          <Modal.Content className='medium-height'>
            <div className="short-modal-text center-text">
              <p className='medium-margin-bottom'>{t('csv-download-on-submit', 'Upon clicking submit, a CSV will be downloaded to your browser.')}</p>
            </div>

            <AuthReq
              userGroup={group}
              requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs','SterilisWasteTypeAdmin',]}
            >
              <div className="form-group">
                <label className=" fake-float" htmlFor="deviceDropdown">{t('Device bucket')}</label>
                <Dropdown
                  className='full-width'
                  name='deviceDropdown'
                  selection
                  id='deviceDropdown'
                  options={deviceDropdown}
                  value={deviceDropdownValue}
                  onChange={this.deviceDropdownChange}
                  required
                />
              </div>
            </AuthReq>
            <div id='downloadDiv' className="download-div"/>
          </Modal.Content>
          <Modal.Actions>
            <Button className='ster-btn submit-device-operators'
                    value="Submit"
                    type="submit"
                    id='cycleReportBtn'
                    onClick={this.exportAllErrors}
            >{t('Submit')}</Button>
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    )
  }
}

export default translate('translations')(ExportDeviceConfigsModal);



