/*
* Main component to upload files to s3
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {Trans, withTranslation as translate} from "react-i18next";
import SimpleLoader from "../SimpleLoader";
import UploadDocumentTable from "./UploadDocumentTable";
import {composeHoc, getFileNameFromUrl, AuthReq} from "../library/helpers";
import {toast} from "react-toastify";
import {Button, Icon} from "semantic-ui-react";
import UploadDocumentModal from "./UploadDocumentModal";
import moment from "moment";
import saveAs from "file-saver";
import DeleteDocumentModal from "./DeleteDocumentModal";
import * as Sentry from "@sentry/browser";
import AuthService from "../AuthService";

const Auth = new AuthService();

class UploadDocument extends React.Component {
  state = {
    isLoading: false,
    showUploadDocumentModal: false,
    documentTableArray: [],
    allowModalToBeClosed: true,
  };

  notifyUploadSuccess = (file) => toast(`Successfully uploaded file ${file}`, {
    type: toast.TYPE.DEFAULT,
    autoClose: 5000
  });
  notifyUploadFailure = () => toast(`Failed to upload file. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  notifyDeleteSuccess = () => toast(`Successfully deleted file.`, {type: toast.TYPE.DEFAULT, autoClose: 5000});
  notifyDeleteFailure = () => toast(`Failed to delete file. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  notifyDownloadFailure = () => toast(`Failed to download file. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });


  componentDidMount() {
    this.preparePage();
  }

  preparePage = () => {
    this.setState({isLoading: true});
    const {group, customerAppAbortController: controller} = this.props;
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    Auth.fetch(`/api/s3-file/`, {
      method: 'GET',
      signal: controller.signal,
    }).then(data => {
      let latestUploadTimestamp = '';
      // Only do this if data is actually populated
      if (data.length > 0) {
        latestUploadTimestamp = data[0].uploaded_at;
      }
      const documentTableArray = data.map((upload) => {
        // If upload['uploaded_at'] is greater than latestUploadTimestamp,
        // upload['uploaded_at'] is the new latestUploadTimestamp
        if (latestUploadTimestamp < upload['uploaded_at']) {
          latestUploadTimestamp = upload['uploaded_at']
        }
        return {
          'filename': getFileNameFromUrl(upload['upload']),
          'desc': upload['description'],
          'upload_date': moment.utc(upload['uploaded_at']).tz(userTimezone).format("YYYY-MM-DD HH:mm:ss z"),
          'actions':
            <div>
              <Icon
                className='download-icon'
                title='Download the file'
                size='large'
                onClick={() => this.downloadFile(upload)}
                name='cloud download'
              />
              <AuthReq
                userGroup={group}
                requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers','SterilisWasteTypeAdmin']}
              >
                <Icon
                  className='delete-icon'
                  title='Delete the file'
                  size='large'
                  onClick={() => this.triggerDeleteDocumentModal(upload)}
                  name='delete'
                />
              </AuthReq>
            </div>,
        }
      });
      if (group === 'CustomerPortalUsers' || group === 'FacilityAdmin') {
        this.props.hoistFunction('latestUploadTimestamp', latestUploadTimestamp)
      }
      this.setState({
        isLoading: false,
        documentTableArray: documentTableArray
      });
    })
      .catch(err => {
        if (err.code !== 20) {
          Sentry.captureException(err);
          this.setState({
            isLoading: false,
          });
        }
      });
  };

  downloadFile = (upload) => {
    const fileID = upload['id'];
    const fileName = getFileNameFromUrl(upload['upload']);
    this.setState({
      isDownloading: true,
      fileName: fileName
    });
    Auth.fetch(`/api/download-file/`, {
      method: 'POST',
      body: JSON.stringify({
        'file_id': fileID,
        'file_type': 'doc'
      })
    }).then(response => response.blob())
      .then(blob => {
        saveAs(blob, fileName);
        this.setState({
          isDownloading: false,
          fileName: null
        })
      })
      .catch(err => {
        this.setState({
          isDownloading: false,
        });
        this.notifyDownloadFailure();
        Sentry.captureException(err);
      })
  };

  deleteFile = (upload) => {
    const fileID = upload['id'];
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false
    });
    Auth.fetch(`/api/s3-file/${fileID}/`, {
      method: 'DELETE'
    }).then(data => {
      this.notifyDeleteSuccess();
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      });
      this.triggerDeleteDocumentModal(null);
      this.preparePage();
    }).catch(err => {
      Sentry.captureException(err);
      this.notifyDeleteFailure();
      this.preparePage();
      this.setState({
        modalLoading: false,
        allowModalToBeClosed: true,
      });
    })
  };

  triggerUploadDocumentModal = () => {
    this.setState((prevState) => {
      return {
        showUploadDocumentModal: !prevState.showUploadDocumentModal
      };
    });
  };

  triggerDeleteDocumentModal = (upload) => {
    this.setState((prevState) => {
      return {
        showDeleteDocumentModal: !prevState.showDeleteDocumentModal,
        fileToDelete: upload
      }
    });
  };

  render() {
    const {
      isLoading,
      showUploadDocumentModal,
      showDeleteDocumentModal,
      documentTableArray,
      fileToDelete,
      modalLoading,
      isDownloading,
      fileName
    } = this.state;

    const documentCount = documentTableArray.length;

    const {t, group} = this.props;
    return (
      <div>
        {isLoading && (
          <SimpleLoader/>
        )}

        {isDownloading && <SimpleLoader loaderText={`Downloading ${fileName}`}/>}

        {/*Need to restrict ToastContainer rendering to Sterilis people only because when UploadDocument*/}
        {/*is used on the CustomerApp, it has a ToastContainer too. The 'New Document Available' toast, which is*/}
        {/*for customers, will feed this toast - creating two notifications. */}
        {/*{ group === 'SterilisSuperUsers' ?*/}
        {/*<ToastContainer /> : null*/}
        {/*}*/}
        <div className='doc-header'>
          <div className='stat-info'>
            <p className='large-num'>{documentCount}</p>
            <div className="stat-name">
              <Trans ns="translations" i18nKey="total_documents">
                TOTAL <br/> DOCUMENTS
              </Trans>
            </div>
          </div>
          <AuthReq
            userGroup={group}
            requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers','SterilisWasteTypeAdmin']}
          >
            <Button className='ster-btn-wide grey-btn'
                    id='uploadDocumentModalBtn'
                    onClick={this.triggerUploadDocumentModal}>
              {t('Upload New Document')}
            </Button>
          </AuthReq>
        </div>

        <UploadDocumentTable
          documentTableArray={documentTableArray}
          group={group}
          fromCustomerApp={this.props.fromCustomerApp}
        />

        <AuthReq
          userGroup={group}
          requiredRoles={['SterilisSuperUsers', 'SterilisPortalUsers','SterilisWasteTypeAdmin',]}
        >
          <UploadDocumentModal
            triggerUploadDocumentModal={this.triggerUploadDocumentModal}
            showUploadDocumentModal={showUploadDocumentModal}
            preparePage={this.preparePage}
            modalLoading={modalLoading}
            notifyUploadSuccess={this.notifyUploadSuccess}
            notifyUploadFailure={this.notifyUploadFailure}
          />
          {showDeleteDocumentModal ?
            <DeleteDocumentModal
              showDeleteDocumentModal={showDeleteDocumentModal}
              triggerDeleteDocumentModal={this.triggerDeleteDocumentModal}
              deleteFile={this.deleteFile}
              fileToDelete={fileToDelete}
              modalLoading={modalLoading}
              allowModalToBeClosed={this.state.allowModalToBeClosed}
            /> : null
          }
        </AuthReq>
      </div>
    )
  }
}

export default composeHoc(translate('translations'))(UploadDocument);
