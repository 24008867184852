/*
*  Component responsible for the landing page that new users access via an email link when finalizing their account creation
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import SimpleLoader from "./SimpleLoader";
import AuthService from "./AuthService";
import {Card, Form} from 'semantic-ui-react'
import sterLogo from "../images/logo-sterilis.png";
import '../css/ActivateAccount.css';
import {Link} from 'react-router-dom';
import debounce from 'lodash/debounce';
import {Trans, withTranslation as translate} from "react-i18next";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/browser";
import {composeHoc, isGroupCustomer, isGroupNotCustomer} from "./library/helpers";
import { withRouter } from "react-router-dom";
const Auth = new AuthService();


class ActivateAccount extends React.Component {
  state = {
    passwordFeedback: false,
    password: '',
    confirmPassword: '',
    username: '',
    email: '',
  };
  notifyFailure = (employeeName) => toast(`Failed to activate user ${employeeName}. Please refresh the page and try again.`, {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  });

  componentDidMount() {
    const activationKey = this.props.match.params['activation_key'];

    // if they're logged in, as in they're logged in as a user that isn't the user being activated,
    // log them out
    if (Auth.loggedIn()) {
      Auth.logout();
    }

    this.setState({
      isLoading: true,
      activationKey: activationKey
    });
    Auth.fetch(`/api/check-activation-key/`, {
      method: 'POST',
      body: JSON.stringify({'activation_key': activationKey})
    }).then(data => {
      if (data['status'] === 'key_valid') {
        this.setState({
          isLoading: false,
          fullName: data['full_name'],
          username: data['username'],
          email: data['email'],
        });
      } else {
        if (data["status"] === "user_active") {
          this.setState({
            userActive: true,
            isLoading: false,
          })
        } else if (data["status"] === "key_expired") {
          this.setState({
            keyInvalid: true,
            isLoading: false,
          })
        } else {

        }
      }
    })
      .catch(err => {
        Sentry.captureException(err);
        this.setState({
          isLoading: false,
          generalError: true,
        });
      });
  }

  checkPassword = (password) => {
    // Regex for number, character, and symbol
    // const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    if (password.length >= 10) {
      this.setState({
        passwordValidationError: false,
        passwordsBeingDebounced: false
      })
    } else {
      this.shakeRequirements();
      this.setState({
        passwordValidationError: true,
        passwordsBeingDebounced: false
      })
    }
  };

  debounceCheckPassword = debounce((password) => this.checkPassword(password), 1000);

  checkEqualPasswords = () => {
    const {
      password,
      confirmPassword
    } = this.state;
    if (confirmPassword === password) {
      this.setState({
        passwordsNotEqualError: false,
        passwordsBeingDebounced: false
      })
    } else {
      this.shakeEqual();
      this.setState({
        passwordsNotEqualError: true,
        passwordsBeingDebounced: false
      })
    }
  };

  debounceCheckEqualPasswords = debounce((password) => this.checkEqualPasswords(password), 1000);

  handleChange = event => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (name === 'password') {
      // disable the submit button until debounce'd checks checks out
      this.setState({
        passwordsBeingDebounced: true
      });
      // 8 character min
      // One letter
      // One number
      // One symbol
      if (value) {
        this.debounceCheckEqualPasswords();
        this.debounceCheckPassword(value);
      } else { //when there is no password present, remove invalidation
        this.setState({
          passwordValidationError: false
        })
      }
    }

    if (name === 'confirmPassword') {
      // disable the submit button until debounce'd checks checks out
      this.setState({
        passwordsBeingDebounced: true
      });
      this.debounceCheckPassword(value);
      this.debounceCheckEqualPasswords();
    }
    this.setState({[name]: value});
  };

  shakeRequirements = () => {
    this.setState({
      setRequirementsShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setRequirementsShake: false,
        })
      }, 1000)
    })
  };

  shakeEqual = () => {
    this.setState({
      setEqualShake: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setEqualShake: false,
        })
      }, 1000)
    })
  };

  detailPasswordFail = (passwordFails) => {
    const passwordFeedback = passwordFails.map((fail) => {
      return <p className='light-validation-error signup-p'>{fail}</p>
    });
    this.shakeRequirements();
    this.setState({passwordFeedback: passwordFeedback})
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      password,
      confirmPassword,
      activationKey,
    } = this.state;
    this.setState({
      isLoading: true
    });

    Auth.fetch(`/api/activate-account/`, {
      method: 'POST',
      body: JSON.stringify({
        'password': password,
        'confirm_password': confirmPassword,
        'activation_key': activationKey
      })
    }).then(data => {
      const authLevel = data['group']['name'];

      Auth.login(this.state.username, this.state.password).then(res => {
        this.setState({isLoading: false}, () => {
          if (isGroupNotCustomer(authLevel)) {
            this.props.history.replace('/');
          } else if (isGroupCustomer(authLevel)) {
            if (res['user']['accepted_toc'] === false) {
              this.props.history.replace('/terms')
            } else {
              this.props.history.replace('/sa');
            }
          }
        });
      }).catch(err => {
          Sentry.captureException(err);
          this.setState({isError: false});
          this.setState({isLoading: false});
        })
    }).catch((err) => {
      Sentry.captureException(err);
      this.setState({isLoading: false});
      this.notifyFailure(this.state.username);
      err.response.json().then(errInfo => {
        this.detailPasswordFail(errInfo['validation_errors']);
      });
    });
  };

  render() {
    const {
      isLoading,
      keyInvalid,
      fullName,
      setRequirementsShake,
      passwordValidationError,
      setEqualShake,
      passwordsNotEqualError,
      userActive,
      generalError,
      passwordsBeingDebounced,
      passwordFeedback

    } = this.state;
    const {
      t
    } = this.props;

    return (
      <div className={
        "signup-form top-border-radius" +
        (keyInvalid || userActive || generalError ? ' mini ' : null) +
        (passwordFeedback ? ' long ' : null)

      }
      >
        {/*<ToastContainer id='toast' />*/}
        {isLoading ? (
          <SimpleLoader/>
        ) : null}
        <div>
          <Card.Content className="login-header top-border-radius">
            <img src={sterLogo} alt="Sterilis Medical" className="ster-img"/>
          </Card.Content>
          {keyInvalid || userActive || generalError ?
            <Card.Content className="login-body">
              <div>
                {
                  generalError &&
                  <div>
                    <div>
                      <p className="signup-intro">
                        <Trans i18nKey="account-signup-code-error">
                          Error validating activation code, please contact support if problem persists.
                        </Trans>
                      </p>
                      <p className="signup-p">
                        <Link
                                 to={"/sa"}
                        >
                          {t('Return home')}
                        </Link>
                      </p>
                    </div>
                  </div>
                }
                {
                  keyInvalid &&
                  <div>
                    <p className="signup-intro">
                      {t('The activation code has expired')}.
                    </p>
                    <p className="signup-p">
                      <Trans i18nKey="account-signup-resend">
                        Please contact a Sterilis Site Administrator within your company to resend an activation email,
                        or contact Sterilis for support.
                      </Trans>
                    </p>
                  </div>
                }
                {
                  userActive &&
                  <div>
                    <p className="signup-intro">
                      {t('This user is already active')}.
                    </p>
                    <p className="signup-p">
                      <Link
                               to={"/sa"}
                      >
                        Return home
                      </Link>
                    </p>
                  </div>
                }
              </div>
            </Card.Content>


            : (
              <Card.Content className="login-body">
                <div className='signup-textbody'>
                  <p className="signup-intro">{t("Hi")} {fullName}, {t("welcome to Sterilis")}.</p>
                  <p className="signup-p">{t("To complete the registration process, create a password here")}.</p>
                  <p className={"signup-p"
                  + (passwordValidationError ? ' light-validation-error ' : '')
                  + (setRequirementsShake ? ' shake ' : '')}>
                    {passwordFeedback && passwordFeedback}
                    {(this.state.password.length < 10 || this.state.confirmPassword.length < 10) &&
                        t('Passwords must be at least ten characters long')}</p>
                  {
                    passwordsNotEqualError ? (
                      <p className={"signup-p light-validation-error " + (setEqualShake ? ' shake ' : '')}>
                        {t("The two passwords do not match")}.
                      </p>
                    ) : null
                  }
                </div>
                <Form onSubmit={this.submitForm}>
                  <Form.Field>
                    <div className="form-group disabled-input">
                      <input value={this.state.username}
                             name='username'
                             type="text"
                             id="usernameInput"
                             disabled={true}
                             className="form-control" required/>
                      <label className="form-control-placeholder"
                             htmlFor="usernameInput">{t('Username')}</label>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="form-group disabled-input">
                      <input value={this.state.email}
                             name='email'
                             type="text"
                             id="emailInput"
                             disabled={true}
                             className="form-control" required/>
                      <label className="form-control-placeholder"
                             htmlFor="emailInput">{t('Email address')}</label>
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="form-group">
                      <input value={this.state.password}
                             onChange={this.handleChange}
                             name='password'
                             type="password"
                             id="passwordInput"
                             className="form-control" required/>
                      <label className="form-control-placeholder"
                             htmlFor="passwordInput">{t('Password')}</label>
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="form-group">
                      <input value={this.state.confirmPassword}
                             onChange={this.handleChange}
                             name='confirmPassword'
                             type="password"
                             id="confirmPasswordInput"
                             className="form-control" required/>
                      <label className="form-control-placeholder"
                             htmlFor="confirmPasswordInput">{t('Confirm password')}</label>
                    </div>
                  </Form.Field>
                  <input
                    className="form-submit"
                    id="loginButton"
                    value="SUBMIT"
                    disabled={passwordValidationError || passwordsNotEqualError || passwordsBeingDebounced}
                    type="submit"
                  />
                </Form>
              </Card.Content>
            )}
        </div>
      </div>
    )
  }
}

export default composeHoc(withRouter, translate('translations'))(ActivateAccount);
