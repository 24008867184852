import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Stack,
    Switch,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDistributorDetails,
    setDistributorActive,
    setDistributorDetails,
    setDistributorName,
    setEditModalOpen,
    updateDistributorDetails,
} from "../distributorSlice";

const EditDistributorModal = (props) => {
    const dispatch = useDispatch();
    const { editModalOpen, selectedDistributor, distributorDetails, updatedDistributor } = useSelector(
        // @ts-ignore
        (store) => store.distributor
    );
    const handleClose = () => dispatch(setEditModalOpen(false));

    useEffect(() => {
        console.log(`Selected Distributor: ${selectedDistributor}`);
        if (selectedDistributor !== null) {
            // @ts-ignore
            dispatch(fetchDistributorDetails(selectedDistributor));
        } else {
            dispatch(setDistributorDetails(null));
        }
    }, [selectedDistributor]);

    const updateDistributorName = (updatedValue) => {
        dispatch(setDistributorName(updatedValue));
    };

    const updateDistributorActive = (updatedValue) => {
        dispatch(setDistributorActive(updatedValue));
    };

    const sendUpdatedDistributorDetails = () => {
        dispatch(setEditModalOpen(false));
        // @ts-ignore
        dispatch(updateDistributorDetails(updatedDistributor));
    };

    return (
        <Dialog onClose={handleClose} open={editModalOpen}>
            <DialogTitle>Edit Distributor</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText>Update Distributor Information and Click Save</DialogContentText>
                    <TextField
                        id="dist-name"
                        label="Name"
                        variant="outlined"
                        defaultValue={distributorDetails?.distributor_name}
                        onChange={(event) => {
                            updateDistributorName(event.target.value);
                        }}
                    />
                    <TextField
                        id="main-customer"
                        variant="outlined"
                        label="Main Customer"
                        defaultValue={distributorDetails?.main_customer?.customer_name}
                        disabled
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" autoFocus onClick={sendUpdatedDistributorDetails}>
                    Save
                </Button>
                <Button onClick={handleClose} variant="outlined" color="error">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditDistributorModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    distributorId: PropTypes.number,
};

export default EditDistributorModal;
