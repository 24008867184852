import React, { useEffect, useState } from "react";
import {
    Tabs,
    Tab,
    Typography,
    Box,
    Divider,
    Stack,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next"; // Import i18next for translations

// Import other components for each tab
import LocationConfiguration from "./LocationConfiguration";
import DeviceConfiguration from "./Configuration";
import DeviceErrorLog from "./ErrorLog";
import SoftwareErrorLog from "./SoftwareErrorLog";
import DeviceConfigLog from "./ConfigLog";
import DeviceTimeline from "./Timeline";
import DeviceFiles from "./Files";
import Grid from "@mui/material/Unstable_Grid2";
import DeviceAutoComplete from "src/components/material/DeviceDropdown/DeviceAutoComplete";
import MainCard from "src/components/material/Cards/MainCard";
import DeviceStatusPanel from "./DeviceStatusPanel";
import { AuthReq, isEmptyObject, isNotEmptyObject } from "src/components/library/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    fetchDeviceConfig,
    fetchDeviceConfigOptions,
    fetchDeviceDropdownOptions,
    fetchDeviceLogs,
    fetchExtraDeviceInfo,
    fetchPvSensors,
    fetchRegulatoryCompliance,
    resetDeviceInfoState,
    selectFilteredDropdownOptions,
    setCustomerDevicesOnly,
    setSelectedDevice,
    setSelectedDeviceConfig,
    setSelectedDistributor,
} from "./deviceInfoSlice";
import queryString from "query-string";

const DeviceInformation = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        selectedDevice,
        selectedDistributor,
        customerDevicesOnly,
        deviceConfigOptions,
        distributorDropdownOptions,
        pvSensorDropdown,
        // @ts-ignore
    } = useSelector((store) => store.deviceInfo);

    const deviceDropdownOptions = useSelector(selectFilteredDropdownOptions);
    // @ts-ignore
    const { group } = useSelector((store) => store.user);
    const [deviceQueryParam, setDeviceQueryParam] = useState(props.match.params["device_id"]);
    const [serialQueryParam, setSerialQueryParam] = useState(queryString.parse(props.location.search).serial);
    const [tabValue, setTabValue] = useState(0);
    const [showDistributorDropdown, setShowDistributorDropdown] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        document.title = "Find A Machine";
        if (isEmptyObject(deviceDropdownOptions)) {
            // @ts-ignore
            dispatch(fetchDeviceDropdownOptions());
        }
        if (pvSensorDropdown.length === 0) {
            // @ts-ignore
            dispatch(fetchPvSensors());
        }
        if (isEmptyObject(deviceConfigOptions)) {
            // @ts-ignore
            dispatch(fetchDeviceConfigOptions());
        }
        if (group === "SterilisWasteTypeAdmin") {
            // @ts-ignore
            dispatch(fetchRegulatoryCompliance());
        }
        return () => {
            // @ts-ignore
            dispatch(resetDeviceInfoState());
        };
    }, []);

    useEffect(() => {
        if (deviceQueryParam && isNotEmptyObject(deviceDropdownOptions)) {
            const deviceOption = deviceDropdownOptions.find((opt) => opt.key === parseInt(deviceQueryParam));
            if (deviceOption) {
                dispatch(setSelectedDevice(deviceOption));
            } else {
                dispatch(setSelectedDevice(null));
            }
        } else if (serialQueryParam && isNotEmptyObject(deviceDropdownOptions)) {
            const deviceOption = deviceDropdownOptions.find((opt) => opt.text.includes(serialQueryParam));
            if (deviceOption) {
                dispatch(setSelectedDevice(deviceOption));
            }
        }
    }, [deviceDropdownOptions]);

    useEffect(() => {
        if (selectedDevice) {
            document.title = `Find A Machine: ${selectedDevice.serial}`;
            history.push(`/find-a-machine/${selectedDevice.value}`);
            // @ts-ignore
            dispatch(fetchDeviceConfig(selectedDevice.value));
            // @ts-ignore
            dispatch(fetchDeviceLogs(selectedDevice.value));
            // @ts-ignore
            dispatch(fetchExtraDeviceInfo(selectedDevice.value));
        } else {
            dispatch(setSelectedDeviceConfig(null));
        }
    }, [selectedDevice]);

    return (
        <MainCard title={t("Device Information")}>
            <Grid container spacing={2}>
                <Grid xs={12} md={7} sx={{ display: "flex" }}>
                    <Stack sx={{ width: "80%", justifyContent: "space-between" }}>
                        <Typography>
                            {t(
                                "device_info_desc",
                                "Display device information. Can search for a device by serial number, customer name, or facility name"
                            )}
                        </Typography>
                        <AuthReq
                            userGroup={group}
                            requiredRoles={[
                                "SterilisSuperUsers",
                                "SterilisPortalUsers",
                                "FSEs",
                                "ExternalFSEs",
                                "FactoryWorkers",
                                "SterilisWasteTypeAdmin",
                            ]}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={showDistributorDropdown}
                                        onChange={(event) => {
                                            dispatch(setSelectedDistributor(null));
                                            setShowDistributorDropdown(event.target.checked);
                                        }}
                                    />
                                }
                                label={t("Filter By Distributor")}
                            />
                            {showDistributorDropdown && (
                                <Autocomplete
                                    value={selectedDistributor}
                                    onChange={(event, newValue) => dispatch(setSelectedDistributor(newValue))}
                                    options={distributorDropdownOptions}
                                    getOptionLabel={(option) => option.text}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("Distributor Selection")} variant="outlined" />
                                    )}
                                />
                            )}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={customerDevicesOnly}
                                        onChange={(event) => dispatch(setCustomerDevicesOnly(event.target.checked))}
                                    />
                                }
                                label={t("Show Customer Devices Only")}
                            />
                        </AuthReq>
                        <DeviceAutoComplete deviceOptions={deviceDropdownOptions} />
                    </Stack>
                </Grid>
                <Grid xs={12} md={5} display={"flex"} justifyContent={"flex-end"}>
                    <DeviceStatusPanel />
                </Grid>
                <Grid xs={12}>
                    <MainCard>
                        <Grid container>
                            <Grid xs={12} sx={{}}>
                                {/* Tabs for different sections */}

                                <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
                                    <Tab label={t("Location Configuration")} />
                                    <Tab label={t("Device Configuration")} />
                                    <Tab label={t("Device Error Log")} />
                                    <Tab label={t("Software Error Log")} />
                                    <Tab label={t("Device Config Log")} />
                                    <Tab label={t("Device Timeline")} />
                                    <Tab label={t("Device Files")} />
                                </Tabs>
                                <Divider />
                            </Grid>
                            <Grid xs={12}>
                                {/* Content for each tab */}
                                <Box hidden={tabValue !== 0}>
                                    <LocationConfiguration />
                                </Box>
                                <Box hidden={tabValue !== 1}>
                                    <DeviceConfiguration />
                                </Box>
                                <Box hidden={tabValue !== 2}>
                                    <DeviceErrorLog />
                                </Box>
                                <Box hidden={tabValue !== 3}>
                                    <SoftwareErrorLog />
                                </Box>
                                <Box hidden={tabValue !== 4}>
                                    <DeviceConfigLog />
                                </Box>
                                <Box hidden={tabValue !== 5}>
                                    <DeviceTimeline />
                                </Box>
                                <Box hidden={tabValue !== 6}>
                                    <DeviceFiles />
                                </Box>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default DeviceInformation;
