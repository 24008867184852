import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { calculateCycleTime, prettyWastetypes } from "src/components/library/helpers";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { DateTime } from "luxon";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { ExpandMoreTwoTone } from "@mui/icons-material";

const cycleStatus = (cycle) => {
    const cycleStatus = cycle["status"];
    if (cycleStatus.length > 1) {
        return (
            <Accordion elevation={0} sx={{ padding: 0 }}>
                <AccordionSummary sx={{ flexDirection: "row-reverse" }} expandIcon={<ExpandMoreTwoTone />}>
                    {cycleStatus.length} Statuses
                </AccordionSummary>
                <AccordionDetails>
                    {cycleStatus.map((status, idx) => {
                        return (
                            <Typography
                                key={`${cycle["id"]}-${idx}`}
                                color={status?.cycle_state_display?.cycle_failed ? "error" : ""}>
                                {status["cycle_state_display"]["status_code"]} -{" "}
                                {status["cycle_state_display"]["message"]}
                            </Typography>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        );
    } else if (cycleStatus.length === 1) {
        const singleCycleStatus = cycleStatus[0];
        return (
            <p key={`${cycle["id"]}`} className={singleCycleStatus["cycle_state_display"]["cycle_failed"] ? "red" : ""}>
                {singleCycleStatus["cycle_state_display"]["status_code"]} -{" "}
                {singleCycleStatus["cycle_state_display"]["message"]}
            </p>
        );
    } else {
        return <p key={`${cycle["id"]}`}>No cycle status for cycle id {cycle["id"]}</p>;
    }
};

const ActiveCyclesTable = (props) => {
    const { t } = useTranslation("translations");
    const theme = useTheme();

    const Toolbar = (props) => {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    const cycles = props.activeCycles.map((cycle) => {
        return {
            id: cycle.id,
            deviceConfigId: cycle.device_config_id,
            deviceSerial: cycle.device_serial,
            timeStarted: cycle.time_started,
            timeElapsed: calculateCycleTime(cycle.time_started, null, cycle.latest_event, cycle.latest_status),
            cycleType:
                cycle.cycle_type === "sterilization"
                    ? prettyWastetypes(cycle.waste_type)
                    : prettyWastetypes(cycle.cycle_type),
            firmware: cycle.firmware,
            operator: cycle.operator,
            status: cycle.status,
        };
    });

    const columns = [
        {
            headerName: "Device Serial",
            field: "deviceSerial",
            headerAlign: "center",
            headerClassName: "active-cycle-table--header",
            align: "center",
            flex: 0.5,
            renderCell: (params) => (
                <Link target="_blank" to={`/find-a-machine?serial=${params.value}`}>
                    {params.value}
                </Link>
            ),
        },
        {
            headerName: "Time Started",
            field: "timeStarted",
            headerAlign: "center",
            headerClassName: "active-cycle-table--header",
            align: "center",
            flex: 1,
            renderCell: (params) => (
                <Link target="_blank" to={`/sa/dashboard/${params.row.deviceConfigId}`}>
                    {DateTime.fromISO(params.value).toLocaleString(DateTime.DATETIME_SHORT)}
                </Link>
            ),
        },
        {
            headerName: "Cycle Time",
            field: "timeElapsed",
            headerAlign: "center",
            headerClassName: "active-cycle-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: "Cycle Type",
            field: "cycleType",
            headerAlign: "center",
            headerClassName: "active-cycle-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: "Software Version",
            field: "firmware",
            headerAlign: "center",
            headerClassName: "active-cycle-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: "Device Operator",
            field: "operator",
            headerAlign: "center",
            headerClassName: "active-cycle-table--header",
            align: "center",
            flex: 1,
        },
        {
            headerName: "Cycle Status",
            field: "status",
            headerAlign: "center",
            headerClassName: "active-cycle-table--header",
            align: "center",
            flex: 1,
            renderCell: (params) => cycleStatus(params.row),
        },
    ];
    return (
        <Box
            sx={{
                width: "100%",
                "& .active-cycle-table--header": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                },
            }}>
            {props.cyclesLoading ? (
                <Skeleton width="100%" height={300} />
            ) : (
                <DataGrid
                    disableSelectionOnClick
                    autoHeight
                    // @ts-ignore
                    columns={columns}
                    rows={cycles}
                    sx={{
                        fontSize: 14,
                    }}
                    getRowHeight={({ id, densityFactor, model }) => {
                        if (model.status.length > 1) {
                            return "auto";
                        }
                    }}
                    components={{ Toolbar: Toolbar }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "timeStarted", sort: "desc" }],
                        },
                    }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            )}
        </Box>
    );
};

ActiveCyclesTable.propTypes = {
    activeCycles: PropTypes.array,
    cyclesLoading: PropTypes.bool,
};

export default ActiveCyclesTable;
