/*
* Main Sterilis facing app base page
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/


import React, { Component } from 'react';
import '../App.css';
import { withTranslation as translate } from "react-i18next";
import withAuth from './withAuth';
import {composeHoc} from "./library/helpers";
import Dashboard from "src/features/Admin/Dashboard/Dashboard";

class App extends Component {
    componentDidMount() {
        document.title = "Sterilis Portal";
    }
    render() {
        return <Dashboard />;
    }
}

export default composeHoc(
	translate("translations"),
	withAuth(
		[
			"SterilisSuperUsers",
			"SterilisPortalUsers",
			"FSEs",
			"ExternalFSEs",
			"FactoryWorkers",
			"DistributorAdmins",
			"DistributorReadOnly",
			"DistributorFSEs",
			"SterilisWasteTypeAdmin",
		],
		"internalPage"
	)
)(App);
