/*
*  Component for the Customer User tab
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import {AuthReq, composeHoc} from "./library/helpers";
import CustomerDeviceOperatorsTable from './CustomerDeviceOperatorsTable';
import EditADeviceOperator from './EditADeviceOperator';
import SimpleLoader from './SimpleLoader';
import {Icon, Dropdown} from 'semantic-ui-react';
import '../css/CustomerUsers.css';
import AuthService from "./AuthService";
import DeleteOperatorModal from "./DeleteOperatorModal";
import ReactivateOperatorModal from "./ReactivateOperatorModal";
import UpgradeOperatorModal from "./customer_portal/UpgradeOperatorModal";
import FAUpgradeOperatorModal from "./customer_portal/FAUpgradeOperatorModal";
import * as Sentry from '@sentry/browser';
import {isGroupSterilis} from "./library/helpers";
import { fetchDeviceOperators } from "src/features/Customer/Operators/operatorSlice";
import { connect } from "react-redux";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { AdminPanelSettingsTwoTone, CheckCircleTwoTone, EmailTwoTone, PersonOffTwoTone } from "@mui/icons-material";
import { setEditUser, setEditUserModalOpen } from "src/features/User/Edit/editUserSlice";
import { setEditOperator, setEditOperatorModalOpen } from "src/features/User/Edit/editOperatorSlice";

const Auth = new AuthService();

class CustomerUsers extends React.Component {
    state = {
        appLoading: false,
        editOperatorModal: false,
        deviceOperatorBeingEdited: null,
        operatorBeingUpdated: null,
        employeeBeingEdited: null,
        deviceOperators: [],
    };

    componentDidMount() {
        this.setState({ appLoading: true });
        this.props.hoistFunction("fetchDeviceOperators", this.props.fetchDeviceOperators);
        this.preparePage().then(() => {
            this.setState({ appLoading: false });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.operators !== this.props.operators) {
            this.buildOperatorTableData();
        }
    }

    preparePage = async () => {
        this.props.fetchDeviceOperators().then(() => {
            this.buildOperatorTableData();
        });
    };

    editOperator = (operatorObj) => {
        this.props.setEditOperator(operatorObj);
        this.props.setEditOperatorModalOpen(true);
    };

    toggleUpgradeOperatorModal = (operatorObj) => {
        this.setState((prevState) => {
            return {
                showUpgradeOperatorModal: !prevState.showUpgradeOperatorModal,
                operatorBeingUpdated: operatorObj,
            };
        });
    };

    toggleFAUpgradeOperatorModal = (operatorObj) => {
        this.setState((prevState) => {
            return {
                showFAUpgradeOperatorModal: !prevState.showFAUpgradeOperatorModal,
                operatorBeingUpdated: operatorObj,
            };
        });
    };

    setDeviceAdmin = (operatorObj) => {
        const adminStatus = operatorObj["is_admin"];
        const oppositeAdminStatus = !adminStatus;
        const deviceOperatorID = operatorObj["id"];
        const employeeName = operatorObj["employee"]["full_name"];

        const putObjDeviceOperator = {
            is_admin: oppositeAdminStatus,
        };
        this.setState({ appLoading: true });
        Auth.fetch(`/api/device-operator/${deviceOperatorID}/`, {
            method: "PATCH",
            body: JSON.stringify(putObjDeviceOperator),
        })
            .then(() => {
                this.props.notifyEditSuccess(employeeName);
                this.props.fetchDeviceOperators();
            })
            .catch((err) => {
                this.props.notifyGenericFailure("set", employeeName, "to a device admin.");
                Sentry.captureException(err);
            });
    };

    handleReactivateOperatorModal = (operator) => {
        this.setState((prevState) => {
            return {
                showReactivateOperatorModal: !prevState.showReactivateOperatorModal,
                operatorBeingReactivated: operator,
            };
        });
    };

    handleDeleteOperatorModal = (operator) => {
        this.setState((prevState) => {
            return {
                showDeleteOperatorModal: !prevState.showDeleteOperatorModal,
                operatorBeingDeleted: operator,
            };
        });
    };

    resendActivationEmail = (portalUser) => {
        this.setState({ appLoading: true });
        Auth.fetch(`/api/resend-activation-email/`, {
            method: "POST",
            body: JSON.stringify({
                portal_user_id: portalUser.id,
            }),
        })
            .then((data) => {
                this.setState({ appLoading: false });
                this.props.notifyResendActivationEmailSuccess(portalUser.employee_email);
            })
            .catch((e) => {
                Sentry.captureException(e);
                this.setState({ appLoading: false });
                this.props.notifyResendActivationEmailFailure(portalUser.employee_email);
            });
    };

    PortalUserStatus = (portalUser) => {
        const { t } = this.props;
        if (portalUser.employee_deleted) {
            const employeeName = portalUser.employee_name;
            return (
                <div data-search="deleted" className="admin-check-div">
                    <Tooltip title={t("account_archived", { employeeName: employeeName })}>
                        <PersonOffTwoTone color="error" />
                    </Tooltip>
                </div>
            );
        } else if (portalUser.is_active === false) {
            const employeeName = portalUser.employee_name;
            return (
                <div data-search="inactive" className="admin-check-div">
                    <Tooltip title={t("account_inactive", { employeeName: employeeName })}>
                        <IconButton onClick={() => this.resendActivationEmail(portalUser)}>
                            <EmailTwoTone color="warning" />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        } else {
            return (
                <div data-search="active" className="admin-check-div">
                    <CheckCircleTwoTone color="success" />
                </div>
            );
        }
    };

    buildOperatorTableData = () => {
        const { t, group } = this.props;
        let deviceOperatorCount = 0;
        const deviceOperators = this.props.operators.map((operator) => {
            const notify_complete = operator["notify_complete"];
            const notify_error = operator["notify_error"];
            const admin_status = operator["is_admin"];
            const portalUser = operator["portal_user"];
            const notificationSetting = (notify_complete, notify_error) => {
                if (notify_complete && notify_error) {
                    return "Completion & Error";
                } else if (notify_complete) {
                    return "Completion";
                } else if (notify_error) {
                    return "Error";
                } else {
                    return "None";
                }
            };

            const operatorRole = (admin_status) => {
                if (admin_status) {
                    return (
                        <Chip
                            data-search="admin"
                            variant="outlined"
                            color="primary"
                            sx={{ fontWeight: "bold" }}
                            label="DEVICE ADMIN"
                            icon={<AdminPanelSettingsTwoTone />}
                        />
                    );
                    // return <div className='admin-check-div'><Icon size='large' name='check circle outline' className='green' /></div>
                }
                // else {
                //   return <div data-search="operator" className='operator-badge'>Operator</div>
                // }
            };

            const deviceOperatorAction = (operator) => {
                if (operator.deleted) {
                    return (
                        <Dropdown.Item
                            onClick={() => this.handleReactivateOperatorModal(operator)}
                            text={<h5>{t("Reactivate Device Operator")}</h5>}
                        />
                    );
                } else {
                    deviceOperatorCount++;
                    return (
                        <Dropdown.Item
                            onClick={() => this.handleDeleteOperatorModal(operator)}
                            text={<h5>{t("Archive Device Operator")}</h5>}
                        />
                    );
                }
            };

            const deviceOperatorStatus = (operator) => {
                if (operator.deleted) {
                    return (
                        <div data-search="deleted" className="admin-check-div">
                            <Tooltip title={`${operator.employee.full_name}'s PIN has been archived.`}>
                                <PersonOffTwoTone color="error" />
                            </Tooltip>
                        </div>
                    );
                } else {
                    return (
                        <div data-search="active" className="admin-check-div">
                            <Icon size="large" name="check circle outline" className="green" />
                        </div>
                    );
                }
            };

            return {
                ...(isGroupSterilis(group) ? { customer_name: operator.employee.customer.customer_name } : null),
                full_name: operator.employee.full_name,
                pin: operator.pin,
                customer_id: operator.employee.customer.id,
                status: deviceOperatorStatus(operator),
                portal_user_status: portalUser.length > 0 ? this.PortalUserStatus(portalUser[0]) : "",
                email_address: operator.employee.email_address,
                phone_number: operator.employee.phone,
                notification_settings: notificationSetting(notify_complete, notify_error),
                operator_role: operatorRole(admin_status),
                deleted_status: operator.deleted,
                actions: (
                    <div className="overflow-visible-td">
                        <Dropdown id={operator["id"]} icon="ellipsis horizontal" pointing="right">
                            <Dropdown.Menu>
                                <Dropdown.Header content={<h4>{t("Operator Actions")}</h4>} />
                                <Dropdown.Divider />
                                {operator.employee.deleted === null && (
                                    <React.Fragment>
                                        <Dropdown.Item
                                            id={"edit-" + operator["id"]}
                                            onClick={() => this.editOperator(operator)}
                                            text={<h5>{t("Edit Operator")}</h5>}
                                        />
                                        <Dropdown.Item
                                            id={"setAdmin-" + operator["id"]}
                                            onClick={() => this.setDeviceAdmin(operator)}
                                            text={
                                                admin_status ? (
                                                    <h5>{t("Remove Device Admin")}</h5>
                                                ) : (
                                                    <h5>{t("Make Device Admin")}</h5>
                                                )
                                            }
                                        />
                                        {group !== "FacilityAdmin" && (
                                            <Dropdown.Item
                                                id={"upgrade-" + operator["id"]}
                                                onClick={() => this.toggleUpgradeOperatorModal(operator)}
                                                text={<h5>{t("Upgrade Operator to Site Admin")}</h5>}
                                            />
                                        )}
                                        <Dropdown.Item
                                            id={"fa-upgrade-" + operator["id"]}
                                            onClick={() => this.toggleFAUpgradeOperatorModal(operator)}
                                            text={<h5>{t("Upgrade Operator to Facility Admin")}</h5>}
                                        />
                                        {portalUser.length > 0 && portalUser[0].is_active === false && (
                                            <Dropdown.Item
                                                onClick={() => this.resendActivationEmail(portalUser[0])}
                                                text={<h5>{t("Resend activation email")}</h5>}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                                {deviceOperatorAction(operator)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ),
            };
        });
        this.setState({
            deviceOperators: deviceOperators,
            appLoading: false,
            deviceOperatorCount: deviceOperatorCount,
        });
    };

    setDeviceOperatorCount = (count) => {
        this.setState({
            deviceOperatorCount: count,
        });
    };

    render() {
        const { t, deviceOperatorBtn, isCurrentUserSterilisOrDist, group } = this.props;

        const {
            editOperatorModal,
            operatorBeingEdited,
            appLoading,
            deviceOperatorCount,
            showDeleteOperatorModal,
            showReactivateOperatorModal,
            operatorBeingDeleted,
            operatorBeingReactivated,
            showUpgradeOperatorModal,
            showFAUpgradeOperatorModal,
        } = this.state;

        return (
            <div>
                <div className="customer-header">
                    <div className="stat-info">
                        <p className="large-num">{deviceOperatorCount}</p>
                        <div className="stat-name">
                            {t("TOTAL")}
                            <br />
                            {t("DEVICE OPERATORS")}
                        </div>
                    </div>
                    {isCurrentUserSterilisOrDist ? this.props.filterByDiv : null}
                    {deviceOperatorBtn}
                </div>
                {this.props.loading ? <SimpleLoader /> : null}
                <AuthReq
                    userGroup={group}
                    requiredRoles={[
                        "SterilisSuperUsers",
                        "SterilisPortalUsers",
                        "DistributorAdmins",
                        "FSEs",
                        "ExternalFSEs",
                        "CustomerPortalUsers",
                        "SterilisWasteTypeAdmin",
                        "FacilityAdmin",
                    ]}>
                    {editOperatorModal ? (
                        <EditADeviceOperator
                            operatorBeingEdited={operatorBeingEdited}
                            editOperatorModal={editOperatorModal}
                            editOperator={this.editOperator}
                            simpleCustomerFacilityObj={this.props.simpleCustomerFacilityObj}
                            fetchDeviceOperators={this.props.fetchDeviceOperators}
                            fetchSiteAdmins={this.props.fetchSiteAdmins}
                            notifyEditSuccess={this.props.notifyEditSuccess}
                            notifyEditFailure={this.props.notifyEditFailure}
                            //gets hoisted from CustomerMachines, used to get new pins after creating new operator
                            fetchCustomerMachines={this.props.fetchCustomerMachines}
                            customerDropdown={this.props.customerDropdown}
                            group={this.props.group}
                        />
                    ) : null}
                    {showDeleteOperatorModal ? (
                        <DeleteOperatorModal
                            group={this.props.group}
                            showDeleteOperatorModal={showDeleteOperatorModal}
                            handleDeleteOperatorModal={this.handleDeleteOperatorModal}
                            fetchDeviceOperators={this.props.fetchDeviceOperators}
                            operatorBeingDeleted={operatorBeingDeleted}
                            notifyOperatorDeactivationSuccess={this.props.notifyOperatorDeactivationSuccess}
                            notifyOperatorDeactivationFailure={this.props.notifyOperatorDeactivationFailure}
                        />
                    ) : null}

                    {showReactivateOperatorModal ? (
                        <ReactivateOperatorModal
                            group={this.props.group}
                            showReactivateOperatorModal={showReactivateOperatorModal}
                            handleReactivateOperatorModal={this.handleReactivateOperatorModal}
                            fetchDeviceOperators={this.props.fetchDeviceOperators}
                            operatorBeingReactivated={operatorBeingReactivated}
                            notifyOperatorReactivationSuccess={this.props.notifyOperatorReactivationSuccess}
                            notifyOperatorReactivationFailure={this.props.notifyOperatorReactivationFailure}
                        />
                    ) : null}

                    {showUpgradeOperatorModal ? (
                        <UpgradeOperatorModal
                            showUpgradeOperatorModal={showUpgradeOperatorModal}
                            toggleUpgradeOperatorModal={this.toggleUpgradeOperatorModal}
                            fetchDeviceOperators={this.props.fetchDeviceOperators}
                            operatorBeingUpdated={this.state.operatorBeingUpdated}
                            fetchSiteAdmins={this.props.fetchSiteAdmins}
                            notifyUpgradeSuccess={this.props.notifyUpgradeSuccess}
                            notifyUpgradeFailure={this.props.notifyUpgradeFailure}
                            notifyGenericSuccess={this.props.notifyGenericSuccess}
                            notifyGenericFailure={this.props.notifyGenericFailure}
                            refreshSiteAdmins={this.props.refreshSiteAdmins}
                        />
                    ) : null}
                    {showFAUpgradeOperatorModal ? (
                        <FAUpgradeOperatorModal
                            group={this.props.group}
                            showFAUpgradeOperatorModal={showFAUpgradeOperatorModal}
                            toggleFAUpgradeOperatorModal={this.toggleFAUpgradeOperatorModal}
                            fetchDeviceOperators={this.props.fetchDeviceOperators}
                            operatorBeingUpdated={this.state.operatorBeingUpdated}
                            fetchSiteAdmins={this.props.fetchSiteAdmins}
                            notifyUpgradeSuccess={this.props.notifyUpgradeSuccess}
                            notifyUpgradeFailure={this.props.notifyUpgradeFailure}
                            customerDropdown={this.props.customerDropdown}
                            notifyGenericSuccess={this.props.notifyGenericSuccess}
                            notifyGenericFailure={this.props.notifyGenericFailure}
                            refreshSiteAdmins={this.props.refreshSiteAdmins}
                        />
                    ) : null}
                </AuthReq>
                <div className="customer-content">
                    <CustomerDeviceOperatorsTable
                        deviceOperators={this.state.deviceOperators}
                        selectedCustomer={this.props.selectedCustomer}
                        group={this.props.group}
                        setDeviceOperatorCount={this.setDeviceOperatorCount}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeviceOperators: () => dispatch(fetchDeviceOperators()),
        setEditUser: (user) => dispatch(setEditUser(user)),
        setEditUserModalOpen: (open) => dispatch(setEditUserModalOpen(open)),
        setEditOperatorModalOpen: (open) => dispatch(setEditOperatorModalOpen(open)),
        setEditOperator: (operator) => dispatch(setEditOperator(operator)),
    };
};

const mapStateToProps = (state) => ({
    operators: state.operator.operators,
    operatorCount: state.operator.operatorCount,
    loading: state.operator.loading,
});

export default composeHoc(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(CustomerUsers);
