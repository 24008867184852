/*
* Component for 404 pages
*
* Copyright (C) 2018, 2019 Sterilis Solutions LLC all rights reserved.
*/

import React from 'react';
import {Link} from "react-router-dom";
import AuthService from './AuthService';
import { withTranslation as translate} from "react-i18next";
const Auth = new AuthService();

class NotFound extends React.Component{
  render() {
    const {t} = this.props;
    const navLink = Auth.getGroup() === 'SterilisSuperUsers' || Auth.getGroup() === 'SterilisWasteTypeAdmin' ? '/' : '/sa';
    return (
      <div className='container-404'>
        {t('The page you attempted to access was not found on this server')}.

        <Link  to={navLink}>
          <h4>{t('Back to home')}</h4>
        </Link>

      </div>
    )
  }
}

export default translate('translations')(NotFound);