/*
*  Device Health Error Occurrence table
*
* Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
*/
import React from 'react';
import {withTranslation as translate} from "react-i18next";
import HookTable from "../library/HookTable";

const ErrorOccurrenceTable = props => {
  const {
    t,
    errorOccurrenceTable
  } = props;

  const columns = [
    {
      Header: t('Error code'),
      accessor: 'error_code',
      id: 'error_code',
    },
    {
      Header: t('Description'),
      accessor: 'description',
      id: 'description',
    },
    {
      Header: t('Occurrence'),
      accessor: 'occurrence',
      id: 'occurrence',
    }
  ];

  return (
    <HookTable
      id='errorOccurrenceTable'
      data={errorOccurrenceTable}
      columns={columns}
      tableName='errorOccurrenceTable'
      defaultSortBy={{id: 'occurrence', desc: true}}
    />
  )
};
export default translate('translations')(ErrorOccurrenceTable);
