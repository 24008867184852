/*
* -placeholder text for new functional component -
*
* Copyright (C) 2018, 2019, 2020 Sterilis Solutions LLC all rights reserved.
*/
import React from 'react';
import PropTypes from 'prop-types';
import CycleStat from "./CycleStat";
import {getRoundedNumber} from "../library/helpers";
import {useTranslation} from "react-i18next";
import moment from "moment";

const PortalCycleInformation = props => {
  const {t} = useTranslation('translations');
  const {
    portalCycle
  } = props;
  const getErrorJsx = deviceErrors => {
    const errorTitle = `${deviceErrors.length} Error${deviceErrors.length > 0 ? 's' : ''}`;
    return <details>
      <summary>
        {errorTitle}
      </summary>
      {deviceErrors.map((error, idx) => {
        return <div className='detailed-cycle-stats' key={`${idx}-error`}>
          <CycleStat
            label={'Error time'}
            value={moment.utc(error['error_time']).format("YYYY-MM-DD HH:mm:ss")}
          />
          {error['sw_error_msg'] &&
          <CycleStat
            label={'Software error message'}
            value={error['sw_error_msg']}
          />
          }
          {error['hw_error_code'] &&
          <React.Fragment>
            <CycleStat
              label={'Error code'}
                value={error['hw_error_code']['error_code']}
            />
            <CycleStat
              label={'Description'}
                value={error['hw_error_code']['description']}
            />
          </React.Fragment>
          }
          <CycleStat
            label={'Device State'}
            value={error['machine_state']}
          />
        </div>
      })}
    </details>
  };
  return <React.Fragment>
    <fieldset>
      <legend>{t('Portal Cycle Information')}</legend>
      <CycleStat
        label={'Cycle Type'}
        value={portalCycle['cycle_type_display']}
      />
      <CycleStat
        label={'Waste Type'}
        value={portalCycle['waste_type_display']}
      />

      <CycleStat
        label={'Weight'}
        value={getRoundedNumber(portalCycle, 'weight')}
      />
      <CycleStat
        label={'Ambient pressure'}
        value={getRoundedNumber(portalCycle, 'ambient_pressure')}
      />
      <CycleStat
        label={'Tv cal'}
        value={getRoundedNumber(portalCycle, 'tv_cal')}
      />
      <CycleStat
        label={'Voltage'}
        value={portalCycle['voltage']}
      />
      <CycleStat
        label={'Odor Spray Count'}
        value={getRoundedNumber(portalCycle, 'odor_spray_count')}
      />
      {portalCycle['firmware'] &&
      <CycleStat
        label={'Firmware version'}
        value={portalCycle['firmware']['firmware_version']}
      />
      }
      {
        portalCycle['device_errors'] && portalCycle['device_errors'].length > 0 && getErrorJsx(portalCycle['device_errors'])
      }
    </fieldset>
  </React.Fragment>
};

PortalCycleInformation.defaultProps = {
  portalCycle: {},

};

PortalCycleInformation.propTypes = {
  portalCycle: PropTypes.object,

};

export default PortalCycleInformation;
