/*
 *  Modal which fires off the endpoint to generate Erich's spreadsheet
 *
 * Copyright (C) 2018, 2019 Sterilis Solutions, LLC, all rights reserved.
 */
import React from "react";
import { Trans, withTranslation as translate } from "react-i18next";
import {
  Button,
  Header,
  Modal,
  TransitionablePortal,
  Popup,
  Icon,
} from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import AuthService from "../../AuthService";
import SimpleLoader from "../../SimpleLoader";
import * as Sentry from "@sentry/browser";

const Auth = new AuthService();

class DeviceSpreadsheetModal extends React.Component {
  state = {
    allDevices: false,
    filterByDate: false,
    includeSteamTime: false,
    includeAbortedCycles: false,
    includeExcelHeaders: true,
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    employeeEmail: "test",
    sheetQueried: false,
    allowModalToBeClosed: true,
    hasCycles: true
  };

  componentDidMount() {
    const portalUserID = Auth.getProfile()["user_id"];

    if (portalUserID) {
      this.setState({
        modalLoading: true,
      });
      Auth.fetch(`/api/portal-user/${portalUserID}`)
        .then((data) => {
          this.setState({
            modalLoading: false,
            employeeEmail: data["employee"]["email_address"],
          });
        })
        .catch((err) => {
          this.setState({
            modalLoading: false,
          });
        });
    }
  }

  generateDeviceSpreadsheet = (e) => {
    e.preventDefault();
    this.setState({
      modalLoading: true,
      allowModalToBeClosed: false,
    });
    Auth.fetch(
      `/api/export/device-spreadsheet?` +
        new URLSearchParams({
          all_devices: this.state.allDevices,
          include_steam_time: this.state.includeSteamTime,
          include_user_aborted_cycles: this.state.includeAbortedCycles,
          include_excel_header: this.state.includeExcelHeaders,
          filter_by_date: this.state.filterByDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
          has_cycles: this.state.hasCycles,
        }),
      {
        method: "GET",
      }
    )
      .then((data) => {
        this.setState({
          modalLoading: false,
          sheetQueried: true,
          allowModalToBeClosed: true,
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
        this.setState({
          modalLoading: false,
          allowModalToBeClosed: true,
        });
      });
  };

  handleChange = (event) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;
    if (event.currentTarget.type === "checkbox") {
      this.setState((state) => ({
        [name]: !state[name],
      }));
    } else {
      this.setState({ [name]: value });
    }
  };

  startDateClick = (timestamp) => {
    this.setState({
      startDate: moment(timestamp).format("YYYY-MM-DD"),
    });
  };
  endDateClick = (timestamp) => {
    this.setState({
      endDate: moment(timestamp).format("YYYY-MM-DD"),
    });
  };

  render() {
    const {
      filterByDate,
      startDate,
      endDate,
      employeeEmail,
      modalLoading,
      sheetQueried,
    } = this.state;
    const { t } = this.props;

    const emailDisplay = employeeEmail ? (
      <Trans ns="translations" i18nKey="device-spreadsheet.email_display">
        After the excel sheet is generated, a download link will be sent to 
        <strong>{{ employeeEmail }}</strong>
      </Trans>
    ) : (
      t(
        "device-spreadsheet.no_email_display",
        "After the excel sheet is generated, a download link will be sent to the email address associated with your portal user account."
      )
    );

    const inlineStyle = {
      modal: {
        // marginTop: topMargin,
        marginTop: "0px !important",
        marginLeft: "auto",
        marginRight: "auto",
      },
      textArea: {
        marginTop: "50px",
        width: "400px",
      },
    };
    return (
      <TransitionablePortal
        open={this.props.showDeviceSpreadsheetModal}
        onOpen={() =>
          setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
        }
        transition={{ animation: "scale", duration: 500 }}
      >
        <Modal
          style={inlineStyle.modal}
          open={true}
          size="small"
          closeOnDimmerClick={this.state.allowModalToBeClosed}
          closeIcon={
            <Button className="close-modal-btn grey-btn">{t("Close")}</Button>
          }
          onClose={(event) => {
            document.body.classList.remove("modal-fade-in");
            this.setState({ sheetQueried: false });
            this.props.triggerDeviceSpreadsheetModal();
          }}
        >
          <Header>
            <h2>
              {t("device-spreadsheet.header", "Generate Device Spreadsheet")}
            </h2>
          </Header>
          {modalLoading && <SimpleLoader />}
          {sheetQueried ? (
            <Modal.Content>
              <div className="short-modal-text center-text">
                <p>
                  {t(
                    "device-spreadsheet.completed",
                    "The server has successfully received your request and is now processing the excel sheet."
                  )}
                </p>
                <p>{emailDisplay}</p>
              </div>
            </Modal.Content>
          ) : (
            <Modal.Content>
              <form
                id="generateDeviceSpreadsheetForm"
                onSubmit={this.generateDeviceSpreadsheet}
              >
                <div className="flex-dir-row modal-text">
                  <div className="fifty-split minor-left-right-padding">
                    <div className="form-group">
                      <label
                        className="large-left-label"
                        htmlFor="allDevicesInput"
                      >
                        {t(
                          "device-spreadsheet.1",
                          "Include factory & HQ devices"
                        )}
                      </label>
                      <input
                        checked={!!this.state.allDevices}
                        name="allDevices"
                        onChange={this.handleChange}
                        type="checkbox"
                        id="allDevicesInput"
                        className="get-files-form-checkbox"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        className="large-left-label"
                        htmlFor="hasCyclesInput"
                      >
                        {t(
                          "device-spreadsheet.5",
                          "Include only devices with cycles"
                        )}
                      </label>
                      <input
                        checked={!!this.state.hasCycles}
                        name="hasCycles"
                        onChange={this.handleChange}
                        type="checkbox"
                        id="hasCyclesInput"
                        className="get-files-form-checkbox"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        className="large-left-label"
                        htmlFor="includeSteamTimeInput"
                      >
                        {t("device-spreadsheet.2", "Include steam time")}{" "}
                        <Popup
                          trigger={
                            <Icon
                              className="padding-left"
                              name="question circle outline"
                            />
                          }
                          content="Including steam time will add an additional column onto the Excel sheet."
                        />
                      </label>
                      <input
                        checked={!!this.state.includeSteamTime}
                        name="includeSteamTime"
                        onChange={this.handleChange}
                        type="checkbox"
                        id="includeSteamTimeInput"
                        className="get-files-form-checkbox"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        className="large-left-label"
                        htmlFor="includeUserAbortedCyclesInput"
                      >
                        {t(
                          "device-spreadsheet.3",
                          "Include user aborted cycles"
                        )}
                      </label>
                      <input
                        checked={!!this.state.includeAbortedCycles}
                        name="includeAbortedCycles"
                        onChange={this.handleChange}
                        type="checkbox"
                        id="includeUserAbortedCyclesInput"
                        className="get-files-form-checkbox"
                      />
                    </div>
                  </div>
                  <div className="fifty-split minor-left-right-padding">
                    <div className="form-group">
                      <label
                        className="large-left-label"
                        htmlFor="includeExcelHeadersInput"
                      >
                        {t("device-spreadsheet.4")}
                      </label>
                      <input
                        checked={!!this.state.includeExcelHeaders}
                        name="includeExcelHeaders"
                        onChange={this.handleChange}
                        type="checkbox"
                        id="includeExcelHeadersInput"
                        className="get-files-form-checkbox"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        className="large-left-label"
                        htmlFor="filterByDateInput"
                      >
                        {t("Filter by date")}
                      </label>
                      <input
                        checked={!!this.state.filterByDate}
                        name="filterByDate"
                        onChange={this.handleChange}
                        type="checkbox"
                        id="filterByDateInput"
                        className="get-files-form-checkbox"
                      />
                    </div>
                    {filterByDate ? (
                      <div>
                        <div>
                          <label className="tiny-left-label">
                            {t("Start")}
                          </label>
                          <DayPickerInput
                            onDayChange={(day) => this.startDateClick(day)}
                            value={startDate}
                          />
                        </div>
                        <div>
                          <label className="tiny-left-label">{t("End")}</label>
                          <DayPickerInput
                            onDayChange={(day) => this.endDateClick(day)}
                            value={endDate}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <p className="modal-text center-text">{emailDisplay}</p>
              </form>
            </Modal.Content>
          )}
          <Modal.Actions className="slight-min-height">
            {sheetQueried ? null : (
              <Button
                className="ster-btn"
                value="Submit"
                type="submit"
                form="generateDeviceSpreadsheetForm"
              >
                {t("Generate Spreadsheet")}
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      </TransitionablePortal>
    );
  }
}

// export default withAuth(['SterilisSuperUsers', 'SterilisPortalUsers', 'FSEs', 'ExternalFSEs', 'FactoryWorkers','SterilisWasteTypeAdmin'])(DeviceSpreadsheetModal);
export default translate("translations")(DeviceSpreadsheetModal);
